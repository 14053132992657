<template>
  <div>
    <div class="flow__graph designed designedAgain scaleDown-0-6" ref="app_designed" id="app_designed">
      <flowy class="" :nodes="nodes"></flowy>
    </div>
    <DefaultOutOfHoursModal 
      @ofh-default-updated="fetchDefaultSetting();"
    />
    <DayOutOfHoursModal 
      @ofh-days-updated="fetchSchedule();"
    />
    <EditUserModal 
      :modalName="'EditUserGraphModal'" 
      @ofh-days-updated="fetchSchedule();"
      @ofh-toggle-updated="fetchcallRouteSetting();"
      @ofh-default-updated="fetchDefaultSetting();"
      @extension-updated="fetchExtensionDetail()" 
      @cf-updated="fetchcallRouteSetting()"
    />
    <ScheduleAssignModal 
      :modalName="`${randomId}-ScheduleAssignModal`" 
      :account="accountcode"
      @interface="updateForwardingSettings({ extension_name: $event.accountname, forward_number: $event.account, })" 
    />
    <DurationPickerModal 
      :modalName="`${randomId}-DurationPickerModal`" 
      @time="updateForwardingSettings({ timeout: $event.seconds })" 
    />
    <CallForwardingNumberInputModal 
      :modalName="`${randomId}-CallForwardingNumberInputModal`" 
      @number="updateForwardingSettings({ forward_number: $event.ber })" 
    />
    <CallForwardSettingModal 
      :modalName="`${randomId}-CallForwardSettingModal`" 
      @updated="updateForwardingSettings($event)" 
    />
  </div>
</template>

<script>
import DayOutOfHoursModal from '../../Modals/DayOutOfHoursModal.vue'
import DefaultOutOfHoursModal from '../../Modals/DefaultOutOfHoursModal.vue'
import EditUserModal from "../../Modals/EditUserModal.vue";
import ScheduleAssignModal from '../../Modals/ScheduleAssignModal.vue'
import DurationPickerModal from '../../Modals/DurationPickerModal.vue'
import CallForwardingNumberInputModal from '../../Modals/CallForwardingNumberInputModal.vue'
import CallForwardSettingModal from '../../Modals/CallForwardSettingModal.vue'
import { mapGetters } from 'vuex'
import { VOIP_API, $fn } from '@/utils';
export default {
  name: 'UserGraph',
  components: {
    DayOutOfHoursModal,
    DefaultOutOfHoursModal,
    EditUserModal,
    ScheduleAssignModal,
    DurationPickerModal,
    CallForwardingNumberInputModal,
    CallForwardSettingModal,
  },
  inject: ['getVoipUsers','appNotify'],
  props:{
    accountcode:{
      type: String
    }
  },
  data(){
    return{
      random_id: '',
      api: {
        out_of_hours: this.$helperFunction.apiInstance(),
        extension_detail: this.$helperFunction.apiInstance(),
        schedules: this.$helperFunction.apiInstance(),
        get_setting: this.$helperFunction.apiInstance(),
        route_call: this.$helperFunction.apiInstance(),
        update_route_call: this.$helperFunction.apiInstance({ source: true }),
      },
      response: {
        out_of_hours: false,
        extension_detail: {},
        schedules: [],
        default_setting: {},
        forwarding_setting: {
          forward_setting: "",
          forward_note: "",
          forward_number: "",
          timeout: 5,
          extension_name: "",
          number: "",
        },
        allow_recording: false,
        recording: false,
      },
    }
  },
  computed:{
    ...mapGetters({
      getCurrentUser: 'getCurrentUser',
      getVoipUsersAlises: 'getVoipUsersAlises'
    }),
    randomId(){ return this.random_id },
    user(){ return this.getVoipUsersAlises[this.accountcode] },
    voipaccount(){ return this.user.voipaccount },
    nodes(){
      if(!this.user) return []
      const voipaccount = this.user?.voipaccount
      const numbers = this.user?.userDid?.map?.(i=>i.did)
      const is_numbers = numbers?.length>0
      const is_ofh = this.response.out_of_hours
      const extensions = this.response?.extension_detail?.extensions ?? []
      const schedules = this.response.schedules ?? []
      const ofh_default_setting = this.response.default_setting ?? {}
      const forwarding_setting = this.response.forwarding_setting
      const nodes = []
      const callBack = this.callBack
      if(is_numbers){
        nodes.push({
          id: "numbers",
          parentId: -1,
          nodeComponent: "vb-graph-node",
          data: {
            node: 'NumbersList',
            numbers,
            callBack,
          }
        })
      }
      nodes.push({
        id: "info",
        parentId: is_numbers ? "numbers" : -1,
        nodeComponent: "vb-graph-node",
        data: {
          node: 'Info',
          voipaccount,
          callBack,
        }
      })
      if(is_ofh){
        nodes.push({
          id: "ofh",
          parentId: "info",
          nodeComponent: "vb-graph-node",
          data: {
            node: 'OutOfHours',
            voipaccount,
            schedules,
            ofh_default_setting,
            callBack,
          }
        })
      }
      nodes.push({
        id: 'extensionsicon',
        parentId: is_ofh ? 'ofh' : 'info',
        nodeComponent: "vb-graph-node",
        data: {
          node: 'ExtensionsIcon',
          extensions,
          voipaccount,
          callBack,
        }
      })
      nodes.push({
        id: 'callforwardingtext',
        parentId: 'extensionsicon',
        nodeComponent: "vb-graph-node",
        data: {
          node: 'LineText',
          text: 'if no answer',
          callBack,
        }
      })
      nodes.push({
        id: 'callforwardingsetting',
        parentId: 'callforwardingtext',
        nodeComponent: "vb-graph-node",
        data: {
          node: 'CallForwardingSetting',
          voipaccount,
          forwarding_setting,
          callBack,
        }
      })
      return nodes;
    },
  },
  methods: {
    fetchcallRouteSetting() {
      let vm = this
      vm.api.route_call.send=true
      VOIP_API.endpoints.users.callroute({
        accountcode: vm.voipaccount,
      })
      .then(({ data }) => {
        vm.response.out_of_hours = !!data.schedule_enable;
        vm.response.forwarding_setting = data.forwarding_setting;
        vm.response.recording = data.recording;
        vm.response.allow_recording = data.allow_recording=='1';
      })
      .finally(() => {
        vm.api.route_call.send=false
      });
    },
    fetchExtensionDetail() {
      var vm = this;
      vm.api.extension_detail.send = true;
      VOIP_API.endpoints.extensions.mobileextensions(vm.voipaccount)
      .then((res) => {
        vm.response.extension_detail = res?.data?.data ?? {};
      })
      .finally(() => {
        vm.api.extension_detail.send = false;
      });
    },
    fetchSchedule() {
      let vm = this
      if(vm.api.schedules.send) return;
      vm.api.schedules.send = true;
      VOIP_API.endpoints.users.schedules(vm.voipaccount, {
        fid: 12,
      })
      .then(({ data: res }) => {
        vm.response.schedules = res?.data ?? [];
      })
      .finally(() => {
        vm.api.schedules.send = false;
      });
    },
    fetchDefaultSetting(){
      let vm = this
      if(vm.api.get_setting.send) return;
      vm.api.get_setting.send = true;
      VOIP_API.endpoints.users.getscheduledefault(vm.voipaccount)
      .then(({ data }) => {
        vm.response.default_setting = data || {};
      })
      .finally(() => {
        vm.api.get_setting.send = false;
      });
    },
    updateForwardingSettings(forward_setting={}){
      let vm = this;
      vm.api.update_route_call.source?.cancel?.("Requesting Again")
      vm.api.update_route_call.source = require("axios").default.CancelToken.source();
      vm.api.update_route_call.send = true;
      const toaster_value = vm.appNotify({
        message: "Saving...",
        type: "success",
        duration: 6 * 1000,
      });
      VOIP_API.endpoints.users.updateroutecall({
        accountcode: vm.voipaccount,
        forward_setting: {
          forward_setting: forward_setting.forward_setting ?? vm.response.forwarding_setting.forward_setting,
          forward_note: forward_setting.forward_note ?? vm.response.forwarding_setting.forward_note,
          forward_number: forward_setting.forward_number ?? vm.response.forwarding_setting.forward_number,
          timeout: forward_setting.timeout ?? vm.response.forwarding_setting.timeout,
          extension_name: forward_setting.extension_name ?? vm.response.forwarding_setting.extension_name,
          number: forward_setting.number ?? vm.response.forwarding_setting.number,
        },
        call_recording: vm.response.recording,
        schedule_enable: vm.response.out_of_hours,
      },vm.api.update_route_call.source.token)
      .then(() => {
        vm.appNotify({
          message: "Successfully Updated!",
          type: 'success',
        })
        vm.fetchcallRouteSetting()
      })
      .catch((ex) => {
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        toaster_value.close();
        vm.api.update_route_call.send = false;
        vm.api.update_route_call.source = null;
      });
    },
    callBack(message){
      switch (message?.event) {
        case 'update_out_of_hours': 
          this.response.out_of_hours=!!message?.payload?.out_of_hours
          break;
        case 'open_days_out_of_hours': 
          this.$modal.show('DayOutOfHoursModal',{ 
            accountcode: message?.payload?.voipaccount,
            updated: ()=>{
              message?.payload?.updated?.()
            }
          })
          break;
        case 'open_default_out_of_hours': 
          this.$modal.show('DefaultOutOfHoursModal',{ 
            accountcode: message?.payload?.voipaccount,
            updated: ()=>{
              message?.payload?.updated?.()
            } 
          })
          break;
        case 'edit_modal': 
          this.$modal.show('EditUserGraphModal',{ 
            accountcode: this.user.voipaccount, 
          })
          break;
        case 'edit_call_forwarding': 
          this.$modal.show(`${this.randomId}-CallForwardSettingModal`,{ 
            account: this.voipaccount, 
            setting: this.response.forwarding_setting 
          })
          break;
        case 'edit_call_forwarding_duration': 
          this.$modal.show(`${this.randomId}-DurationPickerModal`,{
            value: this.response.forwarding_setting.timeout
          })
          break;
        case 'edit_call_forwarding_number': 
          this.$modal.show(`${this.randomId}-CallForwardingNumberInputModal`)
          break;
        case 'edit_call_forwarding_extension': 
          this.$modal.show(`${this.randomId}-ScheduleAssignModal`, { 
            account: this.response.forwarding_setting.forward_number, 
            type: 'setting', 
          })
          break;
        case 'show_graph':
          this.$emit('change-graph',message?.payload?.voipaccount)
          break;
        default:
          break;
      }
    },
  },
  created(){
    this.random_id = $fn.makeid(10)
  },
  mounted(){
    this.fetchcallRouteSetting();
    this.fetchExtensionDetail()
    this.fetchSchedule()
    this.fetchDefaultSetting()
  }
}
</script>

<style>

</style>