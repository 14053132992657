<template>
  <div class="crm-main-section-topBar">
    <h2 class="crm-main-section-heading mb-0">
      {{title}}
    </h2>
    <div class="section-topBar-middlePart">
      <vb-icon icon="crm-inputSearch-icon" />
      <input type="text" placeholder="Search">
    </div>
    <div class="section-topBar-lastPart">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopBarCRM',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
}
</script>

<style>

</style>