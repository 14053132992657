var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-main-section DealsSection"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100 mb-5"
  }, [_c('h2', {
    staticClass: "crm-main-section-heading text-case-unset mb-0"
  }, [_vm._v(" Company Fields "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1), _c('button', {
    staticClass: "crm-button"
  }, [_c('vb-icon', {
    staticClass: "crm-plusButton-icon",
    attrs: {
      "icon": "crm-plusButton-icon"
    }
  }), _vm._v(" Add New ")], 1)]), _vm._m(0), _vm._m(1), _vm._m(2), _vm._m(3)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-card p-32"
  }, [_c('h2', {
    staticClass: "crm-main-section-heading text-case-unset my-0",
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v(" Detail Fields ")]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100 mt-2"
  }, [_c('p', {
    staticClass: "crm-card-text my-0"
  }, [_vm._v(" Fields that are available and will be displayed on records detail. ")]), _c('button', {
    staticClass: "crm-button"
  }, [_vm._v(" Manage Fields ")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-card p-32"
  }, [_c('h2', {
    staticClass: "crm-main-section-heading text-case-unset my-0",
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v(" Creation Fields ")]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100 mt-2"
  }, [_c('p', {
    staticClass: "crm-card-text my-0"
  }, [_vm._v(" Fields that will be displayed when any user creates a record. ")]), _c('button', {
    staticClass: "crm-button"
  }, [_vm._v(" Manage Fields ")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-card p-32"
  }, [_c('h2', {
    staticClass: "crm-main-section-heading text-case-unset my-0",
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v(" Edit / Preview Fields ")]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100 mt-2"
  }, [_c('p', {
    staticClass: "crm-card-text my-0"
  }, [_vm._v(" Fields that are available and will be displayed on record edit/preview view. ")]), _c('button', {
    staticClass: "crm-button"
  }, [_vm._v(" Manage Fields ")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-card p-32"
  }, [_c('h2', {
    staticClass: "crm-main-section-heading text-case-unset my-0",
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v(" List Fields ")]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100 mt-2"
  }, [_c('p', {
    staticClass: "crm-card-text my-0"
  }, [_vm._v(" To adjust the fields that are displayed on the list view, when viewing the Companies list, click the icon located at the navigation bar and choose \"List settings\". ")])])]);

}]

export { render, staticRenderFns }