<template>
  <div class="crm-main-section DealsSection">
    <div class="d-flex justify-content-between align-items-center w-100 mb-5">
      <h2 class="crm-main-section-heading text-case-unset mb-0">
        System Info
        <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
      </h2>
    </div>
    <div class="crm-card">
      <div class="crm-card-heading">
        <div class="w-100 d-flex justify-content-between align-items-center">
          <div>
            Logs
          </div>
          <button class="crm-button">
            <b-icon icon="file-earmark-arrow-down-fill" font-scale="1.5" />
          </button>
        </div>
      </div>
      <div v-for="data in tableData" :key="data.id" class="d-flex justify-content-between align-items-center crm-innerCard mt-4 w-100">
        <div class="crm-card-heading my-0 w-50">{{data.first}}</div>
        <p class="crm-card-text my-0 w-50" style="white-space: normal;word-break: break-word;">
          {{data.last}}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SystemInfo',
  components: {
    
  },
  inject:['isEmpty'],
  data(){
    return {
      tableData : {
        0: {first: 'OS',last : 'WINNT'},
        1: {first: 'Webserver',last : 'Apache/2.4.54 (Win64) OpenSSL/1.1.1p PHP/8.1.12'},
        2: {first: 'Server Protocol',last : 'HTTP/1.1'},
        3: {first: 'PHP Version',last : '	8.1.12'},
        4: {first: 'Last Cron Run',last : 'N/A'},
        5: {first: 'PHP IMAP Extension',last : 'true'},
        6: {first: 'PHP ZIP Extension',last : 'true'},
        7: {first: 'register_argc_argv',last : 'N/A'},
        8: {first: 'max_input_vars',last : '1000'},
        9: {first: 'upload_max_filesize',last : '40M'},
        10: {first: 'post_max_size',last : '40M'},
        11: {first: 'max_execution_time',last : '120'},
        12: {first: 'memory_limit',last : '512M'},
        13: {first: 'allow_url_fopen',last : 'Enabled'},
        14: {first: 'PHP Executable',last : 'C: / xampp / php / php.EXE'},
        15: {first: 'Installed Version',last : '1.1.6'},
        16: {first: 'CloudFlare',last : 'No'},
        17: {first: 'Installation Path',last : 'C:/xampp/htdocs/concord_crm'},
        18: {first: 'Installation Date',last : '2023-04-28 12:27:50'},
        19: {first: 'Last Updated Date',last : 'N/A'},
        20: {first: 'Current Process User',last : 'Frank.F'},
        21: {first: 'Import Status',last : 'false'},
        22: {first: 'DB Driver Version',last : '10.4.27-MariaDB'},
        23: {first: 'DB Driver',last : 'mysql'},
        24: {first: 'SQL Mode',last : 'ONLY_FULL_GROUP_BY,STRICT_TRANS_TABLES,NO_ZERO_IN_DATE,NO_ZERO_DATE,ERROR_FOR_DIVISION_BY_ZERO,NO_ENGINE_SUBSTITUTION'},
        25: {first: 'DB_CONNECTION',last : 'mysql'},
        26: {first: 'APP_ENV',last : 'production'},
        27: {first: 'APP_URL',last : 'http://crm.voip.develop'},
        28: {first: 'APP_DEBUG',last : 'false'},
        29: {first: 'SANCTUM_STATEFUL_DOMAINS',last : '[ "localhost", "localhost:3000", "127.0.0.1", "127.0.0.1:8000", "::1", "crm.voip.develop" ]'},
        30: {first: 'MAIL_MAILER',last : 'array'},
        31: {first: 'CACHE_DRIVER',last : 'file'},
        32: {first: 'SESSION_DRIVER',last : 'file'},
        33: {first: 'SESSION_LIFETIME',last : '480'},
        34: {first: 'QUEUE_CONNECTION',last : 'sync'},
        35: {first: 'LOG_CHANNEL',last : 'daily'},
        36: {first: 'SETTINGS_DRIVER',last : 'json'},
        37: {first: 'MEDIA_DISK',last : 'local'},
        38: {first: 'FILESYSTEM_DISK',last : 'local'},
        39: {first: 'FILESYSTEM_CLOUD',last : 's3'},
        40: {first: 'BROADCAST_DRIVER',last : 'null'},
        41: {first: 'ENABLE_FAVICON',last : 'false'},
        42: {first: 'HTML_PURIFY',last : 'true'},
        43: {first: 'MAIL_CLIENT_SYNC_INTERVAL',last : 'ThreeMinutes'},
        44: {first: 'SYNC_INTERVAL',last : 'ThreeMinutes'},
        45: {first: 'USER_INVITATION_EXPIRES_AFTER',last : '3'},
        46: {first: 'PREFERRED_DEFAULT_HOUR',last : '8'},
        47: {first: 'PREFERRED_DEFAULT_MINUTES',last : '0'},
        48: {first: 'PREFERRED_DEFAULT_REMINDER_MINUTES',last : '30'},
        49: {first: 'PRUNE_TRASHED_RECORDS_AFTER',last : '30'},
        50: {first: 'MAX_IMPORT_ROWS',last : '	4000'},
      },
    }
  },
  computed: {
    
  },
  methods: {
    
  },
  mounted(){
    
  },
}
</script>

<style>

</style>