var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tdmfWrapper",
    staticStyle: {
      "width": "850px"
    }
  }, [_c('div', {
    class: _vm.position === 'left' ? 'tdmfDivWrap' : 'tdmfDivHidden'
  }, [_vm._t("left")], 2), _c('div', {
    staticClass: "wrap"
  }, [_c('div', {
    style: _vm.position === 'left' ? 'visibility: visible; padding-top: 20%;' : 'visibility: hidden;'
  }, [_c('p', [_vm._v(_vm._s(_vm.position === 'left' ? _vm.text : ''))]), _c('div', {
    class: _vm.position === 'left' ? 'links' : 'linksHidden'
  })]), _c('div', {
    staticClass: "vl",
    style: _vm.bottomline ? 'height: 100%; border-left: 2.4px solid #c5ccd0;' : 'height: 50%; border-left: 2.4px solid #c5ccd0;'
  }), _c('div', {
    staticClass: "linksContainer",
    style: _vm.position === 'right' ? 'visibility: visible; padding-top: 20%;' : 'visibility: hidden;'
  }, [_vm.text ? _c('p', [_vm._v(_vm._s(_vm.text))]) : _vm._e(), _c('div', {
    class: _vm.position === 'right' ? 'links' : 'linksHidden',
    style: _vm.text ? '' : 'margin-top: 55px;'
  })])]), _c('div', {
    class: _vm.position === 'right' ? 'tdmfDivWrap' : 'tdmfDivHidden'
  }, [_c('div', {
    staticClass: "node nodeBasic"
  }, [_vm._t("right")], 2), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showMore,
      expression: "showMore"
    }],
    class: _vm.position === 'right' ? 'links' : 'linksHidden',
    style: _vm.text ? '' : 'margin-top: 55px;'
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showMore,
      expression: "showMore"
    }],
    staticClass: "node nodeBasic"
  }, [_vm.showMore ? _c('MoreInfoNode', {
    attrs: {
      "voipaccount": _vm.voipaccount
    },
    on: {
      "interface": function ($event) {
        return _vm.$emit('change-graph', $event);
      }
    }
  }) : _vm._e()], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }