<template>
  <div class="dialer-settings-main">
    <section class="dialer-settings-section dialer-ivr-setting">
      <!-- <div class="dialer-flex">
        <template v-if="$store.getters.getIsMobile">
          <div class="d-flex align-items-center mb-3 calc-title-width-button">
            <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" style="" @click="$emit('back')" />
            <h2 class="dialer-settings-title w-100 mb-0 text-center">IVR</h2>
          </div>
        </template>
        <h2 v-else class="dialer-settings-title">
          <span class="md-mar-right">IVR</span>
          <b-icon id="ivr-info" icon="info-circle-fill" @click="$modal.show('KbInfoModal',{ kb: 'IVR' })" class="cursor_pointer infoKbIcon" />
        </h2>
        <div>
          <div>
            <button class="newButton" @click="$modal.show('AddMenuModal',{ suggested_name: `IVR MENU ${Object.values(getVoipIVRS).length+1}` })">
              <vb-icon icon="squared-add-icon" height="38px" width="38px" />
              <span class="newButton-textPart position-relative d-flex justify-content-center">
                <vb-spinner v-if="api.add_ivr.send" />
                <template v-else>Add IVR</template>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between md-mar-top md-mar-bottom IVR-settings-2nd-section">
        <span class="dialer-settings-intro newerTextVersion mb-0">
          Improve caller experience with personalised greetings and menu options e.g., press 1 for sales, 2 for support.
        </span>
        <div class="d-flex justify-content-end align-items-center w-50">
          <div v-if="ivrsLength > 1" class="whiteBGinputWithGreyRoundedBorder sm-mar-left seachBox">
            <b-input type="text" placeholder="Search IVR" v-model="filter.ivrs.search" />
          </div>
        </div>
      </div> -->

      <div class="settingsFirstSection">
        <template v-if="$store.getters.getIsMobile">
          <div class="d-flex align-items-center mb-3 calc-title-width-button w-100">
            <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" style="" @click="$emit('back')" />
            <h2 class="dialer-settings-title w-100 mb-0 text-center">IVR</h2>
          </div>
          <div>
            <button class="newButton" @click="$modal.show('AddMenuModal',{ suggested_name: `IVR MENU ${Object.values(getVoipIVRS).length+1}` })">
              <vb-icon icon="squared-add-icon" height="38px" width="38px" />
              <span class="newButton-textPart position-relative d-flex justify-content-center">
                <vb-spinner v-if="api.add_ivr.send" />
                <template v-else>Add IVR</template>
              </span>
            </button>
          </div>
        </template>
        <template v-else>
          <h2 class="dialer-settings-title mb-0 settingsFirstSection-left newer">
            <span class="md-mar-right">IVR</span>
            <b-icon id="ivr-info" icon="info-circle-fill" @click="$modal.show('KbInfoModal',{ kb: 'IVR' })" class="cursor_pointer infoKbIcon" />
          </h2>
          <div class="settingsFirstSection-right">
            <div class="d-flex justify-content-end align-items-center mt-0">
              <div v-if="ivrsLength > 1" class="whiteBGinputWithGreyRoundedBorder sm-mar-left sm-mar-right seachBox">
                <b-input type="text" placeholder="Search IVR" v-model="filter.ivrs.search" />
              </div>
              <button class="newButton" @click="$modal.show('AddMenuModal',{ suggested_name: `IVR MENU ${Object.values(getVoipIVRS).length+1}` })">
                <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                <span class="newButton-textPart position-relative d-flex justify-content-center">
                  <vb-spinner v-if="api.add_ivr.send" />
                  <template v-else>Add IVR</template>
                </span>
              </button>
            </div>
          </div>
        </template>
      </div>
      <div class="settingsSecondSection">
        <span class="dialer-settings-intro newerTextVersion mt-0 mb-0">Improve caller experience with personalised greetings and menu options e.g., press 1 for sales, 2 for support.</span>
      </div>

      <template v-if="ivrsLength == 1 && conditions.allow_single_menu">
        <EditMenu 
          class="mt-20px"
          ref="edit-menu"
          @interface="afterAdding()"  
          :propMenu="firstIVR" 
          :isModal="false" 
          @click-graph="firstIVR.status != '1' ? appNotify({ 
            type: 'danger', 
            message: 'Unable to proceed because this ivr is suspended' 
          }) : $modal.show('GraphModal', { 
            accountcode: firstIVR.voipaccount, 
            invalidInput: '' 
          })" 
        />
      </template>
      <vb-table v-else
        class="IVRTable latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover"
        id="ivr-table" 
        textNoRecord=" "
        :isListEmpty="isEmpty(filterIVR)"
        :loading="api.ivrs.send" 
        :listLength="filterIVR.length"
      >
        <template slot="header">
          <tr>
            <th class="dialer-has-sort">
              <span class="dialer-sorted-by ascending">Label</span>
            </th>
            <th class="dialer-has-sort">
              <span>Number</span>
            </th>
            <th class="dialer-has-sort">
              <span>Menu options</span>
            </th>
            <th class="dialer-has-sort dialer-col-right" v-if="!$store.getters.getIsMobile">
              <!-- <span class="for-flex-column">Flow</span> -->
            </th>
            <th class="dialer-has-sort dialer-col-right">
              <span class="for-flex-column">welcome greetings</span>
            </th>
          </tr>
        </template>
        <template #body="{ start, end }">
          <tr 
            v-for="(menu,index) in filterIVR" 
            v-show="index >= start && index <= end" 
            :key="menu.voipaccount || menu.id" 
            @click="menu.queue_service ? '' : menu.status != '1' ? appNotify({ 
              type: 'danger', 
              message: 'Unable to proceed because this ivr is suspended' 
            }) : $modal.show('EditMenuModal', { 
              menu: menu 
            })" 
            :class="`dialer-row-select ${conditions.more==menu.voipaccount ? 'makeTDverticalAlignTop' : ''}`" 
          >
            <td class="dialer-row-title one">
              <Info class="w-fit-content" v-if="menu.queue_service" :id="menu.id" />
              <Info class="w-fit-content" v-else :id="menu.voipaccount" is_suspended_show />
            </td>
            <td class="dialer-row-title two">
              <div class="d-flex flex-column" v-if="menu.userDid && menu.userDid.length">
                <div class="d-flex align-items-center">
                  <span class="sm-mar-left font-weight-bold min-width-160px">
                    <span class="mr-1">
                      <img class="country-img" width="45px" :src="menu | get_property('userDid[0].did') | number_formater | flag_icon" />
                    </span>
                    {{ menu | get_property('userDid[0].did') | number_formater }}
                  </span>
                  <div class="w-auto dialer-dropdown dialer-dropDown-latest dialer-dropDown-onRight dialer-dropDown-p-1 ml-2" v-if="menu.userDid.length>1" @click.stop="conditions.more=conditions.more==menu.voipaccount?'':menu.voipaccount">
                    <div class="px-2 rounded more-numbers-tag">
                      <small>+ {{ menu.userDid.length-1 }} more</small>
                    </div>
                  </div>
                </div>
                <template v-if="conditions.more==menu.voipaccount">
                  <template v-for="(did, index) in menu.userDid.filter((item,index)=>index>0)" >
                    <div class="mt-2" :key="did.did" v-show="index >= start && index <= end" >
                      <div class="d-flex align-items-center sm-mar-left">
                        <span class="mr-2 d-flex align-items-center">
                          <img class="country-img" width="45px" :src="did.did | number_formater | flag_icon" />
                        </span>
                        {{ did.did | number_formater }}
                      </div>
                    </div>
                  </template>
                </template>
              </div>
            </td>
            <td class="dialer-row-title one-5">{{ menu | get_property('schedules[0].options.length',0) }}</td>
            <td class="dialer-row-title dialer-col-right" v-if="!$store.getters.getIsMobile">
              <b-spinner v-if="menu.queue_service" variant="primary" type="grow" small label="Spinning" />
              <div v-else class="dialer-row-title d-flex align-items-center">
                <span class="newThisDeviceIndicaterTextBox-25-11-2023 h-fit-content ml-0 mr-0 showOnly-OnHover-insideTable" > 
                  Configure
                </span>
                <button 
                  class="dialer-button dialer-button-delete-alt buttonNothing" 
                  type="button" 
                  @click.stop="menu.status != '1' ? appNotify({ 
                    type: 'danger', 
                    message: 'Unable to proceed because this ivr is suspended' 
                  }) : $modal.show('GraphModal', { 
                    accountcode: menu.voipaccount, 
                    invalidInput: '' 
                  })"
                >
                  <vb-icon icon="graph-icon" class="graphIcon" height="40.501" width="41.374" />
                </button>
              </div>
            </td>
            <td class="dialer-col-right three">
              <div class="d-flex justify-content-end">
                <vb-audio-player :design="'minimum'" v-if="!menu.queue_service" :src="menu | welcomgreetingsrc" />
              </div>
            </td>
          </tr>
        </template>
        <template #loading>
          <tr v-for="n in 5" :key="n">
            <td>
              <div class="d-flex">
                <div class="latestShimmerDesign mr-2" style="height:35px;width:35px;"></div>
                <div class="d-flex flex-column justify-content-between">
                  <div class="latestShimmerDesign flex-1" style="height:16px;width:60px"></div>
                  <div class="latestShimmerDesign mt-1" style="height:16px;width:30px"></div>
                </div>
              </div>
            </td>
            <td>
              <div class="latestShimmerDesign" style="height:20px;width:50%;"></div>
            </td>
            <td>
              <div class="d-flex">
                <!-- <div class="latestShimmerDesign mr-2" style="height:20px;min-width:40px;"></div> -->
                <div class="latestShimmerDesign" style="height:20px;width:-webkit-fill-available;"></div>
              </div>
            </td>
            <td>
              <div class="latestShimmerDesign" style="height:20px;width:85px;"></div>
            </td>
            <td class="dialer-col-right">
              <div class="latestShimmerDesign ml-auto" style="height:48px;width:48px;"></div>
            </td>
          </tr>
        </template>
      </vb-table>
      
      <GraphModal @interface="conditions.allow_single_menu=false;afterAdding();" />
      <EditMenuModal @interface="afterAdding()" />
      <AddMenuModal @interface="afterAdding()" />
    </section>
  </div>
</template>

<script>
import EditMenuModal from "../Modals/EditMenuModal.vue";
import AddMenuModal from "../Modals/AddMenuModal.vue";
import GraphModal from "../Modals/GraphModal.vue";
import { VOIP_API, } from "../../utils";
import Info from "../Lists/Info.vue";
import { mapGetters, mapState } from 'vuex';
import { welcomgreetingsrc } from '@/filter';


import EditMenu from "./EditMenu.vue";


const {
  uniqueNamesGenerator,
  adjectives,
  colors,
  animals,
} = require("unique-names-generator");

export default {
  name: "Menus",
  components: {
    GraphModal,
    EditMenuModal,
    AddMenuModal,
    Info,
    EditMenu,
  },
  inject:['isEmpty','getProperty','getIvrs','appNotify'],
  data() {
    return {
      filter: {
        ivrs: {
          search: '',
        },
      },
      conditions: {
        more: '',
        allow_single_menu: true,
      },
      api: {
        ivrs: this.$helperFunction.apiInstance(),
        add_ivr: this.$helperFunction.apiInstance(),
      },
    };
  },
  computed: {
    ...mapState({
      total_ivrs: (state) => state.common.ivr.total
    }),
    ...mapGetters({
      getUserPermissions: 'getUserPermissions',
      getVoipIVRS: 'getVoipIVRS',
      addServiceQueue: 'addServiceQueue'
    }),
    ivrsLength(){ return Object.keys(this.getVoipIVRS).length },
    firstIVR(){ return Object.values(this.getVoipIVRS)?.[0] },
    filterIVR(){
      const search = this.filter.ivrs.search.trim().toLowerCase()
      return [this.addServiceQueue,...Object.values(this.getVoipIVRS)].filter(item=>{
        if(!item) return false
        else if(item.queue_service){
          if(item.type=='ivr') return `${item.name}`.trim().toLowerCase().includes(search)
          else return false
        }
        else return `${item.label} ${item.extn}`.trim().toLowerCase().includes(search)
      })
    },
  },
  filters: {
    welcomgreetingsrc: welcomgreetingsrc,
  },
  methods: {
    fetchIvrs() {
      let vm = this
      vm.api.ivrs.send=true
      vm.getIvrs()
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.ivrs.send=false
        vm.conditions.allow_single_menu=true
      })
    },
    afterAdding() {
      let vm = this
      setTimeout(() => {
        vm.fetchIvrs();
      }, 2 * 1000);
    },
    addivr() {
      let vm = this;
      if (vm.api.add_ivr.send) return;
      const randomName = uniqueNamesGenerator({
        dictionaries: [adjectives, colors, animals],
        separator: " ",
        length: 2,
      });
      vm.$appConfirmation({
        title: "Confirmation",
        message: `Are you sure you want to add IVR`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback({confirm}) {
          if (confirm) {
            vm.api.add_ivr.send = true;
            VOIP_API.endpoints.menu.create({
              name: `${randomName} IVR`,
            }).then(() => {
              vm.appNotify({
                message: "Successfully Added!",
                type: "success",
              })
              vm.afterAdding();
            }).catch((ex) => {
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            }).finally(() => {
              vm.api.add_ivr.send = false;
            });
          }
        },
      });
    },
  },
  activated() {
    this.fetchIvrs();
  },
};
</script>
