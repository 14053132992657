<template>
  <div :class="['content-area d-flex h-100 newChatInterface',applyBackground === true ? 'calling_active' : '']">
    <ChatCopy />
  </div>
</template>

<script>
import ChatCopy from '../components/Chat/index.vue';

export default {
  name: "Chat",
  components: {
    ChatCopy,
  },
  data() {
    return {
      applyBackground: false,
    };
  },
  methods: {
    backgroundAction(event) {
      this.applyBackground = event.value;
    },
  },
};
</script>
