import Vue from "vue";
import App from "./App.vue";
// import VueRouter from "vue-router";
// import routes from "./routes";
import router from "./router";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// import VueAnimated from "@codekraft-studio/vue-animated";
// import vuescroll from "vuescroll";
import store from "./store";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
Vue.use(Loading);

Vue.use(require("vue-chartist"));

import VueToast from "vue-toast-notification";
// Import one of available themes
import "vue-toast-notification/dist/theme-default.css";
//import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast,{
  position: 'bottom-left',
  queue: true,
});
// ------------------
// import VueWindowPortal from "vue-window-portal";
// Vue.use(VueWindowPortal);
import { Plugin } from "vue-fragment";
Vue.use(Plugin);
// import "./filter"; // custome filter
import("./Jitsi"); // Jitsi initializing
// import CustomComponents from './components'
// Vue.use(CustomComponents)
import VB from './index'
Vue.use(VB)
// import("./components"); // global components
// import("./directives"); // global directives
// import { common } from "./mixin";
// Vue.mixin(common); //global mixin
import VEmojiPicker from "v-emoji-picker";
Vue.use(VEmojiPicker);
// import { VueChatEmoji } from "vue-chat-emoji";
// Vue.component("Emoji", VueChatEmoji);
// require("vue-chat-emoji/dist/vue-chat-emoji.min.css");
// ------------------



// moment init--------------------------------
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
momentDurationFormatSetup(moment);
// -------------------------------------------

//____________________________
import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);
//____________________________

//__________________________________
import VueGates from 'vue-gates';
Vue.use(VueGates);
//__________________________________

//__________________________________
// import AudioRecorder from "vue-audio-recorder";

// Vue.use(AudioRecorder);
//__________________________________

//vue-tour -------------------------
import VueTour from 'vue-tour'
require('vue-tour/dist/vue-tour.css')
Vue.use(VueTour)
//----------------------------------


Vue.config.productionTip = false;

library.add(fas, far, fab);
Vue.component("font-awesome-icon", FontAwesomeIcon);

// import VueFastAxios from "vue-fast-axios";
// Vue.use(VueFastAxios);

// var infiniteScroll = require("vue-infinite-scroll");
// Vue.use(infiniteScroll);

import ToggleButton from "vue-js-toggle-button";
Vue.use(ToggleButton);

import CircularCountDownTimer from "vue-circular-count-down-timer";
Vue.use(CircularCountDownTimer);
import FlowyPlugin from "@hipsjs/flowy-vue";
import 'dragula/dist/dragula.css'
import "@hipsjs/flowy-vue/dist/lib/flowy-vue.css";
import { Vue2Dragula } from 'vue2-dragula'

Vue.use(Vue2Dragula, {
  logging: {
    dragHandler: true,
    service: true // to only log methods in service (DragulaService)
  }
});
Vue.use(FlowyPlugin);
// import vuetwemoji from 'vue-twemoji'

// Vue.use(vuetwemoji, {
//   // baseUrl: '/assets/image/emoji/', //can set to local folder of emojis. default: https://twemoji.maxcdn.com/
//   extension: '.png', //.svg, .png
//   className: 'emoji', //custom className for image output
//   size: '36x36' //image size
// })
// import DisableAutocomplete from 'vue-disable-autocomplete';

// Vue.use(DisableAutocomplete);
//Router
// Vue.use(VueRouter);
//Custom scroll
// Vue.use(vuescroll);

// Vue.use(VueAnimated, {
//   functional: true,
// });
//Install BootstrapVue
Vue.use(BootstrapVue);
//Install Bootstrap icon component plugin (Optional)
Vue.use(IconsPlugin);

import VueOnlineProp from "vue-online-prop";
Vue.use(VueOnlineProp);

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

// import VueConfirmDialog from "vue-confirm-dialog";
// Vue.use(VueConfirmDialog);

// vue js modal plugin
import VModal from "vue-js-modal";
Vue.use(VModal);
// import VueChatScroll from "vue-chat-scroll";
// Vue.use(VueChatScroll);
// Vue.use(require("vue-shortkey"));

import VueHotkey from "v-hotkey";

import 'vue-search-select/dist/VueSearchSelect.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';

Vue.use(VueHotkey);

import VueObserveVisibility from 'vue-observe-visibility';
Vue.use(VueObserveVisibility);

// import * as Sentry from "@sentry/vue";
// Sentry.init({
//   Vue,
//   dsn: process.env.VUE_APP_SENTRY_DSN,
//   integrations: [
//     new Sentry.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//     }),
//     new Sentry.Replay(),
//   ],
//   tracesSampleRate: 1.0,
// });

// import Tracker from '@openreplay/tracker';
// import trackerAssist from '@openreplay/tracker-assist';
// const tracker = new Tracker({
//   projectKey: "DtEqbcgLM2oEiXhzgRvL",
//   ingestPoint: "https://openreplay.duplex.network/ingest",
// });
// tracker.use(trackerAssist({}));
// tracker.start();

new Vue({
  name: 'dialer-app',
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

