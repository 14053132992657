var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.openedFromModal ? [_c('div', {
    staticClass: "settingsFirstSection pt-3"
  }, [_c('h2', {
    staticClass: "dialer-settings-title mb-0 settingsFirstSection-left newer"
  }, [_c('vb-svg', {
    staticClass: "cursor_pointer_no_hover",
    attrs: {
      "name": "leftLongArrow-icon",
      "width": "20",
      "height": "12",
      "viewBox": "0 0 30 16",
      "stroke-width": "1",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('closeModal');
      }
    }
  }), _c('span', {
    staticClass: "md-mar-left"
  }, [_vm._v("Call recording")])], 1), _c('div', {
    staticClass: "settingsFirstSection-right"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end align-items-center mt-0"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder sm-mar-left sm-mar-right seachBox"
  }, [_c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search user , extension"
    },
    model: {
      value: _vm.filter.call_recordings.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.call_recordings, "search", $$v);
      },
      expression: "filter.call_recordings.search"
    }
  })], 1), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    class: {
      'for-disable': !_vm.getUserPermissions.multi_moh
    },
    attrs: {
      "title": _vm.getUserPermissions.multi_moh ? '' : 'Your package does not support this feature'
    }
  }, [_c('button', {
    staticClass: "newButton",
    attrs: {
      "disabled": !_vm.getUserPermissions.multi_moh
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('StorageModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Call recording storage")])], 1)])])])])] : _vm._e(), _c('div', {
    class: `dialer-settings-main ${_vm.openedFromModal ? 'p-0 b-0' : ''}`
  }, [_c('section', {
    class: `dialer-settings-section dialer-callRecording-settings ${_vm.openedFromModal ? 'p-0' : ''}`
  }, [!_vm.openedFromModal ? [_vm.getIsMobile ? _c('div', {
    staticClass: "d-flex align-items-center justify-content-between flex-column"
  }, [_c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-start calc-title-width-button"
  }, [_c('b-icon', {
    staticClass: "settings-back-icon cursor_pointer",
    attrs: {
      "icon": "chevron-left",
      "font-scale": "1.5"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }), _c('h2', {
    staticClass: "dialer-settings-title text-center"
  }, [_vm._v("Call recordings")])], 1), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "w-100 d-flex align-items-center justify-content-end",
    class: {
      'for-disable': !_vm.getUserPermissions.call_recordings_backup
    },
    attrs: {
      "title": _vm.getUserPermissions.call_recordings_backup ? '' : 'Your package does not support this feature'
    }
  }, [_c('button', {
    staticClass: "dialer-button dialer-button-primary",
    attrs: {
      "disabled": !_vm.getUserPermissions.call_recordings_backup
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('StorageModal');
      }
    }
  }, [_c('span', {}, [_vm._v("Call recording storage")])])])]) : _c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_c('h2', {
    staticClass: "dialer-settings-title"
  }, [_vm._v(" Call recordings "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon md-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('KbInfoModal', {
          kb: 'callRecordings'
        });
      }
    }
  })], 1), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    class: {
      'for-disable': !_vm.getUserPermissions.call_recordings_backup
    },
    attrs: {
      "title": _vm.getUserPermissions.call_recordings_backup ? '' : 'Your package does not support this feature'
    }
  }, [_c('button', {
    staticClass: "dialer-button dialer-button-primary",
    attrs: {
      "disabled": !_vm.getUserPermissions.call_recordings_backup
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('StorageModal');
      }
    }
  }, [_c('span', {}, [_vm._v("Call recording storage")])])])])] : _c('div', {
    staticClass: "d-flex align-items-center justify-content-end"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    class: {
      'for-disable': !_vm.getUserPermissions.call_recordings_backup
    },
    attrs: {
      "title": _vm.getUserPermissions.call_recordings_backup ? '' : 'Your package does not support this feature'
    }
  }, [!_vm.openedFromModal ? _c('button', {
    staticClass: "newButton",
    attrs: {
      "disabled": !_vm.getUserPermissions.call_recordings_backup
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('StorageModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Call recording storage")])], 1) : _vm._e()])]), _vm._m(0), _c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again",
    attrs: {
      "isListEmpty": _vm.filterCallRecording.length == 0,
      "listLength": _vm.filterCallRecording.length,
      "loading": _vm.api.call_recordings.send
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('tr', [_c('th', {
          staticClass: "dialer-has-sort"
        }, [_c('span', [_vm._v("NAME")])]), _c('th', {
          staticClass: "dialer-has-sort"
        }, [_c('span', [_vm._v("recording")])]), _c('th', {
          staticClass: "dialer-has-sort dialer-col-right"
        }, [_c('span', [_vm._v("advance")])])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.filterCallRecording, function (user, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: user.account,
            staticClass: "dialer-row-select"
          }, [_c('td', {
            staticClass: "dialer-row-title one"
          }, [_c('Info', {
            staticClass: "w-fit-content",
            attrs: {
              "id": user.account,
              "is_suspended_show": "",
              "name": user.user,
              "sub_info": user.extn,
              "is_blf": false
            }
          })], 1), _c('td', {
            staticClass: "dialer-row-title two"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('vb-icon', {
            staticClass: "noRect svgTextFill-5757575 mr-8px",
            attrs: {
              "icon": _vm._f("filterIcon")(user),
              "height": "30",
              "width": _vm._f("filterIconWidth")(user),
              "id": user.account
            }
          }), _c('b-form-checkbox', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip"
            }],
            ref: `${user.account}-ondeman_check`,
            refInFor: true,
            staticClass: "newerSwitch",
            attrs: {
              "title": _vm.getUserPermissions.call_recording ? '' : 'Your package does not support this feature',
              "switch": "",
              "disabled": _vm.api.update_call_recording.send || !_vm.getUserPermissions.call_recording,
              "checked": user.off != '1'
            },
            on: {
              "change": function ($event) {
                return _vm.updateCallRecordingSetting($event ? 'ondemand' : 'off', user.account);
              }
            }
          }), _c('b-popover', {
            attrs: {
              "target": user.account,
              "triggers": "hover",
              "placement": "top"
            }
          }, [_vm._v(" " + _vm._s(_vm._f("filterIconText")(_vm._f("filterIcon")(user))) + " ")])], 1)]), _c('td', {
            staticClass: "dialer-col-right three"
          }, [_c('b-form-checkbox', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip"
            }],
            ref: `${user.account}-auto_check`,
            refInFor: true,
            staticClass: "newerSwitch",
            attrs: {
              "title": _vm.getUserPermissions.call_recording ? user.off != '1' ? '' : 'Turn on call recording to configure advance call recording option.' : 'Your package does not support this feature',
              "switch": "",
              "disabled": _vm.api.update_call_recording.send || !_vm.getUserPermissions.call_recording || user.off == '1',
              "checked": user.auto == '1'
            },
            on: {
              "change": function ($event) {
                return _vm.updateCallRecordingSetting($event ? 'auto' : 'ondemand', user.account);
              }
            }
          })], 1)]);
        });
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex"
          }, [_c('div', {
            staticClass: "latestShimmerDesign mr-2",
            staticStyle: {
              "height": "35px",
              "width": "35px"
            }
          }), _c('div', {
            staticClass: "d-flex flex-column justify-content-between"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "16px",
              "width": "60px"
            }
          }), _c('div', {
            staticClass: "latestShimmerDesign mt-1",
            staticStyle: {
              "height": "16px",
              "width": "30px"
            }
          })])])]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "21px",
              "width": "90px"
            }
          })]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "21px",
              "width": "90px"
            }
          })])]);
        });
      },
      proxy: true
    }])
  })], 2), _c('StorageModal'), _c('CallRecordingEditModal', {
    on: {
      "latest": function ($event) {
        return _vm.fetchCallRecordings();
      }
    }
  })], 1)], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-center justify-content-between md-mar-top md-mar-bottom callRecording-2nd-section"
  }, [_c('span', {
    staticClass: "dialer-settings-intro newerTextVersion mb-0"
  }, [_vm._v(" Set users to, on-demand or auto call recording, to capture calls for compliance, quality assurance, and training. ")])]);

}]

export { render, staticRenderFns }