var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('info', {
    attrs: {
      "id": _vm.voipaccount,
      "is_blf": false
    }
  }), _vm.info ? [_vm.info.type == 'user' || _vm.info.type == 'team' || _vm.info.type == 'call_queue' ? _c('div', {
    staticClass: "w-100 d-flex justify-content-end"
  }, [_c('span', {
    staticStyle: {
      "position": "relative"
    },
    on: {
      "click": function ($event) {
        return _vm.edit();
      }
    }
  }, [_c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "pencil-fill",
      "variant": "primary"
    }
  })], 1)]) : _vm._e(), _vm.info.type == 'ivr' ? _c('div', {
    staticClass: "w-100 d-flex justify-content-end"
  }, [_c('span', {
    staticStyle: {
      "position": "relative"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('GraphInfoEditModal', {
          accountcode: _vm.voipaccount
        });
      }
    }
  }, [_c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "pencil-fill",
      "variant": "primary"
    }
  })], 1), _c('GraphInfoEditModal', {
    on: {
      "interface": function ($event) {
        return _vm.$emit('interface', $event);
      }
    }
  })], 1) : _vm._e()] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }