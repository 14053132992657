var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('HorizontalLayout', [_c('div', {
    attrs: {
      "slot": "right"
    },
    slot: "right"
  }, [_c('div', {
    staticClass: "btnContainer",
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "slot": "right"
    },
    on: {
      "click": function ($event) {
        return _vm.add();
      },
      "change-graph": function ($event) {
        return _vm.$emit('interface', $event);
      }
    },
    slot: "right"
  }, [_c('b-icon', {
    attrs: {
      "icon": "plus",
      "font-scale": "1.7"
    }
  }), _vm._v(" Add Menu Options ")], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }