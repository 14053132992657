var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-settings-main"
  }, [_c('section', {
    staticClass: "dialer-settings-section dialer-ivr-setting TeamsSettings"
  }, [_c('div', {
    staticClass: "settingsFirstSection"
  }, [_vm.$store.getters.getIsMobile ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-width-button w-100"
  }, [_c('b-icon', {
    staticClass: "settings-back-icon cursor_pointer",
    attrs: {
      "icon": "chevron-left",
      "font-scale": "1.5"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }), _c('h2', {
    staticClass: "dialer-settings-title w-100 mb-0 text-center"
  }, [_vm._v("Teams")])], 1), _c('div', [_c('button', {
    staticClass: "newButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AddTeamModal', {
          suggested_name: `team ${_vm.voip_teams.length + 1}`
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Add team")])], 1)])] : [_c('h2', {
    staticClass: "dialer-settings-title mb-0 settingsFirstSection-left newer"
  }, [_vm._v(" Teams "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon md-mar-left",
    attrs: {
      "id": "ivr-info",
      "icon": "info-circle-fill"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('KbInfoModal', {
          kb: 'teams'
        });
      }
    }
  })], 1), _c('div', {
    staticClass: "settingsFirstSection-right"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end align-items-center mt-0"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder sm-mar-left sm-mar-right seachBox"
  }, [_c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search team"
    },
    model: {
      value: _vm.filter.teams.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.teams, "search", $$v);
      },
      expression: "filter.teams.search"
    }
  })], 1), _c('button', {
    staticClass: "newButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AddTeamModal', {
          suggested_name: `team ${_vm.voip_teams.length + 1}`
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Add team")])], 1)])])]], 2), _vm._m(0), !_vm.$store.getters.getIsMobile ? [_c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover",
    attrs: {
      "textNoRecord": " ",
      "id": "teams-table",
      "isListEmpty": _vm.isEmpty(_vm.filterTeams),
      "listLength": _vm.filterTeams.length,
      "perPage": 5,
      "loading": _vm.api.teams.send,
      "noRecordWholePage": true
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return [_vm._l(_vm.filterTeams, function (team, index) {
          return [_c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: team.id,
            class: `dialer-row-select ${_vm.conditions.more == team.id ? 'makeTDverticalAlignTop' : ''}`,
            on: {
              "click": function ($event) {
                team.queue_service ? '' : team.status != 'active' ? _vm.appNotify({
                  type: 'danger',
                  message: 'Unable to proceed because this team is suspended'
                }) : _vm.$modal.show('EditTeamModal', {
                  team: team
                });
              }
            }
          }, [_c('td', {
            staticClass: "dialer-row-title one"
          }, [team.queue_service ? _c('Info', {
            staticClass: "w-fit-content",
            attrs: {
              "id": team.id,
              "is_blf": false
            }
          }) : _c('Info', {
            staticClass: "w-fit-content",
            attrs: {
              "id": team.real_id,
              "is_blf": false,
              "is_suspended_show": ""
            }
          })], 1), _c('td', {
            staticClass: "dialer-col-left font-weight-bold two"
          }, [team.ringroup && team.ringroup.did && team.ringroup.did[0] ? _c('div', {
            staticClass: "d-flex flex-column"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('span', {
            staticClass: "sm-mar-left font-weight-bold min-width-160px"
          }, [_c('span', {
            staticClass: "mr-1"
          }, [_c('img', {
            staticClass: "country-img",
            attrs: {
              "width": "45px",
              "src": _vm._f("flag_icon")(_vm._f("number_formater")(_vm._f("get_property")(team, 'ringroup.did[0].did')))
            }
          })]), _vm._v(" " + _vm._s(_vm._f("number_formater")(_vm._f("get_property")(team, 'ringroup.did[0].did'))) + " ")]), team.ringroup.did.length > 1 ? _c('div', {
            staticClass: "w-auto dialer-dropdown dialer-dropDown-latest dialer-dropDown-onRight dialer-dropDown-p-1 ml-2",
            on: {
              "click": function ($event) {
                $event.stopPropagation();
                _vm.conditions.more = _vm.conditions.more == team.id ? '' : team.id;
              }
            }
          }, [_c('div', {
            staticClass: "px-2 rounded more-numbers-tag"
          }, [_c('small', [_vm._v("+ " + _vm._s(team.ringroup.did.length - 1) + " more")])])]) : _vm._e()]), _vm.conditions.more == team.id ? [_vm._l(team.ringroup.did.filter(function (item, index) {
            return index > 0;
          }), function (did, index) {
            return [_c('div', {
              directives: [{
                name: "show",
                rawName: "v-show",
                value: index >= start && index <= end,
                expression: "index >= start && index <= end"
              }],
              key: did.did,
              staticClass: "mt-2"
            }, [_c('div', {
              staticClass: "d-flex align-items-center sm-mar-left"
            }, [_c('span', {
              staticClass: "mr-2 d-flex align-items-center"
            }, [_c('img', {
              staticClass: "country-img",
              attrs: {
                "width": "45px",
                "src": _vm._f("flag_icon")(_vm._f("number_formater")(did.did))
              }
            })]), _vm._v(" " + _vm._s(_vm._f("number_formater")(did.did)) + " ")])])];
          })] : _vm._e()], 2) : [_c('span', {
            staticClass: "text-center"
          }, [_vm._v(" - ")])]], 2), !_vm.$store.getters.getIsMobile ? _c('td', {
            staticClass: "dialer-col-right four"
          }, [team.queue_service ? _c('b-spinner', {
            attrs: {
              "variant": "primary",
              "type": "grow",
              "small": "",
              "label": "Spinning"
            }
          }) : _c('div', {
            staticClass: "dialer-row-title three d-flex align-items-center"
          }, [_c('span', {
            staticClass: "newThisDeviceIndicaterTextBox-25-11-2023 h-fit-content ml-0 mr-16px showOnly-OnHover-insideTable"
          }, [_vm._v(" Configure ")]), _c('button', {
            staticClass: "dialer-button dialer-button-delete-alt buttonNothing",
            attrs: {
              "type": "button"
            },
            on: {
              "click": function ($event) {
                $event.stopPropagation();
                team.status != 'active' ? _vm.appNotify({
                  type: 'danger',
                  message: 'Unable to proceed because this team is suspended'
                }) : _vm.$modal.show('TeamsGraphModal', {
                  accountcode: team.ringroup.accountcode
                });
              }
            }
          }, [_c('vb-icon', {
            staticClass: "graphIcon",
            attrs: {
              "icon": "graph-icon",
              "height": "40.501",
              "width": "41.374"
            }
          })], 1)])], 1) : _vm._e(), _c('td', {
            staticClass: "dialer-row-title three"
          }, [!team.queue_service ? _c('div', {
            staticClass: "d-flex"
          }, [_c('a', {
            staticClass: "dialer-link-more text-decoration-underline",
            on: {
              "click": function ($event) {
                $event.stopPropagation();
                return _vm.$modal.show('AssignNumbersTeam', {
                  team: team,
                  type: 'AssignNumbersTeam'
                });
              }
            }
          }, [_vm._v(" " + _vm._s(_vm._f("memberCount")(team.members)) + " users ")])]) : _vm._e()])])];
        })];
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex"
          }, [_c('div', {
            staticClass: "latestShimmerDesign mr-2",
            staticStyle: {
              "height": "35px",
              "width": "35px"
            }
          }), _c('div', {
            staticClass: "d-flex flex-column justify-content-between"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "16px",
              "width": "60px"
            }
          }), _c('div', {
            staticClass: "latestShimmerDesign mt-1",
            staticStyle: {
              "height": "16px",
              "width": "30px"
            }
          })])])]), _c('td', [_c('div', {
            staticClass: "d-flex"
          }, [_c('div', {
            staticClass: "latestShimmerDesign mr-2",
            staticStyle: {
              "height": "20px",
              "min-width": "40px"
            }
          }), _c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "-webkit-fill-available"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "-webkit-fill-available"
            }
          })]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "48px",
              "width": "48px"
            }
          })])]);
        });
      },
      proxy: true
    }, {
      key: "no-record",
      fn: function () {
        return [_c('div', {
          staticClass: "latestGreyBox-9-9-2023 mb-32px"
        }, [_c('div', {
          staticClass: "emptyScreenContainer"
        }, [_vm.GET_LOCAL_SETTING_IS_DARK_MODE ? _c('img', {
          staticClass: "mt-50px",
          attrs: {
            "width": "365px",
            "height": "128px",
            "src": require('@/assets/images/emptyScreenImages/darkMode/teams.png')
          }
        }) : _c('img', {
          staticClass: "mt-50px",
          attrs: {
            "width": "365px",
            "height": "128px",
            "src": require('@/assets/images/emptyScreenImages/teams.png')
          }
        }), _c('div', {
          staticClass: "emptyScreenContainer-heading mt-50px"
        }, [_vm._v(" Create your first team ")]), _c('div', {
          staticClass: "emptyScreenContainer-text w-75"
        }, [_vm._v(" Teams help you organize and collaborate efficiently. Create your first team to get started. ")]), _c('button', {
          staticClass: "dialer-button dialer-button-primary mt-20px",
          on: {
            "click": function ($event) {
              return _vm.$modal.show('AddTeamModal', {
                suggested_name: `team ${_vm.voip_teams.length + 1}`
              });
            }
          }
        }, [_c('b-icon', {
          staticClass: "mr-3",
          attrs: {
            "icon": "plus-lg"
          }
        }), _vm._v(" Create team ")], 1)])])];
      },
      proxy: true
    }], null, false, 1767660515)
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    staticClass: "dialer-sorted-by ascending"
  }, [_vm._v("Name")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    staticClass: "dialer-sorted-by ascending"
  }, [_vm._v("Numbers")])]), !_vm.$store.getters.getIsMobile ? _c('th', {
    staticClass: "dialer-has-sort dialer-col-right"
  }) : _vm._e(), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Members")])])])])], 2)] : _vm._e(), _vm.$store.getters.getIsMobile ? [_c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover smallerFontSizes",
    attrs: {
      "textNoRecord": " ",
      "id": "teams-table",
      "isListEmpty": _vm.isEmpty(_vm.filterTeams),
      "listLength": _vm.filterTeams.length,
      "perPage": 5,
      "loading": _vm.api.teams.send,
      "noRecordWholePage": true
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref2) {
        var start = _ref2.start,
          end = _ref2.end;
        return [_vm._l(_vm.filterTeams, function (team, index) {
          return [_c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: team.id,
            class: `dialer-row-select ${_vm.conditions.more == team.id ? 'makeTDverticalAlignTop' : ''}`,
            on: {
              "click": function ($event) {
                team.queue_service ? '' : team.status != 'active' ? _vm.appNotify({
                  type: 'danger',
                  message: 'Unable to proceed because this team is suspended'
                }) : _vm.$modal.show('EditTeamModal', {
                  team: team
                });
              }
            }
          }, [_c('td', {
            staticClass: "dialer-row-title one"
          }, [team.queue_service ? _c('Info', {
            staticClass: "w-fit-content",
            attrs: {
              "id": team.id,
              "is_blf": false
            }
          }) : _c('Info', {
            staticClass: "w-fit-content",
            attrs: {
              "id": team.real_id,
              "is_blf": false,
              "is_suspended_show": ""
            }
          })], 1), _c('td', {
            staticClass: "dialer-col-left font-weight-bold"
          }, [team.ringroup && team.ringroup.did && team.ringroup.did[0] ? _c('div', {
            staticClass: "d-flex flex-column"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('span', {
            staticClass: "sm-mar-left font-weight-bold min-width-160px"
          }, [_c('span', {
            staticClass: "mr-1"
          }, [_c('img', {
            staticClass: "country-img",
            attrs: {
              "width": "45px",
              "src": _vm._f("flag_icon")(_vm._f("number_formater")(_vm._f("get_property")(team, 'ringroup.did[0].did')))
            }
          })]), _vm._v(" " + _vm._s(_vm._f("number_formater")(_vm._f("get_property")(team, 'ringroup.did[0].did'))) + " ")]), team.ringroup.did.length > 1 ? _c('div', {
            staticClass: "w-auto dialer-dropdown dialer-dropDown-latest dialer-dropDown-onRight dialer-dropDown-p-1 ml-2",
            on: {
              "click": function ($event) {
                $event.stopPropagation();
                _vm.conditions.more = _vm.conditions.more == team.id ? '' : team.id;
              }
            }
          }, [_c('div', {
            staticClass: "px-2 rounded more-numbers-tag"
          }, [_c('small', [_vm._v("+ " + _vm._s(team.ringroup.did.length - 1) + " more")])])]) : _vm._e()]), _vm.conditions.more == team.id ? [_vm._l(team.ringroup.did.filter(function (item, index) {
            return index > 0;
          }), function (did, index) {
            return [_c('div', {
              directives: [{
                name: "show",
                rawName: "v-show",
                value: index >= start && index <= end,
                expression: "index >= start && index <= end"
              }],
              key: did.did,
              staticClass: "mt-2"
            }, [_c('div', {
              staticClass: "d-flex align-items-center sm-mar-left"
            }, [_c('span', {
              staticClass: "mr-2 d-flex align-items-center"
            }, [_c('img', {
              staticClass: "country-img",
              attrs: {
                "width": "45px",
                "src": _vm._f("flag_icon")(_vm._f("number_formater")(did.did))
              }
            })]), _vm._v(" " + _vm._s(_vm._f("number_formater")(did.did)) + " ")])])];
          })] : _vm._e()], 2) : [_c('span', {
            staticClass: "text-center"
          }, [_vm._v(" - ")])]], 2), _c('td', {
            staticClass: "dialer-row-title three dialer-col-left"
          }, [!team.queue_service ? _c('div', {
            staticClass: "d-flex flex-row justify-content-end text-right"
          }, [_c('a', {
            staticClass: "dialer-link-more text-decoration-underline text-right",
            on: {
              "click": function ($event) {
                $event.stopPropagation();
                return _vm.$modal.show('AssignNumbersTeam', {
                  team: team,
                  type: 'AssignNumbersTeam'
                });
              }
            }
          }, [_vm._v(" " + _vm._s(_vm._f("memberCount")(team.members)) + " users ")])]) : _vm._e()])])];
        })];
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex"
          }, [_c('div', {
            staticClass: "latestShimmerDesign mr-2",
            staticStyle: {
              "height": "35px",
              "width": "35px"
            }
          }), _c('div', {
            staticClass: "d-flex flex-column justify-content-between"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "16px",
              "width": "60px"
            }
          }), _c('div', {
            staticClass: "latestShimmerDesign mt-1",
            staticStyle: {
              "height": "16px",
              "width": "30px"
            }
          })])])]), _c('td', [_c('div', {
            staticClass: "d-flex"
          }, [_c('div', {
            staticClass: "latestShimmerDesign mr-2",
            staticStyle: {
              "height": "20px",
              "min-width": "40px"
            }
          }), _c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "-webkit-fill-available"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "-webkit-fill-available"
            }
          })]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "48px",
              "width": "48px"
            }
          })])]);
        });
      },
      proxy: true
    }, {
      key: "no-record",
      fn: function () {
        return [_c('div', {
          staticClass: "latestGreyBox-9-9-2023 mb-32px"
        }, [_c('div', {
          staticClass: "emptyScreenContainer"
        }, [_vm.GET_LOCAL_SETTING_IS_DARK_MODE ? _c('img', {
          staticClass: "mt-50px",
          attrs: {
            "width": "365px",
            "height": "128px",
            "src": require('@/assets/images/emptyScreenImages/darkMode/teams.png')
          }
        }) : _c('img', {
          staticClass: "mt-50px",
          attrs: {
            "width": "365px",
            "height": "128px",
            "src": require('@/assets/images/emptyScreenImages/teams.png')
          }
        }), _c('div', {
          staticClass: "emptyScreenContainer-heading mt-50px"
        }, [_vm._v(" Create your first team ")]), _c('div', {
          staticClass: "emptyScreenContainer-text w-75"
        }, [_vm._v(" Teams help you organize and collaborate efficiently. Create your first team to get started. ")]), _c('button', {
          staticClass: "dialer-button dialer-button-primary mt-20px"
        }, [_c('b-icon', {
          staticClass: "mr-3",
          attrs: {
            "icon": "plus-lg"
          }
        }), _vm._v(" Create team ")], 1)])])];
      },
      proxy: true
    }], null, false, 3751166245)
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    staticClass: "dialer-sorted-by ascending"
  }, [_vm._v("Name")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    staticClass: "dialer-sorted-by ascending"
  }, [_vm._v("Numbers")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Members")])])])])], 2)] : _vm._e(), _c('EditTeamModal', {
    on: {
      "interface": function ($event) {
        return _vm.afterAdding();
      }
    }
  })], 2), _c('GraphModal', {
    attrs: {
      "modalName": "TeamsGraphModal"
    }
  }), _c('AddTeamModal', {
    on: {
      "interface": function ($event) {
        return _vm.afterAdding();
      }
    }
  }), _c('AddRemoveFromList', {
    attrs: {
      "modalName": "AssignNumbersTeam"
    },
    on: {
      "update-list": function ($event) {
        return _vm.afterAdding();
      }
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "settingsSecondSection"
  }, [_c('span', {
    staticClass: "dialer-settings-intro newerTextVersion mt-0 mb-0"
  }, [_vm._v("Organize users into teams to streamline communication for specific departments or skills, like sales, support, and accounts. Each team will group users together and can have its own calling rules.")])]);

}]

export { render, staticRenderFns }