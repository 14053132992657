var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-900",
    attrs: {
      "width": "50%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    }
  }, [_c('PrivacySettings', {
    attrs: {
      "openedFromModal": true
    },
    on: {
      "closeModal": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }