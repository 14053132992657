var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-main-section DealsSection"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100 mb-5"
  }, [_c('h2', {
    staticClass: "crm-main-section-heading text-case-unset mb-0"
  }, [_vm._v(" Products "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1)]), _c('div', {
    staticClass: "crm-card p-32"
  }, [_vm._m(0), _vm._m(1), _c('b-form-group', {
    staticClass: "crm-radioBTNs-container",
    attrs: {
      "label": "Do you sell your products at rates inclusive of Tax?"
    }
  }, [_c('b-form-radio', {
    attrs: {
      "name": "some-radios",
      "value": "A"
    }
  }, [_c('span', {
    staticClass: "crm-radio-text"
  }, [_vm._v("Tax Exclusive")])]), _c('b-form-radio', {
    attrs: {
      "name": "some-radios",
      "value": "B"
    }
  }, [_c('span', {
    staticClass: "crm-radio-text"
  }, [_vm._v("Tax Inclusive")])]), _c('b-form-radio', {
    attrs: {
      "name": "some-radios",
      "value": "B"
    }
  }, [_c('span', {
    staticClass: "crm-radio-text"
  }, [_vm._v("No Tax")])])], 1), _c('b-form-group', {
    staticClass: "crm-radioBTNs-container",
    attrs: {
      "label": "Default discount type"
    }
  }, [_c('b-form-radio', {
    attrs: {
      "name": "some-radios",
      "value": "A"
    }
  }, [_c('span', {
    staticClass: "crm-radio-text"
  }, [_vm._v("PKR")])]), _c('b-form-radio', {
    attrs: {
      "name": "some-radios",
      "value": "B"
    }
  }, [_c('span', {
    staticClass: "crm-radio-text"
  }, [_vm._v("%")])])], 1), _c('button', {
    staticClass: "crm-button mt-4"
  }, [_vm._v(" Save ")])], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('h2', {
    staticClass: "crm-main-section-heading text-case-unset my-0",
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v(" Products ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "w-50 pr-3"
  }, [_c('div', {
    staticClass: "crm-card-heading mb-1 mt-4 justify-content-start"
  }, [_c('span', {
    staticClass: "mr-1",
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v("*")]), _vm._v(" Tax Label")]), _c('div', {
    staticClass: "crm-input-container withoutIcon mb-0"
  }, [_c('input', {
    staticClass: "w-100"
  })])]), _c('div', {
    staticClass: "w-50 pl-3"
  }, [_c('div', {
    staticClass: "crm-card-heading mb-1 mt-4"
  }, [_vm._v("Tax Rate")]), _c('div', {
    staticClass: "crm-input-container withoutIcon mb-0"
  }, [_c('input', {
    staticClass: "w-100",
    attrs: {
      "placeholder": "Tax Percent"
    }
  })])])]);

}]

export { render, staticRenderFns }