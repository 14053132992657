var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-100"
  }, [_vm.addTask ? _c('div', {
    staticClass: "TODO-taksTable-bodyRow thisIsAddTaskRow mt-12px",
    staticStyle: {
      "min-height": "auto"
    }
  }, [_vm.conditions.add_task ? [_c('b-form', {
    staticClass: "d-flex w-100",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.createTask();
      }
    }
  }, [_c('div', {
    staticClass: "TODO-taksTable-bodyRow-column align-items-start py-2 justify-content-start flex-1 w-100-260px"
  }, [_c('div', {
    staticClass: "d-flex flex-column whiteBGinputWithGreyRoundedBorder w-100-200px seachBox"
  }, [_c('b-form-input', {
    directives: [{
      name: "focus",
      rawName: "v-focus"
    }],
    staticClass: "bg-white w-100",
    attrs: {
      "type": "text",
      "placeholder": "Add title",
      "disabled": _vm.api.create_task.send,
      "maxLength": _vm.$v.forms.create_task.title.$params.maxLength.max
    },
    model: {
      value: _vm.forms.create_task.title,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_task, "title", $$v);
      },
      expression: "forms.create_task.title"
    }
  }), _vm.forms.create_task.submitted && _vm.$v.forms.create_task.title.$invalid || _vm.api.create_task.validation_errors.title ? _c('p', {
    staticClass: "error-red text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.create_task.title.required ? _c('span', [_vm._v("Title is required")]) : !_vm.$v.forms.create_task.title.minLength ? _c('span', [_vm._v(" Title should be minimum " + _vm._s(_vm.$v.forms.create_task.title.$params.minLength.min) + " character")]) : !_vm.$v.forms.create_task.title.maxLength ? _c('span', [_vm._v(" Title can be maximum " + _vm._s(_vm.$v.forms.create_task.title.$params.maxLength.max) + " character")]) : _vm._e(), _vm._l(_vm.api.create_task.validation_errors.title, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()], 1), _c('div', {
    staticClass: "d-flex flex-column whiteBGinputWithGreyRoundedBorder flex-fill dropdownVersion ml-4"
  }, [_c('b-select', {
    attrs: {
      "placeholder": "Select space",
      "disabled": _vm.api.create_task.send || _vm.api.spaces.send,
      "options": _vm.response.spaces,
      "value-field": "id",
      "text-field": "name"
    },
    model: {
      value: _vm.forms.create_task.space_id,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_task, "space_id", $$v);
      },
      expression: "forms.create_task.space_id"
    }
  }), _vm.forms.create_task.submitted && _vm.$v.forms.create_task.space_id.$invalid || _vm.api.create_task.validation_errors.space_id ? _c('p', {
    staticClass: "error-red"
  }, [!_vm.$v.forms.create_task.space_id.required ? _c('span', [_vm._v("Space is required")]) : _vm._e(), _vm._l(_vm.api.create_task.validation_errors.space_id, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()], 1)]), _c('div', {
    staticClass: "TODO-taksTable-bodyRow-column align-items-start py-2 w-auto"
  }, [_c('div', {
    staticClass: "TODO-taksTable-bodyRow-column-text d-flex justify-content-end w-100"
  }, [_c('b-button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "submit",
      "variant": "primary",
      "disabled": _vm.api.create_task.send
    }
  }, [_c('vb-icon', {
    staticClass: "submitIcon",
    attrs: {
      "icon": "squared-submit-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm._v(" Submit ")])], 1), _c('b-button', {
    staticClass: "ml-3",
    attrs: {
      "disabled": _vm.api.create_task.send,
      "variant": "link",
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.add_task = false;
        _vm.forms.create_task.reset();
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "x-lg",
      "variant": "danger"
    }
  })], 1)], 1)])])] : _c('div', {
    staticClass: "TODO-taksTable-bodyRow-column onlyOne justify-content-start",
    on: {
      "click": function ($event) {
        _vm.conditions.add_task = true;
        _vm.getSpaces();
      }
    }
  }, [_c('div', {
    staticClass: "TODO-taksTable-bodyRow-column-text"
  }, [_c('b-icon', {
    staticClass: "mr-3",
    attrs: {
      "icon": "plus-lg"
    }
  }), _vm._v(" Add task ")], 1)])], 2) : _c('div', {
    staticClass: "TODO-taksTable-bodyRow mt-12px",
    on: {
      "click": function ($event) {
        return _vm.$emit('taskClick');
      }
    }
  }, [_vm.$store.getters.getIsMobile ? [_c('div', {
    staticClass: "TODO-taksTable-bodyRow-column onlyOne justify-content-start",
    style: `border-left: 9px solid ${_vm.priorityColor}`
  }, [_vm.task.spaces ? _c('b-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    style: `color:${_vm.task.spaces.color};`,
    attrs: {
      "icon": "circle-fill",
      "title": _vm.task.spaces.name
    }
  }) : _vm._e(), _c('div', {
    staticClass: "TODO-taksTable-bodyRow-column-text ml-2"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.task, 'title')))])], 1)] : [_c('div', {
    staticClass: "TODO-taksTable-bodyRow-column first",
    style: `border-left: 13px solid ${_vm.priorityColor}`
  }, [_c('div', {
    staticClass: "d-flex flex-column w-100"
  }, [_c('div', {
    staticClass: "mainInfo-section"
  }, [_vm.task.spaces ? _c('b-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    style: `color:${_vm.task.spaces.color};`,
    attrs: {
      "icon": "circle-fill",
      "title": _vm.task.spaces.name
    }
  }) : _vm._e(), _c('div', {
    staticClass: "TODO-taksTable-bodyRow-column-text ml-2"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.task, 'title')))])], 1), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_vm.task.created_by ? _c('div', {
    staticClass: "subInfo-section"
  }, [_c('span', {
    staticClass: "d-flex firstPart align-items-center"
  }, [_c('span', {
    staticClass: "mr-8px"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.task, 'spaces.prefix')) + "#" + _vm._s(_vm._f("get_property")(_vm.task, 'id')))]), _c('span', {
    staticClass: "mr-8px d-flex align-items-center putSmallDotInBefore"
  }, [_vm._v(" created by "), _c('Info', {
    staticClass: "mx-1",
    attrs: {
      "only_name": true,
      "id": _vm.task.created_by
    }
  }), _vm._v(" on " + _vm._s(_vm._f("filter_date_current")(_vm.task.created_at)) + " ")], 1), _vm.task.due_date ? [_c('span', {
    staticClass: "mr-8px putSmallDotInBefore"
  }, [_vm._v(" Due "), _c('span', {
    staticClass: "selectedActive"
  }, [_vm._v(_vm._s(_vm._f("filter_date_current")(_vm.task.due_date)))])])] : _vm._e(), _c('span', {
    staticClass: "mr-16px putSmallDotInBefore"
  }, [_vm._v(" Last updated "), _c('span', {
    staticClass: "selectedActive"
  }, [_vm._v(_vm._s(_vm._f("filter_date_current")(_vm.task.updated_at)))])])], 2), _c('span', {
    staticClass: "d-flex secondPart"
  }, [_c('span', {
    staticClass: "mr-16px d-flex align-items-center"
  }, [_vm.myDay ? _c('b-icon', {
    staticClass: "mr-8px",
    attrs: {
      "icon": "sun-fill",
      "variant": "dark",
      "width": "auto",
      "height": "19px"
    }
  }) : _vm._e(), _vm.task.is_important == '1' ? _c('b-icon', {
    staticClass: "mr-8px",
    attrs: {
      "icon": "exclamation-lg",
      "variant": "dark",
      "width": "auto",
      "height": "19px"
    }
  }) : _vm._e(), _vm.task.note ? _c('b-icon', {
    attrs: {
      "icon": "file-earmark-text-fill",
      "variant": "dark",
      "width": "16px",
      "height": "19px"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "categoryText",
    style: `background-color: ${_vm.task.group ? _vm.task.group.color : ''};`
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.task, 'group.name')))])])]) : _vm._e()])])]), _c('div', {
    staticClass: "TODO-taksTable-bodyRow-column second",
    class: `${_vm.assigned.count > 0 ? 'moreThanThree' : _vm.assigned.array.length == 3 ? 'exactlyThree' : ''}`
  }, [_vm._l(_vm.assigned.array, function (assigned) {
    return _c('vb-avatar', {
      key: assigned.id,
      attrs: {
        "showPopover": true,
        "is_blf": false,
        "id": assigned.assigned_to
      }
    });
  }), _vm.assigned.count ? _c('div', {
    staticClass: "countPlus"
  }, [_vm._v("+ " + _vm._s(_vm.assigned.count))]) : _vm._e()], 2), _c('div', {
    staticClass: "TODO-taksTable-bodyRow-column third",
    style: `background-color: ${_vm.task.status ? _vm.task.status.color : 'transparent'}`
  }, [_vm.task.status ? [_c('div', {
    staticClass: "TODO-taksTable-bodyRow-column-text colorWhite"
  }, [_vm._v(" " + _vm._s(_vm.task.status.title) + " ")])] : _vm._e()], 2), _c('div', {
    staticClass: "TODO-taksTable-bodyRow-column fourth"
  }, [_vm.isMore ? _c('div', {
    staticClass: "filterDD dropdownVersion whiteBGinputWithGreyRoundedBorder"
  }, [_c('b-dropdown', {
    attrs: {
      "no-caret": "",
      "disabled": _vm.api.remove_task.send,
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('vb-icon', {
          staticClass: "mx-auto",
          attrs: {
            "icon": "todo-three-dots-vertical-icon",
            "variant": "dark",
            "width": "6px",
            "height": "27px"
          }
        })];
      },
      proxy: true
    }], null, false, 488352484)
  }, [_c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.removeTask();
      }
    }
  }, [_vm._v("Delete")])], 1)], 1) : _vm._e()])]], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }