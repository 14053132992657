<template>
  <div class="dialer-settings-main">
    <section class="dialer-settings-section MyNumbers">
      <!-- <template v-if="getIsMobile">
        <div class="d-flex align-items-center mb-3 calc-title-width">
          <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" style="" @click="$emit('back')" />
          <h2 class="dialer-settings-title w-100 mb-0 text-center">My numbers</h2>
        </div>
      </template>
      <div v-else class="dialer-flex">
        <div class="d-flex align-items-center">
          <h2 class="dialer-settings-title">
            My numbers
            <b-icon 
              icon="info-circle-fill" 
              @click="$modal.show('KbInfoModal',{ 
                kb: 'myNumbers' 
              })" 
              class="cursor_pointer infoKbIcon md-mar-left" 
            />
          </h2>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between md-mar-top md-mar-bottom">
        <span class="newerTextVersion dialer-settings-intro mb-0" :class="getIsMobile ? 'text-center' : ''">
          View all your telephone numbers and caller IDs.
          <template v-if="getCurrentUser.administrator_account">
            If you want to add or remove telephone numbers, the administrator can do this in the 
            <a @click="$emit('my-numbers')">
              <span class="innerRedBoxwithRoundedBorder-PurpleText"> number section.</span>
            </a>
          </template>
        </span>
        <div class="d-flex justify-content-end align-items-center w-50">
          <div class="whiteBGinputWithGreyRoundedBorder sm-mar-left seachBox">
            <b-input type="text" placeholder="Search number" v-vb-input-number-formate v-model.lazy="filter.numbers.search" />
          </div>
        </div>
      </div> -->

      <div class="settingsFirstSection">
        <template v-if="$store.getters.getIsMobile">
          <div class="d-flex align-items-center mb-3 calc-title-width-button w-100">
            <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" style="" @click="$emit('back')" />
            <h2 class="dialer-settings-title w-100 mb-0 text-center">My numbers</h2>
          </div>
        </template>
        <template v-else>
          <h2 class="dialer-settings-title mb-0 settingsFirstSection-left newer">
            My numbers
            <b-icon 
              icon="info-circle-fill" 
              @click="$modal.show('KbInfoModal',{ 
                kb: 'myNumbers' 
              })" 
              class="cursor_pointer infoKbIcon md-mar-left" 
            />
          </h2>
          <div class="settingsFirstSection-right">
            <div class="d-flex justify-content-end align-items-center mt-0">
              <div class="whiteBGinputWithGreyRoundedBorder sm-mar-left seachBox">
                <b-input type="text" placeholder="Search number" v-model="filter.numbers.search" />
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="settingsSecondSection">
        <span class="newerTextVersion dialer-settings-intro mb-0 mt-0" :class="getIsMobile ? 'text-center' : ''">
          View all your telephone numbers and caller IDs.
          <template v-if="getCurrentUser.administrator_account">
            If you want to add or remove telephone numbers, the administrator can do this in the 
            <a @click="$emit('my-numbers')">
              <span class="innerRedBoxwithRoundedBorder-PurpleText"> number section.</span>
            </a>
          </template>
        </span>
      </div>

      <vb-table 
        class="latestTableDesign-withBlackBorders-again" 
        :isListEmpty="isEmpty(filternumbers)" 
        :listLength="filternumbers.length" 
        :loading="api.fetch_cids.send && api.fetch_dids.send" 
        textNoRecord=" "
      >
        <template #header>
          <tr>
            <th class="dialer-has-sort">
              <span class="dialer-sorted-by ascending">Number</span>
            </th>
            <th class="dialer-has-sort">
              <span>type</span>
            </th>
            <th class="dialer-col-right cursor_pointer">
              <span>Default outgoing numbers</span>
            </th>
            <!-- <th class="dialer-col-right cursor_pointer">
              <span>Actions</span>
            </th> -->
          </tr>
        </template>
        <template #body="{ start, end }">
          <tr v-for="(data, index) in filternumbers" :key="data.id" v-show="index >= start && index <= end">
            <td class="dialer-row-title">
              <div class="d-flex align-items-center">
                <vb-icon class="mr-16px" icon="TelephoneNumbersSettings-table-tdIcon" width="35px" height="35px" />
                <span>{{ data.number | number_formater }}</span>
                <br />
                <span v-if="data.name" class="OwnertaggedVersion mb-0 ml-16px">{{ data.name }}</span>
              </div>
            </td>
            <td class="dialer-row-title">
              {{ data.type=='cid' ? "Outgoing Calls Only" : "Telephone number" }}
              <span v-if="data.type!='cid' && data.user_mode" class="OwnertaggedVersion mb-0 ml-16px">
                Dedicated
              </span>
            </td>
            <td class="dialer-col-right">
              <div class="d-flex align-items-center justify-content-between">
                <span>
                  <span 
                    v-if="data.ringgroupid && !data.user_mode" 
                    @click="$modal.show(`${_uid}-NumberSettingForUserModal`,{ 
                      ringgroupid: data.ringgroupid,
                      members: data.ringgroup_members,
                      number: data.number
                    })" 
                    class="newThisDeviceIndicaterTextBox-25-11-2023 d-flex h-fit-content ml-0 showOnly-OnHover-insideTable"
                  >
                    Configure voicemail and forwarding 
                  </span>
                </span>
                <div class="bigSquareCheckbox">
                  <b-form-checkbox
                    v-if="data.is_cid"
                    :disabled="api.update_cid.send || data.number==$store.state.sip.caller_id" 
                    :checked="data.number==$store.state.sip.caller_id" 
                    @change="updateCID(data.number)"
                    class="dialer_full_width_checkbox circle_btn_checkbox circle_btn_onRight users-lists-item-999 makeCheckBoxesSquare"
                  />
                </div>
              </div>
            </td>
            <!-- <td class="dialer-col-right bigSquareCheckbox">
              <b-button 
                v-if="data.ringgroupid && !data.user_mode" 
                @click="$modal.show(`${_uid}-NumberSettingForUserModal`,{ 
                  ringgroupid: data.ringgroupid
                })"
              >
                Routing
              </b-button>
            </td> -->
          </tr>
        </template>
        <template #loading>
          <tr v-for="n in 5" :key="n">
            <td>
              <div class="latestShimmerDesign" style="height:20px;width:130px;"></div>
            </td>
            <td>
              <div class="latestShimmerDesign" style="height:20px;width:150px;"></div>
            </td>
            <td class="dialer-col-right">
              <div class="latestShimmerDesign" style="height:25px;width:25px;"></div>
            </td>
          </tr>
        </template>
      </vb-table>
    </section>
    <NumberSettingForUserModal :modalName="`${_uid}-NumberSettingForUserModal`" />
  </div>
</template>

<script>
import { VOIP_API } from "../../utils";
import _ from "lodash";
import { mapGetters } from 'vuex';
import { number_formater } from '@/filter';
import NumberSettingForUserModal from '../Modals/NumberSettingForUserModal.vue';
export default {
  name: 'Numbers',
  components: { 
    NumberSettingForUserModal, 
  },
  inject:['isEmpty','appNotify'],
  data() {
    return {
      response: {
        cid: {},
        did: {},
      },
      api: {
        fetch_cids: this.$helperFunction.apiInstance(),
        fetch_dids: this.$helperFunction.apiInstance(),
        update_cid: this.$helperFunction.apiInstance(),
      },
      filter: {
        numbers: {
          search: '',
          type: '',
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getIsMobile',
    ]),
    filternumbers() {
      const search = this.filter.numbers.search
      return _.orderBy(_.uniq([...Object.keys(this.response.did),...Object.keys(this.response.cid)]).map((key)=>{
        const res = {
          number: key,
          name: this.response.cid?.[key]?.number_labels?.name,
          is_cid: true,
          type: 'cid',
          id: key,
          ringgroupid: '',
          user_mode: false,
          ringgroup_members: [],
        }
        if(this.response.did[key] && this.response.cid[key]){
          res.type='both'
        } else if(this.response.did[key]){
          res.type='did'
          res.is_cid=false
        } 
        if(this.response.did[key]) {
          res.ringgroupid=this.response.did[key].ringgroup_id
          res.user_mode=this.response.did[key].user_mode==1
          res.ringgroup_members=this.response.did[key].ringgroup_members ?? []
        }
        return res
      }), ["type"], ["asc"])
      .filter(item=>{
        return `${number_formater(item.number,'',true)}`.includes(search) || `${number_formater(item.number,'',false)}`.includes(search) ||
        `${number_formater(item.number,'',true)}`.includes(search) || `${number_formater(item.number,'',false)}`.replaceAll(' ','').includes(search)
      })
    },
  },
  methods: {
    async updateCID(number='') {
      try {
        if(!number) return;
        this.api.update_cid.send=true
        await VOIP_API.endpoints.telephone_number.update(this.getCurrentUser?.account, {
          field: "callerid",
          value: number || '666666',
          fid: "12",
        })
        this.appNotify({
          message: 'Successfully updated!',
          type: 'success',
        })
        this.$store.state.sip.caller_id = number;
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.update_cid.send=false
      }
    },
    async fetchCID() {
      try {
        this.api.fetch_cids.send = true;
        const { data: response } = await VOIP_API.endpoints.telephone_number.list({
          account: this.getCurrentUser?.account,
        })
        this.response.cid = _.keyBy(response?.list, 'caller_id') ?? {};
        this.$store.state.sip.caller_id = response?.selected ?? '';
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_cids.send = false;
      }
    },
    async fetchDID() {
      try {
        this.api.fetch_dids.send = true;
        const { data: response } = await VOIP_API.endpoints.telephone_number.assigned_did()
        this.response.did = response?.data?.did ?? {};
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_dids.send = false;
      }
    },
  },
  activated(){
    this.fetchCID();
    this.fetchDID();
  },
};
</script>

