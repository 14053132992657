<template>
  <div>
    <div v-if="false" class="dialer-chatBox-inVideo">
      <div class="dialer-videoCall-addMember">
        <span class="close-popup cursor_pointer" @click="$store.state.calls.videoCalls[listIndex].isMemberOpen = false">
          <b-icon icon="x-circle" scale="1.3"></b-icon>
        </span>
        <h2>Add Members</h2>
        <div v-if="loading">loading...</div>
        <template v-else>
          <div class="dialer-searchBox my-3">
            <input type="text" class="ml-4 form-control w-75" v-model="search" placeholder="search user, Extension" />
          </div>
          <!-- <div>
            <div v-for="user in membersUsers" :key="user.account">
              <Info :id="user.account" />
            </div>
            <div v-for="user in requestSendedUsers" :key="user.account">
              <Info :id="user.account" />
            </div>
          </div> -->
          <div class="ul-items-container">
            <ul class="items h-auto">
              <li v-for="user in membersUsers" :key="user.account" class="item">
                <Info :id="user.account" />
              </li>
              <li v-for="user in requestSendedUsers" :key="user.account" class="item">
                <Info :id="user.account" />
              </li>
            </ul>
            <ul class="items">
              
              <li v-for="user in filterUsers" :key="user.account" v-show="user.account != $store.getters.getCurrentUser.account" class="item">
                <Info :id="user.account" />
                <div class="actions">
                  <p v-if="user.isRequestSended">Time left: {{ user.time }}</p>
                  <p v-if="user.isMember">Already member</p>
                  <template v-else>
                    <div v-if="!user.isRequestSended" @click="call(user.account)" class="success">
                      <b-icon icon="telephone-fill" />
                    </div>
                    <div v-else @click="cancel(user.account)" class="danger">
                      <b-icon icon="telephone-fill" />
                    </div>
                  </template>
                </div>
              </li>
            </ul>
          </div>
        </template>
      </div>
    </div>
    <!-- <button @click="$modal.show(`${room}-CreateChannelModal`)">open modal</button> -->
    <modal
      :name="`${room}-CreateChannelModal`"
      class="CreateChanneFromUserslModal DuringVideoCall"
      @before-close="$emit('closeModal')"
    >
      <div class="topBar-withCloseIcon mt-0">
        <h3 class="dialer-box-header w-100 mb-0">Add Members</h3>
        <button type="button" class="newCloseButton" @click="$modal.hide(`${room}-CreateChannelModal`)">
          <vb-icon icon="squared-close-icon" height="38px" width="38px" />
          <span class="newCloseButton-textPart">Close</span>
        </button> 
      </div>
      <div class="searchBox">
        <b-form @submit.prevent="''" class="position-relative">
          <b-form-input  v-model="search" placeholder="search user, Extension" class="w-100"></b-form-input>
          <b-icon icon="search" scale="1" class="position-absolute cursor_pointer searchIcon"/>
        </b-form>
      </div>
      <div class="addable-memberLisit">
        <ul class="d-flex list-unstyled">
          <li v-for="user in membersUsers" :key="user.account" class="d-inline-flex flex-column align-items-center pt-1 px-2">
            <div class="user-avatar d-block avatar rounded-circle position-relative">
              <img :src="user.profile_img" @error="$event.target.src = userDPMale"/>
            </div>
            <small class="member-name mt-1">{{ user.name | get_first_name }}</small>
          </li>
          <li v-for="user in requestSendedUsers" :key="user.account" class="d-inline-flex flex-column align-items-center pt-1 px-2">
            <div class="user-avatar d-block avatar rounded-circle position-relative">
              <span  class="member-close position-absolute right-0 top-0"
                @click="cancel(user.account)"
              >
                <b-icon icon="x-circle-fill"></b-icon>
              </span>
              <img :src="user.profile_img" @error="$event.target.src = userDPMale"/>
            </div>
            <small class="member-name mt-1">{{ user.name | get_first_name }}</small>
          </li>
        </ul>
      </div>
      <ul class="AvailableUsersList">
        <li 
          v-for="user in filterUsers" :key="user.account"
          v-show="user.account != $store.getters.getCurrentUser.account" 
          class="AvailableUsersList-item"
          @click="getProperty(user,'user.user_chat_id')?$set(selected_users, user.user.user_chat_id, true):''"
        >
          <Info :id="user.account" :is_suspended_show="false" :is_blf="false" />
          <div class="actions d-flex align-items-center">
            <p v-if="user.isRequestSended" class="mb-0 mr-2">Time left: {{ user.time }}</p>
            <p v-if="user.isMember" class="mb-0 mr-2">Already member</p>
            <template v-else>
              <div v-if="!user.isRequestSended" @click="call(user.account)" class="success">
                <!-- <b-icon icon="telephone-fill" /> -->
                <b-icon icon="plus-circle-fill" font-scale="1.75" variant="primary" />
              </div>
              <div v-else @click="cancel(user.account)" class="danger">
                <!-- <b-icon icon="telephone-fill" /> -->
                <b-icon icon="dash-circle-fill" font-scale="1.75" variant="danger" />
              </div>
            </template>
          </div>
        </li>
      </ul>


      <!-- <div class="d-flex justify-content-end w-100">
        <button type="button" class="dialer-button dialer-button-primary" @click="onDone">
          Done
        </button>
      </div> -->
    </modal>
  </div>
</template>


<script>
import { VOIP_API } from "../../utils";
// import { centrifugo } from "../../Centrifuge";
import Timer from "timer.js";
import Info from '../Lists/Info.vue';
import { AMI_EVENTS, AMI_SOCKET } from '@/ami';
export default {
  name: "AddMembersInVideoCall",
  components: { 
    Info 
  },
  props: {
    room: {
      type: String,
      default: "",
    },
    listIndex: {
      type: Number,
    },
  },
  inject: [
    'getProperty',
  ],
  data() {
    return {
      user_male: require("@/assets/images/user-male.png"),
      users: {},
      loading: false,
      search: '',
    };
  },
  computed: {
    filterUsers(){
      const search = this.search.trim().toLowerCase()
      return Object.values(this.users).filter(user=>!user.isMember && `${user.name} ${user.extn}`.trim().toLowerCase().includes(search))
    },
    membersUsers(){
      return Object.values(this.users).filter(user=>user.isMember)
    },
    requestSendedUsers(){
      return Object.values(this.users).filter(user=>user.isRequestSended)
    },
  },
  watch: {
    // "$store.state.centrifuge.message": "watchingGetCentrifugeMessage",
  },
  filters: {
    get_first_name(value){
      return value?.split(' ')?.[0] ?? value
    },
  },
  methods: {
    //--watching--
    // watchingGetCentrifugeMessage(message) {
    //   let vm = this;
    //   const { data } = message;
    //   switch (data.type) {
    //     case centrifugeEvents.ACCEPT:
    //       vm.ACCEPT(message);
    //       break;
    //     case centrifugeEvents.REJECTED:
    //       vm.REJECTED(message);
    //       break;
    //     case centrifugeEvents.BUSY:
    //       vm.BUSY(message);
    //       break;
    //     default:
    //       break;
    //   }
    // },
    openModal(){
      this.$modal.show(`${this.room}-CreateChannelModal`)
    },
    //--other--
    async call(account) {
      // console.log('call run')
      let vm = this;
      try {
        if (!this.users[account].isMember) {
          await VOIP_API.endpoints.users.publish({
            method: "publish",
            params: {
              channel: `${account}-in`,
              data: {
                ...this.$store.state.calls.videoCalls[this.listIndex].data,
                type: /*centrifugo.calls.events.INVITE*/AMI_EVENTS.INVITE,
              },
            },
          });
          this.users[account].isRequestSended = true;
          this.users[account].timer = new Timer({
            tick: 1,
            ontick() {
              vm.users[account].time -= 1;
            },
            onstart() {
              vm.users[account].time = 30;
            },
            onend() {
              vm.users[account].isRequestSended = false;
              vm.users[account].time = 0;
              vm.users[account].timer = null;
            },
          });
          this.users[account].timer.start(30);
        }
      } catch (error) {
        // console.log("error", error);
      }
    },
    async cancel(account) {
      // console.log('cancel run')
      try {
        if (!this.users[account].isMember) {
          await VOIP_API.endpoints.users.publish({
            method: "publish",
            params: {
              channel: `${account}-in`,
              data: {
                ...this.$store.state.calls.videoCalls[this.listIndex].data,
                type: /*centrifugo.calls.events.CANCEL*/AMI_EVENTS.CANCEL,
              },
            },
          });
          this.users[account].isRequestSended = false;
          this.users[account].timer.stop();
        }
      } catch (error) {
        // console.log("error", error);
      }
    },
    addMember(account) {
      if (this.users[account]) {
        this.users[account].isMember = true;
      }
    },
    removeMember(account) {
      if (this.users[account]) {
        this.users[account].isMember = false;
      }
    },
    //--event--
    ACCEPT(message) {
      const {
        data: { caller },
      } = message;
      if (this.users[caller]) {
        this.users[caller].timer?.stop();
        this.users[caller].isRequestSended = false;
      }
    },
    REJECTED(message) {
      const {
        data: { caller },
      } = message;
      if (this.users[caller]) {
        this.users[caller].timer?.stop();
        this.users[caller].isRequestSended = false;
      }
    },
    BUSY(message) {
      const {
        data: { caller },
      } = message;
      if (this.users[caller]) {
        this.users[caller].timer?.stop();
        this.users[caller].isRequestSended = false;
      }
    },
  },
  created() {
    AMI_SOCKET.on(AMI_EVENTS.ACCEPT,this.ACCEPT)
    AMI_SOCKET.on(AMI_EVENTS.REJECTED,this.REJECTED)
    AMI_SOCKET.on(AMI_EVENTS.BUSY,this.BUSY)
    // centrifugo.calls.on(centrifugo.calls.events.ACCEPT,this.ACCEPT)
    // centrifugo.calls.on(centrifugo.calls.events.REJECTED,this.REJECTED)
    // centrifugo.calls.on(centrifugo.calls.events.BUSY,this.BUSY)
    this.loading = true;
    const users = this.$store.getters.getVoipUsers;
    for (let account in users) {
      this.$set(this.users, account, {
        profile_img: users[account].profile_img,
        name: users[account].user.display_name,
        extn: users[account].extn,
        isMember: false,
        account: account,
        isRequestSended: false,
        time: 0,
        timer: null,
      });
    }
    this.loading = false;
  },
  beforeDestroy(){
    AMI_SOCKET.off(AMI_EVENTS.ACCEPT,this.ACCEPT)
    AMI_SOCKET.off(AMI_EVENTS.REJECTED,this.REJECTED)
    AMI_SOCKET.off(AMI_EVENTS.BUSY,this.BUSY)
    // centrifugo.calls.off(centrifugo.calls.events.ACCEPT,this.ACCEPT)
    // centrifugo.calls.off(centrifugo.calls.events.REJECTED,this.REJECTED)
    // centrifugo.calls.off(centrifugo.calls.events.BUSY,this.BUSY)
  }
};
</script>




<style lang="scss">
.dialer-chatBox-inVideo {
  background: $white;
  height: 100%;
  .dialer-videoCall-addMember {
    height: 100%;
    padding: 20px 0px;
    .close-popup {
      position: absolute;
      right: 25px;
      top: 15px;
      color: $body-color;
    }
    h2 {
      font-size: 1rem;
      font-weight: 600;
      padding: 0px 20px;
      color: $body-color;
    }
    .items {
      height: calc(100% - 7px);
      overflow-y: auto;
      padding: 0px;
      margin: 0px;
      .item {
        list-style: none;
        display: flex;
        justify-content: space-between;
        padding: 10px 20px;
        .info {
          display: flex;
          img {
            height: inherit;
            width: 40px;
            border-radius: 50%;
          }
          div {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: flex-start;
            height: 100%;
            h5 {
              margin: 0;
            }
            p {
              margin: 0;
            }
          }
        }
        .actions {
          display: flex;
          justify-content: center;
          align-items: center;
          p {
            margin: 0px;
            position: relative;
            top: -15px;
            font-size: 13px;
          }
          div {
            cursor: pointer;
            border-radius: 50%;
            height: 30px;
            width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          div.success {
            background-color: var(--success);
          }
          div.danger {
            background-color: var(--danger);
          }
        }
        &:hover {
          background-color: $gray-050;
          color: black;
        }
      }
    }
  }
}
</style>