<template>
  <div class="wrapper" style="width: 600px; display: flex;">
    <div class="div1" style="width: 30%" />
    <div class="div2" style="width: 40%;">
      <slot name="active-hours" />
      <div class="vl3 yesTextInBefore" style="
        height: 66%;
        border-left: 2.4px solid #c5ccd0;
        position: relative;
        left: 51%;
        margin-left: -3px;  
      "/>
    </div>
    <div class="div3" style="width: 30%">
      <div style="height: 20%;">
        <div style="position: inherit; height: 35%;">
          <p class="noText"  style="margin-left: 30px">No</p>
        </div>
        <hr style="width:40%; height: 0.5px; width: 50%; margin-left: 0px; top: 8%; position: relative; background: #c5ccd0;;">
        <div>
          <div class="vl2" style="
            height: 25%;
            border-left: 2px solid rgb(197, 204, 208);
            position: absolute;
            margin-left: 89px;
            top: 45px;
          "/>
        </div>
      </div>
      <div class="div3Inner" style="
        height: 24%;
        padding: 30px;
        background: rgba(224, 211, 211, 0.48);
        border-radius: 9px;
        margin-top: 27%;
      ">
        <slot name="default" />
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  name: 'OutOfHoursLayoutNode',
}
</script>

<style>

</style>