<template>
  <div :class="`node nodeBasic ${node == 'OutOfHours' ? 'OutOfHours-node' : ''} ${node == 'LineText' ? 'LineText-node' : ''} 
    ${node == 'TeamMember' ? 'TeamMember-node' : ''} ${node == 'NumberAssign' ? 'NumberAssign-node' : ''} ${node == 'NumberSharedWith' ? 'NumberSharedWith-node' : ''}
    ${node == 'DTMF' ? 'DTMF-node' : ''} ${node == 'CallQueueMember' ? 'CallQueueMember-node' : ''} ${node == 'AdvanceOptions' ? 'AdvanceOptions-node' : ''}
    ${node == 'WelcomeGreeting' ? 'WelcomeGreeting-node' : ''}`"
  >
    <div class="node__head is-flex has-space-between is-vcenter">
    <div :style="'display:flex;'">
      <component v-bind="$props" @interface="passdataparent" :is="node" /> 
    </div>
    </div>
  </div>
</template>

<script>
// commom
import Info from './common/InfoNode.vue'
import MoreInfo from './common/MoreInfoNode.vue'
import NumbersList from './common/NumbersListNode.vue'
import OutOfHours from './common/OutOfHours.vue'
import LineText from './common/TextNode.vue'
import CallForwardingIcon from './common/callforwarding/Icon.vue'
import CallForwardingSetting from './common/callforwarding/Setting.vue'
// teams
import TeamMember from './teams/Member.vue'
import TeamMemberIcon from './teams/MemberIcon.vue'
// call queue
import CallQueueMember from './callqueue/Members.vue'
// ivr
import AdvanceOptions from './ivr/AdvanceOptions.vue'
import DTMF from './ivr/DTMF.vue'
import WelcomeGreeting from './ivr/WelcomeGreeting.vue'
// numbers
import NumberAssign from './numbers/Assign.vue'
import NumberAssignIcon from './numbers/AssignIcon.vue'
import NumberSharedWith from './numbers/SharedWith.vue'
// user
import ExtensionsIcon from './users/ExtensionsIcon.vue'
export default {
  name: 'Node',
  components: {
    Info,
    MoreInfo,
    NumbersList,
    OutOfHours,
    ExtensionsIcon,
    LineText,
    CallForwardingIcon,
    CallForwardingSetting,
    TeamMember,
    TeamMemberIcon,
    CallQueueMember,
    AdvanceOptions,
    DTMF,
    WelcomeGreeting,
    NumberAssign,
    NumberAssignIcon,
    NumberSharedWith,
  },
  props: [
    'node',
    'callBack',
    'voipaccount',
    'numbers',
    'text',
    'extensions',
    'dtmf',
    'schedules',
    'ofh_default_setting',
    'forwarding_setting',
    'name',
  ],
  methods: {
    passdataparent(message){
      this.callBack?.(message)
    },
  }
}
</script>

<style lang="scss" scoped>
.node {
  padding: 10px;
  border: 1px solid rgba(128, 128, 128, 0.26);
  border-radius: 5px;

  &__drag-button {
    cursor: grab;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: transparent;
    border: 0;
    border-radius: 50%;

    &:hover {
      background-color: rgba(0, 0, 0, 0.11);
    }
  }

  &__remove-button {
    cursor: pointer;
    background-color: cornflowerblue;
    padding: 5px 10px;
    margin-top: 30px;
    color: white;
    border: 1px solid cornflowerblue;
    border-radius: 3px;

    &hover {
      background-color: rgb(79, 127, 216);
    }
  }
}

.flowy-block.draggable-mirror {
  opacity: 0.6;
}


</style>
