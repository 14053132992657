<template>
  <div class="dialer-settings-main">
    <section class="dialer-settings-section dialer-ivr-setting CallQueueSettings">
      <!-- <div class="dialer-flex">
        <template v-if="getIsMobile">
          <div class="d-flex align-items-center mb-3 calc-title-width-button">
            <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" @click="$emit('back')" />
            <h2 class="dialer-settings-title w-100 mb-0 text-center">Call queue</h2>
          </div>
        </template>
        <h2 v-else class="dialer-settings-title">
          <span class="md-mar-right">Call queue</span>
          <b-icon icon="info-circle-fill" @click="$modal.show('KbInfoModal',{ kb: 'callQueue' })" class="cursor_pointer infoKbIcon md-mar-left" />
        </h2>
        <div>
          <div 
            v-b-tooltip :title="getUserPermissions.call_queue?'':'Your package does not support this feature'"
            :class="{
              'for-disable' : !getUserPermissions.call_queue
            }"
          >
            <button 
              :disabled="!getUserPermissions.call_queue" 
              class="newButton" 
              @click="$modal.show('AddCallQueueModal',{ 
                suggested_name: `call queue ${getVoipCallQueues.length+1}` 
              })" 
            >
              <vb-icon icon="squared-add-icon" height="38px" width="38px" />
              <span class="newButton-textPart">Add call queue</span>
            </button>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between md-mar-top md-mar-bottom IVR-settings-2nd-section">
        <span class="dialer-settings-intro newerTextVersion mb-0">Manage calls with virtual waiting areas. Apply fair distribution, music/messages for callers.</span>
        <div class="d-flex justify-content-end align-items-center w-50">
          <div class="whiteBGinputWithGreyRoundedBorder sm-mar-left seachBox">
            <b-input type="text" placeholder="Search call queue" v-model="filter.call_queues.search" />
          </div>
        </div>
      </div> -->

      <div class="settingsFirstSection">
        <template v-if="$store.getters.getIsMobile">
          <div class="d-flex align-items-center mb-3 calc-title-width-button w-100">
            <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" @click="$emit('back')" />
            <h2 class="dialer-settings-title w-100 mb-0 text-center">Call queue</h2>
          </div>
          <div 
            v-b-tooltip :title="getUserPermissions.call_queue?'':'Your package does not support this feature'"
            :class="{
              'for-disable' : !getUserPermissions.call_queue
            }"
          >
            <button 
              :disabled="!getUserPermissions.call_queue" 
              class="newButton" 
              @click="$modal.show('AddCallQueueModal',{ 
                suggested_name: `call queue ${getVoipCallQueues.length+1}` 
              })" 
            >
              <vb-icon icon="squared-add-icon" height="38px" width="38px" />
              <span class="newButton-textPart">Add call queue</span>
            </button>
          </div>
        </template>
        <template v-else>
          <h2 class="dialer-settings-title mb-0 settingsFirstSection-left newer">
            <span class="md-mar-right">Call queue</span>
            <b-icon icon="info-circle-fill" @click="$modal.show('KbInfoModal',{ kb: 'callQueue' })" class="cursor_pointer infoKbIcon md-mar-left" />
          </h2>
          <div class="settingsFirstSection-right">
            <div class="d-flex justify-content-end align-items-center mt-0">
              <div class="whiteBGinputWithGreyRoundedBorder sm-mar-left sm-mar-right seachBox">
                <b-input type="text" placeholder="Search call queue" v-model="filter.call_queues.search" />
              </div>
              <div 
                v-b-tooltip :title="getUserPermissions.call_queue?'':'Your package does not support this feature'"
                :class="{
                  'for-disable' : !getUserPermissions.call_queue
                }"
              >
                <button 
                  :disabled="!getUserPermissions.call_queue" 
                  class="newButton" 
                  @click="$modal.show('AddCallQueueModal',{ 
                    suggested_name: `call queue ${getVoipCallQueues.length+1}` 
                  })" 
                >
                  <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                  <span class="newButton-textPart">Add call queue</span>
                </button>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="settingsSecondSection">
        <span class="dialer-settings-intro newerTextVersion mt-0 mb-0">Manage calls with virtual waiting areas. Apply fair distribution, music/messages for callers.</span>
      </div>

      <vb-table 
        :isListEmpty="filterCallQueue.length==0" 
        :listLength="filterCallQueue.length" 
        :loading="api.call_queues.send" 
        class="latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover"
      >
        <template slot="header">
          <tr>
            <th class="dialer-has-sort">
              <span class="dialer-sorted-by ascending">Label</span>
            </th>
            <th class="dialer-has-sort">
              <span>Number</span>
            </th>
            <th class="dialer-has-sort dialer-col-right" v-if="!$store.getters.getIsMobile">
            </th>
            <th class="dialer-has-sort dialer-col-right">
              <span>Users</span>
            </th>
          </tr>
        </template>
        <template #body="{ start, end }">
          <tr 
            v-for="(callQueue, index) in filterCallQueue" 
            :key="callQueue.voipaccount || callQueue.id" 
            v-show="index >= start && index <= end" 
            :class="`dialer-row-select ${conditions.more==callQueue.voipaccount ? 'makeTDverticalAlignTop' : ''}`" 
            @click="callQueue.queue_service ? '' : callQueue.status!='1' ? appNotify({ 
              type: 'danger', 
              message: 'Unable to proceed because this call queue is suspended' 
            }) : $modal.show('EditCallQueueModal',{ 
              call_queue: callQueue 
            })"
          >
            <td class="dialer-row-title one">
              <Info class="w-fit-content" :is_suspended_show="!!callQueue.voipaccount" :id="callQueue.voipaccount || callQueue.id" />
            </td>
            <td class="dialer-row-title  two">
              <div class="d-flex flex-column" v-if="callQueue.userDid && callQueue.userDid[0]">
                <div class="d-flex align-items-center">
                  <span class="sm-mar-left font-weight-bold min-width-160px">
                    <span class="mr-1" >
                      <img class="country-img" width="45px" :src="callQueue | get_property('userDid[0].did') | number_formater | flag_icon" />
                    </span>
                    {{ callQueue | get_property('userDid[0].did') | number_formater }}
                  </span>
                  <div 
                    class="w-auto dialer-dropdown dialer-dropDown-latest dialer-dropDown-onRight ml-3 dialer-dropDown-p-1" 
                    v-if="callQueue.userDid.length>1" 
                    @click.stop="conditions.more=conditions.more==callQueue.voipaccount?'':callQueue.voipaccount"
                  >
                    <div class="px-2 rounded more-numbers-tag">
                      <small>+ {{ callQueue.userDid.length-1 }} more</small>
                    </div>
                  </div>
                </div>
                <template v-if="conditions.more==callQueue.voipaccount">
                  <template v-for="(userDid, index) in callQueue.userDid.filter((item,index)=>index>0)" >
                    <div class="mt-2" :key="userDid.did" v-show="index >= start && index <= end" >
                      <div class="d-flex align-items-center sm-mar-left font-weight-bold min-width-160px">
                        <span class="mr-2 d-flex align-items-center">
                          <img  class="country-img" width="45px" :src="userDid.did | number_formater | flag_icon" />
                        </span>
                        {{ userDid.did | number_formater }}
                      </div>
                    </div>
                  </template>
                </template>
              </div>
            </td>
            <td class="dialer-row-title dialer-col-right four" v-if="!getIsMobile">
              <b-spinner v-if="callQueue.queue_service" variant="primary" type="grow" small label="Spinning" />
              <div v-else class="dialer-row-title three d-flex align-items-center">
                <span class="newThisDeviceIndicaterTextBox-25-11-2023 h-fit-content ml-0 mr-16px showOnly-OnHover-insideTable" > 
                  Configure
                </span>
                <button 
                  class="dialer-button dialer-button-delete-alt buttonNothing" 
                  type="button" 
                  @click.stop="callQueue.status!='1' ? appNotify({ 
                    type: 'danger', 
                    message: 'Unable to proceed because this call queue is suspended' 
                  }) : $modal.show('CallQueueGraphModal', { 
                    accountcode: callQueue.voipaccount 
                  })"
                >
                  <vb-icon icon="graph-icon" class="graphIcon" height="40.501" width="41.374" />
                </button>
              </div>
            </td>
            <td class="dialer-row-title dialer-col-right three">
              <div v-if="!callQueue.queue_service" class="d-flex justify-content-end">
                <a 
                  class="dialer-link-more text-decoration-underline" 
                  @click.stop="$modal.show('CallQueueMembersModal', { 
                    call_queue: callQueue, 
                    type: 'CallQueueMembers' 
                  })"
                >
                  {{ callQueue | get_property('members.length',0) }} users
                </a>
              </div>
            </td>
          </tr>
        </template>
        <template #loading>
          <tr v-for="n in 5" :key="n">
            <td>
              <div class="d-flex">
                <div class="latestShimmerDesign mr-2" style="height:35px;width:35px;"></div>
                <div class="d-flex flex-column justify-content-between">
                  <div class="latestShimmerDesign flex-1" style="height:16px;width:60px"></div>
                  <div class="latestShimmerDesign mt-1" style="height:16px;width:30px"></div>
                </div>
              </div>
            </td>
            <td>
              <div class="latestShimmerDesign" style="height:20px;width:-webkit-fill-available;"></div>
            </td>
            <td>
              <div class="d-flex">
                <div class="latestShimmerDesign mt-1" style="height:20px;width:70px"></div>
              </div>
            </td>
            <td class="dialer-col-right">
              <div class="latestShimmerDesign ml-auto" style="height:48px;width:48px;"></div>
            </td>
          </tr>
        </template>
      </vb-table>
    </section>
    <EditCallQueueModal @list-update="fetchCallQueuesDebounce()" />
    <AddCallQueueModal @call-queue-added="fetchCallQueuesDebounce()" />
    <GraphModal modalName="CallQueueGraphModal" @interface="fetchCallQueuesDebounce()" />
    <AddRemoveFromList :modalName="'CallQueueMembersModal'" @update-list="fetchCallQueues();" />
  </div>
</template>

<script>
import EditCallQueueModal from '../Modals/EditCallQueueModal.vue'
import AddCallQueueModal from '../Modals/AddCallQueueModal.vue'
import GraphModal from "../Modals/GraphModal.vue";
import AddRemoveFromList from '../Modals/AddRemoveFromList.vue'
import Info from "../Lists/Info.vue";
import { mapGetters } from 'vuex';
import _ from 'lodash'

export default {
  name: "CallQueues",
  inject:[
    'getCallQueue',
    'appNotify'
  ],
  components: {
    Info,
    EditCallQueueModal,
    AddCallQueueModal,
    GraphModal,
    AddRemoveFromList,
  },
  data() {
    return {
      filter: {
        call_queues: {
          search: '',
        },
      },
      api: {
        call_queues: this.$helperFunction.apiInstance(),
      },
      conditions: {
        more: '',
      },
    };
  },
  computed: {
    ...mapGetters([
      'getUserPermissions',
      'getIsMobile',
      'getVoipCallQueues',
      'addServiceQueue',
    ]),
    filterCallQueue(){
      const search = this.filter.call_queues.search.trim().toLowerCase()
      return [...this.getVoipCallQueues,this.addServiceQueue].filter(item=>{
        if(!item) return false
        else if(item.queue_service){
          if(item.type=='call_queue') return `${item.name}`.trim().toLowerCase().includes(search)
          else return false
        }
        else return `${item.label} ${item.extn}`.trim().toLowerCase().includes(search)
      })
    },
  },
  methods: {
    fetchCallQueuesDebounce: _.debounce(function(){
      this.fetchCallQueues()
    }, 2*1000),
    async fetchCallQueues() {
      try {
        this.api.call_queues.send=true
        await this.getCallQueue()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.call_queues.send=false
      }
    },
  },
  activated() {
    this.fetchCallQueues();
  },
};
</script>
