var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "center-zoomIn-transition sipActiveCall-Modal SelectSnoozeTimeModal CallForwardingNumberInputModal",
    attrs: {
      "transition": "center-zoomIn-transition",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('form', {
    staticClass: "dialer-call-notification-tray w-100",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.onDone();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-call-notification dialer-keypadNotification w-100"
  }, [_c('div', {
    staticClass: "dialer-call-notification-inner d-flex align-items-center justify-content-between w-100 mb-2"
  }, [_c('div', {
    staticClass: "dialer-call-notification-text"
  }, [_c('div', {
    staticClass: "dialer-call-notification-main-text"
  }, [_vm._v(_vm._s(_vm.modalTitle))])]), _c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v("Please enter the number for unanswered call forwarding.")]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion maxHeight150px w-100"
  }, [_c('vb-select', {
    attrs: {
      "options": _vm.countries
    },
    on: {
      "change": function ($event) {
        _vm.forms.number.number = '';
      }
    },
    model: {
      value: _vm.forms.number.country,
      callback: function ($$v) {
        _vm.$set(_vm.forms.number, "country", $$v);
      },
      expression: "forms.number.country"
    }
  })], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100 mt-20px"
  }, [_c('input', {
    directives: [{
      name: "vb-input-number-formate",
      rawName: "v-vb-input-number-formate",
      value: _vm.forms.number.country,
      expression: "forms.number.country"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.forms.number.number,
      expression: "forms.number.number"
    }],
    staticClass: "bg-white w-100",
    attrs: {
      "placeholder": "Forward number",
      "type": "text"
    },
    domProps: {
      "value": _vm.forms.number.number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.number, "number", $event.target.value);
      }
    }
  }), _vm.forms.number.submitted && _vm.$v.forms.number.number.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.number.number.validNumber ? _c('span', [_vm._v("* number is invalid")]) : _vm._e()]) : _vm._e()])]), _c('button', {
    staticClass: "fullWidthDoneButton mt-20px"
  }, [_vm._v(" Update ")])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }