<template>
  <div>
    <div class="newCentralNodeForGraph-container">
      <div :class="`${position === 'left' ? 'tdmfDivWrap' : 'tdmfDivHidden'} leftSide-graph`">
        <slot name="left" />
      </div>
      <div class="centerSide-graph">
        <div class="innerCenter ">
          <div class="verticalLine">
            <div class="topVertical-line"></div>
            <div class="bottomVertical-line"></div>
          </div>
          <div class="horizontalLine ">
            <div class="leftHorizontal-line notShow withText">
              <p v-if="text">{{ text }} </p> 
            </div>
            <div class="rightHorizontal-line withText">
              <p v-if="text">{{ text }} </p> 
            </div>
          </div>
        </div>
      </div>
      <div :class="`rightSide-graph ${position === 'right' ? 'tdmfDivWrap' : 'tdmfDivHidden'}`">
        <div class="node nodeBasic minWidth-230 fixHeight" >
          <slot name="right" />
        </div>
        <div v-show="showMore" class="horizontalLineBeforeMoreInfoNode"></div>
        <div v-show="showMore" class="node nodeBasic minWidth-230 moreInfoNode" >
          <MoreInfoNode v-if="showMore" :voipaccount="voipaccount" @interface="$emit('change-graph',$event)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MoreInfoNode from '../common/MoreInfoNode.vue';
import { mapGetters } from 'vuex';
import _ from 'lodash'
export default {
  name: "horizontalLayoutNode",
  components: {
    MoreInfoNode,
  },
  props:{
    text:{
      type: String,
      default: ''
    },
    position:{
      type: String,
      default: 'right'
    },
    bottomline:{
      type: Boolean
    },
    voipaccount: {
      type: String,
    },
  },
  computed: {
    ...mapGetters([
      'getVoipUsersAlises',
      'getVoipTeams',
      'getVoipCallQueues',
      'getVoipIVRSAlises',
      'getVoipNumbers',
    ]),
    info() {
      const key = this.voipaccount ?? ''
      const user = this.getVoipUsersAlises[key];
      const team = this.getVoipTeams.find(team=>key==team?.ringroup?.accountcode || key==team?.real_id || key==team?.ringroup?.extn)
      const call_queue = this.getVoipCallQueues.find(call_queue=>key==call_queue?.voipaccount || key==call_queue?.extn)
      const ivr = this.getVoipIVRSAlises[key]
      const number = this.getVoipNumbers.find(i=>key==i.real_id)
      if(team) return { data: team, type: 'team' }
      else if(call_queue) return { data: call_queue, type: 'call_queue' }
      else if(ivr) return { data: ivr, type: 'ivr' }
      else if(user) return { data: user, type: 'user' }
      else if(number) return { data: number, type: 'number' }
      else return null;
    },
    members(){
      if(this.info?.type=='team') return _.take(this.info.data?.members?.map?.(i=>i.accountcode),4) ?? []
      else if(this.info?.type=='call_queue') return _.take(this.info.data?.members?.map?.(i=>i.membername),4) ?? []
      else if(this.info?.type=='ivr') return _.take(this.info.data?.schedules?.[0]?.options?.map?.(i=>i.ivr_cmd_args),4) ?? []
      else if(this.info?.type=='number') return _.take(this.info.data?.extensions,4) ?? []
      else return []
    },
    showMore(){ return this.members.length>0 },
  },
}
</script>

<style>
.tdmfDivWrap{
  width: 40%;
  display: flex;
}
.tdmfDivHidden{
  padding: 20px;
  width: 40%;
  visibility: hidden;
}
.tdmfWrapper{
  /* width: 90px; */
  display: flex;
  /* padding: 20px; */
  justify-content: center;
}
.linksHidden{
  padding:0 10px; 
  border-top: 2px solid #c5ccd0; 
  height: 1px;
  line-height: 0.1em;
  position: relative;
  top: 50%;
  visibility: hidden;
}
.separator{
  display: flex;
  align-items: center;
}

.separator h2{
  padding: 0 2rem; /* creates the space */
}

.separator .line{
  flex: 1;
  height: 3px;
  background-color: #000;
}
.wrap {
  text-align: center; 
  /* margin: 20px; */
  position: relative;
  display: flex;
  justify-content: center;    
}

.links { 
  padding:0 10px; 
  border-top: 2px solid #c5ccd0; 
  height: 1px;
  line-height: 0.1em;
  position: initial;
}

.dot {
  width: 20px;
  height: 20px;
  background: red;
  float: left;
  margin-right: 150px;
}
</style>