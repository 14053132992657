<template>
  <div>
    <info :id="voipaccount" :is_blf="false" />
    <template v-if="info">
      <div class="w-100 d-flex justify-content-end" v-if="info.type=='user' || info.type=='team' || info.type=='call_queue'">
        <span style="position: relative;" @click="edit()"><b-icon icon="pencil-fill" variant="primary" class="cursor_pointer"></b-icon></span>
      </div>
      <div class="w-100 d-flex justify-content-end" v-if="info.type=='ivr'">
        <span style="position: relative;" @click="$modal.show('GraphInfoEditModal',{ accountcode: voipaccount, })"><b-icon icon="pencil-fill" variant="primary" class="cursor_pointer"></b-icon></span>
        <GraphInfoEditModal @interface="$emit('interface',$event)" />
      </div>
    </template>
  </div>
</template>

<script>
import Info from '@/components/Lists/Info.vue'
import GraphInfoEditModal from '../../Modals/GraphInfoEditModal.vue'
import { mapGetters } from 'vuex'
export default {
  components: { 
    Info,
    GraphInfoEditModal, 
  },
  props:{
    voipaccount: {
      type: [String,Number]
    },
  },
  computed: {
    ...mapGetters([
      'getVoipUsersAlises',
      'getVoipTeams',
      'getVoipCallQueues',
      'getVoipIVRSAlises',
    ]),
    info() {
      const key = this.voipaccount ?? ''
      const user = this.getVoipUsersAlises[key];
      const team = this.getVoipTeams.find(team=>key==team?.ringroup?.accountcode || key==team?.real_id || key==team?.ringroup?.extn)
      const call_queue = this.getVoipCallQueues.find(call_queue=>key==call_queue?.voipaccount || key==call_queue?.extn)
      const ivr = this.getVoipIVRSAlises[key]
      if(team) return { data: team, type: 'team' }
      else if(call_queue) return { data: call_queue, type: 'call_queue' }
      else if(ivr) return { data: ivr, type: 'ivr' }
      else if(user) return { data: user, type: 'user' }
      else return null;
    },
  },
  methods: {
    edit(){
      this.$emit('interface',{
        from: 'info',
        event: 'edit_modal'
      })
    },
  },
}
</script>

<style>

</style>