<template>
  <CenterHorizontalLayout :nobottom="true" :voipaccount="voipaccount" @change-graph="$emit('interface',$event)">
    <div slot="main" class="node nodeBasic">
      <div class="w-100 d-flex justify-content-between aling-items-center">
        <div class="sharedWithHeading">Shared With</div>
        <vb-icon icon="multipleUsersInsideGraph-icon" height="30px" width="35px"/>
      </div>
      <div class="w-100 sharedWithNumber">{{number | get_property('extensions.length',0)}}</div>
      <div class="w-100 d-flex justify-content-end">
        <b-icon @click="edit()" icon="pencil-fill" variant="primary" />
      </div>
    </div>
  </CenterHorizontalLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import CenterHorizontalLayout from '../layouts/CenterHorizontal.vue'
export default {
  name: 'NumberSharedWithNode',
  components: {
    CenterHorizontalLayout,
  },
  props: {
    voipaccount: {
      type: [String,Number],
      default: ''
    }
  },
  computed: {
    ...mapGetters([
      'getVoipNumbers',
    ]),
    number(){ return this.getVoipNumbers.find(i=>i.real_id==this.voipaccount) },
  },
  methods: {
    edit(){
      // console.log('run')
      this.$emit('interface',{ 
        from: 'numbers_graph_node', 
        event: 'update_shared', 
      })
    },
  },
}
</script>

<style>

</style>