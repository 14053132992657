var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-settings-main"
  }, [_c('section', {
    staticClass: "dialer-settings-section dialer-voicemail-settings mt-0"
  }, [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Voicemail settings")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Manage your voicemail settings here.")]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('UserRecordedVoicemail', {
    staticClass: "s2-mobile",
    attrs: {
      "accountcode": _vm.accountcode
    }
  }), !_vm.isTeam ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column justify-content-center align-items-start"
  }, [_c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center"
  }, [_vm._m(0), _c('div', {
    staticClass: "w-fit-content"
  }, [_vm.api.update_voicemail_setting.send || _vm.api.fetch_settings.send ? _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "34px",
      "width": "60px",
      "border-radius": "25px"
    }
  }) : _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    attrs: {
      "title": _vm.getUserPermissions.enabling_pin_for_voicemail ? '' : 'Your package does not support this feature'
    }
  }, [_c('b-form-checkbox', {
    ref: "password_enable",
    staticClass: "newerSwitch",
    attrs: {
      "disabled": !_vm.getUserPermissions.enabling_pin_for_voicemail || _vm.api.update_voicemail_setting.send || _vm.api.fetch_settings.send,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.updateVoicemailSettingDebounce();
      }
    },
    model: {
      value: _vm.forms.update_voicemail_setting.password_enable,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update_voicemail_setting, "password_enable", $$v);
      },
      expression: "forms.update_voicemail_setting.password_enable"
    }
  })], 1)])]), _vm.forms.update_voicemail_setting.password_enable ? [_c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center mt-20px"
  }, [_c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(_vm._s(_vm.forms.update_voicemail_setting.pin))]), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    class: {
      'for-disable': !_vm.getUserPermissions.enabling_pin_for_voicemail
    },
    attrs: {
      "title": _vm.getUserPermissions.enabling_pin_for_voicemail ? '' : 'Your package does not support this feature'
    }
  }, [_c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "newButton",
    attrs: {
      "title": _vm.getUserPermissions.enabling_pin_for_voicemail ? '' : 'Your package does not support this feature'
    },
    on: {
      "click": function ($event) {
        !_vm.getUserPermissions.enabling_pin_for_voicemail ? '' : _vm.$modal.show(`${_vm._uid}-updatepincode`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-update-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Update pin")])], 1)])])] : _vm._e()], 2) : _vm._e(), !_vm.isTeam ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_vm._m(1), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    class: {
      'for-disable': !_vm.getUserPermissions.voice_mailbox_sharing
    },
    attrs: {
      "title": _vm.getUserPermissions.voice_mailbox_sharing ? '' : 'Your package does not support this feature'
    }
  }, [_c('a', {
    staticClass: "newButton",
    attrs: {
      "disabled": !_vm.getUserPermissions.voice_mailbox_sharing
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        !_vm.getUserPermissions.voice_mailbox_sharing ? '' : _vm.$modal.show('AddUserWithExtensionModal', {
          availableUsers: _vm.response.available_members,
          savedUsers: _vm.response.selected_members,
          type: 'AddUserWithExtensionModal'
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-singleUser-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm.selectedMemebersLength > 0 ? [_vm._v(_vm._s(_vm.selectedMemebersLength))] : [_vm._v(" Add ")], _vm._v(" users ")], 2)], 1)])]) : _vm._e(), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_vm._m(2), _c('div', {
    staticClass: "w-fit-content"
  }, [_vm.api.fetch_notification_settings.send ? _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "34px",
      "width": "60px",
      "border-radius": "25px"
    }
  }) : _c('b-form-checkbox', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    ref: "voicemail_transcript",
    staticClass: "newerSwitch",
    attrs: {
      "title": _vm.getUserPermissions.voice_mail_transcript ? '' : 'Your package does not support this feature',
      "disabled": _vm.api.change_voicemail_transcript.send || !_vm.getUserPermissions.voice_mail_transcript || _vm.api.fetch_notification_settings.send,
      "checked": _vm.data.voicemail_transcript,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.changeVoicemailTranscript($event);
      }
    }
  })], 1)])], 1)])]), _c('AddRemoveFromList', {
    attrs: {
      "accountcode": _vm.accountcode,
      "modalName": "AddUserWithExtensionModal"
    },
    on: {
      "closed": function ($event) {
        return _vm.fetchSettings();
      }
    }
  }), _c('modal', {
    staticClass: "center-zoomIn-transition v2 m-w-500 AddTeamModal",
    attrs: {
      "width": "500px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-updatepincode`
    }
  }, [_c('div', {
    staticClass: "w-100"
  }, [_c('div', {
    staticClass: "dialer-edit-header pt-0 w-100"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Voicemail PIN")])]), _c('div', {
    staticClass: "dialer-edit-actions d-flex align-items-center"
  }, [_c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-updatepincode`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _vm.forms.update_voicemail_setting.password_enable ? _c('div', {
    staticClass: "latestGreyBox-9-9-2023 d-flex align-items-center w-100 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100"
  }, [_c('label', [_vm._v("Pin for voicemail")]), _c('vb-pin-code', {
    attrs: {
      "numberslength": _vm.$v.forms.update_voicemail_setting.pin.$params.minLength.max,
      "value": _vm.forms.update_voicemail_setting.pin,
      "disabled": _vm.api.update_voicemail_setting.send || !_vm.forms.update_voicemail_setting.password_enable || _vm.api.fetch_settings.send
    },
    on: {
      "change": function ($event) {
        _vm.forms.update_voicemail_setting.pin = $event;
      }
    }
  }), _vm.forms.update_voicemail_setting.submitted && _vm.$v.forms.update_voicemail_setting.pin.$invalid || _vm.api.update_voicemail_setting.validation_errors.mailboxpin ? [_vm._l(_vm.api.update_voicemail_setting.validation_errors.mailboxpin, function (em, i) {
    return _c('small', {
      key: i,
      staticClass: "danger field-error bold-4"
    }, [_vm._v(_vm._s(em))]);
  }), !_vm.$v.forms.update_voicemail_setting.pin.required ? _c('small', {
    staticClass: "danger sm-mar-top"
  }, [_vm._v("*Pin is required")]) : _vm._e(), !_vm.$v.forms.update_voicemail_setting.pin.minLength ? _c('small', {
    staticClass: "danger sm-mar-top"
  }, [_vm._v("*Pin should be " + _vm._s(_vm.$v.forms.update_voicemail_setting.pin.$params.minLength.max) + " digit")]) : _vm._e()] : _vm._e()], 2)]) : _vm._e(), _c('div', {
    staticClass: "d-flex justify-content-end mt-20px"
  }, [_c('a', {
    staticClass: "fullWidthDoneButton",
    on: {
      "click": function ($event) {
        return _vm.updateVoicemailSettingDebounce();
      }
    }
  }, [_vm._v(" Update ")])])])])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Voicemail PIN")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Enabling PIN access requires entering a PIN code each time you access your voicemail.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Share voicemail mailbox")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Assign other users to receive your voicemail for improved efficiency.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Transcript my voicemail")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Converts voicemails into text for easy viewing in call activity or email.")])]);

}]

export { render, staticRenderFns }