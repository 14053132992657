var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-settings-main"
  }, [_c('section', {
    staticClass: "dialer-settings-section dialer-ivr-setting"
  }, [_c('div', {
    staticClass: "settingsFirstSection"
  }, [_vm.$store.getters.getIsMobile ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-width-button w-100"
  }, [_c('b-icon', {
    staticClass: "settings-back-icon cursor_pointer",
    attrs: {
      "icon": "chevron-left",
      "font-scale": "1.5"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }), _c('h2', {
    staticClass: "dialer-settings-title w-100 mb-0 text-center"
  }, [_vm._v("IVR")])], 1), _c('div', [_c('button', {
    staticClass: "newButton",
    on: {
      "click": function ($event) {
        _vm.$modal.show('AddMenuModal', {
          suggested_name: `IVR MENU ${Object.values(_vm.getVoipIVRS).length + 1}`
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart position-relative d-flex justify-content-center"
  }, [_vm.api.add_ivr.send ? _c('vb-spinner') : [_vm._v("Add IVR")]], 2)], 1)])] : [_c('h2', {
    staticClass: "dialer-settings-title mb-0 settingsFirstSection-left newer"
  }, [_c('span', {
    staticClass: "md-mar-right"
  }, [_vm._v("IVR")]), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon",
    attrs: {
      "id": "ivr-info",
      "icon": "info-circle-fill"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('KbInfoModal', {
          kb: 'IVR'
        });
      }
    }
  })], 1), _c('div', {
    staticClass: "settingsFirstSection-right"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end align-items-center mt-0"
  }, [_vm.ivrsLength > 1 ? _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder sm-mar-left sm-mar-right seachBox"
  }, [_c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search IVR"
    },
    model: {
      value: _vm.filter.ivrs.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.ivrs, "search", $$v);
      },
      expression: "filter.ivrs.search"
    }
  })], 1) : _vm._e(), _c('button', {
    staticClass: "newButton",
    on: {
      "click": function ($event) {
        _vm.$modal.show('AddMenuModal', {
          suggested_name: `IVR MENU ${Object.values(_vm.getVoipIVRS).length + 1}`
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart position-relative d-flex justify-content-center"
  }, [_vm.api.add_ivr.send ? _c('vb-spinner') : [_vm._v("Add IVR")]], 2)], 1)])])]], 2), _vm._m(0), _vm.ivrsLength == 1 && _vm.conditions.allow_single_menu ? [_c('EditMenu', {
    ref: "edit-menu",
    staticClass: "mt-20px",
    attrs: {
      "propMenu": _vm.firstIVR,
      "isModal": false
    },
    on: {
      "interface": function ($event) {
        return _vm.afterAdding();
      },
      "click-graph": function ($event) {
        _vm.firstIVR.status != '1' ? _vm.appNotify({
          type: 'danger',
          message: 'Unable to proceed because this ivr is suspended'
        }) : _vm.$modal.show('GraphModal', {
          accountcode: _vm.firstIVR.voipaccount,
          invalidInput: ''
        });
      }
    }
  })] : _c('vb-table', {
    staticClass: "IVRTable latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover",
    attrs: {
      "id": "ivr-table",
      "textNoRecord": " ",
      "isListEmpty": _vm.isEmpty(_vm.filterIVR),
      "loading": _vm.api.ivrs.send,
      "listLength": _vm.filterIVR.length
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.filterIVR, function (menu, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: menu.voipaccount || menu.id,
            class: `dialer-row-select ${_vm.conditions.more == menu.voipaccount ? 'makeTDverticalAlignTop' : ''}`,
            on: {
              "click": function ($event) {
                menu.queue_service ? '' : menu.status != '1' ? _vm.appNotify({
                  type: 'danger',
                  message: 'Unable to proceed because this ivr is suspended'
                }) : _vm.$modal.show('EditMenuModal', {
                  menu: menu
                });
              }
            }
          }, [_c('td', {
            staticClass: "dialer-row-title one"
          }, [menu.queue_service ? _c('Info', {
            staticClass: "w-fit-content",
            attrs: {
              "id": menu.id
            }
          }) : _c('Info', {
            staticClass: "w-fit-content",
            attrs: {
              "id": menu.voipaccount,
              "is_suspended_show": ""
            }
          })], 1), _c('td', {
            staticClass: "dialer-row-title two"
          }, [menu.userDid && menu.userDid.length ? _c('div', {
            staticClass: "d-flex flex-column"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('span', {
            staticClass: "sm-mar-left font-weight-bold min-width-160px"
          }, [_c('span', {
            staticClass: "mr-1"
          }, [_c('img', {
            staticClass: "country-img",
            attrs: {
              "width": "45px",
              "src": _vm._f("flag_icon")(_vm._f("number_formater")(_vm._f("get_property")(menu, 'userDid[0].did')))
            }
          })]), _vm._v(" " + _vm._s(_vm._f("number_formater")(_vm._f("get_property")(menu, 'userDid[0].did'))) + " ")]), menu.userDid.length > 1 ? _c('div', {
            staticClass: "w-auto dialer-dropdown dialer-dropDown-latest dialer-dropDown-onRight dialer-dropDown-p-1 ml-2",
            on: {
              "click": function ($event) {
                $event.stopPropagation();
                _vm.conditions.more = _vm.conditions.more == menu.voipaccount ? '' : menu.voipaccount;
              }
            }
          }, [_c('div', {
            staticClass: "px-2 rounded more-numbers-tag"
          }, [_c('small', [_vm._v("+ " + _vm._s(menu.userDid.length - 1) + " more")])])]) : _vm._e()]), _vm.conditions.more == menu.voipaccount ? [_vm._l(menu.userDid.filter(function (item, index) {
            return index > 0;
          }), function (did, index) {
            return [_c('div', {
              directives: [{
                name: "show",
                rawName: "v-show",
                value: index >= start && index <= end,
                expression: "index >= start && index <= end"
              }],
              key: did.did,
              staticClass: "mt-2"
            }, [_c('div', {
              staticClass: "d-flex align-items-center sm-mar-left"
            }, [_c('span', {
              staticClass: "mr-2 d-flex align-items-center"
            }, [_c('img', {
              staticClass: "country-img",
              attrs: {
                "width": "45px",
                "src": _vm._f("flag_icon")(_vm._f("number_formater")(did.did))
              }
            })]), _vm._v(" " + _vm._s(_vm._f("number_formater")(did.did)) + " ")])])];
          })] : _vm._e()], 2) : _vm._e()]), _c('td', {
            staticClass: "dialer-row-title one-5"
          }, [_vm._v(_vm._s(_vm._f("get_property")(menu, 'schedules[0].options.length', 0)))]), !_vm.$store.getters.getIsMobile ? _c('td', {
            staticClass: "dialer-row-title dialer-col-right"
          }, [menu.queue_service ? _c('b-spinner', {
            attrs: {
              "variant": "primary",
              "type": "grow",
              "small": "",
              "label": "Spinning"
            }
          }) : _c('div', {
            staticClass: "dialer-row-title d-flex align-items-center"
          }, [_c('span', {
            staticClass: "newThisDeviceIndicaterTextBox-25-11-2023 h-fit-content ml-0 mr-0 showOnly-OnHover-insideTable"
          }, [_vm._v(" Configure ")]), _c('button', {
            staticClass: "dialer-button dialer-button-delete-alt buttonNothing",
            attrs: {
              "type": "button"
            },
            on: {
              "click": function ($event) {
                $event.stopPropagation();
                menu.status != '1' ? _vm.appNotify({
                  type: 'danger',
                  message: 'Unable to proceed because this ivr is suspended'
                }) : _vm.$modal.show('GraphModal', {
                  accountcode: menu.voipaccount,
                  invalidInput: ''
                });
              }
            }
          }, [_c('vb-icon', {
            staticClass: "graphIcon",
            attrs: {
              "icon": "graph-icon",
              "height": "40.501",
              "width": "41.374"
            }
          })], 1)])], 1) : _vm._e(), _c('td', {
            staticClass: "dialer-col-right three"
          }, [_c('div', {
            staticClass: "d-flex justify-content-end"
          }, [!menu.queue_service ? _c('vb-audio-player', {
            attrs: {
              "design": 'minimum',
              "src": _vm._f("welcomgreetingsrc")(menu)
            }
          }) : _vm._e()], 1)])]);
        });
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex"
          }, [_c('div', {
            staticClass: "latestShimmerDesign mr-2",
            staticStyle: {
              "height": "35px",
              "width": "35px"
            }
          }), _c('div', {
            staticClass: "d-flex flex-column justify-content-between"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "16px",
              "width": "60px"
            }
          }), _c('div', {
            staticClass: "latestShimmerDesign mt-1",
            staticStyle: {
              "height": "16px",
              "width": "30px"
            }
          })])])]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "50%"
            }
          })]), _c('td', [_c('div', {
            staticClass: "d-flex"
          }, [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "-webkit-fill-available"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "85px"
            }
          })]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "48px",
              "width": "48px"
            }
          })])]);
        });
      },
      proxy: true
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    staticClass: "dialer-sorted-by ascending"
  }, [_vm._v("Label")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Number")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Menu options")])]), !_vm.$store.getters.getIsMobile ? _c('th', {
    staticClass: "dialer-has-sort dialer-col-right"
  }) : _vm._e(), _c('th', {
    staticClass: "dialer-has-sort dialer-col-right"
  }, [_c('span', {
    staticClass: "for-flex-column"
  }, [_vm._v("welcome greetings")])])])])], 2), _c('GraphModal', {
    on: {
      "interface": function ($event) {
        _vm.conditions.allow_single_menu = false;
        _vm.afterAdding();
      }
    }
  }), _c('EditMenuModal', {
    on: {
      "interface": function ($event) {
        return _vm.afterAdding();
      }
    }
  }), _c('AddMenuModal', {
    on: {
      "interface": function ($event) {
        return _vm.afterAdding();
      }
    }
  })], 2)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "settingsSecondSection"
  }, [_c('span', {
    staticClass: "dialer-settings-intro newerTextVersion mt-0 mb-0"
  }, [_vm._v("Improve caller experience with personalised greetings and menu options e.g., press 1 for sales, 2 for support.")])]);

}]

export { render, staticRenderFns }