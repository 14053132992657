var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup otherCreditCards-Modal min-width-50percent-modal",
    attrs: {
      "width": "553px",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      },
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      }
    }
  }, [_c('div', {
    staticClass: "dialer-form"
  }, [_vm.getIsMobile ? _c('div', {
    staticClass: "dialer-edit-header flex-column"
  }, [_c('div', {
    staticClass: "d-flex w-100 justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Other cards")]), _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  })], 1)]) : _c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_c('vb-svg', {
    staticClass: "cursor_pointer_no_hover",
    attrs: {
      "name": "leftLongArrow-icon",
      "width": "20",
      "height": "12",
      "viewBox": "0 0 30 16",
      "stroke-width": "1",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }), _vm._v(" Other cards ")], 1), _c('div', {
    staticClass: "dialer-edit-actions d-flex align-items-center"
  }, [_c('button', {
    staticClass: "newButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AddCardStripe');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart d-flex justify-content-center position-relative"
  }, [_vm._v("Add")])], 1)])]), _c('div', {
    staticClass: "d-flex flex-column credit-cards-container-v2 mt-3"
  }, [_vm._l(_vm.response.cards, function (card) {
    return _c('div', {
      key: card.real_id,
      class: `primary-card-container w-100 mt-2 mb-4 ${card.primary == 'yes' ? 'itIsPrimaryCard' : ''}`
    }, [_c('div', {
      staticClass: "primary-card-s1"
    }, [_c('vb-icon', {
      attrs: {
        "icon": "masterCard-v2-icon",
        "width": "100",
        "height": "60"
      }
    }), _c('div', {
      staticClass: "s1-text-container"
    }, [_c('div', {
      staticClass: "s1-big-text"
    }, [_c('div', {
      staticClass: "text-b"
    }, [_vm._v("Mastercard")]), _c('div', {
      staticClass: "s1-c-container"
    }, [_c('div', {
      staticClass: "s1-c"
    }), _c('div', {
      staticClass: "s1-c"
    }), _c('div', {
      staticClass: "s1-c"
    }), _c('div', {
      staticClass: "s1-c"
    })]), _c('div', {
      staticClass: "text-b"
    }, [_vm._v(_vm._s(_vm._f("get_property")(card, 'digits', '0000')))])]), _c('div', {
      staticClass: "s1-small-text"
    }, [_vm._v("Expires " + _vm._s(_vm._f("get_property")(card, 'expiry_month', '00')) + "/" + _vm._s(_vm._f("get_property")(card, 'expiry_year', '00')))]), _c('div', {
      staticClass: "s1-small-text"
    }, [_vm._v(_vm._s(card.label))])])], 1), _c('div', {
      staticClass: "primary-card-s2"
    }, [_c('b-dropdown', {
      staticClass: "creditCardDD",
      attrs: {
        "no-caret": "",
        "disabled": card.primary == 'yes'
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function () {
          return [card.primary == 'yes' ? [_vm._v("Primary")] : [_vm._v("Other")], card.primary != 'yes' ? _c('b-icon', {
            staticClass: "ml-2",
            attrs: {
              "icon": "chevron-down"
            }
          }) : _vm._e()];
        },
        proxy: true
      }], null, true)
    }, [card.primary != 'yes' ? _c('b-dropdown-item', {
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          return _vm.makePrimary(card);
        }
      }
    }, [_vm._v("Set primary")]) : _vm._e()], 1), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "s2-text primary cursor_pointer",
      staticStyle: {
        "color": "red"
      },
      on: {
        "click": function ($event) {
          return _vm.deleteCard(card);
        }
      }
    }, [_vm._v("Remove")])])], 1)]);
  }), _vm.response.cards.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.cards.send ? '' : 'Sorry, no data found',
      "design": 3
    }
  }, [_vm.api.cards.send ? _c('template', {
    slot: "loading"
  }, _vm._l(5, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none"
    }, [_c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "175px",
        "width": "100%",
        "border-radius": "13px",
        "margin-bottom": "16px"
      }
    })]);
  }), 0) : _vm._e()], 2) : _vm._e()], 2)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }