<template>
  <HorizontalLayout>
    <div slot="right">
      <div class="btnContainer" style="position: relative;" @click="add()" slot="right" @change-graph="$emit('interface',$event)">
        <b-icon icon="plus" font-scale="1.7" />
        <!-- Add Advance Options -->
        Add Menu Options
      </div>
    </div>
  </HorizontalLayout>
</template>

<script>
// import HorizontalLayout from '../layouts/Horizontal.vue'
import HorizontalLayout from '../layouts/Horizontal2.vue'
export default {
  name: 'AdvanceOptionsNode',
  components: {
    HorizontalLayout
  },
  methods: {
    add(){
      this.$emit('interface', {
        from: 'add_advance', 
        event: 'add_options',  
      })
    },
  },
}
</script>

<style>

</style>