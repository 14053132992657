var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup EditMenuModal EditCallQueueModal",
    attrs: {
      "width": "40%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Edit call queue (" + _vm._s(_vm.forms.update_label.label) + ")")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between w-100"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder withLeftIconOnly w-50"
  }, [_c('label', [_vm._v("Call queue name")]), _c('div', {
    staticClass: "inputContainer-inside-whiteBGinputWithGreyRoundedBorder"
  }, [_c('img', {
    attrs: {
      "src": _vm.data.profile_image
    },
    on: {
      "error": function ($event) {
        $event.target.src = _vm.local_filess.images.user_male;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_label.label,
      expression: "forms.update_label.label"
    }],
    staticClass: "w-100 bg-white",
    attrs: {
      "type": "text",
      "maxlength": _vm.$v.forms.update_label.label.$params.maxLength.max
    },
    domProps: {
      "value": _vm.forms.update_label.label
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update_label, "label", $event.target.value);
      }, _vm.updateLabel]
    }
  })]), _vm.forms.update_label.submitted && _vm.$v.forms.update_label.label.$invalid ? _c('p', [!_vm.$v.forms.update_label.label.required ? _c('span', {
    staticClass: "errorRed"
  }, [_vm._v("Name is required")]) : !_vm.$v.forms.update_label.label.minLength ? _c('span', {
    staticClass: "errorRed"
  }, [_vm._v("* Name is should be minimum " + _vm._s(_vm.$v.forms.update_label.label.$params.minLength.min) + " character")]) : !_vm.$v.forms.update_label.label.maxLength ? _c('span', {
    staticClass: "errorRed"
  }, [_vm._v("* Name is can be maximum " + _vm._s(_vm.$v.forms.update_label.label.$params.maxLength.max) + " character")]) : !_vm.$v.forms.update_label.label.valid ? _c('span', {
    staticClass: "errorRed"
  }, [_vm._v("* Name can be number and alphabet")]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "profileImg-section-latest-container"
  }, [_c('div', {
    staticClass: "profileImg-section-latest",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CallQueueProfileImageUploaderModal', {
          type: 'CALL_QUEUE',
          accountcode: _vm.callQueueAccountcode,
          current_image: _vm.data.profile_image
        });
      }
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.data.profile_image
    },
    on: {
      "error": function ($event) {
        $event.target.src = _vm.local_filess.images.user_male;
      }
    }
  })])])]), _vm.conditions.numbers ? [_c('div', {
    staticClass: "latestGreyBox-heading-main mt-20px"
  }, [_vm._v("Call queue numbers")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" Use this number to dial into the queue, or transfer callers to this number to put them into the queue ")]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [!_vm.callQueue.userDid || !_vm.callQueue.userDid[0] ? [_c('p', {
    staticClass: "dialer-box-text highlight mb-0"
  }, [_vm._v("Assign your queue a number")])] : _c('div', {
    staticClass: "d-flex IVR-numbers-container flex-wrap"
  }, _vm._l(_vm.callQueue.userDid, function (did) {
    return _c('div', {
      key: did.did,
      staticClass: "newIVRLayout-number-item"
    }, [_c('img', {
      staticClass: "country-img",
      attrs: {
        "width": "45px",
        "src": _vm._f("flag_icon")(_vm._f("number_formater")(did.did))
      }
    }), _c('span', {
      staticClass: "newIVRLayout-number-item-text"
    }, [_vm._v(_vm._s(_vm._f("number_formater")(did.did)))])]);
  }), 0), _c('div', {
    staticClass: "lg-mar-left"
  }, [_c('button', {
    staticClass: "newButton sm-mar-left",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-AssignNumberModal`, {
          accountcode: _vm.callQueueAccountcode
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Assign")])], 1)])], 2)])] : _vm._e(), _vm.conditions.members ? _c('div', {
    staticClass: "mt-20px"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Setup your queue members")]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.callQueue, 'members.length', 0)) + " Users")])]), _c('button', {
    staticClass: "newButton md-mar-left",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('EditCallQueueMembersModal', {
          call_queue: _vm.callQueue,
          type: 'CallQueueMembers'
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Add/remove user")])], 1)])])]) : _vm._e(), _c('div', {
    staticClass: "mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Main options")]), _c('a', {
    staticClass: "newButton sm-mar-left",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-MainOptions`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-configure-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Configure")])], 1)]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Agent & timing options")]), _c('a', {
    staticClass: "newButton sm-mar-left",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-AgentOptionsModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-configure-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Configure")])], 1)]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Announcement options")]), _c('a', {
    staticClass: "newButton sm-mar-left",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-AnnouncementOptionsModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-configure-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Configure")])], 1)])])]), _c('div', {
    staticClass: "mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Delete this call queue?")]), _c('button', {
    staticClass: "newDeleteButton",
    on: {
      "click": function ($event) {
        return _vm.remove();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-delete-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDeleteButton-textPart d-flex justify-content-center position-relative"
  }, [_vm.api.delete.send ? _c('vb-spinner') : _c('span', [_vm._v("Delete")])], 1)], 1)])])])], 2), _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 min-width-50percent-modal",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-MainOptions`
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header d-block"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Main options")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-MainOptions`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('p', {
    staticClass: "vm--modal-text mb-0"
  }, [_vm._v("Set up your call queue configuration.")])]), _c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.updateMenuOptions();
      }
    }
  }, [_c('b-alert', {
    attrs: {
      "show": !!_vm.api.main_options.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.main_options.error_message))]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Auto fill")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("By enabling call recording it will record the calls between callers and members.")])]), _c('b-form-checkbox', {
    staticClass: "newerSwitch",
    attrs: {
      "switch": "",
      "disabled": _vm.api.main_options.send
    },
    on: {
      "change": _vm.updateMenuOptions
    },
    model: {
      value: _vm.forms.main_options.autofill,
      callback: function ($$v) {
        _vm.$set(_vm.forms.main_options, "autofill", $$v);
      },
      expression: "forms.main_options.autofill"
    }
  })], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Select your ring strategy")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Calls are distributed among the members handling a queue with one of several strategies.")]), _c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center mt-12px"
  }, [_c('div', [_c('span', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_c('b', [_vm._v(" " + _vm._s(_vm.forms.main_options.strategy) + " ")])]), _c('p', {
    staticClass: "latestGreyBox-descriptionText mb-0"
  }, [_vm.forms.main_options.strategy == 'ringall' ? [_vm._v(" Ring all available agents until one answers ")] : _vm._e(), _vm.forms.main_options.strategy == 'leastrecent' ? [_vm._v(" Ring agent which was least recently called by this queue ")] : _vm._e(), _vm.forms.main_options.strategy == 'fewestcalls' ? [_vm._v(" Ring the agent with fewest completed calls from this queue ")] : _vm._e(), _vm.forms.main_options.strategy == 'random' ? [_vm._v(" Ring random agent ")] : _vm._e(), _vm.forms.main_options.strategy == 'rrmemory' ? [_vm._v(" Round robin with memory, remember where we left off last ring pass ")] : _vm._e(), _vm.forms.main_options.strategy == 'rrordered' ? [_vm._v(" Same as rrmemory but does not remember where we left off ")] : _vm._e(), _vm.forms.main_options.strategy == 'linear' ? [_vm._v(" Rings agents in the order specified, for dynamic agents in the order they logged in ")] : _vm._e(), _vm.forms.main_options.strategy == 'wrandom' ? [_vm._v(" Random using the member's penalty as a weighting factor, see asterisk documentation for specifics ")] : _vm._e()], 2)]), _c('a', {
    staticClass: "newButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-SelectRingStratergy`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change")])], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Select your music on hold class")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" Play music on hold for callers while they wait in line ")]), _c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center mt-12px"
  }, [_c('span', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_c('b', [_vm._v(" " + _vm._s(_vm._f("get_property")(_vm.response.call_queue, `playlist[${_vm.forms.main_options.musiconhold}]`, 'Select music on hold class')) + " ")])]), _c('a', {
    staticClass: "newButton",
    attrs: {
      "disabled": _vm.api.main_options.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-SelectMusicOnHoldClass`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change")])], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Join empty")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" Sets whether callers can be placed in a queue with no agents. Based on different options like yes, no, loose and strict. ")]), _c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center mt-12px"
  }, [_c('div', [_c('span', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_c('b', [_vm._v(" " + _vm._s(_vm.forms.main_options.joinempty ? _vm.forms.main_options.joinempty : 'Select join empty') + " ")])]), _c('p', {
    staticClass: "latestGreyBox-descriptionText mb-0"
  }, [_vm.forms.main_options.joinempty == 'yes' ? [_vm._v(" Allow callers to join a queue ")] : _vm._e(), _vm.forms.main_options.joinempty == 'no' ? [_vm._v(" Do not allow callers to join a queue ")] : _vm._e(), _vm.forms.main_options.joinempty == 'strict' ? [_vm._v(" Do not allow callers to join a queue even if agents state are unavailable ")] : _vm._e(), _vm.forms.main_options.joinempty == 'loose' ? [_vm._v(" Same as no but it will allow callers to join a queue if agents state are either unavailable or paused ")] : _vm._e()], 2)]), _c('a', {
    staticClass: "newButton",
    attrs: {
      "disabled": _vm.api.main_options.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-SelectJoinEmpty`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change")])], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Leave empty")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Decide what to with callers if agents are not available to take calls or they are logged out.")]), _c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center mt-12px"
  }, [_c('div', [_c('span', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_c('b', [_vm._v(" " + _vm._s(_vm.forms.main_options.leavewhenempty ? _vm.forms.main_options.leavewhenempty : 'Select leave an empty') + " ")])]), _c('p', {
    staticClass: "latestGreyBox-descriptionText mb-0"
  }, [_vm.forms.main_options.leavewhenempty == 'yes' ? [_vm._v(" Remove callers from a queue if agents are logged out ")] : _vm._e(), _vm.forms.main_options.leavewhenempty == 'no' ? [_vm._v(" Do not remove caller from a queue even if there are no agents to take calls ")] : _vm._e(), _vm.forms.main_options.leavewhenempty == 'strict' ? [_vm._v(" Remove callers from a queue even if agents are unavailable or paused ")] : _vm._e(), _vm.forms.main_options.leavewhenempty == 'loose' ? [_vm._v(" Same as yes but callers will remain in a queue if agents are unavailable or paused ")] : _vm._e()], 2)]), _c('a', {
    staticClass: "newButton",
    attrs: {
      "disabled": _vm.api.main_options.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-SelectLeaveEmpty`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change")])], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Maximum callers")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" Decide the limit of callers to be placed in a queue. 0 will act as unlimited ")]), _c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center mt-12px"
  }, [_c('span', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_c('b', [_vm._v(" " + _vm._s(_vm.forms.advance_options.maxlen) + " ")])]), _c('a', {
    staticClass: "newButton",
    attrs: {
      "disabled": _vm.api.advance_options.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-SelectMaximumCallers`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change")])], 1)])])])])], 1)], 1)])]), _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 min-width-50percent-modal",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-AgentOptionsModal`
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('form', {
    staticClass: "dialer-form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header d-block"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Agent and timing options ")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-AgentOptionsModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('p', {
    staticClass: "vm--modal-text mb-0"
  }, [_vm._v("Set up your queue and agent timings.")])])])]), _c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.updateAdvanceOptions();
      }
    }
  }, [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Ring timeout")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" Number of seconds agent phone will ring before considering as timeout ")]), _c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center mt-12px"
  }, [_c('span', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_c('b', [_vm._v(" " + _vm._s(_vm.forms.advance_options.timeout) + " ")])]), _c('a', {
    staticClass: "newButton",
    attrs: {
      "disabled": _vm.api.advance_options.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-SelectRingTimeout`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change")])], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Queue priority")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" Gives queues a priority to ensure calls waiting in a higher priority queue will deliver its calls first if there are agents common to both queues. ")]), _c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center mt-12px"
  }, [_c('span', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_c('b', [_vm._v(" " + _vm._s(_vm.forms.advance_options.weight) + " ")])]), _c('a', {
    staticClass: "newButton",
    attrs: {
      "disabled": _vm.api.advance_options.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-SelectQueuePriority`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change")])], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Members delay")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" Connect with caller after selected number of seconds or add a delay before you connect with caller ")]), _c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center mt-12px"
  }, [_c('span', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_c('b', [_vm._v(" " + _vm._s(_vm.forms.agent_options.memberdelay) + " ")])]), _c('a', {
    staticClass: "newButton",
    attrs: {
      "disabled": _vm.api.agent_options.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-SelectMembersDelay`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change")])], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column w-100"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Retry")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Number of seconds to wait before sending a call to next available agent")]), _c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center mt-12px"
  }, [_c('span', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_c('b', [_vm._v(" " + _vm._s(_vm.forms.main_options.retry) + " ")])]), _c('a', {
    staticClass: "newButton",
    attrs: {
      "disabled": _vm.api.main_options.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-SelectRetry`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change")])], 1)])])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Wrap-up time")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("How many seconds to wait before sending a call to an available agent , only after a successful call.")]), _c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center mt-12px"
  }, [_c('span', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_c('b', [_vm._v(" " + _vm._s(_vm.forms.main_options.wrapuptime) + " ")])]), _c('a', {
    staticClass: "newButton",
    attrs: {
      "disabled": _vm.api.main_options.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-SelectWrapUpTime`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change")])], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Ring timeout restart")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" If enabled, restart the ring timeout for an agent to answer a call ")])]), _c('b-form-checkbox', {
    staticClass: "newerSwitch ml-4",
    attrs: {
      "switch": "",
      "disabled": _vm.api.advance_options.send
    },
    on: {
      "change": _vm.updateAdvanceOptions
    },
    model: {
      value: _vm.forms.advance_options.timeoutrestart,
      callback: function ($$v) {
        _vm.$set(_vm.forms.advance_options, "timeoutrestart", $$v);
      },
      expression: "forms.advance_options.timeoutrestart"
    }
  })], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Ring in use")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" If enabled, skip an agent who are on occupied phone ")])]), _c('b-form-checkbox', {
    staticClass: "newerSwitch ml-4",
    attrs: {
      "switch": "",
      "disabled": _vm.api.advance_options.send
    },
    on: {
      "change": _vm.updateAdvanceOptions
    },
    model: {
      value: _vm.forms.advance_options.ringinuse,
      callback: function ($$v) {
        _vm.$set(_vm.forms.advance_options, "ringinuse", $$v);
      },
      expression: "forms.advance_options.ringinuse"
    }
  })], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Report hold time")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" If disabled, hold time of a caller will not be announced to a agent ")])]), _c('b-form-checkbox', {
    staticClass: "newerSwitch ml-0",
    attrs: {
      "switch": "",
      "disabled": _vm.api.agent_options.send
    },
    on: {
      "change": _vm.updateAgentOptions
    },
    model: {
      value: _vm.forms.agent_options.reportholdtime,
      callback: function ($$v) {
        _vm.$set(_vm.forms.agent_options, "reportholdtime", $$v);
      },
      expression: "forms.agent_options.reportholdtime"
    }
  })], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column aign-items-start"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Auto pause")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" If agent missed a pause them right after, this helps save time as it will not ring an agent who is on paused ")])]), _c('b-form-checkbox', {
    staticClass: "newerSwitch ml-4",
    attrs: {
      "switch": "",
      "disabled": _vm.api.advance_options.send
    },
    on: {
      "change": _vm.updateAdvanceOptions
    },
    model: {
      value: _vm.forms.advance_options.autopause,
      callback: function ($$v) {
        _vm.$set(_vm.forms.advance_options, "autopause", $$v);
      },
      expression: "forms.advance_options.autopause"
    }
  })], 1), _vm.forms.advance_options.autopause ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Auto pause busy")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" If enabled then agent who is busy will not be auto paused ")])]), _c('b-form-checkbox', {
    staticClass: "newerSwitch ml-4",
    attrs: {
      "switch": "",
      "disabled": _vm.api.advance_options.send
    },
    on: {
      "change": _vm.updateAdvanceOptions
    },
    model: {
      value: _vm.forms.advance_options.autopausebusy,
      callback: function ($$v) {
        _vm.$set(_vm.forms.advance_options, "autopausebusy", $$v);
      },
      expression: "forms.advance_options.autopausebusy"
    }
  })], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Auto pause unavailable")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" If enabled then agent who is unavailable,offline or device timeout then will not be auto paused ")])]), _c('b-form-checkbox', {
    staticClass: "newerSwitch ml-4",
    attrs: {
      "switch": "",
      "disabled": _vm.api.advance_options.send
    },
    on: {
      "change": _vm.updateAdvanceOptions
    },
    model: {
      value: _vm.forms.advance_options.autopauseunavail,
      callback: function ($$v) {
        _vm.$set(_vm.forms.advance_options, "autopauseunavail", $$v);
      },
      expression: "forms.advance_options.autopauseunavail"
    }
  })], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Auto pause delay " + _vm._s(_vm.forms.advance_options.autopausedelay))]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" Give agent a time before it considers them as paused , it will only work if agent lets supposed picked a call and then if they dont answer their phone they will be consider as paused ")]), _c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center mt-12px"
  }, [_c('span', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_c('b', [_vm._v(" " + _vm._s(_vm.forms.advance_options.autopausedelay) + " ")])]), _c('a', {
    staticClass: "newButton",
    attrs: {
      "disabled": _vm.api.advance_options.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-SelectAutoPauseDelay`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change")])], 1)])])])]) : _vm._e()]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Call recording")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Record incoming calls to agents")])]), _c('b-form-checkbox', {
    staticClass: "newerSwitch",
    attrs: {
      "switch": "",
      "disabled": _vm.api.advance_options.send
    },
    on: {
      "change": _vm.updateAdvanceOptions
    },
    model: {
      value: _vm.forms.advance_options.monitor_join,
      callback: function ($$v) {
        _vm.$set(_vm.forms.advance_options, "monitor_join", $$v);
      },
      expression: "forms.advance_options.monitor_join"
    }
  })], 1)])])])], 1)])]), _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 min-width-50percent-modal",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-AnnouncementOptionsModal`
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header d-block"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Announcement options modal")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-AnnouncementOptionsModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('p', {
    staticClass: "vm--modal-text mb-0"
  }, [_vm._v("Set up your call queue announcements for user experience")])]), _c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.updateAnnouncementOptions();
      }
    }
  }, [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Announce position")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" Based on different option announce position of a caller ")]), _c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center mt-12px"
  }, [_c('div', [_c('span', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_c('b', [_vm._v(" " + _vm._s(_vm.forms.advance_options.announce_position ? _vm.forms.advance_options.announce_position : 'Select announce position') + " ")])]), _c('p', {
    staticClass: "latestGreyBox-descriptionText mb-0"
  }, [_vm.forms.advance_options.announce_position == 'yes' ? [_vm._v(" It will announce position of a caller in a queue ")] : _vm._e(), _vm.forms.advance_options.announce_position == 'no' ? [_vm._v(" It will skip to announce a position of a caller ")] : _vm._e(), _vm.forms.advance_options.announce_position == 'limit' ? [_vm._v(" Only announce position of a caller to a limited users e.g, if 3 is selected then announce position of caller to first 3 users ")] : _vm._e(), _vm.forms.advance_options.announce_position == 'more' ? [_vm._v(" Announce position of a caller to selected number of callers , once limit is exceeded it will only announce position caller positon but it will announce it as there are already number of callers present in a queue ")] : _vm._e()], 2)]), _c('a', {
    staticClass: "newButton",
    attrs: {
      "disabled": _vm.api.advance_options.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-SelectAnnouncePosition`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change")])], 1)])]), ['limit', 'more'].includes(_vm.forms.advance_options.announce_position) ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Announce position limit")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" it will only announce the position of callers to them with respect to the limit, e.g if the limit is 5 then only the first five users will be able to hear their position. ")]), _c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center mt-12px"
  }, [_c('span', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_c('b', [_vm._v(" " + _vm._s(_vm.forms.advance_options.announce_position_limit) + " ")])]), _c('a', {
    staticClass: "newButton",
    attrs: {
      "disabled": _vm.api.advance_options.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-SelectAnnouncePositionLimit`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change")])], 1)])]) : _vm._e(), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Announce hold time")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" Based on different options hold time will be announced to a caller during position announcement ")]), _c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center mt-12px"
  }, [_c('div', [_c('span', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_c('b', [_vm._v(" " + _vm._s(_vm.forms.agent_options.announce_holdtime ? _vm.forms.agent_options.announce_holdtime : 'Select announce hold time') + " ")])]), _c('p', {
    staticClass: "latestGreyBox-descriptionText mb-0"
  }, [_vm.forms.agent_options.announce_holdtime == 'yes' ? [_vm._v(" Estimated hold time will be announced to a caller ")] : _vm._e(), _vm.forms.agent_options.announce_holdtime == 'no' ? [_vm._v(" Do not announce estimated hold time ")] : _vm._e(), _vm.forms.agent_options.announce_holdtime == 'once' ? [_vm._v(" Announce estimated hold time to a caller only once ")] : _vm._e()], 2)]), _c('a', {
    staticClass: "newButton",
    attrs: {
      "disabled": _vm.api.agent_options.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-SelectAnnounceHoldTime`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change")])], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Announce round seconds")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" Controls the value to round to when we announce the caller hold time e.g if hold time is 1 minute and 22 seconds then the value will be rounded to 1 minute and 30 seconds. ")]), _c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center mt-12px"
  }, [_c('span', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_c('b', [_vm._v(" " + _vm._s(_vm.forms.agent_options.announce_round_seconds ? _vm.forms.agent_options.announce_round_seconds : 'Select announce round seconds') + " ")])]), _c('a', {
    staticClass: "newButton",
    attrs: {
      "disabled": _vm.api.agent_options.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-SelectAnnounceRoundSeconds`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change")])], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Announce frequency")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" How often to announce the caller position and, or estimated hold time in the queue. Set this value to zero to disable ")]), _c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center mt-12px"
  }, [_c('span', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_c('b', [_vm._v(" " + _vm._s(_vm.forms.announcement_options.announce_frequency) + " ")])]), _c('a', {
    staticClass: "newButton",
    attrs: {
      "disabled": _vm.api.announcement_options.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-SelectAnnounceFrequency`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change")])], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Minimum announce frequency")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" Minimum amount of time that must pass before we announce the caller’s position in the queue again. This is used when the caller’s position may change frequently ")]), _c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center mt-12px"
  }, [_c('span', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_c('b', [_vm._v(" " + _vm._s(_vm.forms.announcement_options.min_announce_frequency) + " ")])]), _c('a', {
    staticClass: "newButton",
    attrs: {
      "disabled": _vm.api.announcement_options.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-SelectMinimumAnnounceFrequency`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change")])], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Periodic frequency")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" How often to make periodic announcements to the caller, 0 to disable ")]), _c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center mt-12px"
  }, [_c('span', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_c('b', [_vm._v(" " + _vm._s(_vm.forms.announcement_options.periodic_announce_frequency) + " ")])]), _c('a', {
    staticClass: "newButton",
    attrs: {
      "disabled": _vm.api.announcement_options.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-SelectPeriodicFrequency`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change")])], 1)])])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Announce to first user ")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" Announcement will be announced to a caller right after they join a queue ")])]), _c('b-form-checkbox', {
    staticClass: "newerSwitch",
    attrs: {
      "switch": "",
      "disabled": _vm.api.advance_options.send
    },
    on: {
      "change": _vm.updateAdvanceOptions
    },
    model: {
      value: _vm.forms.advance_options.announce_to_first_user,
      callback: function ($$v) {
        _vm.$set(_vm.forms.advance_options, "announce_to_first_user", $$v);
      },
      expression: "forms.advance_options.announce_to_first_user"
    }
  })], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Relative periodic announcement")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" Announce periodic announcement right after retry or in a relative way ")])]), _c('b-form-checkbox', {
    staticClass: "newerSwitch ml-4",
    attrs: {
      "switch": "",
      "disabled": _vm.api.advance_options.send
    },
    on: {
      "change": _vm.updateAdvanceOptions
    },
    model: {
      value: _vm.forms.advance_options.relative_periodic_announce,
      callback: function ($$v) {
        _vm.$set(_vm.forms.advance_options, "relative_periodic_announce", $$v);
      },
      expression: "forms.advance_options.relative_periodic_announce"
    }
  })], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Random periodic announcement")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" Periodic announcement will be announcement in a random order ")])]), _c('b-form-checkbox', {
    staticClass: "newerSwitch ml-4",
    attrs: {
      "switch": "",
      "disabled": _vm.api.advance_options.send
    },
    on: {
      "change": _vm.updateAdvanceOptions
    },
    model: {
      value: _vm.forms.advance_options.random_periodic_announce,
      callback: function ($$v) {
        _vm.$set(_vm.forms.advance_options, "random_periodic_announce", $$v);
      },
      expression: "forms.advance_options.random_periodic_announce"
    }
  })], 1)]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Queue report hold")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Announce caller holdtime to agent before it connects")])]), _c('a', {
    staticClass: "newButton sm-mar-left",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CallQueueSoundFilesModal', {
          type: 'queue_reporthold',
          value: _vm.forms.announcement_options.queue_reporthold.value
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v(_vm._s(_vm.forms.announcement_options.queue_reporthold.value ? 'Change' : 'Add'))])], 1)]), _vm.forms.announcement_options.queue_reporthold.file ? _c('div', {
    staticClass: "w-50 mt-12px"
  }, [_c('vb-audio-player', {
    staticClass: "whiteBGaudioWithGreyRoundedBorder-again w-100",
    attrs: {
      "src": _vm.forms.announcement_options.queue_reporthold.file
    }
  })], 1) : _vm._e()]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Queue thank you")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Thank you for your patience-periodic announcement")])]), _c('a', {
    staticClass: "newButton sm-mar-left",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CallQueueSoundFilesModal', {
          type: 'queue_thankyou',
          value: _vm.forms.announcement_options.queue_thankyou.value
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v(_vm._s(_vm.forms.announcement_options.queue_thankyou.value ? 'Change' : 'Add'))])], 1)]), _vm.forms.announcement_options.queue_thankyou.file ? _c('div', {
    staticClass: "w-50 mt-12px"
  }, [_c('vb-audio-player', {
    staticClass: "whiteBGaudioWithGreyRoundedBorder-again w-100",
    attrs: {
      "src": _vm.forms.announcement_options.queue_thankyou.file
    }
  })], 1) : _vm._e()]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Queue hold time")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Announce holdtime to a caller while they wait in line")])]), _c('a', {
    staticClass: "newButton sm-mar-left",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CallQueueSoundFilesModal', {
          type: 'queue_holdtime',
          value: _vm.forms.announcement_options.queue_holdtime.value
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v(_vm._s(_vm.forms.announcement_options.queue_holdtime.value ? 'Change' : 'Add'))])], 1)]), _vm.forms.announcement_options.queue_holdtime.file ? _c('div', {
    staticClass: "w-50 mt-12px"
  }, [_c('vb-audio-player', {
    staticClass: "whiteBGaudioWithGreyRoundedBorder-again w-100",
    attrs: {
      "src": _vm.forms.announcement_options.queue_holdtime.file
    }
  })], 1) : _vm._e()]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Queue calls waiting")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Notify caller there are already number of callers in a queue waiting")])]), _c('a', {
    staticClass: "newButton sm-mar-left",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CallQueueSoundFilesModal', {
          type: 'queue_callswaiting',
          value: _vm.forms.announcement_options.queue_callswaiting.value
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v(_vm._s(_vm.forms.announcement_options.queue_callswaiting.value ? 'Change' : 'Add'))])], 1)]), _vm.forms.announcement_options.queue_callswaiting.file ? _c('div', {
    staticClass: "w-50 mt-12px"
  }, [_c('vb-audio-player', {
    staticClass: "whiteBGaudioWithGreyRoundedBorder-again w-100",
    attrs: {
      "src": _vm.forms.announcement_options.queue_callswaiting.file
    }
  })], 1) : _vm._e()]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Queue there are")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Announce position of caller to a caller while they wait in line")])]), _c('a', {
    staticClass: "newButton sm-mar-left",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CallQueueSoundFilesModal', {
          type: 'queue_thereare',
          value: _vm.forms.announcement_options.queue_thereare.value
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v(_vm._s(_vm.forms.announcement_options.queue_thereare.value ? 'Change' : 'Add'))])], 1)]), _vm.forms.announcement_options.queue_thereare.file ? _c('div', {
    staticClass: "w-50 mt-12px"
  }, [_c('vb-audio-player', {
    staticClass: "whiteBGaudioWithGreyRoundedBorder-again w-100",
    attrs: {
      "src": _vm.forms.announcement_options.queue_thereare.file
    }
  })], 1) : _vm._e()]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Queue you are next")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("You are now first in line- Announce position.")])]), _c('a', {
    staticClass: "newButton sm-mar-left",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CallQueueSoundFilesModal', {
          type: 'queue_youarenext',
          value: _vm.forms.announcement_options.queue_youarenext.value
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v(_vm._s(_vm.forms.announcement_options.queue_youarenext.value ? 'Change' : 'Add'))])], 1)]), _vm.forms.announcement_options.queue_youarenext.file ? _c('div', {
    staticClass: "w-50 mt-12px"
  }, [_c('vb-audio-player', {
    staticClass: "whiteBGaudioWithGreyRoundedBorder-again w-100",
    attrs: {
      "src": _vm.forms.announcement_options.queue_youarenext.file
    }
  })], 1) : _vm._e()]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Queue caller announce")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Announce caller position , hold time at start from first user")])]), _c('a', {
    staticClass: "newButton sm-mar-left",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CallQueueSoundFilesModal', {
          type: 'queue_callerannounce',
          value: _vm.forms.announcement_options.queue_callerannounce.value
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v(_vm._s(_vm.forms.announcement_options.queue_callerannounce.value ? 'Change' : 'Add'))])], 1)]), _vm.forms.announcement_options.queue_callerannounce.file ? _c('div', {
    staticClass: "w-50 mt-12px"
  }, [_c('vb-audio-player', {
    staticClass: "whiteBGaudioWithGreyRoundedBorder-again w-100",
    attrs: {
      "src": _vm.forms.announcement_options.queue_callerannounce.file
    }
  })], 1) : _vm._e()]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Queue minute")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("It is for periodic announcement")])]), _c('a', {
    staticClass: "newButton sm-mar-left",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CallQueueSoundFilesModal', {
          type: 'queue_minute',
          value: _vm.forms.announcement_options.queue_minute.value
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v(_vm._s(_vm.forms.announcement_options.queue_callerannounce.value ? 'Change' : 'Add'))])], 1)]), _vm.forms.announcement_options.queue_minute.file ? _c('div', {
    staticClass: "w-50 mt-12px"
  }, [_c('vb-audio-player', {
    staticClass: "whiteBGaudioWithGreyRoundedBorder-again w-100",
    attrs: {
      "src": _vm.forms.announcement_options.queue_minute.file
    }
  })], 1) : _vm._e()]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Queue minutes")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("It is for periodic announcement")])]), _c('a', {
    staticClass: "newButton sm-mar-left",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CallQueueSoundFilesModal', {
          type: 'queue_minutes',
          value: _vm.forms.announcement_options.queue_minutes.value
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v(_vm._s(_vm.forms.announcement_options.queue_minutes.value ? 'Change' : 'Add'))])], 1)]), _vm.forms.announcement_options.queue_minutes.file ? _c('div', {
    staticClass: "w-50 mt-12px"
  }, [_c('vb-audio-player', {
    staticClass: "whiteBGaudioWithGreyRoundedBorder-again w-100",
    attrs: {
      "src": _vm.forms.announcement_options.queue_minutes.file
    }
  })], 1) : _vm._e()]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Queue periodic announce")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("It is for periodic announce")])]), _c('a', {
    staticClass: "newButton sm-mar-left",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CallQueueSoundFilesModal', {
          type: 'periodic_announce',
          value: _vm.forms.announcement_options.periodic_announce.value
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v(_vm._s(_vm.forms.announcement_options.periodic_announce.value ? 'Change' : 'Add'))])], 1)]), _vm.forms.announcement_options.periodic_announce.file ? _c('div', {
    staticClass: "w-50 mt-12px"
  }, [_c('vb-audio-player', {
    staticClass: "whiteBGaudioWithGreyRoundedBorder-again w-100",
    attrs: {
      "src": _vm.forms.announcement_options.periodic_announce.file
    }
  })], 1) : _vm._e()]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Queue seconds")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("It is for periodic announcement")])]), _c('a', {
    staticClass: "newButton sm-mar-left",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CallQueueSoundFilesModal', {
          type: 'queue_seconds',
          value: _vm.forms.announcement_options.queue_seconds.value
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v(_vm._s(_vm.forms.announcement_options.queue_seconds.value ? 'Change' : 'Add'))])], 1)]), _vm.forms.announcement_options.queue_seconds.file ? _c('div', {
    staticClass: "w-50 mt-12px"
  }, [_c('vb-audio-player', {
    staticClass: "whiteBGaudioWithGreyRoundedBorder-again w-100",
    attrs: {
      "src": _vm.forms.announcement_options.queue_seconds.file
    }
  })], 1) : _vm._e()])])])])], 1)])]), _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 min-width-50percent-modal",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-CallQueueMembersModal`
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Call queue members ")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-CallQueueMembersModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', [_c('div', {
    staticClass: "dialer-assigned"
  }, [_c('div', {
    staticClass: "dialer-box grayBox"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('div', {
    staticClass: "dialer-box-header mb-0"
  }, [_vm._v("Call queue members")]), _c('div', {
    staticClass: "lg-mar-left"
  }, [_c('button', {
    staticClass: "dialer-button dialer-button-primary md-mar-left",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('EditCallQueueMembersModal', {
          call_queue: _vm.callQueue,
          type: 'CallQueueMembers'
        });
      }
    }
  }, [_c('span', [_vm._v("Add/Remove user")])])])])])])])])])]), _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 min-width-50percent-modal",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-CallQueueNumbersModal`
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Call queue numbers modal")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-CallQueueNumbersModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', [_c('div', {
    staticClass: "dialer-assigned"
  }, [_c('div', {
    staticClass: "dialer-box grayBox"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('div', [_c('div', {
    staticClass: "dialer-box-header mb-0"
  }, [_vm._v("Numbers")]), _c('ul', {
    staticClass: "list-unstyled dialer-number-list mb-0"
  }, [!_vm.callQueue.userDid || !_vm.callQueue.userDid[0] ? _c('p', {
    staticClass: "dialer-box-text mb-0"
  }, [_vm._v("No numbers assigned")]) : _vm._l(_vm.callQueue.userDid, function (did) {
    return _c('p', {
      key: did.did,
      staticClass: "dialer-box-text mb-0"
    }, [_c('span', {
      staticStyle: {
        "height": "45px",
        "width": "45px"
      }
    }, [_c('img', {
      staticClass: "country-img",
      attrs: {
        "width": "45px",
        "src": _vm._f("flag_icon")(_vm._f("number_formater")(did.did))
      }
    })]), _c('span', {
      staticClass: "sm-mar-left font-weight-bold"
    }, [_vm._v(_vm._s(did.did))])]);
  })], 2)]), _c('div', {
    staticClass: "lg-mar-left"
  }, [_c('button', {
    staticClass: "dialer-button dialer-button-primary sm-mar-left",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-AssignNumberModal`, {
          accountcode: _vm.callQueueAccountcode
        });
      }
    }
  }, [_vm._v("Assign")])])])])])])])])]), _c('ProfileImageUploader', {
    attrs: {
      "modalName": "CallQueueProfileImageUploaderModal"
    },
    on: {
      "image-uploaded": function ($event) {
        _vm.data.profile_image = $event;
        _vm.getCallQueue();
      }
    }
  }), _c('CallQueueSoundFilesModal', {
    attrs: {
      "sound_files": _vm.soundFiles
    },
    on: {
      "select-file": function ($event) {
        return _vm.onSelectFile($event);
      }
    }
  }), _c('AssignNumberModal', {
    attrs: {
      "modalName": `${_vm._uid}-AssignNumberModal`
    },
    on: {
      "added": function ($event) {
        return _vm.getCallQueue();
      }
    }
  }), _c('AddRemoveFromList', {
    attrs: {
      "modalName": 'EditCallQueueMembersModal'
    },
    on: {
      "update-list": function ($event) {
        return _vm.getCallQueue();
      }
    }
  }), _c('modal', {
    staticClass: "modalForDropdown",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-SelectRingStratergy`
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header align-items-start pt-0"
  }, [_c('div', {
    staticClass: "flex-1 pr-3"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Select your ring strategy")]), _c('div', {
    staticClass: "dialer-settings-intro newerTextVersion w-100 mb-0"
  }, [_vm._v(" Calls are distributed among the members handling a queue with one of several strategies. ")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-SelectRingStratergy`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, _vm._l(_vm.ringStartegyOptions, function (option) {
    return _c('div', {
      key: option.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none"
    }, [_c('div', {
      class: `actLikeSquareRadio mr-20px ${_vm.forms.main_options.strategy == option.value ? 'checked' : ''}`,
      on: {
        "click": function ($event) {
          _vm.forms.main_options.strategy = option.value;
          _vm.updateMenuOptions();
        }
      }
    }), _c('div', {}, [_c('div', {
      staticClass: "latestGreyBox-heading-main onlyfirstLetterCapital restLowerCase"
    }, [_vm._v(_vm._s(option.text))]), _c('div', {
      staticClass: "latestGreyBox-descriptionText onlyfirstLetterCapital restLowerCase"
    }, [_vm._v(_vm._s(option.desc))])])]);
  }), 0)])])]), _c('modal', {
    staticClass: "modalForDropdown",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-SelectMusicOnHoldClass`
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header align-items-start pt-0"
  }, [_c('div', {
    staticClass: "flex-1 pr-3"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Select your music on hold class")]), _c('div', {
    staticClass: "dialer-settings-intro newerTextVersion w-100 mb-0"
  }, [_vm._v(" Play music on hold for callers while they wait in line ")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-SelectMusicOnHoldClass`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_vm.musicOnHoldClassOptions.length == 0 ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("There is nothing to show")])]) : _vm._l(_vm.musicOnHoldClassOptions, function (option) {
    return _c('div', {
      key: option.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none"
    }, [_c('div', {
      class: `actLikeSquareRadio mr-20px ${_vm.forms.main_options.musiconhold == option.value ? 'checked' : ''}`,
      on: {
        "click": function ($event) {
          _vm.forms.main_options.musiconhold = option.value;
          _vm.updateMenuOptions();
        }
      }
    }), _c('div', {
      staticClass: "latestGreyBox-heading-main"
    }, [_vm._v(_vm._s(option.text))])]);
  })], 2)])])]), _c('modal', {
    staticClass: "modalForDropdown",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-SelectJoinEmpty`
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header align-items-start pt-0"
  }, [_c('div', {
    staticClass: "flex-1 pr-3"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Select join empty")]), _c('div', {
    staticClass: "dialer-settings-intro newerTextVersion w-100 mb-0"
  }, [_vm._v(" Sets whether callers can be placed in a queue with no agents. Based on different options like yes, no, loose and strict. ")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-SelectJoinEmpty`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_vm.joinEmptyOptions.length == 0 ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("There is nothing to show")])]) : _vm._l(_vm.joinEmptyOptions, function (option) {
    return _c('div', {
      key: option.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none"
    }, [_c('div', {
      class: `actLikeSquareRadio mr-20px ${_vm.forms.main_options.joinempty == option.value ? 'checked' : ''}`,
      on: {
        "click": function ($event) {
          _vm.forms.main_options.joinempty = option.value;
          _vm.updateMenuOptions();
        }
      }
    }), _c('div', [_c('div', {
      staticClass: "latestGreyBox-heading-main onlyfirstLetterCapital restLowerCase"
    }, [_vm._v(_vm._s(option.text))]), _c('div', {
      staticClass: "latestGreyBox-descriptionText onlyfirstLetterCapital restLowerCase"
    }, [_vm._v(_vm._s(option.desc))])])]);
  })], 2)])])]), _c('modal', {
    staticClass: "modalForDropdown",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-SelectLeaveEmpty`
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header align-items-start pt-0"
  }, [_c('div', {
    staticClass: "flex-1 pr-3"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Select leave empty")]), _c('div', {
    staticClass: "dialer-settings-intro newerTextVersion w-100 mb-0"
  }, [_vm._v(" Sets whether callers can be placed in a queue with no agents. Based on different options like yes, no, loose and strict. ")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-SelectLeaveEmpty`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_vm.leaveAnEmptyOptions.length == 0 ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("There is nothing to show")])]) : _vm._l(_vm.leaveAnEmptyOptions, function (option) {
    return _c('div', {
      key: option.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none"
    }, [_c('div', {
      class: `actLikeSquareRadio mr-20px ${_vm.forms.main_options.leavewhenempty == option.value ? 'checked' : ''}`,
      on: {
        "click": function ($event) {
          _vm.forms.main_options.leavewhenempty = option.value;
          _vm.updateMenuOptions();
        }
      }
    }), _c('div', [_c('div', {
      staticClass: "latestGreyBox-heading-main onlyfirstLetterCapital restLowerCase"
    }, [_vm._v(_vm._s(option.text))]), _c('div', {
      staticClass: "latestGreyBox-descriptionText onlyfirstLetterCapital restLowerCase"
    }, [_vm._v(_vm._s(option.desc))])])]);
  })], 2)])])]), _c('modal', {
    staticClass: "modalForDropdown",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-SelectMaximumCallers`
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header align-items-start pt-0"
  }, [_c('div', {
    staticClass: "flex-1 pr-3"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Select maximum callers")]), _c('div', {
    staticClass: "dialer-settings-intro newerTextVersion w-100 mb-0"
  }, [_vm._v(" Decide the limit of callers to be placed in a queue. ")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-SelectMaximumCallers`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, _vm._l(_vm.numbers, function (option) {
    return _c('div', {
      key: option.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none"
    }, [_c('div', {
      class: `actLikeSquareRadio mr-20px ${_vm.forms.advance_options.maxlen == option ? 'checked' : ''}`,
      on: {
        "click": function ($event) {
          _vm.forms.advance_options.maxlen = option;
          _vm.updateAdvanceOptions();
        }
      }
    }), _c('div', {
      staticClass: "latestGreyBox-heading-main"
    }, [_vm._v(_vm._s(option))])]);
  }), 0)])])]), _c('modal', {
    staticClass: "modalForDropdown",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-SelectAnnouncePosition`
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header align-items-start pt-0"
  }, [_c('div', {
    staticClass: "flex-1 pr-3"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Select announce position")]), _c('div', {
    staticClass: "dialer-settings-intro newerTextVersion w-100 mb-0"
  }, [_vm._v(" Based on different option announce position of a caller ")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-SelectAnnouncePosition`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, _vm._l(_vm.announcePositionOptions, function (option) {
    return _c('div', {
      key: option.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none"
    }, [_c('div', {
      class: `actLikeSquareRadio mr-20px ${_vm.forms.advance_options.announce_position == option.value ? 'checked' : ''}`,
      on: {
        "click": function ($event) {
          _vm.forms.advance_options.announce_position = option.value;
          _vm.updateAdvanceOptions();
        }
      }
    }), _c('div', [_c('div', {
      staticClass: "latestGreyBox-heading-main onlyfirstLetterCapital restLowerCase"
    }, [_vm._v(_vm._s(option.text))]), _c('div', {
      staticClass: "latestGreyBox-descriptionText onlyfirstLetterCapital restLowerCase"
    }, [_vm._v(_vm._s(option.desc))])])]);
  }), 0)])])]), _c('modal', {
    staticClass: "modalForDropdown",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-SelectAnnouncePositionLimit`
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header align-items-start pt-0"
  }, [_c('div', {
    staticClass: "flex-1 pr-3"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Select announce position limit")]), _c('div', {
    staticClass: "dialer-settings-intro newerTextVersion w-100 mb-0"
  }, [_vm._v(" it will only announce the position of callers to them with respect to the limit, e.g if the limit is 5 then only the first five users will be able to hear their position. ")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-SelectAnnouncePositionLimit`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, _vm._l(_vm.numbers, function (option) {
    return _c('div', {
      key: option.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none"
    }, [_c('div', {
      class: `actLikeSquareRadio mr-20px ${_vm.forms.advance_options.announce_position_limit == option ? 'checked' : ''}`,
      on: {
        "click": function ($event) {
          _vm.forms.advance_options.announce_position_limit = option;
          _vm.updateAdvanceOptions();
        }
      }
    }), _c('div', {
      staticClass: "latestGreyBox-heading-main"
    }, [_vm._v(_vm._s(option))])]);
  }), 0)])])]), _c('modal', {
    staticClass: "modalForDropdown",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-SelectAnnounceHoldTime`
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header align-items-start pt-0"
  }, [_c('div', {
    staticClass: "flex-1 pr-3"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Select announce hold time")]), _c('div', {
    staticClass: "dialer-settings-intro newerTextVersion w-100 mb-0"
  }, [_vm._v(" Based on different options hold time will be announced to a caller during position announcement ")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-SelectAnnounceHoldTime`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, _vm._l(_vm.announceHoldTimeOptions, function (option) {
    return _c('div', {
      key: option.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none"
    }, [_c('div', {
      class: `actLikeSquareRadio mr-20px ${_vm.forms.agent_options.announce_holdtime == option.value ? 'checked' : ''}`,
      on: {
        "click": function ($event) {
          _vm.forms.agent_options.announce_holdtime = option.value;
          _vm.updateAgentOptions();
        }
      }
    }), _c('div', [_c('div', {
      staticClass: "latestGreyBox-heading-main"
    }, [_vm._v(_vm._s(option.text))]), _c('div', {
      staticClass: "latestGreyBox-descriptionText onlyfirstLetterCapital restLowerCase"
    }, [_vm._v(_vm._s(option.desc))])])]);
  }), 0)])])]), _c('modal', {
    staticClass: "modalForDropdown",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-SelectAnnounceRoundSeconds`
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header align-items-start pt-0"
  }, [_c('div', {
    staticClass: "flex-1 pr-3"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Select announce round seconds")]), _c('div', {
    staticClass: "dialer-settings-intro newerTextVersion w-100 mb-0"
  }, [_vm._v(" Controls the value to round to when we announce the caller hold time e.g if hold time is 1 minute and 22 seconds then the value will be rounded to 1 minute and 30 seconds. ")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-SelectAnnounceRoundSeconds`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, _vm._l(_vm.announceRoundSecondsOptions, function (option) {
    return _c('div', {
      key: option.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none"
    }, [_c('div', {
      class: `actLikeSquareRadio mr-20px ${_vm.forms.agent_options.announce_round_seconds == option.value ? 'checked' : ''}`,
      on: {
        "click": function ($event) {
          _vm.forms.agent_options.announce_round_seconds = option.value;
          _vm.updateAgentOptions();
        }
      }
    }), _c('div', {
      staticClass: "latestGreyBox-heading-main"
    }, [_vm._v(_vm._s(option.text))])]);
  }), 0)])])]), _c('modal', {
    staticClass: "modalForDropdown",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-SelectAnnounceFrequency`
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header align-items-start pt-0"
  }, [_c('div', {
    staticClass: "flex-1 pr-3"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Select announce frequency")]), _c('div', {
    staticClass: "dialer-settings-intro newerTextVersion w-100 mb-0"
  }, [_vm._v(" How often to announce the caller position and, or estimated hold time in the queue. Set this value to zero to disable ")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-SelectAnnounceFrequency`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, _vm._l(_vm.numbers, function (option) {
    return _c('div', {
      key: option.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none"
    }, [_c('div', {
      class: `actLikeSquareRadio mr-20px ${_vm.forms.announcement_options.announce_frequency == option ? 'checked' : ''}`,
      on: {
        "click": function ($event) {
          _vm.forms.announcement_options.announce_frequency = option;
          _vm.updateAnnouncementOptions('announce_frequency');
        }
      }
    }), _c('div', {
      staticClass: "latestGreyBox-heading-main"
    }, [_vm._v(_vm._s(option))])]);
  }), 0)])])]), _c('modal', {
    staticClass: "modalForDropdown",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-SelectMinimumAnnounceFrequency`
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header align-items-start pt-0"
  }, [_c('div', {
    staticClass: "flex-1 pr-3"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Select minimum announce frequency")]), _c('div', {
    staticClass: "dialer-settings-intro newerTextVersion w-100 mb-0"
  }, [_vm._v(" Minimum amount of time that must pass before we announce the caller’s position in the queue again. This is used when the caller’s position may change frequently ")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-SelectMinimumAnnounceFrequency`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, _vm._l(_vm.numbers, function (option) {
    return _c('div', {
      key: option.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none"
    }, [_c('div', {
      class: `actLikeSquareRadio mr-20px ${_vm.forms.announcement_options.min_announce_frequency == option ? 'checked' : ''}`,
      on: {
        "click": function ($event) {
          _vm.forms.announcement_options.min_announce_frequency = option;
          _vm.updateAnnouncementOptions('min_announce_frequency');
        }
      }
    }), _c('div', {
      staticClass: "latestGreyBox-heading-main"
    }, [_vm._v(_vm._s(option))])]);
  }), 0)])])]), _c('modal', {
    staticClass: "modalForDropdown",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-SelectPeriodicFrequency`
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header align-items-start pt-0"
  }, [_c('div', {
    staticClass: "flex-1 pr-3"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Select periodic frequency")]), _c('div', {
    staticClass: "dialer-settings-intro newerTextVersion w-100 mb-0"
  }, [_vm._v(" how often to make periodic announcements to the caller ")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-SelectPeriodicFrequency`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, _vm._l(_vm.numbers, function (option) {
    return _c('div', {
      key: option.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none"
    }, [_c('div', {
      class: `actLikeSquareRadio mr-20px ${_vm.forms.announcement_options.periodic_announce_frequency == option ? 'checked' : ''}`,
      on: {
        "click": function ($event) {
          _vm.forms.announcement_options.periodic_announce_frequency = option;
          _vm.updateAnnouncementOptions('periodic_announce_frequency');
        }
      }
    }), _c('div', {
      staticClass: "latestGreyBox-heading-main"
    }, [_vm._v(_vm._s(option))])]);
  }), 0)])])]), _c('modal', {
    staticClass: "modalForDropdown",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-SelectRingTimeout`
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header align-items-start pt-0"
  }, [_c('div', {
    staticClass: "flex-1 pr-3"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Select ring timeout")]), _c('div', {
    staticClass: "dialer-settings-intro newerTextVersion w-100 mb-0"
  }, [_vm._v(" Number of seconds agent phone will ring before considering as timeout ")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-SelectRingTimeout`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, _vm._l(_vm.numbers, function (option) {
    return _c('div', {
      key: option.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none"
    }, [_c('div', {
      class: `actLikeSquareRadio mr-20px ${_vm.forms.advance_options.timeout == option ? 'checked' : ''}`,
      on: {
        "click": function ($event) {
          _vm.forms.advance_options.timeout = option;
          _vm.updateAdvanceOptions();
        }
      }
    }), _c('div', {
      staticClass: "latestGreyBox-heading-main"
    }, [_vm._v(_vm._s(option))])]);
  }), 0)])])]), _c('modal', {
    staticClass: "modalForDropdown",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-SelectQueuePriority`
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header align-items-start pt-0"
  }, [_c('div', {
    staticClass: "flex-1 pr-3"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Select queue priority")]), _c('div', {
    staticClass: "dialer-settings-intro newerTextVersion w-100 mb-0"
  }, [_vm._v(" Gives queues a priority to ensure calls waiting in a higher priority queue will deliver its calls first if there are agents common to both queues. ")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-SelectQueuePriority`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, _vm._l(_vm.numbers, function (option) {
    return _c('div', {
      key: option.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none"
    }, [_c('div', {
      class: `actLikeSquareRadio mr-20px ${_vm.forms.advance_options.weight == option ? 'checked' : ''}`,
      on: {
        "click": function ($event) {
          _vm.forms.advance_options.weight = option;
          _vm.updateAdvanceOptions();
        }
      }
    }), _c('div', {
      staticClass: "latestGreyBox-heading-main"
    }, [_vm._v(_vm._s(option))])]);
  }), 0)])])]), _c('modal', {
    staticClass: "modalForDropdown",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-SelectMembersDelay`
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header align-items-start pt-0"
  }, [_c('div', {
    staticClass: "flex-1 pr-3"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Select members delay")]), _c('div', {
    staticClass: "dialer-settings-intro newerTextVersion w-100 mb-0"
  }, [_vm._v(" Connect with caller after selected number of seconds or add a delay before you connect with caller ")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-SelectMembersDelay`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, _vm._l(_vm.numbers, function (option) {
    return _c('div', {
      key: option.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none"
    }, [_c('div', {
      class: `actLikeSquareRadio mr-20px ${_vm.forms.agent_options.memberdelay == option ? 'checked' : ''}`,
      on: {
        "click": function ($event) {
          _vm.forms.agent_options.memberdelay = option;
          _vm.updateAgentOptions();
        }
      }
    }), _c('div', {
      staticClass: "latestGreyBox-heading-main"
    }, [_vm._v(_vm._s(option))])]);
  }), 0)])])]), _c('modal', {
    staticClass: "modalForDropdown",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-SelectRetry`
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header align-items-start pt-0"
  }, [_c('div', {
    staticClass: "flex-1 pr-3"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Select retry")]), _c('div', {
    staticClass: "dialer-settings-intro newerTextVersion w-100 mb-0"
  }, [_vm._v(" Number of seconds to wait before sending a call to next available agent ")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-SelectRetry`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, _vm._l(_vm.numbers, function (option) {
    return _c('div', {
      key: option.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none"
    }, [_c('div', {
      class: `actLikeSquareRadio mr-20px ${_vm.forms.main_options.retry == option ? 'checked' : ''}`,
      on: {
        "click": function ($event) {
          _vm.forms.main_options.retry = option;
          _vm.updateMenuOptions();
        }
      }
    }), _c('div', {
      staticClass: "latestGreyBox-heading-main"
    }, [_vm._v(_vm._s(option))])]);
  }), 0)])])]), _c('modal', {
    staticClass: "modalForDropdown",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-SelectWrapUpTime`
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header align-items-start pt-0"
  }, [_c('div', {
    staticClass: "flex-1 pr-3"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Select wrap-up time")]), _c('div', {
    staticClass: "dialer-settings-intro newerTextVersion w-100 mb-0"
  }, [_vm._v(" How many seconds to wait before sending a call to an available agent , only after a successful call. ")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-SelectWrapUpTime`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, _vm._l(_vm.numbers, function (option) {
    return _c('div', {
      key: option.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none"
    }, [_c('div', {
      class: `actLikeSquareRadio mr-20px ${_vm.forms.main_options.wrapuptime == option ? 'checked' : ''}`,
      on: {
        "click": function ($event) {
          _vm.forms.main_options.wrapuptime = option;
          _vm.updateMenuOptions();
        }
      }
    }), _c('div', {
      staticClass: "latestGreyBox-heading-main"
    }, [_vm._v(_vm._s(option))])]);
  }), 0)])])]), _c('modal', {
    staticClass: "modalForDropdown",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-SelectAutoPauseDelay`
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header align-items-start pt-0"
  }, [_c('div', {
    staticClass: "flex-1 pr-3"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Select auto pause delay")]), _c('div', {
    staticClass: "dialer-settings-intro newerTextVersion w-100 mb-0"
  }, [_vm._v(" This setting will delay the auto-pause of an agent by a certain number of seconds after taking a call. ")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-SelectAutoPauseDelay`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, _vm._l(_vm.numbers, function (option) {
    return _c('div', {
      key: option.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none"
    }, [_c('div', {
      class: `actLikeSquareRadio mr-20px ${_vm.forms.advance_options.autopausedelay == option ? 'checked' : ''}`,
      on: {
        "click": function ($event) {
          _vm.forms.advance_options.autopausedelay = option;
          _vm.updateAdvanceOptions();
        }
      }
    }), _c('div', {
      staticClass: "latestGreyBox-heading-main"
    }, [_vm._v(_vm._s(option))])]);
  }), 0)])])])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }