
import { TokenService } from "@/services/storage.service"
import { VOIP_API } from "../index"

export const USERS_API_ENDPOINTS = {
  getUsersAccountcode(params={}){
    return VOIP_API.axios.voip.request({
      url: "get-all-organization-users",
      method: "GET",
      params,
    })
  },
  getExtensionData(params={}){
    return VOIP_API.axios.voip.request({
      url: "get-user-extensions",
      method: "GET",
      params,
    })
  },
  checkLogin(data={}){
    return VOIP_API.axios.voip.request({
      url: "check/offline/login-account/status",
      method: "POST",
      data,
    })
  },
  getChangePlanLog(data={}){
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data:{
        ...data,
        type: 'billing',
        action: 'change_plan_log',
      },
    })
  },
  updateoutofhours(data={}){
    return VOIP_API.axios.voip.request({
      url: `update-user-detail`,
      method: 'POST',
      data,
    });
  },
  delete(data={}){
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data:{
        ...data,
        type: 'billing',
        action: 'billing_service_action',
      },
    })
  },
  deleteAndKeepNumber(data={}){
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data:{
        ...data,
        type: 'billing',
        action: 'customer_keep_number',
      },
    })
  },
  userNumberList(data={}){
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "user-number-list",
      data,
    })
  },
  verifytoken(data={}){
    return VOIP_API.axios.voip_relay.request({
      url: 'verify-login-token',
      method: 'POST',
      data,
    })
  },
  sendsms(data={}){
    return VOIP_API.axios.voip.request({
      url: `send-sms`,
      method: 'POST',
      data,
    })
  },
  smslist(data={}){
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data:{
        ...data,
        type: 'billing',
        action: 'whmcs_sms_cdr_data',
      },
    })
  },
  updateblf(data={}){
    return VOIP_API.axios.voip.request({
      url: `update-user-detail`,
      method: 'POST',
      data,
    });
  },
  updateTimeZone(data={}){
    return VOIP_API.axios.voip.request({
      url: `update-user-detail`,
      method: 'POST',
      data,
    });
  },
  changeEmail(data={},){
    return VOIP_API.axios.voip.request({
      method: 'POST',
      url: `portal-proxy`,
      data: {
        ...data,
        type: 'relay',
        action: 'verify_email',
      },
    });
  },
  list(){
    return VOIP_API.axios.voip.request({
      method: 'GET',
      url: `voipusers`,
    });
  },
  updateUserDetail(data={},){
    return VOIP_API.axios.voip.request({
      method: 'POST',
      url: `portal-proxy`,
      data: {
        ...data,
        type: 'relay',
        action: 'user_detail',
      },
    });
  },
  updateUserJobTitle(data={},){
    return VOIP_API.axios.voip.request({
      method: 'POST',
      url: `update/user-tittle`,
      data,
    });
  },
  deletedlist(uid=''){
    return VOIP_API.axios.voip.request({
      method: 'GET',
      url: `fetch-deleted-user-list/${uid}`,
    });
  },
  updateroutecall(data={},CancelToken=''){
    return VOIP_API.axios.voip.request({
      url: `callroute`,
      method: "POST",
      data,
      CancelToken,
    })
  },
  detail(account){
    return VOIP_API.axios.voip.request({
      method: 'GET',
      url: `voipusers/${account}`,
    });
  },
  updatepassword(data={}){
    return VOIP_API.axios.voip.request({
      method: 'POST',
      url: `password-change-handle`,
      data,
    });
  },
  checkregistration(data) {
    return VOIP_API.axios.voip.request({
      method: 'POST',
      url: `new-sip-registration`,
      data,
    });
  },
  create(data={}) {
    return VOIP_API.axios.voip.request({
      method: 'POST',
      url: `billingcons`,
      data: {
        ...data,
        service: "user",
      },
    });
  },
  unregistration() {
    return VOIP_API.axios.voip.request({
      method: 'POST',
      url: `sip-unregistration`,
    });
  },
  schedules(account,params) {
    return VOIP_API.axios.voip.request({
      method: 'GET',
      url: `schedules/${account}`,
      params,
    });
  },
  udpateschedule(data={}) {
    return VOIP_API.axios.voip.request({
      method: 'POST',
      url: `create-schedule`,
      data,
    });
  },
  updatescheduledefault(data={}){
    return VOIP_API.axios.voip.request({
      method: 'POST',
      url: `set-ofh-default-setting`,
      data,
    });
  },
  getscheduledefault(accountcode=''){
    return VOIP_API.axios.voip.request({
      method: 'GET',
      url: `get-ofh-default-setting/${accountcode}`,
    });
  },
  callroute(params,CancelToken='') {
    return VOIP_API.axios.voip.request({
      method: 'GET',
      url: `callroute`,
      params,
      CancelToken,
    });
  },
  callroutePost() {
    return VOIP_API.axios.voip.request({
      method: 'POST',
      url: `callroute`,
    });
  },
  accountdetail() {
    return VOIP_API.axios.voip.request({
      method: 'GET',
      url: `account-detail-app`,
    });
  },
  changeOwner(data={}) {
    return VOIP_API.axios.voip.request({
      url: "portal-proxy",
      method: "post",
      data:{
        ...data,
        type: 'relay',
        action: 'change_ownership',
      },
    });
  },
  accountinvoicedetail(data={}){
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data:{
        ...data,
        type: 'relay',
        action: 'whmcs_invoice_data',
      },
    })
  },
  userInvoices(data={}){
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data:{
        ...data,
        type: 'billing',
        action: 'user_invoices',
      },
    })
  },
  invoicedetail(data={}){
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data:{
        ...data,
        type: 'relay',
        action: 'whmcs_invoice_detail',
      },
    })
  },
  invoicedetailDownloadPDF(id='',params={}){
    return VOIP_API.axios.voip.request({
      method: "GET",
      url: `generate-invoice-pdf/${id}`,
      params,
      // headers: {
      //   'Accept': 'application/pdf',
      // },
      responseType: 'blob', 
    })
  },
  invoicedetailUpdated(data={}){
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data:{
        ...data,
        type: 'billing',
        action: 'user_invoice_details',
      },
    })
  },
  updateinvoicedetail(data={}){
    return VOIP_API.axios.voip.request({
      method: 'POST',
      url: 'update-invoice-detail',
      data,
    });
  },
  updateuserdetail(data={}){
    return VOIP_API.axios.voip.request({
      method: 'POST',
      url: 'update-user-detail',
      data,
    });
  },
  updateblockstate(account,data={}){
    return VOIP_API.axios.voip.request({
      method: 'PATCH',
      url: `voipusers/${account}`,
      data:{
        ...data,
        action: "change_user_status",
      }
    });
  },
  updateuserrole(account,data={}){
    return VOIP_API.axios.voip.request({
      method: 'PATCH',
      url: `voipusers/${account}`,
      data:{
        ...data,
        action: "change_user_role",
      }
    });
  },
  usercallrecordingsetting(){
    return VOIP_API.axios.voip.request({
      method: 'GET',
      url: 'users-call-recording'
    });
  },
  
  getAccountRetention(uid='',params={}){
    return VOIP_API.axios.voip.request({
      method: 'GET',
      url: `accountretention/${uid}`,
      params,
    });
  },
  updateAccountRetention(uid='',data={}){
    return VOIP_API.axios.voip.request({
      method: 'POST',
      url: `updateaccountretentionbyUId/${uid}`,
      data
    });
  },
  updateusercallrecordingsetting(data={}){
    return VOIP_API.axios.voip.request({
      method: 'POST',
      url: 'update-call-recording',
      data,
    });
  },
  userappdata(){
    return VOIP_API.axios.voip.request({
      method: 'GET',
      url: 'voipuser-app-data'
    });
  },
  publish(data){
    return VOIP_API.axios.voip.request({
      method: 'POST',
      url: 'publish',
      data: {
        ...data,
        params: {
          ...data.params,
          data: {
            ...data.params.data,
            rcaller: TokenService.USER.get().account
          }
        }
      },
    });
  },
  groupPublish(data){
    return VOIP_API.axios.voip.request({
      method: 'POST',
      url: 'group-publish',
      data: {
        ...data,
        params: {
          ...data.params,
          data: {
            ...data.params.data,
            rcaller: TokenService.USER.get().account
          }
        }
      },
    });
  },
  organizationLogin(data){
    return VOIP_API.axios.voip_relay.request({
      method: 'POST',
      url: 'origanzation-login',
      data,
    });
  },
  updateuserextensiondetail(account,data={}){
    return VOIP_API.axios.voip.request({
      url: `mobileextension/${account}`,
      method: "PATCH",
      data,
    })
  },
  userextensiondetail(account){
    return VOIP_API.axios.voip.request({
      url: `mobileextension/${account}`,
      method: "GET",
    })
  },
  updatetwofactorauth(data={}){
    return VOIP_API.axios.voip.request({
      url: 'update-user-detail',
      method: 'POST',
      data,
    })
  },
}