<template>
  <div class="crm-main-section DealsSection">
    <div class="d-flex justify-content-between align-items-center w-100 mb-5">
      <h2 class="crm-main-section-heading text-case-unset mb-0">
        Products
        <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
      </h2>
    </div>
    <div class="crm-card p-32">
      <div class="d-flex justify-content-between align-items-center w-100">
        <h2 class="crm-main-section-heading text-case-unset my-0" style="color:black;">
          Products
        </h2>
      </div>
      <div class="d-flex">
        <div class="w-50 pr-3">
          <div class="crm-card-heading mb-1 mt-4 justify-content-start"><span class="mr-1" style="color:red;">*</span> Tax Label</div>
          <div class="crm-input-container withoutIcon mb-0">
            <input class="w-100"/>
          </div>
        </div>
        <div class="w-50 pl-3">
          <div class="crm-card-heading mb-1 mt-4">Tax Rate</div>
          <div class="crm-input-container withoutIcon mb-0">
            <input class="w-100" placeholder="Tax Percent"/>
          </div>
        </div>
      </div>
      <b-form-group label="Do you sell your products at rates inclusive of Tax?" class="crm-radioBTNs-container">
        <b-form-radio name="some-radios" value="A">
          <span class="crm-radio-text">Tax Exclusive</span>
        </b-form-radio>
        <b-form-radio name="some-radios" value="B">
          <span class="crm-radio-text">Tax Inclusive</span>
        </b-form-radio>
        <b-form-radio name="some-radios" value="B">
          <span class="crm-radio-text">No Tax</span>
        </b-form-radio>
      </b-form-group>
      <b-form-group label="Default discount type" class="crm-radioBTNs-container">
        <b-form-radio name="some-radios" value="A">
          <span class="crm-radio-text">PKR</span>
        </b-form-radio>
        <b-form-radio name="some-radios" value="B">
          <span class="crm-radio-text">%</span>
        </b-form-radio>
      </b-form-group>
      <button class="crm-button mt-4">
        Save
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Products',
  components: {
    
  },
  inject:['isEmpty'],
  data(){
    return {
      tableData : {
        0: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        1: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        2: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        3: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        4: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        5: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        6: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        7: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        8: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        9: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        10: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
      },
      allowedUploadfileExtentions: 'jpg, jpeg, png, gif, svg, pdf, aac, ogg, oga, mp3, wav, mp4, m4v, mov, ogv, webm, zip, rar, doc, docx, txt, text, xml, json, xls, xlsx, odt, csv, ppt, pptx, ppsx, ics, eml'
    }
  },
  computed: {
    
  },
  methods: {
    
  },
  mounted(){
    
  },
}
</script>

<style>

</style>