<template>
  <div ref="speedDialContainer" class="speedDialContainer">
    <div class="tab-pane active">
      <div class="dialer-tabs">
        <div class="dialer-tab-content">
          <div class="tab-pane active">
            <div v-if="!$store.getters.getIsMobile" class="dialer_contact_list speed_dial_list afterFavourites allow_scroll">
              <div class="dialer_contact_row forContacts" v-for="addressbook in favouriteAddressBook" :key="addressbook.id">
                <div class="dialer_contact">
                  <vb-avatar :image="addressbook | get_property('profile_image')" />
                  <div class="user-info">
                    <div class="user-name-box">
                      <span class="user-name">
                        <span>{{`${addressbook.firstName} ${addressbook.lastName || ''}`}}</span>
                      </span>
                      <div class="dialer_contact_hidden_area">
                        <div class="dialer_contact_connection_box">
                          <span class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute">
                            <b-dropdown varient="link" no-caret class="ico-filter">
                              <template #button-content>
                                <span class="speedial_code">{{ addressbook | get_property('main_phone.dialShortCode') }}</span>
                                <b-icon icon="three-dots" scale="1.5" />
                              </template>
                              <b-dropdown-item @click="audioCall(addressbook)">
                                <vb-svg class="mr-2" name="dialer-outlineCall-icon2" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                                <span>Call</span>
                              </b-dropdown-item>
                            </b-dropdown>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="user-number">{{ addressbook | get_property('main_phone.number') | number_formater }}</div>
                  </div>
                </div>
              </div>
              <div class="dialer_contact_department_box" v-for="team in favouriteTeams" :key="team.id">
                <div class="dialer_contact_department_box_header  justify-content-between show-on-hover" block v-b-toggle="'accordion-' + team.id" variant="info">
                  <div class="d-flex align-items-center" style="width:inherit;">
                    <info :id="team.real_id" :is_unread_show="true" />
                    <!-- <span v-if="team.is_favourite">Favourite</span> -->
                  </div>
                  <div class="dialer_contact_hidden_area hidden-for-scroll mr-4">
                    <div class="dialer_contact_connection_box">
                      <span class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute">
                        <b-dropdown varient="link" no-caret class="ico-filter">
                          <template v-slot:button-content>
                            <b-icon icon="three-dots" scale="1.5" />
                          </template>
                          <b-dropdown-item @click="dialTeam(team)">
                            <vb-svg name="dialer-outlineCall-icon2" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                            <span class="ml-2">Call</span>
                          </b-dropdown-item>
                          <b-dropdown-item @click="chatTeam(team)">
                            <vb-svg name="dialer-outlineChat-icon" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                            <span class="ml-2">Chat</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </span>
                    </div>
                  </div>
                  <vb-svg name="dialer-downArrow-icon" width="14" height="8.001" viewBox="0 0 14 8.001" stroke-width="0" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" class="dialer-downArrow" />
                </div>
                <b-collapse class="dialer_contact_department_box_hidden_area" :id="'accordion-' + team.id" accordion="my-accordion">
                  <div class="dialer_contact_row flex-column" v-if="!isEmpty(team.members)">
                    <div class="dialer_contact" v-for="(member, key) in team.members" :key="key">
                      <div class="d-flex align-items-center" style="width:inherit">
                        <info :id="member.accountcode" :is_unread_show="true" />
                      </div>
                      <div class="dialer_contact_hidden_area">
                        <div class="dialer_contact_connection_box">
                          <span class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute">
                            <b-dropdown v-if="getCurrentUser.account!=member.accountcode" varient="link" no-caret class="ico-filter">
                              <template v-slot:button-content>
                                <b-icon icon="three-dots" scale="1.5" />
                              </template>
                              <b-dropdown-item @click="dial(member)">
                                <vb-svg class="mr-2" name="dialer-outlineCall-icon2" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                                <span>Call</span>
                              </b-dropdown-item>
                              <b-dropdown-item @click="chatUser(member)">
                                <vb-svg class="mr-2" name="dialer-outlineChat-icon" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                                <span>Chat</span>
                              </b-dropdown-item>
                              <b-dropdown-item @click="videoCall(member)">
                                <vb-svg class="mr-2" name="dialer-outlineVideo-icon" width="20.6" height="13.6" viewBox="0 0 20.6 13.6" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                                <span>Video</span>
                              </b-dropdown-item>
                            </b-dropdown>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <vb-no-record v-if="isEmpty(team.members)" :text="'Sorry, No Data Found'" :design="3" />
                </b-collapse>
              </div>
              <div class="dialer_contact_department_box" v-for="call_queue in favouriteCallQueue" :key="call_queue.voipaccount">
                <div class="dialer_contact_department_box_header  justify-content-between show-on-hover" block v-b-toggle="'accordion-' + call_queue.voipaccount" variant="info">
                  <div class="d-flex align-items-center" style="width:inherit;">
                    <info :id="call_queue.voipaccount" />
                  </div>
                  <div class="dialer_contact_hidden_area hidden-for-scroll mr-4">
                    <div class="dialer_contact_connection_box">
                      <span class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute">
                        <b-dropdown varient="link" no-caret class="ico-filter">
                          <template v-slot:button-content>
                            <b-icon icon="three-dots" scale="1.5" />
                          </template>
                          <b-dropdown-item @click.stop="dialCallQueue(call_queue)">
                            <vb-svg class="mr-2" name="dialer-outlineCall-icon2" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                            <span>Call</span>
                          </b-dropdown-item>
                          <template v-if="getProperty(call_queue,`call_queue_status.${getCurrentUser.account}.adminforce`,'no')=='no'">
                            <b-dropdown-item @click.stop="callQueueToggleLogin(call_queue)">
                              <b-icon class="mr-2" icon="box-arrow-in-left" />
                              <span>{{call_queue | get_property(`call_queue_status.${getCurrentUser.account}.logged_in`,false) | is_logged_in }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item v-if="getProperty(call_queue,`call_queue_status.${getCurrentUser.account}.logged_in`,false)" @click.stop="callQueueTogglePaused(call_queue)">
                              <b-icon class="mr-2" :icon="getProperty(call_queue,`call_queue_status.${getCurrentUser.account}.is_paused`,false)?'play-fill':'pause-fill'" />
                              <span>{{call_queue | get_property(`call_queue_status.${getCurrentUser.account}.is_paused`,false) | is_paused }}</span>
                            </b-dropdown-item>
                          </template>
                        </b-dropdown>
                      </span>
                    </div>
                  </div>
                  <vb-svg name="dialer-downArrow-icon" width="14" height="8.001" viewBox="0 0 14 8.001" stroke-width="0" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" class="dialer-downArrow" />
                </div>
                <b-collapse class="dialer_contact_department_box_hidden_area" :id="'accordion-' + call_queue.voipaccount" accordion="my-accordion">
                  <div class="dialer_contact_row" v-if="!isEmpty(call_queue.members)">
                    <div class="dialer_contact" v-for="member in call_queue.members" :key="member.membername">
                      <div class="d-flex align-items-center" style="width:inherit">
                        <info :id="member.membername" :is_unread_show="true" />
                      </div>
                      <div class="dialer_contact_hidden_area">
                        <div class="dialer_contact_connection_box">
                          <span class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute">
                            <b-dropdown v-if="getCurrentUser.account!=member.membername" varient="link" no-caret class="ico-filter">
                              <template v-slot:button-content>
                                <b-icon icon="three-dots" scale="1.5" />
                              </template>
                              <b-dropdown-item @click="dial(member)">
                                <vb-svg name="dialer-outlineCall-icon2" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                                <span>Call</span>
                              </b-dropdown-item>
                              <b-dropdown-item @click="chatUser(member)">
                                <vb-svg name="dialer-outlineChat-icon" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                                <span>Chat</span>
                              </b-dropdown-item>
                              <b-dropdown-item @click="videoCall(member)">
                                <vb-svg name="dialer-outlineVideo-icon" width="20.6" height="13.6" viewBox="0 0 20.6 13.6" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                                <span>Video</span>
                              </b-dropdown-item>
                              <template v-if="getCurrentUser.administrator_account">
                                <template v-if="getProperty(call_queue,`call_queue_status.${member.membername}.adminforce`,'no')=='no'">
                                  <b-dropdown-item @click.stop="callQueueToggleLogin(call_queue,member.membername)">
                                    <b-icon icon="box-arrow-in-left" />
                                    <span>{{call_queue | get_property(`call_queue_status.${member.membername}.logged_in`,false) | is_logged_in }}</span>
                                  </b-dropdown-item>
                                  <b-dropdown-item v-if="getProperty(call_queue,`call_queue_status.${member.membername}.logged_in`,false)" @click.stop="callQueueTogglePaused(call_queue,member.membername)">
                                    <b-icon icon="pause-fill" />
                                    <span>{{call_queue | get_property(`call_queue_status.${member.membername}.is_paused`,false) | is_paused }}</span>
                                  </b-dropdown-item>
                                </template>
                              </template>
                            </b-dropdown>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <vb-no-record v-if="isEmpty(call_queue.members)" :text="'Sorry, No Data Found'" :design="3" />
                </b-collapse>
              </div>
              <div class="dialer_contact_row" v-for="user in favouriteUsers" :key="user.voipaccount">
                <div class="dialer_contact">
                  <info :id="user.voipaccount" :is_unread_show="true" />
                  <!-- <vb-avatar
                    :image="user.profile_img"
                    :status_id="user.status ? user.status.id || user.status : ''"
                    :name="user | get_property('user.display_name')"
                    :id="user.voipaccount"
                  /> -->
                  <div class="user-info">
                    <div class="user-name-box">
                      <!-- <span class="user-name">{{ user | get_property('user.display_name') }}</span> -->
                      <div class="dialer_contact_hidden_area">
                        <div class="dialer_contact_connection_box">
                          <span class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute">
                            <b-dropdown varient="link" no-caret class="ico-filter"> 
                              <template v-slot:button-content>
                                <b-icon icon="three-dots" scale="1.5" />
                              </template>
                              <b-dropdown-item @click="!getUserPermissions.blf?appNotify({ message: 'Your package does not support this feature', type: 'danger' }):dialinringing(user)" v-if="user.state=='Ringing'">
                                <vb-svg
                                  name="dialer-outlineCall-icon2"
                                  width="16.501"
                                  height="16.501"
                                  viewBox="0 0 16.501 16.501"
                                  stroke-width="0"
                                  stroke="#005DFF"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></vb-svg>
                                <span class="ml-2">Pick Call</span>
                              </b-dropdown-item>
                              <b-dropdown-item @click="dial(user)" v-else>
                                <vb-svg
                                  name="dialer-outlineCall-icon2"
                                  width="16.501"
                                  height="16.501"
                                  viewBox="0 0 16.501 16.501"
                                  stroke-width="0"
                                  stroke="#005DFF"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></vb-svg>
                                <span class="ml-2">Call</span>
                              </b-dropdown-item>
                              <div :id="`RightSideUsersChatOption-${user.voipaccount}`" v-b-tooltip :title="getUserPermissions.chat?'':'Your package does not support this feature'" :class="['' , !getUserPermissions.chat ? 'for-disable' : '']">
                                <b-dropdown-item :class="!getUserPermissions.chat ? 'for-disable-disable' : ''"  @click="!getUserPermissions.chat ? '' :chatUser(user)">
                                  <vb-svg
                                    name="dialer-outlineChat-icon"
                                    width="16.501"
                                    height="16.501"
                                    viewBox="0 0 16.501 16.501"
                                    stroke-width="0"
                                    stroke="#005DFF"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></vb-svg>
                                  <span class="ml-2">Chat</span>
                                </b-dropdown-item>
                              </div>
                              <b-dropdown-item @click="videoCall(user)">
                                <vb-svg
                                  name="dialer-outlineVideo-icon"
                                  width="20.6"
                                  height="13.6"
                                  viewBox="0 0 20.6 13.6"
                                  stroke-width="0"
                                  stroke="#005DFF"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></vb-svg>
                                <span class="ml-2">Video</span>
                              </b-dropdown-item>
                            </b-dropdown>
                          </span>
                        </div>
                      </div> 
                    </div>
                    <!-- <div class="user-number">{{ user.extn }}</div> -->
                  </div>
                </div>
              </div>
              <!-- <vb-no-record 
                v-if="isEmpty(favouriteAddressBook) && isEmpty(favouriteTeams) && isEmpty(favouriteCallQueue) && isEmpty(favouriteUsers)" 
                :text="'Sorry, No Data Found'" 
                :design="3" 
              /> -->
              <div class="emptyScreenContainer b-0 p-0" v-if="isEmpty(favouriteAddressBook) && isEmpty(favouriteTeams) && isEmpty(favouriteCallQueue) && isEmpty(favouriteUsers)">
                <img width="100%" height="auto" :src="require('@/assets/images/emptyScreenImages/rightSideBarFavourites.png')"/>
                <div class="emptyScreenContainer-heading">
                  No favourite contacts yet
                </div>
                <div class="emptyScreenContainer-text w-100">
                  You can add your most frequently contacted people to this list for quick access.
                </div>
                <!-- <button class="dialer-button dialer-button-primary mt-32px">
                  Select Your Favorites
                </button> -->
              </div>
            </div>
            <!-- mobile view design START here -->
            <div v-else class="dialer_contact_list speed_dial_list afterFavourites allow_scroll">
              <div class="dialer_contact_row forContacts b-0" v-for="addressbook in favouriteAddressBook" :key="addressbook.id">
                <div class="updatingContactsAgain-contact-item">
                  <div class="d-flex">
                    <vb-avatar class="sm-mar-right" :image="addressbook | get_property('profile_image')" />
                    <div class="user-info">
                      <div class="user-name-box">
                        <span class="user-name">
                          <span>{{`${addressbook.firstName} ${addressbook.lastName || ''}`}}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <b-dropdown varient="link" no-caret class="ico-filter">
                    <template #button-content>
                      <span class="speedial_code updateSpeedDialCodeAgain">{{ addressbook | get_property('main_phone.dialShortCode') }}</span>
                      <vb-icon icon="mobile-topBar-threeDots-likeAirCall-icon" class="mobile-topBar-threeDots-likeAirCall-icon" height="27.06px" width="6px" />
                    </template>
                    <b-dropdown-item @click="audioCall(addressbook)">
                      <vb-svg class="" name="dialer-outlineCall-icon2" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                      <span class="ml-2">Call</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div class="dialer_contact_department_box b-0" v-for="team in favouriteTeams" :key="team.id">
                <div class="updatingContactsAgain-contact-item" block v-b-toggle="'accordion-' + team.id" variant="info">
                  <info :id="team.real_id" :is_unread_show="false" :is_suspended_show="false" :is_blf="false" />
                  <div class="showOnlyOnHover">
                    <vb-svg name="dialer-downArrow-icon" width="16px" height="10px" viewBox="0 0 14 8.001" stroke-width="0" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" class="dialer-downArrow mr-3" />
                    <b-dropdown varient="link" no-caret class="ico-filter">
                      <template v-slot:button-content>
                        <vb-icon icon="mobile-topBar-threeDots-likeAirCall-icon" class="mobile-topBar-threeDots-likeAirCall-icon" height="27.06px" width="6px" />
                      </template>
                      <b-dropdown-item @click="dialTeam(team)">
                        <vb-svg name="dialer-outlineCall-icon2" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                        <span class="ml-2">Call</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="chatTeam(team)">
                        <vb-svg name="dialer-outlineChat-icon" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                        <span class="ml-2">Chat</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
                <b-collapse :id="'accordion-' + team.id" accordion="my-accordion">
                  <div class="dialer_contact_row pl-2 pr-0 py-0" v-if="!isEmpty(team.members)">
                    <div class="updatingContactsAgain-contact-item" v-for="(member, key) in team.members" :key="key">
                      <info :id="member.accountcode" :is_unread_show="true" :is_suspended_show="false" :is_blf="false"/>
                      <b-dropdown v-if="getCurrentUser.account!=member.accountcode" varient="link" no-caret class="ico-filter">
                        <template v-slot:button-content>
                          <vb-icon icon="mobile-topBar-threeDots-likeAirCall-icon" class="mobile-topBar-threeDots-likeAirCall-icon" height="27.06px" width="6px" />
                        </template>
                        <b-dropdown-item @click="dial(member)">
                          <vb-svg name="dialer-outlineCall-icon2" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                          <span class="ml-2">Call</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="chatUser(member)">
                          <vb-svg name="dialer-outlineChat-icon" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                          <span class="ml-2">Chat</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="videoCall(member)">
                          <vb-svg name="dialer-outlineVideo-icon" width="20.6" height="13.6" viewBox="0 0 20.6 13.6" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                          <span class="ml-2">Video</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                  <vb-no-record v-if="isEmpty(team.members)" :text="'Sorry, No Data Found'" :design="3" />
                </b-collapse>
              </div>
              <div class="dialer_contact_department_box b-0" v-for="call_queue in favouriteCallQueue" :key="call_queue.voipaccount">
                <div class="updatingContactsAgain-contact-item" block v-b-toggle="'accordion-' + call_queue.voipaccount" variant="info">
                  <info :id="call_queue.voipaccount" :is_unread_show="false" :is_suspended_show="false" :is_blf="false" />
                  <div class="showOnlyOnHover">
                    <vb-svg name="dialer-downArrow-icon" width="16px" height="10px" viewBox="0 0 14 8.001" stroke-width="0" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" class="dialer-downArrow mr-3" />
                    <b-dropdown varient="link" no-caret class="ico-filter">
                      <template v-slot:button-content>
                        <vb-icon icon="mobile-topBar-threeDots-likeAirCall-icon" class="mobile-topBar-threeDots-likeAirCall-icon" height="27.06px" width="6px" />
                      </template>
                      <b-dropdown-item @click.stop="dialCallQueue(call_queue)">
                        <vb-svg name="dialer-outlineCall-icon2" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                        <span class="ml-2">Call</span>
                      </b-dropdown-item>
                      <template v-if="getProperty(call_queue,`call_queue_status.${getCurrentUser.account}.adminforce`,'no')=='no'">
                        <b-dropdown-item @click.stop="callQueueToggleLogin(call_queue)">
                          <b-icon icon="box-arrow-in-left" />
                          <span class="ml-2">{{call_queue | get_property(`call_queue_status.${getCurrentUser.account}.logged_in`,false) | is_logged_in }}</span>
                        </b-dropdown-item>
                        <b-dropdown-item v-if="getProperty(call_queue,`call_queue_status.${getCurrentUser.account}.logged_in`,false)" @click.stop="callQueueTogglePaused(call_queue)">
                          <b-icon :icon="getProperty(call_queue,`call_queue_status.${getCurrentUser.account}.is_paused`,false)?'play-fill':'pause-fill'" />
                          <span class="ml-2">{{call_queue | get_property(`call_queue_status.${getCurrentUser.account}.is_paused`,false) | is_paused }}</span>
                        </b-dropdown-item>
                      </template>
                    </b-dropdown>
                  </div>
                </div>
                <b-collapse :id="'accordion-' + call_queue.voipaccount" accordion="my-accordion">
                  <div class="dialer_contact_row b-0 pl-2 pr-0 py-0" v-if="!isEmpty(call_queue.members)">
                    <div class="updatingContactsAgain-contact-item" v-for="member in call_queue.members" :key="member.membername">
                      <info :id="member.membername" :is_unread_show="true" :is_suspended_show="false" :is_blf="false" />
                      <b-dropdown v-if="getCurrentUser.account!=member.membername" varient="link" no-caret class="ico-filter">
                        <template v-slot:button-content>
                          <vb-icon icon="mobile-topBar-threeDots-likeAirCall-icon" class="mobile-topBar-threeDots-likeAirCall-icon" height="27.06px" width="6px" />
                        </template>
                        <b-dropdown-item @click="dial(member)">
                          <vb-svg name="dialer-outlineCall-icon2" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                          <span class="ml-2">Call</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="chatUser(member)">
                          <vb-svg name="dialer-outlineChat-icon" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                          <span class="ml-2">Chat</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="videoCall(member)">
                          <vb-svg name="dialer-outlineVideo-icon" width="20.6" height="13.6" viewBox="0 0 20.6 13.6" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                          <span class="ml-2">Video</span>
                        </b-dropdown-item>
                        <template v-if="getCurrentUser.administrator_account">
                          <template v-if="getProperty(call_queue,`call_queue_status.${member.membername}.adminforce`,'no')=='no'">
                            <b-dropdown-item @click.stop="callQueueToggleLogin(call_queue,member.membername)">
                              <b-icon icon="box-arrow-in-left" />
                              <span class="ml-2">{{call_queue | get_property(`call_queue_status.${member.membername}.logged_in`,false) | is_logged_in }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item v-if="getProperty(call_queue,`call_queue_status.${member.membername}.logged_in`,false)" @click.stop="callQueueTogglePaused(call_queue,member.membername)">
                              <b-icon icon="pause-fill" />
                              <span class="ml-2">{{call_queue | get_property(`call_queue_status.${member.membername}.is_paused`,false) | is_paused }}</span>
                            </b-dropdown-item>
                          </template>
                        </template>
                      </b-dropdown>
                    </div>
                  </div>
                  <vb-no-record v-if="isEmpty(call_queue.members)" :text="'Sorry, No Data Found'" :design="3" />
                </b-collapse>
              </div>
              <div class="dialer_contact_row b-0" v-for="user in favouriteUsers" :key="user.voipaccount">
                <div class="updatingContactsAgain-contact-item">
                  <info :id="user.voipaccount" :is_unread_show="false" :is_suspended_show="false" :is_blf="false" />
                  <b-dropdown varient="link" no-caret class="ico-filter"> 
                    <template v-slot:button-content>
                      <vb-icon icon="mobile-topBar-threeDots-likeAirCall-icon" class="mobile-topBar-threeDots-likeAirCall-icon" height="27.06px" width="6px" />
                    </template>
                    <b-dropdown-item @click="!getUserPermissions.blf?appNotify({ message: 'Your package does not support this feature', type: 'danger' }):dialinringing(user)" v-if="user.state=='Ringing'">
                      <vb-svg
                        name="dialer-outlineCall-icon2"
                        width="16.501"
                        height="16.501"
                        viewBox="0 0 16.501 16.501"
                        stroke-width="0"
                        stroke="#005DFF"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></vb-svg>
                      <span class="ml-2">Pick Call</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="dial(user)" v-else>
                      <vb-svg
                        name="dialer-outlineCall-icon2"
                        width="16.501"
                        height="16.501"
                        viewBox="0 0 16.501 16.501"
                        stroke-width="0"
                        stroke="#005DFF"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></vb-svg>
                      <span class="ml-2">Call</span>
                    </b-dropdown-item>
                    <div :id="`RightSideUsersChatOption-${user.voipaccount}`" v-b-tooltip :title="getUserPermissions.chat?'':'Your package does not support this feature'" :class="['' , !getUserPermissions.chat ? 'for-disable' : '']">
                      <b-dropdown-item :class="!getUserPermissions.chat ? 'for-disable-disable' : ''"  @click="!getUserPermissions.chat ? '' :chatUser(user)">
                        <vb-svg
                          name="dialer-outlineChat-icon"
                          width="16.501"
                          height="16.501"
                          viewBox="0 0 16.501 16.501"
                          stroke-width="0"
                          stroke="#005DFF"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></vb-svg>
                        <span class="ml-2">Chat</span>
                      </b-dropdown-item>
                    </div>
                    <b-dropdown-item @click="videoCall(user)">
                      <vb-svg
                        name="dialer-outlineVideo-icon"
                        width="20.6"
                        height="13.6"
                        viewBox="0 0 20.6 13.6"
                        stroke-width="0"
                        stroke="#005DFF"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></vb-svg>
                      <span class="ml-2">Video</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <vb-no-record 
                v-if="isEmpty(favouriteAddressBook) && isEmpty(favouriteTeams) && isEmpty(favouriteCallQueue) && isEmpty(favouriteUsers)" 
                :text="'Sorry, No Data Found'" 
                :design="3" 
              />
            </div>
            <!-- mobile view design END here -->
          </div>
        </div>
      </div>
    </div>
    <SelectAddressBookNumberModal />
    <DirectChatModal />
    <TeamChatModal />
  </div>
</template>

<script>
import { events, LOGGER, VOIP_API } from "../../utils";
import SelectAddressBookNumberModal from "../Modals/SelectAddressBookNumberModal.vue";
import DirectChatModal from "@/components/Modals/chat/DirectChatModal.vue";
import TeamChatModal from "../Chat/Modal/TeamChatModal.vue";
import Info from '../Lists/Info.vue';
import { mapGetters } from 'vuex';
import { mattermost, MATTERMOST_API } from '@/MatterMost';

export default {
  name: "Favourites",
  components: {
    SelectAddressBookNumberModal,
    DirectChatModal,
    TeamChatModal,
    Info,
  },
  inject: ['local_filess', 'isEmpty', 'getProperty','appNotify'],
  computed: {
    ...mapGetters([
      'getVoipTeams',
      'getVoipCallQueues',
      'getVoipUsersAlises',
      'getCurrentUserVoipCallQueues',
      'getCurrentUser',
      'getVoipUsers',
      'getUserPermissions',
    ]),
    favouriteTeams(){ return this.getVoipTeams.filter(team=>team.is_favourite) },
    favouriteCallQueue(){ return this.getVoipCallQueues.filter(call_queue=>call_queue.is_favourite) },
    favouriteUsers(){ return Object.values(this.getVoipUsers)?.filter?.(user=>user.is_favourites && user.voipaccount!=this.getCurrentUser.account) },
    favouriteAddressBook(){ return [...this.$store.state.common.address_books.local.data,...this.$store.state.common.address_books.global.data].filter(item=>!!item.is_favourite) },
  },
  filters: {
    is_logged_in(value){ return value ? 'logout' : 'login' },
    is_paused(value){ return [true,'1',1].includes(value) ? 'Un Pause' : 'Pause' },
  },
  methods: {
    // addressbook
    audioCall(addressbook) {
      LOGGER.log("addressbook", addressbook);
      if (addressbook?.phones?.length < 2) {
        const number_data = addressbook.main_phone;
        if (!number_data) return;
        this.$root.$emit(events.audio_call, {
          number: number_data.number,
          id: number_data.uid,
          type: 'addressbook',
        });
      } else {
        this.$modal.show("SelectAddressBookNumberModal", {
          addressbook: addressbook,
        });
      }
    },
    // teams
    async chatUser(member) {
      const accountcode = member.accountcode || member.membername || member.voipaccount
      const user = this.getVoipUsersAlises?.[accountcode]
      if(!user?.user?.user_chat_id || this.api.chat_user.send) return;
      let channel_id = user.channel_id
      try {
        this.api.chat_user.send=true
        if(!channel_id){
          const { data: channel } = await MATTERMOST_API.endpoints.channels.createDirectChannel([
            user.user.user_chat_id,
            this.getMattermostCurrentUser?.id
          ]);
          channel_id=channel.id
          mattermost.emitOnUserLevel(mattermost.events.direct_added,{
            channel_id: channel_id
          })
          this.$set(this.$store.state.common.voipusers[user.voipaccount],'channel_id',channel_id)
        }
        this.$store.state.global_conditions.tab='chat'
        this.$root.$emit(events.pinned_post_changed,{
          channel_id: channel_id
        })
        await this.$nextTick()
        this.$root.$emit(events.open_chat,{
          channel_id: channel_id,
        })
        this.$emit('close-side-bar')
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.chat_user.send=false
      }
    },
    async chatTeam(team){
      if(!team?.channel_id || this.api.chat_user.send) return;
      let channel_id = team.channel_id
      try {
        this.api.chat_user.send=true
        this.$store.state.global_conditions.tab='chat'
        this.$root.$emit(events.pinned_post_changed,{
          channel_id: channel_id
        })
        await this.$nextTick()
        this.$root.$emit(events.open_chat,{
          channel_id: channel_id,
        })
        this.$emit('close-side-bar')
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.chat_user.send=false
      }
    },
    videoCall(member) {
      const accountcode = member.accountcode || member.membername || member.voipaccount
      const _user = this.getVoipUsersAlises?.[accountcode]
      LOGGER.log('user',_user)
      if(!_user) return;
      this.$root.$emit(events.video_call, {
        accounts: [_user.voipaccount],
        call_type: "video",
      });
    },
    dial(member) {
      const accountcode = member.accountcode || member.membername || member.voipaccount
      const _user = this.getVoipUsersAlises?.[accountcode]
      LOGGER.log('user',_user)
      if(!_user) return;
      this.$root.$emit(events.audio_call, {
        number: _user.extn,
        id: _user.voipaccount,
      });
    },
    dialTeam(team){
      this.$root.$emit(events.audio_call, {
        number: team.ringroup.extn,
        id: team.real_id,
      });
    },
    // callqueue
    dialCallQueue(call_queu){
      this.$root.$emit(events.audio_call, {
        number: call_queu.extn,
        id: call_queu.voipaccount,
      });
    },
    callQueueToggleLogin(call_queue,accountcode){
      let vm = this
      if(vm.api.toggle_logged.send) return;
      vm.api.toggle_logged.send=true
      VOIP_API.endpoints.call_queues.togglelogin(call_queue.voipaccount,{
        member: accountcode || vm.getCurrentUser?.account,
      })
      .then(({ data })=>{
        vm.appNotify({
          message: `Successfully ${data.join ? 'Logged in' : 'Logged out'}`,
          type: 'success',
        })
        vm.getCallQueue()
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.toggle_logged.send=false
      })
    },
    callQueueTogglePaused(call_queue,accountcode){
      let vm = this
      if(vm.api.toggle_paused.send) return;
      vm.api.toggle_paused.send=true
      VOIP_API.endpoints.call_queues.togglepaused(call_queue.voipaccount,{
        member: accountcode || vm.getCurrentUser?.account,
      })
      .then(({ data })=>{
        vm.appNotify({
          message: `Successfully ${data.paused ? 'Paused' : 'Un Pause'}`,
          type: 'success',
        })
        vm.getCallQueue()
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.toggle_paused.send=false
      })
    },
    // users
    dialinringing(user){
      this.$root.$emit(events.audio_call, { 
        number: user.extn,
        call_type: 'blf_call',
        id: user.voipaccount,
      });
    },
  },
};
</script>



<style lang="scss" >
.profile-image-section-container{
  width: 190px;
  height: 190px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  // @include border-radius(50%);
  @include border-radius(40% 40% 40% 40% / 40% 40% 40% 40%);
  margin: 0px auto;
  .profile-image-section{
    margin: 0px;
    width: 130px;
    height: 130px;
    // border-radius: 50%;
    @include border-radius(40% 40% 40% 40% / 40% 40% 40% 40%);
    // position: relative;
    .profile-img{
      width: 130px;
      height: 130px;
      position: relative;
      // border-radius: 50%;
      @include border-radius(40% 40% 40% 40% / 40% 40% 40% 40%);
      img{
        // border-radius: 50%;
        @include border-radius(40% 40% 40% 40% / 40% 40% 40% 40%);
      }
      &:hover{
        &::before{
          width: 140px;
            height: 140px;
            // content: 'Change Image';
            content: '';
            // border-radius: 50%;
            @include border-radius(40% 40% 40% 40% / 40% 40% 40% 40%);
            // background-color: rgba(0,0,0,0.35);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='180' height='180' viewBox='0 0 200 200'%3E%3Cg id='Group_13837' data-name='Group 13837' transform='translate(-2428 -6171)'%3E%3Ccircle id='Ellipse_5287' data-name='Ellipse 5287' cx='100' cy='100' r='100' transform='translate(2428 6171)' fill='%2307003b' opacity='0.2'/%3E%3Cg id='edit' transform='translate(2512 6255)'%3E%3Cg id='Group_12557' data-name='Group 12557'%3E%3Cpath id='Path_12166' data-name='Path 12166' d='M30.122,1.875a6.4,6.4,0,0,0-9.054,0L3.177,19.767a1.25,1.25,0,0,0-.32.549l-2.81,10.1a1.25,1.25,0,0,0,1.546,1.537l10.1-2.868A1.25,1.25,0,0,0,12.232,27L5.867,20.612,20.7,5.781,26.213,11.3,14.859,22.62a1.25,1.25,0,0,0,1.765,1.77l13.5-13.461a6.4,6.4,0,0,0,0-9.054ZM8.962,27.257l-5.91,1.679L4.707,22.99ZM28.356,9.16l-.373.372L22.465,4.014l.371-.371a3.9,3.9,0,0,1,5.52,5.517Z' transform='translate(0)' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
            background-position: center;
            z-index:9;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            color:white;
            font-weight: bold;
            font-size:22px;
            text-align: center;
            top:-5px;
            left:-5px;
            cursor:pointer;
        }
      }
    }
    
  }
}
</style>