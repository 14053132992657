
<template>
  <div>
    <modal 
      :name="modalName"
      class="EditSpaceModal"
      width="50%"
      height="auto"
      :scrollable="true"
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <div>
        <div class="dialer-edit-header position-relative pt-0">
          <h2 class="dialer-settings-title newer mb-0">Filter tasks by</h2>
          <div class="dialer-edit-actions">
            <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="api.save_filter.send ? '': $modal.hide(modalName)" />
            <a v-else class="newCloseButton" type="button" @click="api.save_filter.send ? '': $modal.hide(modalName)" >
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </a>
          </div>
        </div>
        <!-- <b-form @submit.prevent="saveFilter()" v-if="conditions.save">
          <b-alert :show="!!api.save_filter.error_message" variant="danger">{{ api.save_filter.error_message }}</b-alert>
          <b-button type="button" :disabled="api.save_filter.send" @click="conditions.save=false">
            <b-icon icon="x" />
          </b-button>
          <b-input v-model="forms.save_filter.label" :disabled="api.save_filter.send" />
          <p v-if="(forms.save_filter.submitted && $v.forms.save_filter.label.$invalid) || api.save_filter.validation_errors.label">
            <span v-if="!$v.forms.save_filter.label.required"></span>
            <span v-else-if="!$v.forms.save_filter.label.alpha"></span>
            <template v-else-if="api.save_filter.validation_errors.label">
              <span v-for="(em,i) in api.save_filter.validation_errors.label" :key="i">{{ em }}</span>
            </template>
          </p>
        </b-form> -->
        <div class="latestGreyBox-9-9-2023">
          <!-- assignes -->
          <div class="inputLikewhiteBox-container">
            <label>Assignees</label>
            <div class="inputLikewhiteBox">
              <vb-icon icon="pencil-fill" class="pencil-fill" @click="api.save_filter.send ? '': $modal.show(`${modalName}-SelectUsers`)" />
              <div class="inputLikewhiteBox-row lessPadding" v-if="assigned.array.length>0">
                <div class="d-flex align-items-center stackedAvatars-container">
                  <vb-avatar :is_blf="false" v-for="(assigne,index) in assigned.array" :style="`z-index:${assigned.array.length - index}`" :key="assigne" :id="assigne" />
                  <div class="countPlus" v-if="assigned.count" :style="`z-index:${assigned.array.length - 3}`">+ {{assigned.count}}</div>
                </div>
              </div>
              <div class="inputLikewhiteBox-row" v-else>
                Nothing selected
              </div>
            </div>
          </div>
          <!-- statuses -->
          <div class="inputLikewhiteBox-container mt-16px">
            <label>Statuses</label>
            <div class="inputLikewhiteBox">
              <vb-icon icon="pencil-fill" class="pencil-fill" @click="api.save_filter.send ? '': $modal.show(`${modalName}-SelectStatuses`)" />
              <div class="inputLikewhiteBox-row" v-for="status_id in selected.statuses" :key="status_id">
                <template v-if="statusesHashMap[status_id]">
                  <div class="d-flex align-items-center">
                    <div class="color-square" :style="`background-color: ${statusesHashMap[status_id].color};`"></div>
                    {{ statusesHashMap[status_id].title }}
                  </div>
                  <div v-if="statusesHashMap[status_id].space" class="d-flex align-items-center">
                    <div class="color-square" :style="`background-color: ${statusesHashMap[status_id].space.color};`"></div>
                    {{ statusesHashMap[status_id].space.name }}
                  </div>
                </template>
              </div>
              <div class="inputLikewhiteBox-row" v-if="selected.statuses.length==0">
                Nothing selected
              </div>
            </div>
          </div>
          <!-- groups -->
          <div class="inputLikewhiteBox-container mt-16px">
            <label>Groups</label>
            <div class="inputLikewhiteBox">
              <vb-icon icon="pencil-fill" class="pencil-fill" @click="api.save_filter.send ? '': $modal.show(`${modalName}-SelectGroups`)" />
              <div class="inputLikewhiteBox-row" v-for="group_id in selected.groups" :key="group_id">
                <template v-if="groupsHashMap[group_id]">
                  <div class="d-flex align-items-center">
                    <div class="color-square" :style="`background-color: ${groupsHashMap[group_id].color};`"></div>
                    {{ groupsHashMap[group_id].name }}
                  </div>
                  <div v-if="groupsHashMap[group_id].space" class="d-flex align-items-center">
                    <div class="color-square" :style="`background-color: ${groupsHashMap[group_id].space.color};`"></div>
                    {{ groupsHashMap[group_id].space.name }}
                  </div>
                </template>
              </div>
              <div class="inputLikewhiteBox-row" v-if="selected.groups.length==0">
                Nothing selected
              </div>
            </div>
          </div>
          <!-- priorties -->
          <div class="inputLikewhiteBox-container mt-16px">
            <label>Priority</label>
            <div class="inputLikewhiteBox">
              <vb-icon icon="pencil-fill" class="pencil-fill" @click="api.save_filter.send ? '': $modal.show(`${modalName}-SelectPriority`)" />
              <div class="inputLikewhiteBox-row" v-for="priority in selected.priorities" :key="priority">
                <div class="d-flex align-items-center">
                  <template v-if="priorities[priority]">
                    <div class="color-square" :style="`background-color: ${priorities[priority].color};`"></div>
                    {{ priorities[priority].text }}
                  </template>
                </div>
              </div>
              <div class="inputLikewhiteBox-row" v-if="selected.priorities.length==0">
                Nothing selected
              </div>
            </div>
          </div>
          <div class="mt-20px whiteBoxWithBorderInsideGreyBox">
            <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset flex-column align-items-start">
              <div class="d-flex justify-content-between align-items-center w-100">
                <div class="latestGreyBox-heading-main">Save this filter</div>
                <b-form-checkbox
                  :disabled="api.save_filter.send"
                  class="newerSwitch"
                  :checked="conditions.save"
                  v-model="conditions.save"
                  name="check-button"
                  switch
                />
              </div>
              <div v-if="conditions.save" class="latestGreyBox-9-9-2023 mt-20px w-100">
                <b-form class="whiteBGinputWithGreyRoundedBorder w-100" @submit.prevent="saveFilter()">
                  <b-alert :show="!!api.save_filter.error_message" variant="danger">{{ api.save_filter.error_message }}</b-alert>
                  <label>Filter name</label>
                  <div class="d-flex align-items-center w-100">
                    <b-input v-model="forms.save_filter.label" :disabled="api.save_filter.send" class="w-100" :maxlength="$v.forms.save_filter.label.$params.maxLength.max" />
                  </div>
                  <p class="text text-danger animated bounceIntop" v-if="(forms.save_filter.submitted && $v.forms.save_filter.label.$invalid) || api.save_filter.validation_errors.label">
                    <span v-if="!$v.forms.save_filter.label.required">Label is required</span>
                    <span v-else-if="!$v.forms.save_filter.label.alpha">Label can only be alphabet character</span>
                    <span v-else-if="!$v.forms.save_filter.label.maxLength">Label can maximum {{ $v.forms.save_filter.label.$params.maxLength.max }} character</span>
                    <template v-else-if="api.save_filter.validation_errors.label">
                      <span v-for="(em,i) in api.save_filter.validation_errors.label" :key="i">{{ em }}</span>
                    </template>
                  </p>
                </b-form>
              </div>
            </div>
          </div>
        </div>
        <template v-if="conditions.save">
          <div class="w-100 d-flex justify-content-end mt-20px">
            <button class="fullWidthDoneButton" type="button" @click="saveFilter()">
              Save
            </button>
          </div>
        </template>
        <template v-else>
          <!-- <div class="w-100 d-flex justify-content-end mt-20px">
            <button class="dialer-button dialer-button-primary" type="button" @click="conditions.save=true">Save</button>
          </div> -->
          <div class="w-100 d-flex justify-content-end mt-20px">
            <button class="fullWidthDoneButton" type="button" @click="onDone()">
              Done
            </button>
          </div>
        </template>
      </div>
    </modal>
    <modal
      :name="`${modalName}-SelectUsers`"
      class="dialer_animation right_side_popup m-w-500 TODOfilterByModal addExtension-voicemailModal addRemove_userModal bigSquareCheckbox CallQueueAddRemoveUsersModal min-width-50percent-modal fixScrollIssue"
      width="50%"
      height="auto"
      :scrollable="true"
    >
      <div>
        <div class="dialer-edit-header position-relative">
          <h2 class="dialer-settings-title newer mb-0">
            Filter by assignees
          </h2>
          <div class="dialer-edit-actions">
            <!-- <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(`${modalName}-SelectUsers`)" /> -->
            <!-- <a v-else class="newCloseButton" type="button" @click="$modal.hide(`${modalName}-SelectUsers`)" >
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </a> -->
            <button class="newDoneButton" type="button" @click="$modal.hide(`${modalName}-SelectUsers`)">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
        </div>
        <div class="latestGreyBox-9-9-2023 mt-20px">
          <form class="whiteBGinputWithGreyRoundedBorder w-100" @submit.prevent="''">
            <input type="text" v-model="filters.users.search" class="w-100"/>
          </form>
          <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4">
              <div class="d-flex align-items-center" @click="allSelectedUsers=!allSelectedUsers">
                <b-icon class="mr-16px" v-if="allSelectedUsers" icon="check-square-fill" height="24px" width="24px" />
                <b-icon class="mr-16px" v-else icon="square" variant="#ACACAC" height="24px" width="24px" />
                Select all
              </div>
            </div>
            <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-3" v-for="user in filterUsers" :key="user" >
              <div class="d-flex align-items-center" 
                @click="
                  selected.assignees.includes(user) ? 
                    selected.assignees.splice(selected.assignees.indexOf(user),1) : 
                    selected.assignees.push(user)
                "
              >
                <b-icon class="mr-16px" v-if="selected.assignees.includes(user)" icon="check-square-fill" height="24px" width="24px" />
                <b-icon class="mr-16px" v-else icon="square" variant="#ACACAC" height="24px" width="24px" />
                <Info :id="user" :is_blf="false" />
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="w-100 d-flex justify-content-end mt-20px">
          <button class="newDoneButton" type="button" @click="$modal.hide(`${modalName}-SelectUsers`)">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart">Done</span>
          </button>
        </div> -->

      </div>
    </modal>
    <modal
      :name="`${modalName}-SelectStatuses`"
      class="dialer_animation right_side_popup m-w-500 TODOfilterByModal addExtension-voicemailModal addRemove_userModal bigSquareCheckbox CallQueueAddRemoveUsersModal min-width-50percent-modal fixScrollIssue"
      width="50%"
      height="auto"
      :scrollable="true"
    >
      <div>
        <div class="dialer-edit-header position-relative">
          <h2 class="dialer-settings-title newer mb-0">Filter by status</h2>
          <div class="dialer-edit-actions">
            <!-- <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(`${modalName}-SelectStatuses`)" />
            <a v-else class="newCloseButton" type="button" @click="$modal.hide(`${modalName}-SelectStatuses`)" >
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </a> -->
            <button class="newDoneButton" type="button" @click="$modal.hide(`${modalName}-SelectStatuses`)">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
        </div>
        <div class="latestGreyBox-9-9-2023 mt-20px">
          <form class="whiteBGinputWithGreyRoundedBorder w-100" @submit.prevent="''">
            <input type="text" class="w-100" v-model="filters.statuses.search" />
          </form>
          <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4">
              <div class="d-flex align-items-center" @click="allSelectedStatuses=!allSelectedStatuses">
                <b-icon class="mr-16px" v-if="allSelectedStatuses" icon="check-square-fill" height="24px" width="24px" />
                <b-icon class="mr-16px" v-else icon="square" variant="#ACACAC" height="24px" width="24px" />
                Select all
              </div>
            </div>
            <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-3" v-for="status in filterStatuses" :key="status.id" >
              <div class="d-flex align-items-center" 
                @click="
                  selected.statuses.includes(status.id) ? 
                    selected.statuses.splice(selected.statuses.indexOf(status.id),1) : 
                    selected.statuses.push(status.id)
                "
              >
                <b-icon class="mr-16px" v-if="selected.statuses.includes(status.id)" icon="check-square-fill" height="24px" width="24px" />
                <b-icon class="mr-16px" v-else icon="square" height="24px" width="24px" />
                <div class="createSpace-selectColor-container w-auto mr-16px">
                  <div class="createSpace-selectColor-item smaller selected" :style="`background-color: ${status.color};`"></div>
                </div>
                {{ status.title }}
              </div>
              <div v-if="status.space" class="d-flex align-items-center customClassWidthForTextAtEnd">
                <div class="createSpace-selectColor-container mr-16px">
                  <div class="createSpace-selectColor-item smaller selected" :style="`background-color: ${status.space.color};`"></div>
                </div>
                {{ status.space.name }}
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="w-100 d-flex justify-content-end mt-20px">
          <button class="newDoneButton" type="button" @click="$modal.hide(`${modalName}-SelectStatuses`)">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart">Done</span>
          </button>
        </div> -->
      </div>
    </modal>
    <modal
      :name="`${modalName}-SelectGroups`"
      class="dialer_animation right_side_popup m-w-500 TODOfilterByModal addExtension-voicemailModal addRemove_userModal bigSquareCheckbox CallQueueAddRemoveUsersModal min-width-50percent-modal fixScrollIssue"
      width="50%"
      height="auto"
      :scrollable="true"
    >
      <div>
        <div class="dialer-edit-header position-relative">
          <h2 class="dialer-settings-title newer mb-0">Filter by groups</h2>
          <div class="dialer-edit-actions">
            <!-- <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(`${modalName}-SelectGroups`)" />
            <a v-else class="newCloseButton" type="button" @click="$modal.hide(`${modalName}-SelectGroups`)" >
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </a> -->
            <button class="newDoneButton" type="button" @click="$modal.hide(`${modalName}-SelectGroups`)">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
        </div>
        <div class="latestGreyBox-9-9-2023 mt-20px">
          <form class="whiteBGinputWithGreyRoundedBorder w-100" @submit.prevent="''">
            <input type="text" class="w-100" v-model="filters.groups.search" />
          </form>
          <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4">
              <div class="d-flex align-items-center" @click="allSelectedGroups=!allSelectedGroups" >
                <b-icon class="mr-16px" v-if="allSelectedGroups" icon="check-square-fill" height="24px" width="24px" />
                <b-icon class="mr-16px" v-else icon="square" height="24px" width="24px" />
                Select all
              </div>
            </div>
            <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-3" v-for="group in filterGroups" :key="group.id" >
              <div class="d-flex align-items-center"
                @click="
                  selected.groups.includes(group.id) ? 
                    selected.groups.splice(selected.groups.indexOf(group.id),1) : 
                    selected.groups.push(group.id)
                "
              >
                <b-icon class="mr-16px" v-if="selected.groups.includes(group.id)" icon="check-square-fill" height="24px" width="24px" />
                <b-icon class="mr-16px" v-else icon="square" height="24px" width="24px" />
                <div class="createSpace-selectColor-container w-auto mr-16px">
                  <div class="createSpace-selectColor-item smaller selected" :style="`background-color: ${group.color};`"></div>
                </div>
                {{ group.name }}
              </div>
              <div class="d-flex align-items-center" v-if="group.space">
                <div class="createSpace-selectColor-container w-auto mr-16px">
                  <div class="createSpace-selectColor-item smaller selected" :style="`background-color: ${group.space.color};`"></div>
                </div>
                {{ group.space.name }}
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="w-100 d-flex justify-content-end mt-20px">
          <button class="newDoneButton" type="button" @click="$modal.hide(`${modalName}-SelectGroups`)">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart">Done</span>
          </button>
        </div> -->
      </div>
    </modal>
    <modal
      :name="`${modalName}-SelectPriority`"
      class="dialer_animation right_side_popup m-w-500 TODOfilterByModal addExtension-voicemailModal addRemove_userModal bigSquareCheckbox CallQueueAddRemoveUsersModal min-width-50percent-modal fixScrollIssue"
      width="50%"
      height="auto"
      :scrollable="true"
    >
      <div>
        <div class="dialer-edit-header position-relative">
          <h2 class="dialer-settings-title newer mb-0">Filter by priority</h2>
          <div class="dialer-edit-actions">
            <!-- <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(`${modalName}-SelectPriority`)" />
            <a v-else class="newCloseButton" type="button" @click="$modal.hide(`${modalName}-SelectPriority`)" >
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </a> -->
            <button class="newDoneButton" type="button" @click="$modal.hide(`${modalName}-SelectPriority`)">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
        </div>
        <div class="latestGreyBox-9-9-2023 mt-20px">
          <form class="whiteBGinputWithGreyRoundedBorder w-100" @submit.prevent="''">
            <input type="text" class="w-100" v-model="filters.priorities.search" />
          </form>
          <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4">
              <div class="d-flex align-items-center" @click="allSelectedPriorities=!allSelectedPriorities">
                <b-icon class="mr-16px" v-if="allSelectedPriorities" icon="check-square-fill" height="24px" width="24px" />
                <b-icon class="mr-16px" v-else icon="square" height="24px" width="24px" />
                Select all
              </div>
            </div>
            <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-3" v-for="priority in filterPriorities" :key="priority.value">
              <div class="d-flex align-items-center"
                @click="
                  selected.priorities.includes(priority.value) ? 
                    selected.priorities.splice(selected.priorities.indexOf(priority.value),1) : 
                    selected.priorities.push(priority.value)
                "
              >
                <b-icon class="mr-16px" v-if="selected.priorities.includes(priority.value)" icon="check-square-fill" height="24px" width="24px" />
                <b-icon class="mr-16px" v-else icon="square" height="24px" width="24px" />
                <div class="createSpace-selectColor-container w-auto mr-16px">
                  <div class="createSpace-selectColor-item smaller selected" :style="`background-color: ${priority.color};`"></div>
                </div>
                {{ priority.text }}
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="w-100 d-flex justify-content-end mt-20px">
          <button class="newDoneButton" type="button" @click="$modal.hide(`${modalName}-SelectPriority`)">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart">Done</span>
          </button>
        </div> -->
      </div>
    </modal>
  </div>
</template>

<script>
import { VOIP_API } from '@/utils'
import { mapGetters, mapState } from 'vuex'
import Info from '@/components/Lists/Info.vue';
import _ from 'lodash'
import required from 'vuelidate/lib/validators/required';
import alpha from 'vuelidate/lib/validators/alpha';
import maxLength from 'vuelidate/lib/validators/maxLength';
const priorities = {
  high: {
    text: 'High',
    value: 'high',
    color: '#ff403b',
  },
  mid: {
    text: 'Medium',
    value: 'mid',
    color: '#ffba39',
  },
  low: {
    text: 'Low',
    value: 'low',
    color: '#8ad129',
  }
}
export default {
  name: 'SelectFilterOfTasksModal',
  components: { 
    Info, 
  },
  props: {
    modalName: {
      type: String,
      default: 'SelectFilterOfTasks',
    },
  },
  data() {
    return {
      api: {
        fetch_data: this.$helperFunction.apiInstance(),
        save_filter: this.$helperFunction.apiInstance({
          validation_errors: true,
          error_message: true,
        }),
      },
      forms: {
        save_filter: this.$helperFunction.formInstance({
          data: {
            label: '',
          },
        }),
      },
      filters: {
        users: {
          search: '',
        },
        statuses: {
          search: '',
        },
        groups: {
          search: '',
        },
        priorities: {
          search: '',
        },
      },
      selected: {
        assignees: [],
        statuses: [],
        groups: [],
        priorities: [],
      },
      data: {
        space_ids: [],
      },
      response: {
        users: [],
        groups: [],
        statuses: [],
      },
      conditions: {
        save: false,
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getVoipUsersAlisesDisplayName',
      'getVoipUsersAlises',
      'getIsMobile'
    ]),
    ...mapState({
      voipusers: (state) => Object.values(state.common.voipusers).map(i=>({accountcode:i.voipaccount}))
    }),
    priorities(){ return priorities },
    statusesHashMap(){ return _.keyBy(this.response.statuses, 'id') },
    groupsHashMap(){ return _.keyBy(this.response.groups, 'id') },
    filterUsers(){
      const list = this.response.users
      const search = this.filters.users.search.trim().toLowerCase()
      return list.filter(i=>`${this.getVoipUsersAlisesDisplayName[i]} ${this.getVoipUsersAlises[i]?.extn}`.trim().toLowerCase().includes(search))
    },
    filterStatuses(){
      const list = this.response.statuses
      const search = this.filters.statuses.search.trim().toLowerCase()
      return list.filter(i=>`${i.title}`.trim().toLowerCase().includes(search))
    },
    filterGroups(){
      const list = this.response.groups
      const search = this.filters.groups.search.trim().toLowerCase()
      return list.filter(i=>`${i.name}`.trim().toLowerCase().includes(search))
    },
    filterPriorities(){
      const list = Object.values(priorities)
      const search = this.filters.priorities.search.trim().toLowerCase()
      return list.filter(i=>`${i.text}`.trim().toLowerCase().includes(search))
    },
    allSelectedUsers: {
      get() {
        const list = this.response.users
        const selected = this.selected.assignees
        return list.every((el) => selected.includes(el))
      },
      set(value) {
        if(value) this.selected.assignees = this.response.users
        else this.selected.assignees = []
      }
    },
    allSelectedStatuses: {
      get() {
        const list = this.response.statuses
        const selected = this.selected.statuses
        return list.every((el) => selected.includes(el.id))
      },
      set(value) {
        if(value) this.selected.statuses = this.response.statuses.map(i=>i.id)
        else this.selected.statuses = []
      }
    },
    allSelectedGroups: {
      get() {
        const list = this.response.groups
        const selected = this.selected.groups
        return list.every((el) => selected.includes(el.id))
      },
      set(value) {
        if(value) this.selected.groups = this.response.groups.map(i=>i.id)
        else this.selected.groups = []
      }
    },
    allSelectedPriorities: {
      get() {
        const list = Object.values(priorities)
        const selected = this.selected.priorities
        return list.every((el) => selected.includes(el.value))
      },
      set(value) {
        if(value) this.selected.priorities = Object.keys(priorities)
        else this.selected.priorities = []
      }
    },
    assigned(){
      let array = [], count = 0, assigned = this.selected.assignees ?? []
      if(assigned?.length>3) {
        array = assigned.slice(0,3)
      } else {
        array = assigned
      }
      count = assigned.length-3
      return {
        array: array ?? [],
        count: count > 0 ? count : 0
      }
    },
  },
  validations: {
    forms: {
      save_filter: {
        label: {
          required: required,
          alpha: alpha,
          maxLength: maxLength(50),
        },
      },
    },
  },
  methods: {
    onBeforeOpen(event) {
      this.selected.assignees = event?.params?.assignees ?? []
      this.selected.statuses = event?.params?.statuses ?? []
      this.selected.groups = event?.params?.groups ?? []
      this.selected.priorities = event?.params?.priorities ?? []
      this.data.space_ids = event?.params?.space_ids ?? []
      this.fetchData()
    },
    onBeforeClose() {
      this.api.fetch_data.reset()
      this.api.save_filter.reset()
      this.forms.save_filter.reset()
      this.filters.users.search=''
      this.filters.statuses.search=''
      this.filters.groups.search=''
      this.filters.priorities.search=''
      this.selected.assignees = []
      this.selected.statuses = []
      this.selected.groups = []
      this.selected.priorities = []
      this.data.space_ids=[]
      this.response.users=[]
      this.response.groups=[]
      this.response.statuses=[]
      this.conditions.save=false
    },
    async fetchData(){
      if(this.api.fetch_data.send) return;
      try {
        this.api.fetch_data.send=true
        const { data } = await VOIP_API.endpoints.taskgroups.getSpaceTaskData({
          space_id: this.data.space_ids,
        })
        this.response.users=data?.members ?? []
        this.response.statuses=data?.status ?? []
        this.response.groups=data?.group ?? []
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_data.send=false
      }
    },
    async saveFilter(){
      this.forms.save_filter.submitted=true
      this.$v.forms.save_filter.$touch()
      if(this.api.save_filter.send || this.$v.forms.save_filter.$invalid) return;
      try {
        if(this.selected.assignees.length==0 && this.selected.statuses.length==0 && this.selected.groups.length==0 && this.selected.priorities.length==0) throw new Error('Please add some filter') 
        this.api.save_filter.send=true
        this.api.save_filter.error_message=''
        this.api.save_filter.validation_errors={}
        const  { data } = await VOIP_API.endpoints.tasklist.filters.add({
          accountcode:  this.getCurrentUser.account,
          space_ids: JSON.parse(JSON.stringify(this.data.space_ids)).sort().join(','),
          label: this.forms.save_filter.label,
          filters: {
            assignees: this.selected.assignees,
            statuses: this.selected.statuses,
            groups: this.selected.groups,
            priorities: this.selected.priorities,
          },
        })
        this.$emit('apply-filter',{
          filter_id: data?.id ?? '',
          assignees: this.selected.assignees,
          statuses: this.selected.statuses,
          groups: this.selected.groups,
          priorities: this.selected.priorities,
        })
        this.$emit('added')
        this.$modal.hide(this.modalName)
      } catch (ex) {
        this.api.save_filter.error_message=ex.own_message || ex.message
        this.api.save_filter.validation_errors=ex.own_errors || {}
      } finally {
        this.forms.save_filter.submitted=false
        this.api.save_filter.send=false
      }
    },
    onDone(){
      this.$emit('apply-filter',{
        assignees: this.selected.assignees,
        statuses: this.selected.statuses,
        groups: this.selected.groups,
        priorities: this.selected.priorities,
        filter_id: '',
      })
      this.$modal.hide(this.modalName)
    },
  },
}
</script>

<style>

</style>