<template>
  <div>
    <modal
      class="dialer_animation right_side_popup m-w-500 RecordingListSelect min-width-50percent-modal"
      height="auto"
      :scrollable="true"
      :name="modalName"
      @before-open="onBeforeOpen($event)"
      @before-close="onBeforeClose()"
    >
      <section class="ct-user-new dialer-edit">
        <div class="dialer-edit-header d-block pb-0">
          <div class="d-flex justify-content-between align-items-center">
            <h2 class="dialer-edit-title mb-0">
              <div class="d-flex align-items-center">
                <vb-svg
                  @click="$modal.hide(modalName)"
                  class="cursor_pointer"
                  name="leftLongArrow-icon"
                  width="20"
                  height="12"
                  viewBox="0 0 30 16"
                  stroke-width="1"
                  stroke=""
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <span class="dialer-settings-title newer mb-0 md-mar-left">Sound library</span>
              </div>
            </h2>
            <div class="dialer-edit-actions">
              <!-- <button
                :disabled="!getUserPermissions.add_sound_file"
                @click="!getUserPermissions.add_sound_file ? appNotify({ message: 'Your package does not support this feature', type: 'danger' }) : $modal.show('AddRecordingModal',{
                  group: data.group,
                  select_category: data.select_category,
                })"
                class="dialer-button dialer-button-primary"
                type="button"
              >
                Upload
              </button> -->
              <!-- <button class="dialer-button dialer-button-primary" :disabled="isEmpty(selected.files)" @click="onDone()">Done</button> -->
              <button :disabled="isEmpty(selected.files)" @click="onDone()" class="newDoneButton mt-20px">
                <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                <span class="newDoneButton-textPart">Done</span>
              </button>
            </div>
          </div>
          <div class="vm--modal-text">Discover a variety of sounds or upload your own files. Use them for services like music on hold, IVR announcements, or voicemail greetings.</div>
        </div>
        <template v-if="api.fetch_files.send">
          <div class="latestGreyBox-9-9-2023 mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox">
              <div class="whiteBoxWithBorderInsideGreyBox-row" v-for="n in 3" :key="n.id">
                <div class="latestShimmerDesign" style="height: 24px;width: 180px;"></div>
                <div class="latestShimmerDesign w-50" style="height: 64px;"></div>
                <div class="latestShimmerDesign" style="height: 24px;width: 24px;"></div>
              </div>
            </div>
          </div>
        </template>
        <div class="dialer-tabs" v-else>
          <div class="w-100 d-flex justify-content-end">
            <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD mr-16px">
              <b-dropdown no-caret right class="dd-text-dark dd-with-checkbox">
                <template #button-content>
                  <vb-svg 
                    class="filterDD-icon" 
                    name="filter-icon" 
                    width="19" 
                    height="18" 
                    viewBox="0 0 19 18" 
                    stroke-width="0" 
                    stroke="" 
                    fill="none" 
                    stroke-linecap="round" 
                    stroke-linejoin="round" 
                  />
                </template>
                <b-dropdown-item v-for="type in types" :key="type.value" @click="filter.files.type = type.value">
                  <div class="w-100 d-flex align-items-center">
                    <div :class="`actLikeSquareRadio checkboxVersion mr-16px ${filter.files.type == type.value ? 'checked' : ''}`"></div>
                    {{ type.text }}
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div class="dialer-tab-content">
            <div class="latestGreyBox-9-9-2023 mt-20px">
              <div class="w-100 md-mar-bottom whiteBGinputWithGreyRoundedBorder">
                <b-input type="text" placeholder="Search sound file" v-model="filter.files.search" />
              </div>
              <div class="whiteBoxWithBorderInsideGreyBox">
                <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset" v-for="recording in files" :key="recording.id">
                  <div class="d-flex align-items-center justify-content-between w-100">
                    <div class="RecordingListSelect-item-leftPart w-50 d-flex align-items-center flex-wrap">
                      <span class="dialer-assignee-name font-weight-700 mr-4 minWidth25minus16px">{{ recording.label }}</span>
                      <span class="newBlueColorTagLike ml-0 fitContent">{{recording.group}}</span>
                    </div>
                    <div class="RecordingListSelect-item-rightPart d-flex align-items-center w-50 justify-content-between">
                      <vb-audio-player class="whiteBGaudioWithGreyRoundedBorder-again calc-100-32px-imp" :src="recording.sound_file" />
                      <template v-if="is_single_select">
                        <b-icon
                          :icon="selected.files.id==recording.id ? 'check-square-fill' : 'square'"
                          variant="black"
                          font-scale="1.5"
                          @click="selected.files = { 
                            ...recording, 
                            type: recording.filter_type
                          }"
                        />
                      </template>
                      <template v-else>
                        <b-icon
                          :icon="selected.files[recording.id] ? 'check-circle-fill' : 'circle'"
                          variant="black"
                          font-scale="1.5"
                          @click="selected.files[recording.id] ? 
                            $delete(selected.files, recording.id) : 
                            $set(selected.files, recording.id, {
                              ...recording, 
                              type: recording.filter_type
                            })
                          "
                        />
                      </template>
                    </div>
                  </div>
                </div>
                <div v-if="files.length==0 && !api.fetch_files.send" class="text-center mt-20px mb-20px">
                  <p class="mb-0">There are no sounds, you can add one</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- <AddRecordingModal @update-list="fetchFilesDebounce()" /> -->
    </modal>
  </div>
</template>

<script>
import { VOIP_API } from "../../utils";
// import AddRecordingModal from "./AddRecordingModal.vue";
import _ from 'lodash'
import { mapGetters } from 'vuex';
const types = {
  all: {
    text: "All",
    value: "all",
  },
  library: {
    text: "My library",
    value: "library",
  },
  shared: {
    text: "Shared files",
    value: "shared",
  },
  system: {
    text: "System files",
    value: "system",
  },
}
export default {
  name: "RecordingListSelect",
  components: {
    // AddRecordingModal,
  },
  inject:[
    'isEmpty','appNotify'
  ],
  props: {
    modalName: {
      type: String,
      default: "RecordingListSelect",
    },
    is_single_select: {
      type: Boolean,
      default: false,
    },
    shared_condition: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      api: {
        fetch_files: this.$helperFunction.apiInstance(),
      },
      selected: {
        files: {},
      },
      data: {
        group: '',
        select_category: true,
      },
      response: {
        files: {},
      },
      filter: {
        files: {
          type: types.all.value,
          search: "",
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      'getUserPermissions',
      'getCurrentUser'
    ]),
    types(){ return types },
    files(){
      const search = this.filter.files.search.trim().toLowerCase();
      let files = []
      const library_files = (this.response.files?.global?.data ?? this.response.files?.global)?.filter?.(i=>i.type=='custom')?.map(i=>({ ...i, filter_type: types.library.value})) ?? []
      const shared_files = (this.response.files?.shared?.data ?? this.response.files?.shared)?.map(i=>({ ...i, filter_type: types.shared.value})) ?? []
      const system_files = (this.response.files?.global?.data ?? this.response.files?.global)?.filter?.(i=>i.type=='system')?.map(i=>({ ...i, filter_type: types.system.value})) ?? []
      if([types.all.value,types.library.value].includes(this.filter.files.type)) files = [...files,...library_files]
      if([types.all.value,types.shared.value].includes(this.filter.files.type)) files = [...files,...shared_files]
      if([types.all.value,types.system.value].includes(this.filter.files.type)) files = [...files,...system_files]
      return files
        ?.filter?.(i=>(this.data.group ? this.data.group==i.group : true) && `${i.label}`.trim().toLowerCase().includes(search))
        /*?.sort(a=>(this.is_single_select ? a.id==this.selected.files.id : !!this.selected.files[a.id]) ? -1 : 0)*/ ?? [];
    },
  },
  methods: {
    onBeforeOpen(event){
      const selected = event.params?.selected
      this.data.group = event.params?.group || ''
      this.data.select_category = event.params?.select_category ?? true
      if(selected) {
        this.selected.files=selected
      }
      this.fetchFiles();
    },
    onBeforeClose(){
      this.response.files={}
      this.filter.files.search=''
      this.filter.files.type=types.all.value
      this.selected.files={}
      this.api.fetch_files.reset()
      this.data.group=''
      this.data.select_category=true
    },
    fetchFilesDebounce: _.debounce(function(){
      this.fetchFiles()
    }, 2*1000),
    async fetchFiles() {
      if(this.api.fetch_files.send) return;
      try {
        this.api.fetch_files.send=true
        const { data } = await VOIP_API.endpoints.recording.list({
          uid: this.getCurrentUser?.uid,
          accountcode: this.getCurrentUser?.account,
        })
        this.response.files=data
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_files.send=false
      }
    },
    onDone() {
      const data = this.is_single_select ? this.selected.files : Object.values(this.selected.files);
      if (this.shared_condition) {
        if (this.is_single_select) {
          if (data.shared) {
            this.$emit("selectedRecordings", data);
            this.$modal.hide(this.modalName);
          } else {
            this.appNotify({
              message: "Shared your file first to use",
              type: "info",
            });
            return;
          }
        }
      } else {
        if(_.isEmpty(data)){
          this.appNotify({
            message: 'Select a File',
            type: 'danger',
          })
        } else {
          this.$emit("selectedRecordings", data);
          this.$modal.hide(this.modalName);
        }
      }
    },
  },
};
</script>

<style>
</style>