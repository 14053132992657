var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CenterHorizontalLayout', {
    attrs: {
      "voipaccount": _vm.voipaccount,
      "graph": false,
      "moreText": true
    },
    on: {
      "change-graph": function ($event) {
        return _vm.$emit('interface', $event);
      }
    }
  }, [_c('div', {
    staticClass: "node nodeBasic w-100",
    attrs: {
      "slot": "main"
    },
    slot: "main"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('div', {
    staticClass: "sharedWithHeading"
  }, [_vm._v("Members")]), _c('vb-icon', {
    attrs: {
      "icon": "multipleUsersInsideGraph-icon",
      "height": "30px",
      "width": "35px"
    }
  })], 1), _c('div', {
    staticClass: "w-100 sharedWithNumber"
  }, [_vm._v("Agents Online")]), _c('div', {
    staticClass: "w-100 sharedWithNumber"
  }, [_vm._v(_vm._s(_vm.onlinemembers) + "/" + _vm._s(_vm._f("get_property")(_vm.callqueue, 'members.length', 0)))]), _c('div', {
    staticClass: "w-100 mt-2 d-flex justify-content-end align-items-center"
  }, [_c('b-icon', {
    attrs: {
      "icon": "pencil-fill",
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.edit();
      }
    }
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }