var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-main-section ProductSection"
  }, [_c('div', {
    staticClass: "crm-main-section-topBar"
  }, [_c('h2', {
    staticClass: "crm-main-section-heading mb-0"
  }, [_vm._v(" Products ")]), _c('div', {
    staticClass: "section-topBar-middlePart"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-inputSearch-icon"
    }
  }), _c('input', {
    attrs: {
      "type": "text",
      "placeholder": "Search"
    }
  })], 1), _c('div', {
    staticClass: "section-topBar-lastPart"
  }, [_c('button', {
    staticClass: "crm-button listGridView-btn centerBtn ml-0"
  }, [_c('vb-icon', {
    staticClass: "crm-threeDotsHorizontal-icon",
    attrs: {
      "icon": "crm-threeDotsHorizontal-icon"
    }
  })], 1), _c('button', {
    staticClass: "crm-button"
  }, [_c('vb-icon', {
    staticClass: "crm-plusButton-icon",
    attrs: {
      "icon": "crm-plusButton-icon"
    }
  }), _vm._v(" Create Products ")], 1)])]), _c('div', {
    staticClass: "crm-main-section-body"
  }, [_c('div', {
    staticClass: "w-100 d-flex justify-content-end align-items-center mb-3"
  }, [_c('div', {
    staticClass: "crm-input-container mb-0"
  }, [_c('input', {
    staticClass: "w-100",
    attrs: {
      "placeholder": "Search"
    }
  }), _c('vb-icon', {
    attrs: {
      "icon": "crm-inputSearch-icon"
    }
  })], 1)]), _c('vb-table', {
    staticClass: "mt-1",
    attrs: {
      "isListEmpty": _vm.isEmpty(_vm.tableData),
      "listLength": _vm.tableData.length,
      "textNoRecord": " "
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.tableData, function (data, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: data.id
          }, [_c('td', [_c('b-form-checkbox', {
            staticClass: "crm-checkbox",
            attrs: {
              "id": "checkbox-1"
            },
            model: {
              value: data.chk,
              callback: function ($$v) {
                _vm.$set(data, "chk", $$v);
              },
              expression: "data.chk"
            }
          })], 1), _c('td', {
            staticClass: "dialer-row-title"
          }, [_c('span', [_vm._v(_vm._s(data.m1))])]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(data.m1))]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(data.m1))]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(data.m1))]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            class: `StatusInsideTable ml-auto ${index == 2 ? 'active' : 'notActive'}`
          })])]);
        });
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', [_c('b-form-checkbox', {
    staticClass: "crm-checkbox",
    attrs: {
      "id": "checkbox-1"
    }
  })], 1), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    staticClass: "dialer-sorted-by ascending"
  }, [_vm._v("Name")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("SKU")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Unit Price")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Tax Rate")])]), _c('th', {
    staticClass: "dialer-col-right cursor_pointer"
  }, [_c('span', [_vm._v("Active")])])])])], 2)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }