<template>
  <modal
    class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
    width="50%" 
    :scrollable="true" 
    height="auto"  
    :name="modalName" 
    @before-open="onBeforeOpen($event)" 
    @opened="onOpened()" 
    @before-close="onBeforeClose()"
  >
    <b-form @submit.prevent="createDeal()">
      <b-form-input v-model="forms.create_deal.name" placeholder="Enter Name" />
      <p v-if="(forms.create_deal.submitted && $v.forms.create_deal.name.$invalid) || api.create_deal.validation_errors.name" class="text text-danger animated bounceIntop">
        <span v-if="!$v.forms.create_deal.name.required">Name is required</span>
        <span v-for="(em,i) in api.create_deal.validation_errors.name" :key="i">{{ em }}</span>
      </p>
      <v-select 
        ref="pipelines"
        placeholder="Select Pipeline" 
        v-model="forms.create_deal.pipeline" 
        :options="response.pipelines" 
        label="name" 
        :filterable="false" 
        @search="fetchPiplines()" 
        @option:selected="fetchPipline()"
      >
        <template slot="no-options">type to search pipeline</template>
        <template slot="option" slot-scope="option">{{ option.name }}</template>
        <template slot="selected-option" slot-scope="option">{{ option.name }}</template>
      </v-select>
      <p v-if="(forms.create_deal.submitted && $v.forms.create_deal.pipeline.$invalid) || api.create_deal.validation_errors.pipeline_id" class="text text-danger animated bounceIntop">
        <span v-if="!$v.forms.create_deal.pipeline.required">Pipeline is required</span>
        <span v-for="(em,i) in api.create_deal.validation_errors.pipeline_id" :key="i">{{ em }}</span>
      </p>
      <v-select ref="stages" v-model="forms.create_deal.stage" :options="stages" label="name" placeholder="Select Stage">
        <template slot="no-options">There is no stage</template>
        <template slot="option" slot-scope="option">{{ option.name }}</template>
        <template slot="selected-option" slot-scope="option">{{ option.name }}</template>
      </v-select>
      <p v-if="(forms.create_deal.submitted && $v.forms.create_deal.stage.$invalid) || api.create_deal.validation_errors.stage_id" class="text text-danger animated bounceIntop">
        <span v-if="!$v.forms.create_deal.stage.required">Stage is required</span>
        <span v-for="(em,i) in api.create_deal.validation_errors.stage_id" :key="i">{{ em }}</span>
      </p>
      <b-form-input v-model="forms.create_deal.amount" placeholder="Enter Amount" />
      <p v-if="(forms.create_deal.submitted && $v.forms.create_deal.amount.$invalid) || api.create_deal.validation_errors.amount" class="text text-danger animated bounceIntop">
        <span v-if="!$v.forms.create_deal.amount.numeric">Amount should be numeric</span>
        <span v-for="(em,i) in api.create_deal.validation_errors.amount" :key="i">{{ em }}</span>
      </p>
      <vue-ctk-date-time-picker 
        v-bind="ctkDateTimePickerOptions" 
        :disabled="api.create_deal.send" 
        :format="'YYYY-MM-DD HH:mm'" 
        v-model="forms.create_deal.expected_close_date"  
      />
      <p v-if="api.create_deal.validation_errors.expected_close_date" class="text text-danger animated bounceIntop">
        <span v-for="(em,i) in api.create_deal.validation_errors.expected_close_date" :key="i">{{ em }}</span>
      </p>
      <v-select ref="users" v-model="forms.create_deal.user" :options="voipusers" label="user.display_name" placeholder="Select User">
        <template slot="no-options">There is no user</template>
        <template slot="option" slot-scope="option">{{ option.user.display_name }}</template>
        <template slot="selected-option" slot-scope="option">{{ option.user.display_name }}</template>
      </v-select>
      <p v-if="api.create_deal.validation_errors.user_id" class="text text-danger animated bounceIntop">
        <span v-for="(em,i) in api.create_deal.validation_errors.user_id" :key="i">{{ em }}</span>
      </p>
      <v-select 
        ref="companies" 
        placeholder="Select Companies"
        v-model="forms.create_deal.companies" 
        :options="response.companies" 
        label="name" 
        multiple 
        :filterable="false" 
        @search="fetchCompanies()"
      >
        <template slot="no-options">There is no company</template>
        <template slot="option" slot-scope="option">{{ option.name }}</template>
        <template slot="selected-option" slot-scope="option">{{ option.name }}</template>
      </v-select>
      <p v-if="api.create_deal.validation_errors.companies" class="text text-danger animated bounceIntop">
        <span v-for="(em,i) in api.create_deal.validation_errors.companies" :key="i">{{ em }}</span>
      </p>
      <v-select 
        ref="contacts" 
        placeholder="Select Contacts"
        v-model="forms.create_deal.contacts" 
        :options="response.contacts" 
        label="name" 
        multiple 
        :filterable="false" 
        @search="fetchContacts()"
      >
        <template slot="no-options">There is no contact</template>
        <template slot="option" slot-scope="option">{{ option.name }}</template>
        <template slot="selected-option" slot-scope="option">{{ option.name }}</template>
      </v-select>
      <p v-if="api.create_deal.validation_errors.contacts" class="text text-danger animated bounceIntop">
        <span v-for="(em,i) in api.create_deal.validation_errors.contacts" :key="i">{{ em }}</span>
      </p>
      <b-button type="submit">
        Submit
      </b-button>
    </b-form>
  </modal>
</template>

<script>
import axios from 'axios'
import { VOIP_API } from '@/utils'
import { mapGetters, mapState } from 'vuex'
import { GET_CRM_USER } from '@/store/helper/getters'
import required from 'vuelidate/lib/validators/required'
import numeric from 'vuelidate/lib/validators/numeric'
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { ctkDateTimePicker } from '@/mixin'
export default {
  name: 'CreateDealModal',
  mixins: [ctkDateTimePicker],
  components: {
    vSelect,
  },
  props: {
    modalName: {
      type: String,
      default: 'CreateDeal',
    },
  },
  data() {
    return {
      forms: {
        create_deal: this.$helperFunction.formInstance({
          data: {
            name: '',
            pipeline: null,
            stage: null,
            amount: '',
            expected_close_date: '',
            user: null,
            companies: [],
            contacts: [],
          },
        })
      },
      api: {
        create_deal: this.$helperFunction.apiInstance({ 
          error_message: true,
          validation_errors: true, 
        }),
        fetch_piplines: this.$helperFunction.apiInstance({ 
          source: true, 
        }),
        fetch_pipline: this.$helperFunction.apiInstance({ 
          source: true, 
        }),
        fetch_companies: this.$helperFunction.apiInstance({ 
          source: true, 
        }),
        fetch_contacts: this.$helperFunction.apiInstance({ 
          source: true, 
        }),
      },
      response: {
        pipelines: [],
        pipeline: {},
        companies: [],
        contacts: [],
      },
    }
  },
  computed: {
    ...mapGetters([
      GET_CRM_USER,
    ]),
    ...mapState({
      voipusers: (state) => Object.values(state.common.voipusers),
    }),
    stages(){ return this.response.pipeline?.stages ?? [] },
  },
  validations: {
    forms: {
      create_deal: {
        name: {
          required: required,
        },
        pipeline: {
          required: required,
        },
        stage: {
          required: required,
        },
        amount: {
          numeric: numeric,
        },
        expected_close_date: {
          
        },
        user: {
          
        },
        companies: {

        },
        contacts: {
          
        },
      },
    },
  },
  inject: [
    'appNotify',
  ],
  methods: {
    onBeforeOpen() {
      // TODO - 
    },
    onOpened(){
      this.fetchPiplines()
    },
    onBeforeClose() {
      this.forms.create_deal.reset()
      this.api.create_deal.reset()
      this.api.fetch_piplines.reset()
      this.api.fetch_pipline.reset()
      this.api.fetch_companies.reset()
      this.api.fetch_contacts.reset()
      this.response.pipelines=[]
      this.response.pipeline={}
      this.response.companies=[]
      this.response.contacts=[]
    },
    async createDeal(){
      this.forms.create_deal.submitted=true
      this.$v.forms.create_deal.$touch()
      if(this.api.create_deal.send || this.$v.forms.create_deal.$invalid) return;
      try {
        this.api.create_deal.send=true
        this.api.create_deal.error_message=''
        this.api.create_deal.validation_errors={}
        await VOIP_API.endpoints.crm.deals.create({
          billable: {
            tax_type: null,
            products: []
          },
          name: this.forms.create_deal.name,
          pipeline_id: this.forms.create_deal.pipeline?.id ?? '',
          stage_id: this.forms.create_deal.stage?.id ?? '',
          amount: this.forms.create_deal.amount,
          expected_close_date: this.forms.create_deal.expected_close_date,
          user_id: this.GET_CRM_USER.id, // this.forms.create_deal.user?.id ?? '',
          companies: this.forms.create_deal.companies,
          contacts: this.forms.create_deal.contacts
        })
        this.$emit('created')
        this.$modal.hide(this.modalName)
      } catch (ex) {
        this.api.create_deal.error_message=ex.own_message
        this.api.create_deal.validation_errors=ex.own_errors
      } finally {
        this.api.create_deal.send=false
        this.forms.create_deal.submitted=false
      }
    },
    async fetchPiplines(){
      const search = this.$refs.pipelines?.search ?? ''
      const loading = this.$refs.pipelines?.toggleLoading ?? ''
      this.api.fetch_piplines.source?.cancel?.("Requesting Again")
      this.api.fetch_piplines.source=axios.CancelToken.source()
      try {
        this.api.fetch_piplines.send=true
        loading?.(true)
        const { data: { data: pipelines } } = await VOIP_API.endpoints.crm.pipelines.list({
          q: search,
          per_page: 10,
        },this.api.fetch_piplines.source.token)
        this.response.pipelines=pipelines
      } catch (ex) {
        if(!ex.cancel){
          this.appNotify({
            message: ex.own_message,
            type: 'danger',
          })
        }
      } finally {
        loading?.(false)
        this.api.fetch_piplines.send=false
        this.api.fetch_piplines.source=null
      }
    },
    async fetchPipline(){
      const loading = this.$refs.stages?.toggleLoading ?? ''
      if(this.api.fetch_pipline.send) return;
      try {
        this.api.fetch_pipline.send=true
        loading?.(true)
        const { data: pipeline } = await VOIP_API.endpoints.crm.pipelines.findBy(this.forms.create_deal.pipeline.id)
        this.response.pipeline=pipeline
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        loading?.(false)
        this.api.fetch_pipline.send=false
      }
    },
    async fetchCompanies(){
      const search = this.$refs.companies?.search ?? ''
      const loading = this.$refs.companies?.toggleLoading ?? ''
      this.api.fetch_companies.source?.cancel?.("Requesting Again")
      this.api.fetch_companies.source=axios.CancelToken.source()
      try {
        this.api.fetch_companies.send=true
        loading?.(true)
        const { data: { data: companies } } = await VOIP_API.endpoints.crm.companies.list({
          q: search,
          per_page: 10,
        },this.api.fetch_companies.source.token)
        this.response.companies=companies
      } catch (ex) {
        if(!ex.cancel){
          this.appNotify({
            message: ex.own_message,
            type: 'danger',
          })
        }
      } finally {
        loading?.(false)
        this.api.fetch_companies.send=false
        this.api.fetch_companies.source=null
      }
    },
    async fetchContacts(){
      const search = this.$refs.contacts?.search ?? ''
      const loading = this.$refs.contacts?.toggleLoading ?? ''
      this.api.fetch_contacts.source?.cancel?.("Requesting Again")
      this.api.fetch_contacts.source=axios.CancelToken.source()
      try {
        this.api.fetch_contacts.send=true
        loading?.(true)
        const { data: { data: contacts } } = await VOIP_API.endpoints.crm.contacts.list({
          q: search,
          per_page: 10,
        },this.api.fetch_contacts.source.token)
        this.response.contacts=contacts
      } catch (ex) {
        if(!ex.cancel){
          this.appNotify({
            message: ex.own_message,
            type: 'danger',
          })
        }
      } finally {
        loading?.(false)
        this.api.fetch_contacts.send=false
        this.api.fetch_contacts.source=null
      }
    },
  },
}
</script>

<style>

</style>