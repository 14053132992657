<template>
  <div>
    <b-icon icon="telephone-fill" variant="primary"/>
  </div>
</template>

<script>
export default {
  name: 'CallForwardingIcon',
}
</script>

<style>

</style>