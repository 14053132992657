<template>
  <CenterHorizontalLayout :voipaccount="voipaccount" :graph="false" :moreText="true" @change-graph="$emit('interface',$event)">
    <div class="node nodeBasic w-100" slot="main">
      <div class="d-flex justify-content-between align-items-center">
        <div class="sharedWithHeading">Members</div>
        <vb-icon icon="multipleUsersInsideGraph-icon" height="30px" width="35px"/>
      </div>
      <div class="w-100 sharedWithNumber">Agents Online</div>
      <div class="w-100 sharedWithNumber">{{ onlinemembers }}/{{callqueue | get_property('members.length',0)}}</div>
      <div class="w-100 mt-2 d-flex justify-content-end align-items-center">
        <b-icon @click="edit()" icon="pencil-fill" variant="primary" />
      </div>
    </div>
  </CenterHorizontalLayout>
</template>

<script>
import CenterHorizontalLayout from '../layouts/CenterHorizontal.vue';
import { mapGetters } from 'vuex';
export default {
  name: 'MemberNode',
  components: {
    CenterHorizontalLayout,
  },
  props:{
    voipaccount: {
      type: String
    },
  },
  computed: {
    ...mapGetters([
      'getVoipCallQueues',
    ]),
    callqueue(){ return this.getVoipCallQueues.find(call_queue=>this.voipaccount==call_queue?.voipaccount || this.voipaccount==call_queue?.extn) },
    onlinemembers(){ return this.callqueue?.members?.filter?.(i=>!!this.callqueue?.call_queue_status?.[i.membername]?.logged_in)?.length ?? 0 },
  },
  methods: {
    edit(){
      this.$emit('interface',{
        from: 'call_queue_member',
        event: 'edit_members'
      })
    },
  },
}
</script>

<style>

</style>