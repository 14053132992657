var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup addExtension-voicemailModal addRemove_userModal m-w-700 EditOutOfHoursDayModal bigSquareCheckbox min-width-50percent-modal",
    attrs: {
      "name": _vm.modalName,
      "scrollable": true,
      "height": "auto"
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('section', [_c('div', {
    staticClass: "dialer-edit-header d-block"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v(_vm._s(_vm._f("getFullDayName")(_vm.data.day)) + " business hours")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v("Configure your business hours settings for a specific day.")])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Select for " + _vm._s(_vm._f("getFullDayName")(_vm.data.day)) + " if you are...")]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox withOutPadding mt-12px"
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    staticStyle: {
      "position": "relative",
      "pointer-events": "auto"
    }
  }, [_c('div', {
    staticClass: "w-100"
  }, [_c('b-form-radio', {
    ref: "available_radio",
    staticClass: "whiteBoxWithBorderInsideGreyBox-row withHover checkBoxOrRadioInside position-relative makeCheckBoxesSquare",
    class: _vm.data.type === 'availble' ? 'active' : '',
    attrs: {
      "checked": _vm.data.type,
      "value": "availble",
      "disabled": _vm.api.update_schedule.send
    },
    on: {
      "change": function ($event) {
        return _vm.updateSchedule({
          type: 'availble'
        });
      }
    }
  }, [_c('div', {
    staticClass: "w-100 dialer-call-option-c withRadio",
    class: {
      'active': _vm.data.type === 'availble'
    }
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Available all day")])])]), _c('b-form-radio', {
    ref: "unavailable_radio",
    staticClass: "whiteBoxWithBorderInsideGreyBox-row withHover checkBoxOrRadioInside position-relative makeCheckBoxesSquare b-b",
    class: _vm.data.type === 'unavailable' ? 'active' : '',
    attrs: {
      "checked": _vm.data.type,
      "value": "unavailable",
      "disabled": _vm.api.update_schedule.send
    },
    on: {
      "change": function ($event) {
        return _vm.updateSchedule({
          type: 'unavailable'
        });
      }
    }
  }, [_c('div', {
    staticClass: "w-100 dialer-call-option-c withRadio",
    class: {
      'active': _vm.data.type === 'unavailable'
    }
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Unavailable all day")])])]), _c('div', {
    staticClass: "position-relative",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm.randomId}-TimeSelectionModal`, {
          start: _vm.data.start,
          end: _vm.data.end
        });
      }
    }
  }, [_c('b-form-radio', {
    ref: "custom_radio",
    staticClass: "whiteBoxWithBorderInsideGreyBox-row withHover checkBoxOrRadioInside position-relative makeCheckBoxesSquare",
    class: _vm.data.type === 'custom' ? 'active' : '',
    attrs: {
      "checked": _vm.data.type,
      "value": "custom",
      "disabled": _vm.api.update_schedule.send
    }
  }, [_c('div', {
    staticClass: "w-100 dialer-call-option-c withRadio",
    class: {
      'active': _vm.data.type === 'custom'
    }
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v(" Unavailable between "), _vm.data.start && _vm.data.end ? _c('span', {
    staticClass: "text-decoration-underline"
  }, [_vm._v(_vm._s(_vm.data.start) + " - " + _vm._s(_vm.data.end))]) : _vm._e()])])])], 1)], 1)])], 1), ['unavailable', 'custom'].includes(_vm.data.type) ? [_vm.forwardSetting.is_default ? _c('div', {
    staticClass: "d-flex flex-column w-100 mt-20px"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main mb-0"
  }, [_vm._v("What happens to calls after business hours ?")]), _vm.defaultForwardSetting.forward_note == 'none' ? [_c('div', {
    staticClass: "latestGreyBox-descriptionText-18px"
  }, [_vm._v("Keep ringing")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText-14px"
  }, [_vm._v("Call forwarding is off")])] : _vm._e(), _vm.defaultForwardSetting.forward_note == 'voicemail' ? [_c('div', {
    staticClass: "latestGreyBox-descriptionText-18px"
  }, [_vm._v("Ask to leave a voicemail")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText-14px"
  }, [_vm._v("Call will be forward to voicemail")])] : _vm._e(), _vm.defaultForwardSetting.forward_note == 'extension' ? [_c('div', {
    staticClass: "latestGreyBox-descriptionText-18px"
  }, [_vm._v("Forward to a user, team or IVR menu")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText-14px"
  }, [_vm._v(" Call will be forward to "), _c('span', {
    staticClass: "color-primary cursor_pointer",
    class: {
      'text-underline': !_vm.defaultForwardSetting.extension_name
    }
  }, [_vm._v(_vm._s(_vm.defaultForwardSetting.extension_name || 'Select extension'))])])] : _vm._e(), _vm.defaultForwardSetting.forward_note == 'number' ? [_c('div', {
    staticClass: "latestGreyBox-descriptionText-18px"
  }, [_vm._v("Forward to another number")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText-14px"
  }, [_vm._v(" Call will be forward to "), _c('span', {
    staticClass: "color-primary cursor_pointer",
    class: {
      'text-underline': !_vm.defaultForwardSetting.forward_number
    }
  }, [_vm._v(_vm._s(_vm.defaultForwardSetting.forward_number || 'Select number'))])])] : _vm._e()], 2) : _vm._e(), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start min-height-unset"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between w-100"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Customize business hour behavior for " + _vm._s(_vm._f("getFullDayName")(_vm.data.day)))]), _c('b-form-checkbox', {
    ref: "default-toggle",
    staticClass: "newerSwitch ml-3",
    attrs: {
      "disabled": _vm.api.update_schedule.send,
      "switch": "",
      "checked": !_vm.forwardSetting.is_default
    },
    on: {
      "change": function ($event) {
        return _vm.updateSchedule({
          default: !$event
        });
      }
    }
  })], 1), !_vm.forwardSetting.is_default ? _c('div', {
    staticClass: "d-flex align-items-center justify-content-between w-100 mt-5"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main mb-0"
  }, [_vm._v("What happens to calls after business hours ?")]), _vm.forwardSetting.forward_note == 'none' ? [_c('div', {
    staticClass: "latestGreyBox-descriptionText-18px"
  }, [_vm._v("Keep ringing")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText-14px"
  }, [_vm._v("Call forwarding is off")])] : _vm._e(), _vm.forwardSetting.forward_note == 'voicemail' ? [_c('div', {
    staticClass: "latestGreyBox-descriptionText-18px"
  }, [_vm._v("Ask to leave a voicemail")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText-14px"
  }, [_vm._v("Call will be forward to voicemail")])] : _vm._e(), _vm.forwardSetting.forward_note == 'extension' ? [_c('div', {
    staticClass: "latestGreyBox-descriptionText-18px"
  }, [_vm._v("Forward to a user, team or IVR menu")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText-14px"
  }, [_vm._v(" Call will be forward to "), _c('span', {
    staticClass: "color-primary cursor_pointer text-decoration-underline",
    class: {
      'text-underline': !_vm.forwardSetting.extension_name
    },
    on: {
      "click": function ($event) {
        _vm.api.update_schedule.send ? '' : _vm.$modal.show(`${_vm.randomId}-ScheduleAssignModal`, {
          account: _vm.forwardSetting.forward_number,
          type: 'setting'
        });
      }
    }
  }, [_vm._v(_vm._s(_vm.forwardSetting.extension_name || 'Select extension'))])])] : _vm._e(), _vm.forwardSetting.forward_note == 'number' ? [_c('div', {
    staticClass: "latestGreyBox-descriptionText-18px"
  }, [_vm._v("Forward to another number")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText-14px"
  }, [_vm._v(" Call will be forward to "), _c('span', {
    staticClass: "color-primary cursor_pointer text-decoration-underline",
    class: {
      'text-underline': !_vm.forwardSetting.forward_number
    },
    on: {
      "click": function ($event) {
        _vm.api.update_schedule.send ? '' : _vm.$modal.show(`${_vm.randomId}-CallForwardingNumberInputModal`);
      }
    }
  }, [_vm._v(_vm._s(_vm.forwardSetting.forward_number || 'Select number'))])])] : _vm._e()], 2), _c('button', {
    staticClass: "newButton",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.api.update_schedule.send ? '' : _vm.$modal.show(`${_vm.randomId}-CallForwardSettingModal`, {
          account: _vm.account,
          accountcode: _vm.account,
          setting: _vm.forwardSetting,
          is_ringing_show: _vm.selectedSchedule.type == 'availble',
          is_sec_show: false,
          day: _vm.data.day
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change")])], 1)]) : _vm._e()])])] : _vm._e()], 2)]), _c('TimeSelectionModal', {
    attrs: {
      "modalName": `${_vm.randomId}-TimeSelectionModal`
    },
    on: {
      "updated": function ($event) {
        _vm.data.start = `${$event.start.data.HH}:${$event.start.data.mm}:${'00'}`;
        _vm.data.end = `${$event.end.data.HH}:${$event.end.data.mm}:${'00'}`;
        _vm.updateSchedule({
          start: $event.start,
          end: $event.end,
          type: 'custom'
        });
      },
      "before-close": function ($event) {
        _vm.$refs.available_radio.localChecked = _vm.data.type;
        _vm.$refs.unavailable_radio.localChecked = _vm.data.type;
        _vm.$refs.custom_radio.localChecked = _vm.data.type;
      }
    }
  }), _c('CallForwardSettingModal', {
    attrs: {
      "modalName": `${_vm.randomId}-CallForwardSettingModal`
    },
    on: {
      "updated": function ($event) {
        return _vm.updateSchedule($event);
      }
    }
  }), _c('ScheduleAssignModal', {
    attrs: {
      "modalName": `${_vm.randomId}-ScheduleAssignModal`,
      "account": _vm.account
    },
    on: {
      "interface": function ($event) {
        return _vm.updateSchedule({
          extension_name: $event.accountname,
          forward_number: $event.account
        });
      }
    }
  }), _c('CallForwardingNumberInputModal', {
    attrs: {
      "modalName": `${_vm.randomId}-CallForwardingNumberInputModal`
    },
    on: {
      "number": function ($event) {
        return _vm.updateSchedule({
          forward_number: $event.ber
        });
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }