var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: `node nodeBasic ${_vm.node == 'OutOfHours' ? 'OutOfHours-node' : ''} ${_vm.node == 'LineText' ? 'LineText-node' : ''} 
  ${_vm.node == 'TeamMember' ? 'TeamMember-node' : ''} ${_vm.node == 'NumberAssign' ? 'NumberAssign-node' : ''} ${_vm.node == 'NumberSharedWith' ? 'NumberSharedWith-node' : ''}
  ${_vm.node == 'DTMF' ? 'DTMF-node' : ''} ${_vm.node == 'CallQueueMember' ? 'CallQueueMember-node' : ''} ${_vm.node == 'AdvanceOptions' ? 'AdvanceOptions-node' : ''}
  ${_vm.node == 'WelcomeGreeting' ? 'WelcomeGreeting-node' : ''}`
  }, [_c('div', {
    staticClass: "node__head is-flex has-space-between is-vcenter"
  }, [_c('div', {
    style: 'display:flex;'
  }, [_c(_vm.node, _vm._b({
    tag: "component",
    on: {
      "interface": _vm.passdataparent
    }
  }, 'component', _vm.$props, false))], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }