<template>
  <div class="crm-main-section ProductSection">
    <div class="crm-main-section-topBar">
      <h2 class="crm-main-section-heading mb-0">
        Products
      </h2>
      <div class="section-topBar-middlePart">
        <vb-icon icon="crm-inputSearch-icon" />
        <input type="text" placeholder="Search">
      </div>
      <div class="section-topBar-lastPart">
        <button class="crm-button listGridView-btn centerBtn ml-0">
          <vb-icon class="crm-threeDotsHorizontal-icon" icon="crm-threeDotsHorizontal-icon" />
        </button>
        <button class="crm-button">
          <vb-icon class="crm-plusButton-icon" icon="crm-plusButton-icon" />
          Create Products
        </button>
      </div>
    </div>
    <div class="crm-main-section-body">
      <div class="w-100 d-flex justify-content-end align-items-center mb-3">
        <div class="crm-input-container mb-0">
          <input placeholder="Search" class="w-100"/>
          <vb-icon icon="crm-inputSearch-icon" />
        </div>
        <!-- <div class="totalsTextOnMain">
          1 Products
        </div> -->
      </div>
      <vb-table class="mt-1" :isListEmpty="isEmpty(tableData)" :listLength="tableData.length" textNoRecord=" ">
        <template slot="header">
          <tr>
            <th>
              <b-form-checkbox class="crm-checkbox" id="checkbox-1"></b-form-checkbox>
            </th>
            <th class="dialer-has-sort">
              <span class="dialer-sorted-by ascending">Name</span>
            </th>
            <th class="dialer-has-sort">
              <span>SKU</span>
            </th>
            <th class="dialer-has-sort">
              <span>Unit Price</span>
            </th>
            <th class="dialer-has-sort">
              <span>Tax Rate</span>
            </th>
            <th class="dialer-col-right cursor_pointer">
              <span>Active</span>
            </th>
          </tr>
        </template>
        <template #body="{ start, end }">
          <tr v-for="(data, index) in tableData" :key="data.id" v-show="index >= start && index <= end">
            <td>
              <b-form-checkbox class="crm-checkbox" v-model="data.chk" id="checkbox-1"></b-form-checkbox>
            </td>
            <td class="dialer-row-title">
              <span>{{ data.m1 }}</span>
            </td>
            <td class="dialer-row-title">{{ data.m1 }}</td>
            <td class="dialer-row-title">{{ data.m1 }}</td>
            <td class="dialer-row-title">{{ data.m1 }}</td>
            <td class="dialer-col-right">
              <div :class="`StatusInsideTable ml-auto ${index == 2 ? 'active' : 'notActive'}`"></div>
            </td>
          </tr>
        </template>
      </vb-table>
    </div>
    

  </div>
</template>

<script>
export default {
  name: 'Products',
  components: {
    
  },
  inject:['isEmpty'],
  data(){
    return {
      tableData : {
        0: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        1: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        2: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        3: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        4: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        5: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        6: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        7: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        8: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        9: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        10: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
      }
    }
  },
  computed: {
    
  },
  methods: {
    
  },
  mounted(){
    
  },
}
</script>

<style>

</style>