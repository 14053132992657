<template>
  <HorizontalLayout :bottomline="true" @change-graph="$emit('interface',$event)">
    <div slot="right" >
      <info :id="voipaccount" :is_blf="false" class="align-items-center" />
    </div>
  </HorizontalLayout>
</template>

<script>
import Info from '@/components/Lists/Info.vue';
import HorizontalLayout from '../layouts/Horizontal.vue'
import { mapGetters } from 'vuex';
export default {
  name: 'MemberNode',
  components: {
    Info,
    HorizontalLayout,
  },
  props:{
    voipaccount: {
      type: String
    },
  },
  data(){
    return{
      show_more: false
    }
  },
  computed: {
    ...mapGetters([
      'getVoipUsersAlises',
      'getVoipTeams',
      'getVoipCallQueues',
      'getVoipIVRSAlises',
    ]),
    changeModalName(){ return `${this.user_dtmf}${this.account_name}MenuOptionExtensionAssignModal`.replaceAll(' ','') },
    info() {
      const key = this.voipaccount ?? ''
      const user = this.getVoipUsersAlises[key];
      const team = this.getVoipTeams.find(team=>key==team?.ringroup?.accountcode || key==team?.real_id || key==team?.ringroup?.extn)
      const call_queue = this.getVoipCallQueues.find(call_queue=>key==call_queue?.voipaccount || key==call_queue?.extn)
      const ivr = this.getVoipIVRSAlises[key]
      if(team) return { data: team, type: 'team' }
      else if(call_queue) return { data: call_queue, type: 'call_queue' }
      else if(ivr) return { data: ivr, type: 'ivr' }
      else if(user) return { data: user, type: 'user' }
      else return null;
    },
  },
}
</script>

<style>

</style>