<template>
  <div class="dialer-settings-main">
    <section class="dialer-settings-section dialer-voicemail-settings mt-0">
      <div class="latestGreyBox-9-9-2023 mt-20px">
        <div class="latestGreyBox-heading-main">Voicemail settings</div>
        <div class="latestGreyBox-descriptionText">Manage your voicemail settings here.</div>
        <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
          <UserRecordedVoicemail :accountcode="accountcode" class="s2-mobile" />
          <div v-if="!isTeam" class="whiteBoxWithBorderInsideGreyBox-row flex-column justify-content-center align-items-start">
            <div class="w-100 d-flex justify-content-between align-items-center">
              <div>
                <!-- <div class="latestGreyBox-heading-main">Voicemail pin enabled</div> -->
                <div class="latestGreyBox-heading-main">Voicemail PIN</div>
                <div class="latestGreyBox-descriptionText">Enabling PIN access requires entering a PIN code each time you access your voicemail.</div>
              </div>
              <div class="w-fit-content">
                <div v-if="api.update_voicemail_setting.send || api.fetch_settings.send" class="latestShimmerDesign" style="height:34px;width:60px;border-radius: 25px"></div>
                <div 
                  v-else 
                  v-b-tooltip :title="getUserPermissions.enabling_pin_for_voicemail?'':'Your package does not support this feature'"
                >
                  <b-form-checkbox 
                    ref="password_enable"
                    class="newerSwitch" 
                    :disabled="!getUserPermissions.enabling_pin_for_voicemail || api.update_voicemail_setting.send || api.fetch_settings.send" 
                    v-model="forms.update_voicemail_setting.password_enable" 
                    @change="updateVoicemailSettingDebounce()" 
                    name="check-button" 
                    switch 
                  />
                </div>
              </div>
            </div>
            <template  v-if="forms.update_voicemail_setting.password_enable">
              <div class="w-100 d-flex justify-content-between align-items-center mt-20px">
                <div class="latestGreyBox-descriptionText">{{forms.update_voicemail_setting.pin}}</div>
                <div 
                  v-b-tooltip :title="getUserPermissions.enabling_pin_for_voicemail?'':'Your package does not support this feature'"
                  :class="{
                    'for-disable': !getUserPermissions.enabling_pin_for_voicemail
                  }"
                >
                  <a 
                    class="newButton" 
                    v-b-tooltip :title="getUserPermissions.enabling_pin_for_voicemail?'':'Your package does not support this feature'"
                    @click="!getUserPermissions.enabling_pin_for_voicemail?'':$modal.show(`${_uid}-updatepincode`)"
                  >
                    <vb-icon icon="squared-update-icon" height="38px" width="38px" />
                    <span class="newButton-textPart">Update pin</span>
                  </a>
                </div>
              </div>
            </template>
          </div>
          <div v-if="!isTeam" class="whiteBoxWithBorderInsideGreyBox-row">
            <div class="d-flex flex-column">
              <!-- <div class="latestGreyBox-heading-main">Mailbox shared accounts settings</div>
              <div class="latestGreyBox-descriptionText">Mailbox shared with</div> -->
              <div class="latestGreyBox-heading-main">Share voicemail mailbox</div>
              <div class="latestGreyBox-descriptionText">Assign other users to receive your voicemail for improved efficiency.</div>
            </div>
            <div 
              v-b-tooltip :title="getUserPermissions.voice_mailbox_sharing?'':'Your package does not support this feature'"
              :class="{
                'for-disable': !getUserPermissions.voice_mailbox_sharing
              }"
            >
              <a 
                class="newButton"
                :disabled="!getUserPermissions.voice_mailbox_sharing"
                @click.stop="
                  !getUserPermissions.voice_mailbox_sharing ? '' :
                  $modal.show('AddUserWithExtensionModal', {
                    availableUsers: response.available_members,
                    savedUsers: response.selected_members,
                    type: 'AddUserWithExtensionModal'
                  })
                "
              >
                <vb-icon icon="squared-singleUser-icon" height="38px" width="38px" />
                <span class="newButton-textPart">
                  <template v-if="selectedMemebersLength > 0">{{ selectedMemebersLength }}</template>
                  <template v-else> Add </template> users
                </span>
              </a>
            </div>
          </div>
          <div class="whiteBoxWithBorderInsideGreyBox-row">
            <div class="d-flex flex-column">
              <div class="latestGreyBox-heading-main">Transcript my voicemail</div>
              <div class="latestGreyBox-descriptionText">Converts voicemails into text for easy viewing in call activity or email.</div>
            </div>
            <div class="w-fit-content">
              <div v-if="api.fetch_notification_settings.send" class="latestShimmerDesign" style="height:34px;width:60px;border-radius: 25px"></div>
              <b-form-checkbox
                v-else
                class="newerSwitch"
                ref="voicemail_transcript"
                v-b-tooltip :title="getUserPermissions.voice_mail_transcript?'':'Your package does not support this feature'"
                :disabled="api.change_voicemail_transcript.send || !getUserPermissions.voice_mail_transcript || api.fetch_notification_settings.send"
                :checked="data.voicemail_transcript"
                @change="changeVoicemailTranscript($event)"
                name="check-button"
                switch
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <AddRemoveFromList @closed="fetchSettings()" :accountcode="accountcode" modalName="AddUserWithExtensionModal" />
    <modal 
      class="center-zoomIn-transition v2 m-w-500 AddTeamModal" 
      width="500px" 
      height="auto" 
      :scrollable="true" 
      :name="`${_uid}-updatepincode`" 
    >
      <div class="w-100">
        <div class="dialer-edit-header pt-0 w-100">
          <div class="d-flex align-items-center">
            <h2 class="dialer-settings-title newer mb-0">Voicemail PIN</h2>
          </div>
          <div class="dialer-edit-actions d-flex align-items-center">
            <!-- <a class="dialer-button dialer-button-delete" @click="$modal.hide(`${_uid}-updatepincode`)">Close</a> -->
            <a class="newCloseButton" @click="$modal.hide(`${_uid}-updatepincode`)">
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </a>
          </div>
        </div>
        <div v-if="forms.update_voicemail_setting.password_enable" class="latestGreyBox-9-9-2023 d-flex align-items-center  w-100 mt-20px">
          <div class="whiteBGinputWithGreyRoundedBorder w-100">
            <label>Pin for voicemail</label>
            <vb-pin-code 
              :numberslength="$v.forms.update_voicemail_setting.pin.$params.minLength.max" 
              :value="forms.update_voicemail_setting.pin" 
              @change="forms.update_voicemail_setting.pin = $event;" 
              :disabled="api.update_voicemail_setting.send || !forms.update_voicemail_setting.password_enable || api.fetch_settings.send" 
            />
            <template v-if="(forms.update_voicemail_setting.submitted && $v.forms.update_voicemail_setting.pin.$invalid) || api.update_voicemail_setting.validation_errors.mailboxpin">
              <small class="danger field-error bold-4" v-for="(em,i) in api.update_voicemail_setting.validation_errors.mailboxpin" :key="i">{{em}}</small>
              <small class="danger sm-mar-top" v-if="!$v.forms.update_voicemail_setting.pin.required">*Pin is required</small>
              <small class="danger sm-mar-top" v-if="!$v.forms.update_voicemail_setting.pin.minLength">*Pin should be {{ $v.forms.update_voicemail_setting.pin.$params.minLength.max }} digit</small>
            </template>
          </div>
        </div>
        <div class="d-flex justify-content-end mt-20px">
          <a class="fullWidthDoneButton" @click="updateVoicemailSettingDebounce()">
            Update
          </a>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import UserRecordedVoicemail from "./UserRecordedVoicemailCopy.vue";
import AddRemoveFromList from "../Modals/AddRemoveFromList.vue";
import { $fn, VOIP_API, } from "../../utils";
import { email, minLength, requiredIf } from "vuelidate/lib/validators";
import _ from "lodash";
import { mapGetters } from 'vuex';
export default {
  name: "VoicemailSetting",
  components: {
    UserRecordedVoicemail,
    AddRemoveFromList,
  },
  props: {
    accountcode: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      response: {
        selected_members: {},
        available_members: {},
      },
      api: {
        fetch_settings: this.$helperFunction.apiInstance(),
        fetch_notification_settings: this.$helperFunction.apiInstance(),
        update_voicemail_setting: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true }),
        change_voicemail_transcript: this.$helperFunction.apiInstance(),
      },
      data: {
        voicemail_transcript: false,
      },
      forms: {
        update_voicemail_setting: this.$helperFunction.formInstance({
          data: {
            email: '',
            pin: '',
            password_enable: false,
          }
        }),
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed:{
    ...mapGetters([ 
      'getUserPermissions',
      'getCurrentUser',
      'getVoipUsersAlises',
      'getVoipTeamsAlises',
    ]),
    user(){ return this.getVoipUsersAlises[this.accountcode] ?? this.getVoipUsersAlises[this.getCurrentUser?.account] },
    voipaccount(){ return this.user?.voipaccount ?? this.accountcode },
    userType(){ return this.user?.user?.user_type },
    selectedMemebersLength(){ return Object.values(this.response.selected_members ?? {}).length },
    isTeam(){ return !!this.getVoipTeamsAlises[this.accountcode] },
  },
  validations: {
    forms: {
      update_voicemail_setting: {
        email: {
          required: requiredIf(function () {
            return this.userType=='user' && this.forms.update_voicemail_setting.email_enable;
          }),
          email: email,
        },
        pin: {
          required: requiredIf(function () {
            return this.forms.update_voicemail_setting.password_enable;
          }),
          minLength: minLength(6),
        },
      },
    },
  },
  methods: {
    async fetchSettings() {
      if (this.api.fetch_settings.send) return;
      try {
        this.api.fetch_settings.send = true;
        const { data: res } = await VOIP_API.endpoints.voicemail.list.servicevoicemail(this.voipaccount, {
          fid: 12,
          id: this.voipaccount
        })
        this.response.selected_members = _.isPlainObject(res.detail.members.selected) ? res.detail.members.selected : {};
        this.response.available_members = _.isPlainObject(res.detail.members.available) ? res.detail.members.available : {};
        const setting = res.detail.settings;
        if(!setting) return;
        this.forms.update_voicemail_setting.email = setting.mailboxemail
        this.forms.update_voicemail_setting.pin = setting.mailboxpin || $fn.makeid(6,'number')
        this.forms.update_voicemail_setting.password_enable = setting.mailbox_password_enable!='No'
      } finally {
        if(this.$refs['password_enable']) this.$refs['password_enable'].localChecked=this.forms.update_voicemail_setting.password_enable
        this.api.fetch_settings.send = false;
      }
    },
    updateVoicemailSettingDebounce: _.debounce(function () {
      this.updateVoicemailSetting()
    }, 2 * 1000),
    async updateVoicemailSetting(){
      this.forms.update_voicemail_setting.submitted=true
      this.$v.forms.update_voicemail_setting.$touch()
      if (this.$v.forms.update_voicemail_setting.$invalid || this.api.update_voicemail_setting.send) return;
      const toaster = this.appNotify({
        message: "Saving...",
        type: "info",
        duration: 60 * 1000,
      });
      try {
        this.api.update_voicemail_setting.send=true
        this.api.update_voicemail_setting.validation_errors={}
        await VOIP_API.endpoints.voicemail.updatesetting.default(this.voipaccount, {
          mailboxpin: this.forms.update_voicemail_setting.pin,
          mailbox: this.forms.update_voicemail_setting.password_enable ? "Y" : "N",
          mailboxemail: this.userType=='user' ? this.forms.update_voicemail_setting.email : this.user?.user?.email ?? '',
        })
        this.$modal.hide(`${this._uid}-updatepincode`)
        this.appNotify({
          message: "Successfully updated",
          type: "success",
        })
      } catch (ex) {
        this.api.update_voicemail_setting.validation_errors=ex.own_errors
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
        this.fetchSettings()
      } finally {
        toaster.close();
        this.api.update_voicemail_setting.send=false
        this.forms.update_voicemail_setting.submitted=false
      }
    },
    
    async fetchNotificationSettings() {
      if(this.api.fetch_notification_settings.send) return;
      try {
        this.api.fetch_notification_settings.send=true
        const { data } = await VOIP_API.endpoints.call.getNotificationSettings(this.voipaccount)
        this.data.voicemail_transcript=data.voicemailSettings?.voicemail_transcript=='Yes'
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_notification_settings.send=false
      }
    },
    async changeVoicemailTranscript(value) {
      if(this.api.change_voicemail_transcript.send) return;
      try {
        this.api.change_voicemail_transcript.send=true
        await VOIP_API.endpoints.call.setNotificationSettings({
          field: 'voicemail_transcript',
          value: value ? 1 : 0,
          accountcode: this.voipaccountcode,
        })
        this.data.voicemail_transcript=value
        this.appNotify({
          message: 'Successfully updated',
          type: 'success'
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        if(this.$refs['voicemail_transcript']) this.$refs['voicemail_transcript'].localChecked = this.data.voicemail_transcript;
        this.api.change_voicemail_transcript.send=false
      }
    },
  },
  mounted() {
    this.fetchSettings();
    this.fetchNotificationSettings()
  },
  activated() {
    this.fetchSettings();
    this.fetchNotificationSettings()
  },
};
</script>

<style lang="scss">
.enterPinSection {
  flex-direction: column;
  .react-code-input {
    display: flex;
    input {
      margin-right: 8px;
      text-align: center;
    }
  }
}
</style>
