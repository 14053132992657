<template>
  <div class="dialer-settings-main">
    <section class="dialer-settings-section dialer-account-setting">
      <div class="dialer-settings-top">
        <template v-if="getIsMobile">
          <div class="d-flex align-items-center mb-3 calc-title-width">
            <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" @click="$emit('back')" />
            <h2 class="dialer-settings-title w-100 mb-0 text-center">Billing</h2>
          </div>
        </template>
        <h2 v-else class="dialer-settings-title newer">
          Billing
          <b-icon id="ivr-info" icon="info-circle-fill" @click="$modal.show('KbInfoModal',{ kb: 'billing' })" class="cursor_pointer infoKbIcon md-mar-left" />
        </h2>
      </div>

      <div class="dialer-settings-section_inner p-0 no-padding">
        <div v-if="isAnyUnPaidInvoice" class="innerRedBoxwithRoundedBorder mt-20px">
          <vb-icon icon="AccountBalance-newBilling-icon" height="25px" width="25px" class="AccountBalance-newBilling-icon"/>
          <div class="d-flex align-items-center justify-content-between flex-fill">
            <p class="innerRedBoxwithRoundedBorder-text mb-0">
              Unpaid invoices, please pay now so that there is no disruption of services.
            </p>
            <span @click="$modal.show(`${_uid}-unpaidInvoices`)" class="OwnertaggedVersion mb-0 showOnly-OnHover-insideTable cursor_pointer_no_hover">Pay now</span>
          </div>
        </div>
        <div class="latestBilling-section-1">
          <div class="latestBilling-section-1-card firstCard">
            <template v-if="api.subscription_detail.send">
              <vb-icon icon="PlanIcon-newBilling-icon" class="latestBilling-section-1-card-icon" />
              <div class="latestBilling-section-1-textSection">
                <div class="latestBilling-section-1-cardType">
                  <div class="latestShimmerDesign someTransparent" style="height:18px;width:50%"></div>
                </div>
                <div class="latestShimmerDesign someTransparent" style="height:24px;width:50%"></div>
                <div class="latestShimmerDesign someTransparent mt-16px" style="height:38px;width:100%"></div>
                <div class="latestBilling-section-1-lastRow mt-8px">
                  <div class="latestShimmerDesign someTransparent" style="height:18px;width:50%"></div>
                  <div class="latestShimmerDesign someTransparent ml-auto" style="height:50px;width:150px"></div>
                </div>
              </div>
            </template>
            <template v-else>
              <vb-icon icon="PlanIcon-newBilling-icon" class="latestBilling-section-1-card-icon" />
              <div class="latestBilling-section-1-textSection">
                <div class="latestBilling-section-1-cardType">
                  Current Plan
                  <span v-if="isOnTrail" class="OwnertaggedVersion mb-0 mt-0 mr-16px">{{ 'Trial' }}</span>
                  <span v-if="currentPlanSuspended" class="suspendedTag">{{ currentPlanSuspended ? 'Suspended' : '' }}</span>
                </div>
                <div class="latestBilling-section-1-PackageLabel">{{ response.subscription_detail | get_property('userCurrentSubscription.plan_detail.plan_label') }}</div>
                <div class="latestBilling-section-1-PackageText">
                  Your current plan is {{ response.subscription_detail | get_property('userCurrentSubscription.plan_detail.plan_label') }}. 
                  <template v-if="!isOnFreePlan">
                    <span v-if="response.subscription_detail && response.subscription_detail.next_billing_date && !isOnFreePlan">
                      The {{isOnTrail ? 'trial expiration date' : 'next billing date'}} is <span class="mb-0" v-b-popover.hover.top="`Subscription date: ${response.subscription_detail.current_date}`">{{ response.subscription_detail | get_property('next_billing_date') | showTime }}.</span>
                    </span>
                  </template>
                  <!-- <div v-if="response.subscription_detail && response.subscription_detail.next_billing_date && !isOnFreePlan" class="d-flex w-100 justify-content-between align-items-center flex-wrap">
                    <div class="planWhiteTag mt-8px mr-8px">
                      <span>
                        <b>{{isOnTrail ? 'Trial Expire Date' : 'Next billing date'}}:</b> {{ response.subscription_detail | get_property('next_billing_date')  }}
                      </span>
                    </div>
                    <div class="planWhiteTag mt-8px">
                      <span>
                        <b>Subscription date:</b> {{ response.subscription_detail | get_property('current_date')  }}
                      </span>
                    </div>
                  </div> -->
                </div>
                <div class="latestBilling-section-1-lastRow mt-20px">
                  <!-- <div class="latestBilling-section-1-billingDate">
                    <div class="latestBilling-section-1-billingDate-title">{{isOnTrail ? 'Trial Expire Date' : 'Next billing date'}}:</div>
                    <div class="latestBilling-section-1-billingDate-itself">{{ response.subscription_detail | get_property('next_billing_date')  }}</div>
                  </div> -->
                  <button v-if="!currentPlanSuspended && isOnTrail" class="dialer-button dialer-button-primary" @click="cancelTrail()">Cancel Trial</button>
                  <button v-if="currentPlanSuspended && isOnTrail" class="dialer-button dialer-button-primary" @click="reactivateServices()">Reactivate Services</button>

                  <button class="latestBilling-section-1-button ml-auto" @click="$modal.show('SubscriptionPlanDetailModal')">
                    Change plan
                  </button>
                </div>
              </div>
            </template>
          </div>
          <div class="latestBilling-section-1-card secondCard">
            <template v-if="api.cards.send && !primaryCard">
              <vb-icon icon="masterCard-newBilling-icon2" class="latestBilling-section-1-card-icon" />
              <div class="latestBilling-section-1-textSection">
                <div class="latestBilling-section-1-cardType">
                  <div class="latestShimmerDesign someTransparent" style="height:18px;width:50%"></div>
                </div>
                <div class="latestShimmerDesign someTransparent" style="height:24px;width:50%"></div>
                <div class="latestShimmerDesign someTransparent mt-16px" style="height:38px;width:100%"></div>
                <div class="latestBilling-section-1-lastRow mt-8px">
                  <div class="latestShimmerDesign someTransparent" style="height:18px;width:50%"></div>
                  <div class="latestShimmerDesign someTransparent ml-auto" style="height:50px;width:150px"></div>
                </div>
              </div>
            </template>
            <template v-else>
              <template v-if="primaryCard">
                <vb-icon icon="masterCard-newBilling-icon2" class="latestBilling-section-1-card-icon" />
                <div class="latestBilling-section-1-textSection">
                  <div class="latestBilling-section-1-cardType">Payment card</div>
                  <div class="latestBilling-section-1-creditCardNumberPart">
                    <div class="latestBilling-section-1-creditCardNumber">
                      <span class="d-flex align-items-center mr-20px">
                        <span class="smallCircle"></span>
                        <span class="smallCircle"></span>
                        <span class="smallCircle"></span>
                        <span class="smallCircle"></span>
                      </span>
                      <span class="d-flex align-items-center mr-20px">
                        <span class="smallCircle"></span>
                        <span class="smallCircle"></span>
                        <span class="smallCircle"></span>
                        <span class="smallCircle"></span>
                      </span>
                      <span class="d-flex align-items-center mr-20px">
                        <span class="smallCircle"></span>
                        <span class="smallCircle"></span>
                        <span class="smallCircle"></span>
                        <span class="smallCircle"></span>
                      </span>
                      <div class="latestBilling-section-1-creditCardNumber-text">
                        {{ primaryCard | get_property('digits','0000') }}
                      </div>
                    </div>
                    <div class="latestBilling-section-1-creditCardNumberPart-cardHolder">{{primaryCard.label}}</div>
                    <div class="latestBilling-section-1-creditCardNumberPart-expiryDate">
                      {{ primaryCard | get_property('expiry_month','00') }}/{{ primaryCard | get_property('expiry_year','00') }}
                    </div>
                    <div class="d-flex align-items-center justify-content-end w-100 mt-8px">
                      <!-- <button class="dialer-button dialer-button-primary" @click="$modal.show('AddCardStripe')">Add card</button> -->
                      <button class="dialer-button dialer-button-secondary color-primary font-weight-700 small px-4 border-0" @click="$modal.show(`${_uid}-OtherCreditCard`)">
                        Manage card
                      </button>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <vb-icon icon="masterCard-newBilling-icon2" class="latestBilling-section-1-card-icon" />
                <div class="latestBilling-section-1-textSection">
                  <div class="latestBilling-section-1-cardType">Payment card</div>
                  <div class="latestBilling-section-1-creditCardNumberPart">
                    <div class="latestBilling-section-1-creditCardNumber">
                      <div class="latestBilling-section-1-cardType">
                        Add your payment method
                      </div>
                    </div>
                    <p class="font-14">
                      Add your payment method now and elevate your experience with VoIP Business!
                    </p>
                    <div class="d-flex align-items-center justify-content-wnd w-100 mt-8px">
                      <button class="dialer-button dialer-button-primary" @click="$modal.show('AddCardStripe')">Add card</button>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </div>
        </div>
        <!--<div class="NewBilling-section-1 mt-20px mb-0">
          <div class="halfWidthBox leftBox d-flex flex-column justify-content-between">
            <template v-if="api.subscription_detail.send">
              <div class="halfWidthBox-section1">
                <div class="iconBox-part align-items-start w-100">
                  <vb-icon icon="PlanIcon-newBilling-icon" />
                  <div class="w-75">
                    <div class="latestShimmerDesign someTransparent" style="height:30px;width:50%"></div>
                    <div class="latestShimmerDesign someTransparent w-100 mt-1" style="height:19px;width:20%"></div>
                    <div class="latestShimmerDesign someTransparent w-100 mt-1" style="height:19px;width:20%"></div>
                  </div>
                </div>
              </div>
              <div class="halfWidthBox-section2 justify-content-between">
                <div class="latestShimmerDesign someTransparent ml-auto" style="height:50px;width:150px"></div>
              </div>
            </template>
            <template v-else>
              <div class="halfWidthBox-section1">
                <div class="iconBox-part align-items-start">
                  <vb-icon icon="PlanIcon-newBilling-icon" />
                  <div>
                    <div class="planHeading d-flex flex-column">
                      {{ response.subscription_detail | get_property('userCurrentSubscription.plan_detail.plan_label') }}
                      <span :class="`d-flex align-items-center`">
                        <span v-if="isOnTrail" class="OwnertaggedVersion mb-0 mt-0 mr-16px">{{ 'Trial' }}</span>
                        <span v-if="currentPlanSuspended" class="suspendedTag">{{ currentPlanSuspended ? 'Suspended' : '' }}</span>
                        <!~~ <!~~ <button v-if="currentPlanSuspended && isOnTrail" class="suspendedTag b-0" @click="reactivateServices()">Reactivate Services</button> ~~>
                        <!~~ <button v-if="!currentPlanSuspended && isOnTrail" class="suspendedTag b-0" @click="cancelTrail()">Cancel Trail</button> ~~> ~~>
                      </span>
                    </div>
                    <div class="planDescription onlyfirstLetterCapital restLowerCase mt-1">
                      Your current plan is {{ response.subscription_detail | get_property('userCurrentSubscription.plan_detail.plan_label') }}. 
                      <span style="text-transform: uppercase;">Y</span>ou can upgrade or downgrade your plan by clicking the button below.
                    </div>
                    <!~~ <!~~ v-if="!isOnFreePlan" ~~> ~~>
                    <div v-if="response.subscription_detail && response.subscription_detail.next_billing_date && !isOnFreePlan" class="d-flex w-100 justify-content-between align-items-center flex-wrap">
                      <!~~ <!~~ v-b-tooltip :title="response.subscription_detail | get_property('current_date')" ~~> ~~>
                      <div class="planWhiteTag mt-8px mr-8px">
                        <span>
                          <b>{{isOnTrail ? 'Trial Expire Date' : 'Next billing date'}}:</b> {{ response.subscription_detail | get_property('next_billing_date')  }}
                        </span>
                      </div>
                      <div class="planWhiteTag mt-8px">
                        <span>
                          <b>Subscription date:</b> {{ response.subscription_detail | get_property('current_date')  }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="halfWidthBox-section2 justify-content-between">
                <button v-if="!currentPlanSuspended && isOnTrail" class="dialer-button dialer-button-primary" @click="cancelTrail()">Cancel Trial</button>
                <button v-if="currentPlanSuspended && isOnTrail" class="dialer-button dialer-button-primary" @click="reactivateServices()">Reactivate Services</button>
                <button class="dialer-button dialer-button-secondary color-primary font-weight-700 small px-4 border-0 justify-content-end d-flex ml-auto" @click="$modal.show('SubscriptionPlanDetailModal')">
                  Change plan
                </button>
              </div>
            </template>
          </div>
          <!~~ <!~~ <div class="halfWidthBox rightBox d-flex flex-column justify-content-between">
            <div class="halfWidthBox-section1">
              <div class="iconBox-part">
                <vb-icon icon="InvoiceDetail-newBilling-icon" />
                <div class="planHeading">
                  My invoice details
                </div>
              </div>
            </div>
            <div class="halfWidthBox-section2 flex-column w-100 align-items-start">
              <div class="addressPart w-100">
                <h6>{{ response.account_detail | get_property('user_detail.firstname') }} {{ response.account_detail | get_property('user_detail.lastname') }}</h6>
                <p>{{ response.account_detail | get_property('user_detail.address') }}</p>
              </div>
              <div class="d-flex justify-content-end w-100 mt-20px">
                <button class="dialer-button dialer-button-secondary color-primary font-weight-700 small px-4 border-0" @click="$modal.show('InvoiceDetailModal', {detail: response.account_detail.user_detail,})">Update invoice details</button>
              </div>
            </div>
          </div> ~~> ~~>
          <div class="halfWidthBox d-flex flex-column justify-content-between bgNotOldPurple">
            <div class="halfWidthBox-section1 mb-3">
              <div class="iconBox-part">
                <vb-icon icon="masterCard-newBilling-icon2" class="masterCard-newBilling-icon2" />
                <div class="planHeading">
                  Payment card
                </div>
              </div>
            </div>
            <template v-if="api.cards.send && !primaryCard">
              <div class="halfWidthBox-section2 flex-column w-100 align-items-start">
                <div class="addressPart w-100">
                  <h6 class="d-flex align-items-center mb-1">
                    <div class="latestShimmerDesign someTransparent flex-1" style="height:24px;width:80%"></div>
                  </h6>
                  <div class="latestShimmerDesign someTransparent flex-1 mb-1" style="height:24px;width:50%"></div>
                  <div class="latestShimmerDesign someTransparent flex-1" style="height:24px;width:20%"></div>
                </div>
                <div class="w-100 diflex justify-content-end mt-20px">
                  <div class="latestShimmerDesign someTransparent ml-auto" style="height:50px;width:150px"></div>
                </div>
              </div>
            </template>
            <template v-else>
              <template v-if="primaryCard">
                <div class="halfWidthBox-section2 flex-column w-100 align-items-start">
                  <div class="addressPart creditCardNumberPart w-100">
                    <h6 class="d-flex align-items-center">
                      <span class="d-flex align-items-center mr-20px">
                        <span class="smallCircle"></span>
                        <span class="smallCircle"></span>
                        <span class="smallCircle"></span>
                        <span class="smallCircle"></span>
                      </span>
                      <span class="d-flex align-items-center mr-20px">
                        <span class="smallCircle"></span>
                        <span class="smallCircle"></span>
                        <span class="smallCircle"></span>
                        <span class="smallCircle"></span>
                      </span>
                      <span class="d-flex align-items-center mr-20px">
                        <span class="smallCircle"></span>
                        <span class="smallCircle"></span>
                        <span class="smallCircle"></span>
                        <span class="smallCircle"></span>
                      </span>
                      {{ primaryCard | get_property('digits','0000') }}
                    </h6>
                    <p>{{primaryCard.label}}</p>
                    <p class="font-14">
                      {{ primaryCard | get_property('expiry_month','00') }}/{{ primaryCard | get_property('expiry_year','00') }}
                    </p>
                  </div>
                  <div class="d-flex align-items-center justify-content-between w-100 mt-20px">
                    <!~~ <!~~ <button class="dialer-button dialer-button-secondary color-primary font-weight-700 small px-4 border-0" @click="$modal.show('PaymentDetailModal',{  })">
                      Add card
                    </button> ~~> ~~>

                    <button class="dialer-button dialer-button-primary" @click="$modal.show('AddCardStripe')">Add card</button>
                    <!~~ <!~~ <button class="dialer-button dialer-button-primary mt-12px" @click="$modal.show(`${_uid}-OtherCreditCard`)">other cards</button> ~~> ~~>
                  
                    <button class="dialer-button dialer-button-secondary color-primary font-weight-700 small px-4 border-0" @click="$modal.show(`${_uid}-OtherCreditCard`)">
                      Manage card
                    </button>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="halfWidthBox-section2 flex-column w-100 align-items-start">
                  <div class="addressPart w-100">
                    <h6 class="d-flex align-items-center mb-1">
                      Add your payment method
                    </h6>
                    <p class="font-14">
                      Add your payment method now and elevate your experience with VoIP Business!
                    </p>
                  </div>
                  <div class="d-flex align-items-end flex-column w-100 mt-12px">
                    <!~~ <!~~ <button class="dialer-button dialer-button-secondary color-primary font-weight-700 small px-4 border-0" @click="$modal.show('PaymentDetailModal',{  })">
                      Add card
                    </button> ~~> ~~>
                    <button class="dialer-button dialer-button-secondary mt-12px color-primary font-weight-700 small px-4 border-0" @click="$modal.show('AddCardStripe')">Add stripe card</button>
                  </div>
                </div>
              </template>
            </template>
          </div>
        </div>-->

        <div class="latestBilling-tabsSection">
          <div class="TabsDesignWithIcon-container">
            <div class="TabsDesignWithIcon-item" :id="`popover-1-${allTab.value}`" v-for="allTab in tabs" :key="allTab.id" @click="selected.tab = allTab.value" :class="{'active': selected.tab == allTab.value,'mwd-168px' : allTab.value == 'balance'}">
              <vb-icon :icon="allTab.icon" height="38px" width="38px" />
              <span class="TabsDesignWithIcon-item-textPart">{{allTab.text}}</span>
              <b-popover custom-class="latestTooltipVersion"
                :target="`popover-1-${allTab.value}`"
                placement="bottom"
                triggers="hover"
                :content="`${allTab.tooltipText}`"
              ></b-popover>
            </div>
          </div>
          <div class="latestBilling-balancesSection">
            <div class="latestBilling-balancesSection-leftSide">
              <div class="latestBilling-balancesSection-balance">£{{ response.invoice_detail | get_property('user_balance','0.00') | to_fixed }}</div>
              <!-- <div class="latestBilling-balancesSection-balanceTitle">Prepaid call credit</div> -->
              <div class="latestBilling-balancesSection-balanceTitle">Call credit</div>
            </div>
            <div class="latestBilling-balancesSection-rightSide">
              <div class="latestBilling-balancesSection-balance">£{{ response.sms_balance | get_property('sms_balance','0.00') | to_fixed }}</div>
              <div class="latestBilling-balancesSection-balanceTitle">SMS credit</div>
            </div>
          </div>
        </div>

        <div class="latestGreyBox-9-9-2023 pt-3 mt-30px">
          <template v-if="selected.tab == 'invoices'">
            <vb-table
              class="trustedDevicesTable-updated latestTableDesign-withBlackBorders-again mt-0"
              :isListEmpty="invoices.length==0" 
              :listLength="invoices.length" 
              :perPage="5" 
              :loading="api.invoice_detail.send"
              :noRecordWholePage="true"
            >
              <tr slot="header" class="w-100">
                <th class="border-0"> 
                  <span>Invoice</span>
                </th> 
                <th class=" border-0 ">
                  <span>Amount</span>
                </th>
                <th class=" border-0 ">
                  <span>Date</span>
                </th>
                <th class=" border-0 ">
                  <!-- <span>Status</span> -->
                </th>
                <th class=" border-0 ">
                  <span><br/> </span>
                </th>
              </tr>
              <template #body="{ start, end }">
                <tr v-for="(invoice, index) in invoices" :key="invoice.id" v-show="index >= start && index <= end">
                  <td class="border-0">{{ invoice | filterInvoiceType }}</td>
                  <td class="border-0">{{ invoice.invoice_total_amount | to_fixed }}</td>
                  <td class="border-0">{{ invoice.invoice_paid_at | filter_date_current(false,{ formate: 'DD MMM YYYY' })  }}</td>
                  <td class="border-0">
                    <span :class="`OwnertaggedVersion ${invoice.invoice_status != 'PAID' ? 'red' : 'green'}`">{{ invoice.invoice_status }}</span>
                  </td>
                  <td class="border-0" >
                    <div class="d-flex justify-content-end w-100">
                      <span v-if="invoice.invoice_status != 'PAID'" @click="payOutstandingAmount(invoice)" class="OwnertaggedVersion mb-0 mr-16px showOnly-OnHover-insideTable">
                        Pay now
                      </span>
                      <!-- <button @click="$modal.show('InvoicePDFDownloadAccountSetting',{id:invoice.invoice_id})" class="dialer-button dialer-button-primary" style="min-width:132px;">View invoice</button> -->
                      <span class="text-underline cursor_pointer_no_hover" @click="$modal.show('InvoicePDFDownloadAccountSetting',{id:invoice.invoice_id})">View invoice</span>
                    </div>
                  </td>
                </tr>
              </template>
              <template #loading>
                <tr v-for="n in 5" :key="n">
                  <td>
                    <div class="d-flex w-fit-content">
                      <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex w-fit-content">
                      <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex w-fit-content">
                      <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex w-fit-content">
                      <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                    </div>
                  </td>
                  <td class="dialer-col-right">
                    <div class="latestShimmerDesign ml-auto" style="height:19px;width:135px;"></div>
                  </td>
                </tr>
              </template>
              <template #no-record>
                <div class="latestGreyBox-9-9-2023 mt-32px p-0">
                  <div class="emptyScreenContainer">
                    <img class="mt-12px wd-30 h-auto" :src="require('@/assets/images/emptyScreenImages/invoices.png')"/>
                    <div class="emptyScreenContainer-heading">
                      Manage invoices and credit notes
                    </div>
                    <div class="emptyScreenContainer-text w-75">
                      View and manage your invoices and credit notes here. Start 
                      by creating a new invoice or credit note.
                    </div>
                  </div>
                </div>
              </template>
            </vb-table>
            <div @click="$modal.show('InvoiceDetailModal', {detail: response.account_detail.user_detail,})" class="latestGreyBox-heading-main-18px w-fit-content text-underline cursor_pointer_no_hover mt-20px">
              My invoice details
            </div>
          </template>
          <template v-else-if="selected.tab == 'credit-logs'">
            <vb-table
              class="trustedDevicesTable-updated latestTableDesign-withBlackBorders-again mt-0"
              :isListEmpty="creditLogs.length==0" 
              :listLength="creditLogs.length" 
              :perPage="5" 
              :loading="api.invoice_detail.send"
              :noRecordWholePage="true"
            >
              <tr slot="header" class="w-100">
                <th class="border-0"> 
                  <span>Amount</span>
                </th> 
                <th class="border-0"> 
                  <span>Type</span>
                </th> 
                <th class="border-0"> 
                  <span>Description</span>
                </th> 
              </tr>
              <template #body="{ start, end }">
                <tr v-for="(creditLog, index) in creditLogs" :key="creditLog.id" v-show="index >= start && index <= end">
                  <td class="border-0">{{ creditLog.amount | to_fixed }}</td>
                  <td class="border-0">{{ creditLog.type }}</td>
                  <td class="border-0">{{ creditLog.description }}</td>
                </tr>
              </template>
              <template #loading>
                <tr v-for="n in 5" :key="n">
                  <td>
                    <div class="d-flex w-fit-content">
                      <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex w-fit-content">
                      <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex w-fit-content w-100">
                      <div class="latestShimmerDesign flex-1" style="height:19px;width:100%"></div>
                    </div>
                  </td>
                </tr>
              </template>
              <template #no-record>
                <div class="latestGreyBox-9-9-2023 mt-32px p-0">
                  <div class="emptyScreenContainer">
                    <img class="mt-12px wd-30 h-auto" :src="require('@/assets/images/emptyScreenImages/invoices.png')"/>
                    <div class="emptyScreenContainer-heading">
                      Manage invoices and credit notes
                    </div>
                    <div class="emptyScreenContainer-text w-75">
                      View and manage your invoices and credit notes here. Start 
                      by creating a new invoice or credit note.
                    </div>
                  </div>
                </div>
              </template>
            </vb-table>
          </template>
          <template v-else-if="selected.tab == 'users'">
            <vb-table
              class="trustedDevicesTable-updated latestTableDesign-withBlackBorders-again mt-0"
              :isListEmpty="users.length==0"
              :listLength="users.length"
              :perPage="5"
              :loading="api.account_detail.send"
            >
              <tr slot="header" class="w-100">
                <th>
                  <span>Name</span>
                </th>
                <th class="text-right">
                  <span>Unlimited call plan</span>
                </th>
              </tr>
              <template #body="{ start, end }">
                <tr class="w-100" v-for="(account, index) in users" :key="account" v-show="index >= start && index <= end">
                  <td class="dialer-row-title">
                    <Info :id="account" :is_blf="false" />
                  </td>
                  <td class="dialer-has-sort dialer-col-right">
                    <div class="d-flex justify-content-end">
                      <p v-if="response.account_detail.users[account].cancel_request>0">Cancel at {{ response.subscription_detail | get_property('userCurrentSubscription.subscription_next_billing_date') }}</p>
                      <div v-if="api.change_prefixes.send==account" class="latestShimmerDesign ml-auto" style="height:34px;width: 60px;border-radius: 60px;" />
                      <b-form-checkbox 
                        v-else
                        class="newerSwitch ml-auto" 
                        switch 
                        :disabled="!!api.change_prefixes.send || api.account_detail.send"
                        :checked="response.account_detail.users[account].plan_prefix_type=='unlimited'" 
                        :ref="`${account}-toggle`" 
                        @change="changePrefix(account)" 
                      />
                    </div>
                  </td>
                </tr>
              </template>
              <template #loading>
                <tr v-for="n in 5" :key="n">
                  <td>
                    <div class="d-flex">
                      <div class="latestShimmerDesign mr-2" style="height:35px;width:35px;"></div>
                      <div class="d-flex flex-column justify-content-between">
                        <div class="latestShimmerDesign flex-1" style="height:16px;width:60px"></div>
                        <div class="latestShimmerDesign mt-1" style="height:16px;width:30px"></div>
                      </div>
                    </div>
                  </td>
                  <td class="dialer-col-right">
                    <div class="latestShimmerDesign ml-auto" style="height:32px;width:60px;"></div>
                  </td>
                </tr>
              </template>
            </vb-table>
          </template>
          <template v-else-if="selected.tab == 'numbers'">
            <vb-table 
              class="trustedDevicesTable-updated latestTableDesign-withBlackBorders-again mt-0"
              :isListEmpty="isEmpty(didNumbers)" 
              :listLength="didNumbers.length" 
              :perPage="5" 
              :loading="api.numbers.send"
            >
              <tr slot="header" class="w-100">
                <th>
                  <span>DID number</span>
                </th>
                <th>
                  <span>Assigned</span>
                </th>
                <th class="dialer-has-sort dialer-col-right">
                  <span>Action</span>
                </th>
              </tr>
              <template #body="{ start, end }">
                <tr class="dialer-row-select" v-for="(number, index) in didNumbers" :key="`${number.id}-${number.object}`" v-show="index >= start && index <= end">
                  <td class="dialer-row-title">
                    <div class="d-flex dialer-numberWithCountry align-items-center">
                      <span >
                        <img class="country-img" width="45px" :src="number.number | number_formater | flag_icon" />
                      </span>
                      <span class="ml-2 d-flex flex-column dialer-numberInfo" v-if="number.object === 'TelephoneNumbers'">
                        <span class="m-0 didNumber">{{ number.number | number_formater }}</span>
                        <small class="didNumber-text" v-if="number.label">({{ number.label }})</small>
                        <div v-if="number.status==2" class="suspendedTag">Suspended</div>
                      </span>
                      <span class="d-flex flex-column ml-1 dialer-numberInfo" v-else>
                        <span class="m-0 didNumber">{{ number.number | number_formater }}</span>
                      </span>
                    </div>
                  </td>
                  <td>
                    <Info 
                      v-if="number.extnType" :is_blf="false"
                      :id="number | get_property('assignedToUsersArr.extn')" 
                      :name="number | get_property('assignedToUsersArr.label')" 
                      :sub_info="number | get_property('assignedToUsersArr.extn')" 
                    />
                  </td>
                  <td class="dialer-has-sort dialer-col-right ">
                    <button 
                      @click="deletenumber(number.real_id)" 
                      :disabled="!!api.delete_number.send" 
                      v-if="number.assignedToUsers && number.assignedToUsers=='None' && number.status==1" 
                      class="dialer-button dialer-button-primary"
                    >
                      <vb-spinner v-if="api.delete_number.send==number.real_id" />
                      <template v-else>Remove</template>
                    </button>
                  </td>
                </tr>
              </template>
              <template #loading>
                <tr v-for="n in 5" :key="n">
                  <td>
                    <div class="d-flex w-fit-content">
                      <div class="d-flex align-items-center">
                        <div class="latestShimmerDesign" style="height:20px;width:40px"></div>
                        <div class="latestShimmerDesign ml-2" style="height:17px;width:113px"></div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex w-fit-content">
                      <div class="latestShimmerDesign mr-2" style="height:35px;width:35px;"></div>
                      <div class="d-flex flex-column justify-content-between">
                        <div class="latestShimmerDesign flex-1" style="height:16px;width:60px"></div>
                        <div class="latestShimmerDesign mt-1" style="height:16px;width:30px"></div>
                      </div>
                    </div>
                  </td>
                  <td class="dialer-col-right">
                    <div class="latestShimmerDesign ml-auto" style="height:50px;width:112px;"></div>
                  </td>
                </tr>
              </template>
            </vb-table>
          </template>
          <template v-else-if="selected.tab == 'whmcs'">
            <ul class="list-unstyled mb-0">
              <!--<li>
                <vb-table 
                  class="trustedDevicesTable-updated latestTableDesign-withBlackBorders-again mt-0"
                  :isListEmpty="subscriptionProducts.length==0" 
                  :listLength="subscriptionProducts.length" 
                  :perPage="5" 
                  :loading="api.fetch_subscriptions.send"
                >
                  <template #header>
                    <tr class="w-100">
                      <!~~ <th class="dialer-has-sort">
                        <span>Billing id</span>
                      </th> ~~>
                      <th class="dialer-has-sort">
                        <span>name</span>
                      </th>
                      <th class="dialer-has-sort">
                        <span>type</span>
                      </th>
                      <!~~ <th class="dialer-has-sort">
                        <span>regdate</span>
                      </th> ~~>
                      <th class="dialer-has-sort">
                        <span>next billing date</span>
                      </th>
                      <th class="dialer-has-sort">
                        <span>amount</span>
                      </th>
                      <th class="dialer-has-sort">
                        <span>status</span>
                      </th>
                      <th class="dialer-has-sort text-right">
                        <span>billing frequency</span>
                      </th>
                    </tr>
                  </template>
                  <template #body="{ start, end }">
                    <tr :class="`w-100 ${['Suspended','Terminated'].includes(data.status)?'TerminatedRow':''}`" 
                      v-for="(data, index) in subscriptionProducts" 
                      :key="index" 
                      v-show="index >= start && index <= end"
                    >
                      <!~~ <td class="dialer-row-title">{{ data | get_property('product_id') }}</td> ~~>
                      <td class="dialer-row-title">{{ data | get_property('subscriptionName') }}</td>
                      <td class="dialer-row-title">{{ data | get_property('type') }}</td>
                      <!~~ <td class="dialer-row-title">
                        <span v-b-tooltip.hover.top :title="data | get_property('subscription_next_billing_date')">{{ data | get_property('subscription_start_date') }}</span>
                      </td> ~~>
                      <td class="dialer-row-title">
                        <!~~ v-b-tooltip.hover.top :title="data | get_property('nextBillingDate')" ~~>
                        <span >{{ data | get_property('nextBillingDate') }}</span>
                      </td>
                      <td class="dialer-col-left">{{ data | get_property('amountDue',0) | to_fixed }}</td>
                      <td class="dialer-col-left">
                        <span class="OwnertaggedVersion" :class="{ 'green': data.status=='ACTIVE' }">
                          {{ data | get_property('status') }}
                        </span>
                      </td>
                      <td class="dialer-row-title dialer-col-right">{{ data | get_property('billingFrequency') }}</td>
                    </tr>
                  </template>
                  <template #loading>
                    <tr v-for="n in 5" :key="n">
                      <!~~ <td>
                        <div class="d-flex w-fit-content">
                          <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                        </div>
                      </td> ~~>
                      <td>
                        <div class="d-flex w-fit-content">
                          <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex w-fit-content">
                          <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex w-fit-content">
                          <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex w-fit-content">
                          <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex w-fit-content">
                          <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                        </div>
                      </td>
                      <td class="dialer-col-right">
                        <div class="latestShimmerDesign ml-auto" style="height:19px;width:60px;"></div>
                      </td>
                    </tr>
                  </template>
                </vb-table>
              </li>-->
              <li>
                <vb-table 
                  class="trustedDevicesTable-updated latestTableDesign-withBlackBorders-again mt-0"
                  :isListEmpty="Object.keys(subscriptionProductsGroupBy).length==0" 
                  :listLength="Object.keys(subscriptionProductsGroupBy).length" 
                  :perPage="5" 
                  :loading="api.fetch_subscriptions.send"
                >
                  <template #header>
                    <tr class="w-100">
                      <!-- <th class="dialer-has-sort">
                        <span>Billing id</span>
                      </th> -->
                      <th class="dialer-has-sort ">
                        <span>name</span>
                      </th>
                      <th class="dialer-has-sort">
                        <span>type</span>
                      </th>
                      <!-- <th class="dialer-has-sort">
                        <span>regdate</span>
                      </th> -->
                      <th class="dialer-has-sort">
                        <span>next billing date</span>
                      </th>
                      <th class="dialer-has-sort">
                        <span>amount</span>
                      </th>
                      <th class="dialer-has-sort">
                        <span>status</span>
                      </th>
                      <th class="dialer-has-sort text-right">
                        <span>billing frequency</span>
                      </th>
                    </tr>
                  </template>
                  <template #body="{ start, end }">
                    <template v-for="(data, key, index) in subscriptionProductsGroupBy">
                      <tr :key="key" v-show="index >= start && index <= end">
                        <td class="dialer-row-title ">{{ data | get_property('length') }}x</td>
                        <td class="dialer-row-title">{{ key }}</td>
                        <td class="dialer-row-title">
                          <span >{{ data | get_property('[0].nextBillingDate') }}</span>
                        </td>
                        <td class="dialer-col-left">{{ data | subscriptionGroupTotalPrice | to_fixed }}</td>
                        <td class="dialer-col-left">
                          <span class="OwnertaggedVersion" :class="{ 'green': data[0] && data[0].status=='ACTIVE' }">
                            {{ data | get_property('[0].status') }}
                          </span>
                        </td>
                        <td class="dialer-row-title dialer-col-right ">
                          {{ data | get_property('[0].billingFrequency') }}
                          <b-button @click="conditions.collapse=conditions.collapse==key?'':key" variant="link">
                            <b-icon :icon="conditions.collapse==key?'chevron-up':'chevron-down'" />
                          </b-button>
                        </td>
                      </tr>
                      <template v-if="conditions.collapse==key">
                        <tr :key="`collapse-${key}`" class="w-100" >
                          <td colspan="6" class="w-100 tableInsideTable-contaienr">
                            <table class="w-100 tableInsideTable mt-0">
                              <thead>
                                <tr class="w-100">
                                  <th class="dialer-has-sort">
                                    <span>name</span>
                                  </th>
                                  <th class="dialer-has-sort">
                                    <span>type</span>
                                  </th>
                                  <th class="dialer-has-sort">
                                    <span>next billing date</span>
                                  </th>
                                  <th class="dialer-has-sort">
                                    <span>amount</span>
                                  </th>
                                  <th class="dialer-has-sort">
                                    <span>status</span>
                                  </th>
                                  <th class="dialer-has-sort text-right">
                                    <span>billing frequency</span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody class="w-100">
                                <tr class="w-100"
                                  v-for="(subs, subs_index) in data" 
                                  :key="subs_index" 
                                >
                                  <td class="dialer-row-title">
                                    {{ subs | get_property('subscriptionName') }}
                                  </td>
                                  <td class="dialer-row-title">{{ subs | get_property('type') }}</td>
                                  <td class="dialer-row-title">
                                    <span >{{ subs | get_property('nextBillingDate') }}</span>
                                  </td>
                                  <td class="dialer-col-left">{{ subs.amountDue | to_fixed }}</td>
                                  <td class="dialer-col-left">
                                    <span class="OwnertaggedVersion" :class="{ 'green': subs.status=='ACTIVE' }">
                                      {{ subs | get_property('status') }}
                                    </span>
                                  </td>
                                  <td class="dialer-row-title dialer-col-right">{{ subs | get_property('billingFrequency') }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </template>
                    </template>
                  </template>
                  <template #loading>
                    <tr v-for="n in 5" :key="n">
                      <!-- <td>
                        <div class="d-flex w-fit-content">
                          <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                        </div>
                      </td> -->
                      <td>
                        <div class="d-flex w-fit-content">
                          <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex w-fit-content">
                          <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex w-fit-content">
                          <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex w-fit-content">
                          <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex w-fit-content">
                          <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                        </div>
                      </td>
                      <td class="dialer-col-right">
                        <div class="latestShimmerDesign ml-auto" style="height:19px;width:60px;"></div>
                      </td>
                    </tr>
                  </template>
                </vb-table>
              </li>
              <li>
                <button class="newBlueButton mt-50px" @click="$modal.show('viewPlanLicensesModal')">View plan license</button>
              </li>
            </ul>
          </template>
          <template v-else-if="selected.tab == 'balance'">
            <ul class="latestBilling-balanceSections list-unstyled mb-0">
              <li class="latestBilling-balanceSection first">
                <vb-icon icon="callCredit-newBilling-icon2" class="latestBilling-balanceSection-icon" />
                <div class="latestBilling-balanceSection-textSection">
                  <div class="latestBilling-balanceSection-heading">
                    <span>Out of plan call credit</span>
                    <b :id="`popover-creditAllowed`">£{{ response.invoice_detail | get_property('user_balance','0.00') | to_fixed }}</b>
                    <b-popover v-if="isPostPaidEnabled" custom-class="latestTooltipVersion"
                      :target="`popover-creditAllowed`"
                      placement="top"
                      triggers="hover"
                      :content="`Credit allowed: ${getProperty(response.invoice_detail,'voip_balance.creditAllowed',0)}`"
                    ></b-popover>
                  </div>
                  <div class="latestBilling-balanceSection-text">
                    This credit is used for calls made to destinations not included in your call plan.
                  </div>
                  <div class="latestBilling-balanceSection-lastRow">
                    <div v-if="!isPostPaidEnabled" class="latestGreyBox-heading-main-16px cursor_pointer_no_hover text-underline" @click="$modal.show('TopupSettingModal')">
                      Top up settings
                    </div>
                    <span class="OwnertaggedVersion mb-0" v-if="isPostPaidEnabled">£{{ response.invoice_detail | get_property('voip_balance.creditAllowed',0) }}</span>
                    <button class="latestBilling-balanceSection-newBlueButton" @click="$modal.show('AddCreditModal')">
                      Add credit
                    </button>
                  </div>
                </div>
              </li>
              <li class="latestBilling-balanceSection second">
                <vb-icon icon="SMSCredit-newBilling-icon2" class="latestBilling-balanceSection-icon" />
                <div class="latestBilling-balanceSection-textSection">
                  <div class="latestBilling-balanceSection-heading">
                    <span>SMS Credit</span>
                    <b>£{{ response.sms_balance | get_property('sms_balance','0.00') | to_fixed }}</b>
                  </div>
                  <div class="latestBilling-balanceSection-text">
                    This credit is used for sending SMS messages through your VoIPbusiness application.
                  </div>
                  <div class="latestBilling-balanceSection-lastRow justify-content-end">
                    <button class="latestBilling-balanceSection-newBlueButton" @click="$modal.show('AddCreditModal',{ sms_balance_products: true, })">
                      Add credit
                    </button>
                  </div>
                </div>
              </li>
              <li class="latestBilling-balanceSection third">
                <vb-icon icon="account-Balance-newBilling-icon2" class="latestBilling-balanceSection-icon" />
                <div class="latestBilling-balanceSection-textSection">
                  <div class="latestBilling-balanceSection-heading">
                    <span>Account Balance</span>
                    <b>£{{ response.balance | get_property('balance','0') | to_fixed }}</b>
                  </div>
                  <div class="latestBilling-balanceSection-text">
                    This balance is used to purchase additional services such as user subscriptions, phone numbers, and more within the VoIPbusiness application.
                  </div>
                  <div class="latestBilling-balanceSection-lastRow">
                    <div class="latestGreyBox-heading-main-16px cursor_pointer_no_hover text-underline" @click="$modal.show(`${_uid}-TransactionLogsModal`)">
                      Transaction logs
                    </div>
                    <button class="latestBilling-balanceSection-newBlueButton" 
                      @click="$modal.show('AddCreditModal',{
                        top_up_balance_products: true,
                      })"
                    >
                      Add credit
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul v-if="false" class="list-unstyled mb-0 balanceSections-container flex-wrap mt-16px">
              <li class="wd-33-33-12px w-50">
                <div class="balanceSections-1 p-3">
                  <div class="whiteBoxWithBorderInsideGreyBox withoutBorder px-0">
                    <div class="balanceSection">
                      <div class="balanceSection-1">
                        <div class="balanceSection-1-leftPart">
                          <vb-icon icon="AccountBalance-newBilling-icon2" class="greenBackgroundIcon" />
                          <!-- <div class="balanceSection-1-heading">VoIP balance</div> -->
                          <div class="balanceSection-1-heading">Account balance</div>
                        </div>
                        <div class="balanceSection-1-balance">£{{ response.balance | get_property('balance','0') | to_fixed }}</div>
                      </div>
                      <div class="w-100 d-flex justify-content-end mt-70px">
                        <button class="newBlueButton"
                          @click="$modal.show('AddCreditModal',{
                            top_up_balance_products: true,
                          })"
                        >
                          <!-- Top up balance products -->
                          Buy credit
                        </button>
                      </div>
                    </div>
                    <!-- <div class="whiteBoxWithBorderInsideGreyBox-row">
                      <div class="iconBox-part withSubText">
                        <vb-icon icon="AccountBalance-newBilling-icon2" />
                        <div class="iconBox-TextPart">
                          <div class="planHeading">
                            Balance
                          </div>
                          <div class="d-flex">
                            <div 
                              class="planText" 
                              @click="$modal.show('AddCreditModal',{
                                top_up_balance_products: true,
                              })"
                            >
                              Top up balance products
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="iconBox-part withSubText">
                        <div class="iconBox-TextPart">
                          <div class="planHeading text-right font-28">
                            Voip balance: £{{ response.balance | get_property('balance','0') | to_fixed }}
                          </div>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
              </li>
              <li class="wd-33-33-12px w-50">
                <div class="balanceSections-2 p-3">
                  <div class="whiteBoxWithBorderInsideGreyBox withoutBorder px-0">
                    <div class="balanceSection">
                      <div class="balanceSection-1">
                        <div class="balanceSection-1-leftPart">
                          <vb-icon icon="AccountBalance-newBilling-icon2" class="orangeBackgroundIcon" />
                          <div class="balanceSection-1-heading">Call credit</div>
                        </div>
                        <div class="balanceSection-1-balance" :id="`popover-creditAllowed`">£{{ response.invoice_detail | get_property('user_balance','0.00') | to_fixed }}</div>
                        <b-popover v-if="isPostPaidEnabled" custom-class="latestTooltipVersion"
                          :target="`popover-creditAllowed`"
                          placement="top"
                          triggers="hover"
                          :content="`Credit allowed: ${getProperty(response.invoice_detail,'voip_balance.creditAllowed',0)}`"
                        ></b-popover>
                      </div>
                      <div class="w-100 d-flex justify-content-between align-items-center mt-70px">
                        <div v-if="!isPostPaidEnabled" class="latestGreyBox-heading-main-16px cursor_pointer_no_hover text-underline" @click="$modal.show('TopupSettingModal')">
                          Top up settings
                        </div>
                        <span class="OwnertaggedVersion mb-0" v-if="isPostPaidEnabled">£{{ response.invoice_detail | get_property('voip_balance.creditAllowed',0) }}</span>
                        <button class="newBlueButton" @click="$modal.show('AddCreditModal')">
                          <!-- Top up call products -->
                          Buy call credit
                        </button>
                      </div>
                    </div>
                    <!-- <div class="whiteBoxWithBorderInsideGreyBox-row">
                      <div class="iconBox-part withSubText">
                        <vb-icon icon="AccountBalance-newBilling-icon2" />
                        <div class="iconBox-TextPart">
                          <div class="planHeading">
                            Call credit balance
                          </div>
                          <div class="d-flex">
                            <div class="planText" @click="$modal.show('AddCreditModal')">
                              Top up call products
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="iconBox-part withSubText">
                        <div class="iconBox-TextPart">
                          <div class="planHeading text-right font-28">
                            Call credit balance: £{{ response.invoice_detail | get_property('user_balance','0.00') | to_fixed }}
                          </div>
                          <div class="planText text-right" @click="$modal.show('TopupSettingModal')">
                            Top up settings
                          </div>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
              </li>
              <li class="wd-33-33-12px w-50">
                <div class="balanceSections-3 p-3">
                  <div class="whiteBoxWithBorderInsideGreyBox withoutBorder px-0">
                    <div class="balanceSection">
                      <div class="balanceSection-1">
                        <div class="balanceSection-1-leftPart">
                          <vb-icon icon="AccountBalance-newBilling-icon2" class="blueBackgroundIcon" />
                          <div class="balanceSection-1-heading">SMS credit</div>
                        </div>
                        <div class="balanceSection-1-balance">£{{ response.sms_balance | get_property('sms_balance','0.00') | to_fixed }}</div>
                      </div>
                      <div class="w-100 d-flex justify-content-end mt-70px">
                        <button class="newBlueButton" @click="$modal.show('AddCreditModal',{ sms_balance_products: true, })">
                          Buy sms credit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="wd-33-33-12px w-50">
                <div class="balanceSections-3 p-3">
                  <div class="whiteBoxWithBorderInsideGreyBox withoutBorder px-0">
                    <div class="balanceSection">
                      <div class="balanceSection-1">
                        <div class="balanceSection-1-leftPart">
                          <vb-icon icon="AccountBalance-newBilling-icon2" class="blueBackgroundIcon" />
                          <div class="balanceSection-1-heading">Reserve credit</div>
                        </div>
                        <div class="balanceSection-1-balance">£{{ reserveBalanceTotal | to_fixed }}</div>
                      </div>
                      <div class="w-100 d-flex justify-content-end mt-70px">
                        <button class="newBlueButton" @click="$modal.show(`${_uid}-DetailReserveBalance`)">
                          Detail
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </template>
        </div>

        <!-- <div class="latestGreyBox-9-9-2023 mt-30px">
          <div class="latestGreyBox-heading-main">Invoice Samples</div>
          <div class="whiteBoxWithBorderInsideGreyBox mt-12px">
            <div class="whiteBoxWithBorderInsideGreyBox-row">
              <div class="latestGreyBox-heading-main">Recurring Invoice with multiple users</div>
              <button class="newButton" @click="selectedObject=forObjs.recurring;$modal.show(`${_uid}-invoiceTestModal`)">
                <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                <span class="newButton-textPart d-flex justify-content-center position-relative">
                  view
                </span>
              </button>
            </div>
            <div class="whiteBoxWithBorderInsideGreyBox-row">
              <div class="latestGreyBox-heading-main">Shifting from Free to Trial</div>
              <button class="newButton" @click="selectedObject=forObjs.freeToTrial;$modal.show(`${_uid}-invoiceTestModal`)">
                <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                <span class="newButton-textPart d-flex justify-content-center position-relative">
                  view
                </span>
              </button>
            </div>
            <div class="whiteBoxWithBorderInsideGreyBox-row">
              <div class="latestGreyBox-heading-main">Upgrade plan invoice (paid to paid)</div>
              <button class="newButton" @click="selectedObject=forObjs.paidToPaid;$modal.show(`${_uid}-invoiceTestModal`)">
                <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                <span class="newButton-textPart d-flex justify-content-center position-relative">
                  view
                </span>
              </button>
            </div>
          </div>
        </div> -->
      </div>

      <modal name="viewPlanLicensesModal" class="dialer_animation right_side_popup" width="80%" height="auto" :scrollable="true">
        <div class="dialer-edit-header">
          <h2 class="dialer-edit-title newer mb-0">Plan lincense</h2>
          <div class="d-flex align-items-center">
            <a class="newCloseButton mr-3" @click="$modal.hide('viewPlanLicensesModal')">
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </a>
          </div>
        </div>
        <vb-table
          class="latestTableDesign-withBlackBorders-again pt-3 mt-0"
          :isListEmpty="Object.keys(freeProducts).length==0" 
          :listLength="Object.keys(freeProducts).length" 
          :perPage="5" 
          :loading="api.subscription_detail.send"
        >
          <tr slot="header">
            <th class="dialer-has-sort ">
              <span>Label</span>
            </th>
            <th class="dialer-has-sort ">
              <span class=" ">Limit</span>
            </th>
            <th class="dialer-has-sort ">
              <span class=" ">Used</span>
            </th>
            <th class="dialer-has-sort ">
              <span class=" ">Remaining</span>
            </th>
            <th class="dialer-has-sort dialer-col-right ">
              <!-- <span class=" ">Action</span> -->
            </th>
          </tr>
          <template #body="{ start, end }">
            <template v-for="(data, key, index) in freeProducts">
              <tr class :key="data.product_id"  v-show="index >= start && index <= end">
                <td class="dialer-row-title dialer-col-fixed-width">{{ data | get_property("product_label") }}</td>
                <td class="dialer-row-title dialer-col-fixed-width">{{ data | get_property("product_total_free_allowed", 0) }}</td>
                <td class="dialer-row-title dialer-col-fixed-width">{{ data | get_property("product_already_used", 0) }}</td>
                <td class="dialer-row-title dialer-col-fixed-width">{{ data | get_property("product_free_remaining", 0) }}</td>
                <td class="dialer-row-title dialer-col-right">
                  <button style="width: max-content"
                    v-if="getProperty(data, 'product_already_used', 0) > 0" 
                    class="dialer-button dialer-button-transparent showDetailButton color-575757" 
                    @click="$modal.show('ShowDetailsBillingModal',{ id: data.product_id })"
                  >
                    <vb-icon icon="show-sub-table-icon" height="16px" width="16px" color="primary"/>
                    View details
                  </button>
                </td>
              </tr>
            </template>
          </template>
          <template #loading>
            <tr v-for="n in 5" :key="n">
              <td>
                <div class="d-flex w-fit-content">
                  <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                </div>
              </td>
              <td>
                <div class="d-flex w-fit-content">
                  <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                </div>
              </td>
              <td>
                <div class="d-flex w-fit-content">
                  <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                </div>
              </td>
              <td>
                <div class="d-flex w-fit-content">
                  <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                </div>
              </td>
              <td class="dialer-col-right">
                <div class="latestShimmerDesign ml-auto" style="height:19px;width:60px;"></div>
              </td>
            </tr>
          </template>
        </vb-table>

        <div v-if="!isOnFreePlan" class="dialer-settings-title newer mb-0">Available license</div>
        <vb-table
          v-if="!isOnFreePlan"
          class="latestTableDesign-withBlackBorders-again pt-3"
          :isListEmpty="serviceAddons.length==0" 
          :listLength="serviceAddons.length" 
          :perPage="5" 
          :loading="api.addons.send"
        >
          <tr slot="header" class="w-100">
            <th class="dialer-has-sort ">
              <span>Label</span>
            </th>
            <th class="dialer-has-sort ">
              <span>Limit</span>
            </th>
            <th class="dialer-has-sort">
              <span>Used</span>
            </th>
            <th class="dialer-has-sort">
              <span>Remaining</span>
            </th>
            <th class="dialer-has-sort dialer-col-right ">
              <!-- <span>Action</span> -->
            </th>
          </tr>
          <template #body="{ start, end }">
            <tr v-for="(data, index) in serviceAddons" :key="data.product_id" class="w-100" v-show="index >= start && index <= end">
              <td class="dialer-row-title dialer-col-fixed-width">{{ data | get_property(`label`) }}</td>
              <td class="dialer-row-title dialer-col-fixed-width">{{ data | get_property("allowed", 0) }}</td>
              <td class="dialer-row-title dialer-col-fixed-width">{{ data | get_property("used", 0) }}</td>
              <td class="dialer-row-title dialer-col-fixed-width">{{ data | get_property("remaining", 0) }}</td>
              <td class="dialer-row-title dialer-col-right">
                <button style="width: max-content"
                  v-if="getProperty(data, 'used', 0) > 0" 
                  class="dialer-button dialer-button-transparent showDetailButton color-575757" 
                  @click="$modal.show('ShowDetailsBillingModal',{ id: data.product_id, is_addon: true })"
                >
                  <vb-icon icon="show-sub-table-icon" height="16px" width="16px" color="primary"/>
                  View details
                </button>
              </td>
            </tr>
          </template>
          <template #loading>
            <tr v-for="n in 5" :key="n">
              <td>
                <div class="d-flex w-fit-content">
                  <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                </div>
              </td>
              <td>
                <div class="d-flex w-fit-content">
                  <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                </div>
              </td>
              <td>
                <div class="d-flex w-fit-content">
                  <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                </div>
              </td>
              <td>
                <div class="d-flex w-fit-content">
                  <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                </div>
              </td>
              <td class="dialer-col-right">
                <div class="latestShimmerDesign ml-auto" style="height:19px;width:60px;"></div>
              </td>
            </tr>
          </template>
        </vb-table>
        <div>
          <template v-if="!isOnFreePlan">
            <div class="dialer-settings-title newer mb-0">Included Telephone Number</div>
            <vb-table
              class="latestTableDesign-withBlackBorders-again pt-3"
              :isListEmpty="!freeNumber" 
              :listLength="freeNumber?1:0" 
              :perPage="5" 
              :loading="api.addons.send"
            >
              <tr slot="header" class="w-100">
                <th class="dialer-has-sort ">
                  <span>Label</span>
                </th>
                <th class="dialer-has-sort">
                  <span>Remaining</span>
                </th>
                <th class="dialer-has-sort dialer-col-right ">
                  <!-- <span>Action</span> -->
                </th>
              </tr>
              <template #body>
                <tr v-if="freeNumber" class="w-100">
                  <td class="dialer-row-title dialer-col-fixed-width">Included Telephone Number</td>
                  <td class="dialer-row-title dialer-col-fixed-width">{{ freeNumber | get_property("remaining", 0) }}</td>
                  <td class="dialer-row-title dialer-col-right">
                    <button style="width: max-content"
                      v-if="getProperty(freeNumber, 'used', 0) > 0" 
                      class="dialer-button dialer-button-transparent showDetailButton color-575757" 
                      @click="$modal.show('ShowDetailsBillingModal',{ id: freeNumber.product_id, is_addon: true })"
                    >
                      <vb-icon icon="show-sub-table-icon" height="16px" width="16px" color="primary"/>
                      View details
                    </button>
                  </td>
                </tr>
              </template>
              <template #loading>
                <tr v-for="n in 1" :key="n">
                  <td>
                    <div class="d-flex w-fit-content">
                      <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex w-fit-content">
                      <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                    </div>
                  </td>
                  <td class="dialer-col-right">
                    <div class="latestShimmerDesign ml-auto" style="height:19px;width:60px;"></div>
                  </td>
                </tr>
              </template>
            </vb-table>
          </template>
        </div>

        <div class="dialer-settings-title newer mt-20px mb-0">Call bundles</div>
        <div class="latestGreyBox-9-9-2023 mt-20px">
          <!-- <vb-table 
            class=" latestTableDesign-withBlackBorders-again mt-0 pt-3"
            :isListEmpty="addonPrefixes.length==0" 
            :listLength="addonPrefixes.length" 
            :perPage="5" 
            :loading="api.prefixes.send"
          >
            <tr slot="header">
              <th class="dialer-has-sort   ">
                <span class=" ">Name</span>
              </th>
              <th class="dialer-has-sort   ">
                <span>Price</span>
              </th>
              <th class="dialer-has-sort   ">
                <span>Billing Period</span>
              </th>
              <th class="dialer-has-sort dialer-col-right   ">
                <span>Action</span>
              </th>
            </tr>
            <template #body="{ start, end }">
              <tr class="mx-0 px-0" v-for="(prefixe, index) in addonPrefixes" :key="prefixe.product_id" v-show="index >= start && index <= end">
                <td v-b-toggle="`prefix-bundles-${prefixe.id}`" class="dialer-row-title ">
                  <span>
                    {{ prefixe | get_property('product.product_label') }}
                    <b-collapse :id="`prefix-bundles-${prefixe.id}`">
                      <div v-for="bundle in getProperty(prefixe,'product.plan.vspackage.bundles',[])" :key="bundle.id">
                        {{bundle | get_property('bundle.label')}} ({{bundle | get_property('balance')}})
                      </div>
                    </b-collapse>
                  </span>
                </td>
                <td class="dialer-has-sort ">
                  <span>{{ prefixe | get_property('product.plan.plan_cost') | to_fixed }}</span>
                </td>
                <td class="dialer-has-sort ">
                  <span>{{ prefixe | get_property('product.plan.plan_billing_period') }}</span>
                </td>
                <td class="dialer-has-sort dialer-col-right ">
                  <button class="dialer-button dialer-button-primary" :disabled="!!api.update_addon_prefixes.send" @click="updateAddonPrefix(prefixe.product_id)" >
                    <vb-spinner v-if="api.update_addon_prefixes.send==prefixe.product_id" />
                    <template v-else>Purchase</template>
                  </button>
                </td>
              </tr>
            </template>
            <template #loading>
              <tr v-for="n in 5" :key="n">
                <td>
                  <div class="d-flex w-fit-content">
                    <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                  </div>
                </td>
                <td>
                  <div class="d-flex w-fit-content">
                    <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                  </div>
                </td>
                <td>
                  <div class="d-flex w-fit-content">
                    <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                  </div>
                </td>
                <td class="dialer-col-right">
                  <div class="latestShimmerDesign ml-auto" style="height:19px;width:60px;"></div>
                </td>
              </tr>
            </template>
          </vb-table> -->
          <vb-table 
            class="latestTableDesign-withBlackBorders-again mt-0 pt-3"
            :isListEmpty="plans.length==0" 
            :listLength="plans.length" 
            :perPage="5" 
            :loading="api.whmcs_detail.send"
          >
            <template slot="header">
              <tr class="w-100">
                <th class="dialer-has-sort">
                  <span>Call plan name</span>
                </th>
                <th class="dialer-has-sort">
                  <span>Status</span>
                </th>
                <th class="dialer-has-sort text-right">
                  <span>Remaining minutes</span>
                </th>
              </tr>
            </template>
            <template #body="{ start, end }">
              <tr :class="`w-100`" 
                v-for="(data, index) in plans" 
                :key="index" 
                v-show="index >= start && index <= end"
              >
                <td class="dialer-row-title">{{ data | get_property('name') }}</td>
                <td>{{ data.amount > 0 ? 'active' : 'inactive' }}</td>
                <td class="dialer-row-title dialer-col-right">{{ data | get_property('amount') }}</td>
              </tr>
            </template>
            <template #loading>
              <tr v-for="n in 5" :key="n">
                <td>
                  <div class="d-flex w-fit-content">
                    <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                  </div>
                </td>
                <td>
                  <div class="d-flex w-fit-content">
                    <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                  </div>
                </td>
                <td class="dialer-col-right">
                  <div class="latestShimmerDesign ml-auto" style="height:19px;width:60px;"></div>
                </td>
              </tr>
            </template>
          </vb-table>
        </div>
        <!-- <div class="dialer-settings-title newer mt-30px">Available call bundles</div>
        <vb-table
          class="latestTableDesign-withBlackBorders-again pt-3"
          :isListEmpty="callAddons.length==0" 
          :listLength="callAddons.length" 
          :perPage="5" 
          :loading="api.addons.send"
        >
          <tr slot="header">
            <th class="dialer-has-sort one">
              <span class="dialer-sorted-by ascending ">Label</span>
            </th>
            <th class="dialer-has-sort two">
              <span>Limit</span>
            </th>
            <th class="dialer-has-sort three"><span>User</span></th>
            <th class="dialer-has-sort four"><span>Remaining</span></th>
            <th class="dialer-col-right six">
              <span>Action</span>
            </th>
          </tr>
          <template #body="{ start, end }">
            <tr v-for="(data, index) in callAddons" :key="data.product_id" v-show="index >= start && index <= end">
              <td class="dialer-row-title dialer-col-fixed-width">{{ data | get_property(`label`) }}</td>
              <td class="dialer-row-title dialer-col-fixed-width">{{ data | get_property("allowed", 0) }}</td>
              <td class="dialer-row-title dialer-col-fixed-width">{{ data | get_property("used", 0) }}</td>
              <td class="dialer-row-title dialer-col-fixed-width">{{ data | get_property("remaining", 0) }}</td>
              <td class="dialer-row-title dialer-col-right">
                <button 
                  v-if="getProperty(data, 'used', 0) > 0" 
                  class="dialer-button dialer-button-transparent showDetailButton color-575757" 
                  @click="$modal.show('ShowDetailsBillingModal',{ id: data.product_id, is_addon: true })"
                >
                  <vb-icon icon="show-sub-table-icon" class="mr-3" height="16px" width="16px" color="primary"/>
                  Show details
                </button>
              </td>
            </tr>
          </template>
          <template #loading>
            <tr v-for="n in 5" :key="n">
              <td>
                <div class="d-flex w-fit-content">
                  <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                </div>
              </td>
              <td>
                <div class="d-flex w-fit-content">
                  <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                </div>
              </td>
              <td>
                <div class="d-flex w-fit-content">
                  <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                </div>
              </td>
              <td>
                <div class="d-flex w-fit-content">
                  <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                </div>
              </td>
              <td>
                <div class="d-flex w-fit-content">
                  <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                </div>
              </td>
              <td class="dialer-col-right">
                <div class="latestShimmerDesign ml-auto" style="height:19px;width:60px;"></div>
              </td>
            </tr>
          </template>
        </vb-table> -->
        <div class="dialer-settings-title newer mt-30px">Reserve balance</div>
        <vb-table
          class="latestTableDesign-withBlackBorders-again pt-3"
          :isListEmpty="reserveBalances.length==0" 
          :listLength="reserveBalances.length" 
          :loading="api.fetch_reserve_balance.send"
          :noRecordWholePage="true"
        >
          <tr slot="header" class="w-100">
            <th > 
              <span>Name</span>
            </th> 
            <th > 
              <span>Type</span>
            </th> 
            <th > 
              <span>Description</span>
            </th> 
            <th >
              <span>Amount</span>
            </th>
            <th >
              <span>Status</span>
            </th>
            <th >
              <span>Expire at</span>
            </th>
            <th >
              <span>Created at</span>
            </th>
          </tr>
          <template #body="{ start, end }">
            <tr v-for="(reserveBalance, index) in reserveBalances" :key="reserveBalance.id" v-show="index >= start && index <= end">
              <td class="border-0">
                <Info :is_blf="false" :id="reserveBalance.service_account" :type="reserveBalance.service_type=='Number'?'number':reserveBalance.service_type=='CALL_PLAN_PREFIX'?'USER':reserveBalance.service_type" />
              </td>
              <td class="border-0">{{ reserveBalance.service_type=='CALL_PLAN_PREFIX'?'Unlimited Call Plan':reserveBalance.service_type }}</td>
              <td class="border-0">{{ reserveBalance.description }}</td>
              <td class="border-0">{{ reserveBalance.amount | to_fixed }}</td>
              <td class="border-0">{{ reserveBalance.status }}</td>
              <td class="border-0">{{ reserveBalance.expire_at }}</td>
              <td class="border-0">{{ reserveBalance.created_at }}</td>
            </tr>
          </template>
          <template #loading>
            <tr v-for="n in 5" :key="n">
              <td>
                <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
              </td>
              <td>
                <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
              </td>
              <td>
                <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
              </td>
              <td class="dialer-col-right">
                <div class="latestShimmerDesign ml-auto" style="height:50px;width:135px;"></div>
              </td>
              <td class="dialer-col-right">
                <div class="latestShimmerDesign ml-auto" style="height:50px;width:135px;"></div>
              </td>
              <td class="dialer-col-right">
                <div class="latestShimmerDesign ml-auto" style="height:50px;width:135px;"></div>
              </td>
              <td class="dialer-col-right">
                <div class="latestShimmerDesign ml-auto" style="height:50px;width:135px;"></div>
              </td>
            </tr>
          </template>
          <template #no-record>
            <div class="latestGreyBox-9-9-2023 mt-32px p-0">
              <div class="emptyScreenContainer">
                <img class="mt-12px wd-30 h-auto" :src="require('@/assets/images/emptyScreenImages/invoices.png')"/>
                <div class="emptyScreenContainer-heading">
                  Manage invoices and credit notes
                </div>
                <div class="emptyScreenContainer-text w-75">
                  View and manage your invoices and credit notes here. Start 
                  by creating a new invoice or credit note.
                </div>
              </div>
            </div>
          </template>
        </vb-table>

      </modal>

      <!-- <modal name="CardOptionsModal" class="CardOptionsModal" :clickToClose="false" width="50%" height="auto" :scrollable="true">
        <div class="w-100">
          <div class="dialer-edit-header pt-0 w-100">
            <h2 class="dialer-edit-title my-0">Choose payment method </h2>
            <div class="dialer-edit-actions">
              <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide('CardOptionsModal')" />
              <a v-else class="newCloseButton" @click="$modal.hide('CardOptionsModal')">
                <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                <span class="newCloseButton-textPart">Close</span>
              </a>
            </div>
          </div>
          <div class="CardOptionsModal-boxes mt-20px">
            <div class="CardOptionsModal-boxes-item">
              <vb-icon icon="CardOptionsModal-addCard-icon" height="64.27px" width="43.5px" />
              <div class="CardOptionsModal-boxes-text">Add card</div>
            </div>
            <div class="CardOptionsModal-boxes-item" @click="$modal.show('AddCardStripe')">
              <vb-icon icon="CardOptionsModal-addStripeCard-icon" height="64.27px" width="43.5px" />
              <div class="CardOptionsModal-boxes-text">Add stripe card</div>
            </div>
            <div class="CardOptionsModal-boxes-item" @click="$modal.show(`${_uid}-OtherCreditCard`)">
              <vb-icon icon="CardOptionsModal-otherCards-icon" height="64.27px" width="43.5px" />
              <div class="CardOptionsModal-boxes-text">Other cards</div>
            </div>
          </div>
        </div>  
      </modal> -->

      <!-- <modal class="dialer_animation right_side_popup viewPDFmodal full-width-big-modal" height="auto" :scrollable="true" :name="`${_uid}-invoiceTestModal`">
        <div class="dialer-edit-header d-flex align-items-start justify-content-between px-0 pt-3">
          <div class="dialer-edit-actions">
            <a class="cursor_pointer ml-2" @click="$modal.hide(`${_uid}-invoiceTestModal`)">
              <button type="button" class="newCloseButton"> 
                <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                <span class="newCloseButton-textPart">Close</span> 
              </button>
            </a>
          </div>
        </div>
        
        <template>
          <div class=" allow_scroll_2" style="height:calc(100vh - 141px)">
            <div style="box-shadow: 0 2px 10px -2px rgb(27 33 58 / 40%);">
            <div ref="download" style="padding:0mm !important;margin:0mm !important;width:-webkit-fill-available">
              <div class="pdfPage-container">
                <div class="pdfPage-header">
                  <div class="d-flex">
                    <div class="pdfPage-header-leftSide">
                      <vb-icon icon="voipBusiness-mainLogo" class="voipBusiness-mainLogo" height="42px" width="196px"/>
                    </div>
                    <div class="pdfPage-header-rightSide">
                      <div class="invoiceHeading">Invoice</div>
                      <div class="invoiceText">
                        Invoice:
                        <span class="withBold">VB-26319</span>
                      </div>
                      <div class="invoiceText">
                        {{selectedObject.issued}}
                      </div>
                      <div class="invoiceText">
                        Invoice due date:
                        <span class="withBold">2024-03-05</span>
                      </div>
                      <div class="invoiceText">
                        Invoice amount:
                        <span class="withBold">{{selectedObject.total_amount}}</span>
                      </div>
                      <div class="invoiceText paidColor">
                        PAID
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pdfPage-body">
                  <div class="d-flex mb-4">
                    <div class="pdfPage-body-leftSide">
                      <div class="pdfPage-body-heading">Bill to:</div>
                      <div class="addressText">
                        <span>{{selectedObject.address}}</span>
                      </div>
                    </div>
                    <div class="pdfPage-body-rightSide">
                      <div class="pdfPage-body-heading">PAYMENTS</div>
                      <div class="paymentText">
                        {{selectedObject.total_amount}} was paid on 2024-03-05 00:00:00 UTC 
                        by master card ending 
                      </div>
                    </div>
                  </div>
                  <div class="pdfPage-body-heading">Statement summary</div>
                  <div class="tableContainer">
                    <table>
                      <thead>
                        <tr >
                          <th class="w-50">DESCRIPTION</th>
                          <th>DETAILED PERIOD </th>
                          <th>QUANTITY</th>
                          <th>UNIT PRICE</th>
                          <th>TOTAL AMOUNT</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in selectedObject.arr" :key="item.id">
                          <td class="w-50">
                            <pre>{{item.description}}</pre>
                          </td>
                          <td>{{item.period}}</td>
                          <td>{{item.quantity}}</td>
                          <td>{{item.unit_price}}</td>
                          <td>{{item.total}}</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td> </td>
                          <td> </td>
                          <td> </td>
                          <td>VAT @</td>
                          <td>0.00%</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td class="widthBorder">Total excl. VAT </td>
                          <td class="widthBorder"> {{selectedObject.total_amount}} </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td class="widthBold">Invoice total </td>
                          <td class="widthBold"> {{selectedObject.total_amount}} </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td class="widthBorder">Amount paid </td>
                          <td class="widthBorder"> {{selectedObject.total_amount}} </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td class="widthBigText">Amount due (GBP)</td>
                          <td class="widthBigText"> 0.00</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <div class="pdfPage-footer">
                  <div class="d-flex">
                    <div class="pdfPage-header-leftSide">
                      <div class="NotesHeading">NOTES</div>
                      <div class="NotesText"></div>
                    </div>
                    <div class="pdfPage-header-rightSide">
                      <div class="BillFromHeading">Bill from:</div>
                      <div class="BillFromText">VoIP Business</div>
                      <div class="BillFromText">17 Ensign House,</div>
                      <div class="BillFromText">Admirals Way, Canary Wharf,</div>
                      <div class="BillFromText">London, E14 9XQ</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </template>
      </modal> -->

      <modal class="dialer_animation right_side_popup viewPDFmodal full-width-big-modal" height="auto" :scrollable="true" :name="`${_uid}-unpaidInvoices`">
        <div class="dialer-edit-header d-flex align-items-start justify-content-between px-0 pt-3">
          <div class="dialer-edit-actions">
            <a class="cursor_pointer ml-2" @click="$modal.hide(`${_uid}-unpaidInvoices`)">
              <button type="button" class="newCloseButton"> 
                <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                <span class="newCloseButton-textPart">Close</span> 
              </button>
            </a>
          </div>
        </div>
        <template>
          <vb-table
            class="trustedDevicesTable-updated latestTableDesign-withBlackBorders-again mt-0"
            :isListEmpty="unpaidInvoices.length==0" 
            :listLength="unpaidInvoices.length" 
            :perPage="5" 
            :loading="api.invoice_detail.send"
            :noRecordWholePage="true"
          >
            <tr slot="header" class="w-100">
              <th class="border-0"> 
                <span>Invoice</span>
              </th> 
              <!-- <th class=" border-0 ">
                <span>Outstanding amount</span>
              </th> -->
              <th class=" border-0 ">
                <!-- <span>Status</span> -->
              </th>
              <th class=" border-0 ">
                <span><br/> </span>
              </th>
            </tr>
            <template #body="{ start, end }">
              <tr v-for="(invoice, index) in unpaidInvoices" :key="invoice.id" v-show="index >= start && index <= end">
                <td class="border-0">{{ invoice | filterInvoiceType }}</td>
                <!-- <td class="border-0">{{ invoice.invoice_total_amount | to_fixed }}</td> -->
                <td class="border-0">
                  <span class="OwnertaggedVersion green">
                    {{ invoice.invoice_status }}
                  </span>
                </td>
                <td class="border-0" >
                  <div class="d-flex justify-content-end w-100">
                    <span v-if="invoice.invoice_status!='PAID'" @click="payOutstandingAmount(invoice)" class="OwnertaggedVersion mb-0 mr-16px showOnly-OnHover-insideTable">
                      Pay now
                    </span>
                    <span class="text-underline cursor_pointer_no_hover" @click="$modal.show('InvoicePDFDownloadAccountSetting',{id:invoice.invoice_id})">View invoice</span>
                  </div>
                </td>
              </tr>
            </template>
            <template #loading>
              <tr v-for="n in 5" :key="n">
                <td>
                  <div class="d-flex w-fit-content">
                    <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                  </div>
                </td>
                <td>
                  <div class="d-flex w-fit-content">
                    <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                  </div>
                </td>
                <td>
                  <div class="d-flex w-fit-content">
                    <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                  </div>
                </td>
                <td class="dialer-col-right">
                  <div class="latestShimmerDesign ml-auto" style="height:50px;width:135px;"></div>
                </td>
              </tr>
            </template>
            <template #no-record>
              <div class="latestGreyBox-9-9-2023 mt-32px p-0">
                <div class="emptyScreenContainer">
                  <img class="mt-12px wd-30 h-auto" :src="require('@/assets/images/emptyScreenImages/invoices.png')"/>
                  <div class="emptyScreenContainer-heading">
                    Manage invoices and credit notes
                  </div>
                  <div class="emptyScreenContainer-text w-75">
                    View and manage your invoices and credit notes here. Start 
                    by creating a new invoice or credit note.
                  </div>
                </div>
              </div>
            </template>
          </vb-table>
        </template>
      </modal>

      <modal class="dialer_animation right_side_popup viewPDFmodal full-width-big-modal" height="auto" :scrollable="true" :name="`${_uid}-DetailReserveBalance`">
        <div class="dialer-edit-header d-flex align-items-center justify-content-between px-0 pt-3">
          <h2 class="dialer-edit-title newer mb-0">Reserve balance details</h2>
          <div class="dialer-edit-actions">
            <a class="cursor_pointer ml-2 newCloseButton" @click="$modal.hide(`${_uid}-DetailReserveBalance`)">
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span> 
            </a>
          </div>
        </div>
        <template>
          <vb-table
            class="latestTableDesign-withBlackBorders-again mt-0"
            :isListEmpty="reserveBalances.length==0" 
            :listLength="reserveBalances.length" 
            :loading="api.fetch_reserve_balance.send"
            :noRecordWholePage="true"
          >
            <tr slot="header" class="w-100">
              <th class="border-0"> 
                <span>Name</span>
              </th> 
              <th class="border-0"> 
                <span>Type</span>
              </th> 
              <th class="border-0"> 
                <span>Description</span>
              </th> 
              <th class="border-0">
                <span>Amount</span>
              </th>
              <th class="border-0">
                <span>Status</span>
              </th>
              <th class="border-0">
                <span>Expire at</span>
              </th>
              <th class="border-0">
                <span>Created at</span>
              </th>
            </tr>
            <template #body="{ start, end }">
              <tr v-for="(reserveBalance, index) in reserveBalances" :key="reserveBalance.id" v-show="index >= start && index <= end">
                <td class="border-0">{{ reserveBalance.service_account }}</td>
                <td class="border-0">{{ reserveBalance.service_type }}</td>
                <td class="border-0">{{ reserveBalance.description }}</td>
                <td class="border-0">{{ reserveBalance.amount | to_fixed }}</td>
                <td class="border-0">{{ reserveBalance.status }}</td>
                <td class="border-0">{{ reserveBalance.expire_at }}</td>
                <td class="border-0">{{ reserveBalance.created_at }}</td>
              </tr>
            </template>
            <template #loading>
              <tr v-for="n in 5" :key="n">
                <td>
                  <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                </td>
                <td>
                  <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                </td>
                <td>
                  <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                </td>
                <td class="dialer-col-right">
                  <div class="latestShimmerDesign ml-auto" style="height:50px;width:135px;"></div>
                </td>
                <td class="dialer-col-right">
                  <div class="latestShimmerDesign ml-auto" style="height:50px;width:135px;"></div>
                </td>
                <td class="dialer-col-right">
                  <div class="latestShimmerDesign ml-auto" style="height:50px;width:135px;"></div>
                </td>
                <td class="dialer-col-right">
                  <div class="latestShimmerDesign ml-auto" style="height:50px;width:135px;"></div>
                </td>
              </tr>
            </template>
            <template #no-record>
              <div class="latestGreyBox-9-9-2023 mt-32px p-0">
                <div class="emptyScreenContainer">
                  <img class="mt-12px wd-30 h-auto" :src="require('@/assets/images/emptyScreenImages/invoices.png')"/>
                  <div class="emptyScreenContainer-heading">
                    Manage invoices and credit notes
                  </div>
                  <div class="emptyScreenContainer-text w-75">
                    View and manage your invoices and credit notes here. Start 
                    by creating a new invoice or credit note.
                  </div>
                </div>
              </div>
            </template>
          </vb-table>
        </template>
      </modal>

      <modal 
        class="center-zoomIn-transition v2 TopUpProductsModal" 
        width="500px" 
        height="auto" 
        :scrollable="true" 
        :name="`${_uid}-TransactionLogsModal`" 
      >
        <section class="ct-user-new dialer-edit">
          <div class="dialer-form lg-mar-bottom">
            <div class="dialer-edit-header d-flex justify-content-between align-items-center">
              <div>
                <h2 class="dialer-settings-title newer mb-0">Transaction logs</h2>
              </div>
              <div class="dialer-edit-actions">
                <a class="newCloseButton" @click="$modal.hide(`${_uid}-TransactionLogsModal`)">
                  <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                  <span class="newCloseButton-textPart">Close</span>
                </a>
              </div>
            </div>
            <div class="md-mar-vertical">
              <vb-table 
                :isListEmpty="creditLogs.length==0" 
                :listLength="creditLogs.length" 
                :perPage="5" 
                :loading="api.invoice_detail.send"
                :noRecordWholePage="true"
                class="TopUpProductsBox latestTableDesign-withBlackBorders-again"
              >
                <tr slot="header" class="w-100">
                  <th class="border-0"> 
                    <span>Amount</span>
                  </th> 
                  <th class="border-0"> 
                    <span>Type</span>
                  </th> 
                  <th class="border-0"> 
                    <span>Description</span>
                  </th> 
                </tr>
                <template #body="{ start, end }">
                  <tr v-for="(creditLog, index) in creditLogs" :key="creditLog.id" v-show="index >= start && index <= end">
                    <td class="border-0">{{ creditLog.amount | to_fixed }}</td>
                    <td class="border-0">{{ creditLog.type }}</td>
                    <td class="border-0">{{ creditLog.description }}</td>
                  </tr>
                </template>
                <template #loading>
                  <tr v-for="n in 5" :key="n">
                    <td>
                      <div class="d-flex w-fit-content">
                        <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex w-fit-content">
                        <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex w-fit-content w-100">
                        <div class="latestShimmerDesign flex-1" style="height:19px;width:100%"></div>
                      </div>
                    </td>
                  </tr>
                </template>
                <template #no-record>
                  <div class="latestGreyBox-9-9-2023 mt-32px p-0">
                    <div class="emptyScreenContainer">
                      <img class="mt-12px wd-30 h-auto" :src="require('@/assets/images/emptyScreenImages/invoices.png')"/>
                      <div class="emptyScreenContainer-heading">
                        Manage invoices and credit notes
                      </div>
                      <div class="emptyScreenContainer-text w-75">
                        View and manage your invoices and credit notes here. Start 
                        by creating a new invoice or credit note.
                      </div>
                    </div>
                  </div>
                </template>
              </vb-table>
            </div>
          </div>
        </section>
      </modal>



      <InvoiceDetailModal @updated="fetchAccountDetail()" />
      <UserDetailModal />
      <ViewAllInvoicesModal />
      <TopupSettingModal />
      <OtherCardListModal 
        :modalName="`${_uid}-OtherCreditCard`" 
        ref="other_cards" 
        @fetch-cards="fetchCards()" 
        :isOnFreePlan="isOnFreePlan"
        :anyUnlimitedPlan="anyUnlimitedPlan" 
      />
      <PaymentDetailModal />
      <SetCardPriorityModal @priority-set="fetchCards()" @cards-updated="fetchCards();" />
      <SubscriptionPlanDetailModal 
        :allAddons="allAddons" 
        @update-plan="fetchAccountDetail();fetchSubscriptionDetail();fetchSubscriptions()" 
        @delete-service="fetchSubscriptionDetail();fetchSubscriptions();fetchAddons();" 
      />
      <NumbersListModal />
      <InvoicePDFDownload :modalName="`InvoicePDFDownloadAccountSetting`" />
      <AddCreditModal />
      <VsPackagesModal />
      <ShowDetailsBilling 
        :subscription_detail="response.subscription_detail" 
        :allAddons="allAddons" 
        @delete-service="fetchSubscriptionDetail();fetchSubscriptions();fetchAddons();" 
      />
      <AddCardStripeModal />
    </section>
  </div>
</template>

<script>
import InvoiceDetailModal from "../Modals/InvoiceDetailModal.vue";
import SubscriptionPlanDetailModal from "../Modals/settings/SubscriptionPlanDetailModal.vue";
import OtherCardListModal from "../Modals/settings/OtherCardList.vue";
import UserDetailModal from "../Modals/UserDetailModal.vue";
import InvoicePDFDownload from '../Modals/InvoicePDFDownload.vue'
import TopupSettingModal from "../Modals/TopupSettingModal.vue";
import SetCardPriorityModal from "../Modals/SetCardPriorityModal.vue";
import ShowDetailsBilling from "../Modals/settings/ShowDetailsBilling.vue";

import PaymentDetailModal from '../Modals/PaymentDetailModal.vue'
import NumbersListModal from '../Modals/NumbersListModal.vue';
import ViewAllInvoicesModal from '../Modals/ViewAllInvoicesModal.vue';
import AddCreditModal from '../Modals/AddCreitModal.vue';
import VsPackagesModal from "../Modals/VsPackagesModal.vue";
import { $fn, /*events,*/ VOIP_API, } from "../../utils";
// import { centrifugo } from '../../Centrifuge';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment-timezone';



import Info from '../Lists/Info.vue';
import { check_service } from '../../mixin';
import AddCardStripeModal from '../Modals/AddCardStripeModal.vue';
import { AMI_EVENTS, AMI_SOCKET } from '@/ami';

// let tabs = [
//   {text:'All Users',value:'users'},
//   {text:'All Numbers',value:'numbers'},
//   {text:'Estimated Invoices',value:'invoices'},
//   {text:'Subscriptions',value:'whmcs'},
//   // {text:'Allowance',value:'allowance'},
//   {text:'Plan License',value:'license'},
//   {text:'Call Bundles',value:'addons'}
// ]


// comented on 17 oct 2023 ( START )
// let tabs = [
//   {text:'All Users',value:'users',tooltipText:'View all available user accounts linked to your account'},
//   {text:'All Numbers',value:'numbers',tooltipText:'Review all assigned phone numbers associated with your account'},
//   {text:'Invoices & credit notes',value:'invoices',tooltipText:'Effortlessly manage invoices and credit notes in one place'},
//   {text:'Subscriptions',value:'whmcs',tooltipText:'Access information about your subscription details and preferences'},
//   // {text:'Allowance',value:'allowance'},
//   {text:'Plan License',value:'license',tooltipText:'Get a comprehensive view of your active services, their limits, and the status of available slots. Easily manage and delete services as needed'},
//   {text:'Call Bundles',value:'addons',tooltipText:'Easily subscribe to available bundles or explore new options'}
// ]
// comented on 17 oct 2023 ( END )

let tabs = [
  {text:'Call plans',value:'users',tooltipText:'View all available user accounts linked to your account',icon:'newbilling-tab-callplans-icon'},
  // {text:'All numbers',value:'numbers',tooltipText:'Review all assigned phone numbers associated with your account',icon:'newbilling-tab-allNumbers-icon'},
  {text:'Subscriptions',value:'whmcs',tooltipText:'Access information about your subscription details and preferences',icon:'newbilling-tab-Subscriptions-icon'},
  {text:'Invoices',value:'invoices',tooltipText:'Effortlessly manage invoices and credit notes in one place',icon:'newbilling-tab-invoices-icon'},
  // {text:'Credit logs',value:'credit-logs',tooltipText:'Effortlessly manage invoices and credit notes in one place',icon:'newbilling-tab-invoices-icon'},
  {text:'Account balance',value:'balance',tooltipText:'Effortlessly manage invoices and credit notes in one place',icon:'newbilling-tab-accountBalance-icon'},
  // {text:'Allowance',value:'allowance'},
  // {text:'Plan license',value:'license',tooltipText:'Get a comprehensive view of your active services, their limits, and the status of available slots. Easily manage and delete services as needed'},
  // {text:'Call bundles',value:'addons',tooltipText:'Easily subscribe to available bundles or explore new options'}
]

export default {
  name: "AccountSettings",
  mixins: [
    check_service
  ],
  inject:[
    'isEmpty',
    'getProperty',
    'appNotify',
    'getVoipUsers',
    'getVoipTeams',
    'getCallQueue',
    'getIvrs',
    'getChangePlanLog',
    'getFeatures',
    'appFetchSubscriptionDetail',
  ],
  components: {
    OtherCardListModal,
    InvoiceDetailModal,
    SubscriptionPlanDetailModal,
    UserDetailModal,
    AddCreditModal,
    SetCardPriorityModal,
    PaymentDetailModal,
    TopupSettingModal,
    NumbersListModal,
    ViewAllInvoicesModal,
    InvoicePDFDownload,
    VsPackagesModal,
    Info,
    ShowDetailsBilling,
    AddCardStripeModal
  },
  data() {
    return {
      api: {
        // cards
        cards: this.$helperFunction.apiInstance({  }),
        primary_card: this.$helperFunction.apiInstance({ send: '', }),
        secondary_card: this.$helperFunction.apiInstance({ send: '', }),
        delete_card: this.$helperFunction.apiInstance({ send: '', }),
        switch_priority_card: this.$helperFunction.apiInstance({  }),
        // account
        account_detail: this.$helperFunction.apiInstance({  }),
        // invoices
        invoice_detail: this.$helperFunction.apiInstance({  }),
        invoices: this.$helperFunction.apiInstance({  }),
        // numbers
        numbers: this.$helperFunction.apiInstance({  }),
        delete_number: this.$helperFunction.apiInstance({ send: '', }),
        // whmcs
        whmcs_detail: this.$helperFunction.apiInstance({  }),
        // prefixes
        prefixes: this.$helperFunction.apiInstance({  }),
        update_prefixes: this.$helperFunction.apiInstance({  }),
        change_prefixes: this.$helperFunction.apiInstance({ send: '' }),
        update_addon_prefixes: this.$helperFunction.apiInstance({ send: '', }),
        // subscriptio1n
        subscription_detail: this.$helperFunction.apiInstance({  }),
        cancel_trail: this.$helperFunction.apiInstance({  }),
        reactivate_services: this.$helperFunction.apiInstance({  }),
        fetch_subscriptions: this.$helperFunction.apiInstance({  }),
        // reserve balance
        fetch_reserve_balance: this.$helperFunction.apiInstance({  }),
        // addon
        addons: this.$helperFunction.apiInstance({  }),
        // balance
        fetch_balance: this.$helperFunction.apiInstance({  }),
        fetch_sms_balance: this.$helperFunction.apiInstance({  }),

        pay_outstanding_blance: this.$helperFunction.apiInstance({  }),
      },
      response: {
        account_detail: {},
        subscription_detail: {},
        subscriptions: {},
        reserve_balance: {},
        invoice_detail: {},
        invoices: [],
        whmcs_detail: {},
        cards: [],
        numbers: [],
        prefixes: {},
        addons: {},
        balance: {},
        sms_balance: {},
      },
      selected: {
        tab: tabs[0].value,
        plan_type: false,
        detail_id: "",
        reset(){
          this.tab = tabs[0].value
          this.plan_type = false
          this.detail_id = ""
        },
      },
      conditions: {
        collapse: '',
      },
      // visible: false,

      // selectedObject: {},
      // forObjs:{
      //   recurring: {
      //     title: 'Recurring Invoice with multiple users',
      //     issued: 'Issued on 24 Feb 2024',
      //     address: 'Park Ave South, Floor 16 New York',
      //     total_amount: '$35',
      //     arr: [
      //       {
      //         description: 'Users included in plan',
      //         period: '24 Feb 2024 - 23 Mar 2024',
      //         quantity: '5',
      //         unit_price: '$5',
      //         total: '$25'
      //       },
      //       {
      //         description: 'Additional numbers',
      //         period: '24 Feb 2024 - 23 Mar 2024',
      //         quantity: '2',
      //         unit_price: '$5',
      //         total: '$10'
      //       },
      //     ]
      //   },
      //   freeToTrial: {
      //     title: 'Shifting from Free to Trial',
      //     issued: 'Issued on 24 Feb 2024',
      //     address: 'Park Ave South, Floor 16 New York',
      //     total_amount: '$0',
      //     arr: [
      //       {
      //         description: 'Change package from "old plan" to "new plan"',
      //         period: '',
      //         quantity: '',
      //         unit_price: '',
      //         total: ''
      //       },
      //       {
      //         description: 'Users included in plan',
      //         period: '24 Feb 2024 - 23 Mar 2024',
      //         quantity: '3',
      //         unit_price: '$0',
      //         total: '$0'
      //       },
      //     ]
      //   },
      //   paidToPaid: {
      //     title: 'Upgrade plan invoice (paid to paid)',
      //     issued: 'Issued on 24 Feb 2024',
      //     address: 'Park Ave South, Floor 16 New York',
      //     total_amount: '$250',
      //     arr: [
      //       {
      //         description: 'Change package from "old plan" to "new plan"',
      //         period: '',
      //         quantity: '',
      //         unit_price: '',
      //         total: ''
      //       },
      //       {
      //         description: 'Users included in plan',
      //         period: '24 Feb 2024 - 23 Mar 2024',
      //         quantity: '20',
      //         unit_price: '$10',
      //         total: '$200'
      //       },
      //       {
      //         description: 'Numbers included in plan',
      //         period: '24 Feb 2024 - 23 Mar 2024',
      //         quantity: '5',
      //         unit_price: '$10',
      //         total: '$50'
      //       },
      //     ]
      //   },
      // },
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getIsMobile',
      'getVoipUsersAlises',
      'getVoipNumbers',
    ]),
    // cards
    primaryCard(){ return this.response?.cards?.find?.(item=>item.primary=='yes') },
    secondaryCard(){ return this.response?.cards?.find?.(item=>item.secondary=='yes') },
    otherCards(){ return this.response?.cards?.filter?.(item=>item.primary!='yes' && item.secondary!='yes') },
    // users
    users(){ return Object.keys(this.response.account_detail.users || {}) },
    anyUnlimitedPlan(){ return Object.values(this.response.account_detail.users || {}).some(i=>i.plan_prefix_type=='unlimited') },
    // invoices
    // invoices(){ return _.orderBy(this.response.invoices?.data ?? []/*?.invoice_detail?.invoices?.invoices?.invoice*/, ['status','created_at'], ['desc','desc']) },
    invoices(){ return this.response.invoices?.data ?? []/*_.orderBy(this.response.invoices?.data ?? []/~?.invoice_detail?.invoices?.invoices?.invoice~/, ['invoice_paid_at'], ['desc'])*/ },
    unpaidInvoices(){ return this.invoices?.filter?.(item=>item.invoice_status!='PAID') ?? [] },
    isAnyUnPaidInvoice(){ return this.unpaidInvoices.length>0 },
    creditLogs(){ return this.response.invoices?.creditLogs ?? [] },
    // numbers
    didNumbers(){ return this.response.numbers?.filter?.(item=>item.object=='TelephoneNumbers') ?? [] },
    // whmcs
    whmcsProducts(){ return this.response.whmcs_detail?.products?.product?.filter?.(item=>item.status!='Terminated') ?? [] },
    // subscriptionProducts(){ return this.response.whmcs_detail?.plan_detail?.package_group_detail?.group_allowed_addons?.filter?.(item=>item.status!='Terminated') ?? [] },
    plans(){ return this.response?.whmcs_detail?.plans ?? [] },
    // prefixes
    callPrefixes(){ return this.response.prefixes?.callPrefixes?.map?.(item=>( { text: `${item?.prefix?.label} ${item?.prefix?.prefix}`, value: item?.prefix?.prefix, } )) || [] },
    addonPrefixes(){ return this.response?.prefixes?.addonPrefixes ?? [] },
    // subscription
    currentPlanSuspended(){ return this.response.subscription_detail?.userCurrentSubscription?.subscription_status=="INACTIVE" },
    isOnTrail(){ return this.response.subscription_detail?.usercheckTrial?.trial?.trial_plan_status=='active'/*?.userCurrentSubscription?.plan_detail?.type=='trial'*//*?.usercheckTrial?.is_trial==1*/ },
    isOnFreePlan(){ return this.response.subscription_detail?.userCurrentSubscription?.plan_detail?.plan_free==1 },
    
    freeProducts(){ return this.response.subscription_detail?.freeProductBreakDown ?? {} },
    subscriptionProducts(){ return this.response.subscriptions?.data/*this.response.subscription_detail?.userActiveSubscription*/ ?? [] },
    subscriptionProductsGroupBy(){ return _.groupBy(this.response.subscriptions?.data ?? [], 'type'); },
    // addons
    allAddons(){ return Object.values(this.response.addons ?? {}) ?? [] },
    serviceAddons(){ return this.allAddons.filter(i=>i.type=='service' && !`${i.product_id}`.startsWith('free_number_')) },
    freeNumber(){ return this.allAddons.find(i=>i.type=='service' && `${i.product_id}`.startsWith('free_number_')) },
    callAddons(){ return this.allAddons.filter(i=>i.type!='service') },
    isPostPaidEnabled(){ return this.response.invoice_detail?.voip_balance?.is_post_paid_enable=='yes' },
    // others
    tabs(){ return tabs },
    reserveBalances(){ return this.response.reserve_balance?.data ?? [] },
    reserveBalanceTotal(){ return this.reserveBalances.filter(i=>i.status=='ACTIVE').reduce((v,i)=>parseFloat(i.amount)+parseFloat(v),0) || 0 },
  },
  filters: {
    subscriptionGroupTotalPrice(data){ return data?.reduce?.((c,v)=>parseFloat(v.amountDue)+parseFloat(c),0) ?? 0 },
    filterInvoiceType(invoice){
      const { invoice_type, invoice_id } = invoice
      let type = invoice_type || ''
      if(type=='upgrade') type = 'Plan Change'
      else if(type=='RECURRING') type = 'Periodic'
      if(type) type += '-'
      return `${type}${invoice_id || ''}`
    },
    showTime(value,dv='-'){
      if (!value) return dv;
      return moment(value).format("MMM Do");
    },
  },
  methods: {
    // reserve balance
    async fetchReserveBalance(){
      try {
        this.api.fetch_reserve_balance.send = true;
        const { data } = await VOIP_API.endpoints.billing_api.getReserveBalance({ 
          uid: this.getCurrentUser?.uid 
        })
        this.response.reserve_balance=data ?? {}
      } finally {
        this.api.fetch_reserve_balance.send = false;
      }
    },
    // cards
    fetchCards(){
      let vm = this;
      vm.api.cards.send = true;
      VOIP_API.endpoints.billing_api.getcreditcards({ 
        uid: vm.getCurrentUser?.uid 
      })
      .then(({ data: { data: cards } }) => {
        vm.response.cards = cards;
      })
      .finally(() => {
        vm.api.cards.send = false;
      });
    },
    deleteCard(card_real_id){
      let vm = this
      if(vm.api.delete_card.send || !card_real_id) return;
      vm.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to delete this Card`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            vm.api.delete_card.send=card_real_id
            VOIP_API.endpoints.billing_api.deletecreditcard({
              id: card_real_id,
              accountcode: vm.getCurrentUser?.account,
              uid: vm.getCurrentUser?.uid,
            })
            .then(()=>{
              vm.fetchCards()
              vm.appNotify({
                message: 'Successfully Updated!',
                type: 'success',
              })
            })
            .catch((ex)=>{
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            })
            .finally(()=>{
              vm.api.delete_card.send=''
            })
          }
        },
      });
    },
    makePrimary(card_real_id){
      let vm = this
      if(vm.api.delete_card.send==card_real_id || vm.api.primary_card.send || !card_real_id) return;
      vm.api.primary_card.send=card_real_id
      VOIP_API.endpoints.billing_api.makedefaultcreditcard({
        id: card_real_id,
        accountcode: vm.getCurrentUser?.account,
        uid: vm.getCurrentUser?.uid,
        flag: 'primary'
      })
      .then(()=>{
        vm.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
        vm.fetchCards()
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.primary_card.send=''
      })
    },
    makeSecondary(card_real_id){
      let vm = this
      if(vm.api.delete_card.send==card_real_id || vm.api.secondary_card.send || !card_real_id) return;
      vm.api.secondary_card.send=card_real_id
      VOIP_API.endpoints.billing_api.makedefaultcreditcard({
        id: card_real_id,
        accountcode: vm.getCurrentUser?.account,
        uid: vm.getCurrentUser?.uid,
        flag: 'secondary'
      })
      .then(()=>{
        vm.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
        vm.fetchCards()
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.secondary_card.send=''
      })
    },
    switchPriority(){
      let vm = this
      if(vm.api.switch_priority_card.send) return;
      vm.api.switch_priority_card.send=true
      VOIP_API.endpoints.billing_api.makedefaultcreditcard({
        accountcode: vm.getCurrentUser?.account,
        uid: vm.getCurrentUser?.uid,
        flag: 'switch'
      })
      .then(()=>{
        vm.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
        vm.fetchCards()
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.switch_priority_card.send=false
      })
    },
    // account detail
    fetchAccountDetail(){
      let vm = this;
      vm.api.account_detail.send = true;
      VOIP_API.endpoints.users.accountdetail()
      .then(({ data }) => {
        vm.response.account_detail = data
      })
      .finally(() => {
        vm.api.account_detail.send = false
      });
    },
    // invoice detail
    fetchInvoiceDetail(){
      let vm = this;
      vm.api.invoice_detail.send = true;
      VOIP_API.endpoints.users.accountinvoicedetail({
        uid: vm.getCurrentUser?.uid,
      })
      .then(({ data }) => {
        vm.response.invoice_detail = data
      })
      .finally(() => {
        vm.api.invoice_detail.send = false
      });
    },
    fetchInvoices(){
      let vm = this;
      vm.api.invoices.send = true;
      VOIP_API.endpoints.users.userInvoices({
        uid: vm.getCurrentUser?.uid,
      })
      .then(({ data }) => {
        vm.response.invoices = data ?? []
      })
      .finally(() => {
        vm.api.invoices.send = false
      });
    },
    // numbers
    fetchNumbers(){
      let vm = this
      if(vm.api.numbers.send) return;
      vm.api.numbers.send=true
      VOIP_API.endpoints.telephone_number.numbers()
      .then(({ data: res }) => {
        vm.response.numbers = res.data || res
      })
      .finally(() => {
        vm.api.numbers.send = false
      });
    },
    deletenumber(id){
      let vm = this
      if(vm.api.delete_number.send) return;
      vm.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to delete this Number`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if(confirm){
            vm.api.delete_number.send=id
            VOIP_API.endpoints.telephone_number.deleteNumber({
              id: id,
              uid: vm.getCurrentUser?.uid,
              accountcode: vm.getCurrentUser?.account,
            }).then(() => {
              vm.fetchNumbers()
            }).catch((ex)=>{
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            }).finally(() => {
              vm.api.delete_number.send=''
            });
          }
        }
      })
    },
    // whmcs
    fetchWHMCS(){
      let vm = this
      if(vm.api.whmcs_detail.send) return;
      vm.api.whmcs_detail.send=true
      VOIP_API.endpoints.billing.whmcsDetail({
        uid: vm.getCurrentUser.uid,
        accountcode: vm.getCurrentUser.account,
      })
      .then(({ data: detail })=>{
        vm.response.whmcs_detail= detail
      })
      .finally(()=>{
        vm.api.whmcs_detail.send=false
      })
    },
    // prefixes
    async changePrefix(account){
      if(this.api.change_prefixes.send) return;
      let value = this.response.account_detail.users[account].plan_prefix_type=='unlimited'
      try {
        this.api.change_prefixes.send=account
        if(!value) {
          const payload = await this.checkServicePromise({
            service: 'unlimited_call_plan',
            account: account,
          })
          if(payload?.finally) return;
          await VOIP_API.endpoints.techprifex.update(account,{
            account,
            accountcode: account,
          })
          value=!value
        } else {
          const { confirm } = await this.$appConfirmation({
            title: 'Warning',
            message: `With this action you are going to downgrade your package.`,
            button: {
              yes: 'Now',
              no: 'Till Next Billing Date',
            },
          })
          if(confirm==null) return;
          await VOIP_API.endpoints.techprifex.update(account,{
            account,
            accountcode: account,
            type: 'downgrade_call_plan_prefix',
            billing: confirm?0:1,
          })
        }
        // this.fetchAccountDetail()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.change_prefixes.send=''
        await this.$nextTick();
        const toggle_ref = this.$refs?.[`${account}-toggle`]?.[0]
        if(toggle_ref) toggle_ref.localChecked = value;
        this.response.account_detail.users[account].plan_prefix_type=value?'unlimited':'limited'
      }
      
      // let vm = this
      // if(this.api.change_prefixes.send) return;
      // let value = this.response.account_detail.users[account].plan_prefix_type=='unlimited'
      // if(!value) {
      //   vm.api.change_prefixes.send=true
      //   this.checkService({
      //     service: 'unlimited_call_plan',
      //     account: account,
      //     async then(){
      //       await VOIP_API.endpoints.techprifex.update(account,{
      //         account,
      //         accountcode: account,
      //       })
      //       value=!value
      //       vm.fetchAccountDetail()
      //     },
      //     catch(ex){
      //       vm.appNotify({
      //         message: ex.own_message || ex.message,
      //         type: 'error',
      //       })
      //     },
      //     finally(){
      //       const toggle_ref = vm.$refs?.[`${account}-toggle`]?.[0]
      //       if(toggle_ref) toggle_ref.localChecked = value;
      //       vm.api.change_prefixes.send=''
      //     },
      //   })
      // } else {
      //   vm.$appConfirmation({
      //     title: 'Warning',
      //     message: `With this action you are going to downgrade your package.`,
      //     button: {
      //       yes: 'Now',
      //       no: 'Till Next Billing Date',
      //     },
      //     callback({ confirm }){
      //       vm.api.change_prefixes.send=true
      //       if(confirm==null) {
      //         const toggle_ref = vm.$refs?.[`${account}-toggle`]?.[0]
      //         if(toggle_ref) toggle_ref.localChecked = value;
      //         vm.api.change_prefixes.send=''
      //         return;
      //       }
      //       VOIP_API.endpoints.techprifex.update(account,{
      //         account,
      //         accountcode: account,
      //         type: 'downgrade_call_plan_prefix',
      //         billing: confirm?0:1,
      //       })
      //       .then(()=>{
      //         vm.fetchAccountDetail()
      //       })
      //       .catch((ex)=>{
      //         vm.appNotify({
      //           message: ex.own_message,
      //           type: 'error',
      //         })
      //       })
      //       .finally(()=>{
      //         const toggle_ref = vm.$refs?.[`${account}-toggle`]?.[0]
      //         if(toggle_ref) toggle_ref.localChecked = value;
      //         vm.api.change_prefixes.send=''
      //       })
      //     },
      //   })
      // }
      
      // this.$appConfirmation({
      //   title: "Warning",
      //   message: `Are you sure you want to switch to unlimited call`,
      //   button: {
      //     no: "no",
      //     yes: "yes",
      //   },
      //   async callback({confirm}) {
      //     try {
      //       if(!confirm) return;
      //       vm.api.change_prefixes.send=true
      //       await VOIP_API.endpoints.techprifex.update(account,{
      //         account,
      //         accountcode: account,
      //       })
      //       value=!value
      //       vm.fetchAccountDetail()
      //     } catch (ex) {
      //       vm.appNotify({
      //         message: ex.own_message,
      //         type: 'error',
      //       })
      //     } finally {
      //       const toggle_ref = vm.$refs?.[`${account}-toggle`]?.[0]
      //       if(toggle_ref) toggle_ref.localChecked = value;
      //       vm.api.change_prefixes.send=''
      //     }
      //   },
      // });

      // this.checkService({
      //   service: 'call_prefix',
      //   product_id: account,
      //   async then(){
      //     await VOIP_API.endpoints.techprifex.update(account,{
      //       account,
      //       accountcode: account,
      //     })
      //   },
      //   catch(ex){
      //     vm.appNotify({
      //       message: ex.own_message,
      //       type: 'error',
      //     })
      //   },
      //   finally(){
      //   },
      // })
    },
    fetchPrefixes(){
      let vm = this
      vm.api.prefixes.send=true
      VOIP_API.endpoints.techprifex.get({
        uid: vm.getCurrentUser?.uid,
        accountcode: vm.getCurrentUser?.account,
      })
      .then(({ data: prefixes })=>{
        vm.response.prefixes = prefixes || {}
      })
      .finally(()=>{
        vm.api.prefixes.send=false
      })
    },
    updatePrefix(accountcode,prefix){
      let vm = this
      const prefix_obj = (vm.response.prefixes.callPrefixes || []).find(item=>item.prefix.prefix==prefix)
      if(!prefix_obj || vm.api.update_prefixes.send) return;
      vm.api.update_prefixes.send=true
      VOIP_API.endpoints.techprifex.update(prefix_obj.product_id,{
        id: prefix_obj.product_id,
        prefix: prefix,
        useraccount: accountcode,
        accountcode: accountcode,
        account: vm.getCurrentUser?.account,
        uid: vm.getCurrentUser?.uid,
      })
      .then(()=>{
        return $fn.sleep(2 * 1000)
      })
      .then(()=>{
        vm.fetchAccountDetail()
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(()=>{
        vm.api.update_prefixes.send=false
      })
    },
    updateAddonPrefix(product_id){
      let vm = this
      const prefix_obj = (vm.response.prefixes.addonPrefixes || []).find(item=>item.product_id==product_id)
      if(!prefix_obj || vm.api.update_addon_prefixes.send) return;
      vm.api.update_addon_prefixes.send=product_id
      vm.checkService({
        service: 'call_prefix',
        product_id: product_id,
        async then(){
          await VOIP_API.endpoints.techprifex.update(product_id,{
            id: product_id,
            prefix: product_id,
            useraccount: vm.getCurrentUser?.account,
            accountcode: vm.getCurrentUser?.account,
            account: vm.getCurrentUser?.account,
            uid: vm.getCurrentUser?.uid,
          })
          vm.fetchPrefixes()
          // vm.$root.$emit(events.check_pending_services)
        },
        catch(ex){
          vm.appNotify({
            message: ex.own_message,
            type: 'danger',
          })
        },
        finally(){
          vm.api.update_addon_prefixes.send=''
        },
      })
    },
    // subscription
    fetchSubscriptionDetail(){
      let vm = this
      if(vm.api.subscription_detail.send) return;
      vm.api.subscription_detail.send = true;
      VOIP_API.endpoints.billing.getsubscriptiondetail({ 
        uid: vm.getCurrentUser?.uid,
      })
      .then(({ data }) => {
        vm.response.subscription_detail = data;
      })
      .finally(() => {
        vm.api.subscription_detail.send = false;
      });
    },
    async fetchSubscriptions(){
      if(this.api.fetch_subscriptions.send) return;
      try {
        this.api.fetch_subscriptions.send = true;
        const { data } = await VOIP_API.endpoints.billing.getsubscriptions({ 
          uid: this.getCurrentUser?.uid,
        })
        this.response.subscriptions = data;
      } finally {
        this.api.fetch_subscriptions.send = false;
      }
    },
    async cancelTrail(){
      let vm = this
      if(this.api.cancel_trail.send) return;
      try {
        const { confirm } = await vm.$appConfirmation({
          title: "Warning",
          message: `Are you sure you want to end the trial`,
          variant: 'danger',
          button: {
            no: "No",
            yes: "Yes",
          },
        })
        if(!confirm) return;
        this.api.cancel_trail.send=true
        await VOIP_API.endpoints.billing.cancelTrail({
          uid: vm.getCurrentUser.uid,
          accountcode: vm.getCurrentUser.account,
        })
        this.fetchSubscriptionDetail()
        this.fetchSubscriptions()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.cancel_trail.send=false
      }
    },
    async reactivateServices(){
      if(this.api.reactivate_services.send) return;
      try {
        const { confirm } = await this.$appConfirmation({
          title: "Warning",
          message: `Are you sure you want to reactivate the services`,
          variant: 'danger',
          button: {
            no: "No",
            yes: "Yes",
          },
          alert: {
            variant: 'info',
            title: 'Attention',
            message: `Make sure you sufficient balance to reactive services`,
          },
        })
        if(!confirm) return;
        this.api.reactivate_services.send=true
        await VOIP_API.endpoints.billing.reactivateServices({
          uid: this.getCurrentUser.uid,
        })
        this.fetchAccountDetail();
        this.fetchInvoiceDetail();
        this.fetchInvoices();
        this.fetchNumbers();
        this.fetchWHMCS();
        this.fetchPrefixes();
        this.fetchSubscriptionDetail();
        this.fetchSubscriptions();
        this.fetchAddons();
        
        this.getVoipUsers();
        this.getVoipTeams();
        this.getCallQueue();
        this.getIvrs();
        this.getChangePlanLog();
        this.getFeatures();
        this.appFetchSubscriptionDetail();
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.cancel_trail.send=false
      }
    },
    // addon
    fetchAddons(){
      let vm = this
      if(vm.api.addons.send) return;
      vm.api.addons.send = true;
      VOIP_API.endpoints.billing.getPlanAddonDetail({ 
        uid: vm.$store.getters.getCurrentUser?.uid,
      }).then(({ data }) => {
        vm.response.addons = data;
      }).finally(() => {
        vm.api.addons.send = false;
      });
    },
    // balance
    async fetchBalance(){
      if(this.api.fetch_balance.send) return;
      try {
        this.api.fetch_balance.send=true
        const { data } = await VOIP_API.endpoints.billing.getbalance({
          uid: this.getCurrentUser.uid,
        })
        this.response.balance=data??{}
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_balance.send=false
      }
    },
    async fetchSMSBalance(){
      if(this.api.fetch_sms_balance.send) return;
      try {
        this.api.fetch_sms_balance.send=true
        const { data } = await VOIP_API.endpoints.billing.getsmsbalance({
          uid: this.getCurrentUser.uid,
        })
        this.response.sms_balance=data??{}
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_sms_balance.send=false
      }
    },

    // pay outstanding amount
    
    async payOutstandingAmount(invoice){
      if(this.api.pay_outstanding_blance.send) return;
      try {
        const { confirm } = await this.$appConfirmation({
          title: 'Warning',
          message: `Are you sure you want to manually pay this invoice`,
          button: {
            yes: 'Yes',
            no: 'No',
          },
        })
        if(!confirm) return;
        const { data } = await VOIP_API.endpoints.billing_api.payOutstandingAmount({
          accountcode: this.getCurrentUser?.account,
          uid: this.getCurrentUser?.uid ,
          invoice_id: invoice.invoice_id,
          sub_id: invoice.sub_id,
          invoice_status: invoice.invoice_status,
          payment_gateway: "stripe",
          invoice_total_amount: invoice.invoice_total_amount,
          invoice_description: invoice.invoice_description,
        })
        if(data.invoice_status == 'PAID'){
          this.fetchInvoices()
          this.appNotify({
            message: 'Successfully Payed!',
            type: 'success',
          })
        }
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.pay_outstanding_blance.send=''
      }
    },
    
    // event
    onNotification(message) {
      let vm = this
      if (message.data.action == "add" && message.data.section == "plan") {
        this.fetchCards();
        this.$refs?.other_cards?.fetchCards?.()
        this.fetchAccountDetail();
        this.fetchInvoiceDetail();
        this.fetchInvoices();
        $fn.sleep(3 * 1000)
        .then(()=>{
          vm.fetchSubscriptionDetail()
          vm.fetchSubscriptions()
        })
      }
      if (message.data.flag == "card_added") {
        this.fetchCards();
        this.$refs?.other_cards?.fetchCards?.()
        this.fetchAccountDetail();
        this.fetchInvoiceDetail();
        this.fetchInvoices();
        $fn.sleep(3 * 1000)
        .then(()=>{
          vm.fetchSubscriptionDetail()
          vm.fetchSubscriptions()
        })
      }
      if(message.data.flag=='PLAN_SUSPENDED' || message.data.flag=='PLAN_TERMINATE'){
        // vm.$modal.hide('SubscriptionPlanDetailModal')
        $fn.sleep(3 * 1000)
        .then(()=>{
          vm.fetchSubscriptionDetail()
          vm.fetchSubscriptions()
          vm.fetchWHMCS()
        })
      }
      if(message.data.flag=='TRIAL_ENDED'){
        $fn.sleep(3 * 1000)
        .then(()=>{
          vm.fetchSubscriptionDetail()
          vm.fetchSubscriptions()
          vm.fetchWHMCS()
        })
      }
      if(message.data.flag=='PLAN_CHANGED'){
        $fn.sleep(3 * 1000)
        .then(()=>{
          vm.fetchSubscriptionDetail()
          vm.fetchSubscriptions()
          vm.fetchWHMCS()
        })
      }
      if(message.data.action == "new_card_add" && message.data.section == "card" && message.data.flag == "new_card"){
        this.fetchCards();
        this.$refs?.other_cards?.fetchCards?.()
        $fn.sleep(3 * 1000)
        .then(()=>{
          vm.fetchSubscriptionDetail()
          vm.fetchSubscriptions()
        })
      }
      if(message.data.flag == "CLOSE_PAYMENT_MODAL") {
        this.fetchCards();
        this.$refs?.other_cards?.fetchCards?.()
        $fn.sleep(3 * 1000)
        .then(()=>{
          vm.fetchSubscriptionDetail()
          vm.fetchSubscriptions()
        })
      }
      if(message.data.flag == "topup_added") {
        this.fetchBalance()
        this.fetchInvoices()
        this.fetchInvoiceDetail()
        this.fetchSMSBalance()
      }
      if(message.data.type == "UPDATE_CALL_PLAN") {
        this.fetchAccountDetail();
        this.fetchInvoices();
        this.fetchInvoiceDetail();
      }
    },
  },
  activated() {
    this.fetchBalance();
    this.fetchSMSBalance();
    this.fetchCards();
    this.fetchAccountDetail();
    this.fetchInvoiceDetail();
    this.fetchInvoices();
    this.fetchNumbers();
    this.fetchWHMCS();
    this.fetchPrefixes();
    this.fetchSubscriptionDetail();
    this.fetchSubscriptions()
    this.fetchAddons();
    this.fetchReserveBalance();
  },
  created() {
    AMI_SOCKET.on(AMI_EVENTS.broadcast,this.onNotification)
    // centrifugo.notification.on(centrifugo.notification.events.message,this.onNotification)
    // centrifugo.notification_admin.on(centrifugo.notification_admin.events.message,this.onNotification)
    // centrifugo.mothership.on(centrifugo.mothership.events.message,this.onNotification)
  },
  beforeDestroy() {
    AMI_SOCKET.off(AMI_EVENTS.broadcast,this.onNotification)
    // centrifugo.notification.off(centrifugo.notification.events.message,this.onNotification)
    // centrifugo.notification_admin.off(centrifugo.notification_admin.events.message,this.onNotification)
    // centrifugo.mothership.off(centrifugo.mothership.events.message,this.onNotification)
  },
};
</script>

<style lang="scss">
#credit-slip {
  width: 100%;
  height: 100%;
  background-color: #eee;
}
.add-topUpBtn {
  position: absolute;
  top: -80px;
  right: 0px;
}
</style>