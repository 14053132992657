var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "center-zoomIn-transition v2 m-w-500",
    attrs: {
      "name": _vm.modalName,
      "width": "500px",
      "height": "auto",
      "scrollable": true
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "w-100"
  }, [_c('div', {
    staticClass: "dialer-edit-header w-100 pt-0 d-block"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Update label")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v("Enter the name of the number below.")])]), _c('b-form', {
    staticClass: "w-100",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.updateLabel();
      }
    }
  }, [_c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('label', [_vm._v("Label")]), _c('b-input', {
    attrs: {
      "disabled": _vm.api.update_label.send
    },
    model: {
      value: _vm.forms.update_label.label,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update_label, "label", $$v);
      },
      expression: "forms.update_label.label"
    }
  }), _vm.forms.update_label.submitted && _vm.$v.forms.update_label.label.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_label.label.required ? _c('span', [_vm._v("Label is required")]) : !_vm.$v.forms.update_label.label.alphaNumSpace ? _c('span', [_vm._v("Label can be only alphabet and number")]) : _vm._e()]) : _vm._e()], 1)]), _c('div', {
    staticClass: "w-100"
  }, [_c('b-button', {
    staticClass: "fullWidthDoneButton mt-20px",
    attrs: {
      "disabled": _vm.api.update_label.send,
      "type": "submit"
    }
  }, [_vm.api.update_label.send ? _c('vb-spinner') : _vm._e(), _vm._v(" Update ")], 1)], 1)])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }