<template>
  <div class="dialer-analytics-main dialer-analytics-numbers">
    <div class="dialer-box p-16-24-px">
      <!-- <header class="dialer-analytics-titleBar">
        <template v-if="getIsMobile">
          <div class="dialer-analytics-title calc-title-width-analytics">
            <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" @click="$emit('back')" />
            <h1>Numbers</h1>
          </div>
        </template>
        <div v-else class="dialer-analytics-title">
          <b-icon icon="grid3x3-gap" variant="primary"></b-icon>
          <h1 class="md-mar-left">Numbers</h1>
        </div>
        <div class="TabsDesignWithIcon-container">
          <div class="TabsDesignWithIcon-item mr-8px" 
            v-for="tab in tabs" :key="tab.value" 
            @click="selected.tab=tab.value;tab.value==tabs.custom.value ? '' : fetchNumbers()" 
            :class="{'active': selected.tab === tab.value}"
          >
            <vb-icon :icon="tab.icon" height="38px" width="38px" />
            <span class="TabsDesignWithIcon-item-textPart">{{tab.text}}</span>
          </div>
        </div>
      </header>
      <div class="dialer-analytics-datescroller">
        <h3 class="dialer-analytics-subtitle mb-0">
          <span v-if="selected.tab==tabs.custom.value">Select dates</span>
          <span v-else-if="response.numbers.data && response.numbers.data.date_label">{{ response.numbers.data.date_label }}</span>
        </h3>
        <div style="flex-direction: column;align-items: flex-end;flex-direction: column;" class="d-flex">
          <div v-if="selected.tab==tabs.custom.value" class="d-flex customDate">
            <div class="d-inline-flex align-items-center mr-3 whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker">
              <span>From:</span>
              <vue-ctk-date-time-picker id="start_time" name="start_time"
                v-bind="ctkDateTimePickerOptions" 
                class="ml-2 date-time-input" 
                v-model="filter.numbers.start_time" 
              />
            </div>
            <div class="d-inline-flex align-items-center mr-4 whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker subSectionRightAligned">
              <span>To:</span>
              <vue-ctk-date-time-picker id="end_time" name="end_time"
                v-bind="ctkDateTimePickerOptions" 
                class="ml-2 date-time-input" 
                :min-date="filter.numbers.start_time" 
                v-model="filter.numbers.end_time" 
              />
            </div>
            <a class="newButton" @click="fetchNumbers()">
              <vb-icon icon="squared-browse-icon" height="38px" width="38px" />
              <span class="newButton-textPart">Fetch</span>
            </a>
          </div>
          <div class="whiteBGinputWithGreyRoundedBorder seachBox sm-mar-left mt-3">
            <b-input type="text" placeholder="Search numbers" v-model="filter.numbers.search" />
          </div>
        </div>
      </div> -->
      <template v-if="getIsMobile">
        <header class="dialer-analytics-titleBar pb-1">
          <div class="dialer-analytics-title calc-title-width-analytics">
            <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" style="" @click="$emit('back')" />
            <h1 class="">Numbers</h1>
          </div>
        </header>
        <div class="w-100 d-flex align-items-center justify-content-end">
          <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterWithText mr-16px">
            <b-dropdown varient="link" no-caret class="ico-filter" right> 
              <template #button-content>
                <span class="mr-12px" v-if="response.numbers.data && response.numbers.data.date_label">{{ response.numbers.data.date_label }}</span>
                <b-icon icon="three-dots-vertical" variant="dark" class="fillBlack" scale="1.5" />
              </template>
              <b-dropdown-item v-for="tab in tabs" :key="tab.value" @click="selected.tab=tab.value;tab.value==tabs.custom.value ? $modal.show(`${_uid}-dateFilterModal`) : fetchNumbers()" >
                <vb-icon icon="markAsComplete-icon" :style="`opacity:${selected.tab === tab.value ? '1' : '0'}`" class="mr-12px" width="14.49px" height="10.431px"/>
                <span >{{tab.text}}</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <div class="whiteBGinputWithGreyRoundedBorder seachBox w-100 mb-16px mt-8px">
          <b-input type="text" placeholder="Search number" class="w-100" v-model="filter.numbers.search" />
        </div>
      </template>
      <div v-else class="analytics-newHeader mb-32px">
        <div class="analytics-newHeader-leftSide">
          <!-- <b-icon icon="grid3x3-gap" variant="primary"></b-icon> -->
          <!-- class="md-mar-left" -->
          <h1 >Numbers</h1>
        </div>
        <div class="analytics-newHeader-rightSide">
          <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterWithText mr-16px">
            <b-dropdown varient="link" no-caret class="ico-filter" right> 
              <template #button-content>
                <span class="mr-12px" v-if="response.numbers.data && response.numbers.data.date_label">{{ response.numbers.data.date_label }}</span>
                <b-icon icon="three-dots-vertical" variant="dark" class="fillBlack" scale="1.5" />
              </template>
              <b-dropdown-item v-for="tab in tabs" :key="tab.value" @click="selected.tab=tab.value;tab.value==tabs.custom.value ? $modal.show(`${_uid}-dateFilterModal`) : fetchNumbers()" >
                <vb-icon icon="markAsComplete-icon" :style="`opacity:${selected.tab === tab.value ? '1' : '0'}`" class="mr-12px" width="14.49px" height="10.431px"/>
                <span >{{tab.text}}</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="whiteBGinputWithGreyRoundedBorder seachBox">
            <b-input type="text" placeholder="Search number" v-model="filter.numbers.search" />
          </div>
        </div>
      </div>
      <template v-if="getIsMobile">
        <div class="latestGreyBox-9-9-2023 mt-16px mb-16px">
          <div class="basicWhiteIOScard">
            <div class="basicWhiteIOScard-item" 
              v-for="number in numbers"
              :key="number.did" 
            >
              <div class="w-100 d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <div class="basicIOSIcon mr-6px">
                    <img class="country-img" width="45px" :src="number.number.number | number_formater | flag_icon" />
                  </div>
                  <div class="headingInsideTable">
                    {{ number.number.number | number_formater }}
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div class="d-flex mr-6px">
                    <div class="textInsideCard onRightSide textAlignRight" style="color: #87868C;">{{ number | get_property('number.totalCalls',0) }}</div>
                    <div class="textInsideCard onRightSide textAlignRight mx-1">/</div>
                    <div class="textInsideCard onRightSide textAlignRight" style="color: #FF1900;">{{ number | get_property('number.totalMissCalls',0) }}</div>
                  </div>
                  <!-- <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" /> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <vb-table v-else 
        class="latestTableDesign-withBlackBorders-again"
        :isListEmpty="numbers.length==0" 
        :listLength="numbers.length" 
        :loading="api.fetch_numbers.send" 
        :noRecordWholePage="true"
      >
        <tr slot="header">
          <th class="dialer-has-sort">
            <span>Number</span>
          </th>
          <th class="dialer-has-sort dialer-col-center">
            <span>Total</span>
          </th>
          <th class="dialer-has-sort dialer-col-center dialer-inbound">
            <span>Received</span>
          </th>
          <th class="dialer-has-sort dialer-col-center dialer-outbound">
            <span>Outbound</span>
          </th>
          <th class="dialer-has-sort dialer-col-center dialer-missed">
            <span>Missed</span>
          </th>
          <th class="dialer-has-sort dialer-col-center" v-if="!getIsMobile">
            <span id="numbersTcdTooltip" v-b-popover.hover.focus.top.html="'<small>Total call duration</small>'">TCD</span>
          </th>
          <th class="dialer-has-sort dialer-col-center" v-if="!getIsMobile">
            <span id="numbersAcdTooltip" v-b-popover.hover.focus.top.html="'<small>Average call duration</small>'">ACD</span>
          </th>
          <th class="dialer-has-sort dialer-col-center" v-if="!getIsMobile">
            <span id="numbersTtaTooltip" v-b-popover.hover.focus.top.html="'<small>Time to answer</small>'">TTA</span>
          </th>
        </tr>
        <template #body="{ start, end }">
          <tr v-for="(number, index) in numbers" :key="number.did" v-show="index >= start && index <= end">
            <td class="dialer-row-title dialer-col-name-width wd-75">
              <span class="display-responsive mr-2">NUMBER :</span>
              <div class="d-flex dialer-numberWithCountry">
                <span >
                  <img class="country-img" width="45px" :src="number.number.number | number_formater | flag_icon" />
                </span>
                <span class="d-flex flex-column ml-1 dialer-numberInfo justify-content-center">
                  <span class="m-0 didNumber">{{ number.number.number | number_formater }}</span>
                </span>
              </div>
            </td>
            <td class="dialer-col-center wd-25">
              <span class="display-responsive mr-2">TOTAL :</span>
              {{ number.number.totalCalls }}
            </td>
            <td class="dialer-col-center dialer-inbound wd-25">
              <span class="display-responsive mr-2">INBOUND :</span>
              {{ number.number.totalReceiveCalls }}
            </td>
            <td class="dialer-col-center dialer-outbound wd-25">
              <span class="display-responsive mr-2">OUTBOUND :</span>
              {{ number.number.totalOutgoingCalls }}
            </td>
            <td class="dialer-col-center dialer-missed wd-25">
              <span class="display-responsive mr-2">MISSED :</span>
              {{ number.number.totalMissCalls }}
            </td>
            <td class="dialer-col-center dialer-col-tcd-width wd-25" v-if="!getIsMobile">
              <span class="display-responsive mr-2">TCD :</span>
              {{ number.kpis.tcd | time_audio }} 
            </td>
            <td class="dialer-col-center dialer-col-acd-width wd-25" v-if="!getIsMobile">
              <span class="display-responsive mr-2">ACD :</span>
              {{ number.kpis.acd | time_audio }}
            </td>
            <td class="dialer-col-center wd-25" v-if="!getIsMobile">
              <span class="display-responsive mr-2">TTA :</span>
              {{ number.kpis.tta | time_audio  }}
            </td>
          </tr>
        </template>
        <template #loading>
          <tr v-for="n in 5" :key="n">
            <td>
              <div class="d-flex">
                <div class="latestShimmerDesign mr-2" style="height:35px;width:35px;"></div>
                <div class="d-flex flex-column justify-content-between">
                  <div class="latestShimmerDesign flex-1" style="height:16px;width:60px"></div>
                  <div class="latestShimmerDesign mt-1" style="height:16px;width:30px"></div>
                </div>
              </div>
            </td>
            <td>
              <div class="d-flex flex-column align-items-center w-100">
                <div class="latestShimmerDesign mt-2" style="height:16px;width:50px"></div>
              </div>
            </td>
            <td>
              <div class="d-flex flex-column align-items-center w-100">
                <div class="latestShimmerDesign mt-2" style="height:16px;width:50px"></div>
              </div>
            </td>
            <td>
              <div class="d-flex flex-column align-items-center w-100">
                <div class="latestShimmerDesign mt-2" style="height:16px;width:50px"></div>
              </div>
            </td>
            <td>
              <div class="d-flex flex-column align-items-center w-100">
                <div class="latestShimmerDesign mt-2" style="height:16px;width:50px"></div>
              </div>
            </td>
            <td>
              <div class="d-flex flex-column align-items-center w-100">
                <div class="latestShimmerDesign mt-2" style="height:16px;width:50px"></div>
              </div>
            </td>
            <td>
              <div class="d-flex flex-column align-items-center w-100">
                <div class="latestShimmerDesign mt-2" style="height:16px;width:50px"></div>
              </div>
            </td>
            <td class="dialer-col-right">
              <div class="d-flex flex-column align-items-center w-100">
                <div class="latestShimmerDesign mt-2" style="height:16px;width:50px"></div>
              </div>
            </td>
          </tr>
        </template>
        <template #no-record>
          <div class="latestGreyBox-9-9-2023 mb-32px">
            <div class="emptyScreenContainer">
              <img 
                v-if="GET_LOCAL_SETTING_IS_DARK_MODE" 
                class="mt-50px" 
                width="20%" 
                height="auto" 
                :src="require('@/assets/images/emptyScreenImages/darkMode/numbersAnalytics.png')"
              />
              <img v-else class="mt-50px" width="20%" height="auto" :src="require('@/assets/images/emptyScreenImages/numbersAnalytics.png')"/>
              <div class="emptyScreenContainer-heading">Numbers await</div>
              <div class="emptyScreenContainer-text w-75">Your call data is just a click away. Start making and reviewing calls to populate this section.</div>
              <button class="dialer-button dialer-button-primary mt-20px">Make your first call</button>
            </div>
          </div>
        </template>
      </vb-table>
    </div>
    <modal :name="`${_uid}-dateFilterModal`" class="callReportingFilterModal">
      <div class="dialer-edit-header pt-0 position-relative">
        <div>
          <h2 class="dialer-settings-title newer mb-0">Filters</h2>
        </div>
        <div class="dialer-edit-actions">
          <button class="newDoneButton" type="button" @click="$modal.hide(`${_uid}-dateFilterModal`)">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart">Done</span>
          </button>
        </div>
      </div>
      <div class="latestGreyBox-9-9-2023">
        <div class="analytics-newHeader-text">
          <div class="d-flex customDate">
            <div class="whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker subSectionLeftAligned w-50 pr-2">
              <span>From:</span>
              <vue-ctk-date-time-picker v-bind="ctkDateTimePickerOptions" class="date-time-input" v-model="filter.numbers.start_time" id="starttime" />
            </div>
            <div class="whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker subSectionRightAligned w-50 pl-2">
              <span>To:</span>
              <vue-ctk-date-time-picker :min-date="filter.numbers.start_time" v-bind="ctkDateTimePickerOptions" class="date-time-input" v-model="filter.numbers.end_time" id="endtime" />
            </div>
          </div>
          <div class="w-100 d-flex justify-content-end mt-20px">
            <a class="newButton" @click="fetchNumbers();$modal.hide(`${_uid}-dateFilterModal`)">
              <vb-icon icon="squared-browse-icon" height="38px" width="38px" />
              <span class="newButton-textPart">Fetch</span>
            </a>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { VOIP_API } from "../../utils";
import { ctkDateTimePicker } from '../../mixin';
import { mapGetters } from 'vuex';
import { GET_LOCAL_SETTING_IS_DARK_MODE } from '@/store/helper/getters';
import { number_formater } from '@/filter';
const tabs = {
  today: {
    text: "Today",
    value: "today",
    icon: 'squared-today-icon'
  },
  week: {
    text: "This week",
    value: "week",
    icon: 'squared-thisWeek-icon'
  },
  month: {
    text: "This month",
    value: "month",
    icon: 'squared-thisMonth-icon'
  },
  custom: {
    text: "Custom",
    value: "custom",
    icon: 'squared-customDate-icon'
  },
}
export default {
  name: "Numbers",
  mixins: [
    ctkDateTimePicker
  ],
  data() {
    return {
      api: {
        fetch_numbers: this.$helperFunction.apiInstance({ source: true, }),
      },
      response: {
        numbers: {},
      },
      filter:  {
        numbers: {
          start_time: '',
          end_time: '',
          search: '',
        },
      },
      selected: {
        tab: tabs.today.value,
      },
    };
  },
  computed: {
    ...mapGetters([
      'getIsMobile',
      GET_LOCAL_SETTING_IS_DARK_MODE
    ]),
    numbers(){
      const time_line = this.response.numbers.timeLineData ?? {}
      const numbers = this.response.numbers?.data?.numbers ?? {}
      const kpis = this.response.numbers.kpis ?? {}
      const search = this.filter.numbers.search.trim().toLowerCase()
      return Object.keys(numbers).map(did=>{
        return {
          did: did,
          time_line: time_line[did],
          number: numbers[did],
          kpis: kpis[did],
        }
      // }).filter(item=>`${item.number.name}`.trim().toLowerCase().includes(search))
      }).filter(item=>
        `${number_formater(item?.number?.number ?? '')} ${number_formater(item?.number?.number ?? '','',true)}`.includes(search) ||
        `${number_formater(item?.number?.number ?? '')} ${number_formater(item?.number?.number ?? '','',true)}`.replace(' ','').includes(search)
      )
    },
    tabs(){ return tabs },
  },
  methods: {
    async fetchNumbers(){
      try {
        this.api.fetch_numbers.send=true
        const { data } = await VOIP_API.endpoints.analytics.list({
          type: this.selected.tab,
          section: 'numbers',
          start: this.filter.numbers.start_time,
          end: this.filter.numbers.end_time,
        })
        this.response.numbers = data
      } finally {
        this.api.fetch_numbers.send=false
      }
    },
  },
  mounted() {
    this.fetchNumbers();
  },
};
</script>
