<template>
  <div>
    <div class="flow__graph designed designedAgain responsiveGraph scaleDown-0-6" ref="app_designed" id="app_designed">
      <flowy class="" :nodes="nodes"></flowy>
    </div>
    <IvrGraphMenuOptionsModal @interface="callBack($event)" :menuOptions="ivr" :accountcode="voipaccount" />
    <MenuOptionExtensionAssignModal :modalName="'MenuOptionExtensionAssignModalGraph'" @interface="extensionUpdated($event)" />
    <IVRWelcomeGreetingmodal @file-change="$emit('interface')" />
    <DefaultOutOfHoursModal 
      @ofh-default-updated="fetchDefaultSetting();"
    />
    <DayOutOfHoursModal 
      @ofh-days-updated="fetchSchedule();"
    />
  </div>
</template>

<script>
import DayOutOfHoursModal from '../../Modals/DayOutOfHoursModal.vue'
import DefaultOutOfHoursModal from '../../Modals/DefaultOutOfHoursModal.vue'
import IvrGraphMenuOptionsModal from '../../Modals/IvrGraphMenuOptions.vue'
import IVRWelcomeGreetingmodal from '../../Modals/IVRWelcomeGreetingmodal.vue'
import MenuOptionExtensionAssignModal from "../../Modals/MenuOptionExtensionAssignModal.vue";
import { mapGetters } from 'vuex'
import { VOIP_API } from '../../../utils';
export default {
  name: 'GraphMain',
  components: {
    IvrGraphMenuOptionsModal,
    MenuOptionExtensionAssignModal,
    IVRWelcomeGreetingmodal,
    DayOutOfHoursModal,
    DefaultOutOfHoursModal,
  },
  inject: ['getIvrs'],
  props:{
    accountcode:{
      type: String
    }
  },
  data(){
    return{
      api: {
        delete_dtmf: this.$helperFunction.apiInstance(),
        add_dtmf: this.$helperFunction.apiInstance(),
        out_of_hours: this.$helperFunction.apiInstance(),
        extension_update: this.$helperFunction.apiInstance(),
        schedules: this.$helperFunction.apiInstance(),
        get_setting: this.$helperFunction.apiInstance(),
      },
      response: {
        out_of_hours: false,
        schedules: [],
        default_setting: {},
      },
    }
  },
  computed:{
    ...mapGetters({
      getCurrentUser: 'getCurrentUser',
      getVoipIVRSAlises: 'getVoipIVRSAlises'
    }),
    ivr(){ return this.getVoipIVRSAlises[this.accountcode] },
    voipaccount(){ return this.ivr.voipaccount },
    nodes(){
      if(!this.ivr) return []
      const voipaccount = this.ivr?.voipaccount
      const numbers = this.ivr?.userDid?.map?.(i=>i.did)
      const is_numbers = numbers?.length>0
      const dtmfs = this.ivr?.schedules?.[0]?.options
      const is_dtmfs = dtmfs?.length>0
      const is_ofh = this.response.out_of_hours
      const schedules = this.response.schedules ?? []
      const ofh_default_setting = this.response.default_setting ?? {}
      const nodes = []
      const callBack = this.callBack
      if(is_numbers){
        nodes.push({
          id: "numbers",
          parentId: -1,
          nodeComponent: "vb-graph-node",
          data: {
            node: 'NumbersList',
            numbers,
            callBack,
          }
        })
      }
      nodes.push({
        id: "info",
        parentId: is_numbers ? "numbers" : -1,
        nodeComponent: "vb-graph-node",
        data: {
          node: 'Info',
          voipaccount,
          callBack,
        }
      })
      if(is_ofh){
        nodes.push({
          id: "ofh",
          parentId: "info",
          nodeComponent: "vb-graph-node",
          data: {
            node: 'OutOfHours',
            voipaccount,
            schedules,
            ofh_default_setting,
            callBack,
          }
        })
      }
      nodes.push({
        id: "welcomegreeting",
        parentId: is_ofh ? 'ofh' : 'info',
        nodeComponent: "vb-graph-node",
        data: {
          node: 'WelcomeGreeting',
          voipaccount,
          callBack,
        }
      })
      dtmfs?.forEach((e,i)=>{
        nodes.push({
          id: e.id,
          parentId: i==0? 'welcomegreeting' : dtmfs[i-1]?.id,
          nodeComponent: "vb-graph-node",
          data: {
            node: 'DTMF',
            voipaccount: e?.ivr_cmd_args,
            name: e?.accountname,
            dtmf: e?.user_dtmf,
            callBack,
          }
        })
      })
      nodes.push({
        id: 'advanceoptions',
        parentId: is_dtmfs ? dtmfs[dtmfs.length-1]?.id : 'welcomegreeting',
        nodeComponent: "vb-graph-node",
        data: {
          node: 'AdvanceOptions',
          voipaccount,
          callBack,
        }
      })
      return nodes;
    },
  },
  methods: {
    deleteDTMF(playload){
      var vm = this;
      vm.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to delete this`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            vm.api.delete_dtmf.send=true
            VOIP_API.endpoints.menu.resetoption(this.ivr.voipaccount, {
              option: playload.dtmf == 10 ? "*" : playload.dtmf,
              schedule: this.ivr.schedules[0].id,
            })
            .then(() => {
              vm.$emit('interface');
            })
            .finally(()=>{
              vm.api.delete_dtmf.send=false
            });
          }
        },
      });
    },
    addMenuOption(message){
      let vm = this
      vm.api.add_dtmf.send=true
      VOIP_API.endpoints.menu.addoption(message.option)
      .then(() => {
        vm.$emit('interface');
      })
      .finally(()=>{
        vm.api.add_dtmf.send=false
      });
    },
    extensionUpdated(message){
      // console.log(message)
      let vm = this
      vm.api.extension_update.send=true
      VOIP_API.endpoints.menu.addoption({
        account: message?.account,
        id: this.ivr?.voipaccount,
        option: message?.dtmf,
        schedule_id: this.ivr.schedules[0].id,
        schedule_no: this.ivr.schedules[0].options.findIndex((i)=>i.user_dtmf==message?.dtmf)+1,
        type: "extension",
        command: "callfwd",
        args: message?.account,
      })
      .then(() => {
        vm.$emit('interface');
      })
      .finally(()=>{
        vm.api.extension_update.send=false
      });
    },
    fetchcallRouteSetting() {
      let vm = this
      vm.api.out_of_hours.send=true
      VOIP_API.endpoints.users.callroute({
        accountcode: vm.voipaccount,
      })
      .then(({ data }) => {
        vm.response.out_of_hours = !!data.schedule_enable;
      })
      .finally(() => {
        vm.api.out_of_hours.send=false
      });
    },
    fetchSchedule() {
      let vm = this
      if(vm.api.schedules.send) return;
      vm.api.schedules.send = true;
      VOIP_API.endpoints.users.schedules(vm.voipaccount, {
        fid: 12,
      })
      .then(({ data: res }) => {
        vm.response.schedules = res?.data ?? [];
      })
      .finally(() => {
        vm.api.schedules.send = false;
      });
    },
    fetchDefaultSetting(){
      let vm = this
      if(vm.api.get_setting.send) return;
      vm.api.get_setting.send = true;
      VOIP_API.endpoints.users.getscheduledefault(vm.voipaccount)
      .then(({ data }) => {
        vm.response.default_setting = data || {};
      })
      .finally(() => {
        vm.api.get_setting.send = false;
      });
    },
    callBack(message){
      // console.log("check message", message)
      switch (message?.event) {
        case 'delete_dtmf':
          this.deleteDTMF(message?.payload)
          break;
        case 'add_menu_option':
          this.addMenuOption(message?.payload)
          break
        case 'edit_dtmf':
          this.extensionUpdated(message?.payload)
          break
        case 'add_options':
          this.$modal.show('IvrGraphMenuOptionsModal', {
            value: ''
          })
          break
        case 'edit_option':
          this.$modal.show('MenuOptionExtensionAssignModalGraph', { 
            account: message?.payload?.voipaccount, 
            type: 'setting',
            dtmf: message?.payload?.dtmf, 
          })
          break;
        case 'change_file':
          this.$emit('interface')
          break
        case 'enable_out_of_hours':
          // console.log('check update',message?.payload);
          this.updateOutOfHours(message?.payload)
          break;
        case 'fetch_route_settings':
          // console.log('check update',message?.payload);
          this.fetchcallRouteSetting()
          break;
        case 'update_out_of_hours': 
          this.response.out_of_hours=!!message?.payload?.out_of_hours
          break;
        case 'update_advance_option': 
          this.$emit('interface')
          break;
        case 'update_invalid_input_file': 
          this.$emit('interface')
          break;
        case 'change_welcome_greeting':
          this.$modal.show('IVRWelcomeGreetingmodal',{ 
            accountcode: this.ivr.voipaccount, 
          })
          break;
        case 'open_days_out_of_hours': 
          this.$modal.show('DayOutOfHoursModal',{ 
            accountcode: message?.payload?.voipaccount,
            updated: ()=>{
              message?.payload?.updated?.()
            }
          })
          break;
        case 'open_default_out_of_hours': 
          this.$modal.show('DefaultOutOfHoursModal',{ 
            accountcode: message?.payload?.voipaccount,
            updated: ()=>{
              message?.payload?.updated?.()
            } 
          })
          break;
        case 'show_graph':
          this.$emit('change-graph',message?.payload?.voipaccount)
          break;
        default:
          break;
      }
    },
  },
  async mounted(){
    this.fetchcallRouteSetting();
    this.fetchSchedule()
    this.fetchDefaultSetting()
  }
}
</script>

<style>

</style>