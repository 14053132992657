<template>
  <div class="content-area d-flex h-100 dialer_main_body forTODOList-main">
    <div class="dialer_main_body_inner w-100">
      <div :class="`dialer_inner_left_side forTODOList-sidebar d-r-768px-none ${showSideBar?'open':''}`">
        <h2 v-if="$store.getters.getIsMobile" class="dialer_main_title d-flex justify-content-between align-items-center">
          Task hub
          <div @click="showSideBar=false">
            <b-icon icon="x-lg" @click="showSideBar=false" class="cursor_pointer" />
          </div>
        </h2>
        <h2 v-else class="dialer_main_title d-flex justify-content-between align-items-center">
          Task hub
          <div class="showOnlyOnSmallerScreens" @click="showSideBar=false">
            <b-icon icon="x" @click="showSideBar=false" font-scale="1.5"  class="cursor_pointer" />
          </div>
        </h2>
        <div class="dialer_inner_left_side_inner allow-scroll">
          <ul class="list-unstyled">
            <li 
              v-for="flag in flags" 
              :key="flag.value" 
              :class="`dialer_tab_btn ${selected.flag == flag.value?'active':''}`" 
              @click="selected.flag = flag.value"
            >
              <a>
                <span class="dialer-settingPanel-icon">
                  <vb-icon :icon="flag.icon" width="19.996" height="20" />
                </span>
                <span class="dialer-settingPanel-text"> {{ flag.text }} </span>
              </a>
            </li>
          </ul>
          <h2 class="dialer_main_title d-flex align-items-center">
            <vb-icon icon="todo-allSpaces-icon" class="mr-2" width="23.725px" height="22.076px" />
            Spaces
          </h2>
          <ul class="list-unstyled TODOListVersion">
            <li 
              v-for="space in response.spaces" 
              :key="space.id" 
              :class="`dialer_tab_btn ${selected.space_ids.includes(space.id)?'active':''}`" 
              @click="selected.space_ids.includes(space.id) ? selected.space_ids.splice(selected.space_ids.indexOf(space.id),1) : selected.space_ids.push(space.id)"
            >
              <a class="">
                <span class="dialer-settingPanel-icon">
                  <!-- <b-icon icon="circle-fill" :color="space.color" /> -->
                  <div class="smallColoredTag twentyPX ml-0" :style="`background-color: ${space.color};`"></div>
                </span>
                <span class="dialer-settingPanel-text"> {{ space.name }} </span>
                <div class="d-flex align-items-center color-white">
                  <b-icon 
                    v-if="space.status!='private' && space.created_by==getCurrentUser.account" 
                    icon="trash" variant="danger"
                    class="ml-2 darker-chevron-down withoutPath makeItRed showThisSpecificallyOnlyOnHover" 
                    @click.stop="removeSpaces(space)" 
                  />
                  <b-icon 
                    v-if="space.status=='private' && space.type!='public' ? getCurrentUser.administrator_account : space.created_by==getCurrentUser.account" 
                    icon="pencil-square" 
                    class="ml-2 darker-chevron-down withoutPath showThisSpecificallyOnlyOnHover" 
                    @click.stop="$modal.show('EditSpace',{
                      space_id: space.id
                    })" 
                  />
                  <!-- v-if="selected.space_ids.includes(space.id)" -->
                  <b-icon class="darker-chevron-down withoutPath" :style="`opacity: ${selected.space_ids.includes(space.id) ? 1 : 0}`" icon="check-lg" />
                </div>
              </a>
            </li>
            <vb-no-record v-if="response.spaces.length==0" :text="api.spaces.send ? '' : 'There is no category'" :design="3">
              <!-- <vb-loading v-if="api.spaces.send" slot="loading" /> -->
            </vb-no-record>
            <template v-if="response.spaces.length==0 && api.spaces.send">
              <li class="w-100 mb-3 px-4" v-for="n in 3" :key="n.id">
                <div class="latestShimmerDesign mt-2" style="height:32px;width:100%;"></div>
              </li>
            </template>
            <li class="dialer_tab_btn" @click="$modal.show('AddTaskSpace')">
              <a class="">
                <span class="dialer-settingPanel-icon">
                  <b-icon icon="plus-lg"  />
                </span>
                <span class="dialer-settingPanel-text TODO-smallInnerHeading"> New space </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="dialer_inner_right_side">
        <NewTasks :flag="selected.flag" @openSideBar="showSideBar=true" :space_ids="selected.space_ids" />
      </div>
    </div>
    <AddTaskSpaceModal @created="getSpaces()" />
    <EditSpaceModal @update-list="getSpaces()" />
    <SelectSpaceGroupAndStatusModal :modalName="`TodoTaskMove`" @moved="getSpaces()" />
  </div>
</template>

<script>  
import { mapGetters } from 'vuex';
import { VOIP_API } from '@/utils';
import AddTaskSpaceModal from '@/components/Modals/Todo/AddTaskSpaceModal.vue';
import EditSpaceModal from '@/components/Modals/Todo/EditSpaceModal.vue';
import NewTasks from '@/components/Todo/NewTasks.vue';
import SelectSpaceGroupAndStatusModal from '@/components/Modals/Todo/SelectSpaceGroupAndStatusModal.vue';
import _ from 'lodash'
let flags = {
  all: {
    text: 'Tasks',
    icon: 'todo-tasks-icon',
    value: 'all',
  },
  my_day: {
    text: 'My day',
    icon: 'todo-myday-icon',
    value: 'my_day',
  },
  important: {
    text: 'Important',
    icon: 'todo-important-icon',
    value: 'important',
  },
  planned: {
    text: 'Planned',
    icon: 'todo-planned-icon',
    value: 'planned',
  },
  assign_to_me: {
    text: 'Assign to me',
    icon: 'todo-assignedtome-icon',
    value: 'assign_to_me',
  },
  task_viewer: {
    text: 'Task viewer',
    icon: 'eye-fill',
    value: 'task_viewer',
  },
}
export default {
  name: "Todo",
  components: {
    AddTaskSpaceModal,
    NewTasks,
    EditSpaceModal,
    SelectSpaceGroupAndStatusModal,
  },
  data() {
    return {
      selected: {
        space_ids: [],
        flag: flags.all.value,
      },
      api: {
        spaces: this.$helperFunction.apiInstance({  }),
        remove_space: this.$helperFunction.apiInstance({  }),
      },
      response: {
        spaces: [],
      },
      showSideBar: false
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser'
    ]),
    flags(){ return flags },
    selectedFlags(){ return flags[this.selected.flag] },
  },
  methods: {
    async getSpaces() {
      if(this.api.spaces.send) return;
      try {
        this.api.spaces.send=true
        const { data } = await VOIP_API.endpoints.task_spaces.list({
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
        })
        this.response.spaces=data ?? []
        const space_ids = await this.response.spaces.map(i=>i.id)
        const difference = _.difference(this.selected.space_ids, space_ids)
        this.selected.space_ids = _.difference(this.selected.space_ids, difference)
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.spaces.send=false  
      }
    },
    async removeSpaces(space) {
      const space_id = space.id
      if(!space_id || this.api.remove_space.send) return;
      try {
        this.api.remove_space.send=space_id
        const { confirm } = await this.$appConfirmation({
          title: "WARNING",
          message: `Are you sure you want to delete?`,
          variant: 'danger',
          button: {
            no: "No",
            yes: "Yes",
          },
        })
        if(!confirm) return;
        await VOIP_API.endpoints.task_spaces.remove(space_id,{
          uid: this.getCurrentUser.uid
        })
        this.getSpaces()
        this.appNotify({
          message: 'Successfully Deleted',
          type: 'success',
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.remove_space.send=''
      }
    },
  },
  mounted () {
    this.getSpaces();
  },
  activated () {
    this.getSpaces();
  },
}
</script>

<style>

</style>