<template>
  <HorizontalLayout :text="`Press Option ${dtmf}`" :voipaccount="voipaccount" @change-graph="$emit('interface',$event)">
    <div slot="right">
      <info :id="voipaccount" :name="name" :is_blf="false" />
      <div :class="`d-flex ${ivr ? 'justify-content-between' : 'justify-content-end'} align-items-center w-100 mt-3 position-relative`">
        <vb-audio-player v-if="ivr" :src="ivr | welcomgreetingsrc" :design="'minimum'" class="audioForMobile"/>
        <b-icon style="position: relative;" @click="edit()" icon="pencil-fill" variant="primary" class="cursor_pointer ml-3" />
        <b-icon style="position: relative;" @click="remove()" icon="trash-fill" variant="primary" class="cursor_pointer ml-3" />
      </div>
    </div>
  </HorizontalLayout>
</template>

<script>
import Info from '@/components/Lists/Info.vue'
import HorizontalLayout from '../layouts/Horizontal2.vue'
import { welcomgreetingsrc } from '@/filter'
import { mapGetters } from 'vuex'
export default {
  name: 'DTMFNode',
  components: {
    HorizontalLayout,
    Info,
  },
  props: {
    voipaccount: {
      type: String,
    },
    dtmf: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
  },
  computed: {
    ...mapGetters({
      getVoipIVRSAlises: 'getVoipIVRSAlises'
    }),
    ivr(){ return this.getVoipIVRSAlises[this.voipaccount] },
  },
  filters: {
    welcomgreetingsrc: welcomgreetingsrc,
  },
  methods: {
    edit(){
      this.$emit('interface', {
        from: 'dtmf', 
        event: 'edit_option',
        payload: {
          voipaccount: this.voipaccount,
          dtmf: this.dtmf
        },  
      })
    },
    remove(){
      this.$emit('interface', {
        from: 'dtmf', 
        event: 'delete_dtmf',
        payload: {
          dtmf: this.dtmf,
        },  
      })
    },
  },
}
</script>

<style>

</style>