<template>
  <div class="crm-main-section insightSection">
    <div class="crm-main-section-topBar">
      <h2 class="crm-main-section-heading mb-0">
        Insights
      </h2>
      <div class="section-topBar-middlePart">
        <vb-icon icon="crm-inputSearch-icon" />
        <input type="text" placeholder="Search">
      </div>
      <div class="section-topBar-lastPart">
        <button class="crm-button listGridView-btn centerBtn ml-0">
          <vb-icon class="crm-threeDotsHorizontal-icon" icon="crm-threeDotsHorizontal-icon" />
        </button>
        <b-dropdown class="crm-big-gray-dd-container" no-caret>
          <template #button-content>
            <span class="d-flex align-items-center p-0">
              <span>Application Dashboard</span>
              <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
            </span>
          </template>
          <b-dropdown-item >
            <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
            <span> Application Dashboard </span>
          </b-dropdown-item>
          <b-dropdown-item >
            <span> Application Dashboard </span>
          </b-dropdown-item>
          <b-dropdown-item >
            <span> Application Dashboard </span>
          </b-dropdown-item>
          <b-dropdown-item >
            <span> Application Dashboard </span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div class="crm-main-section-body">
      <div class="insightSection-inner">
        <div class="leftSide">
          <div class="crm-card withSeprateHeader">
            <div class="crm-card-heading">
              <div class="w-100 d-flex justify-content-between align-items-center">
                <div>
                  My activities
                  <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
                </div>
                <button class="crm-small-button">
                  <vb-icon class="crm-plusButton-icon" icon="crm-plusButton-icon" />
                  Create Activity
                </button>
              </div>
              <div class="w-100 d-flex justify-content-between align-items-center mt-38px">
                <b-dropdown class="crm-dd-container" no-caret>
                  <template #button-content>
                    <span class="d-flex align-items-center p-0">
                      <span>This week</span>
                      <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                    </span>
                  </template>
                  <b-dropdown-item >
                    <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                    <span> This week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> This Month </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Month </span>
                  </b-dropdown-item>
                </b-dropdown>
                <div class="crm-input-container mb-0">
                  <vb-icon icon="crm-inputSearch-icon" />
                  <input placeholder="Search" class="w-100"/>
                </div>
              </div>
            </div>
            <div  class="crm-card-body">
              <vb-table :isListEmpty="isEmpty(tableData)" :listLength="tableData.length" textNoRecord=" ">
                <template slot="header">
                  <tr>
                    <th class="dialer-has-sort">
                      <span class="dialer-sorted-by ascending">Title</span>
                    </th>
                    <th class="dialer-has-sort">
                      <span>Due Date</span>
                    </th>
                    <th class="dialer-col-right cursor_pointer">
                      <span>Activity Type </span>
                    </th>
                  </tr>
                </template>
                <template #body="{ start, end }">
                  <tr v-for="(data, index) in tableData" :key="data.id" v-show="index >= start && index <= end">
                    <td class="dialer-row-title">
                      <span>{{ data.m1 }}</span>
                    </td>
                    <td class="dialer-row-title">{{ data.m1 }}</td>
                    <td class="dialer-col-right">
                      <span class="tagTypeDesign">{{data.m2}}</span>
                    </td>
                  </tr>
                </template>
              </vb-table>
            </div>
          </div>
          <div class="crm-card withSeprateHeader">
            <div class="crm-card-heading">
              <div class="w-100 d-flex justify-content-between align-items-center">
                <div>
                  Upcoming activities
                  <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
                </div>
                <b-dropdown class="crm-dd-container" id="sound-setting-micro-devices" no-caret text="Select Device">
                  <template #button-content>
                    <span class="d-flex align-items-center p-0">
                      <span>This week</span>
                      <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                    </span>
                  </template>
                  <b-dropdown-item >
                    <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                    <span> This week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> This Month </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Month </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div class="w-100 d-flex justify-content-between align-items-center mt-38px">
                <b-dropdown class="crm-dd-container" no-caret>
                  <template #button-content>
                    <span class="d-flex align-items-center p-0">
                      <span>This week</span>
                      <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                    </span>
                  </template>
                  <b-dropdown-item >
                    <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                    <span> This week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> This Month </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Month </span>
                  </b-dropdown-item>
                </b-dropdown>
                <div class="crm-input-container mb-0">
                  <vb-icon icon="crm-inputSearch-icon" />
                  <input placeholder="Search" class="w-100"/>
                </div>
              </div>
            </div>
            <div  class="crm-card-body">
              <vb-table :isListEmpty="isEmpty(tableData)" :listLength="tableData.length" textNoRecord=" ">
                <template slot="header">
                  <tr>
                    <th class="dialer-has-sort">
                      <span class="dialer-sorted-by ascending">Title</span>
                    </th>
                    <th class="dialer-has-sort">
                      <span>Due Date</span>
                    </th>
                  </tr>
                </template>
                <template #body="{ start, end }">
                  <tr v-for="(data, index) in tableData" :key="data.id" v-show="index >= start && index <= end">
                    <td class="dialer-row-title">
                      <span>{{ data.m1 }}</span>
                    </td>
                    <td class="dialer-row-title">{{ data.m1 }}</td>
                  </tr>
                </template>
              </vb-table>
            </div>
          </div>
          <div class="crm-card">
            <div class="crm-card-heading">
              <div>
                Activities created by sales agent
                <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
              </div>
              <div class="d-flex align-items-center">
                <b-dropdown class="crm-dd-container" id="sound-setting-micro-devices" no-caret text="Select Device">
                  <template #button-content>
                    <span class="d-flex align-items-center p-0">
                      <span>This week</span>
                      <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                    </span>
                  </template>
                  <b-dropdown-item >
                    <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                    <span> This week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> This Month </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Month </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div class="crm-graph-container">
              put graph here
            </div>
          </div>
          <div class="crm-card">
            <div class="crm-card-heading">
              <div>
                Logged calls by day
                <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
              </div>
              <div class="d-flex align-items-center">
                <b-dropdown class="crm-dd-container mr-3" id="sound-setting-micro-devices" no-caret text="Select Device">
                  <template #button-content>
                    <span class="d-flex align-items-center p-0">
                      <span>Sales Pipeline</span>
                      <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                    </span>
                  </template>
                  <b-dropdown-item >
                    <span> All </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                    <span> Sales Pipeline </span>
                  </b-dropdown-item>
                </b-dropdown>
                <b-dropdown class="crm-dd-container" id="sound-setting-micro-devices" no-caret text="Select Device">
                  <template #button-content>
                    <span class="d-flex align-items-center p-0">
                      <span>This week</span>
                      <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                    </span>
                  </template>
                  <b-dropdown-item >
                    <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                    <span> This week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> This Month </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Month </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div class="crm-graph-container">
              put graph here
            </div>
          </div>
          <div class="crm-card">
            <div class="crm-card-heading">
              <div>
                Total logged calls by sales agent
                <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
              </div>
              <div class="d-flex align-items-center">
                <b-dropdown class="crm-dd-container" id="sound-setting-micro-devices" no-caret text="Select Device">
                  <template #button-content>
                    <span class="d-flex align-items-center p-0">
                      <span>This week</span>
                      <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                    </span>
                  </template>
                  <b-dropdown-item >
                    <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                    <span> This week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> This Month </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Month </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div class="crm-graph-container">
              put graph here
            </div>
          </div>
          <div class="crm-card">
            <div class="crm-card-heading">
              <div>
                Call outcome overview
                <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
              </div>
              <div class="d-flex align-items-center">
                <b-dropdown class="crm-dd-container mr-3" id="sound-setting-micro-devices" no-caret text="Select Device">
                  <template #button-content>
                    <span class="d-flex align-items-center p-0">
                      <span>Sales Pipeline</span>
                      <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                    </span>
                  </template>
                  <b-dropdown-item >
                    <span> All </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                    <span> Sales Pipeline </span>
                  </b-dropdown-item>
                </b-dropdown>
                <b-dropdown class="crm-dd-container" id="sound-setting-micro-devices" no-caret text="Select Device">
                  <template #button-content>
                    <span class="d-flex align-items-center p-0">
                      <span>This week</span>
                      <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                    </span>
                  </template>
                  <b-dropdown-item >
                    <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                    <span> This week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> This Month </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Month </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div class="crm-graph-container">
              put graph here
            </div>
          </div>
          <div class="crm-card">
            <div class="crm-card-heading">
              <div>
                Companies by day
                <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
              </div>
              <div class="d-flex align-items-center">
                <b-dropdown class="crm-dd-container" id="sound-setting-micro-devices" no-caret text="Select Device">
                  <template #button-content>
                    <span class="d-flex align-items-center p-0">
                      <span>This week</span>
                      <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                    </span>
                  </template>
                  <b-dropdown-item >
                    <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                    <span> This week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> This Month </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Month </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div class="crm-graph-container">
              put graph here
            </div>
          </div>
          <div class="crm-card">
            <div class="crm-card-heading">
              <div>
                Companies by source
                <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
              </div>
              <div class="d-flex align-items-center">
                <b-dropdown class="crm-dd-container" id="sound-setting-micro-devices" no-caret text="Select Device">
                  <template #button-content>
                    <span class="d-flex align-items-center p-0">
                      <span>This week</span>
                      <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                    </span>
                  </template>
                  <b-dropdown-item >
                    <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                    <span> This week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> This Month </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Month </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div class="crm-graph-container">
              put graph here
            </div>
          </div>
          <div class="crm-card">
            <div class="crm-card-heading">
              <div>
                Contacts by day
                <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
              </div>
              <div class="d-flex align-items-center">
                <b-dropdown class="crm-dd-container" id="sound-setting-micro-devices" no-caret text="Select Device">
                  <template #button-content>
                    <span class="d-flex align-items-center p-0">
                      <span>This week</span>
                      <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                    </span>
                  </template>
                  <b-dropdown-item >
                    <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                    <span> This week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> This Month </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Month </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div class="crm-graph-container">
              put graph here
            </div>
          </div>
          <div class="crm-card">
            <div class="crm-card-heading">
              <div>
                Contacts by source
                <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
              </div>
              <div class="d-flex align-items-center">
                <b-dropdown class="crm-dd-container" id="sound-setting-micro-devices" no-caret text="Select Device">
                  <template #button-content>
                    <span class="d-flex align-items-center p-0">
                      <span>This week</span>
                      <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                    </span>
                  </template>
                  <b-dropdown-item >
                    <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                    <span> This week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> This Month </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Month </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div class="crm-graph-container">
              put graph here
            </div>
          </div>
          <div class="crm-card withSeprateHeader">
            <div class="crm-card-heading">
              <div class="w-100 d-flex justify-content-between align-items-center">
                <div>
                  Recently created contacts
                  <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
                </div>
                <b-dropdown class="crm-dd-container" id="sound-setting-micro-devices" no-caret text="Select Device">
                  <template #button-content>
                    <span class="d-flex align-items-center p-0">
                      <span>This week</span>
                      <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                    </span>
                  </template>
                  <b-dropdown-item >
                    <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                    <span> This week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> This Month </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Month </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div class="w-100 d-flex justify-content-between align-items-center mt-38px">
                <b-dropdown class="crm-dd-container" no-caret>
                  <template #button-content>
                    <span class="d-flex align-items-center p-0">
                      <span>This week</span>
                      <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                    </span>
                  </template>
                  <b-dropdown-item >
                    <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                    <span> This week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> This Month </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Month </span>
                  </b-dropdown-item>
                </b-dropdown>
                <div class="crm-input-container mb-0">
                  <vb-icon icon="crm-inputSearch-icon" />
                  <input placeholder="Search" class="w-100"/>
                </div>
              </div>
            </div>
            <div  class="crm-card-body">
              <vb-table :isListEmpty="isEmpty(tableData)" :listLength="tableData.length" textNoRecord=" ">
                <template slot="header">
                  <tr>
                    <th class="dialer-has-sort">
                      <span class="dialer-sorted-by ascending">CONTACT</span>
                    </th>
                    <th class="dialer-has-sort">
                      <span>E-MAIL ADDRESS</span>
                    </th>
                    <th class="dialer-col-right cursor_pointer">
                      <span>CREATED AT </span>
                    </th>
                  </tr>
                </template>
                <template #body="{ start, end }">
                  <tr v-for="(data, index) in tableData" :key="data.id" v-show="index >= start && index <= end">
                    <td class="dialer-row-title">
                      <span>{{ data.m1 }}</span>
                    </td>
                    <td class="dialer-row-title">{{ data.m1 }}</td>
                    <td class="dialer-col-right">
                      {{data.m2}}
                    </td>
                  </tr>
                </template>
              </vb-table>
            </div>
          </div>
          <div class="crm-card withSeprateHeader">
            <div class="crm-card-heading">
              <div class="d-flex justify-content-between w-100 align-items-center">
                <div>
                  Closing deals
                  <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
                </div>
                <div class="d-flex">
                  <b-dropdown class="crm-dd-container mr-3" id="sound-setting-micro-devices" no-caret text="Select Device">
                    <template #button-content>
                      <span class="d-flex align-items-center p-0">
                        <span>Admin</span>
                        <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                      </span>
                    </template>
                    <b-dropdown-item >
                      <span> All </span>
                    </b-dropdown-item>
                    <b-dropdown-item >
                      <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                      <span> Admin </span>
                    </b-dropdown-item>
                  </b-dropdown>
                  <b-dropdown class="crm-dd-container" id="sound-setting-micro-devices" no-caret text="Select Device">
                    <template #button-content>
                      <span class="d-flex align-items-center p-0">
                        <span>This week</span>
                        <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                      </span>
                    </template>
                    <b-dropdown-item >
                      <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                      <span> This week </span>
                    </b-dropdown-item>
                    <b-dropdown-item >
                      <span> This Month </span>
                    </b-dropdown-item>
                    <b-dropdown-item >
                      <span> Next Week </span>
                    </b-dropdown-item>
                    <b-dropdown-item >
                      <span> Next Month </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div class="w-100 d-flex justify-content-between align-items-center mt-38px">
                <b-dropdown class="crm-dd-container" no-caret>
                  <template #button-content>
                    <span class="d-flex align-items-center p-0">
                      <span>This week</span>
                      <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                    </span>
                  </template>
                  <b-dropdown-item >
                    <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                    <span> This week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> This Month </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Month </span>
                  </b-dropdown-item>
                </b-dropdown>
                <div class="crm-input-container mb-0">
                  <vb-icon icon="crm-inputSearch-icon" />
                  <input placeholder="Search" class="w-100"/>
                </div>
              </div>
            </div>
            <div  class="crm-card-body">
              <vb-table :isListEmpty="isEmpty(tableData)" :listLength="tableData.length" textNoRecord=" ">
                <template slot="header">
                  <tr>
                    <th class="dialer-has-sort">
                      <span class="dialer-sorted-by ascending">Deal Name</span>
                    </th>
                    <th class="dialer-has-sort">
                      <span>Expected Close date</span>
                    </th>
                  </tr>
                </template>
                <template #body="{ start, end }">
                  <tr v-for="(data, index) in tableData" :key="data.id" v-show="index >= start && index <= end">
                    <td class="dialer-row-title">
                      <span>{{ data.m1 }}</span>
                    </td>
                    <td class="dialer-row-title">{{ data.m1 }}</td>
                  </tr>
                </template>
              </vb-table>
            </div>
          </div>
          <div class="crm-card">
            <div class="crm-card-heading">
              <div>
                Deals by stage
                <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
              </div>
              <div class="d-flex align-items-center">
                <b-dropdown class="crm-dd-container" id="sound-setting-micro-devices" no-caret text="Select Device">
                  <template #button-content>
                    <span class="d-flex align-items-center p-0">
                      <span>This week</span>
                      <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                    </span>
                  </template>
                  <b-dropdown-item >
                    <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                    <span> This week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> This Month </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Month </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div class="crm-graph-container">
              put graph here
            </div>
          </div>
        </div>
        <div class="rightSide">
          <div class="crm-card">
            <div class="crm-card-heading">
              <div>
                Lost deals stage
                <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
              </div>
              <div class="d-flex align-items-center">
                <b-dropdown class="crm-dd-container mr-3" id="sound-setting-micro-devices" no-caret text="Select Device">
                  <template #button-content>
                    <span class="d-flex align-items-center p-0">
                      <span>Sales Pipeline</span>
                      <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                    </span>
                  </template>
                  <b-dropdown-item >
                    <span> All </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                    <span> Sales Pipeline </span>
                  </b-dropdown-item>
                </b-dropdown>
                <b-dropdown class="crm-dd-container" id="sound-setting-micro-devices" no-caret text="Select Device">
                  <template #button-content>
                    <span class="d-flex align-items-center p-0">
                      <span>This week</span>
                      <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                    </span>
                  </template>
                  <b-dropdown-item >
                    <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                    <span> This week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> This Month </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Month </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div class="crm-graph-container">
              put graph here
            </div>
          </div>
          <div class="crm-card">
            <div class="crm-card-heading">
              <div>
                Won deals stage
                <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
              </div>
              <div class="d-flex align-items-center">
                <b-dropdown class="crm-dd-container mr-3" id="sound-setting-micro-devices" no-caret text="Select Device">
                  <template #button-content>
                    <span class="d-flex align-items-center p-0">
                      <span>Sales Pipeline</span>
                      <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                    </span>
                  </template>
                  <b-dropdown-item >
                    <span> All </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                    <span> Sales Pipeline </span>
                  </b-dropdown-item>
                </b-dropdown>
                <b-dropdown class="crm-dd-container" id="sound-setting-micro-devices" no-caret text="Select Device">
                  <template #button-content>
                    <span class="d-flex align-items-center p-0">
                      <span>This week</span>
                      <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                    </span>
                  </template>
                  <b-dropdown-item >
                    <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                    <span> This week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> This Month </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Month </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div class="crm-graph-container">
              put graph here
            </div>
          </div>
          <div class="crm-card">
            <div class="crm-card-heading">
              <div>
                Won deals by day
                <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
              </div>
              <div class="d-flex align-items-center">
                <b-dropdown class="crm-dd-container mr-3" id="sound-setting-micro-devices" no-caret text="Select Device">
                  <template #button-content>
                    <span class="d-flex align-items-center p-0">
                      <span>Sales Pipeline</span>
                      <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                    </span>
                  </template>
                  <b-dropdown-item >
                    <span> All </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                    <span> Sales Pipeline </span>
                  </b-dropdown-item>
                </b-dropdown>
                <b-dropdown class="crm-dd-container" id="sound-setting-micro-devices" no-caret text="Select Device">
                  <template #button-content>
                    <span class="d-flex align-items-center p-0">
                      <span>This week</span>
                      <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                    </span>
                  </template>
                  <b-dropdown-item >
                    <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                    <span> This week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> This Month </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Month </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div class="crm-graph-container">
              put graph here
            </div>
          </div>
          <div class="crm-card">
            <div class="crm-card-heading">
              <div>
                Won deals by month
                <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
              </div>
              <div class="d-flex align-items-center">
                <b-dropdown class="crm-dd-container mr-3" id="sound-setting-micro-devices" no-caret text="Select Device">
                  <template #button-content>
                    <span class="d-flex align-items-center p-0">
                      <span>Sales Pipeline</span>
                      <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                    </span>
                  </template>
                  <b-dropdown-item >
                    <span> All </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                    <span> Sales Pipeline </span>
                  </b-dropdown-item>
                </b-dropdown>
                <b-dropdown class="crm-dd-container" id="sound-setting-micro-devices" no-caret text="Select Device">
                  <template #button-content>
                    <span class="d-flex align-items-center p-0">
                      <span>This week</span>
                      <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                    </span>
                  </template>
                  <b-dropdown-item >
                    <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                    <span> This week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> This Month </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Month </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div class="crm-graph-container">
              put graph here
            </div>
          </div>
          <div class="crm-card withSeprateHeader">
            <div class="crm-card-heading">
              <div class="d-flex w-100 justify-content-between">
                <div>
                  Recently created deals
                  <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
                </div>
              </div>
              <div class="w-100 d-flex justify-content-between align-items-center mt-38px">
                <b-dropdown class="crm-dd-container" no-caret>
                  <template #button-content>
                    <span class="d-flex align-items-center p-0">
                      <span>This week</span>
                      <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                    </span>
                  </template>
                  <b-dropdown-item >
                    <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                    <span> This week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> This Month </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Month </span>
                  </b-dropdown-item>
                </b-dropdown>
                <div class="crm-input-container mb-0">
                  <vb-icon icon="crm-inputSearch-icon" />
                  <input placeholder="Search" class="w-100"/>
                </div>
              </div>
            </div>
            <div  class="crm-card-body">
              <vb-table :isListEmpty="isEmpty(tableData)" :listLength="tableData.length" textNoRecord=" ">
                <template slot="header">
                  <tr>
                    <th class="dialer-has-sort">
                      <span class="dialer-sorted-by ascending">Deal name</span>
                    </th>
                    <th class="dialer-has-sort">
                      <span>stage</span>
                    </th>
                    <th class="dialer-col-right cursor_pointer">
                      <span>CREATED AT </span>
                    </th>
                  </tr>
                </template>
                <template #body="{ start, end }">
                  <tr v-for="(data, index) in tableData" :key="data.id" v-show="index >= start && index <= end">
                    <td class="dialer-row-title">
                      <span>{{ data.m1 }}</span>
                    </td>
                    <td class="dialer-row-title">{{ data.m1 }}</td>
                    <td class="dialer-col-right">
                      {{data.m2}}
                    </td>
                  </tr>
                </template>
              </vb-table>
            </div>
          </div>
          <div class="crm-card withSeprateHeader">
            <div class="crm-card-heading">
              <div class="d-flex align-items-center w-100 justify-content-between">
                <div>
                  Recently modified deals
                  <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
                </div>
              </div>
              <div class="w-100 d-flex justify-content-between align-items-center mt-38px">
                <b-dropdown class="crm-dd-container" no-caret>
                  <template #button-content>
                    <span class="d-flex align-items-center p-0">
                      <span>This week</span>
                      <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                    </span>
                  </template>
                  <b-dropdown-item >
                    <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                    <span> This week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> This Month </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Month </span>
                  </b-dropdown-item>
                </b-dropdown>
                <div class="crm-input-container mb-0">
                  <vb-icon icon="crm-inputSearch-icon" />
                  <input placeholder="Search" class="w-100"/>
                </div>
              </div>
            </div>
            <vb-table :isListEmpty="isEmpty(tableData)" :listLength="tableData.length" textNoRecord=" ">
              <template slot="header">
                <tr>
                  <th class="dialer-has-sort">
                    <span class="dialer-sorted-by ascending">Deal name</span>
                  </th>
                  <th class="dialer-has-sort">
                    <span>stage</span>
                  </th>
                  <th class="dialer-col-right cursor_pointer">
                    <span>CREATED AT </span>
                  </th>
                </tr>
              </template>
              <template #body="{ start, end }">
                <tr v-for="(data, index) in tableData" :key="data.id" v-show="index >= start && index <= end">
                  <td class="dialer-row-title">
                    <span>{{ data.m1 }}</span>
                  </td>
                  <td class="dialer-row-title">{{ data.m1 }}</td>
                  <td class="dialer-col-right">
                    {{data.m2}}
                  </td>
                </tr>
              </template>
            </vb-table>
          </div>
          <div class="crm-card withSeprateHeader">
            <div class="crm-card-heading">
              <div class="d-flex align-items-center w-100 justify-content-between">
                <div>
                  Created deals by sales agent
                  <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
                </div>
                <b-dropdown class="crm-dd-container" id="sound-setting-micro-devices" no-caret text="Select Device">
                  <template #button-content>
                    <span class="d-flex align-items-center p-0">
                      <span>This week</span>
                      <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                    </span>
                  </template>
                  <b-dropdown-item >
                    <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                    <span> This week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> This Month </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Month </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div class="w-100 d-flex justify-content-between align-items-center mt-38px">
                <b-dropdown class="crm-dd-container" no-caret>
                  <template #button-content>
                    <span class="d-flex align-items-center p-0">
                      <span>This week</span>
                      <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                    </span>
                  </template>
                  <b-dropdown-item >
                    <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                    <span> This week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> This Month </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Month </span>
                  </b-dropdown-item>
                </b-dropdown>
                <div class="crm-input-container mb-0">
                  <vb-icon icon="crm-inputSearch-icon" />
                  <input placeholder="Search" class="w-100"/>
                </div>
              </div>
            </div>
            <div  class="crm-card-body">
              <vb-table :isListEmpty="isEmpty(tableData)" :listLength="tableData.length" textNoRecord=" ">
                <template slot="header">
                  <tr>
                    <th class="dialer-has-sort">
                      <span class="dialer-sorted-by ascending">SALES AGENT</span>
                    </th>
                    <th class="dialer-has-sort">
                      <span>TOTAL CREATED</span>
                    </th>
                    <th class="dialer-has-sort">
                      <span>FORECAST AMOUNT</span>
                    </th>
                    <th class="dialer-col-right cursor_pointer">
                      <span>CLOSED AMOUNT</span>
                    </th>
                  </tr>
                </template>
                <template #body="{ start, end }">
                  <tr v-for="(data, index) in tableData" :key="data.id" v-show="index >= start && index <= end">
                    <td class="dialer-row-title">
                      <span>{{ data.m1 }}</span>
                    </td>
                    <td class="dialer-row-title">{{ data.m1 }}</td>
                    <td class="dialer-row-title">{{ data.m1 }}</td>
                    <td class="dialer-col-right">
                      {{data.m2}}
                    </td>
                  </tr>
                </template>
              </vb-table>
            </div>
          </div>
          <div class="crm-card withSeprateHeader">
            <div class="crm-card-heading">
              <div class="d-flex align-items-center justify-content-between w-100">
                <div>
                  Assigned deals by sales agent
                  <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
                </div>
                <b-dropdown class="crm-dd-container" id="sound-setting-micro-devices" no-caret text="Select Device">
                  <template #button-content>
                    <span class="d-flex align-items-center p-0">
                      <span>This week</span>
                      <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                    </span>
                  </template>
                  <b-dropdown-item >
                    <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                    <span> This week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> This Month </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Month </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div class="w-100 d-flex justify-content-between align-items-center mt-38px">
                <b-dropdown class="crm-dd-container" no-caret>
                  <template #button-content>
                    <span class="d-flex align-items-center p-0">
                      <span>This week</span>
                      <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                    </span>
                  </template>
                  <b-dropdown-item >
                    <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                    <span> This week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> This Month </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Month </span>
                  </b-dropdown-item>
                </b-dropdown>
                <div class="crm-input-container mb-0">
                  <vb-icon icon="crm-inputSearch-icon" />
                  <input placeholder="Search" class="w-100"/>
                </div>
              </div>
            </div>
            <div  class="crm-card-body">
              <vb-table :isListEmpty="isEmpty(tableData)" :listLength="tableData.length" textNoRecord=" ">
                <template slot="header">
                  <tr>
                    <th class="dialer-has-sort">
                      <span class="dialer-sorted-by ascending">SALES AGENT</span>
                    </th>
                    <th class="dialer-has-sort">
                      <span>TOTAL ASSIGNED</span>
                    </th>
                    <th class="dialer-has-sort">
                      <span>FORECAST AMOUNT</span>
                    </th>
                    <th class="dialer-col-right cursor_pointer">
                      <span>CLOSED AMOUNT</span>
                    </th>
                  </tr>
                </template>
                <template #body="{ start, end }">
                  <tr v-for="(data, index) in tableData" :key="data.id" v-show="index >= start && index <= end">
                    <td class="dialer-row-title">
                      <span>{{ data.m1 }}</span>
                    </td>
                    <td class="dialer-row-title">{{ data.m1 }}</td>
                    <td class="dialer-row-title">{{ data.m1 }}</td>
                    <td class="dialer-col-right">
                      {{data.m2}}
                    </td>
                  </tr>
                </template>
              </vb-table>
            </div>
          </div>
          <div class="crm-card withSeprateHeader">
            <div class="crm-card-heading">
              <div class="d-flex align-items-center w-100 justify-content-between">
                <div>
                  Product performance
                  <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
                </div>
              </div>
              <div class="w-100 d-flex justify-content-between align-items-center mt-38px">
                <b-dropdown class="crm-dd-container" no-caret>
                  <template #button-content>
                    <span class="d-flex align-items-center p-0">
                      <span>This week</span>
                      <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                    </span>
                  </template>
                  <b-dropdown-item >
                    <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                    <span> This week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> This Month </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Month </span>
                  </b-dropdown-item>
                </b-dropdown>
                <div class="crm-input-container mb-0">
                  <vb-icon icon="crm-inputSearch-icon" />
                  <input placeholder="Search" class="w-100"/>
                </div>
              </div>
            </div>
            <div  class="crm-card-body">
              <vb-table :isListEmpty="isEmpty(tableData)" :listLength="tableData.length" textNoRecord=" ">
                <template slot="header">
                  <tr>
                    <th class="dialer-has-sort">
                      <span class="dialer-sorted-by ascending">Name</span>
                    </th>
                    <th class="dialer-has-sort">
                      <span>Interested in Product</span>
                    </th>
                    <th class="dialer-has-sort">
                      <span>Total Sold</span>
                    </th>
                    <th class="dialer-col-right cursor_pointer">
                      <span>Sold Amount (Tax Exl..)</span>
                    </th>
                  </tr>
                </template>
                <template #body="{ start, end }">
                  <tr v-for="(data, index) in tableData" :key="data.id" v-show="index >= start && index <= end">
                    <td class="dialer-row-title">
                      <span>{{ data.m1 }}</span>
                    </td>
                    <td class="dialer-row-title">{{ data.m1 }}</td>
                    <td class="dialer-row-title">{{ data.m1 }}</td>
                    <td class="dialer-col-right">
                      {{data.m2}}
                    </td>
                  </tr>
                </template>
              </vb-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Insights',
  components: {
    
  },
  inject:['isEmpty'],
  data(){
    return {
      tableData : {
        0: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        1: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        2: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        3: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        4: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        5: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        6: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        7: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        8: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        9: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        10: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
      }
    }
  },
  computed: {
    
  },
  methods: {
    
  },
  mounted(){
    
  },
}
</script>

<style>

</style>