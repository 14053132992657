<template>
  <tr class="dialer-row-select">
    <td @click="$emit('clicked')" class="dialer-row-title cursor_pointer one">
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <vb-icon icon="moh-table-tdIcon" class="mr-16px" width="35px" height="35px" />
          <span v-b-tooltip.hover :title="`created at ${playlist.date_created}`">{{ playlist.label }}</span>
          <span class="newThisDeviceIndicaterTextBox-25-11-2023" v-if="playlist.default==1" id="popover-target-1">Default</span>
          <span v-if="playlist.type==types.custom.key" class="newBlueColorTagLike">Custom</span>
        </div>
        <span class="newThisDeviceIndicaterTextBox-25-11-2023 h-fit-content ml-auto mr-16px showOnly-OnHover-insideTable" > 
          Configure
        </span>
      </div>
    </td>
    <td @click.self="$emit('clicked')" class="two">
      <div class="w-auto min-width-370px">
        <div class="d-flex justify-content-between align-items-center">
          <template v-if="length>0">
            <div class="d-flex align-items-center">
              <b-button :class="`py-0 px-0 ${length>1? '' : 'invisible'} MOHItem-item-arrow-button`" variant="link" :disabled="selected.audio==0" @click.stop="selected.audio=selected.audio-1">
                <!-- v-if="length>1" -->
                <b-icon icon="chevron-left" />
              </b-button>
              <span class="mx-1">{{selected.audio+1}} / {{ length }}</span>
              <b-button :class="`py-0 px-0 ${length>1? '' : 'invisible'} MOHItem-item-arrow-button`" variant="link" :disabled="selected.audio+1==length" @click.stop="selected.audio=selected.audio+1">
                <!-- v-if="length>1" -->
                <b-icon icon="chevron-right" variant="dark" />
              </b-button>
            </div>
            <div class="">
              <vb-audio-player class="whiteBGaudioWithGreyRoundedBorder-again min-w-250px" :src="selectedAudio" />
            </div>
            <slot name="right-end" />
          </template>
          <template v-else>
            Add sound files
          </template>
        </div>
        <!-- <slot name="right-end" /> -->
      </div>
    </td>
  </tr>
</template>

<script>
const types = {
  library: {
    key: 'system',
    text: 'Library',
  },
  custom: {
    key: 'custom',
    text: 'Custom',
  },
}
export default {
  name: 'MOHItem',
  props: {
    playlist: {
      type: Object,
      default: ()=>({}),
      require: true,
    },
  },
  data() {
    return {
      selected: {
        audio: 0,
      },
    }
  },
  computed: {
    types(){ return types },
    length() { return this.playlist?.playlist?.length ?? 0 },
    selectedAudio() { return this.playlist?.playlist[this.selected.audio]?.sound_file?.sound ?? '' },
  },
}
</script>

<style>

</style>