var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "newCentralNodeForGraph-container"
  }, [_c('div', {
    class: `${_vm.position === 'left' ? 'tdmfDivWrap' : 'tdmfDivHidden'} leftSide-graph`
  }, [_vm._t("left")], 2), _c('div', {
    staticClass: "centerSide-graph"
  }, [_c('div', {
    staticClass: "innerCenter"
  }, [_vm._m(0), _c('div', {
    staticClass: "horizontalLine"
  }, [_c('div', {
    staticClass: "leftHorizontal-line notShow withText"
  }, [_vm.text ? _c('p', [_vm._v(_vm._s(_vm.text) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "rightHorizontal-line withText"
  }, [_vm.text ? _c('p', [_vm._v(_vm._s(_vm.text) + " ")]) : _vm._e()])])])]), _c('div', {
    class: `rightSide-graph ${_vm.position === 'right' ? 'tdmfDivWrap' : 'tdmfDivHidden'}`
  }, [_c('div', {
    staticClass: "node nodeBasic minWidth-230 fixHeight"
  }, [_vm._t("right")], 2), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showMore,
      expression: "showMore"
    }],
    staticClass: "horizontalLineBeforeMoreInfoNode"
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showMore,
      expression: "showMore"
    }],
    staticClass: "node nodeBasic minWidth-230 moreInfoNode"
  }, [_vm.showMore ? _c('MoreInfoNode', {
    attrs: {
      "voipaccount": _vm.voipaccount
    },
    on: {
      "interface": function ($event) {
        return _vm.$emit('change-graph', $event);
      }
    }
  }) : _vm._e()], 1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "verticalLine"
  }, [_c('div', {
    staticClass: "topVertical-line"
  }), _c('div', {
    staticClass: "bottomVertical-line"
  })]);

}]

export { render, staticRenderFns }