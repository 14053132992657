<template>
  <div class="textNodeItself">
    <span>{{ position=='left' ? text : '' }}</span>
    <span></span>
    <span>{{ position=='right' ? text : '' }}</span>
  </div>
</template>

<script>
export default {
  name: 'TextNode',
  props: {
    text: {
      type: String,
      default: '',
    },
    position: {
      type: String,
      default: 'left',
    },
  },
}
</script>

<style>

</style>