var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-800 EditUserModal bigSquareCheckbox",
    attrs: {
      "id": `${_vm._uid}-main-div`,
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "opened": function ($event) {
        return _vm.onOpened();
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_vm.api.fetch_user.send || _vm.api.delete_user.send ? [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "d-flex flex-fill"
  }, [_c('div', {
    staticClass: "d-flex flex-column w-50"
  }, [_c('div', {
    staticClass: "latestShimmerDesign mb-2",
    staticStyle: {
      "height": "21px",
      "width": "105px"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "50px",
      "width": "100%"
    }
  })]), _c('div', {
    staticClass: "d-flex flex-column w-50 pl-4"
  }, [_c('div', {
    staticClass: "latestShimmerDesign mb-2",
    staticStyle: {
      "height": "21px",
      "width": "75px"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "50px",
      "width": "90px"
    }
  })])]), _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "135px",
      "width": "135px",
      "border-radius": "50%"
    }
  })]), _vm._l(2, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
    }, _vm._l(3, function (n) {
      return _c('div', {
        key: n.id,
        staticClass: "whiteBoxWithBorderInsideGreyBox-row"
      }, [_c('div', {
        staticClass: "d-flex flex-column w-50"
      }, [_c('div', {
        staticClass: "latestShimmerDesign mb-2",
        staticStyle: {
          "height": "21px",
          "width": "105px"
        }
      }), _c('div', {
        staticClass: "latestShimmerDesign",
        staticStyle: {
          "height": "50px",
          "width": "100%"
        }
      })]), _c('div', {
        staticClass: "latestShimmerDesign",
        staticStyle: {
          "height": "50px",
          "width": "100px"
        }
      })]);
    }), 0);
  })], 2)] : [_c('div', {
    staticClass: "dialer-edit-header",
    attrs: {
      "id": "scrollToTop"
    }
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Edit user")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)], 1)]), _c('div', {
    staticClass: "dialer-form latestGreyBox-9-9-2023 md-mar-bottom mt-24px",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        "";
      }
    }
  }, [_vm.data.accountcode ? _c('UserGeneralInfoUpdateWidget', {
    staticClass: "p-0",
    attrs: {
      "accountcode": _vm.data.accountcode
    }
  }) : _vm._e(), _c('div', {
    staticClass: "mt-24px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Telephone numbers")]), _c('div', {
    staticClass: "d-flex mt-12px IVR-numbers-container flex-wrap"
  }, [_vm.dids.length > 0 ? _vm._l(_vm.dids, function (did, key) {
    return _c('div', {
      key: key,
      staticClass: "newIVRLayout-number-item"
    }, [_c('img', {
      staticClass: "country-img",
      attrs: {
        "width": "45px",
        "src": _vm._f("flag_icon")(_vm._f("number_formater")(did))
      }
    }), _c('span', {
      staticClass: "newIVRLayout-number-item-text"
    }, [_vm._v(_vm._s(_vm._f("number_formater")(did)))])]);
  }) : _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("No numbers assigned")])], 2)]), _c('div', {
    staticClass: "lg-mar-left"
  }, [_c('button', {
    staticClass: "newButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-AssignNumberModal`, {
          accountcode: _vm.data.accountcode
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Assign")])], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Outgoing calls only")]), _c('ul', {
    staticClass: "list-unstyled dialer-number-list mb-0"
  }, [_c('li', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(_vm._s(_vm.callerids.length == 0 ? 'No Caller id Shared' : `${_vm.callerids.length} Numbers`))])])]), _c('div', {
    staticClass: "lg-mar-left"
  }, [_c('button', {
    staticClass: "newButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-AssignCallerIds`, {
          accountcode: _vm.data.accountcode
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Share")])], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Teams")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(_vm._s(_vm._f("memberCount")(_vm.response.user.account_teams)))])]), _c('div', {
    staticClass: "lg-mar-left"
  }, [_c('a', {
    staticClass: "newButton sm-mar-left",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-AssignTeams`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Assign")])], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Enable unlimited calls")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Switch to unlimited calls within your plan, this may have an effect on your subscription.")])]), _c('b-checkbox', {
    ref: "unlimited-toggle",
    staticClass: "newerSwitch",
    attrs: {
      "switch": "",
      "checked": _vm.forms.update_unlimited.unlimited,
      "disabled": _vm.api.fetch_account_detail.send || _vm.api.change_prefixes.send
    },
    on: {
      "change": function ($event) {
        return _vm.changePrefix();
      }
    }
  })], 1), !_vm.isDefaultUser ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Set as admin")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("This user is assigned the " + _vm._s(_vm.forms.update_role.admin ? 'admin' : 'user') + " role")])]), _c('b-form-checkbox', {
    staticClass: "newerSwitch",
    attrs: {
      "disabled": _vm.api.update_role.send,
      "checked": _vm.forms.update_role.admin,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.updateUserRole();
      }
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Extension limits")])]), _c('div', {
    staticClass: "lg-mar-left"
  }, [_c('a', {
    staticClass: "newButton sm-mar-left",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`userLimitsModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-show-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("View")])], 1)])])])])], 1), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-24px p-0"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox p-0 b-0"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row somethingInside p-0"
  }, [_c('CallCopyC', {
    staticClass: "input-consistent-in-grey-component w-100",
    attrs: {
      "accountcode": _vm.data.accountcode,
      "user": _vm.response.user,
      "EditUserModal": true
    },
    on: {
      "updated": function ($event) {
        return _vm.$emit('ofh-updated');
      },
      "ofh-days-updated": function ($event) {
        return _vm.$emit('ofh-days-updated');
      },
      "ofh-toggle-updated": function ($event) {
        return _vm.$emit('ofh-toggle-updated');
      },
      "ofh-default-updated": function ($event) {
        return _vm.$emit('ofh-default-updated');
      },
      "cf-updated": function ($event) {
        return _vm.$emit('cf-updated');
      }
    }
  })], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-24px p-0"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox p-0 b-0"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row somethingInside p-0"
  }, [_c('DevicesLatest', {
    staticClass: "w-100",
    attrs: {
      "accountcode": _vm.data.accountcode
    },
    on: {
      "updated": function ($event) {
        return _vm.$emit('extension-updated');
      }
    }
  })], 1)])]), !_vm.isDefaultUser ? _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-24px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [!_vm.isDefaultUser && _vm.response.user.role == '0' ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Block login")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" If enabled, user will not be able to login to this account.")])]), _c('b-form-checkbox', {
    ref: "block-switch",
    staticClass: "newerSwitch",
    attrs: {
      "disabled": _vm.api.update_block.send,
      "checked": _vm.forms.update_block.block,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.updateBlock();
      }
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Delete this user?")]), _vm.voipuser && _vm.voipuser.action_required == 'yes' ? _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" This user will be deleted on "), _c('b', [_vm._v(_vm._s(_vm.voipuser.actionable_date))])]) : _vm._e()]), _vm.voipuser && _vm.voipuser.action_required == 'yes' ? _c('div') : _c('button', {
    staticClass: "newDeleteButton",
    attrs: {
      "disabled": _vm.api.delete_user.send
    },
    on: {
      "click": function ($event) {
        return _vm.beforeDelete();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-delete-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDeleteButton-textPart d-flex align-items-center justify-content-center position-relative"
  }, [_vm.api.delete_user.send || _vm.api.fetch_user_number_list.send ? _c('vb-spinner') : [_vm._v(" Delete ")]], 2)], 1)])])]) : _vm._e(), _vm.conditions.show_scroll_top ? _c('div', {
    staticClass: "scrollToTopIconContainer",
    on: {
      "click": function ($event) {
        return _vm.scroollToTop();
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "chevron-up",
      "font-scale": "1.3"
    }
  })], 1) : _vm._e(), _vm.conditions.show_scroll_top ? _c('div', {
    staticClass: "scrollToTopIconContainer TopCrossIcon",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "x",
      "font-scale": "1.3"
    }
  })], 1) : _vm._e()], _c('AssignCallerIdsModal', {
    attrs: {
      "modalName": `${_vm._uid}-AssignCallerIds`
    },
    on: {
      "updated": function ($event) {
        return _vm.getVoipUsers();
      }
    }
  }), _c('AssignNumberModal', {
    attrs: {
      "modalName": `${_vm._uid}-AssignNumberModal`
    },
    on: {
      "added": function ($event) {
        _vm.response.user.account_telephone_numbers = $event.selected_list;
        _vm.getVoipUsers();
      }
    }
  }), _c('modal', {
    staticClass: "center-zoomIn-transition v2 m-w-500 RemoveNumberbeforeDeletingUserModal",
    attrs: {
      "name": `${_vm.modalName}BeforeDelete`
    }
  }, [_c('div', {
    staticClass: "w-100"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between w-100"
  }, [_c('h2', {
    staticClass: "dialer-edit-title newer"
  }, [_vm._v("Select a number to be removed")]), _c('button', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm.modalName}BeforeDelete`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v("To remove this account, you can either release an associated telephone number or choose to keep it under an independent subscription.")]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {}, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Number assigned to user to be deleted")]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-8px"
  }, [_vm.response.user_number_list.linked_numbers && _vm.response.user_number_list.linked_numbers.length == 0 ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row py-3 min-height-unset"
  }, [_c('div', {
    staticClass: "RemoveNumberbeforeDeletingUserModal-emptyScreenContainer"
  }, [_c('img', {
    attrs: {
      "width": "20%",
      "height": "auto",
      "src": require('@/assets/images/emptyScreenImages/RemoveNumberbeforeDeletingUserModal-noNumber.png')
    }
  }), _c('div', {
    staticClass: "RemoveNumberbeforeDeletingUserModal-emptyScreenContainer-text"
  }, [_vm._v(" There are no telephone numbers currently assigned to this user. Please select a number from the unassigned list below to either remove or keep keep under a new subscription. ")])])]) : _vm._e(), _vm._l(_vm.response.user_number_list.linked_numbers, function (number) {
    return _c('div', {
      key: number.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row py-3 min-height-unset"
    }, [_c('b-form-checkbox', {
      staticClass: "dialer_full_width_checkbox circle_btn_checkbox circle_btn_onRight p-0 makeCheckBoxesSquare less-padding leastPadding",
      attrs: {
        "checked": _vm.selected.number && _vm.selected.number.id == number.id
      },
      on: {
        "change": function ($event) {
          _vm.selected.number = _vm.selected.number && _vm.selected.number.id == number.id ? null : number;
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('img', {
      staticClass: "country-img",
      attrs: {
        "width": "45px",
        "src": _vm._f("flag_icon")(_vm._f("number_formater")(number.did))
      }
    }), _c('span', {
      staticClass: "RemoveNumberbeforeDeletingUserModal-did ml-16px"
    }, [_vm._v(_vm._s(_vm._f("number_formater")(number.did)))]), number.name ? _c('span', {
      staticClass: "RemoveNumberbeforeDeletingUserModal-did-label ml-8px"
    }, [_vm._v(_vm._s(`( ${number.name} )`))]) : _vm._e(), number.price ? _c('span', {
      staticClass: "RemoveNumberbeforeDeletingUserModal-did-label ml-8px"
    }, [_vm._v(_vm._s(`${number.price | _vm.to_fixed}`))]) : _vm._e()])])], 1);
  })], 2)]), _c('div', [_c('div', {
    staticClass: "latestGreyBox-heading-main mt-20px"
  }, [_vm._v("Other unassign number")]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-8px"
  }, [_vm.response.user_number_list.unassigned_numbers && _vm.response.user_number_list.unassigned_numbers.length == 0 ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row py-3 min-height-unset"
  }, [_c('div', {
    staticClass: "RemoveNumberbeforeDeletingUserModal-emptyScreenContainer"
  }, [_c('img', {
    attrs: {
      "width": "20%",
      "height": "auto",
      "src": require('@/assets/images/emptyScreenImages/RemoveNumberbeforeDeletingUserModal-noNumber.png')
    }
  }), _c('div', {
    staticClass: "RemoveNumberbeforeDeletingUserModal-emptyScreenContainer-text"
  }, [_vm._v(" There are no unassigned telephone numbers available at this time. Please select a number from the assigned list above to either remove or keep under a new subscription. ")])])]) : _vm._e(), _vm._l(_vm.response.user_number_list.unassigned_numbers, function (number) {
    return _c('div', {
      key: number.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row py-3 min-height-unset"
    }, [_c('b-form-checkbox', {
      staticClass: "dialer_full_width_checkbox circle_btn_checkbox circle_btn_onRight p-0 makeCheckBoxesSquare less-padding leastPadding",
      attrs: {
        "checked": _vm.selected.number && _vm.selected.number.id == number.id
      },
      on: {
        "change": function ($event) {
          _vm.selected.number = _vm.selected.number && _vm.selected.number.id == number.id ? null : number;
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('img', {
      staticClass: "country-img",
      attrs: {
        "width": "45px",
        "src": _vm._f("flag_icon")(_vm._f("number_formater")(number.did))
      }
    }), _c('span', {
      staticClass: "RemoveNumberbeforeDeletingUserModal-did ml-16px"
    }, [_vm._v(_vm._s(_vm._f("number_formater")(number.did)))]), number.name ? _c('span', {
      staticClass: "RemoveNumberbeforeDeletingUserModal-did-label ml-8px"
    }, [_vm._v(_vm._s(`( ${number.name} )`))]) : _vm._e(), number.price ? _c('span', {
      staticClass: "RemoveNumberbeforeDeletingUserModal-did-label ml-8px"
    }, [_vm._v(_vm._s(`${number.price | _vm.to_fixed}`))]) : _vm._e()])])], 1);
  })], 2)])]), _c('div', {
    staticClass: "d-flex mt-20px"
  }, [_c('div', {
    staticClass: "w-50 pr-2"
  }, [_c('button', {
    staticClass: "fullWidthBlueButton",
    attrs: {
      "disabled": !_vm.selected.number
    },
    on: {
      "click": function ($event) {
        return _vm.deleteUser(true);
      }
    }
  }, [_vm._v("Keep number and add subscription")])]), _c('div', {
    staticClass: "w-50 pl-2"
  }, [_c('button', {
    staticClass: "fullWidthRedButton",
    attrs: {
      "disabled": !_vm.selected.number
    },
    on: {
      "click": function ($event) {
        return _vm.deleteUser();
      }
    }
  }, [_vm._v("Remove selected numbers")])])])])]), _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-800 EditUserModal bigSquareCheckbox",
    attrs: {
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-AssignTeams`
    },
    on: {
      "before-open": function ($event) {
        return _vm.fetchTeams();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-assign-to md-pad-bottom dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header d-block"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_c('vb-svg', {
    staticClass: "cursor_pointer_no_hover",
    attrs: {
      "disabled": _vm.api.assign_teams.send,
      "name": "leftLongArrow-icon",
      "width": "20",
      "height": "12",
      "viewBox": "0 0 30 16",
      "stroke-width": "1",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-AssignTeams`);
      }
    }
  }), _vm._v(" Teams ")], 1), _c('div', {
    staticClass: "dialer-edit-actions d-flex"
  }, [_c('button', {
    staticClass: "newDoneButton ml-16px",
    attrs: {
      "disabled": _vm.api.assign_teams.send
    },
    on: {
      "click": function ($event) {
        return _vm.assignTeams();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v("View or select the team(s) associated with this user.")])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_vm.api.fetch_teams.send ? _vm._l(3, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row"
    }, [_c('div', {
      staticClass: "d-flex align-items-center w-50"
    }, [_c('div', {
      staticClass: "latestShimmerDesign mr-2",
      staticStyle: {
        "height": "35px",
        "width": "35px"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "21px",
        "width": "40%"
      }
    })]), _c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "24px",
        "width": "24px"
      }
    })]);
  }) : _vm._l(_vm.teams, function (team, key) {
    return _c('div', {
      key: team.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset"
    }, [_c('b-form-checkbox', {
      staticClass: "dialer_full_width_checkbox circle_btn_checkbox circle_btn_onRight p-0 makeCheckBoxesSquare less-padding leastPadding",
      attrs: {
        "id": key,
        "disabled": _vm.api.assign_teams.send,
        "value": team.id,
        "name": "checkbox-1"
      },
      model: {
        value: _vm.selected.teams,
        callback: function ($$v) {
          _vm.$set(_vm.selected, "teams", $$v);
        },
        expression: "selected.teams"
      }
    }, [_c('Info', {
      attrs: {
        "id": team.extn,
        "is_blf": false
      }
    })], 1)], 1);
  })], 2)])])]), _c('modal', {
    staticClass: "userLimitsModal",
    attrs: {
      "name": "userLimitsModal"
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header pt-0"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Extension limits")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('userLimitsModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('UserExtensionLimitDetail', {
    staticClass: "extentionDetailsModal",
    attrs: {
      "voipaccount": _vm.data.accountcode
    }
  })], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }