<template>
  <div v-if="info" class="">
    <template v-if="info.type=='number'">
      <div class="mb-2" v-for="(member,index) in filterMembers" :key="index">
        <Info :id="member |  getExtnByNumberHTML" :is_blf="false" />
      </div>
    </template>
    <template v-else>
      <div class="mb-2" v-for="(member,index) in filterMembers" :key="index">
        <Info :id="member.key" :name="member.name" :is_blf="false" />
      </div>
      <div class="d-flex justify-content-end w-100 mt-4">
        <div v-if="members.length>4 && moreText" style="color:#5576d1;font-weight:600;font-size:16px;">
          {{members.length - 4}} more members
        </div>
        <vb-icon v-if="graph" icon="graphIconInsideGraph-icon" height="32px" width="32px" class="cursor_pointer graphIconInsideGraph-icon" @click="showGraph()"/>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
import Info from '@/components/Lists/Info.vue'
export default {
  name: 'MoreInfoNode',
  components: { 
    Info,
  },
  props:{
    voipaccount: {
      type: [String,Number]
    },
    graph: {
      type: Boolean,
      default: true,
    },
    moreText: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      'getVoipUsersAlises',
      'getVoipTeams',
      'getVoipCallQueues',
      'getVoipIVRSAlises',
      'getVoipNumbers',
    ]),
    info() {
      const key = this.voipaccount ?? ''
      const user = this.getVoipUsersAlises[key];
      const team = this.getVoipTeams.find(team=>key==team?.ringroup?.accountcode || key==team?.real_id || key==team?.ringroup?.extn)
      const call_queue = this.getVoipCallQueues.find(call_queue=>key==call_queue?.voipaccount || key==call_queue?.extn)
      const ivr = this.getVoipIVRSAlises[key]
      const number = this.getVoipNumbers.find(i=>key==i.real_id)
      if(team) return { data: team, type: 'team' }
      else if(call_queue) return { data: call_queue, type: 'call_queue' }
      else if(ivr) return { data: ivr, type: 'ivr' }
      else if(user) return { data: user, type: 'user' }
      else if(number) return { data: number, type: 'number' }
      else return null;
    },
    members(){
      if(this.info?.type=='team') return this.info.data?.members?.map?.(i=>{return {key:i.accountcode}}) ?? []
      else if(this.info?.type=='call_queue') return this.info.data?.members?.map?.(i=>{return {key:i.membername}}) ?? []
      else if(this.info?.type=='ivr') return this.info.data?.schedules?.[0]?.options?.map?.(i=>{return {key:i.ivr_cmd_args,name:i.accountname}}) ?? []
      else if(this.info?.type=='number') return this.info.data?.extensions ?? []
      else return []
    },
    filterMembers(){
      return _.take(this.members,4)
    }
  },
  filters: {
    getExtnByNumberHTML(value){ return value?.replace?.(/[^0-9]/g,'')?.substr?.(0,4) ?? value },
  },
  methods: {
    showGraph(){
      // console.log('run')
      this.$emit('interface',{
        from: 'more_info',
        event: 'show_graph',
        payload: {
          voipaccount: this.voipaccount,
        },
      })
    },
  },
}
</script>

<style>

</style>