var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-500 addExtension-voicemailModal addRemove_userModal bigSquareCheckbox CallQueueAddRemoveUsersModal min-width-50percent-modal fixScrollIssue",
    attrs: {
      "name": _vm.modalName,
      "height": "auto",
      "clickToClose": false
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "closed": function ($event) {
        return _vm.onClosed();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit addUser"
  }, [_c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-svg', {
    staticClass: "cursor_pointer",
    attrs: {
      "name": "leftLongArrow-icon",
      "width": "20",
      "height": "12",
      "viewBox": "0 0 30 16",
      "stroke-width": "1",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }), _c('span', {
    staticClass: "dialer-edit-title md-mar-left"
  }, [_vm._v(_vm._s(_vm._f("textFilter")(_vm.data.type, 'title', {
    extra_data: _vm.data.extra_data
  })))])], 1), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.update();
      }
    }
  }) : _c('a', [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "button",
      "disabled": _vm.api.update.send
    },
    on: {
      "click": function ($event) {
        return _vm.update();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])], 1)]), _vm.data.type == _vm.modalLists.UserCallerIdShared ? _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v("Select users who are authorised to make outbound calls using this number.")]) : _vm._e(), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-50"
  }, [_c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": _vm._f("textFilter")(_vm.data.type, 'place-holder'),
      "disabled": _vm.api.update.send || _vm.api.list.send
    },
    model: {
      value: _vm.filter.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "search", $$v);
      },
      expression: "filter.search"
    }
  })], 1)]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset"
  }, [_c('b-form-checkbox', {
    staticClass: "bg-transparent w-100",
    class: {
      'switchInsideCheckBox-container': _vm.data.type == _vm.modalLists.CallQueueMembers
    },
    attrs: {
      "checked": _vm.isAllCheck
    },
    on: {
      "change": function ($event) {
        return _vm.onSelectAll();
      }
    }
  }, [_vm.data.type == _vm.modalLists.CallQueueMembers ? [_c('div', [_vm._v("Select all")]), _c('div', [_vm._v("Force login")])] : [_c('span', [_vm._v("Select all")])]], 2)], 1), _vm._l(_vm.searchedList, function (item) {
    return _c('div', {
      key: item.account,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset"
    }, [_c('b-form-checkbox', {
      staticClass: "w-100 bg-transparent",
      attrs: {
        "disabled": _vm.api.update.send,
        "checked": !!_vm.list.selected[item.account]
      },
      on: {
        "change": function ($event) {
          return _vm.onChange(item);
        }
      }
    }, [_c('div', {
      staticClass: "d-flex justify-content-between align-item-center"
    }, [_c('Info', {
      attrs: {
        "id": item.account,
        "is_blf": false
      }
    }), !_vm.list.selected[item.account] && _vm.list.added[item.account] ? _c('span', {
      staticClass: "mt-1"
    }, [_vm._v("Removed")]) : _vm._e(), _vm.list.selected[item.account] && _vm.list.not_added[item.account] ? _c('span', {
      staticClass: "mt-1"
    }, [_vm._v("Added")]) : _vm._e(), _vm.data.type == _vm.modalLists.CallQueueMembers ? [_vm.list.added[item.account] ? _c('div', {
      staticClass: "forceSwitchContainer"
    }, [_c('label', {
      staticClass: "switch"
    }, [_c('input', {
      attrs: {
        "type": "checkbox",
        "disabled": _vm.api.update_member_force_admin.send == item.account
      },
      domProps: {
        "checked": item.force == 'yes'
      },
      on: {
        "change": function ($event) {
          return _vm.updateForceAdmin(item, item.account);
        }
      }
    }), _c('span', {
      staticClass: "slider round"
    })])]) : _vm._e()] : _vm._e()], 2)])], 1);
  }), _vm.isEmpty(_vm.searchedList) && !_vm.api.list.send ? _c('vb-no-record', {
    staticClass: "my-4",
    attrs: {
      "text": _vm._f("textFilter")(_vm.data.type, 'no-record'),
      "design": 3
    }
  }) : _vm._e(), _vm.isEmpty(_vm.searchedList) && _vm.api.list.send ? _vm._l(3, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4 justify-content-start"
    }, [_c('div', {
      staticClass: "latestShimmerDesign mx-3",
      staticStyle: {
        "height": "24px",
        "width": "24px"
      }
    }), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "latestShimmerDesign mr-2",
      staticStyle: {
        "height": "45px",
        "width": "45px"
      }
    }), _c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('div', {
      staticClass: "latestShimmerDesign mb-2",
      staticStyle: {
        "height": "21px",
        "width": "120px"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "20px",
        "width": "60px"
      }
    })])])]);
  }) : _vm._e()], 2)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }