<template>
  <div :class="`bill-tracks-container grid-view ${participantsLength > 50 ? 'exceded' : ''} ${participantsLength > 15 ? 'greaterThan15' : participantsLength > 30 ? 'greaterThan30' : ''}`" 
    :participants="participantsLength+1" 
    @mousedown="dragMouseDown($event, `popup-videos-${videoCall.room}`)"
  >
    <!-- participants-container -->
    <slot />
    <Participant
      v-for="(participant,index) in Object.values(participants)"
      :key="participant._id"
      :speaker="speaker_id==participant._id"
      :participant="participant"
      :JitsiConference="JitsiConference"
      :speaker_device_id="speaker_device_id"
      :performanceSetting="performanceSetting"
      :videoCall="videoCall"
      :class="`bill-track-${index+2}`"
    />
  </div>
</template>

<script>
import Participant from "./Participant.vue";
import { dragging } from '../../../mixin'
export default {
  name: "Participants",
  mixins: [dragging],
  components: {
    Participant,
  },
  props: {
    JitsiConference: {
      type: Object,
    },
    speaker_device_id: {
      type: String,
      default: ''
    },
    videoCall:{
      type: Object
    },
    participants: {
      type: Object,
    },
    speaker_id: {
      type: String,
    },
    performanceSetting: {
      type: Object,
      default: ()=>({})
    },
  },
  computed:{
    participantsLength(){ return Object.values(this.participants).length },
  }
};
</script>

<style lang="scss" >
// @use "sass:list";
// @use "sass:math";

// .participants-container {
//   display: flex;
//   height: inherit;
// }



$values: ( '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50' );
@each $value in $values {
  .bill-track-#{$value} {
    grid-area: area#{$value};
  }
}
.bill-tracks-container {
  // flex: 1;
  display: grid;
  grid-gap: 5px;
  background-color: #6B6969;
  height: 100%;
  width: 100%;
  padding: 5px;
  &.grid-view {
    &[participants="1"] {
      grid-template-areas: 
      'area1';
    }
    &[participants="2"] {
      grid-template-areas: 
      'area1 area2';
    }
    &[participants="3"] {
      grid-template-areas: 
      'area1 area2' 
      'area1 area3';
    }
    &[participants="4"] {
      grid-template-areas: 
      'area1 area2' 
      'area3 area4';
    }
    &[participants="5"] {
      grid-template-areas: 
      'area1 area2 area3' 
      'area1 area4 area5';
    }
    &[participants="6"] {
      grid-template-areas: 
      'area1 area2 area3' 
      'area4 area5 area6';
    }
    &[participants="7"] {
      grid-template-areas: 
      'area1 area2 area3 area4' 
      'area1 area5 area6 area7';
    }
    &[participants="8"] {
      grid-template-areas: 
      'area1 area2 area3 area4' 
      'area5 area6 area7 area8';
    }
    &[participants="9"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area1 area6 area7 area8 area9';
    }
    &[participants="10"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10';
    }
    &[participants="11"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area11 area11 area11 area11';
    }
    &[participants="12"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area11 area12 area12 area12';
    }
    &[participants="13"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area12 area13 area13';
    }
    &[participants="14"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area14';
    }
    &[participants="15"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area15';
    }
    &[participants="16"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area14' 
      'area15 area15 area16 area16 area16';
    }
    &[participants="17"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area15' 
      'area16 area16 area16 area17 area17';
    }
    &[participants="18"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area15' 
      'area16 area17 area17 area18 area18';
    }
    &[participants="19"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area15' 
      'area16 area17 area18 area19 area19';
    }
    &[participants="20"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area15' 
      'area16 area17 area18 area19 area20';
    }
    &[participants="21"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area15' 
      'area16 area17 area18 area19 area19' 
      'area20 area20 area21 area21 area21';
    }
    &[participants="22"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area15' 
      'area16 area17 area18 area19 area19' 
      'area20 area21 area21 area22 area22';
    }
    &[participants="23"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area15' 
      'area16 area17 area18 area19 area20' 
      'area21 area21 area22 area22 area23';
    }
    &[participants="24"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area15' 
      'area16 area17 area18 area19 area20' 
      'area21 area22 area23 area24 area24';
    }
    &[participants="25"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area15' 
      'area16 area17 area18 area19 area20' 
      'area21 area22 area23 area24 area25';
    }
    &[participants="26"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area15' 
      'area16 area17 area18 area19 area20' 
      'area21 area22 area23 area24 area24' 
      'area25 area25 area26 area26 area26';
    }
    &[participants="27"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area15' 
      'area16 area17 area18 area19 area20' 
      'area21 area22 area23 area24 area24' 
      'area25 area26 area26 area27 area27';
    }
    &[participants="28"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area15' 
      'area16 area17 area18 area19 area20' 
      'area21 area22 area23 area24 area25' 
      'area26 area27 area27 area28 area28';
    }
    &[participants="29"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area15' 
      'area16 area17 area18 area19 area20' 
      'area21 area22 area23 area24 area25' 
      'area26 area27 area28 area29 area29';
    }
    &[participants="30"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area15' 
      'area16 area17 area18 area19 area20' 
      'area21 area22 area23 area24 area25' 
      'area26 area27 area28 area29 area30';
    }
    &[participants="31"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area15' 
      'area16 area17 area18 area19 area20' 
      'area21 area22 area23 area24 area25' 
      'area26 area27 area28 area29 area29' 
      'area30 area30 area31 area31 area31';
    }
    &[participants="32"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area15' 
      'area16 area17 area18 area19 area20' 
      'area21 area22 area23 area24 area25' 
      'area26 area27 area28 area29 area29' 
      'area30 area31 area31 area32 area32';
    }
    &[participants="33"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area15' 
      'area16 area17 area18 area19 area20' 
      'area21 area22 area23 area24 area25' 
      'area26 area27 area28 area29 area30' 
      'area31 area32 area32 area33 area33';
    }
    &[participants="34"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area15' 
      'area16 area17 area18 area19 area20' 
      'area21 area22 area23 area24 area25' 
      'area26 area27 area28 area29 area30' 
      'area31 area32 area33 area33 area34';
    }
    &[participants="35"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area15' 
      'area16 area17 area18 area19 area20' 
      'area21 area22 area23 area24 area25' 
      'area26 area27 area28 area29 area30' 
      'area31 area32 area33 area34 area35';
    }
    &[participants="36"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area15' 
      'area16 area17 area18 area19 area20' 
      'area21 area22 area23 area24 area25' 
      'area26 area27 area28 area29 area30' 
      'area31 area32 area33 area34 area34' 
      'area35 area35 area36 area36 area36';
    }
    &[participants="37"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area15' 
      'area16 area17 area18 area19 area20' 
      'area21 area22 area23 area24 area25' 
      'area26 area27 area28 area29 area30' 
      'area31 area32 area33 area34 area35' 
      'area36 area36 area37 area37 area37';
    }
    &[participants="38"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area15' 
      'area16 area17 area18 area19 area20' 
      'area21 area22 area23 area24 area25' 
      'area26 area27 area28 area29 area30' 
      'area31 area32 area33 area34 area35' 
      'area36 area37 area37 area38 area38';
    }
    &[participants="39"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area15' 
      'area16 area17 area18 area19 area20' 
      'area21 area22 area23 area24 area25' 
      'area26 area27 area28 area29 area30' 
      'area31 area32 area33 area34 area35' 
      'area36 area37 area38 area39 area39';
    }
    &[participants="40"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area15' 
      'area16 area17 area18 area19 area20' 
      'area21 area22 area23 area24 area25' 
      'area26 area27 area28 area29 area30' 
      'area31 area32 area33 area34 area35' 
      'area36 area37 area38 area39 area40';
    }
    &[participants="41"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area15' 
      'area16 area17 area18 area19 area20' 
      'area21 area22 area23 area24 area25' 
      'area26 area27 area28 area29 area30' 
      'area31 area32 area33 area34 area35' 
      'area36 area37 area38 area39 area39' 
      'area40 area40 area41 area41 area41';
    }
    &[participants="42"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area15' 
      'area16 area17 area18 area19 area20' 
      'area21 area22 area23 area24 area25' 
      'area26 area27 area28 area29 area30' 
      'area31 area32 area33 area34 area35' 
      'area36 area37 area38 area39 area40' 
      'area41 area41 area42 area42 area42';
    }
    &[participants="43"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area15' 
      'area16 area17 area18 area19 area20' 
      'area21 area22 area23 area24 area25' 
      'area26 area27 area28 area29 area30' 
      'area31 area32 area33 area34 area35' 
      'area36 area37 area38 area39 area40' 
      'area41 area42 area42 area43 area43';
    }
    &[participants="44"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area15' 
      'area16 area17 area18 area19 area20' 
      'area21 area22 area23 area24 area25' 
      'area26 area27 area28 area29 area30' 
      'area31 area32 area33 area34 area35' 
      'area36 area37 area38 area39 area40' 
      'area41 area42 area43 area44 area44';
    }
    &[participants="45"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area15' 
      'area16 area17 area18 area19 area20' 
      'area21 area22 area23 area24 area25' 
      'area26 area27 area28 area29 area30' 
      'area31 area32 area33 area34 area35' 
      'area36 area37 area38 area39 area40' 
      'area41 area42 area43 area44 area45';
    }
    &[participants="46"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area15' 
      'area16 area17 area18 area19 area20' 
      'area21 area22 area23 area24 area25' 
      'area26 area27 area28 area29 area30' 
      'area31 area32 area33 area34 area35' 
      'area36 area37 area38 area39 area40' 
      'area41 area42 area43 area44 area44' 
      'area45 area45 area46 area46 area46';
    }
    &[participants="47"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area15' 
      'area16 area17 area18 area19 area20' 
      'area21 area22 area23 area24 area25' 
      'area26 area27 area28 area29 area30' 
      'area31 area32 area33 area34 area35' 
      'area36 area37 area38 area39 area40' 
      'area41 area42 area43 area44 area45' 
      'area46 area46 area47 area47 area47';
    }
    &[participants="48"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area15' 
      'area16 area17 area18 area19 area20' 
      'area21 area22 area23 area24 area25' 
      'area26 area27 area28 area29 area30' 
      'area31 area32 area33 area34 area35' 
      'area36 area37 area38 area39 area40' 
      'area41 area42 area43 area44 area45' 
      'area46 area47 area47 area48 area48';
    }
    &[participants="49"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area15' 
      'area16 area17 area18 area19 area20' 
      'area21 area22 area23 area24 area25' 
      'area26 area27 area28 area29 area30' 
      'area31 area32 area33 area34 area35' 
      'area36 area37 area38 area39 area40' 
      'area41 area42 area43 area44 area45' 
      'area46 area47 area48 area49 area49';
    }
    &[participants="50"] {
      grid-template-areas: 
      'area1 area2 area3 area4 area5' 
      'area6 area7 area8 area9 area10' 
      'area11 area12 area13 area14 area15' 
      'area16 area17 area18 area19 area20' 
      'area21 area22 area23 area24 area25' 
      'area26 area27 area28 area29 area30' 
      'area31 area32 area33 area34 area35' 
      'area36 area37 area38 area39 area40' 
      'area41 area42 area43 area44 area45' 
      'area46 area47 area48 area49 area50';
    }
    &.exceded {
      grid-template-columns: auto auto auto auto auto;
      div {
        grid-area: auto
      }
    }
  }
}

</style>