var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-main-section insightSection"
  }, [_c('div', {
    staticClass: "crm-main-section-topBar"
  }, [_c('h2', {
    staticClass: "crm-main-section-heading mb-0"
  }, [_vm._v(" Insights ")]), _c('div', {
    staticClass: "section-topBar-middlePart"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-inputSearch-icon"
    }
  }), _c('input', {
    attrs: {
      "type": "text",
      "placeholder": "Search"
    }
  })], 1), _c('div', {
    staticClass: "section-topBar-lastPart"
  }, [_c('button', {
    staticClass: "crm-button listGridView-btn centerBtn ml-0"
  }, [_c('vb-icon', {
    staticClass: "crm-threeDotsHorizontal-icon",
    attrs: {
      "icon": "crm-threeDotsHorizontal-icon"
    }
  })], 1), _c('b-dropdown', {
    staticClass: "crm-big-gray-dd-container",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("Application Dashboard")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" Application Dashboard ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" Application Dashboard ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Application Dashboard ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Application Dashboard ")])])], 1)], 1)]), _c('div', {
    staticClass: "crm-main-section-body"
  }, [_c('div', {
    staticClass: "insightSection-inner"
  }, [_c('div', {
    staticClass: "leftSide"
  }, [_c('div', {
    staticClass: "crm-card withSeprateHeader"
  }, [_c('div', {
    staticClass: "crm-card-heading"
  }, [_c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center"
  }, [_c('div', [_vm._v(" My activities "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1), _c('button', {
    staticClass: "crm-small-button"
  }, [_c('vb-icon', {
    staticClass: "crm-plusButton-icon",
    attrs: {
      "icon": "crm-plusButton-icon"
    }
  }), _vm._v(" Create Activity ")], 1)]), _c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center mt-38px"
  }, [_c('b-dropdown', {
    staticClass: "crm-dd-container",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("This week")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" This week ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" This Month ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Week ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Month ")])])], 1), _c('div', {
    staticClass: "crm-input-container mb-0"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-inputSearch-icon"
    }
  }), _c('input', {
    staticClass: "w-100",
    attrs: {
      "placeholder": "Search"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "crm-card-body"
  }, [_c('vb-table', {
    attrs: {
      "isListEmpty": _vm.isEmpty(_vm.tableData),
      "listLength": _vm.tableData.length,
      "textNoRecord": " "
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.tableData, function (data, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: data.id
          }, [_c('td', {
            staticClass: "dialer-row-title"
          }, [_c('span', [_vm._v(_vm._s(data.m1))])]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(data.m1))]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('span', {
            staticClass: "tagTypeDesign"
          }, [_vm._v(_vm._s(data.m2))])])]);
        });
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    staticClass: "dialer-sorted-by ascending"
  }, [_vm._v("Title")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Due Date")])]), _c('th', {
    staticClass: "dialer-col-right cursor_pointer"
  }, [_c('span', [_vm._v("Activity Type ")])])])])], 2)], 1)]), _c('div', {
    staticClass: "crm-card withSeprateHeader"
  }, [_c('div', {
    staticClass: "crm-card-heading"
  }, [_c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center"
  }, [_c('div', [_vm._v(" Upcoming activities "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1), _c('b-dropdown', {
    staticClass: "crm-dd-container",
    attrs: {
      "id": "sound-setting-micro-devices",
      "no-caret": "",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("This week")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" This week ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" This Month ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Week ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Month ")])])], 1)], 1), _c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center mt-38px"
  }, [_c('b-dropdown', {
    staticClass: "crm-dd-container",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("This week")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" This week ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" This Month ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Week ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Month ")])])], 1), _c('div', {
    staticClass: "crm-input-container mb-0"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-inputSearch-icon"
    }
  }), _c('input', {
    staticClass: "w-100",
    attrs: {
      "placeholder": "Search"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "crm-card-body"
  }, [_c('vb-table', {
    attrs: {
      "isListEmpty": _vm.isEmpty(_vm.tableData),
      "listLength": _vm.tableData.length,
      "textNoRecord": " "
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref2) {
        var start = _ref2.start,
          end = _ref2.end;
        return _vm._l(_vm.tableData, function (data, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: data.id
          }, [_c('td', {
            staticClass: "dialer-row-title"
          }, [_c('span', [_vm._v(_vm._s(data.m1))])]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(data.m1))])]);
        });
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    staticClass: "dialer-sorted-by ascending"
  }, [_vm._v("Title")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Due Date")])])])])], 2)], 1)]), _c('div', {
    staticClass: "crm-card"
  }, [_c('div', {
    staticClass: "crm-card-heading"
  }, [_c('div', [_vm._v(" Activities created by sales agent "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-dropdown', {
    staticClass: "crm-dd-container",
    attrs: {
      "id": "sound-setting-micro-devices",
      "no-caret": "",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("This week")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" This week ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" This Month ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Week ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Month ")])])], 1)], 1)]), _c('div', {
    staticClass: "crm-graph-container"
  }, [_vm._v(" put graph here ")])]), _c('div', {
    staticClass: "crm-card"
  }, [_c('div', {
    staticClass: "crm-card-heading"
  }, [_c('div', [_vm._v(" Logged calls by day "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-dropdown', {
    staticClass: "crm-dd-container mr-3",
    attrs: {
      "id": "sound-setting-micro-devices",
      "no-caret": "",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("Sales Pipeline")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('span', [_vm._v(" All ")])]), _c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" Sales Pipeline ")])], 1)], 1), _c('b-dropdown', {
    staticClass: "crm-dd-container",
    attrs: {
      "id": "sound-setting-micro-devices",
      "no-caret": "",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("This week")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" This week ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" This Month ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Week ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Month ")])])], 1)], 1)]), _c('div', {
    staticClass: "crm-graph-container"
  }, [_vm._v(" put graph here ")])]), _c('div', {
    staticClass: "crm-card"
  }, [_c('div', {
    staticClass: "crm-card-heading"
  }, [_c('div', [_vm._v(" Total logged calls by sales agent "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-dropdown', {
    staticClass: "crm-dd-container",
    attrs: {
      "id": "sound-setting-micro-devices",
      "no-caret": "",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("This week")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" This week ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" This Month ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Week ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Month ")])])], 1)], 1)]), _c('div', {
    staticClass: "crm-graph-container"
  }, [_vm._v(" put graph here ")])]), _c('div', {
    staticClass: "crm-card"
  }, [_c('div', {
    staticClass: "crm-card-heading"
  }, [_c('div', [_vm._v(" Call outcome overview "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-dropdown', {
    staticClass: "crm-dd-container mr-3",
    attrs: {
      "id": "sound-setting-micro-devices",
      "no-caret": "",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("Sales Pipeline")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('span', [_vm._v(" All ")])]), _c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" Sales Pipeline ")])], 1)], 1), _c('b-dropdown', {
    staticClass: "crm-dd-container",
    attrs: {
      "id": "sound-setting-micro-devices",
      "no-caret": "",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("This week")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" This week ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" This Month ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Week ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Month ")])])], 1)], 1)]), _c('div', {
    staticClass: "crm-graph-container"
  }, [_vm._v(" put graph here ")])]), _c('div', {
    staticClass: "crm-card"
  }, [_c('div', {
    staticClass: "crm-card-heading"
  }, [_c('div', [_vm._v(" Companies by day "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-dropdown', {
    staticClass: "crm-dd-container",
    attrs: {
      "id": "sound-setting-micro-devices",
      "no-caret": "",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("This week")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" This week ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" This Month ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Week ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Month ")])])], 1)], 1)]), _c('div', {
    staticClass: "crm-graph-container"
  }, [_vm._v(" put graph here ")])]), _c('div', {
    staticClass: "crm-card"
  }, [_c('div', {
    staticClass: "crm-card-heading"
  }, [_c('div', [_vm._v(" Companies by source "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-dropdown', {
    staticClass: "crm-dd-container",
    attrs: {
      "id": "sound-setting-micro-devices",
      "no-caret": "",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("This week")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" This week ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" This Month ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Week ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Month ")])])], 1)], 1)]), _c('div', {
    staticClass: "crm-graph-container"
  }, [_vm._v(" put graph here ")])]), _c('div', {
    staticClass: "crm-card"
  }, [_c('div', {
    staticClass: "crm-card-heading"
  }, [_c('div', [_vm._v(" Contacts by day "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-dropdown', {
    staticClass: "crm-dd-container",
    attrs: {
      "id": "sound-setting-micro-devices",
      "no-caret": "",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("This week")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" This week ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" This Month ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Week ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Month ")])])], 1)], 1)]), _c('div', {
    staticClass: "crm-graph-container"
  }, [_vm._v(" put graph here ")])]), _c('div', {
    staticClass: "crm-card"
  }, [_c('div', {
    staticClass: "crm-card-heading"
  }, [_c('div', [_vm._v(" Contacts by source "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-dropdown', {
    staticClass: "crm-dd-container",
    attrs: {
      "id": "sound-setting-micro-devices",
      "no-caret": "",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("This week")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" This week ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" This Month ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Week ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Month ")])])], 1)], 1)]), _c('div', {
    staticClass: "crm-graph-container"
  }, [_vm._v(" put graph here ")])]), _c('div', {
    staticClass: "crm-card withSeprateHeader"
  }, [_c('div', {
    staticClass: "crm-card-heading"
  }, [_c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center"
  }, [_c('div', [_vm._v(" Recently created contacts "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1), _c('b-dropdown', {
    staticClass: "crm-dd-container",
    attrs: {
      "id": "sound-setting-micro-devices",
      "no-caret": "",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("This week")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" This week ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" This Month ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Week ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Month ")])])], 1)], 1), _c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center mt-38px"
  }, [_c('b-dropdown', {
    staticClass: "crm-dd-container",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("This week")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" This week ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" This Month ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Week ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Month ")])])], 1), _c('div', {
    staticClass: "crm-input-container mb-0"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-inputSearch-icon"
    }
  }), _c('input', {
    staticClass: "w-100",
    attrs: {
      "placeholder": "Search"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "crm-card-body"
  }, [_c('vb-table', {
    attrs: {
      "isListEmpty": _vm.isEmpty(_vm.tableData),
      "listLength": _vm.tableData.length,
      "textNoRecord": " "
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref3) {
        var start = _ref3.start,
          end = _ref3.end;
        return _vm._l(_vm.tableData, function (data, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: data.id
          }, [_c('td', {
            staticClass: "dialer-row-title"
          }, [_c('span', [_vm._v(_vm._s(data.m1))])]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(data.m1))]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_vm._v(" " + _vm._s(data.m2) + " ")])]);
        });
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    staticClass: "dialer-sorted-by ascending"
  }, [_vm._v("CONTACT")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("E-MAIL ADDRESS")])]), _c('th', {
    staticClass: "dialer-col-right cursor_pointer"
  }, [_c('span', [_vm._v("CREATED AT ")])])])])], 2)], 1)]), _c('div', {
    staticClass: "crm-card withSeprateHeader"
  }, [_c('div', {
    staticClass: "crm-card-heading"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between w-100 align-items-center"
  }, [_c('div', [_vm._v(" Closing deals "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1), _c('div', {
    staticClass: "d-flex"
  }, [_c('b-dropdown', {
    staticClass: "crm-dd-container mr-3",
    attrs: {
      "id": "sound-setting-micro-devices",
      "no-caret": "",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("Admin")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('span', [_vm._v(" All ")])]), _c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" Admin ")])], 1)], 1), _c('b-dropdown', {
    staticClass: "crm-dd-container",
    attrs: {
      "id": "sound-setting-micro-devices",
      "no-caret": "",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("This week")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" This week ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" This Month ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Week ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Month ")])])], 1)], 1)]), _c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center mt-38px"
  }, [_c('b-dropdown', {
    staticClass: "crm-dd-container",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("This week")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" This week ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" This Month ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Week ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Month ")])])], 1), _c('div', {
    staticClass: "crm-input-container mb-0"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-inputSearch-icon"
    }
  }), _c('input', {
    staticClass: "w-100",
    attrs: {
      "placeholder": "Search"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "crm-card-body"
  }, [_c('vb-table', {
    attrs: {
      "isListEmpty": _vm.isEmpty(_vm.tableData),
      "listLength": _vm.tableData.length,
      "textNoRecord": " "
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref4) {
        var start = _ref4.start,
          end = _ref4.end;
        return _vm._l(_vm.tableData, function (data, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: data.id
          }, [_c('td', {
            staticClass: "dialer-row-title"
          }, [_c('span', [_vm._v(_vm._s(data.m1))])]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(data.m1))])]);
        });
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    staticClass: "dialer-sorted-by ascending"
  }, [_vm._v("Deal Name")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Expected Close date")])])])])], 2)], 1)]), _c('div', {
    staticClass: "crm-card"
  }, [_c('div', {
    staticClass: "crm-card-heading"
  }, [_c('div', [_vm._v(" Deals by stage "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-dropdown', {
    staticClass: "crm-dd-container",
    attrs: {
      "id": "sound-setting-micro-devices",
      "no-caret": "",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("This week")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" This week ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" This Month ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Week ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Month ")])])], 1)], 1)]), _c('div', {
    staticClass: "crm-graph-container"
  }, [_vm._v(" put graph here ")])])]), _c('div', {
    staticClass: "rightSide"
  }, [_c('div', {
    staticClass: "crm-card"
  }, [_c('div', {
    staticClass: "crm-card-heading"
  }, [_c('div', [_vm._v(" Lost deals stage "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-dropdown', {
    staticClass: "crm-dd-container mr-3",
    attrs: {
      "id": "sound-setting-micro-devices",
      "no-caret": "",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("Sales Pipeline")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('span', [_vm._v(" All ")])]), _c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" Sales Pipeline ")])], 1)], 1), _c('b-dropdown', {
    staticClass: "crm-dd-container",
    attrs: {
      "id": "sound-setting-micro-devices",
      "no-caret": "",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("This week")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" This week ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" This Month ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Week ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Month ")])])], 1)], 1)]), _c('div', {
    staticClass: "crm-graph-container"
  }, [_vm._v(" put graph here ")])]), _c('div', {
    staticClass: "crm-card"
  }, [_c('div', {
    staticClass: "crm-card-heading"
  }, [_c('div', [_vm._v(" Won deals stage "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-dropdown', {
    staticClass: "crm-dd-container mr-3",
    attrs: {
      "id": "sound-setting-micro-devices",
      "no-caret": "",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("Sales Pipeline")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('span', [_vm._v(" All ")])]), _c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" Sales Pipeline ")])], 1)], 1), _c('b-dropdown', {
    staticClass: "crm-dd-container",
    attrs: {
      "id": "sound-setting-micro-devices",
      "no-caret": "",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("This week")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" This week ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" This Month ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Week ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Month ")])])], 1)], 1)]), _c('div', {
    staticClass: "crm-graph-container"
  }, [_vm._v(" put graph here ")])]), _c('div', {
    staticClass: "crm-card"
  }, [_c('div', {
    staticClass: "crm-card-heading"
  }, [_c('div', [_vm._v(" Won deals by day "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-dropdown', {
    staticClass: "crm-dd-container mr-3",
    attrs: {
      "id": "sound-setting-micro-devices",
      "no-caret": "",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("Sales Pipeline")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('span', [_vm._v(" All ")])]), _c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" Sales Pipeline ")])], 1)], 1), _c('b-dropdown', {
    staticClass: "crm-dd-container",
    attrs: {
      "id": "sound-setting-micro-devices",
      "no-caret": "",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("This week")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" This week ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" This Month ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Week ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Month ")])])], 1)], 1)]), _c('div', {
    staticClass: "crm-graph-container"
  }, [_vm._v(" put graph here ")])]), _c('div', {
    staticClass: "crm-card"
  }, [_c('div', {
    staticClass: "crm-card-heading"
  }, [_c('div', [_vm._v(" Won deals by month "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-dropdown', {
    staticClass: "crm-dd-container mr-3",
    attrs: {
      "id": "sound-setting-micro-devices",
      "no-caret": "",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("Sales Pipeline")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('span', [_vm._v(" All ")])]), _c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" Sales Pipeline ")])], 1)], 1), _c('b-dropdown', {
    staticClass: "crm-dd-container",
    attrs: {
      "id": "sound-setting-micro-devices",
      "no-caret": "",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("This week")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" This week ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" This Month ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Week ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Month ")])])], 1)], 1)]), _c('div', {
    staticClass: "crm-graph-container"
  }, [_vm._v(" put graph here ")])]), _c('div', {
    staticClass: "crm-card withSeprateHeader"
  }, [_c('div', {
    staticClass: "crm-card-heading"
  }, [_c('div', {
    staticClass: "d-flex w-100 justify-content-between"
  }, [_c('div', [_vm._v(" Recently created deals "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1)]), _c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center mt-38px"
  }, [_c('b-dropdown', {
    staticClass: "crm-dd-container",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("This week")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" This week ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" This Month ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Week ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Month ")])])], 1), _c('div', {
    staticClass: "crm-input-container mb-0"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-inputSearch-icon"
    }
  }), _c('input', {
    staticClass: "w-100",
    attrs: {
      "placeholder": "Search"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "crm-card-body"
  }, [_c('vb-table', {
    attrs: {
      "isListEmpty": _vm.isEmpty(_vm.tableData),
      "listLength": _vm.tableData.length,
      "textNoRecord": " "
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref5) {
        var start = _ref5.start,
          end = _ref5.end;
        return _vm._l(_vm.tableData, function (data, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: data.id
          }, [_c('td', {
            staticClass: "dialer-row-title"
          }, [_c('span', [_vm._v(_vm._s(data.m1))])]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(data.m1))]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_vm._v(" " + _vm._s(data.m2) + " ")])]);
        });
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    staticClass: "dialer-sorted-by ascending"
  }, [_vm._v("Deal name")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("stage")])]), _c('th', {
    staticClass: "dialer-col-right cursor_pointer"
  }, [_c('span', [_vm._v("CREATED AT ")])])])])], 2)], 1)]), _c('div', {
    staticClass: "crm-card withSeprateHeader"
  }, [_c('div', {
    staticClass: "crm-card-heading"
  }, [_c('div', {
    staticClass: "d-flex align-items-center w-100 justify-content-between"
  }, [_c('div', [_vm._v(" Recently modified deals "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1)]), _c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center mt-38px"
  }, [_c('b-dropdown', {
    staticClass: "crm-dd-container",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("This week")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" This week ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" This Month ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Week ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Month ")])])], 1), _c('div', {
    staticClass: "crm-input-container mb-0"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-inputSearch-icon"
    }
  }), _c('input', {
    staticClass: "w-100",
    attrs: {
      "placeholder": "Search"
    }
  })], 1)], 1)]), _c('vb-table', {
    attrs: {
      "isListEmpty": _vm.isEmpty(_vm.tableData),
      "listLength": _vm.tableData.length,
      "textNoRecord": " "
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref6) {
        var start = _ref6.start,
          end = _ref6.end;
        return _vm._l(_vm.tableData, function (data, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: data.id
          }, [_c('td', {
            staticClass: "dialer-row-title"
          }, [_c('span', [_vm._v(_vm._s(data.m1))])]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(data.m1))]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_vm._v(" " + _vm._s(data.m2) + " ")])]);
        });
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    staticClass: "dialer-sorted-by ascending"
  }, [_vm._v("Deal name")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("stage")])]), _c('th', {
    staticClass: "dialer-col-right cursor_pointer"
  }, [_c('span', [_vm._v("CREATED AT ")])])])])], 2)], 1), _c('div', {
    staticClass: "crm-card withSeprateHeader"
  }, [_c('div', {
    staticClass: "crm-card-heading"
  }, [_c('div', {
    staticClass: "d-flex align-items-center w-100 justify-content-between"
  }, [_c('div', [_vm._v(" Created deals by sales agent "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1), _c('b-dropdown', {
    staticClass: "crm-dd-container",
    attrs: {
      "id": "sound-setting-micro-devices",
      "no-caret": "",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("This week")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" This week ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" This Month ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Week ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Month ")])])], 1)], 1), _c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center mt-38px"
  }, [_c('b-dropdown', {
    staticClass: "crm-dd-container",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("This week")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" This week ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" This Month ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Week ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Month ")])])], 1), _c('div', {
    staticClass: "crm-input-container mb-0"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-inputSearch-icon"
    }
  }), _c('input', {
    staticClass: "w-100",
    attrs: {
      "placeholder": "Search"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "crm-card-body"
  }, [_c('vb-table', {
    attrs: {
      "isListEmpty": _vm.isEmpty(_vm.tableData),
      "listLength": _vm.tableData.length,
      "textNoRecord": " "
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref7) {
        var start = _ref7.start,
          end = _ref7.end;
        return _vm._l(_vm.tableData, function (data, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: data.id
          }, [_c('td', {
            staticClass: "dialer-row-title"
          }, [_c('span', [_vm._v(_vm._s(data.m1))])]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(data.m1))]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(data.m1))]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_vm._v(" " + _vm._s(data.m2) + " ")])]);
        });
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    staticClass: "dialer-sorted-by ascending"
  }, [_vm._v("SALES AGENT")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("TOTAL CREATED")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("FORECAST AMOUNT")])]), _c('th', {
    staticClass: "dialer-col-right cursor_pointer"
  }, [_c('span', [_vm._v("CLOSED AMOUNT")])])])])], 2)], 1)]), _c('div', {
    staticClass: "crm-card withSeprateHeader"
  }, [_c('div', {
    staticClass: "crm-card-heading"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between w-100"
  }, [_c('div', [_vm._v(" Assigned deals by sales agent "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1), _c('b-dropdown', {
    staticClass: "crm-dd-container",
    attrs: {
      "id": "sound-setting-micro-devices",
      "no-caret": "",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("This week")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" This week ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" This Month ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Week ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Month ")])])], 1)], 1), _c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center mt-38px"
  }, [_c('b-dropdown', {
    staticClass: "crm-dd-container",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("This week")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" This week ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" This Month ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Week ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Month ")])])], 1), _c('div', {
    staticClass: "crm-input-container mb-0"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-inputSearch-icon"
    }
  }), _c('input', {
    staticClass: "w-100",
    attrs: {
      "placeholder": "Search"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "crm-card-body"
  }, [_c('vb-table', {
    attrs: {
      "isListEmpty": _vm.isEmpty(_vm.tableData),
      "listLength": _vm.tableData.length,
      "textNoRecord": " "
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref8) {
        var start = _ref8.start,
          end = _ref8.end;
        return _vm._l(_vm.tableData, function (data, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: data.id
          }, [_c('td', {
            staticClass: "dialer-row-title"
          }, [_c('span', [_vm._v(_vm._s(data.m1))])]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(data.m1))]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(data.m1))]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_vm._v(" " + _vm._s(data.m2) + " ")])]);
        });
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    staticClass: "dialer-sorted-by ascending"
  }, [_vm._v("SALES AGENT")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("TOTAL ASSIGNED")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("FORECAST AMOUNT")])]), _c('th', {
    staticClass: "dialer-col-right cursor_pointer"
  }, [_c('span', [_vm._v("CLOSED AMOUNT")])])])])], 2)], 1)]), _c('div', {
    staticClass: "crm-card withSeprateHeader"
  }, [_c('div', {
    staticClass: "crm-card-heading"
  }, [_c('div', {
    staticClass: "d-flex align-items-center w-100 justify-content-between"
  }, [_c('div', [_vm._v(" Product performance "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1)]), _c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center mt-38px"
  }, [_c('b-dropdown', {
    staticClass: "crm-dd-container",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("This week")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" This week ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" This Month ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Week ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Month ")])])], 1), _c('div', {
    staticClass: "crm-input-container mb-0"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-inputSearch-icon"
    }
  }), _c('input', {
    staticClass: "w-100",
    attrs: {
      "placeholder": "Search"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "crm-card-body"
  }, [_c('vb-table', {
    attrs: {
      "isListEmpty": _vm.isEmpty(_vm.tableData),
      "listLength": _vm.tableData.length,
      "textNoRecord": " "
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref9) {
        var start = _ref9.start,
          end = _ref9.end;
        return _vm._l(_vm.tableData, function (data, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: data.id
          }, [_c('td', {
            staticClass: "dialer-row-title"
          }, [_c('span', [_vm._v(_vm._s(data.m1))])]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(data.m1))]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(data.m1))]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_vm._v(" " + _vm._s(data.m2) + " ")])]);
        });
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    staticClass: "dialer-sorted-by ascending"
  }, [_vm._v("Name")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Interested in Product")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Total Sold")])]), _c('th', {
    staticClass: "dialer-col-right cursor_pointer"
  }, [_c('span', [_vm._v("Sold Amount (Tax Exl..)")])])])])], 2)], 1)])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }