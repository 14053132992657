<template>
  <div class="w-100">

    <div class="TODO-taksTable-bodyRow thisIsAddTaskRow mt-12px" style="min-height:auto;" v-if="addTask">
      <template v-if="conditions.add_task">
        <b-form @submit.prevent="createTask()" class="d-flex w-100">
          <div class="TODO-taksTable-bodyRow-column align-items-start py-2 justify-content-start flex-1 w-100-260px">
            <div class="d-flex flex-column whiteBGinputWithGreyRoundedBorder w-100-200px seachBox">
              <b-form-input 
                v-focus 
                type="text" 
                placeholder="Add title" 
                class="bg-white w-100" 
                :disabled="api.create_task.send" 
                v-model="forms.create_task.title" 
                :maxLength="$v.forms.create_task.title.$params.maxLength.max"
              />
              <p class="error-red text text-danger animated bounceIntop" v-if="(forms.create_task.submitted && $v.forms.create_task.title.$invalid) || api.create_task.validation_errors.title">
                <span v-if="!$v.forms.create_task.title.required">Title is required</span>
                <span v-else-if="!$v.forms.create_task.title.minLength"> Title should be minimum {{$v.forms.create_task.title.$params.minLength.min}} character</span>
                <span v-else-if="!$v.forms.create_task.title.maxLength"> Title can be maximum {{$v.forms.create_task.title.$params.maxLength.max}} character</span>
                <span v-for="(em,i) in api.create_task.validation_errors.title" :key="i">{{ em }}</span>
              </p>
            </div>
            <div class="d-flex flex-column whiteBGinputWithGreyRoundedBorder flex-fill dropdownVersion ml-4">
              <b-select 
                placeholder="Select space"
                :disabled="api.create_task.send || api.spaces.send" 
                v-model="forms.create_task.space_id" 
                :options="response.spaces" 
                value-field="id" 
                text-field="name" 
              />
              <p class="error-red" v-if="(forms.create_task.submitted && $v.forms.create_task.space_id.$invalid) || api.create_task.validation_errors.space_id">
                <span v-if="!$v.forms.create_task.space_id.required">Space is required</span>
                <span v-for="(em,i) in api.create_task.validation_errors.space_id" :key="i">{{ em }}</span>
              </p>
            </div>
          </div>
          <div class="TODO-taksTable-bodyRow-column align-items-start py-2 w-auto" >
            <div class="TODO-taksTable-bodyRow-column-text d-flex justify-content-end w-100">
              <b-button type="submit" class="newDoneButton" variant="primary" :disabled="api.create_task.send">
                <vb-icon icon="squared-submit-icon" class="submitIcon" height="38px" width="38px" />
                <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                  Submit
                </span>
              </b-button>
              <b-button :disabled="api.create_task.send" @click="conditions.add_task=false;forms.create_task.reset()" variant="link" class="ml-3" type="button">
                <b-icon icon="x-lg" variant="danger" />
              </b-button>
            </div>
          </div>
        </b-form>

        <!-- <b-form @submit.prevent="createTask()">
          <b-row>
            <b-col>
              <b-form-input v-focus type="text" placeholder="Add title" :disabled="api.create_task.send" v-model="forms.create_task.title" />
              <p v-if="(forms.create_task.submitted && $v.forms.create_task.title.$invalid) || api.create_task.validation_errors.title">
                <span v-if="!$v.forms.create_task.title.required">Title is required</span>
                <span v-for="(em,i) in api.create_task.validation_errors.title" :key="i">{{ em }}</span>
              </p>
            </b-col>
            <b-col cols="4">
              <b-select 
                placeholder="Select Space"
                :disabled="api.create_task.send || api.spaces.send" 
                v-model="forms.create_task.space_id" 
                :options="response.spaces" 
                value-field="id" 
                text-field="name" 
              />
              <p v-if="(forms.create_task.submitted && $v.forms.create_task.space_id.$invalid) || api.create_task.validation_errors.space_id">
                <span v-if="!$v.forms.create_task.space_id.required">Space is required</span>
                <span v-for="(em,i) in api.create_task.validation_errors.space_id" :key="i">{{ em }}</span>
              </p>
            </b-col>
            <b-col cols="3">
              <b-button variant="primary" :disabled="api.create_task.send">
                Submit
              </b-button>
            </b-col>
          </b-row>
          <b-button :disabled="api.create_task.send" @click="conditions.add_task=false;forms.create_task.reset()" variant="link" type="button">
            <b-icon icon="x" variant="danger" />
          </b-button>
        </b-form> -->
      </template>
      <div class="TODO-taksTable-bodyRow-column onlyOne justify-content-start" v-else @click="conditions.add_task=true;getSpaces();">
        <div class="TODO-taksTable-bodyRow-column-text">
          <b-icon icon="plus-lg" class="mr-3" />
          Add task
        </div>
      </div>
    </div>

    <!-- <div v-else class="TODO-taksTable-bodyRow mt-12px" @click="$emit('taskClick')" >
      <template v-if="$store.getters.getIsMobile">
        <div class="TODO-taksTable-bodyRow-column onlyOne justify-content-start" :style="`border-left: 9px solid ${priorityColor}`">
          <b-icon v-if="task.spaces" icon="circle-fill" :style="`color:${task.spaces.color};`" v-b-tooltip.hover :title="task.spaces.name"></b-icon>
          <div class="TODO-taksTable-bodyRow-column-text ml-2">{{ task | get_property('title') }}</div>
        </div>
      </template>
      <template v-else>
        <div class="TODO-taksTable-bodyRow-column first" :style="`border-left: 13px solid ${priorityColor}`">
          <div class="d-flex align-items-center justify-content-between w-100">
            <div class="d-flex align-items-center">
              <b-icon v-if="task.spaces" icon="circle-fill" :style="`color:${task.spaces.color};`" v-b-tooltip.hover :title="task.spaces.name"></b-icon>
              <div class="TODO-taksTable-bodyRow-column-text ml-2">{{ task | get_property('title') }}</div>
            </div>
            <div v-if="task.created_by">
              <b-icon icon="circle-fill" :style="`color:#4aa3fe;`" />
              created by 
              <Info :only_name="true" :id="task.created_by" />
              on {{ task.created_at | filter_date_current }}
              <template v-if="task.due_date">
                Due {{ task.due_date | filter_date_current }}
              </template>
              Last updated 
              {{ task.updated_at | filter_date_current }}
            </div>
            <div class="d-flex align-items-center">
              <b-icon v-if="myDay" class="mr-32px" icon="sun-fill" variant="dark" width="21.056px" height="21.052px"/>
              <b-icon v-if="task.is_important=='1'" class="mr-32px" icon="exclamation-lg" variant="dark" width="21.056px" height="21.052px"/>
              <vb-avatar :showPopover="true" v-for="assigned in assigned.array" :is_blf="false" :key="assigned.id" :id="assigned.assigned_to" />
              <div v-if="assigned.count">+ {{assigned.count}}</div>
              <b-icon v-if="task.note" icon="file-earmark-text-fill" variant="dark" width="21.056px" height="21.052px"/>
            </div>
          </div>
        </div>
        <div class="TODO-taksTable-bodyRow-column second" :style="`background-color: ${task.group ? task.group.color : ''};`">
          <div class="TODO-taksTable-bodyRow-column-text colorWhite">{{task | get_property('group.name')}}</div>
        </div>
        <div class="TODO-taksTable-bodyRow-column third">
          <div class="TODO-taksTable-bodyRow-column-text">{{ task | get_property('due_date') }}</div>
        </div>
        <div class="TODO-taksTable-bodyRow-column fourth" :style="`background-color: ${task.status.color}`">
          <div class="TODO-taksTable-bodyRow-column-text colorWhite">
            {{ task.status.title }}
          </div>
        </div>
        <div class="TODO-taksTable-bodyRow-column fifth">
          <b-dropdown no-caret :disabled="api.remove_task.send" class="filterDD">
            <template #button-content>
              <vb-icon icon="todo-three-dots-vertical-icon" variant="dark" class="mx-auto" width="6px" height="27px"/>
            </template>
            <b-dropdown-item @click.stop="''">priority</b-dropdown-item>
            <b-dropdown-item @click.stop="''">My Day</b-dropdown-item>
            <b-dropdown-item @click.stop="''">Important</b-dropdown-item>
            <b-dropdown-item @click.stop="removeTask()">Delete</b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </div> -->

    <div v-else class="TODO-taksTable-bodyRow mt-12px" @click="$emit('taskClick')">
      <template v-if="$store.getters.getIsMobile">
        <div class="TODO-taksTable-bodyRow-column onlyOne justify-content-start" :style="`border-left: 9px solid ${priorityColor}`">
          <b-icon v-if="task.spaces" icon="circle-fill" :style="`color:${task.spaces.color};`" v-b-tooltip.hover :title="task.spaces.name"></b-icon>
          <div class="TODO-taksTable-bodyRow-column-text ml-2">{{ task | get_property('title') }}</div>
        </div>
      </template>
      <template v-else>
        <div class="TODO-taksTable-bodyRow-column first" :style="`border-left: 13px solid ${priorityColor}`">
          <div class="d-flex flex-column w-100">
            <div class="mainInfo-section">
              <b-icon v-if="task.spaces" icon="circle-fill" :style="`color:${task.spaces.color};`" v-b-tooltip.hover :title="task.spaces.name"></b-icon>
              <div class="TODO-taksTable-bodyRow-column-text ml-2">{{ task | get_property('title') }}</div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <!-- <div v-if="task.created_by" class="subInfo-section">
                <b-icon class="mr-4px" icon="circle-fill" :style="`color:#4aa3fe;`" />
                <span class="mr-16px d-flex align-items-center">
                  created by <Info class="mx-1" :only_name="true" :id="task.created_by" /> on {{ task.created_at | filter_date_current }}
                </span> 
                <template v-if="task.due_date">
                  <span class="mr-16px">Due 
                    <span class="selectedActive">{{ task.due_date | filter_date_current }}</span>
                  </span>
                </template>
                <span class="mr-16px">
                  Last updated
                  <span class="selectedActive">{{ task.updated_at | filter_date_current }}</span>
                </span>
                <span class="mr-16px d-flex align-items-center">
                  <b-icon v-if="myDay" class="mr-8px" icon="sun-fill" variant="dark" width="auto" height="19px"/>
                  <b-icon v-if="task.is_important=='1'" class="mr-8px" icon="exclamation-lg" variant="dark" width="auto" height="19px"/>
                  <b-icon v-if="task.note" icon="file-earmark-text-fill" variant="dark" width="16px" height="19px"/>
                </span>
                <div class="categoryText" :style="`background-color: ${task.group ? task.group.color : ''};`">{{task | get_property('group.name')}}</div>
              </div> -->
              <div v-if="task.created_by" class="subInfo-section">
                <span class="d-flex firstPart align-items-center">
                  <span class="mr-8px">{{ task | get_property('spaces.prefix') }}#{{ task | get_property('id') }}</span>
                  <span class="mr-8px d-flex align-items-center putSmallDotInBefore">
                    created by <Info class="mx-1" :only_name="true" :id="task.created_by" /> on {{ task.created_at | filter_date_current }}
                  </span> 
                  <template v-if="task.due_date">
                    <span class="mr-8px putSmallDotInBefore">
                      Due 
                      <span class="selectedActive">{{ task.due_date | filter_date_current }}</span>
                    </span>
                  </template>
                  <span class="mr-16px putSmallDotInBefore">
                    Last updated
                    <span class="selectedActive">{{ task.updated_at | filter_date_current }}</span>
                  </span>
                </span>
                <span class="d-flex secondPart">
                  <span class="mr-16px d-flex align-items-center">
                    <b-icon v-if="myDay" class="mr-8px" icon="sun-fill" variant="dark" width="auto" height="19px"/>
                    <b-icon v-if="task.is_important=='1'" class="mr-8px" icon="exclamation-lg" variant="dark" width="auto" height="19px"/>
                    <b-icon v-if="task.note" icon="file-earmark-text-fill" variant="dark" width="16px" height="19px"/>
                  </span>
                  <div class="categoryText" :style="`background-color: ${task.group ? task.group.color : ''};`">{{task | get_property('group.name')}}</div>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="TODO-taksTable-bodyRow-column second" :class="`${assigned.count>0?'moreThanThree':assigned.array.length==3?'exactlyThree':''}`">
          <vb-avatar :showPopover="true" v-for="assigned in assigned.array" :is_blf="false" :key="assigned.id" :id="assigned.assigned_to" />
          <div class="countPlus" v-if="assigned.count">+ {{assigned.count}}</div>
        </div>
        <div class="TODO-taksTable-bodyRow-column third" :style="`background-color: ${task.status ? task.status.color : 'transparent'}`">
          <template v-if="task.status">
            <div class="TODO-taksTable-bodyRow-column-text colorWhite">
              {{ task.status.title }}
            </div>
          </template>
        </div>
        <div class="TODO-taksTable-bodyRow-column fourth" >
          <div v-if="isMore" class="filterDD dropdownVersion whiteBGinputWithGreyRoundedBorder">
            <b-dropdown no-caret :disabled="api.remove_task.send" class="" right>
              <template #button-content>
                <vb-icon icon="todo-three-dots-vertical-icon" variant="dark" class="mx-auto" width="6px" height="27px"/>
              </template>
              <!-- <b-dropdown-item @click.stop="''">Priority</b-dropdown-item>
              <b-dropdown-item @click.stop="''">My day</b-dropdown-item>
              <b-dropdown-item @click.stop="''">Important</b-dropdown-item> -->
              <b-dropdown-item @click.stop="removeTask()">Delete</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { VOIP_API } from '@/utils';
import required from 'vuelidate/lib/validators/required'
import { mapGetters } from 'vuex';
import maxLength from 'vuelidate/lib/validators/maxLength';
import minLength from 'vuelidate/lib/validators/minLength';
import Info from '../Lists/Info.vue';
export default {
  name: 'TaskItem',
  components: { 
    Info 
  },
  props: {
    task: {
      type: Object,
      default: ()=>({})
    },
    addTask: {
      type: Boolean,
      default: false,
    },
    space_id: {
      type: [String,Number],
      default: '',
    },
    isMore: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      conditions: {
        add_task: false,
      },
      api: {
        spaces: this.$helperFunction.apiInstance(),
        create_task: this.$helperFunction.apiInstance({
          validation_errors: true,
        }),
        remove_task: this.$helperFunction.apiInstance(),
        update_my_day: this.$helperFunction.apiInstance(),
        update_important: this.$helperFunction.apiInstance(),
        update_priority: this.$helperFunction.apiInstance(),
      },
      forms: {
        create_task: this.$helperFunction.formInstance({
          data: {
            title: '',
            space_id: '',
          },
        })
      },
      response: {
        spaces: [],
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser'
    ]),
    priorityColor(){
      const priority = this.task?.priority
      if(priority=='high') return '#ff403b'
      else if(priority=='mid') return '#ffba39'
      else return '#8ad129'
    },
    myDay(){ return (this.task?.my_day ?? '').includes(this.getCurrentUser.account)},
    assigned(){
      let array = [], count = 0, assigned = this.task.assigned ?? []
      if(assigned?.length>3) {
        array = assigned.slice(0,3)
      } else {
        array = assigned
      }
      count = assigned.length-3
      return {
        array: array ?? [],
        count: count > 0 ? count : 0
      }
    },
  },
  validations: {
    forms: {
      create_task: {
        title: {
          required: required,
          minLength: minLength(1),
          maxLength: maxLength(150),
        },
        space_id: {
          required: required,
        }
      },
    },
  },
  methods: {
    async getSpaces() {
      if(this.api.spaces.send) return;
      try {
        this.api.spaces.send=true
        const { data } = await VOIP_API.endpoints.task_spaces.list({
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
        })
        this.forms.create_task.space_id=this.space_id || data?.[0]?.id || ''
        this.response.spaces=data ?? []
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.spaces.send=false  
      }
    },
    async createTask(){
      this.forms.create_task.submitted=true
      this.$v.forms.create_task.$touch()
      if(this.api.create_task.send || this.$v.forms.create_task.$invalid) return;
      try {
        this.api.create_task.send=true
        this.api.create_task.validation_errors={}
        await VOIP_API.endpoints.tasklist.create({
          title: this.forms.create_task.title,
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
          space_id: this.forms.create_task.space_id,
        })
        this.$emit('created')
        this.$emit('update-list')
        this.conditions.add_task=false
        this.forms.create_task.reset()
        this.appNotify({
          message: 'Successfully created',
          type: 'success',
        })
      } catch (ex) {
        this.api.create_task.validation_errors=ex.own_errors ?? {}
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.create_task.send=false
      }
    },
    async removeTask(){
      if(this.api.remove_task.send) return;
      try {
        this.api.remove_task.send=true;
        const { confirm } = await this.$appConfirmation({
          title: "WARNING",
          message: `Are you sure you want to delete?`,
          variant: 'danger',
          button: {
            no: "No",
            yes: "Yes",
          },
        })
        if(!confirm) return;
        await VOIP_API.endpoints.tasklist.delete(this.task.id)
        this.appNotify({
          message: 'Successfully Deleted',
          type: 'success',
        })
        this.$emit('deleted',{
          task_id: this.task.id
        })
        this.$emit('update-list')
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.remove_task.send=false;
      }
    },
  },
}
</script>

<style>

</style>