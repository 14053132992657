var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-main-section DealsSection"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100 mb-5"
  }, [_c('h2', {
    staticClass: "crm-main-section-heading text-case-unset mb-0"
  }, [_vm._v(" Update "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1)]), _vm._m(0), _vm._m(1), _c('div', {
    staticClass: "crm-card p-32"
  }, [_c('h2', {
    staticClass: "crm-main-section-heading text-case-unset mb-0",
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v(" Patches ")]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center crm-innerCard mt-4 w-100"
  }, [_vm._m(2), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-icon', {
    staticClass: "mr-3",
    attrs: {
      "icon": "file-earmark-arrow-down-fill",
      "font-scale": "1.5"
    }
  }), _c('button', {
    staticClass: "crm-button disabled"
  }, [_vm._v(" Apply ")])], 1)]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center crm-innerCard mt-4 w-100"
  }, [_vm._m(3), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-icon', {
    staticClass: "mr-3",
    attrs: {
      "icon": "file-earmark-arrow-down-fill",
      "font-scale": "1.5"
    }
  }), _c('button', {
    staticClass: "crm-button disabled"
  }, [_vm._v(" Apply ")])], 1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-card p-32"
  }, [_c('h2', {
    staticClass: "crm-main-section-heading text-case-unset my-0",
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v(" Purchase Key ")]), _c('div', {
    staticClass: "crm-input-container withoutIcon mt-3 d-flex w-100 mb-0"
  }, [_c('input', {
    staticStyle: {
      "flex": "1"
    },
    attrs: {
      "placeholder": "Enter your purchase key"
    }
  }), _c('button', {
    staticClass: "crm-button ml-2"
  }, [_vm._v(" Save ")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-card p-32"
  }, [_c('h2', {
    staticClass: "crm-main-section-heading text-case-unset my-0",
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v(" System Updates ")]), _c('div', {
    staticClass: "d-flex align-items-center my-3"
  }, [_c('div', {
    staticClass: "crm-versionBox installed"
  }, [_c('div', {
    staticClass: "crm-versionBox-text"
  }, [_vm._v(" Installed Version ")]), _c('div', {
    staticClass: "crm-versionBox-text"
  }, [_vm._v(" 1.1.6 ")])]), _c('div', {
    staticClass: "crm-versionBox latest"
  }, [_c('div', {
    staticClass: "crm-versionBox-text"
  }, [_vm._v(" Latest Version ")]), _c('div', {
    staticClass: "crm-versionBox-text"
  }, [_vm._v(" 1.2.0 ")])])]), _c('button', {
    staticClass: "crm-button disabled"
  }, [_vm._v(" Save ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column wd-70"
  }, [_c('div', {
    staticClass: "crm-card-heading my-0"
  }, [_vm._v("Fixes incorrect notification sent on owner change")]), _c('div', {
    staticClass: "d-flex align-items-center mt-3"
  }, [_c('p', {
    staticClass: "circular-highlighted-text mb-0"
  }, [_vm._v(" 19508260-5c14-4012-8932-232f890e3a83 ")]), _c('p', {
    staticClass: "crm-card-text my-0 ml-4 font-weight-600"
  }, [_vm._v(" January 23, 2023 15:08 ")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column wd-70"
  }, [_c('div', {
    staticClass: "crm-card-heading my-0"
  }, [_vm._v("Fixes issue with permissions checks")]), _c('div', {
    staticClass: "d-flex align-items-center mt-3"
  }, [_c('p', {
    staticClass: "circular-highlighted-text mb-0"
  }, [_vm._v(" 19508260-5c14-4012-8932-232f890e3a83 ")]), _c('p', {
    staticClass: "crm-card-text my-0 ml-4 font-weight-600"
  }, [_vm._v(" January 23, 2023 15:08 ")])])]);

}]

export { render, staticRenderFns }