var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex flex-column"
  }, _vm._l(_vm.numbers, function (number, index) {
    return _c('span', {
      key: index,
      staticClass: "sm-mar-left font-weight-bold min-width-160px"
    }, [_c('span', {
      staticClass: "mr-2"
    }, [_c('img', {
      staticClass: "country-img",
      attrs: {
        "width": "45px",
        "src": _vm._f("flag_icon")(_vm._f("number_formater")(number))
      }
    })]), _vm._v(" " + _vm._s(_vm._f("number_formater")(number)) + " ")]);
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }