var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-500 AssignNumberTo min-width-50percent-modal",
    attrs: {
      "width": "500px",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-assign-to md-pad-bottom dialer-edit"
  }, [_vm.$store.getters.getIsMobile ? _c('div', {
    staticClass: "dialer-edit-header flex-column"
  }, [_c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Assigned telephone numbers")]), _vm.$store.getters.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "dialer-edit-actions"
  }), _c('div', {
    staticClass: "dialer-edit-actions w-100 justify-content-end d-flex mt-2"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.assign();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]) : _c('div', {
    staticClass: "dialer-edit-header d-block"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_c('vb-svg', {
    staticClass: "cursor_pointer_no_hover",
    attrs: {
      "name": "leftLongArrow-icon",
      "width": "20",
      "height": "12",
      "viewBox": "0 0 30 16",
      "stroke-width": "1",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }), _vm._v(" Assigned telephone numbers ")], 1), _c('div', {
    staticClass: "dialer-edit-actions d-flex"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": _vm.api.fetch_numbers.send || !_vm.isAnyChange
    },
    on: {
      "click": function ($event) {
        return _vm.assign();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v("Choose or buy a telephone number to link to this user.")])]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder seachBox w-100 d-flex justify-content-end"
  }, [_c('b-input', {
    staticClass: "wd-45-imp",
    attrs: {
      "type": "text",
      "placeholder": "Search numbers",
      "onkeypress": "return /([0-9])/i.test(event.key)"
    },
    model: {
      value: _vm.filter.numbers.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.numbers, "search", $$v);
      },
      expression: "filter.numbers.search"
    }
  })], 1), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_vm.api.fetch_numbers.send ? [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-12px"
  }, _vm._l(3, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset"
    }, [_c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "24px",
        "width": "180px"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "40px",
        "width": "40px",
        "border-radius": "50%"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "45px",
        "width": "180px"
      }
    })]);
  }), 0), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-12px"
  }, _vm._l(3, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset"
    }, [_c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "24px",
        "width": "180px"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "40px",
        "width": "40px",
        "border-radius": "50%"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "45px",
        "width": "180px"
      }
    })]);
  }), 0)] : _vm._e(), !_vm.api.fetch_numbers.send && _vm.numbers.length == 0 ? [_c('div', {
    staticClass: "latestGreyBox-heading-main text-center"
  }, [_vm._v("Available numbers")]), _c('div', {
    staticClass: "d-flex flex-column align-items-center"
  }, [_c('vb-icon', {
    staticClass: "mt-3",
    attrs: {
      "icon": "AssignTelePhoneNumbers-addNumbersIcon-icon",
      "height": "99px",
      "width": "102px"
    }
  }), _c('p', {
    staticClass: "mt-3 mb-4 w-50 text-center"
  }, [_vm._v(" It seems you don't have any telephone numbers, You can purchase one or make one available. ")]), _c('button', {
    staticClass: "newButton mb-3",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-AddNumbersModalFromAssignNumber`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-testPart"
  }, [_vm._v("Add telephone number")])], 1)], 1)] : _vm._e(), _vm.numbers.length > 0 ? [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Available numbers")]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-12px"
  }, _vm._l(_vm.filterNumbers, function (number, index) {
    return _c('div', {
      key: index,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset"
    }, [_c('b-form-checkbox', {
      staticClass: "dialer_full_width_checkbox circle_btn_checkbox circle_btn_onRight p-0 makeCheckBoxesSquare less-padding leastPadding",
      class: _vm.selected.numbers.indexOf(number) > -1 ? 'active' : '',
      attrs: {
        "checked": _vm.selected.numbers.indexOf(number) > -1
      },
      on: {
        "change": function ($event) {
          _vm.selected.numbers.indexOf(number) > -1 ? _vm.selected.numbers.splice(_vm.selected.numbers.indexOf(number), 1) : _vm.selected.numbers.push(number);
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('img', {
      staticClass: "country-img mr-16px",
      attrs: {
        "width": "35px",
        "src": _vm._f("flag_icon")(_vm._f("number_formater")(number))
      }
    }), _c('span', {
      staticClass: "latestGreyBox-descriptionText mt-0"
    }, [_vm._v(_vm._s(_vm._f("number_formater")(number)))])])])], 1);
  }), 0)] : _vm._e(), _vm.filterNumbers.length == 0 && Object.keys(_vm.diffAccountAssignNumbers).length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.fetch_numbers.send ? '' : 'No record',
      "design": 3
    }
  }) : _vm._e()], 2), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [Object.keys(_vm.diffAccountAssignNumbers).length > 0 ? [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Unavailable numbers")]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-12px"
  }, _vm._l(_vm.diffAccountAssignNumbers, function (data, number) {
    return _c('div', {
      key: number,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset UnavailableNumbers-row"
    }, [_c('div', {
      staticClass: "UnavailableNumbers-left"
    }, [_c('div', {
      staticClass: "d-flex align-items-center minWidth220px maxWidth220px"
    }, [_c('img', {
      staticClass: "country-img mr-16px",
      attrs: {
        "width": "35px",
        "src": _vm._f("flag_icon")(_vm._f("number_formater")(number))
      }
    }), _c('span', {
      staticClass: "latestGreyBox-descriptionText mt-0"
    }, [_vm._v(_vm._s(_vm._f("number_formater")(number)))])])]), _c('div', {
      staticClass: "assignToMiddleSection UnavailableNumbers-middle"
    }, [_c('vb-icon', {
      staticClass: "mb-0",
      attrs: {
        "icon": "AssignTelePhoneNumbers-arrow-icon",
        "height": "35px",
        "width": "35px"
      }
    })], 1), _c('div', {
      staticClass: "UnavailableNumbers-right"
    }, [data.members.length > 0 ? _c('div', [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('Info', {
      attrs: {
        "id": data.members[0].cg_item,
        "is_blf": false
      },
      scopedSlots: _vm._u([{
        key: "sub-info",
        fn: function () {
          return [data.members.length > 1 ? _c('small', {
            staticClass: "cursor_pointer_no_hover",
            on: {
              "click": function ($event) {
                _vm.conditions.more = _vm.conditions.more == data.accountcode ? '' : data.accountcode;
              }
            }
          }, [_vm._v(" " + _vm._s(_vm.conditions.more == data.accountcode ? '-' : '+') + " " + _vm._s(data.members.length - 1) + " more ")]) : _vm._e()];
        },
        proxy: true
      }], null, true)
    })], 1), _vm.conditions.more == data.accountcode ? [_vm._l(data.members.filter(function (item, index) {
      return index > 0;
    }), function (member) {
      return [_c('div', {
        key: member.cg_item,
        staticClass: "mt-2"
      }, [_c('Info', {
        attrs: {
          "id": member.cg_item,
          "is_blf": false
        }
      })], 1)];
    })] : _vm._e()], 2) : _c('Info', {
      attrs: {
        "id": data.accountcode,
        "is_blf": false
      }
    })], 1)]);
  }), 0)] : _vm._e(), _vm.api.fetch_numbers.send ? [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-12px"
  }, _vm._l(3, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset"
    }, [_c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "24px",
        "width": "180px"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "40px",
        "width": "40px",
        "border-radius": "50%"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "45px",
        "width": "180px"
      }
    })]);
  }), 0), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-12px"
  }, _vm._l(3, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset"
    }, [_c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "24px",
        "width": "180px"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "40px",
        "width": "40px",
        "border-radius": "50%"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "45px",
        "width": "180px"
      }
    })]);
  }), 0)] : _vm._e(), !_vm.api.fetch_numbers.send && Object.keys(_vm.diffAccountAssignNumbers).length == 0 ? [_c('div', {
    staticClass: "latestGreyBox-heading-main text-center"
  }, [_vm._v("Unavailable numbers")]), _c('div', {
    staticClass: "d-flex flex-column align-items-center"
  }, [_c('vb-icon', {
    staticClass: "mt-3",
    attrs: {
      "icon": "AssignTelePhoneNumbers-addNumbersIcon-icon",
      "height": "99px",
      "width": "102px"
    }
  }), _c('p', {
    staticClass: "mt-3 mb-4 w-50 text-center"
  }, [_vm._v(" It seems you don't have any telephone numbers, You can purchase one or un assign an existing one. ")]), _c('button', {
    staticClass: "newButton mb-3",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-AddNumbersModalFromAssignNumber`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-testPart"
  }, [_vm._v("Add telephone number")])], 1)], 1)] : _vm._e()], 2)])]), _c('AddNumbersModal', {
    attrs: {
      "modalName": `${_vm._uid}-AddNumbersModalFromAssignNumber`
    },
    on: {
      "interface": function ($event) {
        return _vm.getNumbersDebounce();
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }