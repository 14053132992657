<template>
  <fragment>
    <ChatPanel v-show="$store.getters.getIsMobile ? !channel_id : true" ref="panel" :channelsTab="channelsTab" :channel_id="channel_id" @change-channel-id="channel_id=$event" />
    <Chatbox v-show="$store.getters.getIsMobile ? channel_id : true" :channel_id="channel_id" @change-channel-id="channel_id=$event" @back="channel_id=''" ref="box" />
  </fragment>
</template>

<script>
import ChatPanel from "./Panel.vue";
import Chatbox from "./Box/index";
import { Fragment } from "vue-fragment";
import { events } from '../../utils';
export default {
  name: "ChatView",
  data(){
    return {
      channel_id: '',
    }
  },
  components: {
    ChatPanel,
    Chatbox,
    Fragment,
  },
  computed: {
    channelsTab(){
      if(this.$parent.$parent.$refs.common_chat){
        return this.$parent.$parent.$refs.common_chat.channelsTab
      }
      return []
    },
  },
  methods: {
    onChatOpen(event){
      const { channel_id } = event || {}
      if(typeof channel_id == 'string'){
        this.channel_id=channel_id
      }
    },
  },
  created(){
    this.$root.$on(events.open_chat,this.onChatOpen)
  },
  beforeDestroy(){
    this.$root.$off(events.open_chat,this.onChatOpen)
  },
};
</script>

<style lang="scss">
// .content-area {
//   .panel {
//     &.chat-panel {
//       min-width: 350px;
//       width: 350px;
//       border-radius: 0px !important;
//       border-top-left-radius: 24px !important;
//       border-bottom-left-radius: 24px !important;
//     }
//   }
//   .chat-center-area {
//     width: 100%;
//     max-width: 100% !important;
//     padding-left: 0px !important;
//     background-color: #191919;
//     border-top-right-radius: 24px;
//     border-bottom-right-radius: 24px;
//     .chat-info {
//       border-radius: 0px !important;
//       margin-bottom: 0px !important;
//       background-color: transparent !important;
//       padding: 10px !important;
//       border-bottom: 1px #666666 solid;
//     }
//     .chat-box-section {
//       background-color: transparent !important;
//       border-radius: 0px !important;
//       padding: 5px;
//       .chat-box {
//         width: 100%;
//         max-width: 100% !important;
//         .chat-box-bill {
//           .dialer_show_emoji {
//             background-color: #2f2d30;
//           }
//         }
//       }
//       .chat-actions {
//         width: 100%;
//         max-width: 100% !important;
//         padding: 5px;
//         .dialer-chatBox-typingMessage-area {
//           background-color: #2f2d30 !important;
//           &.important_message_bill {
//             background-color: rgba(219, 53, 69, 0.2) !important;
//           }
//           .dialer-chatBox-actionIcon-area {
//             padding: 0px;
//             padding-bottom: 5px;
//             // .dialer-textArea {
//             //   max-width: inherit;
//             // }
//           }
//           .dialer-uploadableFiles-showCase {
//             .bill-file-main {
//               background-color: #191919;
//             }
//             .bill-add-file {
//               background-color: #191919;
//               padding: 0px;
//             }
//           }
//           .dialer-chatBox-actionIcon-area { 
//             background-color: transparent !important;
//             form {
//               textarea {
//                 background-color: transparent !important;
//               }
//             }
//           }
//         }
//         .chat-actions-sendIcon {
//           .submit-icon {
//             svg {
//               path {
//                 &:first-child {
//                   fill: #2f2d30 !important;
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }

// .content-area {
//   .panel {
//     &.chat-panel {
//       background-color: #e9f2ff;
//     }
//   }
// }

// .content-area {
//   .panel {
//     &.chat-panel {
//       min-width: 350px;
//       width: 350px;
//       border-radius: 0px !important;
//       border-top-left-radius: 24px !important;
//       border-bottom-left-radius: 24px !important;
//       background-color: #e9f2ff;
//     }
//   }
//   .chat-center-area {
//     width: 100%;
//     max-width: 100% !important;
//     padding-left: 0px !important;
//     background-color: #ffffff;
//     border-top-right-radius: 24px;
//     border-bottom-right-radius: 24px;
//     .chat-info {
//       border-radius: 0px !important;
//       margin-bottom: 0px !important;
//       background-color: transparent !important;
//       padding: 10px !important;
//     }
//     .chat-box-section {
//       background-color: transparent !important;
//       border-radius: 0px !important;
//       padding: 5px;
//       .chat-box {
//         width: 100%;
//         max-width: 100% !important;
//       }
//       .chat-actions {
//         width: 100%;
//         max-width: 100% !important;
//         padding: 5px;
//         .dialer-chatBox-typingMessage-area {
//           background-color: #e9f2ff !important;
//           &.important_message_bill {
//             background-color: rgba(219, 53, 69, 0.2) !important;
//           }
//           .dialer-chatBox-actionIcon-area {
//             padding: 0px;
//             padding-bottom: 5px;
//           }
//           .dialer-uploadableFiles-showCase {
//             .bill-file-main {
//               background-color: #e9f2ff;
//             }
//             .bill-add-file {
//               background-color: #e9f2ff;
//               padding: 0px;
//             }
//           }
//           .dialer-chatBox-actionIcon-area { 
//             background-color: transparent !important;
//             form {
//               textarea {
//                 background-color: transparent !important;
//               }
//             }
//           }
//         }
//         .chat-actions-sendIcon {
//           .submit-icon {
//             svg {
//               path {
//                 &:first-child {
//                   fill: #e9f2ff !important;
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
// }
// }
</style>
