<template>
  <modal
    :name="modalName"
    class="dialer_animation right_side_popup m-w-500 EditNumbersModal min-width-50percent-modal linkCallModal"
    width="50%"
    height="auto"
    :scrollable="true"
    @before-open="onBeforeOpen($event)"
    @before-close="onBeforeClose()"
  >
    <div>
      <div class="dialer-edit-header flex-column align-items-start">
        <div class="d-flex justify-content-between align-items-center w-100">
          <div>
            <h2 class="dialer-settings-title newer">
              <vb-svg @click="$modal.hide(modalName)" :disabled="api.link_call.send" class="cursor_pointer_no_hover" name="leftLongArrow-icon" width="20" height="12" viewBox="0 0 30 16" stroke-width="1" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" />
              Link call
            </h2>
          </div>
          <div class="dialer-edit-actions">
            <!-- <b-button @click="$modal.hide(modalName)" :disabled="api.link_call.send" class="newCloseButton">
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </b-button> -->
            <b-button @click="linkCall()" :disabled="api.link_call.send" class="newDoneButton">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                Done
              </span>
            </b-button>
          </div>
        </div>
      </div>
      <div class="latestGreyBox-9-9-2023 mt-20px">
        <b-form-radio-group v-model="selected.cdr_id" :disabled="api.link_call.send">
          <b-form-radio class="squreRadioButtons withCallLogInIt" v-for="(call_log, index) in calllogs" :key="index" :value="call_log.callerid">
            <Item1 :call="call_log" :conditions="{ actions: false }" />
          </b-form-radio>
        </b-form-radio-group>
        <vb-no-record v-if="calllogs.length==0" :design="3" :text="api.get_call_logs.send?'':'No Calls'">
          <!-- <vb-loading v-if="api.get_call_logs.send" slot="loading" /> -->
          <div class="w-100" v-if="api.get_call_logs.send" slot="loading">
            <div class="w-100" v-for="n in 6" :key="n.id">
              <div :class="`latestShimmerDesign w-75 mt-20px ${n%2 == 1 ? 'ml-auto' : 'mr-auto'}`" style="height:75px;"></div>
            </div>
          </div>
        </vb-no-record>
      </div>
    </div>
  </modal>
</template>

<script>
import { VOIP_API } from '@/utils'
import { mapGetters } from 'vuex'
import Item1 from '@/components/Home/CallActivity/Item1.vue'
export default {
  name: 'LinkedCallModal',
  components: { 
    Item1 
  },
  props: {
    modalName: {
      type: String,
      default: 'LinkedCall'
    },
  },
  data() {
    return {
      api: {
        get_call_logs: this.$helperFunction.apiInstance(),
        link_call: this.$helperFunction.apiInstance(),
      },
      response: {
        call_logs: [],
      },
      data: {
        task_id: '',
      },
      selected: {
        cdr_id: '',
      }
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser'
    ]),
    calllogs(){
      return this.response.call_logs.filter(i=>!!i.callerid)
    },
  },
  methods: {
    onBeforeOpen(event) {
      this.data.task_id=event?.params?.task_id ?? ''
      this.selected.cdr_id=event?.params?.cdr_id ?? ''
      this.getCallLogs()
    },
    onBeforeClose() {
      this.api.get_call_logs.reset()
      this.api.link_call.reset()
      this.response.call_logs=[]
      this.data.task_id=''
      this.selected.cdr_id=''
    },
    async getCallLogs() {
      if(this.api.get_call_logs.send) return;
      try {
        this.api.get_call_logs.send=true
        const { data: { data } } = await VOIP_API.endpoints.call_activity.list(this.getCurrentUser.account, {
          page: 1,
        })
        this.response.call_logs=data ?? []
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.get_call_logs.send=false
      }
    },
    async linkCall(){
      if(!this.selected.cdr_id || this.api.link_call.send) return;
      try {
        this.api.link_call.send=true
        await VOIP_API.endpoints.tasklist.call_log.updateCallLog({
          task_id: this.data.task_id,
          cdr_id: this.selected.cdr_id,
          accountcode: this.getCurrentUser.account,
          uid: this.getCurrentUser.uid,
        })
        this.$emit('updated',{
          cdr_id: this.selected.cdr_id
        })
        this.$modal.hide(this.modalName)
        this.appNotify({
          message: 'Successfully Changed',
          type: 'success',
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.link_call.send=false
      }
    },
  },
}
</script>

<style>

</style>




<style lang="scss">
  .squreRadioButtons{
    &.withCallLogInIt{
      &.custom-control.custom-radio{
        padding: 0px;
        margin-bottom: 16px;
        position: relative;
        .custom-control-input:checked ~ .custom-control-label::before{
          background-color: white;
        }
        .custom-control-input:checked ~ .custom-control-label::after{
          background-image: none;
          opacity: 1;
        }
        & > .custom-control-label{
          position: relative;
          width: 100%;
          padding-left: 40px;
          opacity: 0;
          &:has(.visibleTransition){
            opacity: 1;
          }
          &::before{
            left: 0px;
            top: 8px;
            @include border-radius(3px);
            height: 24px;
            width: 24px;
            border: 1px solid black;
          }
          &::after{
            left: 0px;
            top: 8px;
            @include border-radius(3px);
            width: 1.1rem;
            height: 1.1rem;
            // border: 1px solid black; 
            background-color: #000000;   
            margin-top: 3px;
            margin-left: 3px;
            opacity: 0;
          }
          .callActivity-Item-v2-container{
            width: 100%;
            margin-bottom: 0px;
            &:hover{
              .callActivity-Item-v2-inner-top{
                .last-section{
                  .time{
                    & > span{
                      opacity: 1;
                    }
                  }
                }
              }
            }
          } 
        }
      }
    }
  }
  // .linkCallModal.vm--container{
  //   & > .vm--modal{

  //   }
  // }
</style>