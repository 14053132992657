var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._v(" Welcome Greeting "), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100 mt-3"
  }, [_c('vb-audio-player', {
    staticClass: "audioForMobile",
    attrs: {
      "src": _vm._f("ivrSound")(_vm.ivr),
      "design": 'minimum'
    }
  }), _c('b-icon', {
    staticClass: "cursor_pointer ml-auto",
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "icon": "pencil-fill",
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.edit();
      }
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }