<template>
  <div id="right-side-bar-334" :class="`sidebar right-sidebar position-relative ${toggleRadius?'zeroRadius':''}`">
    <template v-if="!$store.getters.getIsMobile && !$store.getters.isIntegration">
      <div class="rightSideBar-inner">
        <template v-if="!$store.state.css_break_points.b3_rightSideBar">
          <nav class="nav nav-menu">
            <ul class="list-unstyled">
              <li class="audioSettingIcon-container mt-8px">
                <a id="" @click="$modal.show('AudioSettingModal')" :class="`w-100 d-flex align-items-center justify-content-center`">
                  <vb-icon id="soundSetting-icon" class="rightSidebar_icon" icon="soundSetting-icon" width="50" height="50" />
                </a>
                <vb-icon v-if="media_devices_setting.isJabra" icon="jabra-v2-icon" class="jabra-icon v2 rightSidebar_icon position-absolute" width="50" height="20" />
                <vb-icon v-if="media_devices_setting.isLenovo" icon="lenovo-icon" class="jabra-icon v2 rightSidebar_icon position-absolute" width="50" height="20" />
                <b-popover target="soundSetting-icon" placement="left" content="Audio Settings" triggers="hover focus" />
                <!-- <b-checkbox v-if="isElectron" switch v-model="$store.state.electron.app_view" value="mob" unchecked-value="web" /> -->
                <div 
                  v-if="!$store.state.sip.state.is_connected" 
                  v-b-popover.hover="'Sip Socket OR Web Socket not connected'" 
                  class="indicator-light bill-223344-reload"
                >
                  <b-icon icon="arrow-clockwise" @click="reConnectUserAgent()" />
                </div>
                <div 
                  v-if="!$store.state.sip.state.is_register" 
                  style="background-color: green;right: 20px;" 
                  v-b-popover.hover="'Sip User is not register'" 
                  class="indicator-light bill-223344-reload"
                >
                  <b-icon icon="arrow-clockwise" @click="reRegisterUserAgent()" />
                </div>
              </li>
              <li class="mobileViewCheckBox" v-if="isElectron">
                <span class="mobileViewCheckBox-text">Minimize</span>
                <b-checkbox class="minimizedSwitch" v-if="isElectron" switch v-model="$store.state.electron.app_view" value="mob" unchecked-value="web" />
              </li>
              <li>
                <a id="tooltip-notifications" @mouseover="hoverPanel = 'Notifications'" @mouseout="hoverPanel = ''" @click="selectedPanel = 'Notifications'" :class="`w-100 d-flex align-items-center justify-content-center ${selectedPanel === 'Notifications' || hoverPanel === 'Notifications' ? 'active' : ''}`">
                  <vb-icon class="Notifications-icon rightSidebar_icon" icon="Notifications-icon" width="50" height="50" />
                </a>
                <b-popover target="tooltip-notifications" placement="left" content="Notifications" triggers="hover focus" />
              </li>
            </ul>
          </nav>
          <nav class="nav nav-menu">
            <ul class="list-unstyled">
              <li app-tour-web-speed-dials>
                <a id="tooltip-Speed Dials" @mouseover="hoverPanel = 'SpeedDials'" @mouseout="hoverPanel = ''" @click="selectedPanel = 'SpeedDials'" :class="`w-100 d-flex align-items-center justify-content-center ${selectedPanel === 'SpeedDials' || hoverPanel === 'SpeedDials' ? 'active' : ''}`">
                  <vb-icon class="speedDial-icon speedDial-icon-v3 rightSidebar_icon" icon="speedDial-icon-v3" width="50" height="50" />
                </a>
                <b-popover target="tooltip-Speed Dials" placement="left" content="Favourites" triggers="hover focus" />
              </li>
              <li app-tour-web-dashboard-users>
                <a id="tooltip-Users" @mouseover="hoverPanel = 'Users'" @mouseout="hoverPanel = ''" @click="selectedPanel = 'Users'" :class="`w-100 d-flex align-items-center justify-content-center ${selectedPanel === 'Users' || hoverPanel === 'Users' ? 'active' : ''}`">
                  <vb-icon class="users-icon rightSidebar_icon" icon="users-icon" width="50" height="50" />
                </a>
                <b-popover target="tooltip-Users" placement="left" content="Users" triggers="hover focus" />
              </li>
              <li app-tour-web-teams>
                <a id="tooltip-Departments" @mouseover="hoverPanel = 'Departments'" @mouseout="hoverPanel = ''" @click="selectedPanel = 'Departments'" :class="`w-100 d-flex align-items-center justify-content-center ${selectedPanel === 'Departments' || hoverPanel === 'Departments' ? 'active' : ''}`">
                  <vb-icon class="departments-icon rightSidebar_icon" icon="chat-rightSideBarTaemIcon" width="50" height="50" />
                </a>
                <b-popover target="tooltip-Departments" placement="left" content="Teams" triggers="hover focus" />
              </li>
              <li app-tour-web-dashboard-addressbook>
                <a id="tooltip-Addressbooks" @mouseover="hoverPanel = 'Addressbooks'" @mouseout="hoverPanel = ''" @click="selectedPanel = 'Addressbooks'" :class="`w-100 d-flex align-items-center justify-content-center ${selectedPanel === 'Addressbooks' || hoverPanel === 'Addressbooks' ? 'active' : ''}`">
                  <vb-icon class="addressBook-icon rightSidebar_icon" icon="addressBook-icon" width="50" height="50" />
                </a>
                <b-popover target="tooltip-Addressbooks" placement="left" content="Address book" triggers="hover focus" />
              </li>
            </ul>
          </nav>
          <nav class="nav nav-menu">
            <ul class="list-unstyled">
              <!-- <li v-if="!isEmpty($store.state.calls.videoCalls)" class="mb-2">
                <a id="tooltip-Video-Calls" @click="videocalls()" :class="`w-100 d-flex align-items-center justify-content-center ${activeTab==='video-call'?'active':''}`">
                  <vb-icon class="speedDial-icon rightSidebar_icon inVideo-icon" icon="duringJitsiCall-backToVideoCall-icon" width="50" height="50" />
                </a>
                <b-popover target="tooltip-Video-Calls" placement="left" content="Video Calls" triggers="hover focus" />
              </li> -->
              <li app-tour-web-support-ticket>
                <a id="tooltip-Support-Team" @click="$modal.show('KbInfoModal')" class="w-100 d-flex align-items-center justify-content-center">
                  <vb-icon class="rightSidebar_icon" icon="helpSideBar-icon-v2" width="50" height="50" />
                </a>
                <b-popover target="tooltip-Support-Team" placement="left" content="Help" triggers="hover focus" />
              </li>
              <li>
                <div v-if="!$store.getters.isIntegration" @click="feed_back = true" class="betaInRightSideBar position-relative" style="cursor:pointer">
                  <!-- !isInCall &&  -->
                  <div id="popover-beta-bill" class="mr-1">Beta</div>
                  <b-popover target="popover-beta-bill" triggers="hover" placement="left">
                    <div class="rightSideBArTimerText b-0 text-center" @click="sipRegister()" v-b-popover.hover.left="'Register'">Register</div>
                    <div class="rightSideBArTimerText b-0 text-center" @click="sipUnRegister()" v-b-popover.hover.left="'Un Register'">Un Register</div>
                    <div class="rightSideBArTimerText b-0 text-center" v-b-popover.hover.left="'Register Notification'">{{$store.state.sip.state.sip_register_notification}}</div>
                    <div class="rightSideBArTimerText b-0 text-center" v-b-popover.hover.left="'Last Register'">{{$store.state.sip.phone.last_register_time}}</div>
                    <div>
                      <div style="height: 100px;overflow-y: auto">
                        <div v-for="(log,index) in $store.state.sip.phone.register_logs" :key="index">
                          {{ log.register ? 'Register' : 'Un Register' }}
                          {{ log.time }}
                        </div>
                      </div>
                      <div style="height: 100px;overflow-y: auto">
                        <div v-for="(data,index) in $store.state.sip.phone.reconnectLogs" :key="index">
                          {{ data.time }}
                          {{ data.attempt }}
                          {{ data.delay }}
                        </div>
                      </div>
                    </div>
                    <!-- <div>
                      <div style="height: 100px;overflow-y: auto">
                        <div v-for="servce_status in $store.state.common.pending_services" :key="servce_status.id">
                          {{ servce_status.service }}
                        </div>
                      </div>
                      <div style="height: 100px;overflow-y: auto">
                        <div v-for="(data,index) in $store.state.sip.phone.reconnectLogs" :key="index">
                          {{ data.time }}
                          {{ data.attempt }}
                          {{ data.delay }}
                        </div>
                      </div>
                    </div> -->
                  </b-popover>
                  <b-button v-if="isElectron" @click="reload()" variant="link" class="p-0 electronReloadButton">
                    <vb-icon icon="latest-reload-icon" width="16px" height="16px" />
                  </b-button>
                </div>
              </li>
            </ul>
          </nav>
          <div v-if="feed_back" :class="['betaInRightSideBar-wala new-error-template position-absolute tun',feed_back ? ' width' : '']">
            <div class="error-message-new">
              <b-icon icon="x" @click="feed_back=false" font-scale="1.5" class="cross-icon"/>
              <div class="beta-container  z-index-0-imp ">
                <div class="beta-text">
                  <div class="d-flex">
                    <span class="beta-button">Beta</span>
                    <span>This is a new service. </span>
                  </div>
                  <span class="mt-1">
                    Your
                    <a @click="$modal.show('FeedBackCommonComponentModal'),feed_back=false" class="" style="color:black;text-decoration:underline">feedback</a>
                    will help us improve it.
                    <!-- <b class="mt-1">{{ $store.state.common.current_subscription | get_property('userCurrentPlanFeatures.feature_group.label') }}</b> -->
                  </span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="$store.state.css_break_points.b3_rightSideBar">
          <nav class="nav nav-menu">
            <ul class="list-unstyled">
              <li class="audioSettingIcon-container mt-8px">
                <a id="" @click="$modal.show('AudioSettingModal')" :class="`w-100 d-flex align-items-center justify-content-center`">
                  <vb-icon id="soundSetting-icon" class="rightSidebar_icon" icon="soundSetting-icon" width="50" height="50" />
                </a>
                <vb-icon v-if="media_devices_setting.isJabra" icon="jabra-v2-icon" class="jabra-icon v2 rightSidebar_icon position-absolute" width="50" height="20" />
                <vb-icon v-if="media_devices_setting.isLenovo" icon="lenovo-icon" class="jabra-icon v2 rightSidebar_icon position-absolute" width="50" height="20" />
                <b-popover target="soundSetting-icon" placement="left" content="Audio Settings" triggers="hover focus" />
                <div 
                  v-if="!$store.state.sip.state.is_connected" 
                  v-b-popover.hover="'Sip Socket OR Web Socket not connected'" 
                  class="indicator-light bill-223344-reload"
                >
                  <b-icon icon="arrow-clockwise" @click="reConnectUserAgent()" />
                </div>
                <div 
                  v-if="!$store.state.sip.state.is_register" 
                  style="background-color: green;right: 20px;" 
                  v-b-popover.hover="'Sip User is not register'" 
                  class="indicator-light bill-223344-reload"
                >
                  <b-icon icon="arrow-clockwise" @click="reRegisterUserAgent()" />
                </div>
              </li>
              <li class="mobileViewCheckBox" v-if="isElectron">
                <!-- <span class="mobileViewCheckBox-text">{{$store.state.electron.app_view ? 'Maximize' : 'Minimize'}}</span> -->
                <span class="mobileViewCheckBox-text">Minimize</span>
                <b-checkbox class="minimizedSwitch" v-if="isElectron" switch v-model="$store.state.electron.app_view" value="mob" unchecked-value="web" />
              </li>
              <li>
                <a id="tooltip-notifications" @mouseover="hoverPanel = 'Notifications'" @mouseout="hoverPanel = ''" @click="selectedPanel = 'Notifications'" :class="`w-100 d-flex align-items-center justify-content-center ${selectedPanel === 'Notifications' || hoverPanel === 'Notifications' ? 'active' : ''}`">
                  <vb-icon class="Notifications-icon rightSidebar_icon" icon="Notifications-icon" width="50" height="50" />
                </a>
                <b-popover target="tooltip-notifications" placement="left" content="Notifications" triggers="hover focus" />
              </li>
            </ul>
          </nav>
          <nav class="nav nav-menu">
            <ul class="list-unstyled">
              <li app-tour-web-dashboard-users>
                <a id="tooltip-Users" @mouseover="hoverPanel = 'Users'" @mouseout="hoverPanel = ''" @click="selectedPanel = 'Users'" :class="`w-100 d-flex align-items-center justify-content-center ${selectedPanel === 'Users' || hoverPanel === 'Users' ? 'active' : ''}`">
                  <vb-icon class="users-icon rightSidebar_icon" icon="users-icon" width="50" height="50" />
                </a>
                <b-popover target="tooltip-Users" placement="left" content="Users" triggers="hover focus" />
              </li>
              <!-- <li app-tour-web-dashboard-users class="mb-2">
                <a id="tooltip-drawer" @mouseover="hoverPanel = 'App drawer'" @mouseout="hoverPanel = ''" :class="`w-100 d-flex align-items-center justify-content-center sidebarBurger-container`">
                  <vb-icon @click="conditions.show_more=!conditions.show_more" icon="rightSideBar-hamBurger-icon" width="50" height="50" class="rightSideBar-hamBurger-icon"/>
                </a>
                <b-popover target="tooltip-drawer" placement="left" content="App drawer" triggers="hover focus" />
              </li> -->
              <li>
                <b-dropdown dropleft class="VerticalAppDrawerDD-inRightSideBar" no-caret>
                  <template #button-content>
                    <vb-icon icon="rightSideBar-hamBurger-icon" width="50" height="50" class="rightSideBar-hamBurger-icon"/>
                  </template>
                  <!-- <b-dropdown-item 
                    @mouseover="hoverPanel = 'Notifications'" 
                    @mouseout="hoverPanel = ''" 
                    @click="selectedPanel = 'Notifications'"
                  >
                    <vb-icon icon="Notifications-icon" width="50" height="50" />
                    <span>Notifications</span>
                  </b-dropdown-item> -->
                  <b-dropdown-item 
                    @mouseover="hoverPanel = 'SpeedDials'" 
                    @mouseout="hoverPanel = ''" 
                    @click="selectedPanel = 'SpeedDials'"
                  >
                    <vb-icon icon="speedDial-icon-v3" width="50" height="50" />
                    <span>Favourites</span>
                  </b-dropdown-item>
                  <b-dropdown-item 
                    @mouseover="hoverPanel = 'Departments'" 
                    @mouseout="hoverPanel = ''" 
                    @click="selectedPanel = 'Departments'"
                  >
                    <vb-icon icon="chat-rightSideBarTaemIcon" width="50" height="50" />
                    <span>Teams</span>
                  </b-dropdown-item>
                  <b-dropdown-item 
                    @mouseover="hoverPanel = 'Addressbooks'" 
                    @mouseout="hoverPanel = ''" 
                    @click="selectedPanel = 'Addressbooks'"
                  >
                    <vb-icon icon="addressBook-icon" width="50" height="50" />
                    <span>Address book</span>
                  </b-dropdown-item>
                </b-dropdown>
              </li>
            </ul>
          </nav>
          <nav class="nav nav-menu">
            <ul class="list-unstyled">
              <li app-tour-web-support-ticket>
                <a id="tooltip-Support-Team" @click="$modal.show('KbInfoModal')" class="w-100 d-flex align-items-center justify-content-center">
                  <vb-icon class="rightSidebar_icon" icon="helpSideBar-icon-v2" width="50" height="50" />
                </a>
                <b-popover target="tooltip-Support-Team" placement="left" content="Help" triggers="hover focus" />
              </li>
              <li>
                <div v-if="!$store.getters.isIntegration" @click="feed_back = true" class="betaInRightSideBar position-relative" style="cursor:pointer">
                  <div id="popover-beta-bill">Beta</div>
                  <b-popover target="popover-beta-bill" triggers="hover" placement="left">
                    <div class="rightSideBArTimerText b-0 text-center" @click="sipRegister()" v-b-popover.hover.left="'Register'">Register</div>
                    <div class="rightSideBArTimerText b-0 text-center" @click="sipUnRegister()" v-b-popover.hover.left="'Un Register'">Un Register</div>
                    <div class="rightSideBArTimerText b-0 text-center" v-b-popover.hover.left="'Register Notification'">{{$store.state.sip.state.sip_register_notification}}</div>
                    <div class="rightSideBArTimerText b-0 text-center" v-b-popover.hover.left="'Last Register'">{{$store.state.sip.phone.last_register_time}}</div>
                    <div>
                      <div style="height: 100px;overflow-y: auto">
                        <div v-for="(log,index) in $store.state.sip.phone.register_logs" :key="index">
                          {{ log.register ? 'Register' : 'Un Register' }}
                          {{ log.time }}
                        </div>
                      </div>
                      <div style="height: 100px;overflow-y: auto">
                        <div v-for="(data,index) in $store.state.sip.phone.reconnectLogs" :key="index">
                          {{ data.time }}
                          {{ data.attempt }}
                          {{ data.delay }}
                        </div>
                      </div>
                    </div>
                    <!-- <div>
                      <div style="height: 100px;overflow-y: auto">
                        <div v-for="servce_status in $store.state.common.pending_services" :key="servce_status.id">
                          {{ servce_status.service }}
                        </div>
                      </div>
                      <div style="height: 100px;overflow-y: auto">
                        <div v-for="(data,index) in $store.state.sip.phone.reconnectLogs" :key="index">
                          {{ data.time }}
                          {{ data.attempt }}
                          {{ data.delay }}
                        </div>
                      </div>
                    </div> -->
                  </b-popover>
                </div>
              </li>
            </ul>
          </nav>
          <div v-if="feed_back" :class="['betaInRightSideBar-wala new-error-template position-absolute tun',feed_back ? ' width' : '']">
            <div class="error-message-new">
              <b-icon icon="x" @click="feed_back=false" font-scale="1.5" class="cross-icon"/>
              <div class="beta-container  z-index-0-imp ">
                <div class="beta-text">
                  <div class="d-flex">
                    <span class="beta-button">Beta</span>
                    <span>This is a new service. </span>
                  </div>
                  <span class="mt-1">
                    Your
                    <a @click="$modal.show('FeedBackCommonComponentModal'),feed_back=false" class="" style="color:black;text-decoration:underline">feedback</a>
                    will help us improve it.
                    <!-- <b class="mt-1">{{ $store.state.common.current_subscription | get_property('userCurrentPlanFeatures.feature_group.label') }}</b> -->
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!-- <div v-if="conditions.show_more" class="sub-sidebar justify-content-between py-0">
            <nav class="nav nav-menu">
              <ul class="list-unstyled">
                <li>
                  <div class="back-container" @click="conditions.show_more=!conditions.show_more">
                    Back
                    <b-icon class="ml-1" icon="chevron-right"/>
                  </div>
                </li>
                <li>
                  <a id="tooltip-notifications" @mouseover="hoverPanel = 'Notifications'" @mouseout="hoverPanel = ''" @click="selectedPanel = 'Notifications'" :class="`w-100 d-flex align-items-center justify-content-center ${selectedPanel === 'Notifications' || hoverPanel === 'Notifications' ? 'active' : ''}`">
                    <vb-icon class="Notifications-icon rightSidebar_icon" icon="Notifications-icon" width="50" height="50" />
                  </a>
                  <b-popover target="tooltip-notifications" placement="left" content="Notifications" triggers="hover focus" />
                </li>
              </ul>
            </nav>
            <nav class="nav nav-menu">
              <ul class="list-unstyled">
                <li app-tour-web-speed-dials>
                  <a id="tooltip-Speed Dials" @mouseover="hoverPanel = 'SpeedDials'" @mouseout="hoverPanel = ''" @click="selectedPanel = 'SpeedDials'" :class="`w-100 d-flex align-items-center justify-content-center ${selectedPanel === 'SpeedDials' || hoverPanel === 'SpeedDials' ? 'active' : ''}`">
                    <vb-icon class="speedDial-icon speedDial-icon-v3 rightSidebar_icon" icon="speedDial-icon-v3" width="50" height="50" />
                  </a>
                  <b-popover target="tooltip-Speed Dials" placement="left" content="Favourites" triggers="hover focus" />
                </li>
                <li app-tour-web-teams>
                  <a id="tooltip-Departments" @mouseover="hoverPanel = 'Departments'" @mouseout="hoverPanel = ''" @click="selectedPanel = 'Departments'" :class="`w-100 d-flex align-items-center justify-content-center ${selectedPanel === 'Departments' || hoverPanel === 'Departments' ? 'active' : ''}`">
                    <vb-icon class="departments-icon rightSidebar_icon" icon="chat-rightSideBarTaemIcon" width="50" height="50" />
                  </a>
                  <b-popover target="tooltip-Departments" placement="left" content="Teams" triggers="hover focus" />
                </li>
                <li app-tour-web-dashboard-addressbook>
                  <a id="tooltip-Addressbooks" @mouseover="hoverPanel = 'Addressbooks'" @mouseout="hoverPanel = ''" @click="selectedPanel = 'Addressbooks'" :class="`w-100 d-flex align-items-center justify-content-center ${selectedPanel === 'Addressbooks' || hoverPanel === 'Addressbooks' ? 'active' : ''}`">
                    <vb-icon class="addressBook-icon rightSidebar_icon" icon="addressBook-icon" width="50" height="50" />
                  </a>
                  <b-popover target="tooltip-Addressbooks" placement="left" content="Address book" triggers="hover focus" />
                </li>
              </ul>
            </nav>
            <nav class="nav nav-menu"></nav>
          </div> -->
        </template>
      </div>
    </template>
    <!--<template v-if="false">
      <div v-if="!$store.getters.isIntegration" @click="feed_back = true" class="betaInRightSideBar" style="cursor:pointer">
        <!~~ !isInCall &&  ~~>
        <div id="popover-beta-bill">Beta</div>
        <b-popover target="popover-beta-bill" triggers="hover" placement="left">
          <div class="rightSideBArTimerText b-0 text-center" @click="sipRegister()" v-b-popover.hover.left="'Register'">Register</div>
          <div class="rightSideBArTimerText b-0 text-center" @click="sipUnRegister()" v-b-popover.hover.left="'Un Register'">Un Register</div>
          <div class="rightSideBArTimerText b-0 text-center" v-b-popover.hover.left="'Register Notification'">{{$store.state.sip.state.sip_register_notification}}</div>
          <div>
            <div style="height: 100px;overflow-y: auto">
              <div v-for="servce_status in $store.state.common.pending_services" :key="servce_status.id">
                {{ servce_status.service }}
              </div>
            </div>
            <div style="height: 100px;overflow-y: auto">
              <div v-for="(data,index) in $store.state.sip.phone.reconnectLogs" :key="index">
                {{ data.time }}
                {{ data.attempt }}
                {{ data.delay }}
              </div>
            </div>
          </div>
        </b-popover>
      </div>
      <div v-if="feed_back" :class="['betaInRightSideBar-wala new-error-template position-absolute tun',feed_back ? ' width' : '']">
        <div class="error-message-new">
          
          <b-icon icon="x" @click="feed_back=false" font-scale="1.5" class="cross-icon"/>
          <div class="beta-container  z-index-0-imp ">
            <div class="beta-text">
              <div class="d-flex">
                <span class="beta-button">Beta</span>
                <span>This is a new service. </span>
              </div>
              <span class="mt-1">
                Your
                <a @click="$modal.show('FeedBackCommonComponentModal'),feed_back=false" class="" style="color:black;text-decoration:underline">feedback</a>
                will help us improve it.
                <b class="mt-1">{{ $store.state.common.current_subscription | get_property('userCurrentPlanFeatures.feature_group.label') }}</b>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!$store.getters.getIsMobile && !$store.getters.isIntegration" class="dontDisplayInMobile logo position-absolute w-100 text-center py-3">
        <div class="chat-info p-0">
          <nav class="nav nav-menu">
            <ul class="list-unstyled text-center p-2 w-100 m-0 position-relative">
              <template v-if="getIsDialerApplication">
                <li app-tour-web-dashboard-media-device class="mb-0">
                  
                  <a id="" @click="$modal.show('AudioSettingModal')" :class="`w-100 d-flex align-items-center justify-content-center`">
                    <vb-icon id="soundSetting-icon" class="rightSidebar_icon" icon="soundSetting-icon" width="50" height="50" />
                  </a>
                  <vb-icon v-if="media_devices_setting.isJabra" icon="jabra-v2-icon" class="jabra-icon v2 rightSidebar_icon position-absolute" width="50" height="20" />
                  <vb-icon v-if="media_devices_setting.isLenovo" icon="lenovo-icon" class="jabra-icon v2 rightSidebar_icon position-absolute" width="50" height="20" />
                  <b-popover target="soundSetting-icon" placement="left" content="Audio Settings" triggers="hover focus" />
                  
                </li>
              </template>
              <li class="mb-0">
                <div 
                  v-if="!$store.state.sip.state.is_connected" 
                  v-b-popover.hover="'Sip Socket OR Web Socket not connected'" 
                  class="indicator-light bill-223344-reload"
                >
                  <b-icon icon="arrow-clockwise" @click="reConnectUserAgent()" />
                </div>
                <div 
                  v-if="!$store.state.sip.state.is_register" 
                  style="background-color: green;right: 20px;" 
                  v-b-popover.hover="'Sip User is not register'" 
                  class="indicator-light bill-223344-reload"
                >
                  <b-icon icon="arrow-clockwise" @click="reRegisterUserAgent()" />
                </div>
                <!~~ <div v-if="$store.state.sip.phone.next_registration_time">{{ $store.state.sip.phone.next_registration_time | time_audio }}</div>
                <div v-if="$store.state.sip.phone.last_register">{{ $store.state.sip.phone.last_register | fromNow($store.state.sip.phone.next_registration_time) }}</div>
                <div v-if="$store.state.sip.phone.next_register">{{ $store.state.sip.phone.next_register | fromNow($store.state.sip.phone.next_registration_time) }}</div> ~~>
              </li>
            </ul>
            <ul class="list-unstyled text-center p-2 w-100 mx-0 mb-0 mt-3">
              <li class="mb-2">
                <a id="tooltip-notifications" @mouseover="hoverPanel = 'Notifications'" @mouseout="hoverPanel = ''" @click="selectedPanel = 'Notifications'" :class="`w-100 d-flex align-items-center justify-content-center ${selectedPanel === 'Notifications' || hoverPanel === 'Notifications' ? 'active' : ''}`">
                  <vb-icon class="Notifications-icon rightSidebar_icon" icon="Notifications-icon" width="50" height="50" />
                </a>
                <b-popover target="tooltip-notifications" placement="left" content="Notifications" triggers="hover focus" />
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <template v-if="$store.state.css_break_points.b1 && !$store.getters.isIntegration">
        <!~~ <div v-if="!$store.getters.getIsMobile" class="sidebarBurger-container">
          <vb-icon @click="conditions.show_more=!conditions.show_more" icon="rightSideBar-hamBurger-icon" width="50" height="50" class="rightSideBar-hamBurger-icon"/>
        </div> ~~>
        <template v-if="!$store.getters.getIsMobile">
          <nav class="dontDisplayInMobile nav nav-menu pt-3">
            <ul class="list-unstyled text-center p-2 w-100">
              <li app-tour-web-dashboard-users class="mb-2">
                <a id="tooltip-Users" @mouseover="hoverPanel = 'Users'" @mouseout="hoverPanel = ''" @click="selectedPanel = 'Users'" :class="`w-100 d-flex align-items-center justify-content-center ${selectedPanel === 'Users' || hoverPanel === 'Users' ? 'active' : ''}`">
                  <vb-icon class="users-icon rightSidebar_icon" icon="users-icon" width="50" height="50" />
                </a>
                <b-popover target="tooltip-Users" placement="left" content="Users" triggers="hover focus" />
              </li>
              <li app-tour-web-dashboard-users class="mb-2">
                <a id="tooltip-drawer" @mouseover="hoverPanel = 'App drawer'" @mouseout="hoverPanel = ''" :class="`w-100 d-flex align-items-center justify-content-center sidebarBurger-container`">
                  <vb-icon @click="conditions.show_more=!conditions.show_more" icon="rightSideBar-hamBurger-icon" width="50" height="50" class="rightSideBar-hamBurger-icon"/>
                </a>
                <b-popover target="tooltip-drawer" placement="left" content="App drawer" triggers="hover focus" />
              </li>
            </ul>
          </nav>
        </template>
        <div v-if="!$store.getters.getIsMobile && conditions.show_more" class="sub-sidebar">
          <div class="back-container" @click="conditions.show_more=!conditions.show_more">
            Back
            <b-icon class="ml-1" icon="chevron-right"/>
          </div>
          <nav v-if="!$store.getters.getIsMobile" class="dontDisplayInMobile nav nav-menu pt-3">
            <!~~ <ul class="list-unstyled text-center p-2 w-100">
              <li class="mb-2">
                <a id="tooltip-Speed Dials" @mouseover="hoverPanel = 'SpeedDials'" @mouseout="hoverPanel = ''" @click="selectedPanel = 'SpeedDials'" :class="`w-100 d-flex align-items-center justify-content-center ${selectedPanel === 'SpeedDials' || hoverPanel === 'SpeedDials' ? 'active' : ''}`">
                  <vb-icon class="speedDial-icon rightSidebar_icon" icon="speedDial-icon" width="50" height="50" />
                </a>
                <b-popover target="tooltip-Speed Dials" placement="left" content="Speed Dials" triggers="hover focus" />
              </li>
              <li class="mb-2">
                <a id="tooltip-Users" @mouseover="hoverPanel = 'Users'" @mouseout="hoverPanel = ''" @click="selectedPanel = 'Users'" :class="`w-100 d-flex align-items-center justify-content-center ${selectedPanel === 'Users' || hoverPanel === 'Users' ? 'active' : ''}`">
                  <vb-icon class="users-icon rightSidebar_icon" icon="users-icon" width="50" height="50" />
                </a>
                <b-popover target="tooltip-Users" placement="left" content="Users" triggers="hover focus" />
              </li>
              <li class="mb-2">
                <a id="tooltip-Departments" @mouseover="hoverPanel = 'Departments'" @mouseout="hoverPanel = ''" @click="selectedPanel = 'Departments'" :class="`w-100 d-flex align-items-center justify-content-center ${selectedPanel === 'Departments' || hoverPanel === 'Departments' ? 'active' : ''}`">
                  
                  <vb-icon class="departments-icon rightSidebar_icon" icon="chat-rightSideBarTaemIcon" width="50" height="50" />
                </a>
                <b-popover target="tooltip-Departments" placement="left" content="Teams" triggers="hover focus" />
              </li>
              <li class="mb-2">
                <a id="tooltip-Addressbooks" @mouseover="hoverPanel = 'Addressbooks'" @mouseout="hoverPanel = ''" @click="selectedPanel = 'Addressbooks'" :class="`w-100 d-flex align-items-center justify-content-center ${selectedPanel === 'Addressbooks' || hoverPanel === 'Addressbooks' ? 'active' : ''}`">
                  <vb-icon class="addressBook-icon rightSidebar_icon" icon="addressBook-icon" width="50" height="50" />
                </a>
                <b-popover target="tooltip-Addressbooks" placement="left" content="Address book" triggers="hover focus" />
              </li>
            </ul> ~~>
            <ul class="list-unstyled text-center p-2 w-100">
              <template v-if="getIsDialerApplication">
                <!~~<li>
                  <a>
                    <div class="rightSideBArTimerText" @click="sipRegister()" v-b-popover.hover.left="'Register'">Register</div>
                    <div class="rightSideBArTimerText" v-b-popover.hover.left="'Register Notification'">{{$store.state.sip.state.sip_register_notification}}</div>
                    <!~~ <div class="rightSideBArTimerText" v-b-popover.hover.left="'Registration Timer'" v-if="$store.state.sip.phone.next_registration_time">{{ $store.state.sip.phone.next_registration_time | time_audio }}</div>
                    <div class="rightSideBArTimerText" v-b-popover.hover.left="'Last Registered'" v-if="$store.state.sip.phone.last_register">{{ $store.state.sip.phone.last_register | fromNow($store.state.sip.phone.next_registration_time) }}</div>
                    <div class="rightSideBArTimerText" v-b-popover.hover.left="'Next Registration'" v-if="$store.state.sip.phone.next_register">{{ $store.state.sip.phone.next_register | fromNow($store.state.sip.phone.next_registration_time) }}</div> ~~>
                  </a>
                </li>~~>
                <li app-tour-web-speed-dials class="mb-2">
                  <a id="tooltip-Speed Dials" @mouseover="hoverPanel = 'SpeedDials'" @mouseout="hoverPanel = ''" @click="selectedPanel = 'SpeedDials'" :class="`w-100 d-flex align-items-center justify-content-center ${selectedPanel === 'SpeedDials' || hoverPanel === 'SpeedDials' ? 'active' : ''}`">
                    <vb-icon class="speedDial-icon speedDial-icon-v3 rightSidebar_icon" icon="speedDial-icon-v3" width="50" height="50" />
                    
                  </a>
                  <b-popover target="tooltip-Speed Dials" placement="left" content="Favourites" triggers="hover focus" />
                  
                </li>
                <!~~ <li app-tour-web-dashboard-users class="mb-2">
                  <a id="tooltip-Users" @mouseover="hoverPanel = 'Users'" @mouseout="hoverPanel = ''" @click="selectedPanel = 'Users'" :class="`w-100 d-flex align-items-center justify-content-center ${selectedPanel === 'Users' || hoverPanel === 'Users' ? 'active' : ''}`">
                    <vb-icon class="users-icon rightSidebar_icon" icon="users-icon" width="50" height="50" />
                  </a>
                  <b-popover target="tooltip-Users" placement="left" content="Users" triggers="hover focus" />
                </li> ~~>
                <li app-tour-web-teams class="mb-2">
                  <a id="tooltip-Departments" @mouseover="hoverPanel = 'Departments'" @mouseout="hoverPanel = ''" @click="selectedPanel = 'Departments'" :class="`w-100 d-flex align-items-center justify-content-center ${selectedPanel === 'Departments' || hoverPanel === 'Departments' ? 'active' : ''}`">
                    
                    <vb-icon class="departments-icon rightSidebar_icon" icon="chat-rightSideBarTaemIcon" width="50" height="50" />
                  </a>
                  <b-popover target="tooltip-Departments" placement="left" content="Teams" triggers="hover focus" />
                </li>
                <li app-tour-web-dashboard-addressbook class="mb-2">
                  <a id="tooltip-Addressbooks" @mouseover="hoverPanel = 'Addressbooks'" @mouseout="hoverPanel = ''" @click="selectedPanel = 'Addressbooks'" :class="`w-100 d-flex align-items-center justify-content-center ${selectedPanel === 'Addressbooks' || hoverPanel === 'Addressbooks' ? 'active' : ''}`">
                    <vb-icon class="addressBook-icon rightSidebar_icon" icon="addressBook-icon" width="50" height="50" />
                  </a>
                  <b-popover target="tooltip-Addressbooks" placement="left" content="Address book" triggers="hover focus" />
                </li>
                <!~~ <li class="mb-2">
                  <a 
                    @mouseover="hoverPanel = 'Todo'" 
                    @mouseout="hoverPanel = ''" 
                    @click="selectedPanel = 'Todo'" 
                    :class="`w-100 d-flex align-items-center justify-content-center ${selectedPanel === 'Todo' || hoverPanel === 'Todo' ? 'active' : ''}`"
                    v-b-popover.hover.focus.left="{
                      content: 'Address book'
                    }"
                  >
                    <vb-icon class="addressBook-icon rightSidebar_icon" icon="addressBook-icon" width="50" height="50" />
                  </a>
                </li> ~~>
              </template>
              <template v-if="getIsHRMApplication">
                <li class="mb-2">
                  <a id="tooltip-Speed Dials" :class="`w-100 d-flex align-items-center justify-content-center`">
                    <vb-icon class="rightSidebar_icon" icon="announcements-rightSideBar-hrmIcon" width="50" height="50" />
                  </a>
                  <b-popover target="tooltip-Speed Dials" placement="left" content="Announcements" triggers="hover focus" />
                </li>
              </template>
            </ul>
          </nav>
        </div>
      </template> 
      
      <nav v-else-if="!$store.getters.isIntegration" class="dontDisplayInMobile nav nav-menu pt-3">
        <ul class="list-unstyled text-center p-2 w-100">
          <template v-if="getIsDialerApplication">
            <li>
              <a>
                <!~~ movedin popover of beta text above ( START ) ~~>
                <!~~ <div class="rightSideBArTimerText" @click="sipRegister()" v-b-popover.hover.left="'Register'">Register</div>
                <div class="rightSideBArTimerText" @click="sipUnRegister()" v-b-popover.hover.left="'Un Register'">Un Register</div>
                <div class="rightSideBArTimerText" v-b-popover.hover.left="'Register Notification'">{{$store.state.sip.state.sip_register_notification}}</div> ~~>
                <!~~ movedin popover of beta text above ( END ) ~~>

                <!~~ <div class="rightSideBArTimerText" v-b-popover.hover.left="'Registration Timer'" v-if="$store.state.sip.phone.next_registration_time">{{ $store.state.sip.phone.next_registration_time | time_audio }}</div>
                <div class="rightSideBArTimerText" v-b-popover.hover.left="'Last Registered'" v-if="$store.state.sip.phone.last_register">{{ $store.state.sip.phone.last_register | fromNow($store.state.sip.phone.next_registration_time) }}</div>
                <div class="rightSideBArTimerText" v-b-popover.hover.left="'Next Registration'" v-if="$store.state.sip.phone.next_register">{{ $store.state.sip.phone.next_register | fromNow($store.state.sip.phone.next_registration_time) }}</div> ~~>
              </a>
            </li>
            <li app-tour-web-speed-dials class="mb-2">
              <a id="tooltip-Speed Dials" @mouseover="hoverPanel = 'SpeedDials'" @mouseout="hoverPanel = ''" @click="selectedPanel = 'SpeedDials'" :class="`w-100 d-flex align-items-center justify-content-center ${selectedPanel === 'SpeedDials' || hoverPanel === 'SpeedDials' ? 'active' : ''}`">
                <vb-icon class="speedDial-icon speedDial-icon-v3 rightSidebar_icon" icon="speedDial-icon-v3" width="50" height="50" />
                
              </a>
              <b-popover target="tooltip-Speed Dials" placement="left" content="Favourites" triggers="hover focus" />
              
            </li>
            <li app-tour-web-dashboard-users class="mb-2">
              <a id="tooltip-Users" @mouseover="hoverPanel = 'Users'" @mouseout="hoverPanel = ''" @click="selectedPanel = 'Users'" :class="`w-100 d-flex align-items-center justify-content-center ${selectedPanel === 'Users' || hoverPanel === 'Users' ? 'active' : ''}`">
                <vb-icon class="users-icon rightSidebar_icon" icon="users-icon" width="50" height="50" />
              </a>
              <b-popover target="tooltip-Users" placement="left" content="Users" triggers="hover focus" />
            </li>
            <li app-tour-web-teams class="mb-2">
              <a id="tooltip-Departments" @mouseover="hoverPanel = 'Departments'" @mouseout="hoverPanel = ''" @click="selectedPanel = 'Departments'" :class="`w-100 d-flex align-items-center justify-content-center ${selectedPanel === 'Departments' || hoverPanel === 'Departments' ? 'active' : ''}`">
                
                <vb-icon class="departments-icon rightSidebar_icon" icon="chat-rightSideBarTaemIcon" width="50" height="50" />
              </a>
              <b-popover target="tooltip-Departments" placement="left" content="Teams" triggers="hover focus" />
            </li>
            <li app-tour-web-dashboard-addressbook class="mb-2">
              <a id="tooltip-Addressbooks" @mouseover="hoverPanel = 'Addressbooks'" @mouseout="hoverPanel = ''" @click="selectedPanel = 'Addressbooks'" :class="`w-100 d-flex align-items-center justify-content-center ${selectedPanel === 'Addressbooks' || hoverPanel === 'Addressbooks' ? 'active' : ''}`">
                <vb-icon class="addressBook-icon rightSidebar_icon" icon="addressBook-icon" width="50" height="50" />
              </a>
              <b-popover target="tooltip-Addressbooks" placement="left" content="Address book" triggers="hover focus" />
            </li>
              <!~~ <li class="mb-2">
                <a 
                  @mouseover="hoverPanel = 'Todo'" 
                  @mouseout="hoverPanel = ''" 
                  @click="selectedPanel = 'Todo'" 
                  :class="`w-100 d-flex align-items-center justify-content-center ${selectedPanel === 'Todo' || hoverPanel === 'Todo' ? 'active' : ''}`"
                  v-b-popover.hover.focus.left="{
                    content: 'Todo'
                  }"
                >
                  <vb-icon class="addressBook-icon rightSidebar_icon" icon="addressBook-icon" width="50" height="50" />
                </a>
              </li> ~~>
          </template>
          <template v-if="getIsHRMApplication">
            <li class="mb-2">
              <a id="tooltip-Speed Dials" :class="`w-100 d-flex align-items-center justify-content-center`">
                <vb-icon class="rightSidebar_icon" icon="announcements-rightSideBar-hrmIcon" width="50" height="50" />
              </a>
              <b-popover target="tooltip-Speed Dials" placement="left" content="Announcements" triggers="hover focus" />
            </li>
          </template>
        </ul>
      </nav>
      <div v-if="!$store.getters.getIsMobile && !$store.getters.isIntegration" class="dontDisplayInMobile logo position-absolute w-100 text-center py-3 bottomOne" style="bottom:30px;top:auto;">
        <div class="chat-info p-0">
          <nav class="nav nav-menu">
            <ul class="list-unstyled text-center p-2 w-100 m-0">
              <li v-if="!isEmpty($store.state.calls.videoCalls)" class="mb-2">
                <a id="tooltip-Video-Calls" @click="videocalls()" :class="`w-100 d-flex align-items-center justify-content-center ${activeTab==='video-call'?'active':''}`">
                  <vb-icon class="speedDial-icon rightSidebar_icon inVideo-icon" icon="duringJitsiCall-backToVideoCall-icon" width="50" height="50" />
                </a>
                <b-popover target="tooltip-Video-Calls" placement="left" content="Video Calls" triggers="hover focus" />
              </li>
              <li v-if="!$store.getters.isIntegration" app-tour-web-support-ticket class="mb-2">
                <a id="tooltip-Support-Team" @click="$modal.show('KbInfoModal')" class="w-100 d-flex align-items-center justify-content-center helpSideBar-icon-container">
                  <vb-icon class="rightSidebar_icon helpSideBar-icon v2" icon="helpSideBar-icon-v2" width="50" height="50" />
                </a>
                <b-popover target="tooltip-Support-Team" placement="left" content="Help" triggers="hover focus" />
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </template>-->
    <div v-if="$store.getters.getIsMobile && !['settings'].includes($store.state.global_conditions.tab)" class="display-only-mobile">
      
      <div class="top-icons-container">
        <UserProfile v-if="$store.getters.getIsMobile" />
        <div class="d-flex position-relative logo_mobile_container">
          <!-- <svg @click="$emit('selectedTab','homeCopy')" xmlns="http://www.w3.org/2000/svg" width="50" height="48.219" viewBox="0 0 64 62.219" class="logo_mobile">
            <g id="Logo" transform="translate(-375 -24)">
              <path id="Path_1431" data-name="Path 1431" d="M12.469,22.926c.214-.252.443-.5.647-.712a1.572,1.572,0,0,1,.2-.209l.063-.073a1.5,1.5,0,0,1,.218-.2l.013-.014a2.777,2.777,0,0,1,.232-.2A3.085,3.085,0,0,1,14.1,21.3c.04-.03.117-.091.182-.142l.026-.028a.676.676,0,0,1,.115-.105.98.98,0,0,0,.155-.142c.027-.014.038-.03.067-.031l.038-.044c.194-.152.39-.285.57-.416a1.832,1.832,0,0,1,.406-.266c.118-.077.2-.142.275-.185a1.146,1.146,0,0,1,.184-.111c.1-.068.206-.131.315-.189l.285-.159a.932.932,0,0,1,.158-.084c.142-.066.285-.132.4-.182a1.208,1.208,0,0,1,.185-.084h.014a.013.013,0,0,1,.013-.016h0c.084-.033.142-.066.225-.1a.491.491,0,0,1,.142-.038c8.73-3.5,17.048-2.924,25.836-2.33l.815.06a10.791,10.791,0,0,0-.118-2.4l-.178-.88a.59.59,0,0,0-.058-.2c-.061-.252-.113-.39-.154-.57a5.859,5.859,0,0,0-.211-.592c-.105-.285-.207-.533-.322-.772a.742.742,0,0,1-.084-.16c-.084-.168-.179-.356-.261-.53l-.1-.184a.865.865,0,0,1-.111-.184.651.651,0,0,1-.067-.142.774.774,0,0,1-.155-.221,1.34,1.34,0,0,1-.142-.169,7.22,7.22,0,0,0-.605-.887c-.107-.13-.2-.245-.3-.36l-.272-.323c-.016-.027-.03-.038-.031-.051l-.014-.013-.359-.372a.537.537,0,0,1-.1-.1c-.285-.266-.474-.444-.676-.607a17.231,17.231,0,0,0-2.563-1.774l-.4-.2c-.315-.168-.631-.338-.974-.5l-.157-.07a6.794,6.794,0,0,0-.639-.266c-.355-.151-.592-.242-.837-.333A20,20,0,0,0,32.7,3.189l-.325-.1c-.169-.043-.338-.1-.507-.127a.415.415,0,0,1-.142-.044c-.35-.1-.7-.192-1.079-.273l-.285-.061c-.657-.142-1.327-.285-2.024-.392-.809-.142-1.629-.272-2.515-.353s-1.843-.187-2.81-.249l-.846-.044a5.86,5.86,0,0,0-.689-.028s-.638,0-1.2.028-1.046.09-1.046.09c-1.655.285-3.347.619-5.2,1.037A17.9,17.9,0,0,0,.4,23.941a18.745,18.745,0,0,0,1.413,4.013,103.379,103.379,0,0,0,8.465,14.267C7.177,32.455,9.485,26.448,12.469,22.926Z" transform="translate(375.012 24.651)" fill="#00000"/>
              <path id="Path_1433" data-name="Path 1433" d="M49.646,36.609a20.7,20.7,0,0,1-7.2,3.8l-.688.164c-.244.06-.474.091-.691.142l-.191.028A14.584,14.584,0,0,1,29.9,37.788a1.345,1.345,0,0,1-.142-.142c-.837-.869-1.618-1.716-2.384-2.549a10.232,10.232,0,0,1-.712-.837A89.793,89.793,0,0,1,14.21,15.988a1.514,1.514,0,0,0-.185.1c-.142.108-.3.2-.47.312l-.078.048a6.226,6.226,0,0,0-.533.4.89.89,0,0,0-.1.077.256.256,0,0,1-.063.061.637.637,0,0,1-.154.135c-.013.014-.038.044-.066.047l-.013.014-.013.014a4.471,4.471,0,0,1-.553.484l-.038.044a1.049,1.049,0,0,1-.142.121.141.141,0,0,1-.063.06l-.084.1a6.338,6.338,0,0,0-.518.57c-3.348,3.945-5.587,11.733,2.734,26.45a.656.656,0,0,1,.1.168c1.228,1.455,2.6,2.972,4.123,4.571a17.9,17.9,0,0,0,25.258.82,17.263,17.263,0,0,0,2.729-3.264,103.9,103.9,0,0,0,5.49-9.554A14.881,14.881,0,0,1,49.646,36.609Z" transform="translate(378.449 30.817)" fill="#00000"/>
              <path id="Path_1434" data-name="Path 1434" d="M54.818,17.662C55.187,8.738,49.685,3.24,42.321.955A18.735,18.735,0,0,0,38.136.182,104.081,104.081,0,0,0,21.6.52c.182.041.35.084.518.125a11.9,11.9,0,0,1,1.2.3c.238.05.463.115.688.179l.285.084c.746.218,1.451.451,2.113.712a7.809,7.809,0,0,1,.95.379c.184.067.427.168.668.285l.212.093c.557.273,1.087.534,1.567.813a19.325,19.325,0,0,1,2.948,2.038,9.495,9.495,0,0,1,.783.712l.1.085.494.511a.944.944,0,0,1,.208.218,6.8,6.8,0,0,1,.624.776,6,6,0,0,1,.743,1.055,1.02,1.02,0,0,1,.169.248c.031.051.063.091.094.142.078.117.155.236.235.367H36.2c.016.027.031.04.033.066a5.34,5.34,0,0,1,.453.873c.016.027.033.067.048.093.169.357.315.7.454,1.064a6.562,6.562,0,0,1,.248.723c.038.134.058.216.093.309l.093.309c.034.093.077.285.118.457l.184.948a13.507,13.507,0,0,1,0,4.415,1.645,1.645,0,0,1-.037.222,1.4,1.4,0,0,1-.114.31A102.573,102.573,0,0,1,26.829,43.08,11.914,11.914,0,0,0,34.756,44.8a13.871,13.871,0,0,0,7.079-3.247S52.975,31.935,54.818,17.662Z" transform="translate(384.164 24)" fill="#00000"/>
            </g>
          </svg> -->
          <div v-if="!$store.state.sip.state.is_connected">
            <div id="indicator-light-tooltip" class="indicator-light">
              <b-icon icon="arrow-clockwise" @click="reload()" />
            </div>
            <b-popover target="indicator-light-tooltip" content="Sip Socket OR Web Socket not connected" triggers="hover" />
          </div>
        </div>
        <!-- <div class="mobileViewCheckBox-container-inMobileView">
          <div class="mobileViewCheckBox" v-if="isElectron || true">
            <span class="mobileViewCheckBox-text">Minimize</span>
            <b-checkbox class="minimizedSwitch" v-if="isElectron || true" switch v-model="$store.state.electron.app_view" value="mob" unchecked-value="web" />
          </div>
        </div> -->
        <!-- <div v-if="isInCall" @click="$store.state.global_conditions.tab='video-call'" class="backToCallButton">Back to Call</div> -->
        <div class="d-flex align-items-center soundSetting-icon-container">
          <vb-icon v-if="!$store.getters.isIntegration" id="soundSetting-icon" @click="$modal.show('AudioSettingModal')" icon="mobileSoundSettings-updated-icon" width="34.337" height="34.337" />
        </div>
        <!-- <div class="w-auto-c">
          <a v-if="!$store.getters.isIntegration" @click="$modal.show('KbInfoModal')" class="d-flex align-items-center justify-content-center helpSideBar-icon-container">
            <vb-icon class="rightSidebar_icon helpSideBar-icon v2" icon="support-mobile-icon" width="50" height="50" />
          </a>
          <div class="d-flex align-items-center soundSetting-icon-container">
            <vb-icon v-if="!$store.getters.isIntegration" id="soundSetting-icon" @click="$modal.show('AudioSettingModal')" icon="mobile-sound-settings" width="34.337" height="34.337" />
          </div>
          <UserProfile v-if="$store.getters.getIsMobile" />
        </div> -->
      </div>
    </div>
    <ContactPanel ref="ContactRef" @interface="RightPanel()" @dial="dialNow($event)" @videocall="videoCall($event)" />
    <AudioSettingModal />
    <vb-icon icon="voipBusiness-mainLogo" height="38px" width="180px" class="mainLogoOverlay"/>

  </div>
</template>

<script>
import ContactPanel from "./ContactPanel.vue";
// import SoundSetting from "./SoundSetting.vue";
import UserProfile from "../UserProfile.vue";
import { LOGGER, events, } from "../../utils";
import AudioSettingModal from '../Modals/AudioSettingModal.vue'
import _ from "lodash";
import { mapGetters, mapState } from 'vuex';
import moment from 'moment-timezone';
import { electron_events } from '@/electron/events';

export default {
  name: "RightSideBar",
  inject: ['isEmpty','appNotify'],
  props: {
    activeTab: {
      type: String,
      default: '',
    },
  },
  components: {
    ContactPanel,
    // SoundSetting,
    UserProfile,
    AudioSettingModal,
  },
  data() {
    return {
      selectedPanel: "",
      hoverPanel: "",
      toggleRadius: false,
      show: {
        sound_setting: false,
      },
      pin_post: "",
      conditions: {
        dropdown_show_id: "",
        clicked: false,
        is_show: false,
        show_more: false,
      },
      feed_back:false,
      api: {
        sip_register: this.$helperFunction.apiInstance(),
        sip_unregister: this.$helperFunction.apiInstance(),
      },
    };
  },
  computed: {
    isElectron(){ return process.env.IS_ELECTRON ? true : false },
    isInCall(){
      const sip_sessions = !_.isEmpty(this.$store.state.sip.phone) ? this.$store.state.sip.phone.getSessions() || [] : []
      const jitsi_outgoings = !_.isEmpty(this.$store.state.calls.outgoing_calls) ? this.$store.state.calls.outgoing_calls : []
      const jitsi_incomings = !_.isEmpty(this.$store.state.calls.incoming_calls) ? this.$store.state.calls.incoming_calls : []
      const jitsi_videocalls = !_.isEmpty(this.$store.state.calls.videoCalls) ? this.$store.state.calls.videoCalls : []
      const new_sip_sessions = sip_sessions.filter(session=>session.number!='*154*');
      return !_.isEmpty(new_sip_sessions) || !_.isEmpty(jitsi_outgoings) || !_.isEmpty(jitsi_incomings) || !_.isEmpty(jitsi_videocalls)
    },
    ...mapState({
      media_devices_setting: state=>state.media_devices_setting
    }),
    ...mapGetters([
      'getIsHRMApplication',
      'getIsDialerApplication',
    ]),
  },
  watch: {
    selectedPanel: "watchingSelectedPanel",
    "$store.state.global_conditions.add_addressbooks"(val) {
      // console.log("$store.state.global_conditions.add_addressbooks",val)
      if (val) {
        let vm = this
        setTimeout(()=>{
          vm.selectedPanel = "Addressbooks";
          vm.$store.state.global_conditions.add_addressbooks=val
        },0.5*1000)
      }
    },
  },
  filters: {
    date_filter(value){
      const date = moment(value).calendar(null, {
        sameDay: 'hh:mm:ss',
        nextDay: '[Tomorrow] hh:mm:ss',
        nextWeek: 'dddd',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'DD MMM YYYY'
      })
      return date!='Invalid Date' ? date : ''
    },
    fromNow(value){
      const date = moment(value).fromNow()
      return date!='Invalid Date' ? date : ''
    }
  },
  methods: {
    
    reload(){
      if(process.env.IS_ELECTRON){
        window.electron.send(electron_events.RELOAD)
      } else {
        window.location.reload();
      }
    },
    RightPanel() {
      this.selectedPanel = "";
    },
    dialNow($event) {
      this.$parent.$refs.home.$refs.audiocall.dialNow($event);
    },
    videoCall($event) {
      this.$parent.$refs.home.$refs.audiocall.videoCall($event);
    },
    watchingSelectedPanel(panel) {
      if (panel) {
        this.$parent.applyMargin = true;
        this.toggleRadius = true;
        this.$refs.ContactRef.showPanel = true;
        this.$refs.ContactRef.selected = panel;
        this.$store.state.global_conditions.is_right_bar_open=true
      } else {
        this.$parent.applyMargin = false;
        this.toggleRadius = false;
        this.$store.state.global_conditions.is_right_bar_open=false
      }
    },
    pinPost(message) {
      let vm = this
      LOGGER.log('message',message);
      setTimeout(()=>{
        vm.selectedPanel = "PinnedMsgs";
        vm.pin_post = message;
      },1 * 1000)
    },
    videocalls(){
      this.$emit('selectedTab','video-call')
    },
    //--event--
    ctrl_shift_1() {
      if (this.selectedPanel === "SpeedDials") {
        this.$refs.ContactRef.handleMenu();
      } else {
        this.selectedPanel = "SpeedDials";
      }
    },
    ctrl_shift_2() {
      if (this.selectedPanel === "Users") {
        this.$refs.ContactRef.handleMenu();
      } else {
        this.selectedPanel = "Users";
      }
    },
    ctrl_shift_3() {
      if (this.selectedPanel === "Departments") {
        this.$refs.ContactRef.handleMenu();
      } else {
        this.selectedPanel = "Departments";
      }
    },
    ctrl_shift_4() {
      if (this.selectedPanel === "Addressbooks") {
        this.$refs.ContactRef.handleMenu();
      } else {
        this.selectedPanel = "Addressbooks";
      }
    },
    bvDropdownHide(bvEvent) {
      if (this.conditions.clicked) {
        bvEvent.preventDefault();
        this.conditions.clicked = false;
      } else if (bvEvent.componentId === "sound-setting-dropdown") {
        if (this.dropdown_show_id === "sound-setting-micro-devices") {
          bvEvent.preventDefault();
        } else {
          this.conditions.is_show = false;
        }
      } else if (bvEvent.componentId === "sound-setting-micro-devices") {
        this.dropdown_show_id = "";
      }
    },
    bvDropdownShow(bvEvent) {
      if (bvEvent.componentId === "sound-setting-dropdown") {
        this.conditions.is_show = true;
      } else if (bvEvent.componentId === "sound-setting-micro-devices") {
        this.dropdown_show_id = "sound-setting-micro-devices";
      }
    },
    
    reRegisterUserAgent(){
      this.$store.state.sip.phone?.userAgentRegister?.()
    },
    reConnectUserAgent(){
      this.$store.state.sip.phone?.attemptReconnection?.()
    },
    async sipRegister(){
      if(this.api.sip_register.send) return;
      try {
        this.api.sip_register.send=true
        await this.$store.state.sip.phone?.registerer?.register?.()
      } catch(ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.sip_register.send=false
      }
    },
    async sipUnRegister(){
      if(this.api.sip_unregister.send) return;
      try {
        this.api.sip_unregister.send=true
        await this.$store.state.sip.phone?.registerer?.unregister?.()
      } catch(ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.sip_unregister.send=false
      }
    },
  },
  created() {
    this.$root.$on(events.pin_posts, this.pinPost);
    this.$root.$on(events.ctrl_shift_1, this.ctrl_shift_1);
    this.$root.$on(events.ctrl_shift_2, this.ctrl_shift_2);
    this.$root.$on(events.ctrl_shift_3, this.ctrl_shift_3);
    this.$root.$on(events.ctrl_shift_4, this.ctrl_shift_4);
    this.$root.$on("bv::dropdown::show", this.bvDropdownShow);
    this.$root.$on("bv::dropdown::hide", this.bvDropdownHide);
  },
  beforeDestroy() {
    this.$root.$off(events.pin_posts, this.pinPost);
    this.$root.$off(events.ctrl_shift_1, this.ctrl_shift_1);
    this.$root.$off(events.ctrl_shift_2, this.ctrl_shift_2);
    this.$root.$off(events.ctrl_shift_3, this.ctrl_shift_3);
    this.$root.$off(events.ctrl_shift_4, this.ctrl_shift_4);
    this.$root.$off("bv::dropdown::show", this.bvDropdownShow);
    this.$root.$off("bv::dropdown::hide", this.bvDropdownHide);
  },
  mounted(){
    
  }
};
</script>

<style lang="scss" scoped>
.chat-info {
  background-color: transparent;
  @include border-radius(0);
}
</style>
