var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-main-section DealsSection"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100 mb-5"
  }, [_c('h2', {
    staticClass: "crm-main-section-heading text-case-unset mb-0"
  }, [_vm._v(" Brands "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1)]), _c('div', {
    staticClass: "crm-card withSeprateHeader"
  }, [_c('div', {
    staticClass: "crm-card-heading"
  }, [_c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center"
  }, [_c('div', [_vm._v(" Brands "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1), _c('div', {
    staticClass: "d-flex"
  }, [_c('button', {
    staticClass: "crm-small-button"
  }, [_c('vb-icon', {
    staticClass: "crm-plusButton-icon",
    attrs: {
      "icon": "crm-plusButton-icon"
    }
  }), _vm._v(" Create Brands ")], 1)])]), _c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center mt-38px"
  }, [_c('b-dropdown', {
    staticClass: "crm-dd-container",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("This week")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" This week ")])], 1)], 1), _c('div', {
    staticClass: "crm-input-container mb-0"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-inputSearch-icon"
    }
  }), _c('input', {
    staticClass: "w-100",
    attrs: {
      "placeholder": "Search"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "crm-card-body"
  }, [_c('vb-table', {
    staticClass: "mt-3",
    attrs: {
      "isListEmpty": _vm.isEmpty(_vm.tableData),
      "listLength": _vm.tableData.length,
      "textNoRecord": " "
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.tableData, function (data, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: data.id
          }, [_c('td', {
            staticClass: "dialer-row-title"
          }, [_c('span', [_vm._v(_vm._s(data.m1))])]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(" " + _vm._s(data.m1) + " ")]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('b-dropdown', {
            staticClass: "crm-insideTable-3dotsVertical-dd",
            attrs: {
              "no-caret": ""
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function () {
                return [_c('b-icon', {
                  attrs: {
                    "icon": "three-dots-vertical",
                    "height": "16px",
                    "width": "16px"
                  }
                })];
              },
              proxy: true
            }], null, true)
          }, [_c('b-dropdown-item', [_vm._v(" Edit ")]), _c('b-dropdown-item', [_vm._v(" Delete ")])], 1)], 1)]);
        });
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', [_vm._v(" ID ")]), _c('th', [_vm._v(" Label ")]), _c('th', {
    staticClass: "dialer-col-right cursor_pointer"
  }, [_c('span', [_vm._v("Actions ")])])])])], 2)], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }