var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-settings-main"
  }, [_c('section', {
    staticClass: "dialer-settings-section dialer-users-setting"
  }, [_c('div', {
    staticClass: "settingsFirstSection"
  }, [_vm.$store.getters.getIsMobile ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-width-button w-100"
  }, [_c('b-icon', {
    staticClass: "settings-back-icon cursor_pointer",
    attrs: {
      "icon": "chevron-left",
      "font-scale": "1.5"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }), _c('h2', {
    staticClass: "dialer-settings-title w-100 mb-0 text-center"
  }, [_vm._v("Users")])], 1), _c('div', [_c('button', {
    staticClass: "newButton mr-0",
    attrs: {
      "disabled": _vm.api.check_service.send
    },
    on: {
      "click": function ($event) {
        return _vm.checkservice();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart d-flex align-items-center justify-content-center position-relative"
  }, [_vm.api.check_service.send ? _c('vb-spinner') : [_c('span', [_vm._v("Add user")])]], 2)], 1)])] : [_c('h2', {
    staticClass: "dialer-settings-title mb-0 settingsFirstSection-left newer"
  }, [_vm._v(" Users "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon md-mar-left",
    attrs: {
      "id": "ivr-info",
      "icon": "info-circle-fill"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('KbInfoModal', {
          kb: 'users'
        });
      }
    }
  })], 1), _c('div', {
    staticClass: "settingsFirstSection-right"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end align-items-center mt-0"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD ul-width-fit-content"
  }, [_c('b-dropdown', {
    staticClass: "dd-text-dark dd-with-checkbox",
    attrs: {
      "no-caret": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('vb-svg', {
          staticClass: "filterDD-icon",
          attrs: {
            "name": "filter-icon",
            "width": "19",
            "height": "18",
            "viewBox": "0 0 19 18",
            "stroke-width": "0",
            "stroke": "",
            "fill": "none",
            "stroke-linecap": "round",
            "stroke-linejoin": "round"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-form', {}, [_c('b-form-checkbox', {
    staticClass: "makeCheckBoxesSquare",
    model: {
      value: _vm.GridView,
      callback: function ($$v) {
        _vm.GridView = $$v;
      },
      expression: "GridView"
    }
  }, [_c('span', {
    staticClass: "ml-3"
  }, [_vm._v("Grid view")])]), _c('b-form-checkbox', {
    staticClass: "makeCheckBoxesSquare",
    model: {
      value: _vm.filter.list.active_users,
      callback: function ($$v) {
        _vm.$set(_vm.filter.list, "active_users", $$v);
      },
      expression: "filter.list.active_users"
    }
  }, [_c('span', {
    staticClass: "ml-3"
  }, [_vm._v("Active users")])]), _c('b-form-checkbox', {
    staticClass: "makeCheckBoxesSquare",
    model: {
      value: _vm.filter.list.inactive_users,
      callback: function ($$v) {
        _vm.$set(_vm.filter.list, "inactive_users", $$v);
      },
      expression: "filter.list.inactive_users"
    }
  }, [_c('span', {
    staticClass: "ml-3"
  }, [_vm._v("Inactive users")])]), _c('b-form-checkbox', {
    staticClass: "makeCheckBoxesSquare",
    model: {
      value: _vm.filter.list.invitations,
      callback: function ($$v) {
        _vm.$set(_vm.filter.list, "invitations", $$v);
      },
      expression: "filter.list.invitations"
    }
  }, [_c('span', {
    staticClass: "ml-3"
  }, [_vm._v("Invitations")])])], 1)], 1)], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder sm-mar-left sm-mar-right seachBox"
  }, [_c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search user"
    },
    model: {
      value: _vm.filter.list.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.list, "search", $$v);
      },
      expression: "filter.list.search"
    }
  })], 1), _c('button', {
    staticClass: "newButton mr-0",
    attrs: {
      "disabled": _vm.api.check_service.send
    },
    on: {
      "click": function ($event) {
        return _vm.checkservice();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart d-flex align-items-center justify-content-center position-relative"
  }, [_vm.api.check_service.send ? _c('vb-spinner') : [_c('span', [_vm._v("Add user")])]], 2)], 1)])])]], 2), _vm._m(0), _vm.GridView ? [_c('div', {
    staticClass: "settingsGridView-container mt-32px"
  }, _vm._l(_vm.filterList, function (data) {
    return _c('div', {
      key: data.id || data.voipaccount,
      staticClass: "settingsGridView-item"
    }, [data.voipaccount ? _c('div', {
      class: `settingsGridView-accountStatus ${data.user.userRole != 0 || data.active_status != '1' ? 'showKaro' : 'naiShowKrna'}`
    }, [_c('div', {
      staticClass: "settingsGridView-accountStatus-text"
    }, [data.active_status != '1' ? _c('span', [_vm._v("Disabled")]) : data.user.user_type == 'main' ? _c('span', [_vm._v("Owner")]) : data.user.userRole != 0 ? _c('span', [_vm._v("Admin")]) : _vm._e(), _c('div', {
      class: `settingsGridView-accountStatus-circle ${data.active_status == '1' ? 'active' : 'inActive'}`
    })])]) : _vm._e(), data.voipaccount ? _c('vb-avatar', {
      attrs: {
        "account": data.voipaccount,
        "image": data.profile_img,
        "name": _vm._f("get_property")(data, 'user.display_name'),
        "status_id": _vm._f("get_property")(data, 'status')
      }
    }) : _vm._e(), data.queue_service ? _c('vb-avatar', {
      attrs: {
        "id": data.id
      }
    }) : _vm._e(), data.object == 'UserInvitation' ? _c('vb-avatar') : _vm._e(), data.voipaccount ? _c('div', {
      staticClass: "settingsGridView-display_name"
    }, [_vm._v(_vm._s(_vm._f("get_property")(data, 'user.display_name')))]) : _vm._e(), data.object == 'UserInvitation' ? _c('div', {
      staticClass: "settingsGridView-display_name"
    }, [_vm._v(" " + _vm._s(_vm._f("get_property")(data, 'data.firstname')) + " " + _vm._s(_vm._f("get_property")(data, 'data.lastname')) + " ")]) : _vm._e(), data.queue_service ? _c('div', {
      staticClass: "settingsGridView-display_name"
    }, [_vm._v(_vm._s(_vm._f("get_property")(data, 'name')))]) : _vm._e(), data.object == 'UserInvitation' ? _c('div', {
      staticClass: "d-flex"
    }, [_c('div', [_c('span', {
      staticClass: "d-flex flex-column align-items-start justify-content-end text-center"
    }, [_c('div', {
      staticClass: "d-flex align-items-center text-center"
    }, [_vm._v(" " + _vm._s(_vm._f("invitationExpireText")(data)) + " "), _c('b-icon', {
      staticClass: "cursor_pointer_no_hover ml-2",
      attrs: {
        "icon": "arrow-clockwise",
        "variant": "primary",
        "animation": _vm.api.retry_invitation.send.indexOf(data.id) > -1 ? 'spin' : ''
      },
      on: {
        "click": function ($event) {
          return _vm.retryInvitation(data);
        }
      }
    }), _c('b-icon', {
      staticClass: "cursor_pointer_no_hover ml-2",
      attrs: {
        "icon": "trash",
        "variant": "danger",
        "animation": _vm.api.cancel_invitation.send.includes(data.id) ? 'spin' : ''
      },
      on: {
        "click": function ($event) {
          return _vm.cancelInvitation(data);
        }
      }
    })], 1)])])]) : _vm._e(), _c('div', {
      staticClass: "settingsGridView-email"
    }, [data.voipaccount ? _c('span', [_vm._v(_vm._s(_vm._f("usernameDisplay")(data, _vm.$store.getters.getCurrentUser.email)))]) : _vm._e(), data.queue_service ? _c('span', [_vm._v(_vm._s(data.username))]) : _vm._e(), data.object == 'UserInvitation' ? _c('span', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      attrs: {
        "title": _vm.getProperty(data, 'created_at.date', '').substring(0, 10)
      }
    }, [_vm._v(_vm._s(data.email))]) : _vm._e()]), data.voipaccount ? [data.userDid && data.userDid[0] ? _c('div', {
      staticClass: "d-flex flex-column mt-1"
    }, [_c('div', {
      staticClass: "d-flex flex-column align-items-center"
    }, [_c('span', {
      staticClass: "text-center font-weight-bold min-width-160px"
    }, [_c('img', {
      staticClass: "country-img",
      attrs: {
        "width": "45px",
        "src": _vm._f("flag_icon")(_vm._f("number_formater")(_vm._f("get_property")(data, 'userDid[0].did')))
      }
    }), _vm._v(" " + _vm._s(_vm._f("number_formater")(_vm._f("get_property")(data, 'userDid[0].did'))) + " ")]), data.userDid.length > 1 ? _c('div', {
      staticClass: "w-auto dialer-dropdown mt-2 dialer-dropDown-latest dialer-dropDown-onRight dialer-dropDown-p-1 ml-2",
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          _vm.conditions.more = _vm.conditions.more == data.voipaccount ? '' : data.voipaccount;
        }
      }
    }, [_c('div', {
      staticClass: "px-2 more-numbers-tag cursor_pointer_no_hover"
    }, [_vm.conditions.more == data.voipaccount ? _c('small', [_vm._v("- Hide")]) : _c('small', [_vm._v("+ " + _vm._s(data.userDid.length - 1) + " more")])])]) : _vm._e()]), _vm.conditions.more == data.voipaccount ? _vm._l(data.userDid.filter(function (item, index) {
      return index > 0;
    }), function (did) {
      return _c('div', {
        key: did.did,
        staticClass: "mt-2 d-flex align-items-center text-center"
      }, [_c('img', {
        staticClass: "country-img",
        attrs: {
          "width": "45px",
          "src": _vm._f("flag_icon")(_vm._f("number_formater")(did.did))
        }
      }), _c('div', {
        staticClass: "d-flex align-items-center font-weight-bold sm-mar-left text-center justify-content-center"
      }, [_vm._v(_vm._s(_vm._f("number_formater")(did.did)))])]);
    }) : _vm._e()], 2) : _c('span', {
      staticClass: "settingsGridView-email"
    }, [_vm._v("No numbers")])] : _vm._e(), data.object == 'UserInvitation' ? [data.data && data.data.telephone ? _c('div', {
      staticClass: "d-flex flex-column mt-1"
    }, [_c('div', {
      staticClass: "d-flex flex-column align-items-center"
    }, [_c('span', {
      staticClass: "text-center font-weight-bold min-width-160px"
    }, [_vm._v(_vm._s(_vm._f("number_formater")(data.data.telephone)))])])]) : _c('span', {
      staticClass: "settingsGridView-email"
    }, [_vm._v("No numbers")])] : _vm._e(), data.voipaccount ? _c('div', {
      staticClass: "d-flex settingsGridView-bottomIcons-container mt-20px"
    }, [_c('div', {
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          _vm.api.delete_user.send ? '' : data.active_status != '1' ? _vm.appNotify({
            type: 'danger',
            message: 'Unable to proceed because this user is suspended'
          }) : _vm.$modal.show('UsersGraphModal', {
            accountcode: data.voipaccount
          });
        }
      }
    }, [_c('vb-icon', {
      staticClass: "cursor_pointer_no_hover mr-20px",
      attrs: {
        "icon": "UserSettings-gridView-graph-icon",
        "height": "55px",
        "width": "55px"
      }
    })], 1), _c('div', {
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          !data.voipaccount || _vm.api.delete_user.send ? '' : data.active_status != '1' ? _vm.appNotify({
            type: 'danger',
            message: 'Unable to proceed because this user is suspended'
          }) : _vm.userDetail(data.voipaccount);
        }
      }
    }, [_c('vb-icon', {
      staticClass: "cursor_pointer_no_hover",
      attrs: {
        "icon": "UserSettings-gridView-edit-icon",
        "height": "55px",
        "width": "55px"
      }
    })], 1)]) : _vm._e()], 2);
  }), 0)] : [!_vm.$store.getters.getIsMobile ? [_c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover",
    attrs: {
      "textNoRecord": " ",
      "id": "users-table",
      "isListEmpty": _vm.isEmpty(_vm.filterList),
      "listLength": _vm.filterList.length,
      "perPage": 5,
      "conditions": {
        loading: {
          show: false
        },
        pagination: {
          show: false
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return _vm._l(_vm.filterList, function (data) {
          return _c('tr', {
            key: data.id || data.voipaccount,
            staticClass: "dialer-row-select",
            on: {
              "click": function ($event) {
                !data.voipaccount || _vm.api.delete_user.send ? '' : data.active_status != '1' ? _vm.appNotify({
                  type: 'danger',
                  message: 'Unable to proceed because this user is suspended'
                }) : _vm.userDetail(data.voipaccount);
              }
            }
          }, [_c('td', {
            staticClass: "dialer-row-title one"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [data.voipaccount ? _c('Info', {
            staticClass: "w-fit-content",
            attrs: {
              "is_suspended_show": "",
              "id": data.voipaccount,
              "is_blf": false,
              "username": _vm._f("usernameDisplay")(data, _vm.$store.getters.getCurrentUser.email)
            }
          }) : _vm._e(), data.object == 'UserInvitation' ? [data.object == 'UserInvitation' ? _c('Info', {
            staticClass: "w-fit-content",
            attrs: {
              "name": `${data.data.firstname} ${data.data.lastname}`,
              "username": `${data.email}`
            },
            scopedSlots: _vm._u([{
              key: "sub-info",
              fn: function () {
                return [_c('span', {
                  staticClass: "verySmallText"
                }, [_vm._v(" " + _vm._s(_vm._f("invitationExpireText")(data)) + " "), _c('b-icon', {
                  staticClass: "cursor_pointer_no_hover ml-2",
                  attrs: {
                    "icon": "arrow-clockwise",
                    "variant": "dark",
                    "animation": _vm.api.retry_invitation.send.includes(data.id) ? 'spin' : ''
                  },
                  on: {
                    "click": function ($event) {
                      return _vm.retryInvitation(data);
                    }
                  }
                }), _c('b-icon', {
                  staticClass: "cursor_pointer_no_hover ml-2",
                  attrs: {
                    "icon": "trash-fill",
                    "variant": "danger",
                    "animation": _vm.api.cancel_invitation.send.includes(data.id) ? 'spin' : ''
                  },
                  on: {
                    "click": function ($event) {
                      return _vm.cancelInvitation(data);
                    }
                  }
                })], 1)];
              },
              proxy: true
            }], null, true)
          }) : _vm._e()] : _vm._e(), data.queue_service ? _c('Info', {
            staticClass: "w-fit-content",
            attrs: {
              "id": data.id
            }
          }) : _vm._e(), data.voipaccount ? [data.user.user_type == 'main' ? _c('span', {
            staticClass: "OwnertaggedVersion mb-0 ml-16px"
          }, [_vm._v("Owner")]) : data.user.userRole != 0 ? _c('span', {
            staticClass: "AdmintaggedVersion mb-0 ml-16px"
          }, [_vm._v("Admin")]) : _vm._e()] : _vm._e()], 2)]), _c('td', {
            staticClass: "dialer-col-left font-weight-bold two"
          }, [data.voipaccount ? [data.userDid && data.userDid[0] ? _c('div', {
            staticClass: "d-flex flex-column"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('span', {
            staticClass: "sm-mar-left font-weight-bold min-width-160px"
          }, [_c('span', {
            staticClass: "mr-1"
          }, [_c('img', {
            staticClass: "country-img",
            attrs: {
              "width": "45px",
              "src": _vm._f("flag_icon")(_vm._f("number_formater")(_vm._f("get_property")(data, 'userDid[0].did')))
            }
          })]), _vm._v(" " + _vm._s(_vm._f("number_formater")(_vm._f("get_property")(data, 'userDid[0].did'))) + " ")]), data.userDid.length > 1 ? _c('div', {
            staticClass: "w-auto dialer-dropdown dialer-dropDown-latest dialer-dropDown-onRight dialer-dropDown-p-1 ml-2",
            on: {
              "click": function ($event) {
                $event.stopPropagation();
                _vm.conditions.more = _vm.conditions.more == data.voipaccount ? '' : data.voipaccount;
              }
            }
          }, [_c('div', {
            staticClass: "px-2 rounded more-numbers-tag"
          }, [_vm.conditions.more == data.voipaccount ? _c('small', [_vm._v("- Hide")]) : _c('small', [_vm._v("+ " + _vm._s(data.userDid.length - 1) + " more")])])]) : _vm._e()]), _vm.conditions.more == data.voipaccount ? [_vm._l(data.userDid.filter(function (item, index) {
            return index > 0;
          }), function (did) {
            return [_c('div', {
              key: did.did,
              staticClass: "mt-2"
            }, [_c('div', {
              staticClass: "d-flex align-items-center sm-mar-left"
            }, [_c('span', {
              staticClass: "mr-2 d-flex align-items-center"
            }, [_c('img', {
              staticClass: "country-img",
              attrs: {
                "width": "45px",
                "src": _vm._f("flag_icon")(_vm._f("number_formater")(did.did))
              }
            })]), _vm._v(" " + _vm._s(_vm._f("number_formater")(did.did)) + " ")])])];
          })] : _vm._e()], 2) : _c('span', {
            staticClass: "text-center"
          }, [_vm._v(" - ")])] : _vm._e(), data.object == 'UserInvitation' ? [data.data && data.data.telephone ? _c('div', {
            staticClass: "d-flex flex-column"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('span', {
            staticClass: "sm-mar-left font-weight-bold min-width-160px"
          }, [_c('span', {
            staticClass: "mr-1"
          }, [_c('img', {
            staticClass: "country-img",
            attrs: {
              "width": "45px",
              "src": _vm._f("flag_icon")(_vm._f("number_formater")(data.data.telephone))
            }
          })]), _vm._v(" " + _vm._s(_vm._f("number_formater")(data.data.telephone)) + " ")])])]) : _c('span', {
            staticClass: "text-center"
          }, [_vm._v(" - ")])] : _vm._e(), data.queue_service ? [_c('span', {
            staticClass: "text-center"
          }, [_vm._v(" - ")])] : _vm._e()], 2), _c('td', {
            staticClass: "dialer-col-right four"
          }, [data.voipaccount ? _c('span', {
            staticClass: "d-flex align-items-center"
          }, [_c('span', {
            staticClass: "newThisDeviceIndicaterTextBox-25-11-2023 h-fit-content ml-0 mr-16px showOnly-OnHover-insideTable"
          }, [_vm._v(" Configure ")]), _c('div', {
            staticClass: "dialer-row-title three"
          }, [_c('button', {
            staticClass: "dialer-button dialer-button-delete-alt buttonNothing",
            attrs: {
              "type": "button"
            },
            on: {
              "click": function ($event) {
                $event.stopPropagation();
                _vm.api.delete_user.send ? '' : data.active_status != '1' ? _vm.appNotify({
                  type: 'danger',
                  message: 'Unable to proceed because this user is suspended'
                }) : _vm.$modal.show('UsersGraphModal', {
                  accountcode: data.voipaccount
                });
              }
            }
          }, [_c('vb-icon', {
            staticClass: "graphIcon",
            attrs: {
              "icon": "graph-icon",
              "height": "40.501",
              "width": "41.374"
            }
          })], 1)])]) : _vm._e(), data.queue_service ? _c('b-spinner', {
            attrs: {
              "variant": "primary",
              "type": "grow",
              "small": "",
              "label": "Spinning"
            }
          }) : _vm._e()], 1)]);
        });
      },
      proxy: true
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex"
          }, [_c('div', {
            staticClass: "latestShimmerDesign mr-2",
            staticStyle: {
              "height": "35px",
              "width": "35px"
            }
          }), _c('div', {
            staticClass: "d-flex flex-column justify-content-between"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "16px",
              "width": "60px"
            }
          }), _c('div', {
            staticClass: "latestShimmerDesign mt-1",
            staticStyle: {
              "height": "16px",
              "width": "30px"
            }
          })])])]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "-webkit-fill-available"
            }
          })]), _c('td', [_c('div', {
            staticClass: "d-flex"
          }, [_c('div', {
            staticClass: "latestShimmerDesign mr-2",
            staticStyle: {
              "height": "20px",
              "min-width": "40px"
            }
          }), _c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "-webkit-fill-available"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "-webkit-fill-available"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "-webkit-fill-available"
            }
          })]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "48px",
              "width": "48px"
            }
          })])]);
        });
      },
      proxy: true
    }], null, false, 1324472833)
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v(" Name ")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Numbers")])]), _c('th', {
    staticClass: "dialer-has-sort dialer-col-right"
  })])])], 2)] : _vm._e(), _vm.$store.getters.getIsMobile ? [_c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover smallerFontSizes",
    attrs: {
      "textNoRecord": " ",
      "isListEmpty": _vm.isEmpty(_vm.filterList),
      "listLength": _vm.filterList.length,
      "perPage": 5,
      "conditions": {
        loading: {
          show: false
        },
        pagination: {
          show: false
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return _vm._l(_vm.filterList, function (data) {
          return _c('tr', {
            key: data.id || data.voipaccount,
            on: {
              "click": function ($event) {
                !data.voipaccount || _vm.api.delete_user.send ? '' : data.active_status != '1' ? _vm.appNotify({
                  type: 'danger',
                  message: 'Unable to proceed because this user is suspended'
                }) : _vm.userDetail(data.voipaccount);
              }
            }
          }, [_c('td', {
            staticClass: "avatar-container"
          }, [_c('div', {
            staticClass: "d-flex align-items-start flex-column"
          }, [data.voipaccount ? _c('Info', {
            staticClass: "w-fit-content",
            attrs: {
              "is_suspended_show": "",
              "id": data.voipaccount,
              "is_blf": false,
              "username": _vm._f("usernameDisplay")(data, _vm.$store.getters.getCurrentUser.email)
            }
          }) : _vm._e(), data.object == 'UserInvitation' ? _c('Info', {
            staticClass: "w-fit-content",
            attrs: {
              "name": `${data.data.firstname} ${data.data.lastname}`,
              "username": `${data.email}`
            }
          }) : _vm._e(), data.queue_service ? _c('Info', {
            staticClass: "w-fit-content",
            attrs: {
              "id": data.id
            }
          }) : _vm._e(), data.voipaccount ? [data.user.user_type == 'main' ? _c('span', {
            staticClass: "OwnertaggedVersion mb-0 mt-8px"
          }, [_vm._v("Owner")]) : data.user.userRole != 0 ? _c('span', {
            staticClass: "AdmintaggedVersion mb-0 mt-8px"
          }, [_vm._v("Admin")]) : _vm._e()] : _vm._e()], 2)]), _c('td', [data.voipaccount ? [_c('div', {
            staticClass: "name"
          }, [_vm._v(_vm._s(_vm._f("get_property")(data, 'extn')))])] : _vm._e(), data.object == 'UserInvitation' ? [_vm._v(" " + _vm._s(_vm._f("invitationExpireText")(data)) + " "), _c('b-icon', {
            staticClass: "cursor_pointer_no_hover ml-2",
            attrs: {
              "icon": "arrow-clockwise",
              "variant": "primary",
              "animation": _vm.api.retry_invitation.send.includes(data.id) ? 'spin' : ''
            },
            on: {
              "click": function ($event) {
                return _vm.retryInvitation(data);
              }
            }
          }), _c('b-icon', {
            staticClass: "cursor_pointer_no_hover ml-2",
            attrs: {
              "icon": "trash",
              "variant": "danger",
              "animation": _vm.api.cancel_invitation.send.includes(data.id) ? 'spin' : ''
            },
            on: {
              "click": function ($event) {
                return _vm.cancelInvitation(data);
              }
            }
          })] : _vm._e(), data.queue_service ? [_c('div', {
            staticClass: "name"
          }, [_vm._v(_vm._s(`${data.name}`))])] : _vm._e()], 2), _c('td', [data.voipaccount ? [_c('div', {
            staticClass: "dialer-row-title three"
          })] : _vm._e()], 2)]);
        });
      },
      proxy: true
    }], null, false, 549072750)
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v(" Name ")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Role")])]), _c('th', {
    staticClass: "dialer-has-sort dialer-col-right"
  }, [_c('span', {
    staticClass: "for-flex-column"
  }, [_c('span', [_vm._v("Status")])])])])])], 2)] : _vm._e()], _c('InvitePeopleModal', {
    on: {
      "load": function ($event) {
        return _vm.fetchInvitations();
      }
    }
  }), _c('EditUserModal'), _c('GraphModal', {
    attrs: {
      "modalName": "UsersGraphModal"
    }
  }), _c('AddUserANDSubUserModal', {
    on: {
      "user-added": function ($event) {
        return _vm.afterAdding();
      }
    }
  })], 2)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "settingsSecondSection"
  }, [_c('span', {
    staticClass: "dialer-settings-intro newerTextVersion mt-0 mb-0"
  }, [_vm._v("Instantly add, remove, and effectively manage users within the organization.")])]);

}]

export { render, staticRenderFns }