<template>
  <div class="dialer-analytics-main dialer-analytics-likeSomeThing">
    <div class="dialer-box p-16-24-px">
      <div class="analytics-newHeader">
        <div class="analytics-newHeader-leftSide">
          <div>
            <h1>Voicemail</h1>
          </div>
        </div>
        <div class="analytics-newHeader-rightSide justify-content-end">
          <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion userTeamsFilter filterWithText w-33-imp">
            <!-- <label>Users & Teams</label> -->
            <b-dropdown right>
              <template #button-content> 
                <b class="text-right">{{ `${filters.voicemails.extensions.length ? filters.voicemails.extensions.length : 'No'} selected` }}</b> 
                <div class="d-flex align-items-center">
                  <b-icon v-if="filters.voicemails.extensions.length>0" icon="x-circle-fill" varinat="#000000" class="ml-3" @click.stop="filters.voicemails.extensions=[]" />
                  <!-- <b-icon-chevron-down class="sm-mar-left" /> -->
                  <b-icon icon="three-dots-vertical" variant="dark" class="fillBlack sm-mar-left" scale="1.5" />
                </div>
              </template>
              <b-dropdown-form>
                <b-check-group v-model="filters.voicemails.extensions" @change="fetchReportingVoicemails();">
                  <b-check v-for="(extension, key) in extensions" :key="key" :value="key">
                    <Info :id="key" :is_blf="false" :is_suspended_show="false" :type="'user,team'" />
                  </b-check>
                </b-check-group>
              </b-dropdown-form>
            </b-dropdown>
          </div>
        </div>
      </div>
      <!-- <h2 class="dialer-settings-title newer">Voicemail</h2> -->
      <p class="dialer-settings-intro newerTextVersion mb-0">
        <span>
          Access detailed analytics of voicemails ,to review and respond to messages efficiently.
        </span>
      </p>
      <div class="d-flex align-items-center w-100">
        <!-- <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion mr-20px wd-33-imp">
          <label>Date</label>
          <vb-select 
            class="w-100"
            :selected="5"
            :options="[
              5,
              10,
              15,
              20,
              25,
            ]"
          />
        </div> -->
        <!-- <div class="whiteBGinputWithGreyRoundedBorder mr-20px dropdownVersion wd-33-imp">
          <label>Users</label>
          <vb-select 
            class="w-100"
            v-model="filters.voicemails.user"
            @change="fetchReportingVoicemails()"
            :options="users"
            textField="user.display_name"
            valueField="voipaccount"
          >
            <template #selected-option="{ option }">
              <div class="d-flex align-content-center justify-content-between w-100 h-100">
                <Info v-if="option" :id="option.voipaccount" :is_blf="false" :is_suspended_show="false" :type="'user'" />
                <span class="p-0" v-else>Select user</span>
                <b-button v-if="option" variant="link" class="align-content-center justify-content-center p-0 m-0 mr-2" style="line-height: 0;">
                  <b-icon icon="x" scale="1.5" @click="filters.voicemails.user='';fetchReportingVoicemails();" />
                </b-button>
              </div>
            </template>
            <template #option="{ option }">
              <Info :id="option.voipaccount" :is_blf="false" :is_suspended_show="false" :type="'user'" />
            </template>
            <template #no-record>There is no user</template>
          </vb-select>
        </div>
        <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion wd-33-imp">
          <label>Teams</label>
          <vb-select 
            class="w-100"
            v-model="filters.voicemails.team"
            @change="fetchReportingVoicemails()"
            :options="teams"
            textField="name"
            valueField="real_id"
          >
            
            <template #selected-option="{ option }">
              <div class="d-flex align-content-center justify-content-between w-100 h-100">
                <Info v-if="option" :id="option.real_id" :is_blf="false" :is_suspended_show="false" :type="'team'" />
                <span class="p-0" v-else>Select team</span>
                <b-button v-if="option" variant="link" class="align-content-center justify-content-center p-0 m-0 mr-2" style="line-height: 0;">
                  <b-icon icon="x" scale="1.5" @click="filters.voicemails.team='';fetchReportingVoicemails();" />
                </b-button>
              </div>
            </template>
            <template #option="{ option }">
              <Info :id="option.real_id" :is_blf="false" :is_suspended_show="false" :type="'team'" />
            </template>
            <template #no-record>There is no team</template>
          </vb-select>
        </div> -->
        <!-- <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion userTeamsFilter wd-33-imp">
          <label>Users & Teams</label>
          <b-dropdown>
            <template #button-content> 
              <b>{{ `${filters.voicemails.extensions.length ? filters.voicemails.extensions.length : 'No'} selected` }}</b> 
              <div class="d-flex align-items-center">
                <b-icon v-if="filters.voicemails.extensions.length>0" icon="x-circle-fill" varinat="#000000" @click.stop="filters.voicemails.extensions=[]" />
                <b-icon-chevron-down class="sm-mar-left" />
              </div>
            </template>
            <b-dropdown-form>
              <b-check-group v-model="filters.voicemails.extensions" @change="fetchReportingVoicemails();">
                <b-check v-for="(extension, key) in extensions" :key="key" :value="key">
                  <Info :id="key" :is_blf="false" :is_suspended_show="false" :type="'user,team'" />
                </b-check>
              </b-check-group>
            </b-dropdown-form>
          </b-dropdown>
        </div> -->
      </div>
      <vb-table 
        class="latestTableDesign-withBlackBorders-again"
        :isListEmpty="voicemails.length==0" 
        :listLength="voicemails.length" 
        :loading="api.fetch_voicemails.send" 
        :noRecordWholePage="true"
      >
        <tr slot="header">
          <th class="dialer-has-sort">
            <span>Number</span>
          </th>
          <!-- <th class="dialer-has-sort dialer-col-center">
            <span>Duration</span>
          </th> -->
          <th class="dialer-has-sort dialer-col-right dialer-missed">
            <span>Recording</span>
          </th>
        </tr>
        <template #body="{ start, end }">
          <tr v-for="(voicemail, index) in voicemails" :key="index" v-show="index >= start && index <= end">
            <td class="dialer-row-title dialer-col-name-width wd-75">
              <Info :only_name="true" v-if="voicemail.callerid" :id="voicemail.callerid | calleridTextFilter" /> 
              <b>{{voicemail.callerid | calleridTextFilter | number_formater}}</b>
              called 
              <Info :only_name="true" v-if="voicemail.mailboxuser" :id="voicemail.mailboxuser" /> 
            </td>
            <!-- <td class="dialer-col-center wd-25">{{ voicemail.duration | time_audio }}</td> -->
            <td class="dialer-col-center dialer-missed dialer-col-right wd-25">
              <div class="d-flex justify-content-end w-100">
                <vb-audio-player v-if="voicemail.filename" :src="voicemail.filename" :design="'minimum'" />
              </div>
            </td>
          </tr>
        </template>
        <template #loading>
          <tr v-for="n in 5" :key="n">
            <td>
              <div class="d-flex">
                <div class="latestShimmerDesign mr-2" style="height:35px;width:35px;"></div>
                <div class="d-flex flex-column justify-content-between">
                  <div class="latestShimmerDesign flex-1" style="height:16px;width:60px"></div>
                  <div class="latestShimmerDesign mt-1" style="height:16px;width:30px"></div>
                </div>
              </div>
            </td>
            <!-- <td>
              <div class="d-flex flex-column align-items-center w-100">
                <div class="latestShimmerDesign mt-2" style="height:16px;width:50px"></div>
              </div>
            </td> -->
            <td>
              <div class="d-flex flex-column align-items-center w-100">
                <div class="latestShimmerDesign mt-2" style="height:16px;width:50px"></div>
              </div>
            </td>
          </tr>
        </template>
        <template #no-record>
          <div class="latestGreyBox-9-9-2023 mb-32px">
          <div class="emptyScreenContainer">
            <img class="mt-50px w-50 h-auto" :src="require('@/assets/images/emptyScreenImages/teamAnalytics.png')"/>
            <div class="emptyScreenContainer-heading">
              Team analytics: dive into team performance
            </div>
            <div class="emptyScreenContainer-text w-75">
              Explore comprehensive analytics for your teams' call activities, including call durations, volumes, and more.
            </div>
            <!-- <button type="button" @click="''" class="dialer-button dialer-button-primary mt-20px">
              Get team insights
            </button> -->
          </div>
        </div>
        </template>
      </vb-table>
    </div>
  </div>
</template>

<script>
import { VOIP_API } from '@/utils';
import { mapState } from 'vuex';
import Info from '../Lists/Info.vue';
import _ from 'lodash';
export default {
  name: "Voicemail",
  components: { 
    Info, 
  },
  data() {
    return {
      api: {
        fetch_voicemails: this.$helperFunction.apiInstance({ 
          source: true,  
        }),
      },
      response: {
        voicemails: [],
      },
      filters: {
        voicemails: {
          // user: '',
          // team: '',
          extensions: [],
        },
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapState({
      users: (state)=>Object.values(state.common.voipusers ?? {}),
      teams: (state)=>state.common.teams?.data ?? [],
    }),
    extensions(){ 
      return {
        ..._.keyBy(this.users, 'voipaccount'),
        ..._.keyBy(this.teams, 'ringroup.accountcode'),
      }
    },
    voicemails(){ return this.response.voicemails/*_.orderBy(this.response.voicemails, 'origtime', 'desc') ?? []*/ },
  },
  filters: {
    calleridTextFilter(str){
      return str.substr(str.indexOf('<'),str.length).replace('<','').replace('>','')
    },
  },
  methods: {
    async fetchReportingVoicemails(){
      try {
        this.api.fetch_voicemails.source?.cancel?.("Requesting Again");
        this.api.fetch_voicemails.source=require("axios").default.CancelToken.source()
        this.api.fetch_voicemails.send=true
        const { data } = await VOIP_API.endpoints.call_activity.reportingVoicemail({
          // user: this.filters.voicemails.user,
          // team: this.filters.voicemails.team,
          extensions: this.filters.voicemails.extensions,
        },this.api.fetch_voicemails.source.token)
        this.response.voicemails=data?.data ?? []
      } catch (ex) {
        if(ex.cancel) return;
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_voicemails.send=false
      }
    },
  },
  mounted() {
    this.fetchReportingVoicemails();
  },
}
</script>

<style>

</style>