var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex align-items-start mt-24px w-100 TODO-comment-log-row"
  }, [_c('vb-avatar', {
    staticClass: "mr-3",
    attrs: {
      "is_blf": false,
      "id": _vm.payload.created_by
    }
  }), _c('div', {
    staticClass: "d-flex flex-column align-items-start w-100"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('div', {
    staticClass: "TODO-comment-name"
  }, [_vm._v(" " + _vm._s(_vm.getVoipUsersAlisesDisplayName[_vm.payload.created_by]) + " "), _c('div', {
    staticClass: "TODO-comment-commentedTime ml-2"
  }, [_vm._v(_vm._s(_vm._f("filter_date_current")(_vm.activity.created_at, null, {
    formate_now: true
  })))])]), _c('div', {
    staticClass: "TODO-comment-commentedTime showOnHover ml-2"
  }, [_vm._v(_vm._s(_vm._f("filter_date_current")(_vm.activity.created_at, '', {
    formate: 'hh:mm a , DD MMM YYYY'
  })))])]), _c('div', {
    staticClass: "todo-comment-smallGreyBox"
  }, [_vm.activity.action == _vm.types.create_task ? [_vm._v(" Created this task ")] : _vm.activity.action == _vm.types.update_task ? [_vm._v(" update task ")] : _vm.activity.action == _vm.types.assigned_task ? [_vm._v(" assigned to "), _c('Info', {
    staticClass: "ml-2",
    attrs: {
      "only_name": true,
      "is_blf": false,
      "id": _vm.payload.next
    }
  })] : _vm.activity.action == _vm.types.add_day ? [_vm._v(" added to my day ")] : _vm.activity.action == _vm.types.add_attachment ? [_vm._v(" added an attachment ")] : _vm.activity.action == _vm.types.remove_attachment ? [_vm._v(" remove an attachment ")] : _vm.activity.action == _vm.types.create_checklist ? [_vm._v(" created a checklist ")] : _vm.activity.action == _vm.types.create_comment ? [_vm._v(" commented ")] : _vm.activity.action == _vm.types.update_cdr_data ? [_vm._v(" linked a call ")] : _vm.activity.action == _vm.types.delete_checklist ? [_vm._v(" deleted a checklist ")] : _vm.activity.action == _vm.types.start_timer ? [_vm._v(" start the timer ")] : _vm.activity.action == _vm.types.stop_timer ? [_vm._v(" stop the timer " + _vm._s(_vm.payload.seconds) + "s ")] : _vm.activity.action == _vm.types.update_checklist ? [_vm._v(" update the check list item to \"" + _vm._s(_vm.payload.data.description.next) + "\" ")] : _vm.activity.action == _vm.types.update_task_notes ? [_vm._v(" update the notes ")] : _vm.activity.action == _vm.types.task_link ? [_vm._v(" task link ")] : _vm.activity.action == _vm.types.task_unlink ? [_vm._v(" unlink the task ")] : _vm.activity.action == _vm.types.user_assign_checklist ? [_vm._v(" assigned check list \"" + _vm._s(_vm.payload.description) + "\" to "), _c('b', {
    staticClass: "ml-2"
  }, [_vm._v(" " + _vm._s(_vm.getVoipUsersAlisesDisplayName[_vm.payload.next_id]))])] : _vm.activity.action == _vm.types.unassigned_task ? [_vm._v(" un assigned the task ")] : _vm.activity.action == _vm.types.assigned_viewer ? [_vm._v(" assigned "), _c('b', {
    staticClass: "mx-2"
  }, [_vm._v(" " + _vm._s(_vm.getVoipUsersAlisesDisplayName[_vm.payload.next]))]), _vm._v(" as task viewer ")] : _vm._e()], 2)])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }