<template>
  <modal class="sipActiveCall-Modal TransferCallModal center-zoomIn-transition" :transition="!getIsMobile ? 'center-zoomIn-transition ': ''" :name="modalName" :scrollable="false">
    <div class="dialer-call-notification-tray">
      <div class="dialer-call-notification">
        <!-- <div class="dialer-call-notification-close" @click="$modal.hide(modalName)">
          <vb-icon icon="dialer-close-icon" width="14" height="14" />
        </div> -->
        <div class="dialer-call-notification-inner w-100 justify-content-between">
          <div class="dialer-call-notification-text">
            <div class="dialer-call-notification-main-text">Transfer call</div>
            <div class="dialer-call-notification-sub-text">Send your call to another user</div>
          </div>
          <a class="newCloseButton" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </a>
        </div>
        <div class="dialer-call-notification-actions">
          <div class="dialer-call-notification-actions-inner">
            <b-form @submit.prevent="''" class="dialer-searchBox">
              <div class=" whiteBGinputWithGreyRoundedBorder">
                <b-form-input v-model="form.number" placeholder="Search here" @focus="isInputFocuse = true" />
              </div>
              <div v-show="isInputFocuse && !isEmpty(searchNumber)" class=" dialer-dropdown position-absolute top-35 dialer-fetchData-drop-down " >
                <div class="dropdown">
                  <ul class="dropdown-menu show">
                    <template v-for="data in searchNumber">
                      <template v-if="data">
                        <template v-if="data.user">
                          <li :key="data.voipaccount"  @click=" form.number = data.extn; isInputFocuse = false; " >
                            <a class="dropdown-item dialer_contact">
                              <span class="user-profile">
                                <vb-avatar :id="data.voipaccount" :image="data.profile_img" />
                              </span>
                              <div class="user-info">
                                <span class="user-name">{{ data | get_property('user.display_name') }}</span>
                                <span class="user-number">{{ data.extn }}</span>
                              </div>
                            </a>
                          </li>
                        </template>
                        <template v-else-if="data.object === 'UserSpeedDial'">
                          <li :key="data.id" @click=" form.number = data.fullNumber; isInputFocuse = false; " >
                            <a class="dropdown-item dialer_contact">
                              <span class="user-profile">
                                <vb-avatar :image="local_filess.images.user_male" :name="data.name" />
                              </span>
                              <div class="user-info">
                                <span class="user-name">{{ data.name }}</span>
                                <span class="user-number">{{ data.fullNumber }}</span>
                              </div>
                            </a>
                          </li>
                        </template>
                        <template v-else-if="data.object === 'PhoneBook'">
                          <li :key="data.id" @click=" form.number = getProperty( data, 'phones[0].number', number ); isInputFocuse = false; " >
                            <a class="dropdown-item dialer_contact">
                              <span class="user-profile">
                                <vb-avatar :image="local_filess.images.user_male" :name="data.emails" />
                              </span>
                              <div class="user-info">
                                <span class="user-name">{{ `${data.firstName} ${data.lastName}` }}</span>
                                <span class="user-number">{{ data | get_property("phones[0].number", "") }}</span>
                              </div>
                            </a>
                          </li>
                        </template>
                        <template v-else>
                          <li :key="data.id.toString()" v-show="false"></li>
                        </template>
                      </template>
                    </template>
                  </ul>
                </div>
              </div>
            </b-form>
            <div class="dialer-searchBox mt-0">
              <div class="dialer-tabs">
                <div class="dialer-tab-headers">
                  <div v-for="(tab, key) in tabs" :key="key" @click="filter.transfer.selected_tab = tab" :class="`dialer-tab text-capitalize ${ filter.transfer.selected_tab === tab ? 'selected' : '' }`" > {{ tab }} </div>
                </div>
                <div class="dialer-tab-content">
                  <div>
                    <ul class="list-unstyled dialer-call-transfer-picker mb-0 allow_scroll " >
                      <li v-for="(item, key) in searchedTransferCall" :key="key" @click="onSelect(item)" >
                        <div v-if="item.object === 'Teams'" class="dialer-transfer-info" >
                          <Info :id="item.real_id" :image="item | get_property('avatar.image.image')" :name="item.name" :sub_info="item | get_property('ringroup.extn')" />
                        </div>
                        <div v-else-if="item.object === 'GlobalSpeedDial'" class="dialer-transfer-info" >
                          <Info :image="item | get_property('avatar.image.image')" :name="item.name" :sub_info="item | get_property('fullNumber')" />
                        </div>
                        <div v-else-if="item.object === 'UserSpeedDial'" class="dialer-transfer-info" >
                          <Info :image="item | get_property('avatar.image.image')" :name="item.name" :sub_info="item | get_property('fullNumber')" />
                        </div>
                        <div v-else-if="!item.object && item.callRecording" class="dialer-transfer-info" >
                          <Info :id="item.voipaccount" :image="item.profile_img" :name="item | get_property('user.display_name')" :sub_info="item.extn" />
                        </div>
                        <div v-else-if="!item.object && item.label" class="dialer-transfer-info" >
                          <Info :image="item | get_property('avatar.image.image')" :name="item.label" />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
            <!-- :disabled="!isValidNumber"  -->
          <div class="d-flex justify-content-between">
            <button 
              type="button" 
              :class="`d-flex dialer-button md-mar-top dialer-button-primary ${ !isValidNumber ? 'disabled' : '' }`" 
              @click="transfer('soft')"
            >
              Soft Transfer
            </button>
              <!-- :disabled="!isValidNumber"  -->
            <button 
              type="button" 
              :class="`d-flex dialer-button md-mar-top dialer-button-primary ${ !isValidNumber ? 'disabled' : '' }`" 
              @click="transfer('hard')"
            >
              Hard Transfer
            </button>
            <button 
              type="button" 
              :class="`d-flex dialer-button md-mar-top dialer-button-primary ${ !isValidNumber ? 'disabled' : '' }`" 
              @click="testTransfer()"
            >
              Blind Transfer AMI
            </button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { $fn } from '@/utils';
import { mapGetters, mapState } from 'vuex';
import Info from '../Lists/Info.vue';
import { AMI_SOCKET } from '@/ami';
export default {
  components: { Info },
  name: "TransferCallModal",
  props: {
    phones: {
      type: Object,
      default: () => ({}),
      require: true,
    },
    session: {
      type: Object,
      default: () => ({}),
      require: true,
    },
    modalName: {
      type: String,
      default: 'TransferCallModal'
    },
  },
  data() {
    return {
      filter: {
        transfer: {
          search: "",
          selected_tab: "User",
        },
      },
      isInputFocuse: false,
      form: {
        number: "",
      },
      tabs: ["User", "Team"], //"IVR", "SpeedDial", 
    };
  },
  inject: ['local_filess','isEmpty', 'getProperty','appNotify'],
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getVoipTeams',
      'getIsMobile',
      'getVoipIVRS',
    ]),
    ...mapState({
      voipusers: (state)=>Object.values(state.common.voipusers),
      local_speed_dials: (state)=>state.common.speed_dials.local.data,
      global_speed_dials: (state)=>state.common.speed_dials.global.data,
      local_address_books: (state)=>state.common.address_books.local.data,
      global_address_books: (state)=>state.common.address_books.global.data,
      departments: (state)=>state.common.departments,
    }),
    searchedTransferCall() {
      const search = this.form.number.toString().toLowerCase().trim();
      const selected_tab = this.filter.transfer.selected_tab;
      const users = this.voipusers.filter((user) => !(user.voipaccount == this.getCurrentUser?.account || user.extn == this.session.number));
      const array = [
        ...this.getVoipTeams,
        ...users,
        ...Object.values(this.getVoipIVRS),
        ...this.local_speed_dials,
        ...this.global_speed_dials
      ];
      return array.filter((item) => {
        if (item.object === "Teams" && selected_tab == "Team") {
          return `${item.name || ""} ${item?.ringroup?.extn || ""}`.toString().toLowerCase().trim().includes(search);
        } else if (item.object === "GlobalSpeedDial" && selected_tab == "SpeedDial") {
          return `${item.name || ""} ${item.fullNumber || ""} ${item.shortNumber || ""}`.toString().toLowerCase().trim().includes(search);
        } else if (item.object === "UserSpeedDial" && selected_tab == "SpeedDial") {
          return `${item.name || ""} ${item.fullNumber || ""} ${item.shortNumber || ""}`.toString().toLowerCase().trim().includes(search);
        } else if (!item.object && item.callRecording && selected_tab == "User") {
          return `${item.user?.display_name || ""} ${item.extn || ""}`.toString().toLowerCase().trim().includes(search);
        } else if (!item.object && item.label && selected_tab == "IVR") {
          return `${item.label || ""} ${item.extn || ""}`.toString().toLowerCase().trim().includes(search);
        } else {
          return false;
        }
      });
    },
    searchNumber() {
      const search = this.form.number.toLowerCase().trim();
      const addressbooks = [...this.local_address_books,...this.global_address_books];
      const departments = this.departments;
      const speeddials = this.local_speed_dials;
      let array = [...speeddials, ...addressbooks, ...departments];
      if (!search) return [];
      return array.filter((data) => {
        if (data.user) {
          return `${data?.user?.display_name || ""} ${data.extn || ""} ${data.user.ac_user || ""}`.toLowerCase().trim().includes(search);
        } else if (data.object === "UserSpeedDial") {
          return `${data.fullNumber || ""} ${data.name || ""} ${data.shortNumber || ""}`.toLowerCase().trim().includes(search);
        } else if (data.object === "PhoneBook") {
          return `${data.emails || ""} ${data.firstName || ""} ${data.lastName || ""} ${data.nickname || ""}`.toLowerCase().trim().includes(search);
        } else {
          return false;
        }
      });
    },
    isValidNumber() {
      return /(^[0-9,*,#,+]\d+$)/.test(this.form.number);
    },
  },
  filters: {
    firstCharacter(value) {
      if (!value) return "";
      return value.charAt(0);
    },
  },
  methods: {
    transfer(type) {
      if (!this.isValidNumber) {
        this.appNotify({
          message: 'invalid number',
          type: 'danger',
        })
        return;
      }
      let number = this.form.number
      if(number==this.getCurrentUser.extn){
        this.appNotify({
          message: 'can not transfer call to own self',
          type: 'danger',
        })
        return;
      }
      if(number==this.session.number){
        this.appNotify({
          message: 'can not transfer call to the user which you are talking right now',
          type: 'danger',
        })
        return;
      }
      const uuid = $fn.makeid(5)
      if(type=='hard') {
        number = `##${number}`
        for (let index = 0; index < number.length; index++) {
          const num = number[index];
          this.phones.sendDTMF(num);
        }
      } else {
        this.phones.customBeforeTransfer(number,{
          is_transfer: true,
          uuid,
        });
        this.$store.state.calls.active_call=uuid
        this.$emit('tranfered',this.form.number)
        // this.phones.transfer(this.form.number);
        this.$modal.hide(this.modalName);
      }
    },
    testTransfer(){
      if (!this.isValidNumber) {
        this.appNotify({
          message: 'invalid number',
          type: 'danger',
        })
        return;
      }
      let number = this.form.number
      if(number==this.getCurrentUser.extn){
        this.appNotify({
          message: 'can not transfer call to own self',
          type: 'danger',
        })
        return;
      }
      if(number==this.session.number){
        this.appNotify({
          message: 'can not transfer call to the user which you are talking right now',
          type: 'danger',
        })
        return;
      }
      AMI_SOCKET.transferCall({
        number, 
        uniqueid: this.session.extra_data.uniqe_id,
      })
    },
    onSelect(item) {
      if (item.object == "Teams" && item.ringroup) {
        this.form.number = item.ringroup.extn;
      } else if (item.object == "GlobalSpeedDial") {
        this.form.number = item.fullNumber;
      } else if (item.object == "UserSpeedDial") {
        this.form.number = item.fullNumber;
      } else if (!item.object && !item.label && item.extn) {
        this.form.number = item.extn;
      } else if (!item.object && item.label && item.extn) {
        this.form.number = item.extn;
      }
      this.isInputFocuse = false;
    },
  },
};
</script>

<style>
</style>