<template>
  <div class="crmView">
    <div v-if="!GET_CRM_IS_CONNECTED">
      You are not connected with crm system right now contact for adminstrator for help 
      <div v-if="getCurrentUser.user_type=='main'">
        <button>
          Connect
        </button>
      </div>
    </div>
    <CrmHome v-if="activeTab != 'crm-setting'" ref="crm-setting" />
    <CRMSetting v-if="activeTab === 'crm-setting'" ref="crm-setting" />
  </div>
</template>

<script>
import { GET_CRM_IS_CONNECTED } from '@/store/helper/getters'
import { mapGetters } from 'vuex'
import CrmHome from './Home.vue'
import CRMSetting from './Settings.vue'
export default {
  name: 'CrmView',
  components: {
    CrmHome,
    CRMSetting
  },
  computed: {
    ...mapGetters([
      GET_CRM_IS_CONNECTED,
      'getCurrentUser',
    ]),
    activeTab(){ return this.$store.state.global_conditions.tab },
  },
}
</script>

<style>

</style>