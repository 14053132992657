<template>
  <modal 
    class="dialer_animation right_side_popup m-w-800 EditUserModal bigSquareCheckbox" 
    :id="`${_uid}-main-div`" 
    height="auto" 
    :scrollable="true" 
    :name="modalName" 
    @before-open="onBeforeOpen($event)" 
    @opened="onOpened()" 
    @before-close="onBeforeClose()"
  >
    <template v-if="api.fetch_user.send || api.delete_user.send">
      <div class="latestGreyBox-9-9-2023 mt-20px">
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex flex-fill">
            <div class="d-flex flex-column w-50">
              <div class="latestShimmerDesign mb-2" style="height: 21px;width: 105px;"></div>
              <div class="latestShimmerDesign" style="height: 50px;width: 100%;"></div>
            </div>
            <div class="d-flex flex-column w-50 pl-4">
              <div class="latestShimmerDesign mb-2" style="height: 21px;width: 75px;"></div>
              <div class="latestShimmerDesign" style="height: 50px;width: 90px;"></div>
            </div>
          </div>
          <div class="latestShimmerDesign" style="height: 135px;width: 135px;border-radius: 50%;"></div>
        </div>
        <div class="whiteBoxWithBorderInsideGreyBox mt-20px" v-for="n in 2" :key="n.id">
          <div class="whiteBoxWithBorderInsideGreyBox-row" v-for="n in 3" :key="n.id">
            <div class="d-flex flex-column w-50">
              <div class="latestShimmerDesign mb-2" style="height: 21px;width: 105px;"></div>
              <div class="latestShimmerDesign" style="height: 50px;width: 100%;"></div>
            </div>
            <div class="latestShimmerDesign" style="height: 50px;width: 100px;"></div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div id="scrollToTop" class="dialer-edit-header">
        <h2 class="dialer-settings-title newer mb-0">Edit user</h2>
        <div class="dialer-edit-actions">
          <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
          <a v-else class="newCloseButton" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </a>
        </div>
      </div>
      <div class="dialer-form latestGreyBox-9-9-2023 md-mar-bottom mt-24px" @submit.prevent="&quot;&quot;;">
        <UserGeneralInfoUpdateWidget class="p-0" v-if="data.accountcode" :accountcode="data.accountcode" />
        <!-- Numbers and teams section -->
        <div class="mt-24px">
          <div class="whiteBoxWithBorderInsideGreyBox">
            <!-- number -->
            <div class="whiteBoxWithBorderInsideGreyBox-row">
              <div class="d-flex flex-column">
                <div class="latestGreyBox-heading-main">Telephone numbers</div>
                <!-- <ul class="list-unstyled dialer-number-list mb-0">
                  <template v-if="dids.length > 0">
                    <li class="latestGreyBox-descriptionText" v-for="(did, key) in dids" :key="key"> 
                      <span class="mr-2" >
                        <img 
                          class="country-img" 
                          width="35px" 
                          :src="did | number_formater | flag_icon" 
                        />
                      </span>
                      <span class="font-18 ml-1">{{ did | number_formater }}</span>
                    </li>
                  </template>
                  <li v-else class="latestGreyBox-descriptionText">No numbers assigned</li>
                </ul> -->
                <div class="d-flex mt-12px IVR-numbers-container flex-wrap">
                  <template v-if="dids.length > 0">
                    <div v-for="(did, key) in dids" :key="key" class="newIVRLayout-number-item">
                      <img class="country-img" width="45px" :src="did | number_formater | flag_icon" />
                      <span class="newIVRLayout-number-item-text">{{ did | number_formater }}</span>
                    </div>
                  </template>
                  <div v-else class="latestGreyBox-descriptionText">No numbers assigned</div>
                </div>
              </div>
              <div class="lg-mar-left">
                <button 
                  class="newButton" 
                  type="button" 
                  @click="$modal.show(`${_uid}-AssignNumberModal`, {
                    accountcode: data.accountcode
                  })"
                >
                  <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                  <span class="newButton-textPart">Assign</span>
                </button>
              </div>
            </div>
            <!-- caller ids -->
            <div class="whiteBoxWithBorderInsideGreyBox-row">
              <div class="d-flex flex-column">
                <div class="latestGreyBox-heading-main">Outgoing calls only</div>
                <ul class="list-unstyled dialer-number-list mb-0">
                  <li class="latestGreyBox-descriptionText">{{ callerids.length==0 ? 'No Caller id Shared' : `${callerids.length} Numbers` }}</li>
                  <!-- <template v-if="callerids.length > 0">
                    <li class="latestGreyBox-descriptionText" v-for="callerid in callerids" :key="callerid.did"> 
                      <span class="mr-2" >
                        <img 
                          class="country-img" 
                          width="35px" 
                          :src="callerid.did | number_formater | flag_icon" 
                        />
                      </span>
                      <span class="font-18 ml-1">{{ callerid.did | number_formater }}</span>
                    </li>
                  </template>
                  <li v-else class="latestGreyBox-descriptionText">No Caller id Shared</li> -->
                </ul>
              </div>
              <div class="lg-mar-left">
                <button 
                  class="newButton" 
                  type="button" 
                  @click="$modal.show(`${_uid}-AssignCallerIds`, {
                    accountcode: data.accountcode
                  })"
                >
                  <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                  <span class="newButton-textPart">Share</span>
                </button>
              </div>
            </div>
            <!-- teams -->
            <div class="whiteBoxWithBorderInsideGreyBox-row">
              <div class="d-flex flex-column">
                <div class="latestGreyBox-heading-main">Teams</div>
                <div class="latestGreyBox-descriptionText">{{ response.user.account_teams | memberCount }}</div>
              </div>
              <div class="lg-mar-left">
                <a class="newButton sm-mar-left" @click="$modal.show(`${_uid}-AssignTeams`)">
                  <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                  <span class="newButton-textPart">Assign</span>
                </a>
              </div>
            </div>
            <!-- call recording -->
            <!-- <UserCallRecordingWidget v-if="data.accountcode" :accountcode="data.accountcode" /> -->
            <!-- unlimited -->
            <div class="whiteBoxWithBorderInsideGreyBox-row">
              <div class="d-flex flex-column">
                <div class="latestGreyBox-heading-main">Enable unlimited calls</div>
                <div class="latestGreyBox-descriptionText">Switch to unlimited calls within your plan, this may have an effect on your subscription.</div>
              </div>
              <b-checkbox 
                class="newerSwitch"
                switch 
                :checked="forms.update_unlimited.unlimited" 
                @change="changePrefix()" 
                :disabled="api.fetch_account_detail.send || api.change_prefixes.send" 
                ref="unlimited-toggle" 
              />
            </div>
            <!-- Set as admin section -->
            <div v-if="!isDefaultUser" class="whiteBoxWithBorderInsideGreyBox-row">
              <div class="d-flex flex-column">
                <div class="latestGreyBox-heading-main">Set as admin</div>
                <div class="latestGreyBox-descriptionText">This user is assigned the {{ forms.update_role.admin ?  'admin' : 'user' }} role</div>
              </div>
              <b-form-checkbox
                :disabled="api.update_role.send"
                class="newerSwitch"
                :checked="forms.update_role.admin"
                name="check-button"
                switch
                @change="updateUserRole()"
              />
            </div>
            <!-- Extension limit -->
            <div class="whiteBoxWithBorderInsideGreyBox-row">
              <div class="d-flex flex-column">
                <div class="latestGreyBox-heading-main">Extension limits</div>
              </div>
              <div class="lg-mar-left">
                <a class="newButton sm-mar-left" @click="$modal.show(`userLimitsModal`)">
                  <!-- <vb-icon icon="squared-add-icon" height="38px" width="38px" /> -->
                  <vb-icon icon="squared-show-icon" height="38px" width="38px" />
                  <span class="newButton-textPart">View</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- calls -->
      <div class="latestGreyBox-9-9-2023 mt-24px p-0">
        <div class="whiteBoxWithBorderInsideGreyBox p-0 b-0">
          <div class="whiteBoxWithBorderInsideGreyBox-row somethingInside p-0">
            <CallCopyC
              @updated="$emit('ofh-updated')" 
              @ofh-days-updated="$emit('ofh-days-updated')"
              @ofh-toggle-updated="$emit('ofh-toggle-updated')"
              @ofh-default-updated="$emit('ofh-default-updated')"
              @cf-updated="$emit('cf-updated')"
              class="input-consistent-in-grey-component w-100" 
              :accountcode="data.accountcode" 
              :user="response.user"
              :EditUserModal="true"
            />
          </div>
        </div>
      </div>
      <!-- phones -->
      <div class="latestGreyBox-9-9-2023 mt-24px p-0">
        <div class="whiteBoxWithBorderInsideGreyBox p-0 b-0">
          <div class="whiteBoxWithBorderInsideGreyBox-row somethingInside p-0">
            <DevicesLatest class="w-100" @updated="$emit('extension-updated')" :accountcode="data.accountcode" />
          </div>
        </div>
      </div>
      
      <!-- delete -->
      <div v-if="!isDefaultUser" class="latestGreyBox-9-9-2023 mt-24px">
        <div class="whiteBoxWithBorderInsideGreyBox">
          <!-- block -->
          <div v-if="!isDefaultUser && response.user.role=='0'" class="whiteBoxWithBorderInsideGreyBox-row">
            <div class="d-flex flex-column">
              <div class="latestGreyBox-heading-main">Block login</div>
              <!-- <div class="latestGreyBox-descriptionText"> {{ forms.update_block.block ?  'blocked' : 'un blocked' }}</div> -->
              <div class="latestGreyBox-descriptionText"> If enabled, user will not be able to login to this account.</div>
            </div>
            <b-form-checkbox
              :disabled="api.update_block.send"
              class="newerSwitch"
              :checked="forms.update_block.block"
              name="check-button"
              ref="block-switch"
              switch
              @change="updateBlock()"
            />
          </div>
          <div class="whiteBoxWithBorderInsideGreyBox-row">
            <div class="d-flex flex-column">
              <div class="latestGreyBox-heading-main">Delete this user?</div>
              <div class="latestGreyBox-descriptionText" v-if="voipuser && voipuser.action_required=='yes'">
                This user will be deleted on <b>{{ voipuser.actionable_date }}</b>
              </div>
            </div>
            <div v-if="voipuser && voipuser.action_required=='yes'">
            </div>
            <button v-else class="newDeleteButton" :disabled="api.delete_user.send" @click="beforeDelete()">
              <vb-icon icon="squared-delete-icon" height="38px" width="38px" />
              <span class="newDeleteButton-textPart d-flex align-items-center justify-content-center position-relative">
                <vb-spinner v-if="api.delete_user.send || api.fetch_user_number_list.send" />
                <template v-else>
                  Delete
                </template>
              </span>
            </button>
          </div>
        </div>
      </div>
      <!-- reset password -->
      <!--<div class="dialer-edit-user" >
        <div class="dialer-edit">
          <div class="dialer-modalContent">
            <form class="dialer-form md-mar-bottom" @submit.prevent="&quot;&quot;;">
              <template v-if="isInvitedUser">
                <div class="d-flex justify-content-end w-100">
                  <b-button class="dialer-button dialer-button-primary" :disabled="api.reset_password_request.send" @click="requestforreset()" variant="primary">
                    <vb-spinner v-if="api.reset_password_request.send" />
                    <template v-else>Reset password</template>
                  </b-button>
                </div>
              </template>
            </form>
          </div>
        </div>
      </div>-->
      <!-- extensions -->
      <!-- <div class="no-box-shadow editUsersShowMore mt-20px" v-if="conditions.show_more">
        <template>
          <div class="dialer-tab-content no-box-shadow">
            <ul class="list-unstyled dialer-asssignee-list">
              <UserExtensionLimitDetail class="extentionDetailsModal" :voipaccount="data.accountcode" />
            </ul>
          </div>
        </template>
      </div> -->
      <!-- show more toggle -->
      <!-- <div class="cursor_pointer d-flex w-100 justify-content-center py-3 mb-3 rounded-custom editUserModalShowMore mt-20px" @click="conditions.show_more=!conditions.show_more">
        <span class="font-weight-bold">
          {{ conditions.show_more ? 'Show less' : 'Show more' }}
          <b-icon :icon="conditions.show_more ? 'chevron-up' : 'chevron-down'"/>
        </span>
      </div> -->
      <!-- scroll to top -->
      <div v-if="conditions.show_scroll_top" @click="scroollToTop()" class="scrollToTopIconContainer">
        <b-icon icon="chevron-up" font-scale="1.3"/>
      </div>
      <!-- close -->
      <div v-if="conditions.show_scroll_top" @click="$modal.hide(modalName)" class="scrollToTopIconContainer TopCrossIcon">
        <b-icon icon="x" font-scale="1.3"/>
      </div>
    </template>
    <AssignCallerIdsModal :modalName="`${_uid}-AssignCallerIds`" @updated="getVoipUsers();" />
    <AssignNumberModal :modalName="`${_uid}-AssignNumberModal`" @added="response.user.account_telephone_numbers=$event.selected_list;getVoipUsers();" />
    <!-- <modal class="center-zoomIn-transition v2 m-w-500 RemoveNumberbeforeDeletingUserModal" :name="`${modalName}BeforeDelete`">
      <div class="w-100">
        <div class="d-flex align-items-center justify-content-between w-100">
          <h2 class="dialer-edit-title newer">Unassign numbers</h2>
          <button @click="$modal.hide(`${modalName}BeforeDelete`)" class="newCloseButton">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </button>
        </div>
        <template v-if="response.user_number_list.flag">
          <div class="latestGreyBox-9-9-2023 mt-20px" v-if="response.user_number_list.flag=='NUMBER_LINKED'">
            <div class="latestGreyBox-heading-main mt-0">Numbers linked</div>
            <div class="latestGreyBox-descriptionText">Please delete any of the following numbers linked to this user to delete a user</div>
            <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
              <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-3">
                <div class="latestGreyBox-heading-main mt-0">{{ response.user_number_list | get_property('detail.did') | number_formater }}</div>
                <button class="dialer-button dialer-button-delete-alt" @click="deleteUser(getProperty(response.user_number_list,'detail'))">Delete</button>
              </div>
            </div>
          </div>
          <div class="latestGreyBox-9-9-2023 mt-20px" v-else-if="response.user_number_list.flag=='NUMBER_LIST'">
            <div class="latestGreyBox-heading-main mt-0">Numbers list</div>
             <div class="beforeDeleteNumbers-container">
              <div class="beforeDeleteNumbers-item flex-column no-hover w-50 h-auto align-items-start" v-for="number in getProperty(response.user_number_list,'detail',[])" :key="number.id">
                <h6>{{ number.did | number_formater }}</h6>
                <div class="d-flex flex-wrap">
                  <button class="dialer-button dialer-button-primary mr-1 h-auto py-1 flex-fill mt-12px" @click="deleteUser(number,true)">
                    Keep
                  </button>
                  <button class="dialer-button dialer-button-delete-alt ml-1 h-auto py-1 flex-fill mt-12px" @click="deleteUser(number)">
                    Delete
                  </button>
                </div>
              </div>
            </div> 
            <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
              <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-3" v-for="number in getProperty(response.user_number_list,'detail',[])" :key="number.id">
                <div class="latestGreyBox-heading-main">{{ number.did | number_formater }}</div>
                <div class="d-flex flex-wrap">
                  <button class="dialer-button dialer-button-primary mr-1 h-auto py-1 flex-fill mt-12px" @click="deleteUser(number,true)">
                    Keep
                  </button>
                  <button class="dialer-button dialer-button-delete-alt ml-1 h-auto py-1 flex-fill mt-12px" @click="deleteUser(number)">
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="latestGreyBox-9-9-2023 mt-20px" v-else-if="response.user_number_list.flag=='NUMBER_RELEASED'">
            <div class="latestGreyBox-heading-main mt-0">Numbers list</div>
            <div class="latestGreyBox-descriptionText">Please unassign any of the following numbers to delete a user</div>
            <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
              <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-3" v-for="number in getProperty(response.user_number_list,'detail',[])" :key="number.id">
                <template v-if="number.forwardto==number.ringgroup_id">
                  <div>
                    <Info class="my-1" :is_blf="false" v-for="member in number.ring_group_members" :key="member.cg_item" :id="member.cg_item" />
                  </div>
                   <div class="d-flex align-items-center">
                    <Info :is_blf="false" :id="number | get_property('ring_group_members[0].cg_item')" />
                    <div class="w-auto dialer-dropdown dialer-dropDown-latest dialer-dropDown-onRight dialer-dropDown-p-1 ml-2" v-if="number.ring_group_members.length>1">
                      <div class="px-2 rounded more-numbers-tag">
                        <small>+ {{ number.ring_group_members.length-1 }} more</small>
                      </div>
                    </div>
                  </div> 
                </template>
                <Info v-else :is_blf="false" :id="number.forwardto" />
                <p class="my-0 latestGreyBox-descriptionText-14px">{{ number.did | number_formater }}</p>
              </div>
            </div>
          </div>
        </template>
      </div>
    </modal> -->
    <modal class="center-zoomIn-transition v2 m-w-500 RemoveNumberbeforeDeletingUserModal" :name="`${modalName}BeforeDelete`">
      <div class="w-100">
        <div class="d-flex align-items-center justify-content-between w-100">
          <h2 class="dialer-edit-title newer">Select a number to be removed</h2>
          <button @click="$modal.hide(`${modalName}BeforeDelete`)" class="newCloseButton">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </button>
        </div>
        <div class="vm--modal-text">To remove this account, you can either release an associated telephone number or choose to keep it under an independent subscription.</div>
        <div class="latestGreyBox-9-9-2023 mt-20px">
          <div class="">
            <div class="latestGreyBox-heading-main">Number assigned to user to be deleted</div>
            <div class="whiteBoxWithBorderInsideGreyBox mt-8px">
              <div class="whiteBoxWithBorderInsideGreyBox-row py-3 min-height-unset" v-if="response.user_number_list.linked_numbers && response.user_number_list.linked_numbers.length==0">
                <div class="RemoveNumberbeforeDeletingUserModal-emptyScreenContainer">
                  <img width="20%" height="auto" :src="require('@/assets/images/emptyScreenImages/RemoveNumberbeforeDeletingUserModal-noNumber.png')"/>
                  <div class="RemoveNumberbeforeDeletingUserModal-emptyScreenContainer-text">
                    There are no telephone numbers currently assigned to this user. Please 
                    select a number from the unassigned list below to either remove or keep 
                    keep under a new subscription.
                  </div>
                </div>
              </div>
              <div class="whiteBoxWithBorderInsideGreyBox-row py-3 min-height-unset"
                v-for="number in response.user_number_list.linked_numbers" 
                :key="number.id" 
              >
                <b-form-checkbox class="dialer_full_width_checkbox circle_btn_checkbox circle_btn_onRight p-0 makeCheckBoxesSquare less-padding leastPadding"
                  :checked="selected.number && selected.number.id==number.id" 
                  @change="selected.number=selected.number && selected.number.id==number.id ? null : number"
                >
                  <div class="d-flex align-items-center">
                    <img class="country-img" width="45px" :src="number.did | number_formater | flag_icon" />
                    <span class="RemoveNumberbeforeDeletingUserModal-did ml-16px">{{ number.did | number_formater }}</span>
                    <span v-if="number.name" class="RemoveNumberbeforeDeletingUserModal-did-label ml-8px">{{ `( ${number.name} )` }}</span>
                    <span v-if="number.price" class="RemoveNumberbeforeDeletingUserModal-did-label ml-8px">{{ `${number.price | to_fixed}` }}</span>
                  </div>
                </b-form-checkbox>
              </div>
              <!-- <b-form-checkbox class="whiteBoxWithBorderInsideGreyBox-row"
                v-for="number in response.user_number_list.linked_numbers" 
                :checked="selected.number && selected.number.id==number.id" 
                :key="number.id" 
                @change="selected.number=number"
              >
                <p class="my-0">
                  <span>
                    <img class="country-img" width="45px" :src="number.did | number_formater | flag_icon" />
                  </span>
                  <span class="d-flex flex-column ml-1 dialer-numberInfo justify-content-center">
                    <span class="m-0 didNumber">{{ number.did | number_formater }}</span>
                  </span>
                </p>
                <p v-if="number.name" class="my-0">{{ `( ${number.name} )` }}</p>
                <p v-if="number.price" class="my-0">{{ `${number.price | to_fixed}` }}</p>
              </b-form-checkbox> -->
            </div>
          </div>
          <div>
            <div class="latestGreyBox-heading-main mt-20px">Other unassign number</div>
            <div class="whiteBoxWithBorderInsideGreyBox mt-8px">
              <div class="whiteBoxWithBorderInsideGreyBox-row py-3 min-height-unset" v-if="response.user_number_list.unassigned_numbers && response.user_number_list.unassigned_numbers.length==0">
                <div class="RemoveNumberbeforeDeletingUserModal-emptyScreenContainer">
                  <img width="20%" height="auto" :src="require('@/assets/images/emptyScreenImages/RemoveNumberbeforeDeletingUserModal-noNumber.png')"/>
                  <div class="RemoveNumberbeforeDeletingUserModal-emptyScreenContainer-text">
                    There are no unassigned telephone numbers available at this time.  
                    Please select a number from the assigned list above to either remove or 
                    keep under a new subscription.
                  </div>
                </div>
              </div>
              <div class="whiteBoxWithBorderInsideGreyBox-row py-3 min-height-unset"
                v-for="number in response.user_number_list.unassigned_numbers" 
                :key="number.id" 
              >
                <b-form-checkbox class="dialer_full_width_checkbox circle_btn_checkbox circle_btn_onRight p-0 makeCheckBoxesSquare less-padding leastPadding"
                  :checked="selected.number && selected.number.id==number.id" 
                  @change="selected.number=selected.number && selected.number.id==number.id ? null : number"
                >
                  <div class="d-flex align-items-center">
                    <img class="country-img" width="45px" :src="number.did | number_formater | flag_icon" />
                    <span class="RemoveNumberbeforeDeletingUserModal-did ml-16px">{{ number.did | number_formater }}</span>
                    <span v-if="number.name" class="RemoveNumberbeforeDeletingUserModal-did-label ml-8px">{{ `( ${number.name} )` }}</span>
                    <span v-if="number.price" class="RemoveNumberbeforeDeletingUserModal-did-label ml-8px">{{ `${number.price | to_fixed}` }}</span>
                  </div>
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex mt-20px">
          <div class="w-50 pr-2">
            <button class="fullWidthBlueButton" :disabled="!selected.number" @click="deleteUser(true)">Keep number and add subscription</button>
          </div>
          <div class="w-50 pl-2">
            <button class="fullWidthRedButton" :disabled="!selected.number" @click="deleteUser()">Remove selected numbers</button>
          </div>
        </div>
      </div>
    </modal>
    <modal 
      class="dialer_animation right_side_popup m-w-800 EditUserModal bigSquareCheckbox" 
      height="auto" 
      :scrollable="true" 
      :name="`${_uid}-AssignTeams`" 
      @before-open="fetchTeams()" 
    >
      <div class="dialer-assign-to md-pad-bottom dialer-edit">
        <div class="dialer-edit-header d-block">
          <div class="d-flex justify-content-between align-items-center">
            <h2 class="dialer-settings-title newer mb-0">
              <vb-svg :disabled="api.assign_teams.send" @click="$modal.hide(`${_uid}-AssignTeams`)" class="cursor_pointer_no_hover" name="leftLongArrow-icon" width="20" height="12" viewBox="0 0 30 16" stroke-width="1" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" />
              Teams
            </h2>
            <div class="dialer-edit-actions d-flex"> 
              <!-- <button class="newCloseButton" :disabled="api.assign_teams.send" @click="$modal.hide(`${_uid}-AssignTeams`)">
                <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                <span class="newCloseButton-textPart">Close</span>
              </button> -->
              <button class="newDoneButton ml-16px" :disabled="api.assign_teams.send" @click="assignTeams()">
                <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                <span class="newDoneButton-textPart">Done</span>
              </button>
            </div>
          </div>
          <div class="vm--modal-text">View or select the team(s) associated with this user.</div>
        </div>
        <div class="latestGreyBox-9-9-2023 mt-20px">
          <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
            <template v-if="api.fetch_teams.send">
              <div class="whiteBoxWithBorderInsideGreyBox-row" v-for="n in 3" :key="n.id">
                <div class="d-flex align-items-center w-50">
                  <div class="latestShimmerDesign mr-2" style="height: 35px;width: 35px;"></div>
                  <div class="latestShimmerDesign" style="height: 21px;width: 40%;"></div>
                </div>
                <div class="latestShimmerDesign" style="height: 24px;width: 24px;"></div>
              </div>
            </template>
            <template v-else>
              <div v-for="(team, key) in teams" :key="team.id" class="whiteBoxWithBorderInsideGreyBox-row min-height-unset ">
                <b-form-checkbox 
                  :id="key" 
                  :disabled="api.assign_teams.send"
                  :value="team.id" 
                  v-model="selected.teams" 
                  name="checkbox-1"
                  class="dialer_full_width_checkbox circle_btn_checkbox circle_btn_onRight p-0 makeCheckBoxesSquare less-padding leastPadding"
                >
                  <Info :id="team.extn" :is_blf="false" />
                </b-form-checkbox>
              </div>
            </template>
          </div>
        </div>
      </div>
    </modal>
    <modal class="userLimitsModal" name="userLimitsModal">
      <div class="dialer-edit-header pt-0">
        <h2 class="dialer-settings-title newer mb-0">Extension limits</h2>
        <div class="dialer-edit-actions">
          <a class="newCloseButton" @click="$modal.hide('userLimitsModal')">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </a>
        </div>
      </div>
      <UserExtensionLimitDetail class="extentionDetailsModal" :voipaccount="data.accountcode" />
    </modal>
  </modal>
</template>

<script>
import { VOIP_API, $fn, } from "../../utils";
import UserExtensionLimitDetail from "../User/UserExtensionsDetail.vue";
import DevicesLatest from "../Setting/DevicesLatest.vue";
import CallCopyC from "../Setting/CallCopyC.vue";
import _ from "lodash";
import AssignNumberModal from "./AssignNumberModal.vue";
import { mapGetters } from 'vuex';
import Info from '../Lists/Info.vue';
import AssignCallerIdsModal from './AssignCallerIdsModal.vue'
import UserGeneralInfoUpdateWidget from '../widgets/users/UserGeneralInfoUpdate.vue';
import { check_service } from '@/mixin';
//import UserCallRecordingWidget from '../widgets/users/CallRecording.vue';
export default {
  name: "EditUserModal",
  mixins: [check_service],
  components: {
    UserExtensionLimitDetail,
    AssignNumberModal,
    DevicesLatest,
    CallCopyC,
    Info,
    UserGeneralInfoUpdateWidget,
    AssignCallerIdsModal,
  //  UserCallRecordingWidget,
  },
  inject: ['local_filess', 'getProperty','getVoipUsers','appNotify'],
  props: {
    modalName: {
      type: String,
      default: 'EditUserModal'
    },
  },
  data() {
    return {
      forms: {
        update_unlimited: this.$helperFunction.formInstance({
          data: {
            unlimited: false,
          }
        }),
        update_role: this.$helperFunction.formInstance({
          data: {
            admin: false,
          }
        }),
        update_block: this.$helperFunction.formInstance({
          data: {
            block: false,
          }
        }),
        reset(){
          this.update_unlimited.reset()
          this.update_role.reset()
          this.update_block.reset()
        },
      },
      api: {
        fetch_user: this.$helperFunction.apiInstance(),
        fetch_subscription: this.$helperFunction.apiInstance({  }),
        fetch_account_detail: this.$helperFunction.apiInstance(),
        fetch_user_number_list: this.$helperFunction.apiInstance(),
        delete_user: this.$helperFunction.apiInstance({  }),
        // reset_password_request: this.$helperFunction.apiInstance({  }),
        update_role: this.$helperFunction.apiInstance({  }),
        update_block: this.$helperFunction.apiInstance({  }),
        change_prefixes: this.$helperFunction.apiInstance(),
        fetch_teams: this.$helperFunction.apiInstance({  }),
        assign_teams: this.$helperFunction.apiInstance(),
        reset(){
          this.fetch_user.reset()
          this.fetch_subscription.reset()
          this.fetch_account_detail.reset()
          this.fetch_user_number_list.reset()
          this.delete_user.reset()
          // this.reset_password_request.reset()
          this.update_role.reset()
          this.update_block.reset()
          this.change_prefixes.reset()
          this.fetch_teams.reset()
          this.assign_teams.reset()
        },
      },
      response: this.$helperFunction.stateInstance({
        data: {
          subscription: {},
          user: {},
          user_number_list: {},
          teams: {},
        },
      }),
      conditions: this.$helperFunction.stateInstance({
        data: {
          show_more: false,
          show_scroll_top: false,
        },
      }),
      data: this.$helperFunction.stateInstance({
        data: {
          accountcode: '',
        },
      }),
      selected: this.$helperFunction.stateInstance({
        data: {
          teams: [],
          number: null,
        },
      }),
    };
  },
  computed:{
    ...mapGetters([
      'getCurrentUser',
      'getUserPermissions',
      'getVoipUsersAlises',
      'getIsMobile',
    ]),
    isDefaultUser(){ return this.userType=='main' },
    isInvitedUser(){ return this.userType=='invite' },
    voipuser(){ return this.getVoipUsersAlises[this.data.accountcode] },
    userType(){ return this.voipuser?.user?.user_type },
    isFreePlan(){ return this.response.subscription?.userCurrentSubscription?.plan_detail?.plan_free==1 },
    dids(){ return Object.values(this.response.user?.account_telephone_numbers || {}) },
    callerids(){ return this.voipuser?.shared_caller_Ids ?? [] },
    teams(){ return this.response.teams?.list ?? [] },
  },
  filters: {
    memberCount(value) {
      if (!value) return "No teams selected"
      if (Object.keys(value).length > 1) return Object.keys(value).length + " teams";
      return Object.keys(value).length + " team";
    },
  },
  methods: {
    onBeforeOpen(event) {
      this.data.accountcode=event?.params?.accountcode ?? ''
      this.fetchUser()
    },
    onBeforeClose() {
      this.data.reset()
      this.response.reset()
      this.conditions.reset()
      this.api.reset()
      this.forms.reset()
      this.selected.reset()
    },
    scroollToTop(){
      document.getElementById(`${this._uid}-main-div`).scrollTop=0
    },
    async onOpened(){
      let vm = this
      const el = document.getElementById(`${this._uid}-main-div`)
      await this.$nextTick()
      if(el){
        el.onscroll = function(event){
          vm.conditions.show_scroll_top=event.target.scrollTop>500
        }
      }
    },
    async fetchAccountDetail(){
      this.api.fetch_account_detail.send = true;
      try {
        const { data } = await VOIP_API.endpoints.users.accountdetail()
        this.forms.update_unlimited.unlimited=data?.users?.[this.data.accountcode]?.plan_prefix_type=='unlimited'
      } finally {
        this.api.fetch_account_detail.send = false
      }
    },
    async fetchSubscriptionDetail(){
      if(this.api.fetch_subscription.send) return;
      try {
        const { data } = await VOIP_API.endpoints.billing.getsubscriptiondetail({ 
          uid: this.getCurrentUser.uid,
        })
        this.response.subscription = data;
      } finally {
        this.api.fetch_subscription.send = false;
      }
    },
    async fetchUser(){
      this.api.fetch_user.send=true
      try {
        const { data } = await VOIP_API.endpoints.users.detail(this.data.accountcode)
        this.response.user = data;
        this.forms.update_role.admin = ["1", "3", 1].includes(data?.role)
        this.forms.update_block.block = this.voipuser?.login_enable!='1'
        this.fetchSubscriptionDetail();
        this.fetchAccountDetail();
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_user.send=false
      }
    },
    // async changePrefix(){
    //   if(this.api.change_prefixes.send) return;
    //   try {
    //     const { confirm } = await this.$appConfirmation({
    //       title: "Warning",
    //       message: `Are you sure you want to switch to unlimited call`,
    //       button: {
    //         no: "no",
    //         yes: "yes",
    //       },
    //     })
    //     if(!confirm) return;
    //     this.api.change_prefixes.send=true
    //     await VOIP_API.endpoints.techprifex.update(this.data.accountcode,{
    //       account: this.data.accountcode,
    //       accountcode: this.data.accountcode,
    //     })
    //     this.forms.update_unlimited.unlimited=!this.forms.update_unlimited.unlimited
    //   } catch (ex) {
    //     this.appNotify({
    //       message: ex.own_message,
    //       type: 'error',
    //     })
    //   } finally {
    //     const toggle_ref = this.$refs?.[`unlimited-toggle`]
    //     if(toggle_ref) toggle_ref.localChecked = this.forms.update_unlimited.unlimited;
    //     this.api.change_prefixes.send=false
    //   }
    // },
    // prefixes
    async changePrefix(){
      if(this.api.change_prefixes.send) return;
      try {
        this.api.change_prefixes.send=true
        if(!this.forms.update_unlimited.unlimited) {
          const payload = await this.checkServicePromise({
            service: 'unlimited_call_plan',
            account: this.data.accountcode,
          })
          if(payload?.finally) return;
          await VOIP_API.endpoints.techprifex.update(this.data.accountcode,{
            account: this.data.accountcode,
            accountcode: this.data.accountcode,
          })
          this.forms.update_unlimited.unlimited=!this.forms.update_unlimited.unlimited
        } else {
          const { confirm } = await this.$appConfirmation({
            title: 'Warning',
            message: `With this action you are going to downgrade your package.`,
            button: {
              yes: 'Now',
              no: 'Till Next Billing Date',
            },
          })
          if(confirm==null) return;
          await VOIP_API.endpoints.techprifex.update(this.data.accountcode,{
            account: this.data.accountcode,
            accountcode: this.data.accountcode,
            type: 'downgrade_call_plan_prefix',
            billing: confirm?0:1,
          })
        }
        this.fetchAccountDetail()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.change_prefixes.send=false
        const toggle_ref = this.$refs?.[`unlimited-toggle`]
        if(toggle_ref) toggle_ref.localChecked = this.forms.update_unlimited.unlimited;
      }
    },
    async updateUserRole(){
      if(this.api.update_role.send) return;
      try {
        this.api.update_role.send=true
        const { data: { data } } = await VOIP_API.endpoints.users.updateuserrole(this.data.accountcode)
        this.forms.update_role.admin = ["1", "3", 1].includes(data?.[this.data.accountcode]?.user?.userRole)
        if(_.isEmpty(data)) throw 'Users is Empty'
        let voipusers = await $fn.manipulateVoipUsers(voipusers,this.$store.state.common.voipusers)
        voipusers = _.keyBy(voipusers,'voipaccount')
        this.$store.commit("setVoipUsers", voipusers);
        this.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.update_role.send=false
      }
    },
    async updateBlock(){
      if(this.api.update_block.send) return;
      try {
        this.api.update_block.send=true
        const { confirm } = await this.$appConfirmation({
          title: 'Warning',
          message: `Are You sure you want to block this user`,
          button: {
            yes: 'Yes',
            no: 'No',
          },
        })
        if(!confirm) return;
        await VOIP_API.endpoints.users.updateblockstate(this.data.accountcode)
        this.forms.update_block.block = !this.forms.update_block.block
        this.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
        this.getVoipUsers()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'error',
        })
      } finally {
        this.$refs['block-switch'].localChecked=this.forms.update_block.block
        this.api.update_block.send=false
      }
    },
    // async requestforreset(){
    //   if(this.api.reset_password_request.send) return;
    //   try {
    //     const { confirm } = await this.$appConfirmation({
    //       title: "warning",
    //       message: `Are you sure you want to request for reset password?`,
    //       button: {
    //         no: "No",
    //         yes: "Yes",
    //       },
    //     })
    //     if(!confirm) return;
    //     this.api.reset_password_request.send = true;
    //     await VOIP_API.endpoints.user.account(this.data.accountcode, {
    //       uid: this.getCurrentUser?.uid,
    //       action: "user_password",
    //     })
    //     this.appNotify({
    //       message: 'Successfully Send the request',
    //       type: 'success',
    //     })
    //   } catch (ex) {
    //     this.appNotify({
    //       message: ex.own_message,
    //       type: 'error',
    //     })
    //   } finally {
    //     this.api.reset_password_request.send = false;
    //   }
    // },
    // delete user
    async beforeDelete(){
      // console.log('run')
      if(this.api.fetch_user_number_list.send) return;
      try {
        if(this.isFreePlan){
          this.deleteUser()
        } else {
          this.api.fetch_user_number_list.send=true
          const { data: user_number_list } = await VOIP_API.endpoints.users.userNumberList({
            accountcode: this.data.accountcode,
          })
          this.response.user_number_list=user_number_list
          if(typeof user_number_list.skip_number != 'undefined' && user_number_list.skip_number>0){
            this.deleteUser()
          } else if(user_number_list.linked_numbers && user_number_list.linked_numbers.length==0 && user_number_list.unassigned_numbers && user_number_list.unassigned_numbers.length==0) {
            const { confirm } = await this.$appConfirmation({
              title: "No Numbers Available for Deletion",
              message: `You are about to delete this user, but there are no assigned or unassigned telephone numbers available.`,
              variant: 'danger',
              alert: {
                variant: 'danger',
                title: 'Warning',
                message: `To proceed with deleting this user, you must first release a telephone number. Without a number to remove, the account cannot be deleted.`,
              },
              button: {
                no: "Cancel",
                yes: "Unassigned number",
              },
            })
            if(!confirm) return;
            else return;
          } else {
            this.$modal.show(`${this.modalName}BeforeDelete`)
          }
        }
      } catch(ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_user_number_list.send=false
      }
    },
    async deleteUser(keep=false) {
      if(this.api.delete_user.send) return;  /*|| !number*/
      try {
        const { confirm } = await this.$appConfirmation({
          title: "Delete user?",
          message: `Are you sure you want to delete this user.`,
          variant: 'danger',
          alert: {
            variant: 'danger',
            title: 'Warning',
            message: `This action is irreversible, You will lose all the configurations for this user`,
          },
          button: {
            no: "No",
            yes: "Yes",
          },
        })
        if(!confirm) return;
        this.$modal.hide(`${this.modalName}BeforeDelete`)
        this.api.delete_user.send=true
        const number_id = this.selected.number?.id
        const number_did = this.selected.number?.did
        if(keep) {
          await VOIP_API.endpoints.users.deleteAndKeepNumber({
            user_account: this.data.accountcode,
            number_id: number_id,
            number: number_did,
            uid: this.getCurrentUser?.uid,
            accountcode: this.getCurrentUser?.default_accountcode,
          })
        } else {
          await VOIP_API.endpoints.users.delete({
            id: this.data.accountcode,
            number_id: number_id,
            uid: this.getCurrentUser?.uid,
            accountcode: this.getCurrentUser?.account,
          })
        }
        await $fn.sleep(15 * 1000)
        this.getVoipUsers()
        this.$modal.hide(this.modalName)
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.delete_user.send=false
      }
    },
    // teams
    async assignTeams() {
      if(this.api.assign_teams.send) return;
      try {
        this.api.assign_teams.send=true
        const selected = await this.selected.teams.filter(i=>!this.response.teams.selected.includes(i))
        const deleted = await this.response.teams.selected.filter(i=>!this.selected.teams.includes(i))
        const { data } = await VOIP_API.endpoints.teams.updateTeams({
          extension: this.data.accountcode,
          fid: 12,
          teams: this.selected.teams.join(","),
          new_selected: selected.join(","),
          old_deleted: deleted.join(","),
        })
        this.response.user.account_teams = data;
        this.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
        this.$modal.hide(`${this._uid}-AssignTeams`)
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.assign_teams.send=false
      }
    },
    async fetchTeams() {
      if(this.api.fetch_teams.send) return;
      try {
        this.api.fetch_teams.send = true;
        const { data } = await VOIP_API.endpoints.teams.extensionTeams({ 
          extension: this.data.accountcode, 
          fid: 12 
        })
        this.response.teams = data ?? [];
        this.selected.teams = _.cloneDeep(data?.selected ?? []);
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_teams.send = false;
      }
    },
  },
};
</script>
