var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('HorizontalLayout', {
    attrs: {
      "text": `Press Option ${_vm.dtmf}`,
      "voipaccount": _vm.voipaccount
    },
    on: {
      "change-graph": function ($event) {
        return _vm.$emit('interface', $event);
      }
    }
  }, [_c('div', {
    attrs: {
      "slot": "right"
    },
    slot: "right"
  }, [_c('info', {
    attrs: {
      "id": _vm.voipaccount,
      "name": _vm.name,
      "is_blf": false
    }
  }), _c('div', {
    class: `d-flex ${_vm.ivr ? 'justify-content-between' : 'justify-content-end'} align-items-center w-100 mt-3 position-relative`
  }, [_vm.ivr ? _c('vb-audio-player', {
    staticClass: "audioForMobile",
    attrs: {
      "src": _vm._f("welcomgreetingsrc")(_vm.ivr),
      "design": 'minimum'
    }
  }) : _vm._e(), _c('b-icon', {
    staticClass: "cursor_pointer ml-3",
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "icon": "pencil-fill",
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.edit();
      }
    }
  }), _c('b-icon', {
    staticClass: "cursor_pointer ml-3",
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "icon": "trash-fill",
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.remove();
      }
    }
  })], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }