var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "position": "relative",
      "width": "800px"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex": "1",
      "opacity": "0"
    }
  }, [_c('div', {
    staticClass: "node nodeBasic arrowOnLeftSide"
  }, [_c('MoreInfoNode', {
    attrs: {
      "graph": _vm.graph,
      "moreText": _vm.moreText,
      "voipaccount": _vm.voipaccount
    },
    on: {
      "interface": function ($event) {
        return _vm.$emit('change-graph', $event);
      }
    }
  })], 1), _c('div', {
    staticStyle: {
      "border-top": "2px solid #c5ccd0",
      "width": "50px"
    }
  })]), _c('div', {
    staticClass: "nodeBasic-container",
    staticStyle: {
      "display": "flex",
      "flex": "1",
      "justify-content": "center",
      "align-items": "flex-start"
    }
  }, [_vm._t("main")], 2), _c('div', {
    staticStyle: {
      "display": "flex",
      "flex": "1"
    },
    style: `opacity: ${_vm.showMore ? '' : '0'}`
  }, [_c('div', {
    staticClass: "mt-4",
    staticStyle: {
      "border-top": "2px solid #c5ccd0",
      "width": "50px"
    }
  }), _c('div', {
    staticClass: "node nodeBasic minWidth-200",
    staticStyle: {
      "flex": "1"
    }
  }, [_c('MoreInfoNode', {
    staticClass: "mb-2",
    attrs: {
      "graph": _vm.graph,
      "moreText": _vm.moreText,
      "voipaccount": _vm.voipaccount
    },
    on: {
      "interface": function ($event) {
        return _vm.$emit('change-graph', $event);
      }
    }
  })], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }