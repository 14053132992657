<template>
  <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
    <!-- Push Notification -->
    <div class="whiteBoxWithBorderInsideGreyBox-row">
      <div class="d-flex flex-column">
        <div class="latestGreyBox-heading-main">Enable push notifications</div>
        <div class="latestGreyBox-descriptionText">Get push alerts for call activity</div>
      </div>
      <div class="w-fit-content">
        <div v-if="api.fetch_notification_settings.send" class="latestShimmerDesign" style="height:34px;width:60px;border-radius: 25px"></div>
        <div
          v-else
        >
          <b-form-checkbox
            v-b-tooltip :title="getUserPermissions.enable_push_notifications?'':'Your package does not support this feature'"
            class="newerSwitch"
            ref="push_notification"
            :disabled="!getUserPermissions.enable_push_notifications || api.change_push_notification.send || api.fetch_notification_settings.send"
            :checked="data.push_notification"
            @change="changePushNotification($event)"
            name="check-button"
            switch
          />
        </div>
      </div>
    </div>
    <!-- Voicemail Notification -->
    <div class="whiteBoxWithBorderInsideGreyBox-row flex-column justify-content-center align-items-start">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <div>
          <!-- <div class="latestGreyBox-heading-main">Send voicemail alerts to email</div> -->
          <div class="latestGreyBox-heading-main">Voicemail</div>
          <div class="latestGreyBox-descriptionText">Get voicemail alerts in your email inbox. Stay connected, even away from the phone.</div>
        </div>
        <div class="w-fit-content">
          <div v-if="api.fetch_notification_settings.send" class="latestShimmerDesign" style="height:34px;width:60px;border-radius: 25px"></div>
          <b-form-checkbox
            v-else
            class="newerSwitch"
            ref="voicemail_notification"
            :disabled="api.change_voicemail_notification.send || api.fetch_notification_settings.send"
            :checked="data.voicemail_notification"
            @change="changeVoicemailNotification($event)"
            name="check-button"
            switch
          />
        </div>
      </div>
      <div class="latestGreyBox-9-9-2023 mt-20px w-100" v-if="data.voicemail_notification">
        <div class="whiteBoxWithBorderInsideGreyBox">
          <!-- Voicemail attachment Notification -->
          <div class="whiteBoxWithBorderInsideGreyBox-row">
            <div class="d-flex flex-column">
              <div class="latestGreyBox-heading-main">Attach voicemail recording</div>
              <div class="latestGreyBox-descriptionText">Include the voicemail recording as an attachment to the email.</div>
            </div>
            <div class="w-fit-content">
              <div v-if="api.fetch_notification_settings.send" class="latestShimmerDesign" style="height:34px;width:60px;border-radius: 25px"></div>
              <b-form-checkbox
                v-else
                class="newerSwitch"
                ref="voicemail_attachment"
                v-b-tooltip :title="getUserPermissions.voicemail_attachment?'':'Your package does not support this feature'"
                :disabled="api.change_voicemail_attachment.send || !getUserPermissions.voicemail_attachment || api.fetch_notification_settings.send"
                :checked="data.voicemail_attachment"
                @change="changeVoicemailAttachment($event)"
                name="check-button"
                switch
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Missed Call Notification -->
    <MissedCallNotificationWidget :accountcode="accountcode" />
    <!-- After Call Feedback -->
    <div v-if="isCurrentUser" class="whiteBoxWithBorderInsideGreyBox-row">
      <div class="d-flex flex-column">
        <div class="latestGreyBox-heading-main">After call feedback</div>
        <div class="latestGreyBox-descriptionText">Have better insight with 'after call feedback'. Appearing at the end of every call</div>
      </div>
      <div class="w-fit-content">
        <div 
          v-b-tooltip :title="getUserPermissions.after_call_feedback?'':'Your package does not support this feature'"
        >
          <div v-if="api.fetch_notification_settings.send" class="latestShimmerDesign" style="height:34px;width:60px;border-radius: 25px"></div>
          <b-form-checkbox
            v-else
            class="newerSwitch"
            ref="after_call_feedback"
            :disabled="!getUserPermissions.after_call_feedback"
            :checked="data.after_call_feedback"
            @change="changeAfterCallFeedback($event)"
            name="check-button"
            switch
          />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { VOIP_API } from '@/utils';
import { mapGetters, mapMutations } from 'vuex';
import { GET_LOCAL_SETTING_CALLS } from '@/store/helper/getters';
import { SET_LOCAL_SETTING_CALLS_FEEDBACK } from '@/store/helper/mutations';
import MissedCallNotificationWidget from './MissedCallNotificationWidget.vue';
export default {
  name: 'NotificationSettingsWidget',
  components: {
    MissedCallNotificationWidget,
  },
  props: {
    accountcode: {
      type: [String,Number],
      default: ''
    },
  },
  inject: [
    'appNotify',
  ],
  data() {
    return {
      api: {
        fetch_notification_settings: this.$helperFunction.apiInstance(),
        change_push_notification: this.$helperFunction.apiInstance(),
        change_voicemail_notification: this.$helperFunction.apiInstance(),
        change_voicemail_attachment: this.$helperFunction.apiInstance(),
        change_voicemail_transcript: this.$helperFunction.apiInstance(),
        change_after_call_feedback: this.$helperFunction.apiInstance(),
      },
      data: {
        push_notification: false,
        voicemail_notification: false,
        voicemail_attachment: false,
        voicemail_transcript: false,
        after_call_feedback: false,
      },
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getUserPermissions',
      GET_LOCAL_SETTING_CALLS
    ]),
    voipaccountcode(){ return this.accountcode },
    isCurrentUser(){ return this.voipaccountcode == this.getCurrentUser.account },
  },
  methods: {
    ...mapMutations([
      SET_LOCAL_SETTING_CALLS_FEEDBACK
    ]),
    async fetchNotificationSettings() {
      if(this.api.fetch_notification_settings.send) return;
      try {
        this.api.fetch_notification_settings.send=true
        const { data } = await VOIP_API.endpoints.call.getNotificationSettings(this.voipaccountcode)
        this.data.push_notification=data.push_notification==1
        this.data.voicemail_notification=data.voicemail_notification==1
        this.data.voicemail_attachment=data.voicemailSettings?.voicemail_attachment=='Yes'
        this.data.voicemail_transcript=data.voicemailSettings?.voicemail_transcript=='Yes'
        this.data.after_call_feedback=data.after_call_feedback==1
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_notification_settings.send=false
      }
    },
    async changePushNotification(value) {
      if(this.api.change_push_notification.send) return;
      try {
        this.api.change_push_notification.send=true
        await VOIP_API.endpoints.call.setNotificationSettings({
          field: 'push_notification',
          value: value ? 1 : 0,
          accountcode: this.voipaccountcode,
        })
        this.data.push_notification=value
        this.appNotify({
          message: 'Successfully updated',
          type: 'success'
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        if(this.$refs['push_notification']) this.$refs['push_notification'].localChecked = this.data.push_notification;
        this.api.change_push_notification.send=false
      }
    },
    async changeVoicemailNotification(value) {
      if(this.api.change_voicemail_notification.send) return;
      try {
        this.api.change_voicemail_notification.send=true
        await VOIP_API.endpoints.call.setNotificationSettings({
          field: 'voicemail_notification',
          value: value ? 1 : 0,
          accountcode: this.voipaccountcode,
        })
        this.data.voicemail_notification=value
        this.appNotify({
          message: 'Successfully updated',
          type: 'success'
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        if(this.$refs['voicemail_notification']) this.$refs['voicemail_notification'].localChecked = this.data.voicemail_notification;
        this.api.change_voicemail_notification.send=false
      }
    },
    async changeVoicemailAttachment(value) {
      if(this.api.change_voicemail_attachment.send) return;
      try {
        this.api.change_voicemail_attachment.send=true
        await VOIP_API.endpoints.call.setNotificationSettings({
          field: 'voicemail_attachment',
          value: value ? 'Yes' : 'No',
          accountcode: this.voipaccountcode,
        })
        this.data.voicemail_attachment=value
        this.appNotify({
          message: 'Successfully updated',
          type: 'success'
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        if(this.$refs['voicemail_attachment']) this.$refs['voicemail_attachment'].localChecked = this.data.voicemail_attachment;
        this.api.change_voicemail_attachment.send=false
      }
    },
    async changeVoicemailTranscript(value) {
      if(this.api.change_voicemail_transcript.send) return;
      try {
        this.api.change_voicemail_transcript.send=true
        await VOIP_API.endpoints.call.setNotificationSettings({
          field: 'voicemail_transcript',
          value: value ? 1 : 0,
          accountcode: this.voipaccountcode,
        })
        this.data.voicemail_transcript=value
        this.appNotify({
          message: 'Successfully updated',
          type: 'success'
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        if(this.$refs['voicemail_transcript']) this.$refs['voicemail_transcript'].localChecked = this.data.voicemail_transcript;
        this.api.change_voicemail_transcript.send=false
      }
    },
    async changeAfterCallFeedback(value) {
      if(this.api.change_after_call_feedback.send) return;
      try {
        this.api.change_after_call_feedback.send=true
        await VOIP_API.endpoints.call.setNotificationSettings({
          field: 'after_call_feedback',
          value: value ? 1 : 0,
          accountcode: this.voipaccountcode,
        })
        this.data.after_call_feedback=value
        this.SET_LOCAL_SETTING_CALLS_FEEDBACK(!this.GET_LOCAL_SETTING_CALLS.feedback);
        this.appNotify({
          message: 'Successfully updated',
          type: 'success'
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        if(this.$refs['after_call_feedback']) this.$refs['after_call_feedback'].localChecked = this.data.after_call_feedback;
        this.api.change_after_call_feedback.send=false
      }
    },
  },
  mounted(){
    this.fetchNotificationSettings()
  },
  activated(){
    this.fetchNotificationSettings()
  },
}
</script>

<style>

</style>