var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition', {
    attrs: {
      "name": "slide-fade"
    }
  }, [true ? [!_vm.$store.getters.isIntegration ? _c('div', {
    class: ['panel h-100 make-panel-responsive CallLogInCallActivity v2', _vm.GET_LOCAL_SETTING_DASHBOARD.design.width == 25 ? 'width-is-25' : '', _vm.GET_LOCAL_SETTING_DASHBOARD.design.width > 25 ? 'widthGreaterThan25' : '', _vm.$store.getters.getIsMobile ? 'IsMobile' : ''],
    attrs: {
      "id": "panel"
    }
  }, [_c('div', {
    staticClass: "panel-inner"
  }, [_c('h2', {
    staticClass: "d-flex flex-wrap align-items-center justify-content-between mb-3"
  }, [_c('span', {
    staticClass: "d-block title"
  }, [_vm._v("Activity")]), _c('div', {
    staticClass: "d-flex align-items-center list-icon-container"
  }, [!_vm.$store.getters.getIsMobile ? _c('div', {
    staticClass: "callActivity-search-container"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filter.call_activity.search,
      expression: "filter.call_activity.search"
    }],
    class: ['hide-callActivity-search', _vm.show_search ? 'show-callActivity-search' : ''],
    attrs: {
      "type": "text",
      "placeholder": "Search",
      "disabled": _vm.api_sent.call_Activity
    },
    domProps: {
      "value": _vm.filter.call_activity.search
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.filter.call_activity, "search", $event.target.value);
      }, _vm.onSearchCallActivity]
    }
  }), _c('vb-icon', {
    class: ['callActivity-search-icon', _vm.show_search ? 'move' : ''],
    attrs: {
      "width": "18.979",
      "height": "17.257",
      "icon": "callActivity-search"
    },
    on: {
      "click": function ($event) {
        _vm.show_search = !_vm.show_search;
      }
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "adjust-design-dd",
    attrs: {
      "app-tour-web-dashboard-dialer-design-adjust": ""
    }
  }, [_c('b-dropdown', {
    ref: "dropdown",
    staticClass: "m-2 dd-text-dark",
    attrs: {
      "id": "dropdown-form",
      "no-caret": "",
      "offset": "25",
      "app-tour-step": "2"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('vb-icon', {
          directives: [{
            name: "b-popover",
            rawName: "v-b-popover.hover.left.html",
            value: `<small>Adjust design</small>`,
            expression: "`<small>Adjust design</small>`",
            modifiers: {
              "hover": true,
              "left": true,
              "html": true
            }
          }],
          staticClass: "callActivity-AdjustDesign-v3",
          attrs: {
            "icon": "callActivity-AdjustDesign-v3",
            "width": "18.979",
            "height": "17.257"
          }
        })];
      },
      proxy: true
    }], null, false, 2998696465)
  }, [_c('b-dropdown-form', {
    staticClass: "mb-0"
  }, [(_vm.$store.getters.getIsMobile ? false : true) ? _c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('b-form-checkbox', {
    staticClass: "dialer-switch ml-5 cursor_pointer2",
    attrs: {
      "checked": _vm.GET_LOCAL_SETTING_DASHBOARD.design.flip,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.SET_LOCAL_SETTING_DASHBOARD_DESIGN_FLIP(!_vm.GET_LOCAL_SETTING_DASHBOARD.design.flip);
      }
    }
  }, [_vm._v(" Flip ")])], 1) : _vm._e(), _c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('b-form-checkbox', {
    staticClass: "dialer-switch ml-5 cursor_pointer2",
    attrs: {
      "checked": _vm.GET_LOCAL_SETTING_DASHBOARD.design.tags_show,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.SET_LOCAL_SETTING_DASHBOARD_DESIGN_TAGS_SHOW(!_vm.GET_LOCAL_SETTING_DASHBOARD.design.tags_show);
      }
    }
  }, [_vm._v(" Tags and note show ")])], 1), _c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('b-form-checkbox', {
    staticClass: "dialer-switch ml-5 cursor_pointer2",
    attrs: {
      "checked": _vm.GET_LOCAL_SETTING_DASHBOARD.design.scroll_top,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.SET_LOCAL_SETTING_DASHBOARD_DESIGN_SCROLL_TOP(!_vm.GET_LOCAL_SETTING_DASHBOARD.design.scroll_top);
      }
    }
  }, [_vm._v(" Scroll top ")])], 1), _c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('b-form-checkbox', {
    staticClass: "dialer-switch ml-5 cursor_pointer2",
    attrs: {
      "checked": _vm.GET_LOCAL_SETTING_DASHBOARD.design.transfer_log,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.SET_LOCAL_SETTING_DASHBOARD_DESIGN_TRANSFER_LOGS(!_vm.GET_LOCAL_SETTING_DASHBOARD.design.transfer_log);
      }
    }
  }, [_vm._v(" Log detail ")])], 1), _c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('b-form-checkbox', {
    staticClass: "dialer-switch ml-5 cursor_pointer2",
    attrs: {
      "disabled": _vm.GET_LOCAL_SETTING_DASHBOARD.design.width == 25,
      "checked": _vm.compact,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        _vm.GET_LOCAL_SETTING_DASHBOARD.design.width == 25 ? _vm.compact = false : _vm.compact = !_vm.compact;
      }
    }
  }, [_vm._v(" Compact ")])], 1)], 1), (_vm.$store.getters.getIsMobile ? false : true) ? _c('b-dropdown-divider') : _vm._e(), (_vm.$store.getters.getIsMobile ? false : true) ? _c('b-dropdown-form', {
    staticClass: "mb-0"
  }, [_c('b-form-group', {
    staticClass: "mb-0 d-flex justify-content-center"
  }, [_c('b-form-radio-group', {
    attrs: {
      "checked": _vm.GET_LOCAL_SETTING_DASHBOARD.design.width
    },
    on: {
      "change": function ($event) {
        _vm.SET_LOCAL_SETTING_DASHBOARD_DESIGN_WIDTH($event);
        _vm.GET_LOCAL_SETTING_DASHBOARD.design.width == 25 ? _vm.compact = false : '';
      }
    }
  }, [_c('b-form-radio', {
    staticClass: "mx-2",
    attrs: {
      "value": 25
    }
  }, [_vm._v("25%")]), _c('b-form-radio', {
    staticClass: "mx-2",
    attrs: {
      "value": 50
    }
  }, [_vm._v("50%")]), _c('b-form-radio', {
    staticClass: "mx-2",
    attrs: {
      "value": 75
    }
  }, [_vm._v("75%")])], 1)], 1)], 1) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "dialer-dropdown dialPanel_filter_dropdown ml-3 bg-white",
    attrs: {
      "app-tour-web-dashboard-dialer-call-logs-filter": "",
      "app-tour-step": "3"
    }
  }, [_c('b-dropdown', {
    ref: "dropdown",
    staticClass: "m-2",
    attrs: {
      "id": "dropdown-form",
      "text": "Filter by",
      "no-caret": "",
      "dropright": "",
      "offset": "25"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('vb-icon', {
          directives: [{
            name: "b-popover",
            rawName: "v-b-popover.hover.top.html",
            value: `<small>Filter call logs</small>`,
            expression: "`<small>Filter call logs</small>`",
            modifiers: {
              "hover": true,
              "top": true,
              "html": true
            }
          }],
          attrs: {
            "icon": "callActivity-filter",
            "width": "18.979",
            "height": "17.257"
          }
        })];
      },
      proxy: true
    }], null, false, 2706236723)
  }, [_c('b-dropdown-form', [_c('b-form-checkbox', {
    attrs: {
      "checked": _vm.filter.call_activity.allchecked
    },
    on: {
      "change": function ($event) {
        return _vm.filter.call_activity.toggleallcheck();
      }
    }
  }, [_vm._v(_vm._s(_vm.filter.call_activity.allchecked ? "Uncheck all" : "Check all"))]), _c('b-form-checkbox', {
    attrs: {
      "checked": _vm.filter.call_activity.teams
    },
    on: {
      "change": function ($event) {
        _vm.filter.call_activity.teams = !_vm.filter.call_activity.teams;
      }
    }
  }, [_vm._v("Teams")]), _c('b-form-checkbox', {
    attrs: {
      "checked": _vm.filter.call_activity.shared_voicemail
    },
    on: {
      "change": function ($event) {
        _vm.filter.call_activity.shared_voicemail = !_vm.filter.call_activity.shared_voicemail;
      }
    }
  }, [_vm._v("Shared voicemail")]), _c('b-form-checkbox', {
    attrs: {
      "checked": _vm.filter.call_activity.voicemail
    },
    on: {
      "change": function ($event) {
        _vm.filter.call_activity.voicemail = !_vm.filter.call_activity.voicemail;
      }
    }
  }, [_vm._v("Voicemail")]), _c('b-form-checkbox', {
    attrs: {
      "checked": _vm.filter.call_activity.recording
    },
    on: {
      "change": function ($event) {
        _vm.filter.call_activity.recording = !_vm.filter.call_activity.recording;
      }
    }
  }, [_vm._v("Recording")]), _c('b-form-checkbox', {
    attrs: {
      "checked": _vm.filter.call_activity.sms
    },
    on: {
      "change": function ($event) {
        _vm.filter.call_activity.sms = !_vm.filter.call_activity.sms;
      }
    }
  }, [_vm._v("SMS")]), _c('b-dropdown-group', {
    attrs: {
      "header": "Video"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "checked": _vm.filter.call_activity.video.missed
    },
    on: {
      "change": function ($event) {
        _vm.filter.call_activity.video.missed = !_vm.filter.call_activity.video.missed;
      }
    }
  }, [_vm._v("Missed")]), _c('b-form-checkbox', {
    attrs: {
      "checked": _vm.filter.call_activity.video.dial
    },
    on: {
      "change": function ($event) {
        _vm.filter.call_activity.video.dial = !_vm.filter.call_activity.video.dial;
      }
    }
  }, [_vm._v("Dial")]), _c('b-form-checkbox', {
    attrs: {
      "checked": _vm.filter.call_activity.video.received
    },
    on: {
      "change": function ($event) {
        _vm.filter.call_activity.video.received = !_vm.filter.call_activity.video.received;
      }
    }
  }, [_vm._v("Received")])], 1), _c('b-dropdown-group', {
    attrs: {
      "header": "Audio"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "checked": _vm.filter.call_activity.audio.missed
    },
    on: {
      "change": function ($event) {
        _vm.filter.call_activity.audio.missed = !_vm.filter.call_activity.audio.missed;
      }
    }
  }, [_vm._v("Missed")]), _c('b-form-checkbox', {
    attrs: {
      "checked": _vm.filter.call_activity.audio.dial
    },
    on: {
      "change": function ($event) {
        _vm.filter.call_activity.audio.dial = !_vm.filter.call_activity.audio.dial;
      }
    }
  }, [_vm._v("Dial")]), _c('b-form-checkbox', {
    attrs: {
      "checked": _vm.filter.call_activity.audio.received
    },
    on: {
      "change": function ($event) {
        _vm.filter.call_activity.audio.received = !_vm.filter.call_activity.audio.received;
      }
    }
  }, [_vm._v("Received")])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "ml-3 d-flex align-items-center",
    attrs: {
      "app-tour-web-dashboard-dialer-call-logs-reload": "",
      "app-tour-step": "4"
    }
  }, [_vm.refresh_sec == '0' ? _c('button', {
    class: `${_vm.api_sent.call_Activity ? 'rotate-animation' : ''}`,
    attrs: {
      "id": "refresh-button"
    },
    on: {
      "click": function ($event) {
        return _vm.fetchCallActivity();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "callActivity-refresh",
      "width": "18.979",
      "height": "17.257"
    }
  })], 1) : _vm._e(), _c('b-popover', {
    attrs: {
      "target": "refresh-button",
      "placement": "right",
      "triggers": "hover"
    }
  }, [_c('small', [_vm._v("Refresh call logs")])])], 1), !_vm.isProduction ? [_c('div', {
    staticClass: "ml-3 d-flex align-items-center"
  }, [_c('button', {
    on: {
      "click": function ($event) {
        return _vm.testConfirmation();
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "pencil"
    }
  })], 1)]), _c('div', {
    staticClass: "ml-3 d-flex align-items-center"
  }, [_c('button', {
    attrs: {
      "id": "test-notification"
    },
    on: {
      "click": function ($event) {
        return _vm.notificationTest();
      }
    }
  }, [_c('b-icon', {
    staticStyle: {
      "vertical-align": "middle"
    },
    attrs: {
      "variant": "primary",
      "icon": "arrow-clockwise",
      "scale": "1.1"
    }
  })], 1), _c('b-popover', {
    attrs: {
      "target": "test-notification",
      "placement": "right",
      "triggers": "hover"
    }
  }, [_c('small', [_vm._v("Test notification")])])], 1)] : _vm._e()], 2)]), _vm.$store.getters.getIsMobile ? _c('div', {
    staticClass: "callActivity-search-container"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filter.call_activity.search,
      expression: "filter.call_activity.search"
    }],
    class: ['hide-callActivity-search', _vm.show_search ? 'show-callActivity-search' : ''],
    attrs: {
      "type": "text",
      "disabled": _vm.api_sent.call_Activity
    },
    domProps: {
      "value": _vm.filter.call_activity.search
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.filter.call_activity, "search", $event.target.value);
      }, _vm.onSearchCallActivity]
    }
  }), _c('b-icon', {
    class: ['callActivity-search-icon', _vm.show_search ? 'move' : ''],
    attrs: {
      "icon": "search"
    },
    on: {
      "click": function ($event) {
        _vm.show_search = !_vm.show_search;
      }
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "list-area"
  }, [_c('div', {
    staticClass: "holder"
  }, [_c('ul', {
    staticClass: "phone-list allow_scroll list-unstyled scrollOnHOver",
    attrs: {
      "id": "phoneListScroll"
    },
    on: {
      "scroll": _vm.onScroll
    }
  }, [_vm.filteredCallActivity.length < 1 && _vm.api_sent.call_Activity ? _c('div', {
    staticClass: "d-flex justify-content-center align-items-center h-100 w-100"
  }, [true ? _c('vb-loading', {
    attrs: {
      "type": "ellipseV2"
    }
  }) : _vm._e()], 1) : _vm._e(), _c('div', {
    staticClass: "h-auto"
  }, [!_vm.isEmpty(_vm.filteredCallActivity) ? _c('div', {
    staticClass: "callActivity_overlayTop"
  }) : _vm._e(), _vm._l(_vm.filteredCallActivity, function (call, index) {
    return [call.isDateShow ? _c('h3', {
      key: `${index}-date-call-activity`,
      staticClass: "pb-1 ddd"
    }, [_c('span', {
      staticStyle: {
        "color": "black",
        "font-size": "12px"
      }
    }, [_vm._v(" " + _vm._s(_vm._f("dateFormate")(call.call_date || call.time)) + " ")])]) : _vm._e(), _c('li', {
      key: index
    }, [_c('CallActivityItem1', {
      class: `${_vm.compact ? 'compactVersion' : ''}`,
      attrs: {
        "call": call,
        "conditions": {
          tag: _vm.GET_LOCAL_SETTING_DASHBOARD.design.tags_show,
          note: _vm.GET_LOCAL_SETTING_DASHBOARD.design.tags_show
        }
      },
      on: {
        "click-play": function ($event) {
          return _vm.$emit('click-play', $event);
        },
        "onClickInfo": function ($event) {
          return _vm.$modal.show('CallInfoModal', {
            callInfo: call
          });
        },
        "onClickInfo1": function ($event) {
          return _vm.$modal.show('CallInfoModalNew', Object.assign({
            callInfo: call
          }, $event !== null && $event !== void 0 ? $event : {}));
        },
        "openOnlyTranscriptModal": function ($event) {
          return _vm.$modal.show('AITranscript', {
            call: call
          });
        }
      }
    })], 1)];
  }), !_vm.isEmpty(_vm.filteredCallActivity) ? _c('div', {
    staticClass: "callActivity_overlayBottom"
  }) : _vm._e()], 2), _c('div', [_vm.api_sent.call_Activity ? _c('vb-loading', {
    attrs: {
      "type": "ellipseV2"
    }
  }) : _vm.isEmpty(_vm.filteredCallActivity) ? [_c('div', {
    staticClass: "emptyScreenContainer allTransparent pt-0"
  }, [_vm.GET_LOCAL_SETTING_IS_DARK_MODE ? _c('img', {
    staticClass: "mt-20px",
    staticStyle: {
      "max-width": "380px"
    },
    attrs: {
      "width": "65%",
      "height": "auto",
      "src": require('@/assets/images/emptyScreenImages/darkMode/forCallLogs.png')
    }
  }) : _c('img', {
    staticClass: "mt-20px",
    staticStyle: {
      "max-width": "380px"
    },
    attrs: {
      "width": "65%",
      "height": "auto",
      "src": require('@/assets/images/emptyScreenImages/forCallLogs.png')
    }
  }), _c('div', {
    staticClass: "emptyScreenContainer-heading inCallLogs mt-50px"
  }, [_vm._v(" Welcome aboard to VoIP Business! ")]), _c('div', {
    staticClass: "emptyScreenContainer-text inCallLogs w-100"
  }, [_vm._v(" Are you ready to unleash unstoppable productivity? Take the plunge and make your first call now! Alternatively, give our test call a try to ensure everything's set up just right. ")]), _c('button', {
    staticClass: "newDoneButton mt-20px",
    on: {
      "click": function ($event) {
        return _vm.testCall();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-call-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Make a call")])], 1), _c('div', {
    staticClass: "emptyScreenContainer-heading inCallLogs mt-50px"
  }, [_vm._v(" Quick start guide. ")]), _c('div', {
    staticClass: "emptyScreenContainer-text inCallLogs w-75"
  }, [_vm._v(" Use quick start to get to know the app and set up your organisation correctly. ")]), _c('button', {
    staticClass: "newButton mt-20px"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-quickStart-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Quick start")])], 1)])] : _vm.$store.state.common.call_activity.is_completed ? _c('div', {
    staticClass: "NoMoreRecordText",
    staticStyle: {
      "display": "flex",
      "justify-content": "center",
      "align-items": "center",
      "margin-top": "25px"
    }
  }, [_vm._v(" No more record ")]) : _vm._e()], 2)])])])]), _c('CallInfoModal'), _c('CallInfoModalNew', {
    attrs: {
      "modalName": "CallInfoModalNew"
    },
    on: {
      "latest-record": function ($event) {
        return _vm.fetchCallActivity();
      }
    }
  }), _c('WriteCallNoteModal', {
    on: {
      "updated": function ($event) {
        return _vm.fetchCallActivity();
      }
    }
  }), _c('TagCallNoteModal', {
    on: {
      "updated": function ($event) {
        return _vm.fetchCallActivity();
      }
    }
  }), _c('TagsAndNotesUpdateModal', {
    on: {
      "updated": function ($event) {
        return _vm.fetchCallActivity();
      }
    }
  }), _c('DirectChatModal'), _c('AITranscriptModal')], 1) : _vm._e()] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }