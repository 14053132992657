<template>
  <div class="dialer-settings-main">
    <template>
      <section class="dialer-profile-setting v2 dialer-settings-section">
        <!-- <template v-if="getIsMobile">
          <div class="d-flex align-items-center mb-3 calc-title-width w-100">
            <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" @click="$emit('back')" />
            <h2 class="dialer-settings-title w-100 mb-0 text-center">My profile</h2>
          </div>
        </template>
        <h2 v-else class="dialer-settings-title newer">
          My profile
          <b-icon icon="info-circle-fill" @click="$modal.show('KbInfoModal',{ kb: 'profile' })" class="cursor_pointer infoKbIcon md-mar-left" />
        </h2>
        <p class="dialer-settings-intro newerTextVersion">
          <span>Tailor your profile to suit your preferences.</span>
        </p> -->

        <div class="settingsFirstSection">
          <template v-if="getIsMobile">
            <div class="d-flex align-items-center mb-3 calc-title-width-button w-100">
              <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" @click="$emit('back')" />
              <h2 class="dialer-settings-title w-100 mb-0 text-center">My profile</h2>
            </div>
          </template>
          <template v-else>
            <h2 class="dialer-settings-title mb-0 settingsFirstSection-left newer">
              My profile
              <b-icon icon="info-circle-fill" @click="$modal.show('KbInfoModal',{ kb: 'profile' })" class="cursor_pointer infoKbIcon md-mar-left" />
            </h2>
          </template>
        </div>
        <div class="settingsSecondSection">
          <span class="dialer-settings-intro newerTextVersion mt-0 mb-0">
            <span>Tailor your profile to suit your preferences.</span>
          </span>
        </div>

        <div class="latestGreyBox-9-9-2023 mt-20px">
          <!-- profile image and change password section -->
          <div class="whiteBoxWithBorderInsideGreyBox">
            <div class="latestGreyBox-heading-main mt-24px">Personal information & account credentials</div>
            <div class="latestGreyBox-9-9-2023 mt-24px mb-32px">
              <div class="d-flex align-items-center profileImg-section-latest-container-parent">
                <div class="profileImg-section-latest-container">
                  <div 
                    class="profileImg-section-latest" 
                    @click="$modal.show(`${_uid}-UserProfileImageUploader`, { 
                      type: 'USER', 
                      accountcode: getCurrentUser.account, 
                      current_image: getCurrentUser.profileImg 
                    })"
                  >
                    <img 
                      :src="getCurrentUser.profileImg" 
                      @error="$event.target.src=local_filess.images.user_male" 
                      :alt="getCurrentUser.display_name" 
                    />
                  </div>
                  <h3 class="latestGreyBox-heading-main-18px mt-12px mb-0">{{user | get_property('user.display_name')}}</h3>
                </div>
                <form @submit.prevent="''" class="dialer-form flex-fill ml-60px d-flex flex-column dialer-change-password">
                  <div class="d-flex w-100">
                    <div class="whiteBGinputWithGreyRoundedBorder pr-3 flex-fill">
                      <label>First name</label>
                      <input disabled :value="generalInfo.first_name" class="w-100" />
                    </div>
                    <div class="whiteBGinputWithGreyRoundedBorder pl-3 flex-fill">
                      <label>Last name</label>
                      <input disabled :value="generalInfo.last_name" class="w-100" />
                    </div>
                  </div>
                  <div class="d-flex w-100">
                    <div class="whiteBGinputWithGreyRoundedBorder withLeftIconOnly flex-fill mt-16px mr-32px">
                      <label>
                        <template v-if="userType == 'user'">Username</template>
                        <template v-else>E-mail</template>
                      </label>
                      <div class="inputContainer-inside-whiteBGinputWithGreyRoundedBorder">
                        <vb-icon icon="latest-emailInsideInput-icon" width="18.818px" height="14.112px" />
                        <input disabled :value="currentUserEmail" class="w-100" />
                      </div>
                    </div>
                    <div class="profileImg-section-inputs-column prcnt33 mt-auto">
                      <button 
                        type="button" 
                        @click="$modal.show(`${_uid}-ChangePasswordModal`,{
                          accountcode: getCurrentUser.account
                        })" 
                        class="newButton w-100"
                      >
                        <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                        <span class="newButton-textPart">Change password</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!-- Select Theme Section -->
          <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox-row">
              <div class="d-flex flex-column">
                <div class="latestGreyBox-heading-main">Dark mode</div>
                <div class="latestGreyBox-descriptionText">Easy on the eyes, and perfect for low-light settings</div>
              </div>
              <div class="w-fit-content">
                <b-form-checkbox 
                  class="newerSwitch" 
                  switch 
                  :checked="GET_LOCAL_SETTING_THEME.mode=='dark'" 
                  @change="SET_LOCAL_SETTING_THEME_MODE($event?'dark':'colored3');appNotify({ 
                    message: 'Successfully updated' ,
                    type: 'success'
                  });" 
                />
              </div>
              <!-- <button @click="$modal.show(`${_uid}-SelectThemeModal`)" class="newButton">
                <vb-icon icon="squared-selectTheme-icon" height="38px" width="38px" />
                <span class="newButton-textPart">Select</span>
              </button> -->
            </div>
            <div class="whiteBoxWithBorderInsideGreyBox-row">
              <div class="d-flex flex-column">
                <div class="latestGreyBox-heading-main">International format</div>
                <div class="latestGreyBox-descriptionText">Enabling this option will display numbers in international format, ensuring clear communication across borders.</div>
              </div>
              <div class="w-fit-content">
                <b-form-checkbox 
                  class="newerSwitch" 
                  switch 
                  :checked="GET_LOCAL_SETTING_NUMBERS.formating.international_formate" 
                  @change="SET_LOCAL_SETTING_NUMBERS_FORMATING_INTERNATIONAL_FORMATE(!GET_LOCAL_SETTING_NUMBERS.formating.international_formate);appNotify({ 
                    message: 'Successfully updated' ,type:'success'
                  })" 
                />
              </div>
            </div>
            <div class="whiteBoxWithBorderInsideGreyBox-row somethingInside">
              <DesktopNotificationWidget :accountcode="voipaccount" />
            </div>
            <div class="whiteBoxWithBorderInsideGreyBox-row">
              <div class="d-flex flex-column">
                <div class="latestGreyBox-heading-main">Call waiting</div>
                <div class="latestGreyBox-descriptionText">If enabled, you will be able to receive multiple incoming calls at the same time.</div>
              </div>
              <div 
                v-b-tooltip :title="getUserPermissions.call_waiting?'':'Your package does not support this feature'"
                class="w-fit-content" 
                :class="{
                  'for-disable': !getUserPermissions.call_waiting
                }"
              >
                <template v-if="api.user_detail.send">
                  <div class="latestShimmerDesign" style="height:34px;width:60px;border-radius: 25px"></div>
                </template>
                <b-form-checkbox
                  v-else
                  ref="call_waiting"
                  :disabled="!getUserPermissions.call_waiting || api.update_call_waiting.send || api.user_detail.send"
                  class="newerSwitch"
                  :checked="!conditions.call_waiting"
                  @change="updateCallWaiting()"
                  name="check-button"
                  switch
                />
              </div>
            </div>
          </div>
          <!-- 2FA section -->
          <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox-row d-flex flex-column">
              <div class="d-flex justify-content-between align-items-center w-100">
                <div class="d-flex flex-column">
                  <div class="latestGreyBox-heading-main">Two factor authentication</div>
                  <div class="latestGreyBox-descriptionText">Enhance security through two-factor authentication for an added layer of account protection</div>
                </div>
                <b-check ref="two_factor_auth_checkbox" class="newerSwitch" :disabled="api.update_two_factor_auth.send" switch :checked="tfaEnabled" @change="changeTwoFactorAuth($event?'yes':'no');" />
              </div>
              <div class="latestGreyBox-9-9-2023 mt-20px w-100" v-if="tfaEnabled">
                <div class="whiteBoxWithBorderInsideGreyBox">
                  <div class="whiteBoxWithBorderInsideGreyBox-row">
                    <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 dd-2FA">
                      <div class="dialer-dropdown">
                        <b-dropdown ref="dropdown">
                          <template #button-content>
                            <span class="d-flex align-items-center p-0">
                              <span class="text-left">
                                <template v-if="selected.two_fact_auth === 'yes'">All devices</template>
                                <template v-else-if="selected.two_fact_auth === 'non_trusted'">Un-trusted devices</template>
                                <template v-else>Select options</template>
                              </span>
                              <b-icon-chevron-down scale="1.1" />
                            </span>
                          </template>
                          <b-dropdown-item :disabled="api.update_two_factor_auth.send" @click="changeTwoFactorAuth('yes')">
                            All devices
                          </b-dropdown-item>
                          <b-dropdown-item :disabled="api.update_two_factor_auth.send" @click="changeTwoFactorAuth('non_trusted')">
                            Un-trusted devices
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- trusted devices table section -->
          <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
            <div class="d-flex flex-column">
              <div class="latestGreyBox-heading-main mt-24px">Trusted devices</div>
              <!-- <div class="latestGreyBox-descriptionText">Manage the devices that have access to your account for added security.</div> -->
              <div class="latestGreyBox-descriptionText">Below are the trusted devices exempt from 2FA for login. All other devices will require 2FA for enhanced security.</div>
            </div>
            <vb-table 
              class="latestTableDesign-withBlackBorders-again smallerFontSizes mb-4 TrustedDevicesTableContainer" 
              :isListEmpty="filterTrustedDevices.length==0" 
              :listLength="filterTrustedDevices.length" 
              :loading="api.trusted_devices.send"
              :noRecordWholePage="true"
              :perPage="5"
            >
              <template slot="header">
                <tr>
                  <th class="dialer-has-sort one">
                    <span>Device</span>
                  </th>
                  <th class="dialer-has-sort two">
                    <span>Location</span>
                  </th>
                  <th class="dialer-has-sort three">
                    <span>IP address</span>
                  </th>
                  <th class="dialer-has-sort four">
                    <span>Last visit</span>
                  </th>
                  <th class="dialer-has-sort five">
                    <span></span>
                  </th>
                </tr>
              </template>
              <template #body="{ start, end }">
                <tr 
                  class="dialer-row-select" 
                  v-for="(data, index) in filterTrustedDevices" 
                  :key="index" 
                  v-show="index >= start && index <= end"
                >
                  <td class="dialer-row-title one">
                    <div class="deviceContainer forTrustedDevicesTable d-flex align-items-center w-fit-content">
                      <vb-icon :icon="data.fp_data | jsonParse | extensionDeviceInfo('icon')" height="25px" width="25px"/>
                      <vb-icon class="ml-2" :icon="data.fp_data | jsonParse | extensionDeviceInfo('osicon')" height="25px" width="25px"/>
                      <div class="newThisDeviceIndicaterTextBox-25-11-2023" v-if="isSameDevice(data.fp_data)">
                        <span>This device</span>
                      </div>
                    </div>
                  </td>
                  <td class="dialer-row-title two">
                    <span>{{ data.fp_data | jsonParse | extensionDeviceInfo('location') }}</span>
                  </td>
                  <td class="dialer-row-title three">
                    <span>{{ data.fp_data | jsonParse | extensionDeviceInfo('ip') }}</span>
                  </td>
                  <td class="dialer-row-title four">
                    <span>{{data.created_at | filter_date_current}}</span>
                  </td>
                  <td class="dialer-row-title dialer-col-right five">
                    <div class="d-flex justify-content-end">
                      <vb-spinner v-if="api.delete_trusted_device.send.includes(data.id)" />
                      <div 
                        v-else 
                        class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD" 
                        :disabled="api.delete_trusted_device.send.includes(data.id)"
                      >
                        <b-dropdown right >
                          <template #button-content>
                            <b-icon icon="three-dots-vertical" scale="1.5"></b-icon>
                          </template>
                          <b-dropdown-item @click="deleteTrustedDevice(data)">Delete</b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
              <template #loading>
                <tr v-for="n in 5" :key="n">
                  <td>
                    <div class="d-flex align-items-center w-fit-content">
                      <div class="latestShimmerDesign" style="height:25px;width:25px;"></div>
                      <div class="latestShimmerDesign ml-2" style="height:25px;width:25px;"></div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex align-items-center w-fit-content">
                      <div class="latestShimmerDesign" style="height:20px;width:140px;"></div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex w-fit-content">
                      <div class="latestShimmerDesign" style="height:20px;width:108px;"></div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex w-fit-content">
                      <div class="latestShimmerDesign" style="height:20px;width:108px;"></div>
                    </div>
                  </td>
                  <td class="dialer-col-right">
                    <div class="latestShimmerDesign ml-auto" style="height:25px;width:20px;"></div>
                  </td>
                </tr>
              </template>
              <template #no-record>
                <div class="latestGreyBox-9-9-2023 mt-32px mb-32px">
                  <div class="emptyScreenContainer">
                    <img class="mt-12px wd-30 h-auto" v-if="GET_LOCAL_SETTING_IS_DARK_MODE" :src="require('@/assets/images/emptyScreenImages/darkMode/trustedDevices.png')"/>
                    <img class="mt-12px wd-30 h-auto" v-else :src="require('@/assets/images/emptyScreenImages/trustedDevices.png')"/>
                    <div class="emptyScreenContainer-heading mt-12px">
                      Trusted devices
                    </div>
                    <div class="emptyScreenContainer-text w-75">
                      Your trusted devices have their own hangout spot right here! Keep an eye on your device 
                      list. When logging in with an untrusted device, you've got the option to add it to the 
                      trusted list. Let's ensure your account stays safe and secure!
                    </div>
                  </div>
                </div>
              </template>
            </vb-table>
          </div>
          <!-- call queue and teams section -->
          <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
            <div class="d-flex flex-column">
              <div class="latestGreyBox-heading-main mt-24px">Groups</div>
              <div class="latestGreyBox-descriptionText">View all teams within this organization from this section.</div>
            </div>
            <vb-table 
              class="latestTableDesign-withBlackBorders-again mb-4" 
              :isListEmpty="callQueuesAndTeams.length==0" 
              :listLength="callQueuesAndTeams.length" 
              :noRecordWholePage="true"
            >
              <template slot="header">
                <tr>
                  <th class="dialer-has-sort">
                    <span>Name</span>
                  </th>
                </tr>
              </template>
              <template #body="{ start, end }">
                <tr 
                  class="dialer-row-select" 
                  v-for="(callQueuesAndTeam, index) in callQueuesAndTeams" 
                  :key="callQueuesAndTeam.voipaccount || callQueuesAndTeam.real_id"  
                  v-show="index >= start && index <= end"
                >
                  <td class="dialer-row-title onlyOneTD">
                    <div class="d-flex align-items-center">
                      <Info :id="callQueuesAndTeam.voipaccount || callQueuesAndTeam.real_id" is_suspended_show :is_blf="false" />
                      <div class="OwnertaggedVersion ml-16px mb-0">
                        {{ callQueuesAndTeam.object=="Teams"?callQueuesAndTeam.object:'Call queue' }}
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
              <template #loading>
                <tr v-for="n in 5" :key="n">
                  <td>
                    <div class="d-flex w-fit-content">
                      <div class="latestShimmerDesign mr-2" style="height:35px;width:35px;"></div>
                      <div class="d-flex flex-column justify-content-between">
                        <div class="latestShimmerDesign flex-1" style="height:16px;width:60px"></div>
                        <div class="latestShimmerDesign mt-1" style="height:16px;width:30px"></div>
                      </div>
                    </div>
                  </td>
                  <td class="dialer-col-right">
                    <div class="latestShimmerDesign ml-auto" style="height:20px;width:60px;"></div>
                  </td>
                </tr>
              </template>
              <template #no-record>
                <div class="latestGreyBox-9-9-2023 mt-20px mb-32px">
                  <div class="emptyScreenContainer">
                    <img width="398px" height="149px" class="mt-50px" :src="require('@/assets/images/emptyScreenImages/groups2.png')"/>
                    <div class="emptyScreenContainer-heading mt-50px">
                      Not Part of Any Teams or Queues Yet
                    </div>
                    <div class="emptyScreenContainer-text w-75">
                      Looks like you're flying solo for now! No groups, no queues – just you, the lone 
                      ranger of productivity! But hey, if you're feeling like you're missing out on the 
                      team fun, nudge your administrator to put you into a group.
                    </div>
                    <button class="newButton mt-20px">
                      <vb-icon icon="squared-adminUser-icon" height="38px" width="38px" />
                      <span class="newButton-textPart">Request Admin</span>
                    </button>
                  </div>
                </div>
              </template>
            </vb-table>
          </div>
          <!-- Organizations settings -->
          <div v-if="organizationLength>1" class="whiteBoxWithBorderInsideGreyBox mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox-row somethingInside">
              <Organizations class="w-100 p-0" />
            </div>
          </div>
        </div>

        <div class="latestGreyBox-9-9-2023 mt-20px">
          <div class="latestGreyBox-heading-main">Notifications</div>
          <NotificationSettingsWidget :accountcode="voipaccount" />
        </div>

        <div class="latestGreyBox-9-9-2023 mt-20px">
          <div class="whiteBoxWithBorderInsideGreyBox">
            <div class="whiteBoxWithBorderInsideGreyBox-row">
              <div class="mr-16px">
                <div class="latestGreyBox-heading-main mb-16px">Dedicated numbers</div>
                <!-- <div class="latestGreyBox-descriptionText">
                  <div v-for="did in response.did_assigned" :key="did.did">{{ did.did }}</div>
                </div> -->
                <div class="d-flex IVR-numbers-container flex-wrap" v-if="response.did_assigned.length > 0">
                  <div v-for="did in response.did_assigned" :key="did.did" class="newIVRLayout-number-item">
                    <img class="country-img" width="45px" :src="did.did | number_formater | flag_icon" />
                    <span class="newIVRLayout-number-item-text">{{ did.did | number_formater }}</span>
                  </div>
                </div>
                <template v-else>
                  <p class="dialer-box-text highlight mb-0">No numbers assigned</p>
                </template>
              </div>
            </div>
            <div class="whiteBoxWithBorderInsideGreyBox-row border-0">
              <div class="mr-16px">
                <div class="latestGreyBox-heading-main">User based call routing</div>
                <div class="latestGreyBox-descriptionText">
                  <!-- there is description here -->
                  <!-- <div v-for="did in response.did_assigned" :key="did.did">{{ did.did }}</div> -->
                </div>
              </div>
              <button class="newButton ml-5" type="button" @click="conditions.is_view=!conditions.is_view">
                <vb-icon :icon="conditions.is_view ? 'squared-hide-icon' : 'squared-show-icon'" height="38px" width="38px" />
                <span class="newButton-textPart">
                  <span>{{ conditions.is_view ? 'Hide' : 'Show' }}</span>
                </span>
              </button>
            </div>
            <b-collapse class="mt-20px" :visible="conditions.is_view">
              <div class="mb-28px">
                <div class="whiteBoxWithBorderInsideGreyBox-row somethingInside pt-0">
                  <CallForwardingSetting 
                    @cf-updated="$emit('cf-updated')"
                    :account="voipaccount"
                  />
                </div>
                <div class="whiteBoxWithBorderInsideGreyBox-row somethingInside pt-0 dialer_box_text_my-0">
                  <ScheduleSetting  
                    @ofh-days-updated="$emit('ofh-days-updated')"
                    @ofh-default-updated="$emit('ofh-default-updated')"
                    @ofh-toggle-updated="$emit('ofh-toggle-updated')"
                    :account="voipaccount" 
                  />
                </div>
                <div class="whiteBoxWithBorderInsideGreyBox-row somethingInside dialer_box_text_my-0">
                  <MusicOnHoldWidget :accountcode="voipaccount"  />
                </div>
                <VoicemailSetting class="VoicemailSetting-insideTeamsModal makeGreyBoxTransparent" :accountcode="voipaccount" />
              </div>
            </b-collapse>
          </div>
        </div>
        <DevicesLatestSettings />
        <IntegrationsSettings />
      </section>
    </template>
    <ProfileImageUploader :modalName="`${_uid}-UserProfileImageUploader`" @image-uploaded="''" />
    <!-- <SelectThemeModal :modalName="`${_uid}-SelectThemeModal`" /> -->
    <ChangePasswordModal :modalName="`${_uid}-ChangePasswordModal`" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import _ from 'lodash'
import { VOIP_API } from "@/utils";
import { INTEGRATIONS } from '@/integrations';
import { GET_CRM_TOKEN, GET_LOCAL_SETTING_IS_DARK_MODE, GET_LOCAL_SETTING_NUMBERS, GET_LOCAL_SETTING_THEME } from '@/store/helper/getters';
import Info from '../Lists/Info.vue';
import DesktopNotificationWidget from '../widgets/DesktopNotification.vue'
import Organizations from "./Organizations.vue";
import ProfileImageUploader from '../Modals/ProfileImageUploader.vue'
// import SelectThemeModal from '../Modals/SelectThemeModal.vue'
import ChangePasswordModal from '../Modals/ChangePasswordModal.vue';
import { SET_LOCAL_SETTING_NUMBERS_FORMATING_INTERNATIONAL_FORMATE, SET_LOCAL_SETTING_THEME_MODE } from '@/store/helper/mutations';

import NotificationSettingsWidget from '../widgets/calls/NotificationSettingsWidget.vue';
import MusicOnHoldWidget from '../widgets/MusicOnHold.vue'
import ScheduleSetting from '../Common/ScheduleSetting.vue';
import CallForwardingSetting from '../Common/CallForwardingSetting.vue';
// import VoicemailSetting from "../Voicemail/Setting.vue";
import VoicemailSetting from "../Voicemail/Setting.vue";
import DevicesLatestSettings from "./DevicesLatest.vue";
import IntegrationsSettings from "./Integrations.vue";


const expected_tfa = ['no','yes','non_trusted']
export default {
  name: 'Profile',
  components: {
    ProfileImageUploader,
    // SelectThemeModal,
    ChangePasswordModal,
    Info,
    Organizations,
    DesktopNotificationWidget,
    
    NotificationSettingsWidget,
    MusicOnHoldWidget,
    ScheduleSetting,
    CallForwardingSetting,
    VoicemailSetting,
    DevicesLatestSettings,
    IntegrationsSettings,
  },
  inject:['isEmpty','local_filess','appNotify'],
  data() {
    return {
      setting: {
        international_formate: false,
      },
      api: {
        update_call_waiting: this.$helperFunction.apiInstance(),
        fetch_did_assigned: this.$helperFunction.apiInstance(),
        user_detail: this.$helperFunction.apiInstance(),
        trusted_devices: this.$helperFunction.apiInstance(),
        delete_trusted_device: this.$helperFunction.apiInstance({ send: [] }),
        update_two_factor_auth: this.$helperFunction.apiInstance(),
      },
      response: {
        trusted_devices: [],
        did_assigned: [],
        user_detail: {},
      },
      selected: {
        two_fact_auth: 'no',
      },

      
      conditions: {
        is_view: false,
        call_waiting: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      teams: 'getCurrentUserVoipTeams',
      callQueues: 'getCurrentUserVoipCallQueues',
      getCurrentUser: 'getCurrentUser',
      getIsMobile: 'getIsMobile',
      getUserPermissions: 'getUserPermissions',
      GET_LOCAL_SETTING_IS_DARK_MODE: GET_LOCAL_SETTING_IS_DARK_MODE,
      getVoipUsersAlises: 'getVoipUsersAlises',
      fpData: 'fpData',
      GET_CRM_TOKEN: GET_CRM_TOKEN,
      GET_LOCAL_SETTING_NUMBERS: GET_LOCAL_SETTING_NUMBERS,
      GET_LOCAL_SETTING_THEME: GET_LOCAL_SETTING_THEME,
    }),
    filterTrustedDevices(){ 
      return _.orderBy(this.response.trusted_devices,'created_at','desc').sort(item=>item.fp_data?.includes?.(this.fpData?.device_id)?-1:1)
    },
    callQueuesAndTeams(){ return [...this.teams,...this.callQueues]  },
    tfaEnabled(){ return expected_tfa.filter(i=>i!='no').includes(this.selected.two_fact_auth) },
    isSameDevice(){
      let vm = this
      return (value) => {
        return JSON.parse(value)?.device_id==vm.fpData?.device_id
      }
    },
    currentUserEmail(){
      if(this.getCurrentUser.user_type=='user'){
        return `${this.user?.user?.ac_user}@${this.getCurrentUser.company}`
        // return this.getCurrentUser.email.replace('.com','')
      } else {
        return this.getCurrentUser.email
      }
    },
    organizationLength(){ return (this.$store.getters.getCurrentUser?.organizations || []).length ?? 0 },
    user(){ return this.getVoipUsersAlises[this.accountcode] ?? this.getVoipUsersAlises[this.getCurrentUser?.account] },
    voipaccount(){ return this.user?.voipaccount },
    userType(){ return this.user?.user?.user_type },
    generalInfo(){
      const display_name = this.getCurrentUser?.display_name ?? ''
      return {
        first_name: display_name.split(' ')?.[0] ?? '',
        last_name: display_name.split(' ')?.[1] ?? '',
      }
    },
    // currentUserEmail(){
    //   const email = this.user?.user?.email
    //   const email2 = this.user?.user?.email2
    //   const ac_user = this.user?.user?.ac_user
    //   const current_user_email = this.getCurrentUser.email
    //   return ((email2 || email) === current_user_email ? ac_user : (ac_user != email) ? ac_user : email2 || email || ac_user) ?? ''
    // },
  },
  filters: {
    jsonParse(value){ return JSON.parse(value) },
    extensionDeviceInfo(data,value_type,current_device_id){
      const is_desktop = data?.platform=='desktop'
      const is_web = data?.platform=='web'
      const is_mobile = data?.platform=='mobile'
      const is_postman = data?.platform=='postman'
      const is_extension = data?.platform=='extension'
      const is_integration = data?.platform=='integration'
      const browser = data?.browser ?? ''
      const name = data?.name ?? ''
      const os = data?.os ?? ''
      const os_version = data?.os_version ?? ''
      const device_id = data?.device_id ?? ''
      if(value_type=='icon'){
        if(is_desktop) return 'trustedDevice-desktop-icon'
        else if(is_mobile) {
          if(os=='android') return 'trustedDevice-android-OSIcon'
          if(os=='ios') return 'trustedDevice-apple-OSIcon'
        } else if(is_web) {
          if(browser=='Chrome') return 'trustedDevice-chrome-icon'
          else if(browser=='Opera') return 'trustedDevice-opera-icon'
          else if(browser=='Edge') return 'trustedDevice-microsoft-icon'
          else if(browser=='Firefox') return 'trustedDevice-firefox-icon'
          else if(browser=='Safari') return 'trustedDevice-safari-icon'
        } else if (is_integration){
          if(name==INTEGRATIONS.platform_instance.hubspot) return 'trustedDevice-chrome-icon'
          else if(name==INTEGRATIONS.platform_instance.zoho) return 'trustedDevice-chrome-icon'
          else if(name==INTEGRATIONS.platform_instance.salesforce) return 'trustedDevice-chrome-icon'
        } else if (is_postman) return 'trustedDevice-chrome-icon'
        else if(is_extension) return 'trustedDevice-chrome-icon'
      } else if (value_type=='osicon') {
        if(os=='android') return 'trustedDevice-androidPhone-icon'
        else if(os=='ios') return 'trustedDevice-IPhone-icon'
        else if(os=='Windows' && os_version=='11') return 'trustedDevice-window11-OSIcon'
        else if(os=='Windows') return 'trustedDevice-window10-OSIcon'
        else if(os=='Linux') return 'trustedDevice-linux-OSIcon'
        else if(os?.includes?.('MAC')) return 'trustedDevice-appleMac-OSIcon'
        else return 'trustedDevice-window10-OSIcon'
      } else if(value_type=='ip'){
        return data?.ip ?? ''
      } else if(value_type=='location'){
        return `${data?.city ?? ''} ${data?.country ?? ''}`
      } else if(value_type=='app-name'){
        if(name) return name
        else if(is_desktop) return 'Desktop App'
        else if(is_mobile) return 'Mobile App'
        else if(is_web) return 'Web App'
        else if(is_postman) return 'Postman App'
        else if(is_extension) return 'Browser Extension App'
      } else if(value_type=='current-device'){
        return current_device_id==device_id
      }
      return ''
    },
  },
  methods: {
    ...mapMutations([
      SET_LOCAL_SETTING_NUMBERS_FORMATING_INTERNATIONAL_FORMATE,
      SET_LOCAL_SETTING_THEME_MODE,
    ]),
    async changeTwoFactorAuth(two_factor_auth='no'){
      if(two_factor_auth==this.selected.two_fact_auth || !expected_tfa.includes(two_factor_auth) || this.api.update_two_factor_auth.send) return;
      try {
        this.api.update_two_factor_auth.send=true
        const { confirm } = await this.$appConfirmation({
          title: "Warning",
          message: two_factor_auth=='no' ? 
            `Are you sure you want to turn off the two factor authentication` : 
            two_factor_auth=='yes' ? 
              `Are you sure you want to send the pin code on ${this.getCurrentUser.email} email` : 
              `Are you sure you want to send the pin code on ${this.getCurrentUser.email} email when device is not on the trusted devices list`,
          button: {
            no: "No",
            yes: "Yes",
          },
        })
        if(!confirm) return;
        await VOIP_API.endpoints.users.updatetwofactorauth({
          field: 'two_factor_auth',
          accountcode: this.getCurrentUser?.account,
          value: two_factor_auth
        })
        this.selected.two_fact_auth=two_factor_auth
        this.$store.state.common.user.two_factor_auth = this.selected.two_fact_auth;
        this.appNotify({
          message: "Successfully Updated!",
          type: "success",
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        if(this.$refs['two_factor_auth_checkbox']) {
          this.$refs['two_factor_auth_checkbox'].localChecked=this.tfaEnabled
        }
        this.api.update_two_factor_auth.send = false;
      }
    },
    async getTrustedDevices(){
      if(this.api.trusted_devices.send) return;
      try {
        this.api.trusted_devices.send=true
        const { data } = await VOIP_API.endpoints.trusted_devices.list({
          accountcode: this.getCurrentUser?.account
        })
        this.response.trusted_devices= data ?? []
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.trusted_devices.send=false
      }
    },
    async deleteTrustedDevice(device){
      if(this.api.delete_trusted_device.send.includes(device.id)) return;
      try {
        const { confirm } = await this.$appConfirmation({
          title: "Warning",
          // message: `Are you sure you want to delete this`,
          message: `Performing this action may require 2FA for login. Do you wish to proceed?`,
          variant: 'danger',
          button: {
            no: "No",
            yes: "Yes",
          },
        })
        if(!confirm) return;
        this.api.delete_trusted_device.send.push(device.id);
        await VOIP_API.endpoints.trusted_devices.delete({
          id: device.id,
          accountcode: this.getCurrentUser?.account,
        })
        this.getTrustedDevices()
        this.appNotify({
          message: "Successfully deleted!",
          type: "success",
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        if (this.api.delete_trusted_device.send.includes(device.id)) {
          this.api.delete_trusted_device.send.splice(this.api.delete_trusted_device.send.indexOf(device.id),1);
        }
      }
    },
    
    async fetchDidAssigned(){
      if(this.api.fetch_did_assigned.send) return;
      try {
        this.api.fetch_did_assigned.send=true
        const { data } = await VOIP_API.endpoints.telephone_number.getdidassigned(this.getCurrentUser?.account)
        this.response.did_assigned=data ?? []
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_did_assigned.send=false
      }
    },
    updateCallWaiting() {
      let vm = this;
      if(vm.api.update_call_waiting.send) return;
      vm.api.update_call_waiting.send = true;
      VOIP_API.endpoints.users.updateuserdetail({
        field: `call_waiting`,
        value: !vm.conditions.call_waiting,
        accountcode: this.voipaccount,
      })
      .then(() => {
        vm.conditions.call_waiting=!vm.conditions.call_waiting
        vm.appNotify({
          message: "Successfully Updated!",
          type: "success",
        })
      })
      .catch((ex) => {
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        if(vm.$refs['call_waiting']) vm.$refs['call_waiting'].localChecked=!vm.conditions.call_waiting
        vm.api.update_call_waiting.send=false;
      });
    },
    fetchUserDetail() {
      let vm = this
      if (vm.api.user_detail.send) return;
      vm.api.user_detail.send = true;
      VOIP_API.endpoints.users.detail(vm.voipaccount)
      .then(({ data: detail }) => {
        vm.conditions.call_waiting=detail.callwaiting=='Y'
      })
      .finally(() => {
        vm.api.user_detail.send = false;
      });
    },
    onCollapseState(collapseId, isJustShown){
      if(collapseId=='advanceOptions-collapse'){
        this.conditions.is_view=isJustShown
      }
    },
  },
  activated() {
    this.selected.two_fact_auth=expected_tfa.includes(this.getCurrentUser?.two_factor_auth) ? this.getCurrentUser?.two_factor_auth : 'no'
    this.getTrustedDevices()
    this.fetchUserDetail()
    this.fetchDidAssigned()
  },
};
</script>

<style lang="scss" scoped>
.profile-image-section {
  position: relative;
  width: 180px;
  height: 180px;
  margin: 0 auto;
  overflow: hidden;
  @include border-radius(50%);
  margin-bottom: 3 * $dialer-default-padding;
  .loader-icon {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .profile-img-upload-btn {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    align-items: center;
    display: none;
    justify-content: center;
    .upload-icon {
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 1;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.6);
    }
    input {
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 2;
      opacity: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .profile-img {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include border-radius(50%);
    }
  }
  &:hover {
    .profile-img-upload-btn {
      display: block;
      cursor: pointer;
    }
  }
}
</style>