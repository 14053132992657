<template>
  <div v-if="isExecute" :id="el_id" class="connection">
    <Conference
      @to-dashboard="$emit('to-dashboard')"
      @calls-list="$emit('calls-list')"
      v-if="isConnectionEstablished"
      ref="conference"
      :JitsiConnection="JitsiConnection"
      :payload="videoCall.payload"
      :videoCall="videoCall"
      :listIndex="listIndex"
      @onDestroyed="onConferenceDestroyed"
      @addNewConference="addNewConference($event)"
      @toggleIsPlay="toggleIsPlay"
      @showModal="$modal.show('AddMembersInVideoCall')"
      :el_id="el_id"
    />
    <div v-else class="connection-Loading">
      
    </div>
  </div>
</template>

<script>
import JitsiMeetJS from "@/Jitsi";
import Conference from "./Conference.vue";
import { $fn, LOGGER, URLS } from "../../utils";

export default {
  props: ["videoCall", "listIndex"],
  name: "Connection",
  components: {
    Conference,
  },
  data() {
    return {
      isConnectionEstablished: false,
      isExecute: false,
      JitsiConnection: null,
      payload: {},
      el_id: '',
    };
  },
  methods: {
    //--own--
    toggleIsPlay(isPlay) {
      this.$emit("toggleIsPlay", isPlay);
    },
    makeConnection(token) {
      try {
        // this.payload = JwtDecode(token);
        const domain = URLS.JitsiUrl.replace('https://','').replace('/http-bind','')
        const socket_url = `wss://${domain}/xmpp-websocket`
        const options = {
          hosts: {
            domain: `${this.videoCall.payload.sub}`,
            muc: `muc.${this.videoCall.payload.sub}`,
          },
          serviceUrl: socket_url,
          clientNode: domain,
          // consider_bosh_secure: true,
          enableEffect: true,
        };
        this.JitsiConnection = new JitsiMeetJS.JitsiConnection(this.videoCall.payload.iss,token,options);
        LOGGER.info("JitsiConnection: ", this.JitsiConnection);
        const { events: { connection: events }, } = JitsiMeetJS;
        this.JitsiConnection.addEventListener(events.CONNECTION_ESTABLISHED,this.CONNECTION_ESTABLISHED);
        this.JitsiConnection.addEventListener(events.CONNECTION_FAILED,this.CONNECTION_FAILED);
        this.JitsiConnection.addEventListener(events.CONNECTION_DISCONNECTED,this.CONNECTION_DISCONNECTED);
        this.JitsiConnection.addEventListener(events.WRONG_STATE, this.WRONG_STATE);
        this.JitsiConnection.addEventListener(events.DISPLAY_NAME_REQUIRED,this.DISPLAY_NAME_REQUIRED);
        this.JitsiConnection.connect();
        this.isExecute = true;
      } catch (error) {
        LOGGER.danger("error in makeConnection function", error);
        this.$destroy()
      }
    },
    onConferenceDestroyed() {
      this.$destroy();
    },

    //--events--
    CONNECTION_ESTABLISHED(id) {
      LOGGER.event("CONNECTION_ESTABLISHED", {
        id,
      });
      this.isConnectionEstablished = true;
    },
    CONNECTION_FAILED(errorCode, type) {
      LOGGER.event("CONNECTION_FAILED", {
        errorCode,
        type,
      });
      const {
        errors: { connection: errors },
      } = JitsiMeetJS;
      switch (errorCode) {
        case errors.OTHER_ERROR:
          this.OTHER_ERROR();
          break;
        case errors.PASSWORD_REQUIRED:
          this.PASSWORD_REQUIRED();
          break;
        case errors.CONNECTION_DROPPED_ERROR:
          this.CONNECTION_DROPPED_ERROR();
          break;
        case errors.SERVER_ERROR:
          this.SERVER_ERROR();
          break;

        default:
          LOGGER.info("error are not in JitsiMeetJS", { errors });
          break;
      }
    },
    CONNECTION_DISCONNECTED() {
      LOGGER.event("CONNECTION_DISCONNECTED");
      this.$destroy();
    },
    WRONG_STATE() {
      LOGGER.event("WRONG_STATE");
    },
    DISPLAY_NAME_REQUIRED(...args) {
      LOGGER.event("DISPLAY_NAME_REQUIRED", ...args);
    },

    //--error--
    CONNECTION_DROPPED_ERROR() {
      LOGGER.danger("CONNECTION_DROPPED_ERROR");
    },
    PASSWORD_REQUIRED() {
      LOGGER.danger("PASSWORD_REQUIRED");
    },
    SERVER_ERROR() {
      LOGGER.danger("SERVER_ERROR");
    },
    OTHER_ERROR() {
      LOGGER.danger("OTHER_ERROR");
      this.JitsiConnection.disconnect();
    },
  },
  created() {
    LOGGER.hook("data", this.videoCall);
    this.makeConnection(this.videoCall?.jwt);
    this.el_id=`jitsi-${$fn.makeid()}`
  },
  beforeDestroy() {
    LOGGER.hook("beforeDestroy Connection");
    if (this.JitsiConnection) {
      const { events: { connection: events }, } = JitsiMeetJS;
      this.JitsiConnection.removeEventListener(events.CONNECTION_ESTABLISHED,this.CONNECTION_ESTABLISHED);
      this.JitsiConnection.removeEventListener(events.CONNECTION_FAILED,this.CONNECTION_FAILED);
      this.JitsiConnection.removeEventListener(events.CONNECTION_DISCONNECTED,this.CONNECTION_DISCONNECTED);
      this.JitsiConnection.removeEventListener(events.WRONG_STATE, this.WRONG_STATE);
      this.JitsiConnection.removeEventListener(events.DISPLAY_NAME_REQUIRED,this.DISPLAY_NAME_REQUIRED);
      this.JitsiConnection.disconnect();
    }
  },
  destroyed() {
    LOGGER.hook("destroyed Connection");
    this.$emit("onEnd");
  },
};
</script>

<style lang="scss" scoped>
.connection {
  height: 100%;
  width: 100%;
  border-bottom: currentColor solid 1px;
  .connection-Loading {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    video{
      width: 100%;
      height: 100%;
    }
  }
}
</style>