
<template>
  <modal 
    :name="modalName" 
    @before-open="onBeforeOpen($event)" 
    @before-close="onBeforeClose()" 
    class="MenuOptionExtensionAssignModal dialer_animation right_side_popup m-w-500 bigSquareCheckbox min-width-50percent-modal" 
    width="50%" 
    height="auto" 
    :scrollable="true"
  >
    <div class="dialer-assign-to md-pad-bottom dialer-edit">
      <div class="dialer-edit-header d-block">
        <div class="d-flex justify-content-between align-items-center w-100">
          <h2 class="dialer-settings-title newer">Press {{data.dtmf}} for</h2>
          <div class="dialer-edit-actions">
            
          </div>
        </div>
        <p class="vm--modal-text mb-0">Select where the call will be forwarded if '{{data.dtmf}}' is pressed in the IVR menu.</p>
      </div>
      <!-- ringgroup options -->
      <template v-if="data.ringgroup_id">
        <div class="latestGreyBox-9-9-2023 mt-20px">
          <div class="whiteBoxWithBorderInsideGreyBox">
            <div class="whiteBoxWithBorderInsideGreyBox-row">
              <div class="mr-16px">
                <div class="latestGreyBox-heading-main">Advance options</div>
                <!-- <div class="latestGreyBox-descriptionText"></div> -->
              </div>
              <button class="newButton ml-5" type="button" @click="conditions.is_view=!conditions.is_view">
                <vb-icon :icon="conditions.is_view ? 'squared-hide-icon' : 'squared-show-icon'" height="38px" width="38px" />
                <span class="newButton-textPart">
                  <span>{{ conditions.is_view ? 'Hide' : 'View' }}</span>
                </span>
              </button>
            </div>
            <b-collapse class="mt-20px" :visible="conditions.is_view">
              <div class="whiteBoxWithBorderInsideGreyBox-row">
                <div class="d-flex flex-column w-75">
                  <div class="latestGreyBox-heading-main">Dedicate this number to a IVR</div>
                  <div class="latestGreyBox-descriptionText">
                    This option assigns a number to a IVR, allowing custom call routing, forwarding, and voicemail settings in the user profile section.
                  </div>
                </div>
                <div class="md-mar-left">
                  <div v-if="api.update_user_mode.send" class="latestShimmerDesign" style="height:34px;width:60px;border-radius: 25px"></div>
                  <b-form-checkbox 
                    v-else
                    v-b-tooltip :title="isUser || data.ringGroupMembers.length==1?'':'Please select only one user to switch this toggle button'"
                    :disabled="api.update_user_mode.send || !(isUser || data.ringGroupMembers.length==1)"
                    class="newerSwitch" 
                    :checked="data.user_mode" 
                    @change="updateUserMode()"
                    ref="user-mode"
                    switch 
                  />
                </div>
              </div>
              <div>
                <template v-if="!data.user_mode">
                  <div  class="whiteBoxWithBorderInsideGreyBox-row somethingInside">
                    <CallForwardingSetting 
                      @cf-updated="$emit('cf-updated')"
                      :account="data.ringgroup_id"
                    />
                  </div>
                  <div class="whiteBoxWithBorderInsideGreyBox-row somethingInside dialer_box_text_my-0">
                    <MusicOnHoldWidget :accountcode="data.ringgroup_id"  />
                  </div>
                </template>
              </div>
            </b-collapse>
          </div>
        </div>
      </template>
    </div>
  </modal>
</template>

<script>
import { VOIP_API } from "../../utils";
import MusicOnHoldWidget from '../widgets/MusicOnHold.vue'
import CallForwardingSetting from '../Common/CallForwardingSetting.vue';
import { mapGetters } from 'vuex';
import _ from 'lodash'
export default {
  name: "IVRMenuRingGroupSettingModal",
  components: { 
    MusicOnHoldWidget,
    CallForwardingSetting,
  },
  inject:[
    'isEmpty',
    'appNotify',
  ],
  props: {
    modalName: {
      type: String,
      default: 'IVRMenuRingGroupSettingModal'
    },
  },
  data() {
    return {
      api: {
        update_user_mode: this.$helperFunction.apiInstance(),
      },
      data: {
        type: '',
        dtmf: '',
        accountcode: '',
        schedule_id: '',
        account: '',
        ringgroup_id: '',
        ringGroupMembers: [],
        user_mode: false,
      },
      conditions: {
        is_view: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      'getVoipUsersAlises',
    ]),
    isUser(){ return !!this.getVoipUsersAlises[this.data.account] },
  },
  methods: {
    onBeforeOpen(event){
      const { account='', type='', dtmf='', schedule_id='', accountcode='', ringgroup_id='', user_mode=0, ringGroupMembers=[] } = event.params ?? {}
      const members = ringGroupMembers.map(i=>i.cg_item)
      this.data.type = type;
      this.data.dtmf = dtmf;
      this.data.accountcode = accountcode;
      this.data.schedule_id = schedule_id;
      this.data.account = account;
      this.data.ringgroup_id = ringgroup_id;
      this.data.user_mode = user_mode==1;
      this.data.ringGroupMembers = _.cloneDeep(members);
    },
    onBeforeClose(){
      this.data.type = '';
      this.data.dtmf = '';
      this.data.accountcode = '';
      this.data.schedule_id = '';
      this.data.account = '';
      this.data.ringgroup_id = '';
      this.data.ringGroupMembers = [];
      this.data.user_mode = false;
    },
    async updateUserMode(){
      if(this.api.update_user_mode.send) return;
      try {
        this.api.update_user_mode.send=true
        const { data: { ivr: { data: menu } } } = await VOIP_API.endpoints.menu.updateUserMode({
          id: this.data.accountcode,
          option: this.data.dtmf,
          schedule_id: this.data.schedule_id,
          type: 'ivrs'
        })
        this.data.user_mode=!this.data.user_mode
        this.$emit("updated",{
          menu: menu
        });
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.update_user_mode.send=false
        if(this.$refs['update-role']) this.$refs['update-role'].localChecked = this.data.user_mode
      }
    },
  },
};
</script>
