<template>
  <div class="position-relative">
    <div class="flow__graph designed designedAgain responsiveGraph scaleDown-0-6" ref="app_designed" id="app_designed">
      <flowy class="" :nodes="nodes"></flowy>
    </div>
    <ScheduleAssignModal 
      :disableCurrentUser="false"
      :modalName="`CallForwardingSelectExtensionModalForGraph`" 
      @interface="updateExtension($event)" 
    />
    <AddRemoveFromList modalName="UserCallerIdShared2" @update-list="getNumbers()" />
  </div>
</template>

<script>
import ScheduleAssignModal from "../../Modals/ScheduleAssignModal.vue";
import AddRemoveFromList from "../../Modals/AddRemoveFromList.vue";
import { LOGGER, VOIP_API } from '../../../utils';
import { mapGetters } from 'vuex';

export default {
  name: 'NumberGraphMain',
  components:{
    ScheduleAssignModal,
    AddRemoveFromList
  },
  inject: ['getNumbers','appNotify'],
  props:{
    id: {
      type: [String,Number]
    }
  },
  computed: {
    ...mapGetters([
      'getVoipNumbers',
    ]),
    number(){ return this.getVoipNumbers.find(i=>i.real_id==this.id) },
    nodes() {
      if(!this.number) return;
      const voipaccount = this.number.real_id
      const assignto = this.number?.assignedToUsersArr?.extn
      const callBack = this.callBack
      const nodes = []
      nodes.push({
        id: "info",
        parentId: -1,
        nodeComponent: "vb-graph-node",
        data: {
          node: 'Info',
          voipaccount,
        }
      })
      nodes.push({
        id: "assigned",
        parentId: "info",
        nodeComponent: "vb-graph-node",
        data: {
          node: 'NumberAssign',
          voipaccount: assignto,
          callBack
        }
      })
      nodes.push({
        id: "sharednumbernode",
        parentId: "assigned",
        nodeComponent: "vb-graph-node",
        data: {
          node: 'NumberSharedWith',
          voipaccount,
          callBack
        }
      })
      return nodes
    }
  },
  methods:{
    callBack(message){
      LOGGER.log(message)
      switch (message?.event) {
        case 'update_extension':
          this.$modal.show('CallForwardingSelectExtensionModalForGraph',{
            type: 'setting',
          })
          break;
        case 'unassign_extension':
          this.updateExtension()
          break;
        case 'update_shared':
          this.$modal.show('UserCallerIdShared2', {
            extensions: this.number?.extensions ?? [], 
            number: this.number, 
            type: 'UserCallerIdShared'
          })
          break;
        case 'show_graph':
          this.$emit('change-graph',message?.payload?.voipaccount)
          break;
        default:
          break;
      }
    },
    updateExtension($event) {
      VOIP_API.endpoints.telephone_number.updateExtn(this.number.real_id, {
        extn: $event?.account ?? '',
        field: 'extn',
      })
      .then(() => {
        this.getNumbers()
        this.appNotify({
          message: "Successfully Added!",
          type: "success",
        })
      });
    },
  },
}
</script>

<style>

</style>