import { $fn, VOIP_API } from "@/utils/index";
import { CRM_FETCH_PIPELINES, CRM_FETCH_STORE_DATA, CRM_LOGIN, CRM_REFRESH_TOKEN, CRM_RESET } from "../helper/actions";
import { GET_CRM_API_URL, GET_CRM_IS_CONNECTED, GET_CRM_PIPELINES, GET_CRM_TOKEN, GET_CRM_USER } from "../helper/getters";
import { SET_CRM_API_URL, SET_CRM_PIPELINES, SET_CRM_TOKEN, SET_CRM_USER } from "../helper/mutations";

const api_sent = {
  refresh: false,
}

export default {
  state: {
    user: {},
    api_url: '',
    token: '',
    pipelines: [],
  },
  getters: {
    [GET_CRM_TOKEN](state){ return state.token },
    [GET_CRM_USER](state){ return state.user },
    [GET_CRM_API_URL](state){ return state.api_url },
    [GET_CRM_IS_CONNECTED](state,getters){ return !!getters[GET_CRM_TOKEN] },
    [GET_CRM_PIPELINES](state){ return state.pipelines },
  },
  actions: {
    async [CRM_LOGIN](context){
      const { getCurrentUser, fpData } = context.rootGetters
      const { data: { crm_accessToken, crm_userId, crm_email, crm_name } } = await VOIP_API.endpoints.crm.auth.login({
        email: `${getCurrentUser.jid_account}@voip.com`,
        password: getCurrentUser.jid_pass,
        device_name: fpData.device_id,
      })
      context.commit(SET_CRM_TOKEN,crm_accessToken)
      context.commit(SET_CRM_USER,{
        id: crm_userId,
        name: crm_name,
        email: crm_email,
      })
    },
    async [CRM_FETCH_STORE_DATA](context) {
      context.dispatch(CRM_FETCH_PIPELINES)
    },
    async [CRM_FETCH_PIPELINES](context){
      const { data: { data: pipelines } } = await VOIP_API.endpoints.crm.pipelines.list({
        per_page: 1000,
      })
      context.commit(SET_CRM_PIPELINES,pipelines)
    },
    async [CRM_REFRESH_TOKEN](context){
      if(api_sent.refresh){
        // TODO - in case of refresh token api send
        await $fn.sleep('5s')
        if(api_sent.refresh){
          await $fn.sleep('5s')
          if(api_sent.refresh){
            await $fn.sleep('5s')
            if(api_sent.refresh){
              await $fn.sleep('5s')
              if(api_sent.refresh){
                await $fn.sleep('5s')
                if(api_sent.refresh){
                  await $fn.sleep('5s')
                  if(api_sent.refresh){
                    await $fn.sleep('5s')
                    return;
                  } else {
                    return;
                  }
                } else {
                  return;
                }
              } else {
                return;
              }
            } else {
              return;
            }
          } else {
            return;
          }
        } else {
          return;
        }
      } else {
        api_sent.refresh=true
        await context.dispatch(CRM_LOGIN)
        api_sent.refresh=false
      }
    },
    async [CRM_RESET](context){
      context.commit(SET_CRM_API_URL)
      context.commit(SET_CRM_TOKEN)
      context.commit(SET_CRM_USER)
      context.commit(SET_CRM_PIPELINES)
    },
  },
  mutations: {
    [SET_CRM_API_URL](state,data){ 
      state.api_url=data ?? ''
    },
    [SET_CRM_TOKEN](state,data){ 
      state.token=data ?? ''
    },
    [SET_CRM_USER](state,data){ 
      state.user=data ?? {}
    },
    [SET_CRM_PIPELINES](state,data){ 
      state.pipelines=data ?? []
    },
  },
};