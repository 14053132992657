<template>
  <div>
    <modal class="dialer_animation right_side_popup AudioSettingsModal v2" width="40%" height="auto" :scrollable="true" :name="modalName" @before-close="onBeforeClose">
      <div class="dialer-soundSetting-container">
        <div class="dialer-soundSetting dialer-soundSetting-responsive">
          <div class="dialer-edit-header d-block pb-0">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <h2 class="dialer-settings-title newer mb-0">Communication device and alert</h2>
              </div>
              <div class="dialer-edit-actions">
                <button class="newDoneButton" type="button" @click="$modal.hide(modalName)">
                  <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                  <span class="newDoneButton-textPart">Done</span>
                </button>
              </div>
            </div>
            <div class="vm--modal-text">Set up your audio and video devices, and personalize your ringtone and alert sounds.</div>
          </div>
          <div class="latestGreyBox-9-9-2023 mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox">
              <!-- microphone section -->
              <div web-dashboard-media-setting-microphone class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                <div class="d-flex justify-content-between align-items-center w-100 testButtonsContainer-parent">
                  <div class="d-flex flex-column flex-fill pr-4">
                    <div class="latestGreyBox-heading-main">Microphone</div>
                    <div class="latestGreyBox-descriptionText">When you are speaking to a caller, which microphone do you want to use?</div>
                  </div>
                  <div class="testButtonsContainer justify-content-end">
                    <div @click=" isEmpty(testCallSession) ? testCall() : $store.state.sip.phone.activeSession.hangup() " class=" dialer-soundSetting-testing-audio dialer-soundSetting-testing-call " >
                      <vb-svg name="dialer-outlineCall-icon" width="18.801" stroke="" height="18.802" viewBox="0 0 18.801 18.802" fill="none" stroke-width="0" stroke-linecap="round" stroke-linejoin="round" ></vb-svg>
                      <span class="dialer-soundSetting-testing-audio-text"> {{ isEmpty(testCallSession) ? "Make a free test call" : "End call" }} </span>
                    </div>
                  </div>
                </div>
                <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion optionAndButtonIsdotted addScrollToMenu withCustomSvgCheck mt-12px w-100 mb-0">
                  <b-dropdown id="sound-setting-micro-devices">
                    <template #button-content>
                      <span>{{ media_devices_setting.selectedMicDevice | get_property('label','Select device') }}</span>
                      <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                    </template>
                    <b-dropdown-item v-for="device in media_devices_setting.audioinputs" :key="device.deviceId" @click="media_devices_setting.micId=device.deviceId;">
                      <vb-svg v-if="device.deviceId==media_devices_setting.mic_id" name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                      <span> {{ device.label }} </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <MicrophoneTester class="mt-4 w-100" v-if="media_devices_setting.mic_id" :deviceId="media_devices_setting.mic_id" />
              </div>
              <!-- speaker section -->
              <div web-dashboard-media-setting-speaker class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                <div class="d-flex justify-content-between align-items-center w-100 testButtonsContainer-parent">
                  <div class="d-flex flex-column flex-fill pr-4">
                    <div class="latestGreyBox-heading-main">Speaker</div>
                    <div class="latestGreyBox-descriptionText">When you are talking to a caller, which speaker do you want to use?</div>
                  </div>
                  <div class="testButtonsContainer justify-content-end">
                    <div class="dialer-soundSetting-testing-audio" @click="is_play = is_play==audioTypes.speaker ? '' : audioTypes.speaker; " >
                      <vb-svg id="toggle-play" :name=" is_play!=audioTypes.speaker ? 'dialer-outlinePlay-icon' : 'dialer-pause-arrowhead' " width="16" stroke="" height="18.035" viewBox="0 0 16 18.035" fill="none" stroke-width="0" stroke-linecap="round" stroke-linejoin="round" ></vb-svg>
                      <span class="dialer-soundSetting-testing-audio-text"> Test audio </span>
                    </div>
                  </div>
                </div>
                <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion optionAndButtonIsdotted addScrollToMenu withCustomSvgCheck mt-12px w-100 mb-0">
                  <b-dropdown id="sound-setting-micro-devices" text="Select device">
                    <template #button-content>
                      <span> {{ media_devices_setting.selectedSpeakerDevice | get_property('label','Select device') }} </span>
                      <b-icon-chevron-down scale="1.1" />
                    </template>
                    <b-dropdown-item v-for="device in media_devices_setting.audiooutputs" :key="device.deviceId" @click="media_devices_setting.speakerId=device.deviceId">
                      <vb-svg v-if="media_devices_setting.speaker_id== device.deviceId" name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                      <span>{{ device.label }}</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <div class="SpeakerProgressContainer w-100">
                  <input :value="media_devices_setting.speaker_volume" min="0" max="100" type="range" @change="media_devices_setting.speakerVolume=$event.target.value" step="1" />
                  <audio @ended="is_play=''" :show="false" id="testing-audio" :src="require('@/assets/sounds/incoming.mp3')" />
                </div>
              </div>
            </div>
          
            <!-- camera section -->
            <div web-dashboard-media-setting-video class="mt-20px">
              <div class="whiteBoxWithBorderInsideGreyBox">
                <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                  <div class="d-flex justify-content-between align-items-center w-100">
                    <div class="d-flex flex-column flex-fill pr-4">
                      <div class="latestGreyBox-heading-main">Video</div>
                      <div class="latestGreyBox-descriptionText">When you are talking to a caller, which camera do you want to use?</div>
                    </div>
                  </div>
                  <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion optionAndButtonIsdotted addScrollToMenu withCustomSvgCheck mt-12px w-100 mb-0">
                    <b-dropdown id="sound-setting-micro-devices" text="Select device">
                      <template #button-content>
                        <span> {{ media_devices_setting.selectedCameraDevice | get_property('label','Select device') }} </span>
                        <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                      </template>
                      <b-dropdown-item v-for="device in media_devices_setting.videoinputs" :key="device.deviceId" @click="media_devices_setting.cameraId=device.deviceId" >
                        <vb-svg v-if="media_devices_setting.camera_id == device.deviceId" name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                        <span> {{ device.label }} </span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <div class="cameraContainer latestGreyBox-9-9-2023 w-100 d-flex justify-content-center">
                    <CameraTester v-if="media_devices_setting.camera_id" :deviceId="media_devices_setting.camera_id" />
                    <template v-else>
                      <div class="d-flex w-100 justify-content-center">
                        <img src="../../assets/images/cameraPlaceholder.png" />
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-20px">
              <div class="whiteBoxWithBorderInsideGreyBox">
                <!-- ringing device section -->
                <div web-dashboard-media-setting-ringing-device class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                  <div class="d-flex justify-content-between align-items-center w-100">
                    <div class="d-flex flex-column flex-fill pr-4">
                      <div class="latestGreyBox-heading-main">Ringing device</div>
                      <div class="latestGreyBox-descriptionText">Which speaker do you want to use to hear incoming calls ringing?</div>
                    </div>
                  </div>
                  <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion optionAndButtonIsdotted addScrollToMenu withCustomSvgCheck mt-12px w-100 mb-0">
                    <b-dropdown id="sound-setting-micro-devices" text="Select Device">
                      <template #button-content>
                        <span> {{ media_devices_setting.selectedRingingSpeakerDevice | get_property('label','Select device') }} </span>
                        <b-icon-chevron-down scale="1.1" />
                      </template>
                      <b-dropdown-item v-for="device in media_devices_setting.audiooutputs" :key="device.deviceId" @click="media_devices_setting.ringingSpeakerId=device.deviceId;">
                        <vb-svg v-if="media_devices_setting.ringing_speaker_id==device.deviceId" name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                        <span>{{ device.label }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <div class="SpeakerProgressContainer w-100">
                    <input :value="media_devices_setting.ringing_speaker_volume" min="0" max="100" type="range" @change="media_devices_setting.ringingSpeakerVolume=$event.target.value" step="1" />
                  </div>
                </div>
                <!-- incomming ringtune section -->
                <div web-dashboard-media-setting-incoming-ringtone class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                  <div class="d-flex justify-content-between align-items-center w-100">
                    <div class="d-flex flex-column flex-fill pr-4">
                      <div class="latestGreyBox-heading-main">Incoming ringtone</div>
                      <div class="latestGreyBox-descriptionText">Choose your preferred ringtone here, you'll hear a preview when you've selected one</div>
                    </div>
                    <a class="newButton" @click="$modal.show(`${_uid}-IncommingRingtone`)">
                      <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                      <span class="newButton-textPart">Change</span>
                    </a>
                  </div>
                  <div class="testButtonsContainer w-100 justify-content-between mt-20px">
                    <div class="latestGreyBox-descriptionText">
                      <b>{{ media_devices_setting.selectedIncomingRingtone | get_property('label','Select ringtone') }}</b>
                    </div>
                    <audio @ended="is_play=''" :show="false" id="incoming-ringtone-testing-audio" :src="media_devices_setting.selectedIncomingRingtone | get_property('file')" />
                    <div class="dialer-soundSetting-testing-audio" @click="is_play = is_play==audioTypes.incoming_ringtone ? '' : audioTypes.incoming_ringtone; " >
                      <vb-svg id="toggle-play" :name=" is_play!=audioTypes.incoming_ringtone ? 'dialer-outlinePlay-icon' : 'dialer-pause-arrowhead' " width="16" stroke="" height="18.035" viewBox="0 0 16 18.035" fill="none" stroke-width="0" stroke-linecap="round" stroke-linejoin="round" ></vb-svg>
                      <span class="dialer-soundSetting-testing-audio-text"> Test audio </span>
                    </div>
                  </div>
                  <!-- <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion addScrollToMenu withCustomSvgCheck mt-12px w-75 mb-0">
                    <b-dropdown id="sound-setting-micro-devices" text="Select Device">
                      <template #button-content>
                        <span> {{ media_devices_setting.selectedIncomingRingtone | get_property('label','Select Ringtone') }} </span>
                        <b-icon-chevron-down scale="1.1" />
                      </template>
                      <b-dropdown-item v-for="ringtone in media_devices_setting.incomingRingtons" :key="ringtone.id" @click="media_devices_setting.incomingRingtoneId=ringtone.id;">
                        <vb-svg v-if="media_devices_setting.incoming_ringtone==ringtone.id" name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                        <span>{{ ringtone.label }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div> -->
                </div>
                <!-- Alert ringtune section -->
                <div web-dashboard-media-setting-alert-ringtone class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                  <div class="d-flex justify-content-between align-items-center w-100">
                    <div class="d-flex flex-column flex-fill pr-4">
                      <div class="latestGreyBox-heading-main">Alert ringtone</div>
                      <div class="latestGreyBox-descriptionText">Choose your preferred ringtone here, you'll hear a preview when you've selected one</div>
                    </div>
                    <a class="newButton" @click="$modal.show(`${_uid}-AlertRingtone`)">
                      <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                      <span class="newButton-textPart">Change</span>
                    </a>
                  </div>
                  <div class="testButtonsContainer w-100 justify-content-between mt-20px">
                    <div class="latestGreyBox-descriptionText">
                      <b>{{ media_devices_setting.selectedAlertRingtone | get_property('label','Select ringtone') }}</b>
                    </div>
                    <audio @ended="is_play=''" :show="false" id="alert-ringtone-testing-audio" :src="media_devices_setting.selectedAlertRingtone | get_property('file')" />
                    <div class="dialer-soundSetting-testing-audio" @click="is_play = is_play==audioTypes.alert_ringtone ? '' : audioTypes.alert_ringtone; " >
                      <vb-svg id="toggle-play" :name=" is_play!=audioTypes.alert_ringtone ? 'dialer-outlinePlay-icon' : 'dialer-pause-arrowhead' " width="16" stroke="" height="18.035" viewBox="0 0 16 18.035" fill="none" stroke-width="0" stroke-linecap="round" stroke-linejoin="round" ></vb-svg>
                      <span class="dialer-soundSetting-testing-audio-text"> Test audio </span>
                    </div>
                  </div>
                  <!-- <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion addScrollToMenu withCustomSvgCheck mt-12px w-75 mb-0">
                    <b-dropdown id="sound-setting-micro-devices" text="Select Device">
                      <template #button-content>
                        <span> {{ media_devices_setting.selectedAlertRingtone | get_property('label','Select Ringtone') }} </span>
                        <b-icon-chevron-down scale="1.1" />
                      </template>
                      <b-dropdown-item v-for="ringtone in media_devices_setting.alertRingtons" :key="ringtone.id" @click="media_devices_setting.alertRingtoneId=ringtone.id;">
                        <vb-svg v-if="media_devices_setting.alert_ringtone==ringtone.id" name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                        <span>{{ ringtone.label }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div> -->
                </div>
                <!-- Send Message ringtune section -->
                <div web-dashboard-media-setting-alert-ringtone class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                  <div class="d-flex justify-content-between align-items-center w-100">
                    <div class="d-flex flex-column flex-fill pr-4">
                      <div class="latestGreyBox-heading-main">Send message ringtone</div>
                      <div class="latestGreyBox-descriptionText">Choose your preferred ringtone here, you'll hear a preview when you've selected one</div>
                    </div>
                    <a class="newButton" @click="$modal.show(`${_uid}-SendMessageRingtone`)">
                      <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                      <span class="newButton-textPart">Change</span>
                    </a>
                  </div>
                  <div class="testButtonsContainer w-100 justify-content-between mt-20px">
                    <div class="latestGreyBox-descriptionText">
                      <b>{{ media_devices_setting.selectedSendmessageRingtone | get_property('label','Select ringtone') }}</b>
                    </div>
                    <audio @ended="is_play=''" :show="false" id="send-message-ringtone-testing-audio" :src="media_devices_setting.selectedSendmessageRingtone | get_property('file')" />
                    <div class="dialer-soundSetting-testing-audio" @click="is_play = is_play==audioTypes.send_message_ringtone ? '' : audioTypes.send_message_ringtone; " >
                      <vb-svg id="toggle-play" :name=" is_play!=audioTypes.send_message_ringtone ? 'dialer-outlinePlay-icon' : 'dialer-pause-arrowhead' " width="16" stroke="" height="18.035" viewBox="0 0 16 18.035" fill="none" stroke-width="0" stroke-linecap="round" stroke-linejoin="round" ></vb-svg>
                      <span class="dialer-soundSetting-testing-audio-text"> Test audio </span>
                    </div>
                  </div>
                  <!-- <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion addScrollToMenu withCustomSvgCheck mt-12px w-75 mb-0">
                    <b-dropdown id="sound-setting-micro-devices" text="Select Device">
                      <template #button-content>
                        <span> {{ media_devices_setting.selectedSendmessageRingtone | get_property('label','Select Ringtone') }} </span>
                        <b-icon-chevron-down scale="1.1" />
                      </template>
                      <b-dropdown-item v-for="ringtone in media_devices_setting.sendMessageRingtons" :key="ringtone.id" @click="media_devices_setting.sendMessageRingtoneId=ringtone.id;">
                        <vb-svg v-if="media_devices_setting.send_message_ringtone==ringtone.id" name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                        <span>{{ ringtone.label }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div> -->
                </div>
                <!-- Recieve Message ringtune section -->
                <div web-dashboard-media-setting-alert-ringtone class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                  <div class="d-flex justify-content-between align-items-center w-100">
                    <div class="d-flex flex-column flex-fill pr-4">
                      <div class="latestGreyBox-heading-main">Receive message ringtone</div>
                      <div class="latestGreyBox-descriptionText">Choose your preferred ringtone here, you'll hear a preview when you've selected one</div>
                    </div>
                    <a class="newButton" @click="$modal.show(`${_uid}-RecieveMessageRingtone`)">
                      <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                      <span class="newButton-textPart">Change</span>
                    </a>
                  </div>
                  <div class="testButtonsContainer w-100 justify-content-between mt-20px">
                    <div class="latestGreyBox-descriptionText">
                      <b>{{ media_devices_setting.selectedRecievemessageRingtone | get_property('label','Select ringtone') }}</b>
                    </div>
                    <audio @ended="is_play=''" :show="false" id="recieve-message-ringtone-testing-audio" :src="media_devices_setting.selectedRecievemessageRingtone | get_property('file')" />
                      <div class="dialer-soundSetting-testing-audio" @click="is_play = is_play==audioTypes.recieve_message_ringtone ? '' : audioTypes.recieve_message_ringtone; " >
                      <vb-svg id="toggle-play" :name=" is_play!=audioTypes.recieve_message_ringtone ? 'dialer-outlinePlay-icon' : 'dialer-pause-arrowhead' " width="16" stroke="" height="18.035" viewBox="0 0 16 18.035" fill="none" stroke-width="0" stroke-linecap="round" stroke-linejoin="round" ></vb-svg>
                      <span class="dialer-soundSetting-testing-audio-text"> Test audio </span>
                    </div>
                  </div>
                  <!-- <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion addScrollToMenu withCustomSvgCheck mt-12px w-75 mb-0">
                    <b-dropdown id="sound-setting-micro-devices" text="Select Device">
                      <template #button-content>
                        <span> {{ media_devices_setting.selectedRecievemessageRingtone | get_property('label','Select Ringtone') }} </span>
                        <b-icon-chevron-down scale="1.1" />
                      </template>
                      <b-dropdown-item v-for="ringtone in media_devices_setting.recieveMessageRingtons" :key="ringtone.id" @click="media_devices_setting.recieveMessageRingtoneId=ringtone.id;">
                        <vb-svg v-if="media_devices_setting.recieve_message_ringtone==ringtone.id" name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                        <span>{{ ringtone.label }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div> -->
                </div>
              </div>
            </div>
          </div>















          <!--<div v-if="false" web-dashboard-media-setting-microphone class="dialer-box alt">
            <div class="dialer-soundSetting-device dialer-volumeSetting-device">
              <div class="dialer-soundSetting-device-inner">
                <span class="dialer-soundSetting-device-name dialer-box-header"> Microphone </span>
                <p class="dialer-box-text">
                  <span>When you are speaking to a caller, which microphone do you want to use?</span>
                </p>
                <div class="dialer-input-field input-consistent-inner-shadows whiteBg-select w-75 simple-image">
                  <div class="dialer-dropdown dialer-soundSetting-device-dropdown fullWidth">
                    <b-dropdown id="sound-setting-micro-devices" text="Select Device">
                      <template #button-content>
                        <span class="d-flex align-items-center p-0">
                          <span>{{ media_devices_setting.selectedMicDevice | get_property('label','Select Device') }}</span>
                          <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                        </span>
                      </template>
                      <b-dropdown-item v-for="device in media_devices_setting.audioinputs" :key="device.deviceId" @click="media_devices_setting.micId=device.deviceId;">
                        <vb-svg v-if="device.deviceId==media_devices_setting.mic_id" name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                        <span> {{ device.label }} </span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
              </div>
              <MicrophoneTester v-if="media_devices_setting.mic_id" :deviceId="media_devices_setting.mic_id" />
            </div>
            <div class="testButtonsContainer justify-content-end mt-5">
              <div @click=" isEmpty(testCallSession) ? testCall() : $store.state.sip.phone.activeSession.hangup() " class=" dialer-soundSetting-testing-audio dialer-soundSetting-testing-call " >
                <vb-svg name="dialer-outlineCall-icon" width="18.801" stroke="" height="18.802" viewBox="0 0 18.801 18.802" fill="none" stroke-width="0" stroke-linecap="round" stroke-linejoin="round" ></vb-svg>
                <span class="dialer-soundSetting-testing-audio-text"> {{ isEmpty(testCallSession) ? "Make a free test call" : "End Call" }} </span>
              </div>
            </div>
          </div>
          <div v-if="false" web-dashboard-media-setting-speaker class="dialer-box alt">
            <div class="dialer-soundSetting-device dialer-volumeSetting-device">
              <div class="dialer-soundSetting-device-inner">
                <span class="dialer-soundSetting-device-name dialer-box-header"> Speaker </span>
                <p class="dialer-box-text">
                  <span>When you are talking to a caller, which speaker do you want to use?</span>
                </p>
                <div class="dialer-input-field input-consistent-inner-shadows whiteBg-select w-75 simple-image">
                  <div class="dialer-dropdown dialer-soundSetting-device-dropdown fullWidth">
                    <b-dropdown id="sound-setting-micro-devices" text="Select Device">
                      <template #button-content>
                        <span class="d-flex align-items-center p-0">
                          <span> {{ media_devices_setting.selectedSpeakerDevice | get_property('label','Select Device') }} </span>
                          <b-icon-chevron-down scale="1.1" />
                        </span>
                      </template>
                      <b-dropdown-item v-for="device in media_devices_setting.audiooutputs" :key="device.deviceId" @click="media_devices_setting.speakerId=device.deviceId">
                        <vb-svg v-if="media_devices_setting.speaker_id== device.deviceId" name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                        <span>{{ device.label }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
              </div>
              <div class="SpeakerProgressContainer">
                <input :value="media_devices_setting.speaker_volume" min="0" max="100" type="range" @change="media_devices_setting.speakerVolume=$event.target.value" step="1" />
                <audio :show="false" id="testing-audio" loop :src="require('@/assets/sounds/incoming.mp3')" />
              </div>
            </div>
            <div class="testButtonsContainer justify-content-end mt-5">
              <div class="dialer-soundSetting-testing-audio" @click="is_play = is_play==audioTypes.speaker ? '' : audioTypes.speaker; " >
                <vb-svg id="toggle-play" :name=" is_play!=audioTypes.speaker ? 'dialer-outlinePlay-icon' : 'dialer-pause-arrowhead' " width="16" stroke="" height="18.035" viewBox="0 0 16 18.035" fill="none" stroke-width="0" stroke-linecap="round" stroke-linejoin="round" ></vb-svg>
                <span class="dialer-soundSetting-testing-audio-text"> Test audio </span>
              </div>
            </div>
          </div>
          <div v-if="false" web-dashboard-media-setting-video class="dialer-box alt">
            <div class="dialer-soundSetting-device border-0">
              <span class="dialer-soundSetting-device-name dialer-box-header"> Video </span>
              <p class="dialer-box-text">
                <span>When you are talking to a caller, which Camera do you want to use?</span>
              </p>
              <div class="dialer-input-field input-consistent-inner-shadows whiteBg-select w-75 simple-image">
                <div class="dialer-dropdown dialer-soundSetting-device-dropdown fullWidth">
                  <b-dropdown id="sound-setting-micro-devices" text="Select Device">
                    <template #button-content>
                      <span class="d-flex align-items-center p-0">
                        <span> {{ media_devices_setting.selectedCameraDevice | get_property('label','Select Device') }} </span>
                        <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                      </span>
                    </template>
                    <b-dropdown-item v-for="device in media_devices_setting.videoinputs" :key="device.deviceId" @click="media_devices_setting.cameraId=device.deviceId" >
                      <vb-svg v-if="media_devices_setting.camera_id == device.deviceId" name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                      <span> {{ device.label }} </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </div>
            <div class="cameraContainer">
              <CameraTester v-if="media_devices_setting.camera_id" :deviceId="media_devices_setting.camera_id" />
              <template v-else>
                <div class="d-flex w-100">
                  <img src="../../assets/images/cameraPlaceholder.png" />
                </div>
              </template>
            </div>
          </div>
          <div v-if="false" web-dashboard-media-setting-ringing-device class="dialer-box alt">
            <div class="dialer-soundSetting-device dialer-volumeSetting-device">
              <div class="dialer-soundSetting-device-inner">
                <span class="dialer-soundSetting-device-name dialer-box-header"> Ringing Device </span>
                <p class="dialer-box-text">
                  <span>Which speaker do you want to use to hear incoming calls ringing?</span>
                </p>
                <div class="dialer-input-field input-consistent-inner-shadows whiteBg-select w-75 simple-image">
                  <div class="dialer-dropdown dialer-soundSetting-device-dropdown fullWidth">
                    <b-dropdown id="sound-setting-micro-devices" text="Select Device">
                      <template #button-content>
                        <span class="d-flex align-items-center p-0">
                          <span> {{ media_devices_setting.selectedRingingSpeakerDevice | get_property('label','Select Device') }} </span>
                          <b-icon-chevron-down scale="1.1" />
                        </span>
                      </template>
                      <b-dropdown-item v-for="device in media_devices_setting.audiooutputs" :key="device.deviceId" @click="media_devices_setting.ringingSpeakerId=device.deviceId;">
                        <vb-svg v-if="media_devices_setting.ringing_speaker_id==device.deviceId" name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                        <span>{{ device.label }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
              </div>
              <div class="SpeakerProgressContainer">
                <input :value="media_devices_setting.ringing_speaker_volume" min="0" max="100" type="range" @change="media_devices_setting.ringingSpeakerVolume=$event.target.value" step="1" />
              </div>
            </div>
          </div>
          <div v-if="false" web-dashboard-media-setting-incoming-ringtone class="dialer-box alt">
            <div class="dialer-soundSetting-device dialer-volumeSetting-device">
              <div class="dialer-soundSetting-device-inner">
                <span class="dialer-soundSetting-device-name dialer-box-header"> Incoming Ringtone </span>
                <audio :show="false" id="incoming-ringtone-testing-audio" loop :src="media_devices_setting.selectedIncomingRingtone | get_property('file')" />
                <p class="dialer-box-text">
                  <span>Choose your preferred ringtone here, you'll hear a preview when you've selected one</span>
                </p>
                <div class="dialer-input-field input-consistent-inner-shadows whiteBg-select w-75 simple-image">
                  <div class="dialer-dropdown dialer-soundSetting-device-dropdown fullWidth">
                    <b-dropdown id="sound-setting-micro-devices" text="Select Device">
                      <template #button-content>
                        <span class="d-flex align-items-center p-0">
                          <span> {{ media_devices_setting.selectedIncomingRingtone | get_property('label','Select Ringtone') }} </span>
                          <b-icon-chevron-down scale="1.1" />
                        </span>
                      </template>
                      <b-dropdown-item v-for="ringtone in media_devices_setting.incomingRingtons" :key="ringtone.id" @click="media_devices_setting.incomingRingtoneId=ringtone.id;">
                        <vb-svg v-if="media_devices_setting.incoming_ringtone==ringtone.id" name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                        <span>{{ ringtone.label }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
                <div class="testButtonsContainer justify-content-end mt-5">
                  <div class="dialer-soundSetting-testing-audio" @click="is_play = is_play==audioTypes.incoming_ringtone ? '' : audioTypes.incoming_ringtone; " >
                    <vb-svg id="toggle-play" :name=" is_play!=audioTypes.incoming_ringtone ? 'dialer-outlinePlay-icon' : 'dialer-pause-arrowhead' " width="16" stroke="" height="18.035" viewBox="0 0 16 18.035" fill="none" stroke-width="0" stroke-linecap="round" stroke-linejoin="round" ></vb-svg>
                    <span class="dialer-soundSetting-testing-audio-text"> Test audio </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="false" web-dashboard-media-setting-alert-ringtone class="dialer-box alt">
            <div class="dialer-soundSetting-device dialer-volumeSetting-device">
              <div class="dialer-soundSetting-device-inner">
                <span class="dialer-soundSetting-device-name dialer-box-header"> Alert Ringtone </span>
                <audio :show="false" id="alert-ringtone-testing-audio" loop :src="media_devices_setting.selectedAlertRingtone | get_property('file')" />
                <p class="dialer-box-text">
                  <span>Choose your preferred ringtone here, you'll hear a preview when you've selected one</span>
                </p>
                <div class="dialer-input-field input-consistent-inner-shadows whiteBg-select w-75 simple-image">
                  <div class="dialer-dropdown dialer-soundSetting-device-dropdown fullWidth">
                    <b-dropdown id="sound-setting-micro-devices" text="Select Device">
                      <template #button-content>
                        <span class="d-flex align-items-center p-0">
                          <span> {{ media_devices_setting.selectedAlertRingtone | get_property('label','Select Ringtone') }} </span>
                          <b-icon-chevron-down scale="1.1" />
                        </span>
                      </template>
                      <b-dropdown-item v-for="ringtone in media_devices_setting.alertRingtons" :key="ringtone.id" @click="media_devices_setting.alertRingtoneId=ringtone.id;">
                        <vb-svg v-if="media_devices_setting.alert_ringtone==ringtone.id" name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                        <span>{{ ringtone.label }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
                <div class="testButtonsContainer justify-content-end mt-5">
                  <div class="dialer-soundSetting-testing-audio" @click="is_play = is_play==audioTypes.alert_ringtone ? '' : audioTypes.alert_ringtone; " >
                    <vb-svg id="toggle-play" :name=" is_play!=audioTypes.alert_ringtone ? 'dialer-outlinePlay-icon' : 'dialer-pause-arrowhead' " width="16" stroke="" height="18.035" viewBox="0 0 16 18.035" fill="none" stroke-width="0" stroke-linecap="round" stroke-linejoin="round" ></vb-svg>
                    <span class="dialer-soundSetting-testing-audio-text"> Test audio </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="false" web-dashboard-media-setting-send-message-ringtone class="dialer-box alt">
            <div class="dialer-soundSetting-device dialer-volumeSetting-device">
              <div class="dialer-soundSetting-device-inner">
                <span class="dialer-soundSetting-device-name dialer-box-header"> Send Message Ringtone </span>
                <audio :show="false" id="send-message-ringtone-testing-audio" loop :src="media_devices_setting.selectedSendmessageRingtone | get_property('file')" />
                <p class="dialer-box-text">
                  <span>Choose your preferred ringtone here, you'll hear a preview when you've selected one</span>
                </p>
                <div class="dialer-input-field input-consistent-inner-shadows whiteBg-select w-75 simple-image">
                  <div class="dialer-dropdown dialer-soundSetting-device-dropdown fullWidth">
                    <b-dropdown id="sound-setting-micro-devices" text="Select Device">
                      <template #button-content>
                        <span class="d-flex align-items-center p-0">
                          <span> {{ media_devices_setting.selectedSendmessageRingtone | get_property('label','Select Ringtone') }} </span>
                          <b-icon-chevron-down scale="1.1" />
                        </span>
                      </template>
                      <b-dropdown-item v-for="ringtone in media_devices_setting.sendMessageRingtons" :key="ringtone.id" @click="media_devices_setting.sendMessageRingtoneId=ringtone.id;">
                        <vb-svg v-if="media_devices_setting.send_message_ringtone==ringtone.id" name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                        <span>{{ ringtone.label }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
                <div class="testButtonsContainer justify-content-end mt-5">
                  <div class="dialer-soundSetting-testing-audio" @click="is_play = is_play==audioTypes.send_message_ringtone ? '' : audioTypes.send_message_ringtone; " >
                    <vb-svg id="toggle-play" :name=" is_play!=audioTypes.send_message_ringtone ? 'dialer-outlinePlay-icon' : 'dialer-pause-arrowhead' " width="16" stroke="" height="18.035" viewBox="0 0 16 18.035" fill="none" stroke-width="0" stroke-linecap="round" stroke-linejoin="round" ></vb-svg>
                    <span class="dialer-soundSetting-testing-audio-text"> Test audio </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="false" web-dashboard-media-setting-recieve-message-ringtone class="dialer-box alt">
            <div class="dialer-soundSetting-device dialer-volumeSetting-device">
              <div class="dialer-soundSetting-device-inner">
                <span class="dialer-soundSetting-device-name dialer-box-header"> Recieve Message Ringtone </span>
                <audio :show="false" id="recieve-message-ringtone-testing-audio" loop :src="media_devices_setting.selectedRecievemessageRingtone | get_property('file')" />
                <p class="dialer-box-text">
                  <span>Choose your preferred ringtone here, you'll hear a preview when you've selected one</span>
                </p>
                <div class="dialer-input-field input-consistent-inner-shadows whiteBg-select w-75 simple-image">
                  <div class="dialer-dropdown dialer-soundSetting-device-dropdown fullWidth">
                    <b-dropdown id="sound-setting-micro-devices" text="Select Device">
                      <template #button-content>
                        <span class="d-flex align-items-center p-0">
                          <span> {{ media_devices_setting.selectedRecievemessageRingtone | get_property('label','Select Ringtone') }} </span>
                          <b-icon-chevron-down scale="1.1" />
                        </span>
                      </template>
                      <b-dropdown-item v-for="ringtone in media_devices_setting.recieveMessageRingtons" :key="ringtone.id" @click="media_devices_setting.recieveMessageRingtoneId=ringtone.id;">
                        <vb-svg v-if="media_devices_setting.recieve_message_ringtone==ringtone.id" name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                        <span>{{ ringtone.label }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
                <div class="testButtonsContainer justify-content-end mt-5">
                  <div class="dialer-soundSetting-testing-audio" @click="is_play = is_play==audioTypes.recieve_message_ringtone ? '' : audioTypes.recieve_message_ringtone; " >
                    <vb-svg id="toggle-play" :name=" is_play!=audioTypes.recieve_message_ringtone ? 'dialer-outlinePlay-icon' : 'dialer-pause-arrowhead' " width="16" stroke="" height="18.035" viewBox="0 0 16 18.035" fill="none" stroke-width="0" stroke-linecap="round" stroke-linejoin="round" ></vb-svg>
                    <span class="dialer-soundSetting-testing-audio-text"> Test audio </span>
                  </div>
                </div>
              </div>
            </div>
          </div>-->
        </div>
      </div>
    </modal>
    <modal class="modalForDropdown" width="600px" height="auto" :scrollable="true" :name="`${_uid}-IncommingRingtone`">
      <section class="ct-user-new dialer-edit">
        <div class="dialer-edit-header d-flex w-100 align-items-start justify-content-between pt-0">
          <div>
            <h2 class="dialer-settings-title newer">Incoming ringtone</h2>
            <div class="dialer-settings-intro newerTextVersion w-75 mb-0">
              Choose your preferred ringtone here, you'll hear a preview when you've selected one
            </div>
          </div>
          <div class="dialer-edit-actions">
            <button class="newDoneButton" @click="$modal.hide(`${_uid}-IncommingRingtone`)">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
        </div>
        <div class="latestGreyBox-9-9-2023 hasScrollBarInsideIt mt-20px" style="--total: 90vh; --minusValue: 75px;">
          <div class="whiteBoxWithBorderInsideGreyBox">
            <div class="whiteBoxWithBorderInsideGreyBox-row min-height-none" v-for="ringtone in media_devices_setting.incomingRingtons" :key="ringtone.id" @click="media_devices_setting.incomingRingtoneId=ringtone.id;" >
              <div class="d-flex align-items-center">
                <div :class="`actLikeSquareRadio mr-20px ${media_devices_setting.incoming_ringtone==ringtone.id ? 'checked' : '' }`"></div>
                <div class="latestGreyBox-heading-main">{{ ringtone.label }}</div>
              </div>
              <vb-audio-player :design="'minimum'" :src="ringtone.file" />
            </div>
          </div>
        </div>
      </section>
    </modal>
    <modal class="modalForDropdown" width="600px" height="auto" :scrollable="true" :name="`${_uid}-AlertRingtone`">
      <section class="ct-user-new dialer-edit">
        <div class="dialer-edit-header d-flex w-100 align-items-start justify-content-between pt-0">
          <div>
            <h2 class="dialer-settings-title newer">Alert ringtone</h2>
            <div class="dialer-settings-intro newerTextVersion w-75 mb-0">
              Choose your preferred ringtone here, you'll hear a preview when you've selected one
            </div>
          </div>
          <div class="dialer-edit-actions">
            <button class="newDoneButton" @click="$modal.hide(`${_uid}-AlertRingtone`)">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
        </div>
        <div class="latestGreyBox-9-9-2023 hasScrollBarInsideIt mt-20px" style="--total: 90vh; --minusValue: 75px;">
          <div class="whiteBoxWithBorderInsideGreyBox">
            <div class="whiteBoxWithBorderInsideGreyBox-row min-height-none" v-for="ringtone in media_devices_setting.alertRingtons" :key="ringtone.id" @click="media_devices_setting.alertRingtoneId=ringtone.id;" >
              <div class="d-flex align-items-center">
                <div  :class="`actLikeSquareRadio mr-20px ${media_devices_setting.alert_ringtone==ringtone.id ? 'checked' : '' }`"></div>
                <div class="latestGreyBox-heading-main">{{ ringtone.label }}</div>
              </div>
              <vb-audio-player :design="'minimum'" :src="ringtone.file" />
            </div>
          </div>
        </div>
      </section>
    </modal>
    <modal class="modalForDropdown" width="600px" height="auto" :scrollable="true" :name="`${_uid}-SendMessageRingtone`">
      <section class="ct-user-new dialer-edit">
        <div class="dialer-edit-header d-flex w-100 align-items-start justify-content-between pt-0">
          <div>
            <h2 class="dialer-settings-title newer">Send message ringtone</h2>
            <div class="dialer-settings-intro newerTextVersion w-75 mb-0">
              Choose your preferred ringtone here, you'll hear a preview when you've selected one
            </div>
          </div>
          <div class="dialer-edit-actions">
            <button class="newDoneButton" @click="$modal.hide(`${_uid}-SendMessageRingtone`)">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
        </div>
        <div class="latestGreyBox-9-9-2023 hasScrollBarInsideIt mt-20px" style="--total: 90vh; --minusValue: 75px;">
          <div class="whiteBoxWithBorderInsideGreyBox">
            <div class="whiteBoxWithBorderInsideGreyBox-row min-height-none" v-for="ringtone in media_devices_setting.sendMessageRingtons" :key="ringtone.id" @click="media_devices_setting.sendMessageRingtoneId=ringtone.id;" >
              <div class="d-flex align-items-center">
                <div  :class="`actLikeSquareRadio mr-20px ${media_devices_setting.send_message_ringtone==ringtone.id ? 'checked' : '' }`"></div>
                <div class="latestGreyBox-heading-main">{{ ringtone.label }}</div>
              </div>
              <vb-audio-player :design="'minimum'" :src="ringtone.file" />
            </div>
          </div>
        </div>
      </section>
    </modal>
    <modal class="modalForDropdown" width="600px" height="auto" :scrollable="true" :name="`${_uid}-RecieveMessageRingtone`">
      <section class="ct-user-new dialer-edit">
        <div class="dialer-edit-header d-flex w-100 align-items-start justify-content-between pt-0">
          <div>
            <h2 class="dialer-settings-title newer">Receive message ringtone</h2>
            <div class="dialer-settings-intro newerTextVersion w-75 mb-0">
              Choose your preferred ringtone here, you'll hear a preview when you've selected one
            </div>
          </div>
          <div class="dialer-edit-actions">
            <button class="newDoneButton" @click="$modal.hide(`${_uid}-RecieveMessageRingtone`)">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
        </div>
        <div class="latestGreyBox-9-9-2023 hasScrollBarInsideIt mt-20px" style="--total: 90vh; --minusValue: 75px;">
          <div class="whiteBoxWithBorderInsideGreyBox">
            <div class="whiteBoxWithBorderInsideGreyBox-row min-height-none" v-for="ringtone in media_devices_setting.recieveMessageRingtons" :key="ringtone.id" @click="media_devices_setting.recieveMessageRingtoneId=ringtone.id;" >
              <div class="d-flex align-items-center">
                <div  :class="`actLikeSquareRadio mr-20px ${media_devices_setting.recieve_message_ringtone==ringtone.id ? 'checked' : '' }`"></div>
                <div class="latestGreyBox-heading-main">{{ ringtone.label }}</div>
              </div>
              <vb-audio-player :design="'minimum'" :src="ringtone.file" />
            </div>
          </div>
        </div>
      </section>
    </modal>
  </div>
</template>

<script>
import MicrophoneTester from "../Common/TestingMedias/Microphone.vue";
import CameraTester from "../Common/TestingMedias/Camera.vue";
import { mapState } from 'vuex';
import { events } from '../../utils';
let audios_types = {
  speaker: 'speaker',
  incoming_ringtone: 'incoming_ringtone',
  alert_ringtone: 'alert_ringtone',
  send_message_ringtone: 'send_message_ringtone',
  recieve_message_ringtone: 'recieve_message_ringtone',
}
export default {
  name: "AudioSettingModal",
  inject:['isEmpty'],
  props: {
    modalName: {
      type: String,
      default: 'AudioSettingModal',
    },
  },
  components: {
    MicrophoneTester,
    CameraTester,
  },
  data() {
    return {
      is_play: '',
    };
  },
  computed: {
    ...mapState({
      media_devices_setting: state=>state.media_devices_setting,
      sip_phone: state=>state.sip.phone
    }),
    audioTypes(){return audios_types},
    testCallSession() {
      const active_session = this.sip_phone?.activeSession
      return active_session?.number=="*154*" ? active_session : {}
    },
  },
  watch: {
    "media_devices_setting.speaker_volume": "mediaDeviceSettingSpeakerVolume",
    "media_devices_setting.speaker_id": "mediaDeviceSettingSpeakerId",
    "media_devices_setting.ringing_speaker_volume": "mediaDeviceSettingRingingSpeakerVolume",
    "media_devices_setting.ringing_speaker_id": "mediaDeviceSettingRingingSpeakerId",
    "media_devices_setting.send_message_ringtone": "mediaDeviceSettingSendMessageRingtone",
    "media_devices_setting.recieve_message_ringtone": "mediaDeviceSettingRecieveMessageRingtone",
    "media_devices_setting.alert_ringtone": "mediaDeviceSettingAlertRingtone",
    "media_devices_setting.incoming_ringtone": "mediaDeviceSettingIncomingRingtone",
    is_play(is_play){
      const audio = document.getElementById("testing-audio");
      const audio1 = document.getElementById("alert-ringtone-testing-audio");
      const audio2 = document.getElementById("send-message-ringtone-testing-audio");
      const audio3 = document.getElementById("recieve-message-ringtone-testing-audio");
      const audio4 = document.getElementById("incoming-ringtone-testing-audio");
      is_play==audios_types.speaker ? audio?.play?.() : audio?.pause?.()
      is_play==audios_types.alert_ringtone ? audio1?.play?.() : audio1?.pause?.()
      is_play==audios_types.send_message_ringtone ? audio2?.play?.() : audio2?.pause?.()
      is_play==audios_types.recieve_message_ringtone ? audio3?.play?.() : audio3?.pause?.()
      is_play==audios_types.incoming_ringtone ? audio4?.play?.() : audio4?.pause?.()
    },
  },
  methods: {
    onBeforeClose(){
      this.is_play=''
    },
    mediaDeviceSettingSpeakerId(speaker_id){
      const audio = document.getElementById("testing-audio");
      if(audio) audio.setSinkId(speaker_id);
    },
    mediaDeviceSettingSpeakerVolume(speaker_volume){
      const audio = document.getElementById("testing-audio");
      if(audio) audio.volume = speaker_volume / 100;
    },
    mediaDeviceSettingRingingSpeakerId(ringing_speaker_id){
      const audio = document.getElementById("send-message-ringtone-testing-audio");
      if(audio) audio.setSinkId(ringing_speaker_id);
      const audio1 = document.getElementById("recieve-message-ringtone-testing-audio");
      if(audio1) audio1.setSinkId(ringing_speaker_id);
      const audio2 = document.getElementById("alert-ringtone-testing-audio");
      if(audio2) audio2.setSinkId(ringing_speaker_id);
      const audio3 = document.getElementById("incoming-ringtone-testing-audio");
      if(audio3) audio3.setSinkId(ringing_speaker_id);
    },
    mediaDeviceSettingRingingSpeakerVolume(ringing_speaker_volume){
      const audio = document.getElementById("send-message-ringtone-testing-audio");
      if(audio) audio.volume = ringing_speaker_volume / 100;
      const audio1 = document.getElementById("recieve-message-ringtone-testing-audio");
      if(audio1) audio1.volume = ringing_speaker_volume / 100;
      const audio2 = document.getElementById("alert-ringtone-testing-audio");
      if(audio2) audio2.volume = ringing_speaker_volume / 100;
      const audio3 = document.getElementById("incoming-ringtone-testing-audio");
      if(audio3) audio3.volume = ringing_speaker_volume / 100;
    },
    mediaDeviceSettingSendMessageRingtone(){
      let vm = this
      const audio = document.getElementById("send-message-ringtone-testing-audio");
      if(audio){
        audio.load();
        audio.onloadedmetadata = () => {
          if(vm.is_play==audios_types.send_message_ringtone){
            audio.play()
          }
        }
      }
    },
    mediaDeviceSettingRecieveMessageRingtone(){
      let vm = this
      const audio = document.getElementById("recieve-message-ringtone-testing-audio");
      if(audio){
        audio.load();
        audio.onloadedmetadata = () => {
          if(vm.is_play==audios_types.recieve_message_ringtone){
            audio.play()
          }
        }
      }
    },
    mediaDeviceSettingAlertRingtone(){
      let vm = this
      const audio = document.getElementById("alert-ringtone-testing-audio");
      if(audio){
        audio.load();
        audio.onloadedmetadata = () => {
          if(vm.is_play==audios_types.alert_ringtone){
            audio.play()
          }
        }
      }
    },
    mediaDeviceSettingIncomingRingtone(){
      let vm = this
      const audio = document.getElementById("incoming-ringtone-testing-audio");
      if(audio){
        audio.load();
        audio.onloadedmetadata = () => {
          if(vm.is_play==audios_types.incoming_ringtone){
            audio.play()
          }
        }
      }
    },
    mediaDevicesSettingSink(){
      if(this.media_devices_setting?.speaker_id !== undefined){ this.mediaDeviceSettingSpeakerId(this.media_devices_setting.speaker_id) }
      if(this.media_devices_setting?.ringing_speaker_id !== undefined){ this.mediaDeviceSettingRingingSpeakerId(this.media_devices_setting.ringing_speaker_id) }
      if(this.media_devices_setting?.speaker_volume !== undefined){ this.mediaDeviceSettingSpeakerVolume(this.media_devices_setting.speaker_volume) }
      if(this.media_devices_setting?.ringing_speaker_volume !== undefined){ this.mediaDeviceSettingRingingSpeakerVolume(this.media_devices_setting.ringing_speaker_volume) }
    },
    testCall() {
      this.$root.$emit(events.audio_call,{
        call_type: 'test',
      })
    },
  },
  mounted(){
    this.mediaDevicesSettingSink()
  },
};
</script>