<template>
  <div class="dialer-box alt position-relative p-0">
    <div class="d-flex justify-content-between">
      <div class="dialer-call-options v3 w-100">
        <div class="selectedContainer">
          <template v-if="forwarding_setting.forward_note == 'voicemail'">
            <div class="selectedText-big">Ask to leave a voicemail</div>
            <div class="selectedText-small">
              If phone rings for 
              <span @click="duration()" class="color-primary text-decoration-underline">{{forwarding_setting.timeout}} Seconds</span> 
              call will be forward to voicemail
            </div>
          </template>
          <template v-else-if="forwarding_setting.forward_note == 'extension'">
            <div class="selectedText-big">Redirect to a user, team or IVR menu</div>
            <div class="selectedText-small">
              If phone rings for 
              <span @click="duration()" class="color-primary cursor_pointer text-decoration-underline">{{forwarding_setting.timeout}} Seconds</span> 
              call will be forward to 
              <span @click="extension()" class="color-primary cursor_pointer text-decoration-underline">{{forwarding_setting.extension_name}}</span>
            </div>
          </template>
          <template v-else-if="forwarding_setting.forward_note == 'number'">
            <div class="selectedText-big">Redirect to another number</div>
            <div class="selectedText-small">
              If phone rings for 
              <span @click="duration()" class="color-primary text-decoration-underline">{{forwarding_setting.timeout}} Seconds</span> 
              call will be forward to 
              <span @click="number()" class="color-primary text-decoration-underline">{{forwarding_setting.forward_number}}</span>
            </div>
          </template>
          <template v-else>
            <div class="selectedText-big">Keep ringing</div>
            <div class="selectedText-small">Call forwarding is off</div>
          </template>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <b-icon icon="pencil-fill" variant="primary" class="cursor_pointer mt-2" @click="edit()" />
    </div>
  </div>
</template>

<script>
export default {
  name: "CallForwardingSetting",
  props: {
    voipaccount: {
      type: String,
      default: "",
    },
    forwarding_setting: {
      type: Object,
      default: ()=>({})
    },
  },
  methods: {
    edit(){
      this.$emit('interface',{
        from: 'call_forwarding_setting',
        event: 'edit_call_forwarding',
      })
    },
    duration(){
      this.$emit('interface',{
        from: 'call_forwarding_setting',
        event: 'edit_call_forwarding_duration',
      })
    },
    number(){
      this.$emit('interface',{
        from: 'call_forwarding_setting',
        event: 'edit_call_forwarding_number',
      })
    },
    extension(){
      this.$emit('interface',{
        from: 'call_forwarding_setting',
        event: 'edit_call_forwarding_extension',
      })
    },
  },
};
</script>
