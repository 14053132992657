var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('vb-icon', {
    attrs: {
      "icon": "teamsInsideGraph-icon",
      "height": "50px",
      "width": "50px"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }