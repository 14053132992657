<template>
  <div>
    <vb-icon icon="teamsInsideGraph-icon" height="50px" width="50px"/>
  </div>
</template>

<script>
export default {
  name: 'TeamMemberIcon'
}
</script>

<style>

</style>