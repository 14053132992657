var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrapper",
    staticStyle: {
      "width": "600px",
      "display": "flex"
    }
  }, [_c('div', {
    staticClass: "div1",
    staticStyle: {
      "width": "30%"
    }
  }), _c('div', {
    staticClass: "div2",
    staticStyle: {
      "width": "40%"
    }
  }, [_vm._t("active-hours"), _c('div', {
    staticClass: "vl3 yesTextInBefore",
    staticStyle: {
      "height": "66%",
      "border-left": "2.4px solid #c5ccd0",
      "position": "relative",
      "left": "51%",
      "margin-left": "-3px"
    }
  })], 2), _c('div', {
    staticClass: "div3",
    staticStyle: {
      "width": "30%"
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "div3Inner",
    staticStyle: {
      "height": "24%",
      "padding": "30px",
      "background": "rgba(224, 211, 211, 0.48)",
      "border-radius": "9px",
      "margin-top": "27%"
    }
  }, [_vm._t("default")], 2)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "height": "20%"
    }
  }, [_c('div', {
    staticStyle: {
      "position": "inherit",
      "height": "35%"
    }
  }, [_c('p', {
    staticClass: "noText",
    staticStyle: {
      "margin-left": "30px"
    }
  }, [_vm._v("No")])]), _c('hr', {
    staticStyle: {
      "width": "50%",
      "height": "0.5px",
      "margin-left": "0px",
      "top": "8%",
      "position": "relative",
      "background": "#c5ccd0"
    }
  }), _c('div', [_c('div', {
    staticClass: "vl2",
    staticStyle: {
      "height": "25%",
      "border-left": "2px solid rgb(197, 204, 208)",
      "position": "absolute",
      "margin-left": "89px",
      "top": "45px"
    }
  })])]);

}]

export { render, staticRenderFns }