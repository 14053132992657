<template>
<div>
  <modal
    class="dialer_animation right_side_popup tagStatus-popup"
    width="50%"
    height="auto"
    :scrollable="true"
    name="StatusesModal"
    :clickToClose="false"
    @closed="onClosed"
  >
    <div class="dialer-assign-to md-pad-bottom dialer-edit">
      <div class="dialer-edit-header d-block">
        <div class="d-flex justify-content-between aling-items-center w-100">
          <h2 class="dialer-settings-title newer mb-0">
            <vb-svg @click="updated_id ? updated_id = '' : $modal.hide('StatusesModal')" class="cursor_pointer_no_hover" name="leftLongArrow-icon" width="20" height="12" viewBox="0 0 30 16" stroke-width="1" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" />
            Status
          </h2>
          <div class="dialer-edit-actions">
            <!-- <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide('StatusesModal')" />
            <a v-else
              class="newCloseButton"
              type="button"
              @click="$modal.hide('StatusesModal')"
            >
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </a> -->
            <div 
              v-b-tooltip :title="getUserPermissions.unlimited_custom_status?'':'Your package does not support this feature'"
              class="w-fit-content"
              :class="{
                'for-disable' : !getUserPermissions.unlimited_custom_status
              }"
            >
              <button v-if="!updated_id"
                v-b-tooltip :title="getUserPermissions.unlimited_custom_status?'':'Your package does not support this feature'"
                :disabled="!getUserPermissions.unlimited_custom_status" 
                @click="$modal.show('AddStatus')" 
                class="newButton" 
                variant="success" 
              >
                <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                <span class="newButton-textPart">Add status</span>
              </button>
            </div>
          </div>
        </div>
        <div v-if="updated_id" class="vm--modal-text">
          Edit status name and details.
        </div>
        <div v-else class="vm--modal-text">
          Customise your status to convey your availability or current situation beyond standard options like available, away, or busy.
        </div>
      </div>
      <div class="dialer-tabs">
        <template v-if="updated_id">
          <!-- <span class="dialer-link-more" @click="updated_id = ''">
            <vb-svg name="leftLongArrow-icon" width="20" height="12" viewBox="0 0 30 16" stroke-width="1" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" />
            <span class="mb-0 ml-2 color-black">Back</span>
          </span> -->
          <div class="latestGreyBox-9-9-2023 mt-12px">
            <div class="latestGreyBox-heading-main">Update status</div>
            <b-form @submit.prevent="update" class="dialer-form">
              <!-- <div class="whiteBoxWithBorderInsideGreyBox mt-20px"> -->
                <!-- <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"> -->
                  <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mt-32px">
                    <label>Status name</label>
                    <b-input class="w-100" type="text" v-model="forms.update.text" :disabled="api_sent.update" placeholder="Enter name" />
                    <p v-if="(is_submitted.update && $v.forms.update.text.$invalid) || errors.update.text" class="mb-1 text-small text text-danger animated bounceIntop">
                      <span v-if="!$v.forms.update.text.required">* Name is required</span>
                      <span v-for="em in errors.update.text" :key="em">* {{ em }}</span>
                    </p>
                    <template v-if="is_submitted.update">
                      <p v-if="!$v.forms.update.text.required" class="mb-1 text-small text text-danger animated bounceIntop">
                        <span>* Name is required</span>
                      </p>
                    </template>
                  </div>
                <!-- </div> -->
                <!-- <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                  <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100">
                    <label>Select color combination</label>
                    <vb-select
                      :defaultSelectedText="'Select color combination'"
                      class="colorCombinationDD"
                      :disabled="api_sent.update || api.fetch_color_combination.send"
                      :selected="forms.update.color_combo_id"
                      @change="forms.update.color_combo_id = $event;"
                      :options="colorComboOptions"
                    />
                    <p v-if="is_submitted.update && $v.forms.update.color_combo_id.$invalid" class="mb-1 text-small text text-danger animated bounceIntop">
                      <span v-if="!$v.forms.update.color_combo_id.required">* Select color is required</span>
                    </p>
                  </div>
                </div> -->
                <div class="whiteBGinputWithGreyRoundedBorder mt-20px">
                  <label>Status color</label>
                  <div class="createSpace-selectColor-container">
                    <template v-if="api_sent.update || api.fetch_color_combination.send">
                      <div v-for="n in 32" :key="n.id" class="createSpace-selectColor-item-container">
                        <div class="latestShimmerDesign" style="height: 30px;width: 30px;"></div>
                      </div>
                    </template>
                    <template v-else>
                      <div v-for="color in colorComboOptions" :key="color.id" class="createSpace-selectColor-item-container">
                        <div 
                          @click="api_sent.update ? '' : forms.update.color_combo_id = color.value;" 
                          class="createSpace-selectColor-item"
                          :class="{
                            'selected': forms.update.color_combo_id==color.value,
                            'TransparentSelected': color.color == 'rgba(0,0,0,0)',
                          }"
                          :style="`background-color:${color.color};`">
                          <vb-icon v-if="color.color == 'rgba(0,0,0,0)'" icon="todo-transparentColored-icon" height="28px" width="30px" />
                        </div>
                      </div>
                    </template>
                  </div>
                  <p v-if="is_submitted.update && $v.forms.update.color_combo_id.$invalid" class="mb-1 text-small text text-danger animated bounceIntop">
                    <span v-if="!$v.forms.update.color_combo_id.required">* Color is required</span>
                  </p>
                </div>
              <!-- </div> -->
              <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
                <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset">
                  <div class="latestGreyBox-heading-main">Allow incoming calls</div>
                  <b-form-checkbox class="newerSwitch" :disabled="api_sent.update" :checked="forms.update.allow_call" @change="forms.update.allow_call=!forms.update.allow_call" switch />
                </div>
              </div>
              <div class="w-100 d-flex justify-content-end mt-4">
                <button class="newDoneButton" variant="success" :disabled="api_sent.update">
                  <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                  <span class="newDoneButton-textPart position-relative d-flex justify-content-center">
                    <vb-spinner v-if="api_sent.update" />
                    <template v-else>Update</template>
                  </span>
                </button>
              </div>
            </b-form>
          </div>

          <!--<div class="dialer-box grayBox inEditMode" v-if="updated_id">
            <div class="dialer-tab-content">
              <h2 class="dialer-edit-title d-flex flex-column">
                <span class="dialer-link-more" @click="updated_id = ''">
                  <vb-svg name="leftLongArrow-icon" width="20" height="12" viewBox="0 0 30 16" stroke-width="1" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                  <span class="mb-0 ml-2 color-black">Back</span>
                </span>
                <span class="mt-4 line-height-1">Update Status</span>
              </h2>
              <b-form @submit.prevent="update" class="dialer-form">
                <div class="dialer-input-group-2 align-items-start">
                  <div class="dialer-input-field input-consistent-inner-shadows ">
                    <label>Status Name</label>
                    <b-input class="bg-white" type="text" v-model="forms.update.text" :disabled="api_sent.update" placeholder="Enter name" />
                    <p v-if="(is_submitted.update && $v.forms.update.text.$invalid) || errors.update.text" class="mb-1 text-small text text-danger animated bounceIntop">
                      <span v-if="!$v.forms.update.text.required">* Name is required</span>
                      <span v-for="em in errors.update.text" :key="em">* {{ em }}</span>
                    </p>
                    <template v-if="is_submitted.update">
                      <p v-if="!$v.forms.update.text.required" class="mb-1 text-small text text-danger animated bounceIntop">
                        <span>* Name is required</span>
                      </p>
                    </template>
                  </div>
                  <div class="dialer-input-field input-consistent-inner-shadows w-100 mt-3">
                    <label>Select Color Combination</label>
                    <vb-select
                      :defaultSelectedText="'Select Color Combination'"
                      class="colorCombinationDD"
                      :disabled="api_sent.update || api.fetch_color_combination.send"
                      :selected="forms.update.color_combo_id"
                      @change="forms.update.color_combo_id = $event;"
                      :options="colorComboOptions"
                    />
                    
                    <p v-if="is_submitted.update && $v.forms.update.color_combo_id.$invalid" class="mb-1 text-small text text-danger animated bounceIntop">
                      <span v-if="!$v.forms.update.color_combo_id.required">* Select Color is required</span>
                    </p>
                  </div>
                  <!~~ <vb-color-picker :value="forms.update.color" @change="forms.update.color = $event" class="dialer-input-field input-consistent-inner-shadows color-picker-input">
                    <p slot="error" v-if="(is_submitted.update && $v.forms.update.color.$invalid) || errors.update.color || errors.update.colour" class="mb-1 text-small text text-danger animated bounceIntop">
                      <span v-if="!$v.forms.update.color.required">* Color is required</span>
                      <span v-else-if="!$v.forms.update.color.validColor">* Color is invalid</span>
                      <span v-for="em in errors.update.color" :key="em">* {{ em }}</span>
                      <span v-for="em in errors.update.colour" :key="em">* {{ em }}</span>
                    </p>
                  </vb-color-picker> ~~>
                </div>
                <div class="d-flex justify-content-between md-mar-top">
                  <div class="allowCall">
                    <b-form-checkbox class="dialer-switch" v-model="forms.update.allow_call" switch >Allow Call</b-form-checkbox>
                  </div>
                  <button class="dialer-button dialer-button-primary" variant="success" :disabled="api_sent.update">
                    <vb-spinner v-if="api_sent.update" />
                    <template v-else>update</template>
                  </button>
                </div>
              </b-form>
            </div>
          </div>-->
        </template>
        <template v-else>
          <vb-table class="latestTableDesign-withBlackBorders-again showLastTDonHover"
            :isListEmpty="isEmpty($store.state.common.statuses)"
            :listLength="$store.state.common.statuses.length"
            :perPage="5"
            :conditions="{
              loading: {
                show: false,
              },
            }"
          >
            <tr slot="header">
              <th class="dialer-has-sort">
                <span>Name</span>
              </th>
              <th class="dialer-has-sort dialer-col-center">
                <span>Color</span>
              </th>
              <!-- <th class="dialer-has-sort dialer-col-center">
                <span>Active</span>
              </th> -->
              <th class="dialer-has-sort dialer-col-center">
                <span>Allow call</span>
              </th>
              <th class="dialer-has-sort dialer-col-right">
                <!-- <span>Action</span> -->
              </th>
            </tr>
            <template #body="{ start, end }">
              <tr
                v-for="(status, index) in $store.state.common.statuses"
                :key="status.id"
                v-show="index >= start && index <= end"
              >
                <td class="dialer-row-title wd-768-50">
                  <span class="d-r-768px-show mr-2">Name :</span>
                  {{ status.status }}
                  <!-- <b-icon
                    v-if="status.id == $store.getters.getCurrentUser.status_id"
                    icon="check2-circle"
                    scale="1.3"
                    variant="primary"
                  ></b-icon> -->
                  <b-icon 
                    v-if="status.id == $store.getters.getCurrentUser.status_id"
                    icon="check-circle-fill" 
                    font-scale="1.3" 
                    class="ml-3" 
                    variant="success" 
                  />
                  <span v-if="status.accountcode" class="OwnertaggedVersion mb-0 ml-16px">Custom</span>
                </td>
                <td class="dialer-has-sort dialer-col-center wd-768-50">
                  <span class="d-r-768px-show mr-2">Color :</span>
                  <div class="dialer-userStatusArea">
                    <vb-status
                      :statusId="status.id"
                      style="height: 20px; width: 20px; border-radius: 50%"
                    />
                  </div>
                </td>
                <!-- <td class="dialer-has-sort dialer-col-center wd-768-50">
                  <span class="d-r-768px-show mr-2">Active :</span>
                
                  <b-icon
                    v-if="status.id == $store.getters.getCurrentUser.status_id"
                    icon="check2-circle"
                    scale="1.3"
                    variant="primary"
                  ></b-icon>
                </td> -->
                <td class="dialer-has-sort dialer-col-center wd-768-50">
                  <span class="d-r-768px-show mr-2">Allow call :</span>
                  
                  <!-- <b-icon
                    v-if="api_sent.allow_call === status.id"
                    icon="circle-fill"
                    animation="throb"
                    variant="primary"
                    scale="1.3"
                  /> -->
                  <div class="d-flex justify-content-center w-100">
                    <div v-if="api_sent.allow_call === status.id" class="latestShimmerDesign" style="height:34px;width:60px;border-radius: 25px"></div>
                    <b-checkbox 
                      v-else
                      class="m-0 newerSwitch"
                      v-b-tooltip :title="getUserPermissions.status?'':'Your package does not support this feature'"
                      :disabled="
                        !getUserPermissions.status || 
                        api_sent.allow_call === status.id ||
                        api_sent.remove === status.id ||
                        api_sent.set === status.id ||
                        !status.accountcode
                      "
                      :checked="status.allow_call"
                      @change="
                        status.accountcode
                          ? allowCall(status.id, $event, index)
                          : ''
                      "
                      switch
                    />
                  </div>
                  <!-- <toggle-button
                    v-else
                    :value="status.allow_call"
                    :aria-disabled="
                      api_sent.allow_call === status.id ||
                      api_sent.remove === status.id ||
                      api_sent.set === status.id ||
                      !status.accountcode
                    "
                    @change="
                      status.accountcode
                        ? allowCall(status.id, $event, index)
                        : ''
                    "
                    sync
                    :height="20"
                    :width="40"
                    class="m-0"
                  /> -->
                </td>
                <td class="dialer-has-sort dialer-col-right wd-768-50">
                  <span class="d-r-768px-show mr-2">Action :</span>
                  <div class="d-flex justify-content-end">
                    <!-- <p v-if="!status.accountcode">Default</p> -->
                    <!-- <div
                      v-else
                      class="
                        dialer-dropdown
                        dialer-dropDown-latest
                        dialer-dropDown-onRight
                      "
                    > -->
                    <div v-if="status.accountcode" class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD">
                      <b-dropdown right>
                        <template #button-content>
                          <b-icon icon="three-dots" scale="1.5"></b-icon>
                        </template>
                        <b-dropdown-item
                          v-b-tooltip :title="getUserPermissions.status?'':'Your package does not support this feature'"
                          :disabled="api_sent.remove === status.id"
                          @click="!getUserPermissions.status ? '' : setUpdateId(status)"
                        >
                          Edit
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="api_sent.remove === status.id"
                        ></b-dropdown-item>
                        <b-dropdown-item
                          v-else
                          v-b-tooltip :title="getUserPermissions.status?'':'Your package does not support this feature'"
                          :disabled="!!api_sent.set || !!api_sent.remove"
                          @click="!getUserPermissions.status ? '' : remove(status)"
                          >Delete</b-dropdown-item
                        >
                      </b-dropdown>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
            <template #loading>
              <tr v-for="n in 5" :key="n">
                <td>
                  <div class="d-flex align-items-center w-fit-content">
                    <div class="latestShimmerDesign" style="height:21px;width:80px;"></div>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center w-fit-content mx-auto">
                    <div class="md-mar-left latestShimmerDesign" style="height:21px;width:21px;"></div>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center w-fit-content mx-auto">
                    <div class="latestShimmerDesign md-mar-left" style="height:21px;width:21px;"></div>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center w-fit-content mx-auto">
                    <div class="latestShimmerDesign md-mar-left" style="height:21px;width:50px;"></div>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center justify-sontent-end w-fit-content ml-auto">
                    <div class="latestShimmerDesign" style="height:16px;width:70px;"></div>
                  </div>
                </td>
              </tr>
            </template>
          </vb-table>
        </template>
      </div>
    </div>
  </modal>
  <AddStatusModal />
</div>
</template>

<script>
import { LOGGER, VOIP_API } from "../../../utils";
import { required } from "vuelidate/lib/validators";
import validateColor from "validate-color";
import { mapActions, mapGetters } from 'vuex';
import AddStatusModal from './AddStatusModal.vue';

export default {
  name: "StatusesModal",
  components: { 
    AddStatusModal
  },
  data() {
    return {
      assets: {
        spinner: require("@/assets/images/spinner.svg"),
      },
      show: {
        update_color: false,
        create_color: false,
      },
      updated_id: "",
      allow_call: { 1: true },
      forms: {
        create: {
          text: "",
          color: "rgba(0,0,0,1)",
          allow_call: true,
          reset() {
            this.text = "";
            this.color = "rgba(0,0,0,1)";
            this.allow_call = true;
          },
        },
        update: {
          text: "",
          // color: "",
          allow_call: false,
          color_combo_id: '',
          reset() {
            this.text = "";
            this.color = "";
            this.allow_call = false;
            this.color_combo_id=''
          },
        },
      },
      api: {
        fetch_color_combination: this.$helperFunction.apiInstance(),
      },
      api_sent: {
        set: "",
        update: false,
        remove: "",
        create: false,
        allow_call: "",
      },
      is_submitted: {
        update: false,
        create: false,
      },
      errors: {
        update: {},
        create: {},
      },
      response: {
        color_combinations: [],
      },
    };
  },
  inject:['isEmpty','appNotify'],
  computed: {
    ...mapGetters([
      'getUserPermissions'
    ]),
    statuses() {
      return this.$store.state.common.statuses;
    },
    colorComboOptions(){
      return this.response.color_combinations.map(i=>({
        text: i.label,
        value: i.id,
        color: i.color
      }))
    },
    selectedColorCombo(){ return this.response.color_combinations.find(i=>i.id==this.forms.update.color_combo_id) },
  },
  validations: {
    forms: {
      create: {
        text: {
          required,
        },
        color: {
          required,
          validColor: (value) => { return validateColor(value) },
        },
      },
      update: {
        text: {
          required,
        },
        color_combo_id: {
          required,
        },
        // color: {
        //   required,
        //   validColor: (value) => { return validateColor(value) },
        // },
      },
    },
  },
  methods: {
    ...mapActions([
      'setStatus',
    ]),
    async colorCombinations(){
      if(this.api.fetch_color_combination.send) return;
      try {
        this.api.fetch_color_combination.send=true
        const { data: color_combinations } = await VOIP_API.endpoints.tags.colorCombinations()
        this.response.color_combinations=color_combinations ?? []
      } finally {
        this.api.fetch_color_combination.send=false
      }
    },
    create() {
      let vm = this;
      this.is_submitted.create = true;
      this.$v.$touch();
      if (this.$v.forms.create.$invalid || this.api_sent.create) return;
      this.api_sent.create = true;
      vm.errors.create={}
      VOIP_API.endpoints.statuses.create({
          status: this.forms.create.text,
          color: this.forms.create.color,
          colour: this.forms.create.color,
          allow_call: this.forms.create.allow_call,
          uid: this.$store.getters.getCurrentUser?.uid,//this.current_user.uid,
        })
        .then(({ data: status }) => {
          vm.$store.state.common.statuses.push({
            ...status,
            uid: null,
            allow_call: vm.forms.create.allow_call,
          });
          vm.forms.create.reset();
          vm.appNotify({
            message: 'Successfully created',
            type: 'success',
          })
        })
        .catch((ex)=>{
          vm.appNotify({
            message: ex.own_message,
            type: 'danger',
          })
          vm.errors.create=ex.own_errors ?? {}
        })
        .finally(() => {
          vm.is_submitted.create = false;
          vm.api_sent.create = false;
        });
    },
    remove(status) {
      const id = status.id
      // const sync_code = status.sync_code
      let vm = this;
      if (!id || vm.api_sent.remove || vm.api_sent.set == id) return;
      this.$appConfirmation({
        title: "Warning",
        message: `Are you sure you want to delete.`,
        variant: 'danger',
        button: {
          no: "no",
          yes: "yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            vm.api_sent.remove = id;
            VOIP_API.endpoints.statuses.delete(id,{
              // flag: 'portal_status_delete'
            })
              .then(() => {
                vm.$store.state.common.statuses =
                vm.$store.state.common.statuses.filter((status) => status.id !== id);
                if (vm.$store.getters.getCurrentUser?.status_id === id) {
                  vm.setStatus();
                }
              })
              .finally(() => {
                vm.api_sent.remove = "";
              });
          }
        },
      });
    },
    update() {
      let vm = this;
      this.is_submitted.update = true;
      this.$v.$touch();
      if (this.$v.forms.update.$invalid || this.api_sent.update) return;
      this.api_sent.update = true;
      vm.errors.update={}
      VOIP_API.endpoints.statuses.update({
        id: this.updated_id,
        status: this.forms.update.text,
        // color: this.forms.update.color,
        // colour: this.forms.create.color,
        color_combination_id: vm.forms.update.color_combo_id,
        color: this.selectedColorCombo.color,
        colour: this.selectedColorCombo.color,
        allow_call: this.forms.update.allow_call,
        flag: 'portal_status_update'
      })
      .then(() => {
        return vm.$store.state.common.statuses.map(
          (status) => {
            if (status.id === vm.updated_id) {
              return {
                ...status,
                status: vm.forms.update.text,
                colour: vm.selectedColorCombo.color,
                allow_call: vm.forms.update.allow_call,
                color_combination_id: vm.forms.update.color_combo_id,
              };
            } else {
              return status;
            }
          }
        );
      })
      .then((statuses)=>{
        vm.$store.state.common.statuses = statuses
        vm.updated_id = "";
        vm.forms.update.reset();
        vm.appNotify({
          message: 'Successfully updated',
          type: 'success',
        })
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
        vm.errors.update=ex.own_errors ?? {}
      })
      .finally(() => {
        vm.is_submitted.update = false;
        vm.api_sent.update = false;
      });
    },
    setUpdateId(status) {
      let vm = this;
      // console.log(status)
      if (this.api_sent.remove == status.id || this.api_sent.set == status.id) return;
      this.forms.update.text = status.status;
      this.forms.update.color = status.colour;
      this.forms.update.color_combo_id = status.color_combination_id;
      this.forms.update.allow_call = status.allow_call;
      this.colorCombinations()
      setTimeout(() => {
        vm.updated_id = status.id;
      }, 500);
    },
    set(id, name) {
      let vm = this;
      if (!id || this.api_sent.set || this.api_sent.remove == id) return;
      this.api_sent.set = id;
      VOIP_API.endpoints.user_statuses.set({ 
        id 
      })
      .then(() => {
        vm.$store.state.common.user.status_id = id;
        vm.$store.state.common.user.status = name;
      })
      .finally(() => {
        vm.api_sent.set = "";
      });
    },
    allowCall(id, event, index) {
      LOGGER.log("id,event.value,index", id, event, index);
      let vm = this;
      if (this.api_sent.allow_call) return;
      this.api_sent.allow_call = id;
      VOIP_API.endpoints.statuses.allowcall({
        id,
        allow_call: event,
      })
      .then(() => {
        vm.$store.state.common.statuses = vm.$store.state.common.statuses.map(
          (status) =>
            status.id === id ? { ...status, allow_call: event } : status
        );
      })
      .finally(() => {
        vm.api_sent.allow_call = "";
      });
    },
    onClosed() {
      this.updated_id = "";
      this.forms.create.reset();
      this.forms.update.reset();
      this.is_submitted.update = false;
      this.is_submitted.create = false;
      this.errors.create = {};
      this.errors.update = {};
    },
    openSucker(isOpen) {
      if (isOpen) {
        // ... canvas be created
        // this.suckerCanvas = canvas
        // this.suckerArea = [x1, y1, x2, y2]
      } else {
        // this.suckerCanvas && this.suckerCanvas.remove
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons {
  margin-top: 35px;
}
.dialer-table {
  .dialer-col-center {
    span {
      .custom-control {
        padding-left: 0px;
      }
    }
  }
}

.colorPicker-section {
  ul {
    display: flex;
    justify-content: flex-end;
    li {
      .color-circle {
        cursor: pointer;
        display: block;
        width: 35px !important;
        height: 35px;
        margin-right: 15px;
        padding: 0px !important;
        @include border-radius(50% !important);
        overflow: hidden;
        &.primary {
          background-color: $primary;
        }
        &.secondary {
          background-color: $secondary;
        }
        &.danger {
          background-color: $danger;
        }
        &.success {
          background-color: $success;
        }
        .color-swatch {
          position: absolute;
          padding: 0px;
          right: -8px;
          top: -8px;
          width: 56px !important;
          height: 56px;
          border: none;
          @include border-radius(50% !important);
        }
        &:after {
          position: absolute;
          content: "";
          top: 11px;
          left: 50%;
          transform: translate(-50%) rotate(135deg);
          border-top: solid $white;
          border-right: solid $white;
          border-width: 1px;
          width: 10px;
          height: 10px;
          cursor: pointer;
          pointer-events: none;
        }
      }
      &:last-child {
        .color-circle {
          margin-right: 0px;
        }
      }
    }
  }
}
.dialer-userStatusArea {
  display: flex;
  justify-content: center;
  .dialer-userStatus {
    position: relative;
  }
}
</style>
