var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', {
    staticClass: "dialer-row-select"
  }, [_c('td', {
    staticClass: "dialer-row-title cursor_pointer one",
    on: {
      "click": function ($event) {
        return _vm.$emit('clicked');
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-icon', {
    staticClass: "mr-16px",
    attrs: {
      "icon": "moh-table-tdIcon",
      "width": "35px",
      "height": "35px"
    }
  }), _c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "title": `created at ${_vm.playlist.date_created}`
    }
  }, [_vm._v(_vm._s(_vm.playlist.label))]), _vm.playlist.default == 1 ? _c('span', {
    staticClass: "newThisDeviceIndicaterTextBox-25-11-2023",
    attrs: {
      "id": "popover-target-1"
    }
  }, [_vm._v("Default")]) : _vm._e(), _vm.playlist.type == _vm.types.custom.key ? _c('span', {
    staticClass: "newBlueColorTagLike"
  }, [_vm._v("Custom")]) : _vm._e()], 1), _c('span', {
    staticClass: "newThisDeviceIndicaterTextBox-25-11-2023 h-fit-content ml-auto mr-16px showOnly-OnHover-insideTable"
  }, [_vm._v(" Configure ")])])]), _c('td', {
    staticClass: "two",
    on: {
      "click": function ($event) {
        if ($event.target !== $event.currentTarget) return null;
        return _vm.$emit('clicked');
      }
    }
  }, [_c('div', {
    staticClass: "w-auto min-width-370px"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_vm.length > 0 ? [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-button', {
    class: `py-0 px-0 ${_vm.length > 1 ? '' : 'invisible'} MOHItem-item-arrow-button`,
    attrs: {
      "variant": "link",
      "disabled": _vm.selected.audio == 0
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.selected.audio = _vm.selected.audio - 1;
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "chevron-left"
    }
  })], 1), _c('span', {
    staticClass: "mx-1"
  }, [_vm._v(_vm._s(_vm.selected.audio + 1) + " / " + _vm._s(_vm.length))]), _c('b-button', {
    class: `py-0 px-0 ${_vm.length > 1 ? '' : 'invisible'} MOHItem-item-arrow-button`,
    attrs: {
      "variant": "link",
      "disabled": _vm.selected.audio + 1 == _vm.length
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.selected.audio = _vm.selected.audio + 1;
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "chevron-right",
      "variant": "dark"
    }
  })], 1)], 1), _c('div', {}, [_c('vb-audio-player', {
    staticClass: "whiteBGaudioWithGreyRoundedBorder-again min-w-250px",
    attrs: {
      "src": _vm.selectedAudio
    }
  })], 1), _vm._t("right-end")] : [_vm._v(" Add sound files ")]], 2)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }