<template>
  <div>
    <vb-icon icon="numbersInsideGraph-icon" height="40px" width="40px"/>
  </div>
</template>

<script>
export default {
  name: 'NumberAssignIcon',
}
</script>

<style>

</style>