var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isExecute ? _c('div', {
    staticClass: "connection",
    attrs: {
      "id": _vm.el_id
    }
  }, [_vm.isConnectionEstablished ? _c('Conference', {
    ref: "conference",
    attrs: {
      "JitsiConnection": _vm.JitsiConnection,
      "payload": _vm.videoCall.payload,
      "videoCall": _vm.videoCall,
      "listIndex": _vm.listIndex,
      "el_id": _vm.el_id
    },
    on: {
      "to-dashboard": function ($event) {
        return _vm.$emit('to-dashboard');
      },
      "calls-list": function ($event) {
        return _vm.$emit('calls-list');
      },
      "onDestroyed": _vm.onConferenceDestroyed,
      "addNewConference": function ($event) {
        return _vm.addNewConference($event);
      },
      "toggleIsPlay": _vm.toggleIsPlay,
      "showModal": function ($event) {
        return _vm.$modal.show('AddMembersInVideoCall');
      }
    }
  }) : _c('div', {
    staticClass: "connection-Loading"
  })], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }