var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "center-zoomIn-transition v2 m-w-600",
    attrs: {
      "width": "500px",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit s1"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.addExtension();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer onlyfirstLetterCapital restLowerCase"
  }, [_vm._v("Add " + _vm._s(_vm._f("extensionText")(_vm.data.type)))])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "md-mar-vertical"
  }, [_c('div', {
    staticClass: "dialer-team-name mb-4"
  }, [_vm.conditions.screen == _vm.screens.limit ? _c('div', {
    staticClass: "dialer-input-field"
  }, [_vm.api.checkpurchaselimit.send ? [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "latestShimmerDesign mb-2",
    staticStyle: {
      "height": "21px",
      "width": "161px"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign mb-0",
    staticStyle: {
      "height": "50px",
      "width": "100%"
    }
  })]), _c('div', {
    staticClass: "latestShimmerDesign mt-4 ml-auto",
    staticStyle: {
      "height": "50px",
      "width": "160px"
    }
  })] : _c('h3', {
    staticClass: "has-no-margin text-center",
    staticStyle: {
      "font-size": "14px"
    }
  }, [_c('i', {
    staticClass: "fab fa-product-hunt"
  }), _vm._v(" Sorry you have reached your purchase limit.Please contact to your administrator. ")])], 2) : _vm._e(), _vm.conditions.screen == _vm.screens.add ? _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_vm.isExternal ? _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('label', [_vm._v("Enter extension number")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.extension.number,
      expression: "forms.extension.number"
    }, {
      name: "vb-input-number-formate",
      rawName: "v-vb-input-number-formate"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Enter extension number",
      "disabled": _vm.api.add_extension.send
    },
    domProps: {
      "value": _vm.forms.extension.number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.extension, "number", $event.target.value);
      }
    }
  }), _vm.forms.extension.submitted && _vm.$v.forms.extension.number.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.extension.number.required ? _c('span', [_vm._v("* Number is required")]) : !_vm.$v.forms.extension.number.validNumber ? _c('span', [_vm._v("* Number is not valid")]) : _vm._e()]) : _vm._e()]) : _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('label', [_vm._v("Enter name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.extension.name,
      expression: "forms.extension.name"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Name",
      "disabled": _vm.api.add_extension.send,
      "maxlength": _vm.$v.forms.extension.name.$params.maxLength.max
    },
    domProps: {
      "value": _vm.forms.extension.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.extension, "name", $event.target.value);
      }
    }
  }), _vm.forms.extension.submitted && _vm.$v.forms.extension.name.$invalid || _vm.api.add_extension.validations_error.name ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.extension.name.required ? _c('span', [_vm._v("* Name is required")]) : !_vm.$v.forms.extension.name.alphaNum ? _c('span', [_vm._v("* Name can be only alphabet and number")]) : !_vm.$v.forms.extension.name.maxLength ? _c('span', [_vm._v("* Name can be maximum " + _vm._s(_vm.$v.forms.extension.name.$params.maxLength.max))]) : _vm._e(), _vm._l(_vm.api.add_extension.validations_error.name || [], function (error_message) {
    return _c('span', {
      key: error_message
    }, [_vm._v("* " + _vm._s(error_message))]);
  })], 2) : _vm._e()])]) : _vm._e()])]), _c('div', {
    staticClass: "w-100 d-flex justify-content-end mt-4"
  }, [_c('button', {
    staticClass: "fullWidthDoneButton",
    attrs: {
      "type": "submit",
      "disabled": _vm.api.add_extension.send
    }
  }, [_vm.api.add_extension.send ? _c('vb-spinner') : [_vm._v("Create extension")]], 2)])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }