<template>
  <div class="crm-main-section DealsSection">
    <div class="d-flex justify-content-between align-items-center w-100 mb-5">
      <h2 class="crm-main-section-heading text-case-unset mb-0">
        Translator
        <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
      </h2>
    </div>
    <div class="crm-card p-32">
      <div class="d-flex justify-content-between aling-items-center">
        <h2 class="crm-main-section-heading text-case-unset mb-0" style="color:black;">
          Translator
        </h2>
        <button class="crm-button">
          <vb-icon class="crm-plusButton-icon" icon="crm-plusButton-icon" />
          New Locale
        </button>
      </div>
      <teamplate v-if="showEditSection == false">
        <div v-for="data in translatorData" :key="data.id" 
          class="d-flex justify-content-between align-items-center crm-innerCard mt-4 w-100"
          @click="showEditSection=true;editSectionData = data" style="cursor:pointer;"
        >
          <div class="crm-card-heading my-0">{{data.label}}</div>
          <b-icon icon="chevron-down" color="black" font-scale="1.5"/>
        </div>
      </teamplate>
      <template v-if="showEditSection">
        <div class="crm-innerCard mt-4 w-100">
          <div class="crm-card-heading my-0">{{editSectionData.label}}</div>
          <vb-table class="mt-3" :isListEmpty="isEmpty(editSectionData.tableData)" :listLength="editSectionData.tableData.length" textNoRecord=" ">
            <template slot="header">
              <tr>
                <th class="dialer-has-sort">
                  <span class="dialer-sorted-by ascending">KEY</span>
                </th>
                <th class="dialer-has-sort">
                  <span>SOURCE</span>
                </th>
                <th class="dialer-col-right">
                  <span>EN</span>
                </th>
              </tr>
            </template>
            <template #body="{ start, end }">
              <tr v-for="(data, index) in editSectionData.tableData" :key="data.id" v-show="index >= start && index <= end">
                <td class="dialer-row-title">
                  <span>{{ data.key }}</span>
                </td>
                <td class="dialer-row-title">{{ data.source }}</td>
                <td class="dialer-col-right">
                  <div class="crm-input-container mb-0">
                    <textarea style="height:70px;" :value="data.en"/>
                  </div>
                </td>
              </tr>
            </template>
          </vb-table>
        </div>
        <button @click="showEditSection=false;editSectionData = {}" class="crm-button mt-3">
          Cancel
        </button>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Update',
  components: {
    
  },
  inject:['isEmpty'],
  data(){
    return {
      tableData : {
        0: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        1: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        2: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        3: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        4: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        5: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        6: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        7: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        8: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        9: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        10: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
      },
      translatorData : {
        0: {
          label: 'Actions',
          tableData : {
            0:{key : 'action',source : 'Action',en : 'Action'},
            1:{key : 'actions',source : 'Actions',en : 'Actions'},
            2:{key : 'select',source : 'Select Action',en : 'Select Action'},
            3:{key : 'confirmation_message',source : 'Are you sure you want to perform this action?',en : 'Are you sure you want to perform this action?'},
            4:{key : 'run_successfully',source : 'The action run successfully.',en : 'The action run successfully.'},
            5:{key : 'search_in_google',source : 'Search In Google',en : 'Search In Google'},
            6:{key : 'records_count',source : '1 record | :count records',en : '1 record | :count records'},
          }
        },
        1: {
          label: 'Activity',
          tableData : {
            0:{key : 'activities',source : 'Activities',en : 'Activities'},
            1:{key : 'activity',source : 'Activity',en : 'Activity'},
            2:{key : 'add',source : 'Add Activity',en : 'Add Activity'},
            3:{key : 'description',source : 'Description',en : 'Description'},
            4:{key : 'description_info',source : 'The description is visible to all guests',en : 'The description is visible to all guests'},
            5:{key : 'note',source : 'Note',en : 'Note'},
            6:{key : 'title	',source : 'Title',en : 'Title'},
          }
        },
        2: {
          label: 'Api',
          tableData : {
            0:{key : 'api',source : 'API',en : 'API'},
            1:{key : 'access',source : 'API Access',en : 'API Access'},
            2:{key : 'personal_access_tokens',source : 'Personal Access Tokens',en : 'Personal Access Tokens'},
            3:{key : 'personal_access_token',source : 'Personal Access Token',en : 'Personal Access Token'},
            4:{key : 'create_token',source : 'Create New Token',en : 'Create New Token'},
            5:{key : 'no_tokens',source : '	You have not created any personal access tokens.',en : '	You have not created any personal access tokens.'},
            6:{key : 'token_name',source : 'Name',en : '	Name'},
          }
        },
        3: {
          label: 'Actions',
          tableData : {
            0:{key : 'action',source : 'Action',en : 'Action'},
            1:{key : 'actions',source : 'Actions',en : 'Actions'},
            2:{key : 'select',source : 'Select Action',en : 'Select Action'},
            3:{key : 'confirmation_message',source : 'Are you sure you want to perform this action?',en : 'Are you sure you want to perform this action?'},
            4:{key : 'run_successfully',source : 'The action run successfully.',en : 'The action run successfully.'},
            5:{key : 'search_in_google',source : 'Search In Google',en : 'Search In Google'},
            6:{key : 'records_count',source : '1 record | :count records',en : '1 record | :count records'},
          }
        },
        4: {
          label: 'Actions',
          tableData : {
            0:{key : 'action',source : 'Action',en : 'Action'},
            1:{key : 'actions',source : 'Actions',en : 'Actions'},
            2:{key : 'select',source : 'Select Action',en : 'Select Action'},
            3:{key : 'confirmation_message',source : 'Are you sure you want to perform this action?',en : 'Are you sure you want to perform this action?'},
            4:{key : 'run_successfully',source : 'The action run successfully.',en : 'The action run successfully.'},
            5:{key : 'search_in_google',source : 'Search In Google',en : 'Search In Google'},
            6:{key : 'records_count',source : '1 record | :count records',en : '1 record | :count records'},
          }
        },
        5: {
          label: 'Actions',
          tableData : {
            0:{key : 'action',source : 'Action',en : 'Action'},
            1:{key : 'actions',source : 'Actions',en : 'Actions'},
            2:{key : 'select',source : 'Select Action',en : 'Select Action'},
            3:{key : 'confirmation_message',source : 'Are you sure you want to perform this action?',en : 'Are you sure you want to perform this action?'},
            4:{key : 'run_successfully',source : 'The action run successfully.',en : 'The action run successfully.'},
            5:{key : 'search_in_google',source : 'Search In Google',en : 'Search In Google'},
            6:{key : 'records_count',source : '1 record | :count records',en : '1 record | :count records'},
          }
        },
        6: {
          label: 'Actions',
          tableData : {
            0:{key : 'action',source : 'Action',en : 'Action'},
            1:{key : 'actions',source : 'Actions',en : 'Actions'},
            2:{key : 'select',source : 'Select Action',en : 'Select Action'},
            3:{key : 'confirmation_message',source : 'Are you sure you want to perform this action?',en : 'Are you sure you want to perform this action?'},
            4:{key : 'run_successfully',source : 'The action run successfully.',en : 'The action run successfully.'},
            5:{key : 'search_in_google',source : 'Search In Google',en : 'Search In Google'},
            6:{key : 'records_count',source : '1 record | :count records',en : '1 record | :count records'},
          }
        },
        7: {
          label: 'Actions',
          tableData : {
            0:{key : 'action',source : 'Action',en : 'Action'},
            1:{key : 'actions',source : 'Actions',en : 'Actions'},
            2:{key : 'select',source : 'Select Action',en : 'Select Action'},
            3:{key : 'confirmation_message',source : 'Are you sure you want to perform this action?',en : 'Are you sure you want to perform this action?'},
            4:{key : 'run_successfully',source : 'The action run successfully.',en : 'The action run successfully.'},
            5:{key : 'search_in_google',source : 'Search In Google',en : 'Search In Google'},
            6:{key : 'records_count',source : '1 record | :count records',en : '1 record | :count records'},
          }
        },
        8: {
          label: 'Actions',
          tableData : {
            0:{key : 'action',source : 'Action',en : 'Action'},
            1:{key : 'actions',source : 'Actions',en : 'Actions'},
            2:{key : 'select',source : 'Select Action',en : 'Select Action'},
            3:{key : 'confirmation_message',source : 'Are you sure you want to perform this action?',en : 'Are you sure you want to perform this action?'},
            4:{key : 'run_successfully',source : 'The action run successfully.',en : 'The action run successfully.'},
            5:{key : 'search_in_google',source : 'Search In Google',en : 'Search In Google'},
            6:{key : 'records_count',source : '1 record | :count records',en : '1 record | :count records'},
          }
        },
        9: {
          label: 'Actions',
          tableData : {
            0:{key : 'action',source : 'Action',en : 'Action'},
            1:{key : 'actions',source : 'Actions',en : 'Actions'},
            2:{key : 'select',source : 'Select Action',en : 'Select Action'},
            3:{key : 'confirmation_message',source : 'Are you sure you want to perform this action?',en : 'Are you sure you want to perform this action?'},
            4:{key : 'run_successfully',source : 'The action run successfully.',en : 'The action run successfully.'},
            5:{key : 'search_in_google',source : 'Search In Google',en : 'Search In Google'},
            6:{key : 'records_count',source : '1 record | :count records',en : '1 record | :count records'},
          }
        },
      },
      showEditSection: false,
      editSectionData :{},
      allowedUploadfileExtentions: 'jpg, jpeg, png, gif, svg, pdf, aac, ogg, oga, mp3, wav, mp4, m4v, mov, ogv, webm, zip, rar, doc, docx, txt, text, xml, json, xls, xlsx, odt, csv, ppt, pptx, ppsx, ics, eml'
    }
  },
  computed: {
    
  },
  methods: {
    
  },
  mounted(){
    
  },
}
</script>

<style>

</style>