var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "right_side_popup m-w-600 addRemove_userModal bigSquareCheckbox min-width-50percent-modal RedirectToModal",
    attrs: {
      "width": "50%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose($event);
      }
    }
  }, [_c('div', {
    staticClass: "dialer-assign-to md-pad-bottom dialer-edit"
  }, [_vm.getIsMobile ? _c('div', {
    staticClass: "dialer-edit-header flex-column"
  }, [_c('div', {
    staticClass: "d-flex w-100 justify-content-between align-items-center mb-2"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Redirect to")]), _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  })], 1), _c('div', {
    staticClass: "dialer-edit-actions d-flex justify-content-end w-100"
  }, [_c('button', {
    class: _vm.selected.extensions.length == 0 ? 'newDeleteButton' : 'newDoneButton',
    attrs: {
      "disabled": _vm.api.update_extension.send
    },
    on: {
      "click": function ($event) {
        return _vm.updateExtension();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": _vm.selected.extensions.length == 0 ? 'squared-delete-icon' : 'squared-done-icon',
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v(_vm._s(_vm.selected.extensions.length == 0 ? 'Unassign' : 'Done'))])], 1)])]) : _c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_c('vb-svg', {
    staticClass: "cursor_pointer_no_hover",
    attrs: {
      "name": "leftLongArrow-icon",
      "width": "20",
      "height": "12",
      "viewBox": "0 0 30 16",
      "stroke-width": "1",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }), _vm._v(" Redirect to ")], 1)]), _c('div', {
    staticClass: "dialer-edit-actions d-flex"
  }, [_c('button', {
    class: _vm.selected.extensions.length == 0 ? 'newDeleteButton' : 'newDoneButton',
    attrs: {
      "disabled": _vm.api.update_extension.send
    },
    on: {
      "click": function ($event) {
        return _vm.updateExtension();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": _vm.selected.extensions.length == 0 ? 'squared-delete-icon' : 'squared-done-icon',
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v(_vm._s(_vm.selected.extensions.length == 0 ? 'Unassign' : 'Done'))])], 1)])]), _c('div', {
    staticClass: "TabsDesignWithIcon-container mt-20px"
  }, _vm._l(_vm.allowExtensions, function (extension) {
    return _c('div', {
      key: extension,
      staticClass: "TabsDesignWithIcon-item",
      class: {
        'active': _vm.selected.tab == extension
      },
      on: {
        "click": function ($event) {
          _vm.selected.tab = extension;
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": _vm._f("extensionInfo")(extension, 'icon'),
        "height": "38px",
        "width": "38px"
      }
    }), _c('span', {
      staticClass: "TabsDesignWithIcon-item-textPart"
    }, [_vm._v(_vm._s(_vm._f("extensionInfo")(extension, 'title')))])], 1);
  }), 0), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between w-100 mt-20px"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main-24px"
  }, [_vm._v(_vm._s(_vm._f("extensionInfo")(_vm.selected.tab, 'title')))]), _c('div', {
    staticClass: "w-50 whiteBGinputWithGreyRoundedBorder"
  }, [_c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": _vm._f("extensionInfo")(_vm.selected.tab, 'search-placeholder')
    },
    model: {
      value: _vm.filter.list.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.list, "search", $$v);
      },
      expression: "filter.list.search"
    }
  })], 1)]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('b-alert', {
    attrs: {
      "show": _vm.data.user_mode,
      "variant": "info"
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "info-circle-fill",
      "variant": "info"
    }
  }), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v("To ring multiple user you will need to turn off \"dedicated number mode\" or assign this number to a team where multiple user based on groups or departments can be added.")])], 1), _vm.api.extensions.send ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, _vm._l(4, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row"
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "latestShimmerDesign mr-2",
      staticStyle: {
        "height": "45px",
        "width": "45px"
      }
    }), _c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "21px",
        "width": "100px"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign mt-1",
      staticStyle: {
        "height": "21px",
        "width": "70%"
      }
    })])]), _c('div', {
      staticClass: "latestShimmerDesign mr-2",
      staticStyle: {
        "height": "24px",
        "width": "24px"
      }
    })]);
  }), 0) : _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_vm._l(_vm.extensions, function (extension) {
    return _c('div', {
      key: extension.accountcode,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-none"
    }, [_c('b-form-checkbox', {
      staticClass: "dialer_full_width_checkbox circle_btn_checkbox circle_btn_onRight p-0 makeCheckBoxesSquare less-padding leastPadding noPYinLabel",
      attrs: {
        "id": 'checkbox-' + extension.accountcode,
        "disabled": _vm.api.update_extension.send,
        "name": "telephoneextension",
        "checked": _vm.selected.extensions.includes(extension.accountcode)
      },
      on: {
        "change": function ($event) {
          return _vm.onSelectExtension(extension);
        }
      }
    }, [_c('Info', {
      attrs: {
        "id": extension.accountcode,
        "type": extension.account,
        "is_blf": false
      }
    })], 1)], 1);
  }), _vm.extensions.length == 0 ? _c('vb-no-record', {
    staticClass: "my-5",
    attrs: {
      "text": _vm.api.extensions.send ? '' : 'There is no record',
      "design": 3
    }
  }) : _vm._e()], 2)], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }