<template>
  <div class="dialer-settings-main">
    <section class="dialer-settings-section dialer-ivr-setting TeamsSettings">
      <!-- <div class="dialer-flex">
        <template v-if="$store.getters.getIsMobile">
          <div class="d-flex align-items-center mb-3 calc-title-width-button">
            <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" style="" @click="$emit('back')" />
            <h2 class="dialer-settings-title w-100 mb-0 text-center">Teams</h2>
          </div>
        </template>
        <h2 v-else class="dialer-settings-title">
          Teams
          <b-icon id="ivr-info" icon="info-circle-fill" @click="$modal.show('KbInfoModal',{ kb: 'teams' })" class="cursor_pointer infoKbIcon md-mar-left" />
        </h2>
        <div>
          <button class="newButton" @click="$modal.show('AddTeamModal',{ suggested_name: `team ${voip_teams.length+1}` })">
            <vb-icon icon="squared-add-icon" height="38px" width="38px" />
            <span class="newButton-textPart">Add team</span>
          </button>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between md-mar-top md-mar-bottom teams-settings-2nd-section">
        <span class="dialer-settings-intro newerTextVersion mb-0">Organize users into teams to streamline communication for specific departments or skills, like sales, support, and accounts. Each team will group users together and can have its own calling rules.</span>
        <div class="d-flex justify-content-end align-items-center w-50">
          <div class="whiteBGinputWithGreyRoundedBorder sm-mar-left seachBox">
            <b-input type="text" placeholder="Search team" v-model="filter.teams.search" />
          </div>
        </div>
      </div> -->

      <div class="settingsFirstSection">
        <template v-if="$store.getters.getIsMobile">
          <div class="d-flex align-items-center mb-3 calc-title-width-button w-100">
            <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" style="" @click="$emit('back')" />
            <h2 class="dialer-settings-title w-100 mb-0 text-center">Teams</h2>
          </div>
          <div>
            <button class="newButton" @click="$modal.show('AddTeamModal',{ suggested_name: `team ${voip_teams.length+1}` })">
              <vb-icon icon="squared-add-icon" height="38px" width="38px" />
              <span class="newButton-textPart">Add team</span>
            </button>
          </div>
        </template>
        <template v-else>
          <h2 class="dialer-settings-title mb-0 settingsFirstSection-left newer">
            Teams
            <b-icon id="ivr-info" icon="info-circle-fill" @click="$modal.show('KbInfoModal',{ kb: 'teams' })" class="cursor_pointer infoKbIcon md-mar-left" />
          </h2>
          <div class="settingsFirstSection-right">
            <div class="d-flex justify-content-end align-items-center mt-0">
              <div class="whiteBGinputWithGreyRoundedBorder sm-mar-left sm-mar-right seachBox">
                <b-input type="text" placeholder="Search team" v-model="filter.teams.search" />
              </div>
              <button class="newButton" @click="$modal.show('AddTeamModal',{ suggested_name: `team ${voip_teams.length+1}` })">
                <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                <span class="newButton-textPart">Add team</span>
              </button>
            </div>
          </div>
        </template>
      </div>
      <div class="settingsSecondSection">
        <span class="dialer-settings-intro newerTextVersion mt-0 mb-0">Organize users into teams to streamline communication for specific departments or skills, like sales, support, and accounts. Each team will group users together and can have its own calling rules.</span>
      </div>

      <template v-if="!$store.getters.getIsMobile">
        <vb-table textNoRecord=" " class="latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover" id="teams-table" :isListEmpty="isEmpty(filterTeams)" :listLength="filterTeams.length" :perPage="5" :loading="api.teams.send" :noRecordWholePage="true">
          <template slot="header">
            <tr>
              <th class="dialer-has-sort">
                <span class="dialer-sorted-by ascending">Name</span>
              </th>
              <th class="dialer-has-sort">
                <span class="dialer-sorted-by ascending">Numbers</span>
              </th>
              <th class="dialer-has-sort dialer-col-right" v-if="!$store.getters.getIsMobile">
                <!-- <span class="">Flow</span> -->
              </th>
              <th class="dialer-has-sort">
                <!-- <span>Assigned To</span> -->
                <span>Members</span>
              </th>
            </tr>
          </template>
          <template #body="{ start, end }">
            <template v-for="(team, index) in filterTeams">
              <tr 
                :class="`dialer-row-select ${conditions.more==team.id ? 'makeTDverticalAlignTop' : ''}`" 
                :key="team.id" 
                v-show="index >= start && index <= end" 
                @click="team.queue_service ? '' : team.status !='active' ? appNotify({ type: 'danger', message: 'Unable to proceed because this team is suspended' }) : $modal.show('EditTeamModal', { team: team })"
              >
                
                <td class="dialer-row-title one">
                  <Info class="w-fit-content" v-if="team.queue_service" :id="team.id" :is_blf="false" />
                  <Info class="w-fit-content" v-else :id="team.real_id" :is_blf="false" is_suspended_show />
                </td>
                <td class="dialer-col-left font-weight-bold two">
                  <div class="d-flex flex-column" v-if="team.ringroup && team.ringroup.did && team.ringroup.did[0]">
                    <div class="d-flex align-items-center">
                      <span class="sm-mar-left font-weight-bold min-width-160px">
                        <span class="mr-1">
                          <img class="country-img" width="45px" :src="team | get_property('ringroup.did[0].did') | number_formater | flag_icon" />
                        </span>
                        {{ team | get_property('ringroup.did[0].did') | number_formater }}
                      </span>
                      <div class="w-auto dialer-dropdown dialer-dropDown-latest dialer-dropDown-onRight dialer-dropDown-p-1 ml-2" v-if="team.ringroup.did.length>1" @click.stop="conditions.more=conditions.more==team.id?'':team.id">
                        <div class="px-2 rounded more-numbers-tag">
                          <small>+ {{ team.ringroup.did.length-1 }} more</small>
                        </div>
                      </div>
                    </div>
                    <template v-if="conditions.more==team.id">
                      <template v-for="(did, index) in team.ringroup.did.filter((item,index)=>index>0)" >
                        <div class="mt-2" :key="did.did" v-show="index >= start && index <= end" >
                          <div class="d-flex align-items-center sm-mar-left">
                            <span class="mr-2 d-flex align-items-center">
                              <img  class="country-img" width="45px" :src="did.did | number_formater | flag_icon" />
                            </span>
                            {{ did.did | number_formater }}
                          </div>
                        </div>
                      </template>
                    </template>
                  </div>
                  <template v-else>
                    <span class="text-center"> - </span>
                  </template>
                </td>
                <td class=" dialer-col-right four" v-if="!$store.getters.getIsMobile">
                  <b-spinner v-if="team.queue_service" variant="primary" type="grow" small label="Spinning" />
                  <div v-else class="dialer-row-title three d-flex align-items-center">
                    <span class="newThisDeviceIndicaterTextBox-25-11-2023 h-fit-content ml-0 mr-16px showOnly-OnHover-insideTable" > 
                      Configure
                    </span>
                    <button 
                      class="dialer-button dialer-button-delete-alt buttonNothing" 
                      type="button" 
                      @click.stop="team.status !='active' ? appNotify({ 
                        type: 'danger', 
                        message: 'Unable to proceed because this team is suspended' 
                      }) : $modal.show('TeamsGraphModal', { 
                        accountcode: team.ringroup.accountcode 
                      })"
                    >
                      <vb-icon icon="graph-icon" class="graphIcon" height="40.501" width="41.374" />
                    </button>
                  </div>
                </td>
                <td class="dialer-row-title three">
                  <div v-if="!team.queue_service" class="d-flex">
                    <a class="dialer-link-more text-decoration-underline" @click.stop="$modal.show('AssignNumbersTeam', { team: team, type: 'AssignNumbersTeam' })">
                      {{ team.members | memberCount }} users
                    </a>
                  </div>
                </td>
              </tr>
              <!-- <tr v-if="conditions.more==team.id" :key="`${team.id}-numbers`" class="w-100 sub-table-new in-teams">
                <td class=""> 
                  <div class=" ">
                    <vb-table :isListEmpty="isEmpty(team.ringroup.did)" :listLength="team.ringroup.did.length" :perPage="5" :loading="false" >
                      <template slot="header">
                        <tr>
                          <th class="dialer-has-sort">
                            <span class="dialer-sorted-by ascending">Remaining Numbers</span>
                          </th>
                        </tr>
                      </template>
                      <template #body="{ start, end }">
                        <template v-for="(did, index) in team.ringroup.did.filter((item,index)=>index>0)" >
                          <tr class="dialer-row-select" :key="did.did" v-show="index >= start && index <= end" >
                            <td class="dialer-row-title">
                              <span style="height:45px;width:45px;">
                                <img  class="country-img" width="45px" :src="did.did | number_formater | flag_icon" />
                              </span>
                              {{ did.did | number_formater }}
                            </td>
                          </tr>
                        </template>
                      </template>
                    </vb-table>
                  </div>
                </td>
                <td style=""><br/></td>
                <td style=""><br/></td>
              </tr> -->
            </template>
            
          </template>
          <template #loading>
            <tr v-for="n in 5" :key="n">
              <td>
                <div class="d-flex">
                  <div class="latestShimmerDesign mr-2" style="height:35px;width:35px;"></div>
                  <div class="d-flex flex-column justify-content-between">
                    <div class="latestShimmerDesign flex-1" style="height:16px;width:60px"></div>
                    <div class="latestShimmerDesign mt-1" style="height:16px;width:30px"></div>
                  </div>
                </div>
              </td>
              <td>
                <div class="d-flex">
                  <div class="latestShimmerDesign mr-2" style="height:20px;min-width:40px;"></div>
                  <div class="latestShimmerDesign" style="height:20px;width:-webkit-fill-available;"></div>
                </div>
              </td>
              <td>
                <div class="latestShimmerDesign" style="height:20px;width:-webkit-fill-available;"></div>
              </td>
              <td class="dialer-col-right">
                <div class="latestShimmerDesign ml-auto" style="height:48px;width:48px;"></div>
              </td>
            </tr>
          </template>
          <template #no-record>
            <div class="latestGreyBox-9-9-2023 mb-32px">
              <div class="emptyScreenContainer">
                <img width="365px" height="128px" class="mt-50px" v-if="GET_LOCAL_SETTING_IS_DARK_MODE" :src="require('@/assets/images/emptyScreenImages/darkMode/teams.png')"/>
                <img width="365px" height="128px" class="mt-50px" v-else :src="require('@/assets/images/emptyScreenImages/teams.png')"/>
                <div class="emptyScreenContainer-heading mt-50px">
                  Create your first team
                </div>
                <div class="emptyScreenContainer-text w-75">
                  Teams help you organize and collaborate efficiently. Create your first team to get started.
                </div>
                <button @click="$modal.show('AddTeamModal',{ suggested_name: `team ${voip_teams.length+1}` })" class="dialer-button dialer-button-primary mt-20px">
                  <b-icon icon="plus-lg" class="mr-3" />
                  Create team
                </button>
              </div>
            </div>
          </template>
        </vb-table>  
      </template>
      <template v-if="$store.getters.getIsMobile">
        <vb-table textNoRecord=" " class="latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover smallerFontSizes" id="teams-table" :isListEmpty="isEmpty(filterTeams)" :listLength="filterTeams.length" :perPage="5" :loading="api.teams.send" :noRecordWholePage="true">
          <template slot="header">
            <tr>
              <th class="dialer-has-sort">
                <span class="dialer-sorted-by ascending">Name</span>
              </th>
              <th class="dialer-has-sort">
                <span class="dialer-sorted-by ascending">Numbers</span>
              </th>
              <th class="dialer-has-sort">
                <span>Members</span>
              </th>
            </tr>
          </template>
          <template #body="{ start, end }">
            <template v-for="(team, index) in filterTeams">
              <tr 
                :class="`dialer-row-select ${conditions.more==team.id ? 'makeTDverticalAlignTop' : ''}`" 
                :key="team.id" 
                v-show="index >= start && index <= end" 
                @click="team.queue_service ? '' : team.status !='active' ? appNotify({ type: 'danger', message: 'Unable to proceed because this team is suspended' }) : $modal.show('EditTeamModal', { team: team })"
              >
                
                <td class="dialer-row-title one">
                  <Info class="w-fit-content" v-if="team.queue_service" :id="team.id" :is_blf="false" />
                  <Info class="w-fit-content" v-else :id="team.real_id" :is_blf="false" is_suspended_show />
                </td>
                <td class="dialer-col-left font-weight-bold ">
                  <div class="d-flex flex-column" v-if="team.ringroup && team.ringroup.did && team.ringroup.did[0]">
                    <div class="d-flex align-items-center">
                      <span class="sm-mar-left font-weight-bold min-width-160px">
                        <span class="mr-1">
                          <img class="country-img" width="45px" :src="team | get_property('ringroup.did[0].did') | number_formater | flag_icon" />
                        </span>
                        {{ team | get_property('ringroup.did[0].did') | number_formater }}
                      </span>
                      <div class="w-auto dialer-dropdown dialer-dropDown-latest dialer-dropDown-onRight dialer-dropDown-p-1 ml-2" v-if="team.ringroup.did.length>1" @click.stop="conditions.more=conditions.more==team.id?'':team.id">
                        <div class="px-2 rounded more-numbers-tag">
                          <small>+ {{ team.ringroup.did.length-1 }} more</small>
                        </div>
                      </div>
                    </div>
                    <template v-if="conditions.more==team.id">
                      <template v-for="(did, index) in team.ringroup.did.filter((item,index)=>index>0)" >
                        <div class="mt-2" :key="did.did" v-show="index >= start && index <= end" >
                          <div class="d-flex align-items-center sm-mar-left">
                            <span class="mr-2 d-flex align-items-center">
                              <img  class="country-img" width="45px" :src="did.did | number_formater | flag_icon" />
                            </span>
                            {{ did.did | number_formater }}
                          </div>
                        </div>
                      </template>
                    </template>
                  </div>
                  <template v-else>
                    <span class="text-center"> - </span>
                  </template>
                </td>
                <td class="dialer-row-title three dialer-col-left ">
                  <div v-if="!team.queue_service" class="d-flex flex-row justify-content-end text-right">
                    <a class="dialer-link-more text-decoration-underline text-right" @click.stop="$modal.show('AssignNumbersTeam', { team: team, type: 'AssignNumbersTeam' })">
                      {{ team.members | memberCount }} users
                    </a>
                  </div>
                </td>
              </tr>
              <!-- <tr v-if="conditions.more==team.id" :key="`${team.id}-numbers`" class="w-100 sub-table-new in-teams">
                <td class=""> 
                  <div class=" ">
                    <vb-table :isListEmpty="isEmpty(team.ringroup.did)" :listLength="team.ringroup.did.length" :perPage="5" :loading="false" >
                      <template slot="header">
                        <tr>
                          <th class="dialer-has-sort">
                            <span class="dialer-sorted-by ascending">Remaining Numbers</span>
                          </th>
                        </tr>
                      </template>
                      <template #body="{ start, end }">
                        <template v-for="(did, index) in team.ringroup.did.filter((item,index)=>index>0)" >
                          <tr class="dialer-row-select" :key="did.did" v-show="index >= start && index <= end" >
                            <td class="dialer-row-title">
                              <span style="height:45px;width:45px;">
                                <img  class="country-img" width="45px" :src="did.did | number_formater | flag_icon" />
                              </span>
                              {{ did.did | number_formater }}
                            </td>
                          </tr>
                        </template>
                      </template>
                    </vb-table>
                  </div>
                </td>
                <td style=""><br/></td>
                <td style=""><br/></td>
              </tr> -->
            </template>
            
          </template>
          <template #loading>
            <tr v-for="n in 5" :key="n">
              <td>
                <div class="d-flex">
                  <div class="latestShimmerDesign mr-2" style="height:35px;width:35px;"></div>
                  <div class="d-flex flex-column justify-content-between">
                    <div class="latestShimmerDesign flex-1" style="height:16px;width:60px"></div>
                    <div class="latestShimmerDesign mt-1" style="height:16px;width:30px"></div>
                  </div>
                </div>
              </td>
              <td>
                <div class="d-flex">
                  <div class="latestShimmerDesign mr-2" style="height:20px;min-width:40px;"></div>
                  <div class="latestShimmerDesign" style="height:20px;width:-webkit-fill-available;"></div>
                </div>
              </td>
              <td>
                <div class="latestShimmerDesign" style="height:20px;width:-webkit-fill-available;"></div>
              </td>
              <td class="dialer-col-right">
                <div class="latestShimmerDesign ml-auto" style="height:48px;width:48px;"></div>
              </td>
            </tr>
          </template>
          <template #no-record>
            <div class="latestGreyBox-9-9-2023 mb-32px">
              <div class="emptyScreenContainer">
                <img width="365px" height="128px" class="mt-50px" v-if="GET_LOCAL_SETTING_IS_DARK_MODE" :src="require('@/assets/images/emptyScreenImages/darkMode/teams.png')"/>
                <img width="365px" height="128px" class="mt-50px" v-else :src="require('@/assets/images/emptyScreenImages/teams.png')"/>
                <div class="emptyScreenContainer-heading mt-50px">
                  Create your first team
                </div>
                <div class="emptyScreenContainer-text w-75">
                  Teams help you organize and collaborate efficiently. Create your first team to get started.
                </div>
                <button class="dialer-button dialer-button-primary mt-20px">
                  <b-icon icon="plus-lg" class="mr-3" />
                  Create team
                </button>
              </div>
            </div>
          </template>
        </vb-table>
      </template>
      <EditTeamModal @interface="afterAdding()" />
    </section>
    <!-- <TeamsGraphModal modalName="TeamsGraphModal" /> -->
    <GraphModal modalName="TeamsGraphModal" />
    <AddTeamModal @interface="afterAdding()" />
    <!-- <EditTeamModal @interface="afterAdding()" /> -->
    <AddRemoveFromList modalName="AssignNumbersTeam" @update-list="afterAdding()" />
    
  </div>
</template>

<script>
import EditTeamModal from "../Modals/EditTeamModal.vue";
// import TeamsGraphModal from '../Modals/TeamsGraphModal.vue';
import GraphModal from "../Modals/GraphModal.vue";
import AddRemoveFromList from "../Modals/AddRemoveFromList";
import AddTeamModal from "../Modals/AddTeamModal.vue";
import { VOIP_API, } from "../../utils";
import Info from '../Lists/Info.vue';
import { mapGetters } from 'vuex';
import { GET_LOCAL_SETTING_IS_DARK_MODE } from '@/store/helper/getters';
export default {
  name: "TeamsSettings",
  components: {
    // TeamsGraphModal,
    GraphModal,
    EditTeamModal,
    AddTeamModal,
    // AssignTeamsModal,
    AddRemoveFromList,
    Info,
  },
  data() {
    return {
      conditions: {
        more: '',
      },
      filter: {
        teams: {
          search: '',
        },
      },
      api: {
        teams: this.$helperFunction.apiInstance(),
      },
    };
  },
  inject:['isEmpty','getVoipTeams','appNotify'],
  computed: {
    ...mapGetters({
      voip_teams: 'getVoipTeams',
      addServiceQueue: 'addServiceQueue',
      GET_LOCAL_SETTING_IS_DARK_MODE: GET_LOCAL_SETTING_IS_DARK_MODE
    }),
    filterTeams() {
      const search = this.filter.teams.search.toLowerCase().trim()
      const teams = this.$store.getters.getVoipTeams
      const filter_teams = [...teams,this.addServiceQueue].filter(item=>{
        if(!item) return false
        else if(item.queue_service){
          if(item.type=='team') return `${item.name}`.toLowerCase().trim().includes(search)
          else return false
        } 
        else return `${item.name} ${item.ringroup.extn}`.toLowerCase().trim().includes(search)
      });
      return filter_teams
    },
  },
  filters: {
    memberCount(value) {
      if (typeof value == "number") return value;
      if (!value) return "";
      return value.length;
    },
    filterExtension(value) {
      if (!value) return "-";
      return value.extn;
    },
  },
  methods: {
    fetchTeams() {
      var vm = this;
      if (vm.api.teams.send) return;
      vm.api.teams.send = true;
      VOIP_API.endpoints.teams.list({
        fid: 12,
      })
      .then(({ data: { data: teams } }) => {
        vm.$store.commit("setVoipTeams", teams);
      })
      .finally(() => {
        vm.api.teams.send = false;
      });
    },
    afterAdding() {
      let vm = this;
      setTimeout(() => {
        vm.getVoipTeams(true);
      }, 2 * 1000);
    },
  },
  activated(){
    this.getVoipTeams()
  }
};
</script>