<template>
  <OutOfHoursLayout>
    <div slot="active-hours" class="div2Inner" style="
      text-align: center;
      height: 42%;
      background: rgba(224, 211, 211, 0.48);
      border-radius: 9px;
    ">
      <div class="dialer-box-header">In Active Hours?</div>
      <div class="d-flex justify-content-end w-100" style="display: flex; position: relative;">
        <a @click="openday()" style="text-decoration: underline; color: blue;">
          <b-icon icon="pencil-fill" variant="primary" class="cursor_pointer mt-2"></b-icon>
        </a>
      </div>
    </div>
    <div slot="default" >
      <template v-if="isAllDefaulted">
        <template v-if="forwardSetting.forward_note == 'voicemail'">
          <div class="selectedText-big">Ask to leave a voicemail</div>
          <div class="selectedText-small">Call will be forward to voicemail</div>
        </template>
        <template v-else-if="forwardSetting.forward_note == 'extension'">
          <div class="selectedText-big">Redirect to a user, team or IVR menu</div>
          <div class="selectedText-small">
            Call will be forward to
            <span 
              class="color-primary cursor_pointer" 
              :class="{
                'text-underline': !forwardSetting.extension_name
              }"
            >{{forwardSetting.extension_name || forwardSetting.forward_number || 'Select extension'}}</span>
          </div>
        </template>
        <template v-else-if="forwardSetting.forward_note == 'number'">
          <div class="selectedText-big">Redirect to another number</div>
          <div class="selectedText-small">
            Call will be forward to 
            <span 
              class="color-primary cursor_pointer" 
              :class="{
                'text-underline': !forwardSetting.forward_number
              }"
            >{{forwardSetting.forward_number || 'Select Number'}}</span>
          </div>
        </template>
        <template v-else>
          <div class="selectedText-big">Keep ringing</div>
          <div class="selectedText-small">Call forwarding is off</div>
        </template>
      </template>
      <template v-else>
        <!-- You can View -->
        <div class="defaultViewText">View Your Default Settings</div>
      </template>
      <span v-if="isAllDefaulted" class="d-flex w-100 justify-content-end" style="position: relative;" @click="opendefault()"><b-icon icon="pencil-fill" variant="primary"></b-icon></span>
      <span v-else class="d-flex w-100 justify-content-end" style="position: relative;" @click="opendefault()"><b-icon icon="eye-fill" variant="primary"></b-icon></span>
    </div>
  </OutOfHoursLayout>
</template>

<script>
import OutOfHoursLayout from '../layouts/OutOfHours.vue'
export default {
  name: 'OutOfHoursNode',
  components: {
    OutOfHoursLayout,
  },
  props: {
    voipaccount: {
      type: String,
    },
    schedules: {
      type: Array,
      default: ()=>[]
    },
    ofh_default_setting: {
      type: Object,
      default: ()=>({})
    },
  },
  computed: {
    days() {
      return {
        mon: this.schedules?.find?.(i=>i.day=='mon'),
        tue: this.schedules?.find?.(i=>i.day=='tue'),
        wed: this.schedules?.find?.(i=>i.day=='wed'),
        thu: this.schedules?.find?.(i=>i.day=='thu'),
        fri: this.schedules?.find?.(i=>i.day=='fri'),
        sat: this.schedules?.find?.(i=>i.day=='sat'),
        sun: this.schedules?.find?.(i=>i.day=='sun'),
      };
    },
    isAllDefaulted(){
      return [this.days.mon?.setting_type,
      this.days.tue?.setting_type,
      this.days.wed?.setting_type,
      this.days.thu?.setting_type,
      this.days.fri?.setting_type,
      this.days.sat?.setting_type,
      this.days.sun?.setting_type].every(i=>i=='default')
    },
    forwardSetting(){
      return {
        extension_name: this.ofh_default_setting?.forward_name ?? '',
        forward_note: this.ofh_default_setting?.extension_type || 'none',
        forward_number: this.ofh_default_setting?.forward_to ?? '',
        number: this.ofh_default_setting?.forward_to ?? '',
      }
    },
  },
  methods: {
    opendefault(){
      let vm = this
      vm.$emit('interface',{
        from: 'ofh',
        event: 'open_default_out_of_hours',
        payload: {
          voipaccount: vm.voipaccount,
          updated(){
            vm.fetchDefaultSetting()
          },
        },
      })
    },
    openday(){
      let vm = this
      vm.$emit('interface',{
        from: 'ofh',
        event: 'open_days_out_of_hours',
        payload: {
          voipaccount: vm.voipaccount,
          updated(){
            // console.log('run')
            vm.fetchSchedule()
          },
        },
      })
    },
  },
}
</script>

<style>

</style>