<template> 
  <modal
    class="right_side_popup m-w-600 addRemove_userModal bigSquareCheckbox min-width-50percent-modal RedirectToModal"
    width="50%"
    height="auto"
    :scrollable="true"
    :name="modalName"
    @before-open="onBeforeOpen"
    @before-close="onBeforeClose"
  >
    <div class="dialer-assign-to md-pad-bottom dialer-edit">
      <div v-if="$store.getters.getIsMobile" class="dialer-edit-header flex-column">
        <div class="d-flex w-100 justify-content-between align-items-center mb-2">
          <h2 class="dialer-edit-title">Redirect to</h2>
          <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
        </div>
        <div class="dialer-edit-actions d-flex justify-content-end w-100">
          <button class="newDoneButton" @click="updateSelectedAccount()">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart">Done</span>
          </button>
        </div>
      </div>
      <div v-else class="dialer-edit-header">
        <div>
          <h2 class="dialer-settings-title newer">
            <vb-svg @click="$modal.hide(modalName)" class="cursor_pointer_no_hover" name="leftLongArrow-icon" width="20" height="12" viewBox="0 0 30 16" stroke-width="1" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" />
            Redirect to
          </h2>
        </div>
        <div class="dialer-edit-actions d-flex">
          <!-- <button class="newCloseButton mr-8px" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </button> -->
          <button class="newDoneButton" @click="updateSelectedAccount()">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart">Done</span>
          </button>
        </div>
      </div>

      <!-- <div v-if="conditions.select_type" class="w-100">
        <div class="latestGreyBox-9-9-2023 mt-20px">
          <div class="whiteBoxWithBorderInsideGreyBox tabs">
            <div v-for="(tab, index) in tabs" :key="index" @click="selected.tab = tab;conditions.select_type=false" class="whiteBoxWithBorderInsideGreyBox-tabs-item">
              <vb-icon :icon="tab.icon" height="63px" width="63px" class="mr-20px" />
              <div class="latestGreyBox-heading-main">{{ tab.text }}</div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="TabsDesignWithIcon-container mt-20px">
        <div class="TabsDesignWithIcon-item" v-for="allTab in tabs" :key="allTab.id" @click="selected.tab = allTab" :class="{'active': selected.tab.value == allTab.value,'mwd-168px' : allTab.value == 'balance'}">
          <vb-icon :icon="allTab.icon" height="38px" width="38px" />
          <span class="TabsDesignWithIcon-item-textPart">{{allTab.text}}</span>
        </div>
      </div>
      <!-- <template v-else> -->
        <div class="d-flex align-items-center justify-content-between w-100 mt-20px">
          <!-- <div @click="conditions.select_type=true" class="cursor_pointer d-flex align-items-center mt-0 latestGreyBox-descriptionText">
            <b-icon icon="chevron-left" class="mr-3" />
            Back
          </div> -->
          <div class="latestGreyBox-heading-main-24px">{{selected.tab.text}}</div>
          <div class="w-50 whiteBGinputWithGreyRoundedBorder">
            <b-input type="text" :placeholder="`Search ${selected.tab.placeholder}`" v-model="filter.list.search" />
          </div>
        </div>
        <div class="latestGreyBox-9-9-2023 mt-20px">
          <!-- <vb-loading class="my-2" v-if="api.extensions.send" slot="loading" /> -->
          <template v-if="api.extensions.send">
            <div class="whiteBoxWithBorderInsideGreyBox">
              <div v-for="n in 4" :key="n.id" class="whiteBoxWithBorderInsideGreyBox-row">
                <div class="d-flex align-items-center">
                  <div class="latestShimmerDesign mr-2" style="height:45px;width:45px"></div>
                  <div class="d-flex flex-column">
                    <div class="latestShimmerDesign" style="height:21px;width:100px"></div>
                    <div class="latestShimmerDesign mt-1" style="height:21px;width:70%"></div>
                  </div>
                </div>
                <div class="latestShimmerDesign mr-2" style="height:24px;width:24px"></div>
              </div>
            </div>
          </template>
          <div v-else class="whiteBoxWithBorderInsideGreyBox">
            <div v-for="extension in filterList" :key="extension.accountcode" class="whiteBoxWithBorderInsideGreyBox-row min-height-none">
              <b-form-checkbox
                :id="'checkbox-' + extension.accountcode"
                @change="selected.extension=$event?extension.accountcode:''"
                name="telephoneextension"
                class="dialer_full_width_checkbox circle_btn_checkbox circle_btn_onRight p-0 makeCheckBoxesSquare less-padding leastPadding noPYinLabel"
                :checked="selected.extension==extension.accountcode || selected.extension==extension.extn"
              >
                <Info :id="extension.accountcode" :is_blf="false" />
                <!-- {{extn }} {{ extension.extn}} {{extnType}} -->
              </b-form-checkbox>
            </div>
            <vb-no-record class="my-5" v-if="filterList.length==0" :text="api.extensions.send?'':'There is no record'" :design="3">
              <!-- <vb-loading class="my-2" v-if="api.extensions.send" slot="loading" /> -->
            </vb-no-record>
          </div>
        </div>
      <!-- </template> -->











      <!--<div class="dialer-tabs">
        <div class="dialer-tab-headers">
          <div class="dialer-tab text-capitalize" :class="{selected: selected.tab.value === tab.value}" v-for="tab in tabs" :key="tab.value" @click="selected.tab = tab">{{ tab.text }}</div>
        </div>
        <div class="dialer-tab-content">
          <div class="w-100 dialer-input-field input-consistent-inner-shadows md-mar-bottom">
            <b-input type="text" :placeholder="`Search ${selected.tab.text}`" v-model="filter.list.search" />
          </div>
          <ul class="list-unstyled dialer-asssignee-list">
            <div v-for="extension in filterList" :key="extension.accountcode"> 
              <li class="dialer-assignee cursor_auto">
                <b-form-checkbox
                  :id="'checkbox-' + extension.accountcode"
                  @change="selected.extension=$event?extension.accountcode:''"
                  name="telephoneextension"
                  class="dialer_full_width_checkbox circle_btn_checkbox circle_btn_onRight fix makeCheckBoxesSquare"
                  :checked="selected.extension==extension.accountcode || selected.extension==extension.extn"
                >
                  <Info :id="extension.accountcode" />
                  <!~~ {{extn }} {{ extension.extn}} {{extnType}}  ~~>
                </b-form-checkbox>
              </li>
            </div>
            <vb-no-record v-if="filterList.length==0" :text="api.extensions.send?'':'There is no Record'" :design="3">
              <vb-loading v-if="api.extensions.send" slot="loading" />
            </vb-no-record>
          </ul>
        </div>
      </div>-->
    </div>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { VOIP_API } from "../../utils";
import Info from '../Lists/Info.vue'
let tabs = {
  team: {
    text: 'Team',
    placeholder: 'team',
    value: 1,
    // icon: 'latest-assigntoTabsIcons-teams'
    icon: 'squared-multipleUsers-icon'
  },
  ivr: {
    // text: 'Basic IVR',
    text: 'IVR',
    placeholder: 'ivr',
    value: 2,
    // icon: 'latest-assigntoTabsIcons-ivr'
    icon: 'squared-ivr-icon'
  },
  user: {
    text: 'User',
    placeholder: 'user',
    value: 3,
    // icon: 'latest-assigntoTabsIcons-user'
    icon: 'squared-singleUser-icon'
  },
  queue: {
    text: 'Call queue',
    placeholder: 'call queue',
    value: 4,
    // icon: 'latest-assigntoTabsIcons-queue'
    icon: 'squared-callQueue-icon'
  },
}
export default {
  name: "ScheduleAssignModal",
  components: {
    Info,
  },
  props: {
    modalName: {
      type: String,
      default: 'ScheduleAssignModal'
    },
    account: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: "",
    },
    extnType: {
      type: String,
      default: "",
    },
    extn: {
      type: String,
      default: "",
    },
    disableCurrentUser: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      data: {
        type: null,
      },
      api: {
        extensions: this.$helperFunction.apiInstance(),
      },
      selected: {
        tab: tabs.user,
        extension: '',
      },
      response: {
        accounts: {},
        extensions: {},
        extn:''
      },
      filter: {
        list: {
          search: '',
        },
      },
      
      conditions: {
        select_type: true,
      },
      extra_data: {},
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
    ]),
    useraccountcode(){ return this.account || this.getCurrentUser.account },
    filterList(){
      const search = this.filter.list.search.trim().toLowerCase()
      const tab = this.selected.tab
      const accounts = this.response.accounts
      let account = ''
      let useraccountcode = this.useraccountcode
      if(tab.value==tabs.team.value) account = accounts.TEAM
      if(tab.value==tabs.ivr.value) account = accounts.BASICIVR
      if(tab.value==tabs.user.value) account = accounts.USER
      if(tab.value==tabs.queue.value) account = accounts.QUEUE
      return Object.values(this.response.extensions).filter(extension=>(this.disableCurrentUser ? extension.accountcode!=useraccountcode : true) && extension.account === account && `${extension.accountname} ${extension.extn}`.trim().toLowerCase().includes(search))
    },
    tabs(){ return tabs },

    
  },
  filters: {
    firstCharacter: function (value) {
      if (!value) return "-";
      return value.charAt(0).toUpperCase();
    },
  },
  methods: {
    onBeforeOpen(event) {
      this.data.type = event?.params?.type ?? '';
      this.selected.extension = event?.params?.account ?? this.extn ?? '';
      this.extra_data = event?.params?.extra_data ?? {};
      this.fetchExtensions();
      this.selectedTabAndService()
    },
    onBeforeClose() {
      this.data.type = null;
      this.api.extensions?.reset?.();
      this.selected.extension = '';
      this.selected.tab = tabs.user.value;
      this.conditions.select_type=true
      this.response.accounts = '';
      this.response.extensions = '';
      this.filter.list.search = '';
      this.extra_data={}

      // this.extnType = '';
    },
    fetchExtensions() {
      let vm = this
      vm.api.extensions.send = true;
      VOIP_API.endpoints.telephone_number.extensions()
      .then(({ data: res }) => {
        vm.response.extensions = res?.data ?? {};
        vm.response.accounts = res?.accounts ?? {};
        vm.response.extn = res?.extn;
      })
      .finally(() => {
        vm.api.extensions.send = false;
      });
    },
    updateSelectedAccount() {
      if(!this.selected.extension) return;
      let extension = Object.values(this.response.extensions).find((value) => value.accountcode == this.selected.extension);
      this.$emit("interface", {
        account: this.selected.extension,
        accountcode: extension.accountcode,
        accountname: extension?.accountname ?? 'None',
        extn: extension.extn,
        type: this.data.type,
        account_type: extension.account,
        extra_data: this.extra_data,
      });
      this.$modal.hide(this.modalName);
    },

    selectedTabAndService(){
      // let name = this.name;
      let extnType = this.extnType;
      // this.selected.extension = name
      if(extnType == "BASICIVR"){
        this.selected.tab = this.tabs.ivr
      } else if(extnType == "TEAM" || extnType == "RINGGROUP"){
        this.selected.tab = this.tabs.team
      } else if(extnType == "USER"){
        this.selected.tab = this.tabs.user
      } else if(extnType == "QUEUE"){
        this.selected.tab = this.tabs.queue
      } else {
        this.selected.tab = this.tabs.user
      }
      // console.log(name + "+" + this.selected.tab);
    }
    
  },
  // created() {
  //   this.selectedTabAndService()
  // }
};
</script>

<style lang="scss">
.slideToRigth-enter,
.slideToRigth-leave-active {
  right: -100%;
}
</style>
