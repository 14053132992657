<template>
  <div>
    Welcome Greeting
    <div class="d-flex justify-content-between align-items-center w-100 mt-3">
      <vb-audio-player :src="ivr | ivrSound" :design="'minimum'" class="audioForMobile"/>
      <b-icon style="position: relative;" @click="edit()" icon="pencil-fill" variant="primary" class="cursor_pointer ml-auto" />
    </div>
  </div>
</template>

<script>
import { welcomgreetingsrc } from '@/filter'
import { mapGetters } from 'vuex'
export default {
  name: 'WelcomeGreetingNode',
  props: {
    voipaccount: {
      type: String,
      default: ''
    },
  },
  computed: {
    ...mapGetters({
      getVoipIVRSAlises: 'getVoipIVRSAlises'
    }),
    ivr(){ return this.getVoipIVRSAlises[this.voipaccount] },
  },
  filters: {
    ivrSound: welcomgreetingsrc,
    
  },
  methods: {
    edit(){
      this.$emit('interface',{
        from: 'welcome_greeting',
        event: 'change_welcome_greeting',
      })
    },
  },
}
</script>

<style>

</style>