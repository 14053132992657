<template>
  <modal 
    class="dialer_animation right_side_popup m-w-800 ChangePlaylistMusicOnHoldModal" 
    width="80%" 
    height="auto" 
    :scrollable="true" 
    :name="modalName" 
    @before-open="onBeforeOpen($event)"
    @before-close="onBeforeClose()"
  >
    <!-- {{section}} -->
    <div class="dialer-assign-to dialer-edit">
      <div v-if="getIsMobile" class="dialer-edit-header flex-column">
        <div class="d-flex w-100 justify-content-between align-items-center mb-2">
          <h2 class="dialer-edit-title">Playlist files</h2>
          <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="api.set_default.send ? '' : $modal.hide(modalName)" />
        </div>
      </div>
      <div v-else class="dialer-edit-header">
        <div>
          <h2 class="dialer-edit-title">Playlist files</h2>
        </div>
        <div class="dialer-edit-actions">
          <!-- <button class="dialer-button dialer-button-delete" :disabled="api.set_default.send" @click="$modal.hide(modalName)">
            <vb-loading class="mr-0" v-if="api.set_default.send" type="something" />
            <template v-else>Close</template>
          </button> -->
          <button class="newDoneButton" :disabled="api.set_default.send" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart position-relative d-flex justify-content-center">
              <vb-spinner v-if="api.set_default.send" />
              <template v-else>Done</template>
            </span>
          </button>
        </div>
      </div>
      <vb-table 
        class="latestTableDesign-withBlackBorders-again hasSoundFiles" 
        textNoRecord=" " 
        :isListEmpty="playlists.length==0" 
        :listLength="playlists.length" 
        :loading="api.playlists.send"
      >
        <template slot="header">
          <tr>
            <th class="dialer-has-sort wd-30">Playlist name</th>
            <th class="dialer-has-sort w-50">Files</th>
          </tr>
        </template>
        <template #body="{ start, end }">
          <MOHItem 
            v-for="(playlist, index) in playlists"
            :key="playlist.id"
            :playlist="playlist"
            v-show="index >= start && index <= end"
          >
            <template #right-end>
              <b-button :disabled="api.set_default.send" class="p-0" variant="link" @click="selected.playlist_id=playlist.id;setDefault()">
                <!-- <b-icon v-if="selected.playlist_id==playlist.id" icon="check-circle-fill" />
                <b-icon v-else icon="circle" /> -->
                <div :class="`actLikeSquareRadio checkboxVersion ${selected.playlist_id==playlist.id ? 'checked' : ''}`"></div>
              </b-button>
            </template>
          </MOHItem>
        </template>
        <template #loading>
          <tr v-for="n in 5" :key="n">
            <td>
              <div class="latestShimmerDesign" style="height:20px;width:-webkit-fill-available;"></div>
            </td>
            <td>
              <div class="d-flex align-items-center w-fit-content">
                <div class="latestShimmerDesign flex-1" style="height:64px;width:250px"></div>
                <div class="latestShimmerDesign flex-1 ml-2" style="height:21px;width:40px"></div>
              </div>
            </td>
            <td>
              <div class="d-flex align-items-center">
                <div class="latestShimmerDesign" style="height:20px;width:-webkit-fill-available;"></div>
              </div>
            </td>
            <td class="dialer-col-right">
              <div class="latestShimmerDesign ml-auto" style="height:48px;width:48px;"></div>
            </td>
          </tr>
        </template>
      </vb-table>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { VOIP_API } from "../../utils";
import MOHItem from '../Lists/MOHItem.vue';

export default {
  name: "ChangePlaylistMusicOnHoldModal",
  components: { 
    MOHItem, 
  },
  props: {
    modalName: {
      type: String,
      default: 'ChangePlaylistMusicOnHold',
    },
  },
  data() {
    return {
      api: {
        playlists: this.$helperFunction.apiInstance(),
        set_default: this.$helperFunction.apiInstance(),
      },
      selected: {
        playlist_id: '',
      },
      response: {
        playlists: {},
      },
      data: {
        accountcode: ''
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getIsMobile',
      'getCurrentUser',
    ]),
    isOrganization(){ return this.getCurrentUser.default_accountcode==this.data.accountcode },
    playlists(){ return this.response.playlists?.playlists?.data ?? [] },
  },
  methods: {
    onBeforeOpen(event){
      this.selected.playlist_id=event?.params?.id ?? ''
      this.data.accountcode=event?.params?.account ?? ''
      this.fetchPlayListFiles();
    },
    onBeforeClose(){
      this.api.playlists.reset()
      this.api.set_default.reset()
      this.response.playlists={}
      this.selected.playlist_id=''
    },
    async setDefault() {
      if(this.api.set_default.send) return;
      try {
        this.api.set_default.send=true
        await VOIP_API.endpoints.music_on_hold.updateplaylist(this.selected.playlist_id, {
          flag: "changedefault",
          type: this.isOrganization?'admin':"user",
          fid: 12,
          account: this.data.accountcode,
        })
        this.$emit('updated')
        this.appNotify({
          message: 'Successfully updated',
          type: 'success',
        })
        
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.set_default.send=false
      }
    },
    async fetchPlayListFiles() {
      if (this.api.playlists.send) return;
      try {
        this.api.playlists.send = true;
        const { data } = await VOIP_API.endpoints.music_on_hold.playlistfiles(this.getCurrentUser.account,{
          account: this.data.accountcode,
          accountcode: this.data.accountcode,
        });
        this.response.playlists=data
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.playlists.send = false;
      }
    },
  }
};
</script>

<style>
.buttons {
  margin-top: 35px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
