var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sidebar left-sidebar position-relative"
  }, [_c('div', {
    staticClass: "dontDisplayInMobile logo position-absolute w-100 text-center py-3"
  }, [_c('a', {
    staticClass: "d-inline-block position-relative"
  }, [_c('img', {
    staticStyle: {
      "cursor": "default"
    },
    attrs: {
      "src": require('@/assets/images/Top-icon-for-dialing/latest-VB-Logo.svg'),
      "alt": "VOIP"
    }
  })])]), _vm.$store.state.css_break_points.b1 ? [!_vm.$store.getters.getIsMobile ? [_c('nav', {
    staticClass: "dontDisplayInMobile nav nav-menu position-relative text-center"
  }, [_c('ul', {
    staticClass: "list-unstyled"
  }, [_c('li', {
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('homeCopy');
      }
    }
  }, [_c('a', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover",
      value: 'Dialer',
      expression: "'Dialer'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "d-flex align-items-center justify-content-center",
    class: {
      'router-link-exact-active': _vm.activeTab === 'homeCopy'
    },
    attrs: {
      "id": "tooltip-Right-Side-Bar-Dashboard"
    }
  }, [_c('vb-svg', {
    staticClass: "sidebar_icon",
    class: {
      active: _vm.activeTab === 'homeCopy'
    },
    attrs: {
      "id": "dialer-callIcon",
      "name": "call-monitoring-icon",
      "width": "50",
      "height": "50",
      "viewBox": "0 0 64 64",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })], 1)]), !_vm.$store.getters.isIntegration ? _c('li', {
    on: {
      "click": function ($event) {
        !_vm.getUserPermissions.chat ? '' : _vm.changeActiveTab('chat');
      }
    }
  }, [_c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "d-flex align-items-center justify-content-center",
    class: {
      'router-link-exact-active': _vm.activeTab === 'chat'
    },
    attrs: {
      "id": "tooltip-Right-Side-Bar-Chat",
      "title": _vm.getUserPermissions.chat ? '' : 'Your package does not support this feature'
    }
  }, [_c('vb-svg', {
    staticClass: "sidebar_icon",
    class: {
      active: _vm.activeTab === 'chat'
    },
    attrs: {
      "name": "chat-icon",
      "width": "50",
      "height": "50",
      "viewBox": "0 0 64 64",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _vm.getTotalUnread ? _c('div', {
    staticClass: "unreadMessageNumber"
  }, [_vm._v(_vm._s(_vm.getTotalUnread > 99 ? '+99' : _vm.getTotalUnread))]) : _vm._e()], 1), _c('b-popover', {
    attrs: {
      "target": "tooltip-Right-Side-Bar-Chat",
      "content": "Chat",
      "triggers": "hover"
    }
  })], 1) : _vm._e(), !_vm.$store.getters.isIntegration ? _c('li', {
    attrs: {
      "app-tour-web-dashboard-meeting": ""
    },
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('todo');
      }
    }
  }, [_c('a', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover",
      value: 'Task hub',
      expression: "'Task hub'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "d-flex align-items-center justify-content-center",
    class: {
      'router-link-exact-active': _vm.activeTab === 'todo'
    },
    attrs: {
      "id": "tooltip-Right-Side-Bar-Meeting"
    }
  }, [_c('vb-svg', {
    staticClass: "sidebar_icon meeting_icon",
    class: {
      active: _vm.activeTab === 'todo'
    },
    attrs: {
      "name": "todo-icon",
      "width": "50",
      "height": "50",
      "viewBox": "0 0 57.795 57.794",
      "stroke-width": "0",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })], 1)]) : _vm._e()]), _c('ul', {
    staticClass: "list-unstyled"
  }, [_c('li', [_c('b-dropdown', {
    staticClass: "VerticalAppDrawerDD",
    attrs: {
      "dropright": "",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('vb-icon', {
          staticClass: "leftSideBar-hamBurger-icon",
          attrs: {
            "id": "tooltip-Right-Side-Bar-burger",
            "icon": "leftSideBar-hamBurger-icon",
            "width": "50",
            "height": "50"
          }
        })];
      },
      proxy: true
    }], null, false, 1159755175)
  }, [!_vm.$store.getters.isIntegration ? _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('schedule');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "newScheduling-sidebar-icon",
      "height": "50px",
      "width": "50px"
    }
  }), _c('span', [_vm._v("Members")])], 1) : _vm._e(), !_vm.$store.getters.isIntegration ? _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        !_vm.getUserPermissions.analytics ? '' : _vm.changeActiveTab('analytics');
      }
    }
  }, [_c('vb-svg', {
    class: {
      active: _vm.activeTab === 'analytics'
    },
    attrs: {
      "name": "analytics-icon",
      "width": "50",
      "height": "50",
      "viewBox": "0 0 64 64",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v("Analytics")])], 1) : _vm._e(), !_vm.$store.getters.isIntegration && _vm.$store.getters.getCurrentUser.administrator_account ? _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('callDashboard');
      }
    }
  }, [_c('vb-svg', {
    class: {
      active: _vm.activeTab === 'callDashboard'
    },
    attrs: {
      "name": "call-monitoring-icon",
      "width": "50",
      "height": "50",
      "viewBox": "0 0 64 64",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v("Monitor Dashboard")])], 1) : _vm._e()], 1)], 1), !_vm.$store.getters.isIntegration ? _c('li', {
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('settings');
      }
    }
  }, [_c('a', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover",
      value: 'Setting',
      expression: "'Setting'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "d-flex align-items-center justify-content-center",
    class: {
      'router-link-exact-active': _vm.activeTab === 'settings'
    },
    attrs: {
      "id": "tooltip-Right-Side-Bar-Setting"
    }
  }, [_c('vb-svg', {
    staticClass: "sidebar_icon",
    class: {
      active: _vm.activeTab === 'settings'
    },
    attrs: {
      "name": "setting-icon",
      "width": "50",
      "height": "50",
      "viewBox": "0 0 64 64",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })], 1)]) : _vm._e()])])] : _vm._e()] : _c('nav', {
    staticClass: "dontDisplayInMobile nav nav-menu position-relative text-center"
  }, [_c('ul', {
    staticClass: "list-unstyled"
  }, [_vm.getIsDialerApplication ? [_c('li', {
    attrs: {
      "app-tour-web-dashboard-dialer": ""
    },
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('homeCopy');
      }
    }
  }, [_c('a', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover",
      value: 'Dialer',
      expression: "'Dialer'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "d-flex align-items-center justify-content-center",
    class: {
      'router-link-exact-active': _vm.activeTab === 'homeCopy'
    },
    attrs: {
      "id": "tooltip-Right-Side-Bar-Dashboard"
    }
  }, [_c('vb-svg', {
    staticClass: "sidebar_icon",
    class: {
      active: _vm.activeTab === 'homeCopy'
    },
    attrs: {
      "id": "dialer-callIcon",
      "name": "call-monitoring-icon",
      "width": "50",
      "height": "50",
      "viewBox": "0 0 64 64",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })], 1)]), !_vm.$store.getters.isIntegration ? _c('li', {
    attrs: {
      "app-tour-web-dashboard-chat": ""
    },
    on: {
      "click": function ($event) {
        !_vm.getUserPermissions.chat ? '' : _vm.changeActiveTab('chat');
      }
    }
  }, [_c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "d-flex align-items-center justify-content-center",
    class: {
      'router-link-exact-active': _vm.activeTab === 'chat'
    },
    attrs: {
      "id": "tooltip-Right-Side-Bar-Chat",
      "title": _vm.getUserPermissions.chat ? '' : 'Your package does not support this feature'
    }
  }, [_c('vb-svg', {
    staticClass: "sidebar_icon",
    class: {
      active: _vm.activeTab === 'chat'
    },
    attrs: {
      "name": "chat-icon",
      "width": "50",
      "height": "50",
      "viewBox": "0 0 64 64",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _vm.getTotalUnread ? _c('div', {
    staticClass: "unreadMessageNumber"
  }, [_vm._v(_vm._s(_vm.getTotalUnread > 99 ? '+99' : _vm.getTotalUnread))]) : _vm._e()], 1), _c('b-popover', {
    attrs: {
      "target": "tooltip-Right-Side-Bar-Chat",
      "content": "Chats",
      "triggers": "hover"
    }
  })], 1) : _vm._e(), !_vm.$store.getters.isIntegration ? _c('li', {
    attrs: {
      "app-tour-web-dashboard-meeting": ""
    },
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('schedule');
      }
    }
  }, [_c('a', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover",
      value: 'Members',
      expression: "'Members'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "d-flex align-items-center justify-content-center",
    class: {
      'router-link-exact-active': _vm.activeTab === 'schedule'
    },
    attrs: {
      "id": "tooltip-Right-Side-Bar-Meeting"
    }
  }, [_c('vb-icon', {
    staticClass: "newScheduling-sidebar-icon",
    attrs: {
      "icon": "newScheduling-sidebar-icon",
      "height": "50px",
      "width": "50px"
    }
  })], 1)]) : _vm._e(), !_vm.$store.getters.isIntegration ? _c('li', {
    attrs: {
      "app-tour-web-dashboard-meeting": ""
    },
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('todo');
      }
    }
  }, [_c('a', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover",
      value: 'Task hub',
      expression: "'Task hub'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "d-flex align-items-center justify-content-center",
    class: {
      'router-link-exact-active': _vm.activeTab === 'todo'
    },
    attrs: {
      "id": "tooltip-Right-Side-Bar-Meeting"
    }
  }, [_c('vb-svg', {
    staticClass: "sidebar_icon meeting_icon",
    class: {
      active: _vm.activeTab === 'todo'
    },
    attrs: {
      "name": "todo-icon",
      "width": "50",
      "height": "50",
      "viewBox": "0 0 57.795 57.794",
      "stroke-width": "0",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })], 1)]) : _vm._e()] : _vm.getIsHRMApplication ? [_c('li', {
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('HrmHomePage');
      }
    }
  }, [_c('a', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover",
      value: 'Home',
      expression: "'Home'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "d-flex align-items-center justify-content-center",
    class: {
      'router-link-exact-active': _vm.activeTab === 'HrmHomePage'
    }
  }, [_c('vb-icon', {
    staticClass: "sidebar_icon home-leftSideBar-hrmIcon",
    class: {
      active: _vm.activeTab === 'HrmHomePage'
    },
    attrs: {
      "icon": "home-leftSideBar-hrmIcon",
      "width": "50",
      "height": "50"
    }
  })], 1)]), _c('li', {
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('hrm-calender');
      }
    }
  }, [_c('a', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover",
      value: 'Calender',
      expression: "'Calender'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "d-flex align-items-center justify-content-center",
    class: {
      'router-link-exact-active': _vm.activeTab === 'hrm-calender'
    }
  }, [_c('vb-icon', {
    staticClass: "sidebar_icon home-leftSideBar-hrmIcon",
    class: {
      active: _vm.activeTab === 'hrm-calender'
    },
    attrs: {
      "icon": "calender-leftSideBar-hrmIcon",
      "width": "50",
      "height": "50"
    }
  })], 1)]), _c('li', {
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('hrm-home');
      }
    }
  }, [_c('a', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover",
      value: 'Users',
      expression: "'Users'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "d-flex align-items-center justify-content-center",
    class: {
      'router-link-exact-active': _vm.activeTab === 'hrm-home'
    }
  }, [_c('vb-icon', {
    staticClass: "sidebar_icon home-leftSideBar-hrmIcon",
    class: {
      active: _vm.activeTab === 'hrm-home'
    },
    attrs: {
      "icon": "user-leftSideBar-hrmIcon",
      "width": "50",
      "height": "50"
    }
  })], 1)])] : _vm.getIsPamarlyApplication ? [_c('li', {
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('pamarly-home');
      }
    }
  }, [_c('a', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover",
      value: 'Home',
      expression: "'Home'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "d-flex align-items-center justify-content-center",
    class: {
      'router-link-exact-active': _vm.activeTab === 'pamarly-home'
    }
  }, [_c('vb-icon', {
    staticClass: "sidebar_icon home-leftSideBar-hrmIcon",
    class: {
      active: _vm.activeTab === 'pamarly-home'
    },
    attrs: {
      "icon": "home-leftSideBar-hrmIcon",
      "width": "50",
      "height": "50"
    }
  })], 1)]), _c('li', {
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('pamarly-teams');
      }
    }
  }, [_c('a', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover",
      value: 'Teams',
      expression: "'Teams'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "d-flex align-items-center justify-content-center",
    class: {
      'router-link-exact-active': _vm.activeTab === 'pamarly-teams'
    }
  }, [_c('vb-icon', {
    staticClass: "sidebar_icon home-leftSideBar-hrmIcon",
    class: {
      active: _vm.activeTab === 'pamarly-teams'
    },
    attrs: {
      "icon": "home-leftSideBar-hrmIcon",
      "width": "50",
      "height": "50"
    }
  })], 1)]), _c('li', {
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('pamarly-resources');
      }
    }
  }, [_c('a', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover",
      value: 'Resources',
      expression: "'Resources'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "d-flex align-items-center justify-content-center",
    class: {
      'router-link-exact-active': _vm.activeTab === 'pamarly-resources'
    }
  }, [_c('vb-icon', {
    staticClass: "sidebar_icon home-leftSideBar-hrmIcon",
    class: {
      active: _vm.activeTab === 'pamarly-resources'
    },
    attrs: {
      "icon": "home-leftSideBar-hrmIcon",
      "width": "50",
      "height": "50"
    }
  })], 1)]), _c('li', {
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('pamarly-permissions');
      }
    }
  }, [_c('a', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover",
      value: 'Permissions',
      expression: "'Permissions'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "d-flex align-items-center justify-content-center",
    class: {
      'router-link-exact-active': _vm.activeTab === 'pamarly-permissions'
    }
  }, [_c('vb-icon', {
    staticClass: "sidebar_icon home-leftSideBar-hrmIcon",
    class: {
      active: _vm.activeTab === 'pamarly-permissions'
    },
    attrs: {
      "icon": "home-leftSideBar-hrmIcon",
      "width": "50",
      "height": "50"
    }
  })], 1)]), _c('li', {
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('pamarly-folder');
      }
    }
  }, [_c('a', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover",
      value: 'Folders',
      expression: "'Folders'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "d-flex align-items-center justify-content-center",
    class: {
      'router-link-exact-active': _vm.activeTab === 'pamarly-folder'
    }
  }, [_c('vb-icon', {
    staticClass: "sidebar_icon home-leftSideBar-hrmIcon",
    class: {
      active: _vm.activeTab === 'pamarly-folder'
    },
    attrs: {
      "icon": "home-leftSideBar-hrmIcon",
      "width": "50",
      "height": "50"
    }
  })], 1)]), _c('li', {
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('pamarly-deleted-resources');
      }
    }
  }, [_c('a', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover",
      value: 'Deleted Resources',
      expression: "'Deleted Resources'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "d-flex align-items-center justify-content-center",
    class: {
      'router-link-exact-active': _vm.activeTab === 'pamarly-deleted-resources'
    }
  }, [_c('vb-icon', {
    staticClass: "sidebar_icon home-leftSideBar-hrmIcon",
    class: {
      active: _vm.activeTab === 'pamarly-deleted-resources'
    },
    attrs: {
      "icon": "home-leftSideBar-hrmIcon",
      "width": "50",
      "height": "50"
    }
  })], 1)]), _c('li', {
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('pamarly-history');
      }
    }
  }, [_c('a', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover",
      value: 'History',
      expression: "'History'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "d-flex align-items-center justify-content-center",
    class: {
      'router-link-exact-active': _vm.activeTab === 'pamarly-history'
    }
  }, [_c('vb-icon', {
    staticClass: "sidebar_icon home-leftSideBar-hrmIcon",
    class: {
      active: _vm.activeTab === 'pamarly-history'
    },
    attrs: {
      "icon": "home-leftSideBar-hrmIcon",
      "width": "50",
      "height": "50"
    }
  })], 1)])] : _vm.getIsCRMApplication ? [_c('li', {
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('crm-deals');
      }
    }
  }, [_c('a', {
    staticClass: "d-flex align-items-center justify-content-center",
    class: {
      'router-link-exact-active': _vm.activeTab === 'crm-deals'
    }
  }, [_c('vb-icon', {
    staticClass: "sidebar_icon home-leftSideBar-hrmIcon",
    class: {
      active: _vm.activeTab === 'crm-deals'
    },
    attrs: {
      "icon": "crm-deals-icon",
      "width": "50",
      "height": "50"
    }
  }), _vm._m(0)], 1)]), _c('li', {
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('crm-activities');
      }
    }
  }, [_c('a', {
    staticClass: "d-flex align-items-center justify-content-center",
    class: {
      'router-link-exact-active': _vm.activeTab === 'crm-activities'
    }
  }, [_c('vb-icon', {
    staticClass: "sidebar_icon home-leftSideBar-hrmIcon",
    class: {
      active: _vm.activeTab === 'crm-activities'
    },
    attrs: {
      "icon": "crm-Activities-icon",
      "width": "50",
      "height": "50"
    }
  }), _vm._m(1)], 1)]), _c('li', {
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('crm-inbox');
      }
    }
  }, [_c('a', {
    staticClass: "d-flex align-items-center justify-content-center",
    class: {
      'router-link-exact-active': _vm.activeTab === 'crm-inbox'
    }
  }, [_c('vb-icon', {
    staticClass: "sidebar_icon home-leftSideBar-hrmIcon",
    class: {
      active: _vm.activeTab === 'crm-inbox'
    },
    attrs: {
      "icon": "crm-inbox-icon",
      "width": "50",
      "height": "50"
    }
  }), _vm._m(2)], 1)]), _c('li', {
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('crm-documents');
      }
    }
  }, [_c('a', {
    staticClass: "d-flex align-items-center justify-content-center",
    class: {
      'router-link-exact-active': _vm.activeTab === 'crm-documents'
    }
  }, [_c('vb-icon', {
    staticClass: "sidebar_icon home-leftSideBar-hrmIcon",
    class: {
      active: _vm.activeTab === 'crm-documents'
    },
    attrs: {
      "icon": "crm-documents-icon",
      "width": "50",
      "height": "50"
    }
  }), _vm._m(3)], 1)]), _c('li', {
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('crm-contacts');
      }
    }
  }, [_c('a', {
    staticClass: "d-flex align-items-center justify-content-center",
    class: {
      'router-link-exact-active': _vm.activeTab === 'crm-contacts'
    }
  }, [_c('vb-icon', {
    staticClass: "sidebar_icon home-leftSideBar-hrmIcon",
    class: {
      active: _vm.activeTab === 'crm-contacts'
    },
    attrs: {
      "icon": "crm-contacts-icon",
      "width": "50",
      "height": "50"
    }
  }), _vm._m(4)], 1)]), _c('li', {
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('crm-companies');
      }
    }
  }, [_c('a', {
    staticClass: "d-flex align-items-center justify-content-center",
    class: {
      'router-link-exact-active': _vm.activeTab === 'crm-companies'
    }
  }, [_c('vb-icon', {
    staticClass: "sidebar_icon home-leftSideBar-hrmIcon",
    class: {
      active: _vm.activeTab === 'crm-companies'
    },
    attrs: {
      "icon": "crm-company-icon",
      "width": "50",
      "height": "50"
    }
  }), _vm._m(5)], 1)]), _c('li', {
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('crm-insights');
      }
    }
  }, [_c('a', {
    staticClass: "d-flex align-items-center justify-content-center",
    class: {
      'router-link-exact-active': _vm.activeTab === 'crm-insights'
    }
  }, [_c('vb-icon', {
    staticClass: "sidebar_icon home-leftSideBar-hrmIcon",
    class: {
      active: _vm.activeTab === 'crm-insights'
    },
    attrs: {
      "icon": "crm-insights-icon",
      "width": "50",
      "height": "50"
    }
  }), _vm._m(6)], 1)]), _c('li', {
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('crm-products');
      }
    }
  }, [_c('a', {
    staticClass: "d-flex align-items-center justify-content-center",
    class: {
      'router-link-exact-active': _vm.activeTab === 'crm-products'
    }
  }, [_c('vb-icon', {
    staticClass: "sidebar_icon home-leftSideBar-hrmIcon",
    class: {
      active: _vm.activeTab === 'crm-products'
    },
    attrs: {
      "icon": "crm-products-icon",
      "width": "50",
      "height": "50"
    }
  }), _vm._m(7)], 1)]), _c('li', {
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('crm-setting');
      }
    }
  }, [_c('a', {
    staticClass: "d-flex align-items-center justify-content-center",
    class: {
      'router-link-exact-active': _vm.activeTab === 'crm-setting'
    }
  }, [_c('vb-icon', {
    staticClass: "sidebar_icon home-leftSideBar-hrmIcon",
    class: {
      active: _vm.activeTab === 'crm-setting'
    },
    attrs: {
      "icon": "crm-settings-icon",
      "width": "50",
      "height": "50"
    }
  }), _vm._m(8)], 1)])] : _vm._e()], 2), _c('ul', {
    staticClass: "list-unstyled"
  }, [_vm.getIsDialerApplication ? [!_vm.$store.getters.isIntegration ? _c('li', {
    attrs: {
      "app-tour-web-dashboard-analytics": ""
    },
    on: {
      "click": function ($event) {
        !_vm.getUserPermissions.analytics ? '' : _vm.changeActiveTab('analytics');
      }
    }
  }, [_c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "d-flex align-items-center justify-content-center",
    class: {
      'router-link-exact-active': _vm.activeTab === 'analytics'
    },
    attrs: {
      "id": "tooltip-Right-Side-Bar-Analytics",
      "title": _vm.getUserPermissions.analytics ? '' : 'Your package does not support this feature'
    }
  }, [_c('vb-svg', {
    staticClass: "sidebar_icon",
    class: {
      active: _vm.activeTab === 'analytics'
    },
    attrs: {
      "name": "analytics-icon",
      "width": "50",
      "height": "50",
      "viewBox": "0 0 64 64",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })], 1), _c('b-popover', {
    attrs: {
      "target": "tooltip-Right-Side-Bar-Analytics",
      "content": "Analytics",
      "triggers": "hover"
    }
  })], 1) : _vm._e(), !_vm.$store.getters.isIntegration && _vm.$store.getters.getCurrentUser.administrator_account ? _c('li', {
    attrs: {
      "app-tour-web-dashboard-monitor": ""
    },
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('callDashboard');
      }
    }
  }, [_c('a', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover",
      value: 'Monitor Dashboard',
      expression: "'Monitor Dashboard'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "position-relative d-flex align-items-center justify-content-center",
    class: {
      'router-link-exact-active': _vm.activeTab === 'callDashboard'
    },
    attrs: {
      "id": "tooltip-Right-Side-Bar-Call-dashboard"
    }
  }, [_c('vb-svg', {
    staticClass: "sidebar_icon",
    class: {
      active: _vm.activeTab === 'callDashboard'
    },
    attrs: {
      "name": "call-monitoring-icon",
      "width": "50",
      "height": "50",
      "viewBox": "0 0 64 64",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })], 1)]) : _vm._e(), !_vm.$store.getters.isIntegration ? _c('li', {
    attrs: {
      "app-tour-web-dashboard-settings": "",
      "app-tour-step": "6"
    },
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('settings');
      }
    }
  }, [_c('a', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover",
      value: 'Settings',
      expression: "'Settings'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "d-flex align-items-center justify-content-center",
    class: {
      'router-link-exact-active': _vm.activeTab === 'settings'
    },
    attrs: {
      "id": "tooltip-Right-Side-Bar-Setting"
    }
  }, [_c('vb-svg', {
    staticClass: "sidebar_icon",
    class: {
      active: _vm.activeTab === 'settings'
    },
    attrs: {
      "name": "setting-icon",
      "width": "50",
      "height": "50",
      "viewBox": "0 0 64 64",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })], 1)]) : _vm._e()] : _vm._e(), _vm.getIsHRMApplication ? [_c('li', {
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('HrmDocuments');
      }
    }
  }, [_c('a', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover",
      value: 'Documents',
      expression: "'Documents'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "d-flex align-items-center justify-content-center",
    class: {
      'router-link-exact-active': _vm.activeTab === 'HrmDocuments'
    }
  }, [_c('vb-icon', {
    class: {
      active: _vm.activeTab === 'HrmDocuments'
    },
    attrs: {
      "icon": "documents-leftSideBar-hrmIcon",
      "width": "50",
      "height": "50"
    }
  })], 1)]), _c('li', {
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('hrm-reports');
      }
    }
  }, [_c('a', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover",
      value: 'Reports',
      expression: "'Reports'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "d-flex align-items-center justify-content-center",
    class: {
      'router-link-exact-active': _vm.activeTab === 'hrm-reports'
    }
  }, [_c('vb-icon', {
    staticClass: "sidebar_icon home-leftSideBar-hrmIcon",
    class: {
      active: _vm.activeTab === 'hrm-reports'
    },
    attrs: {
      "icon": "ReportsSideBar-hrmIcon",
      "width": "50",
      "height": "50"
    }
  })], 1)]), _c('li', {
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('hrm-settingNew');
      }
    }
  }, [_c('a', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover",
      value: 'Settings',
      expression: "'Settings'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "d-flex align-items-center justify-content-center",
    class: {
      'router-link-exact-active': _vm.activeTab === 'hrm-settingNew'
    }
  }, [_c('vb-svg', {
    staticClass: "sidebar_icon",
    class: {
      active: _vm.activeTab === 'hrm-settingNew'
    },
    attrs: {
      "name": "setting-icon",
      "width": "50",
      "height": "50",
      "viewBox": "0 0 64 64",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })], 1)])] : _vm._e(), _vm.isDevelopment ? _c('li', {
    staticClass: "mb-2"
  }, [_c('b-dropdown', {
    staticClass: "ApplicationsDropDown",
    attrs: {
      "dropleft": "",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('vb-icon', {
          staticClass: "speedDial-icon rightSidebar_icon",
          attrs: {
            "icon": "appDrawer-icon",
            "width": "50",
            "height": "50"
          }
        })];
      },
      proxy: true
    }], null, false, 2113505817)
  }, [_c('b-dropdown-item', [_c('div', {
    staticClass: "applicationsContainer"
  }, [_c('div', {
    staticClass: "applicationsItself",
    on: {
      "click": function ($event) {
        return _vm.$store.commit('setApplication', 'hrm');
      }
    }
  }, [_c('vb-icon', {
    staticClass: "ApplicationIcon",
    attrs: {
      "icon": "speedDial-icon",
      "width": "50",
      "height": "50"
    }
  }), _c('div', {
    staticClass: "ApplicationText text-wrap"
  }, [_vm._v("HRM")])], 1), _c('div', {
    staticClass: "applicationsItself",
    on: {
      "click": function ($event) {
        return _vm.$store.commit('setApplication', 'dialer');
      }
    }
  }, [_c('vb-icon', {
    staticClass: "ApplicationIcon",
    attrs: {
      "icon": "speedDial-icon",
      "width": "50",
      "height": "50"
    }
  }), _c('div', {
    staticClass: "ApplicationText"
  }, [_vm._v("Dialer")])], 1), _c('div', {
    staticClass: "applicationsItself",
    on: {
      "click": function ($event) {
        return _vm.$store.commit('setApplication', 'pamarly');
      }
    }
  }, [_c('vb-icon', {
    staticClass: "ApplicationIcon",
    attrs: {
      "icon": "speedDial-icon",
      "width": "50",
      "height": "50"
    }
  }), _c('div', {
    staticClass: "ApplicationText"
  }, [_vm._v("Pamarly")])], 1), _c('div', {
    staticClass: "applicationsItself",
    on: {
      "click": function ($event) {
        return _vm.$store.commit('setApplication', 'crm');
      }
    }
  }, [_c('vb-icon', {
    staticClass: "ApplicationIcon",
    attrs: {
      "icon": "speedDial-icon",
      "width": "50",
      "height": "50"
    }
  }), _c('div', {
    staticClass: "ApplicationText"
  }, [_vm._v("CRM")])], 1), !_vm.$store.getters.isIntegration ? _c('div', {
    staticClass: "applicationsItself",
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('schedule');
      }
    }
  }, [_c('vb-svg', {
    staticClass: "sidebar_icon meeting_icon",
    class: {
      active: _vm.activeTab === 'schedule'
    },
    attrs: {
      "name": "meeting-icon",
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "stroke-width": "0",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('div', {
    staticClass: "ApplicationText"
  }, [_vm._v("Meetings")])], 1) : _vm._e()])])], 1)], 1) : _vm._e()], 2)]), !_vm.$store.getters.getIsMobile ? _c('UserProfile') : _vm._e(), _c('div', {
    staticClass: "display-only-mobile"
  }, [_c('div', {
    staticClass: "bottom-icons-container"
  }, [!_vm.$store.getters.isIntegration ? _c('vb-icon', {
    staticClass: "mobile-monitor-dashboard TODO-mobile-bottomBarIcon",
    class: {
      active: _vm.activeTab === 'todo'
    },
    attrs: {
      "icon": "mobile-bottomBar-TODO-likeAirCall-icon",
      "width": "40",
      "height": "40"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('selectedTab', 'todo');
      }
    }
  }) : _vm._e(), !_vm.$store.getters.isIntegration ? _c('vb-icon', {
    staticClass: "mobile-monitor-dashboard",
    class: {
      active: _vm.activeTab === 'selectContext'
    },
    attrs: {
      "icon": "mobile-bottomBar-contacts-likeAirCall-icon",
      "width": "40",
      "height": "40"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('selectedTab', 'selectContext');
      }
    }
  }) : _vm._e(), !_vm.$store.getters.isIntegration ? _c('vb-icon', {
    staticClass: "mobile-call-activity",
    class: {
      active: _vm.activeTab === 'callLogs'
    },
    attrs: {
      "icon": "mobile-bottomBar-history-likeAirCall-icon",
      "width": "40",
      "height": "40"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('selectedTab', 'callLogs');
      }
    }
  }) : _vm._e(), _c('vb-icon', {
    staticClass: "mobile-dialer-icon",
    class: {
      'active': _vm.activeTab === 'homeCopy',
      'mx-auto': _vm.$store.getters.isIntegration
    },
    attrs: {
      "icon": "mobile-bottomBar-keypad-likeAirCall-icon",
      "width": "40",
      "height": "40"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('selectedTab', 'homeCopy');
      }
    }
  }), _vm.$store.getters.isIntegration ? _c('UserProfile', {
    staticClass: "userProfile-inCaseOfIntegrations"
  }) : _vm._e(), !_vm.$store.getters.isIntegration ? _c('vb-icon', {
    staticClass: "mobile-chat",
    class: {
      active: _vm.activeTab === 'chat'
    },
    attrs: {
      "icon": "mobile-bottomBar-chat-likeAirCall-icon",
      "width": "40",
      "height": "40"
    },
    on: {
      "click": function ($event) {
        !_vm.getUserPermissions.chat ? _vm.appNotify({
          message: 'Your package does not support this feature',
          type: 'danger'
        }) : _vm.$emit('selectedTab', 'chat');
      }
    }
  }) : _vm._e()], 1)])], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "router-link-exact-text tooltipVersion"
  }, [_c('span', [_vm._v("Deals")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "router-link-exact-text tooltipVersion"
  }, [_c('span', [_vm._v("Activities")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "router-link-exact-text tooltipVersion"
  }, [_c('span', [_vm._v("Inbox")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "router-link-exact-text tooltipVersion"
  }, [_c('span', [_vm._v("Documents")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "router-link-exact-text tooltipVersion"
  }, [_c('span', [_vm._v("Contacts")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "router-link-exact-text tooltipVersion"
  }, [_c('span', [_vm._v("Companies")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "router-link-exact-text tooltipVersion"
  }, [_c('span', [_vm._v("Insights")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "router-link-exact-text tooltipVersion"
  }, [_c('span', [_vm._v("Products")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "router-link-exact-text tooltipVersion"
  }, [_c('span', [_vm._v("Settings")])]);

}]

export { render, staticRenderFns }