<template>
  <div>
    
    <modal class="center-zoomIn-transition v2 m-w-600" width="500px" height="auto" :scrollable="true" :name="modalName" @before-open="onBeforeOpen" @before-close="onBeforeClose" >
      <section class="ct-user-new dialer-edit s1">
        <form class="dialer-form lg-mar-bottom" @submit.prevent="addExtension()">
          <div class="dialer-edit-header">
            <div>
              <!-- <h2 class="dialer-edit-title">Add Extension</h2> -->
              <h2 class="dialer-settings-title newer onlyfirstLetterCapital restLowerCase">Add {{data.type | extensionText}}</h2>
            </div>
            <div class="dialer-edit-actions">
              <a class="newCloseButton" @click="$modal.hide(modalName)">
                <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                <span class="newCloseButton-textPart">Close</span>
              </a>
              
            </div>
          </div>
          <div class="md-mar-vertical">
            <div class="dialer-team-name mb-4">
              <div v-if="conditions.screen==screens.limit" class="dialer-input-field">
                <!-- <vb-loading v-if="api.checkpurchaselimit.send" /> -->
                <template v-if="api.checkpurchaselimit.send">
                  <div class="latestGreyBox-9-9-2023 mt-20px">
                    <div class="latestShimmerDesign mb-2" style="height: 21px;width: 161px;"></div>
                    <div class="latestShimmerDesign mb-0" style="height: 50px;width: 100%;"></div>
                  </div>
                  <div class="latestShimmerDesign mt-4 ml-auto" style="height: 50px;width: 160px;"></div>
                </template>
                <h3 v-else class="has-no-margin text-center" style="font-size: 14px">
                  <i class="fab fa-product-hunt" /> Sorry you have reached your purchase limit.Please contact to your administrator.
                </h3>
              </div>
              <div v-if="conditions.screen==screens.add" class="latestGreyBox-9-9-2023 mt-20px">
                <div v-if="isExternal" class="whiteBGinputWithGreyRoundedBorder">
                  <label>Enter extension number</label>
                  <input 
                    type="text" 
                    placeholder="Enter extension number" 
                    :disabled="api.add_extension.send" 
                    v-model="forms.extension.number" 
                    class="form-control"
                    v-vb-input-number-formate
                  />
                  <p v-if="forms.extension.submitted && $v.forms.extension.number.$invalid" class="text text-danger animated bounceIntop">
                    <span v-if="!$v.forms.extension.number.required">* Number is required</span>
                    <span v-else-if="!$v.forms.extension.number.validNumber">* Number is not valid</span>
                  </p>
                </div>
                <div v-else class="whiteBGinputWithGreyRoundedBorder">
                  <label>Enter name</label>
                  <input 
                    type="text" 
                    placeholder="Name" 
                    :disabled="api.add_extension.send" 
                    v-model="forms.extension.name" 
                    class="form-control"
                    :maxlength="$v.forms.extension.name.$params.maxLength.max" 
                  />
                  <p v-if="(forms.extension.submitted && $v.forms.extension.name.$invalid) || api.add_extension.validations_error.name" class="text text-danger animated bounceIntop">
                    <span v-if="!$v.forms.extension.name.required">* Name is required</span>
                    <span v-else-if="!$v.forms.extension.name.alphaNum">* Name can be only alphabet and number</span>
                    <span v-else-if="!$v.forms.extension.name.maxLength">* Name can be maximum {{ $v.forms.extension.name.$params.maxLength.max }}</span>
                    <span v-for="error_message in (api.add_extension.validations_error.name || [])" :key="error_message">* {{ error_message }}</span>
                  </p>
                </div>
              </div>
              <!--<div v-if="conditions.screen==screens.add" class="dialer-input-field input-consistent-inner-shadows">
                <!~~ {{data.type}} ~~>
                <label>Enter extension name</label>
                <input 
                  type="text" 
                  placeholder="Extension Name" 
                  :disabled="api.add_extension.send" 
                  v-model="forms.extension.name" 
                  class="form-control"
                  :maxlength="$v.forms.extension.name.$params.maxLength.max" 
                />
                <p v-if="(forms.extension.submitted && $v.forms.extension.name.$invalid) || api.add_extension.validations_error.name" class="text text-danger animated bounceIntop">
                  <span v-if="!$v.forms.extension.name.required">* Name is required</span>
                  <span v-else-if="!$v.forms.extension.name.alphaNum">* Name can be only alphabet and number</span>
                  <span v-else-if="!$v.forms.extension.name.maxLength">* Name can be maximum {{ $v.forms.extension.name.$params.maxLength.max }}</span>
                  <span v-for="error_message in (api.add_extension.validations_error.name || [])" :key="error_message">* {{ error_message }}</span>
                </p>
              </div>-->
            </div>
          </div>
          <div class="w-100 d-flex justify-content-end mt-4">
            <!-- <button type="submit" :disabled="api.add_extension.send" class="newButton">
              <vb-icon icon="squared-add-icon" height="38px" width="38px" />
              <span class="newButton-textPart d-flex justify-content-center position-relative">
                <vb-spinner v-if="api.add_extension.send" />
                <template v-else>Create extension</template>
              </span>
            </button> -->
            <button type="submit" :disabled="api.add_extension.send" class="fullWidthDoneButton">
              <vb-spinner v-if="api.add_extension.send" />
              <template v-else>Create extension</template>
            </button>
          </div>
        </form>
      </section>
    </modal>
  </div>
</template>

<script>
import alphaNum from 'vuelidate/lib/validators/alphaNum';
import maxLength from 'vuelidate/lib/validators/maxLength';
import requiredIf from 'vuelidate/lib/validators/requiredIf';
import { $fn, LOGGER, VOIP_API } from '../../utils';
let screens = {
  limit: 'limit',
  add: 'add',
}
export default {
  name: "PurchaseExtensionModal",
  props: {
    modalName: {
      type: String,
      default: 'PurchaseExtensionModal'
    },
  },
  data() {
    return {
      forms: {
        extension: {
          name: '',
          number: '',
          reset(){
            this.name=''
            this.number = ''
            this.submitted = false
          },
          submitted: false,
        },
      },
      response: {
        checkpurchaselimit: {},
      },
      conditions: {
        screen: screens.limit,
      },
      api: {
        checkpurchaselimit: {
          count: 0,
          send: false,
          status: false,
          token: null,
          error: {},
          error_message: '',
          validations_error: {},
          get errorShow(){return this.count>0},
          increment(){return this.count=this.count+1},
          reset(){
            this.count = 0
            this.send = false
            this.status = false
            this.token = null
            this.error = {}
            this.error_message = ''
            this.validations_error = {}
          },
        },
        add_extension: {
          count: 0,
          send: false,
          status: false,
          token: null,
          error: {},
          error_message: '',
          validations_error: {},
          get errorShow(){return this.count>0},
          increment(){return this.count=this.count+1},
          reset(){
            this.count = 0
            this.send = false
            this.status = false
            this.token = null
            this.error = {}
            this.error_message = ''
            this.validations_error = {}
          },
        },
      },
      data: {
        type: '',
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    screens(){ return screens },
    isExternal(){ return this.data.type=='external' },
  },
  validations: {
    forms: {
      extension: {
        name: { 
          required: requiredIf(function(){
            return !this.isExternal
          }),
          alphaNum: alphaNum,
          maxLength: maxLength(20), 
        },
        number: { 
          required: requiredIf(function(){
            return this.isExternal
          }),
          validNumber(value){ return !value || $fn.validPhoneNumber(value) },
        },
      },
    }
  },
  filters: {
    extensionText(value){
      if(value=='handset') return 'Handset'
      else if(value=='integration') return 'Integration'
      else if(value=='desktop') return 'Desktop app'
      else if(value=='web') return 'Web app'
      else if(value=='mobile') return 'Mobile app'
      else if(value=='external') return 'External'
      else return value
    },
  },
  methods: {
    onBeforeOpen(event) {
      this.data.type = event?.params?.type ?? ''
      this.conditions.screen=screens.limit
      this.checkPurchaseLimit()
    },
    onBeforeClose() {
      this.forms.extension.reset()
      this.api.checkpurchaselimit.reset()
      this.api.add_extension.reset()
      this.conditions.screen = screens.limit
      this.response.checkpurchaselimit = {}
      this.data.type = ''
    },
    checkPurchaseLimit(){
      let vm = this
      if(vm.api.checkpurchaselimit.send) return;
      vm.api.checkpurchaselimit.send=true
      VOIP_API.endpoints.devices.checkpurchaselimit({
        type: vm.data.type,
      }).then(({ data: res }) => {
        LOGGER.log("checkPurchaseLimit", res);
        vm.response.checkpurchaselimit=res
        if(res.extensionAvaliable>0 || res.extensionAvaliable!=''){
          vm.conditions.screen=screens.add
        }
      }).catch((ex) => {
        vm.api.checkpurchaselimit.validations_error=ex.own_errors
        vm.api.checkpurchaselimit.error_message=ex.own_message
      }).finally(()=>{
        vm.api.checkpurchaselimit.send=false
      });
    },
    addExtension(){
      let vm = this
      vm.forms.extension.submitted=true
      vm.$v.forms.extension.$touch()
      if(vm.api.add_extension.send || vm.$v.forms.extension.$invalid) return;
      vm.api.add_extension.send=true
      vm.api.add_extension.validations_error={}
      vm.api.add_extension.error_message=''
      const type = vm.data.type
      let products = {
        external: 'eex',
        mobile: 'mobile',
        handset: 'hs',
        desktop: 'de',
        web: 'we',
        integration: 'ie',
      }
      const product = products[type] ?? type
      VOIP_API.endpoints.devices.create({
        name: vm.isExternal ? vm.forms.extension.number : vm.forms.extension.name,
        // number: vm.isExternal ? vm.forms.extension.number : vm.forms.extension.name,
        product: product,
        action: "adduserproduct",
        uid: vm.$store.getters.getCurrentUser?.uid,//vm.current_user.uid,
        purchase_by: "USER",
        extn: vm.$store.getters.getCurrentUser?.extn,
        purchase_account: vm.$store.getters.getCurrentUser?.account,//vm.current_user.account,
        service: product
      }).then(() => {
        vm.$modal.hide(vm.modalName);
        vm.$emit("interface");
      }).catch((ex) => {
        vm.api.add_extension.validations_error=ex.own_errors
        vm.api.add_extension.error_message=ex.own_message
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(()=>{
        vm.forms.extension.submitted=false
        vm.api.add_extension.send=false
      });
    },
  },
};
</script>
