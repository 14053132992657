<template>
  <div :class="`dialer-settings-main ${openedFromModal ? 'p-0 b-0' : ''}`">
    <section :class="`dialer-settings-section dialer-callRecording-settings MySoundFiles ${openedFromModal ? 'p-0' : ''}`">
      <div class="dialer-flex">
        <template v-if="!openedFromModal">
          <template v-if="getIsMobile">
            <div class="d-flex align-items-center mb-3 calc-title-width-button">
              <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" style="" @click="$emit('back')" />
              <h2 class="dialer-settings-title w-100 mb-0 text-center">My sound files</h2>
            </div>
          </template>
          <h2 v-else class="dialer-settings-title">
            My sound files
            <b-icon 
              icon="info-circle-fill" 
              class="cursor_pointer infoKbIcon md-mar-left" 
              @click="$modal.show('KbInfoModal',{ 
                kb: 'mySoundFiles' 
              })" 
            />
          </h2>
        </template>
        <template v-else>
          <div class="dialer-edit-header w-100">
            <h2 class="dialer-settings-title newer mb-0">
              <vb-svg @click="$emit('closeModal')" class="cursor_pointer_no_hover" name="leftLongArrow-icon" width="20" height="12" viewBox="0 0 30 16" stroke-width="1" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" />
              My sound files
            </h2>
            <div class="dialer-edit-actions">
              <!-- <a class="newCloseButton" @click="$modal.hide(modalName)">
                <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                <span class="newCloseButton-textPart">Close</span>
              </a> -->
            </div>
          </div>
        </template>
        <div 
          v-b-tooltip :title="getUserPermissions.add_sound_file?'':'Your package does not support this feature'"
          :class="{
            'for-disable': !getUserPermissions.add_sound_file
          }"
        >
          <button 
            :disabled="!getUserPermissions.add_sound_file" 
            class="newButton" 
            @click="$modal.show('AddRecordingModal',{ 
              suggested_name: `sound ${getProperty(response.playlists,'files.data.length',0)+1}` 
            })"
          >
            <vb-icon icon="squared-add-icon" height="38px" width="38px" />
            <span class="newButton-textPart">Add sound</span>
          </button>
        </div>
      </div>
      <p class="dialer-settings-intro with-button mt-4 mb-4">
        <span>This is a library of all sound files available to you, which can be used for IVR, Voicemail and more.</span>
      </p>
      <div class="TabsDesignWithIcon-container mt-20px mb-20px">
        <div 
          v-for="group in groups" 
          :key="group.value" 
          class="TabsDesignWithIcon-item"
          :class="{
            'selected': filter.playlists.group==group.value,
            'mwd-168px': group.value=='announcement'
          }"
          @click="filter.playlists.group=group.value"
        >
          <vb-icon :icon="group.icon" height="38px" width="38px" />
          <span class="TabsDesignWithIcon-item-textPart">{{group.text}}</span>
        </div>
      </div>
      <div class="d-flex align-items-center w-100 justify-content-end">
        <div class="d-flex align-items-center">
          <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD mr-16px">
            <b-dropdown no-caret class="dd-text-dark dd-with-checkbox">
              <template #button-content>
                <vb-svg 
                  class="filterDD-icon" 
                  name="filter-icon" 
                  width="19" 
                  height="18" 
                  viewBox="0 0 19 18" 
                  stroke-width="0" 
                  stroke="" 
                  fill="none" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                />
              </template>
              <b-dropdown-form>
                <b-form-checkbox 
                  class="makeCheckBoxesSquare fixVerticalAlign" 
                  :checked="allSelectedTypes"
                  @change="filter.playlists.types = $event ? Object.keys(types) : []"
                >
                  <span class="ml-4">All</span>
                </b-form-checkbox>
                <b-form-checkbox 
                  v-for="type in types" 
                  :key="type.value"
                  class="makeCheckBoxesSquare fixVerticalAlign" 
                  :checked="filter.playlists.types.includes(type.value)"
                  @change="filter.playlists.types.includes(type.value) ? 
                    filter.playlists.types.splice(filter.playlists.types.indexOf(type.value),1) : 
                    filter.playlists.types.push(type.value)
                  "
                >
                  <span class="ml-4">{{ type.text }}</span>
                </b-form-checkbox>
              </b-dropdown-form>
            </b-dropdown>
          </div>
          <div class="seachBox whiteBGinputWithGreyRoundedBorder">
            <b-input type="text" placeholder="Search sound" v-model="filter.playlists.search" />
          </div>
        </div>
      </div>
      <vb-table 
        :isListEmpty="playlists.length==0" 
        :listLength="playlists.length" 
        :loading="api.playlists.send"
        :noRecordWholePage="true"
        class="dialer-table-call-recording latestTableDesign-withBlackBorders-again hasSoundFiles showGraphIconOnlyOnHover"
      >
        <template slot="header">
          <tr>
            <th class="dialer-has-sort">
              <span>Name</span>
            </th>
            <th class="dialer-has-sort">
              <span>Audio</span>
            </th>
          </tr>
        </template>
        <template #body="{ start, end }">
          <tr v-for="(file, index) in playlists" :key="`${file.sound}-${file.sound}-${file.label}-${file.group}`" v-show="index >= start && index <= end">
            <td 
              class="dialer-row-title" 
              @click="!api.delete_playlist.send.includes(file.id) && file.status=='processing' || file.filter_type!='file' ? '' : $modal.show('EditRecordingModal', { 
                sound: file 
              })"
            >
              <div class="d-flex align-items-center">
                <vb-icon v-b-tooltip.hover :title="file.group" v-if="file.group == 'moh'" icon="moh-table-tdIcon" class="mr-16px" width="35px" height="35px" />
                <vb-icon v-b-tooltip.hover :title="file.group" v-else-if="file.group == 'ivr'" icon="ivr-icon" class="mr-16px" width="35px" height="35px" />
                <vb-icon v-b-tooltip.hover :title="file.group" v-else-if="file.group == 'announcement'" icon="announcement-icon-2" class="mr-16px" width="35px" height="35px" />
                <vb-icon v-b-tooltip.hover :title="file.group ? file.group : ''" v-else icon="MySoundFiles-table-tdIcon" class="mr-16px" width="35px" height="35px" />
                <div>
                  <span v-b-tooltip.hover :title="file.created_at ? `created at ${file.created_at.slice(0, 10)}` : ''">{{ file.label }}</span>
                  <div v-if="file.filter_type=='file'" class="insideTable-subText">Uploaded by you</div>
                  <div v-else-if="file.filter_type=='shared'" class="insideTable-subText">Shared with you</div>
                  <div v-else-if="file.filter_type=='system'" class="insideTable-subText">From library</div>
                </div>
                <span v-if="file.filter_type=='file' && file.shared" class="OwnertaggedVersion ml-16px">Shared</span>
              </div>
            </td>
            <td>
              <div v-if="file.status=='processing'">
                <div class="dialer-audio-playback">{{ file.status }}</div>
                <span class="icon-refresh" @click="fetchPlaylists()" />
              </div>
              <div v-else class="d-flex align-items-center justify-content-between">
                <vb-audio-player class="whiteBGaudioWithGreyRoundedBorder-again" :src="file.sound_file" />
                <b-button class="dialer-button dialer-button-delete-alt buttonNothing p-0 ml-8px" v-if="file.filter_type=='file'" :disabled="api.delete_playlist.send.includes(file.id)" @click="deleteRecording(file.id)" variant="link">
                  <b-icon v-if="api.delete_playlist.send.includes(file.id)" icon="circle-fill" animation="throb" variant="primary" />
                  <b-icon v-else icon="trash-fill" variant="danger" />
                </b-button>
              </div>
            </td>
          </tr>
        </template>
        <template #loading>
          <tr v-for="n in 5" :key="n">
            <td>
              <div class="latestShimmerDesign" style="height:20px;width:-webkit-fill-available;"></div>
            </td>
            <td>
              <div class="latestShimmerDesign" style="height:50px;width:250px;"></div>
            </td>
            <td>
              <div class="latestShimmerDesign flex-1" style="height:20px;width:60px"></div>
            </td>
            <td class="dialer-col-right">
              <div class="latestShimmerDesign ml-auto" style="height:48px;width:48px;"></div>
            </td>
          </tr>
        </template>
        <template #no-record>
          <div class="latestGreyBox-9-9-2023 mb-32px mt-20px">
            <div class="emptyScreenContainer">
              <img class="mt-50px w-50 h-auto" v-if="GET_LOCAL_SETTING_IS_DARK_MODE" :src="require('@/assets/images/emptyScreenImages/darkMode/mysoundfiles.png')"/>
              <img class="mt-50px w-50 h-auto" v-else :src="require('@/assets/images/emptyScreenImages/mysoundfiles.png')"/>
              <div class="emptyScreenContainer-heading">No sound files found</div>
              <div class="emptyScreenContainer-text w-75">Upload sound files to personalize your call experience</div>
              <div 
                v-b-tooltip :title="getUserPermissions.add_sound_file?'':'Your package does not support this feature'"
              >
                <!-- <button 
                  class="dialer-button dialer-button-primary mt-20px"
                  @click="$modal.show('AddRecordingModal',{ 
                    suggested_name: `sound ${getProperty(response.playlists,'files.data.length',0)+1}` 
                  })"
                  :disabled="!getUserPermissions.add_sound_file"
                >
                  Add sound file
                </button> -->
                <button 
                  class="newButton mt-20px" 
                  @click="$modal.show('AddRecordingModal',{ 
                    suggested_name: `sound ${getProperty(response.playlists,'files.data.length',0)+1}` 
                  })"
                  :disabled="!getUserPermissions.add_sound_file"
                >
                  <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                  <span class="newButton-textPart">Add sound file</span>
                </button>
              </div>
            </div>
          </div>
        </template>
      </vb-table>
      <AddRecordingModal @update-list="fetchPlaylists()" />
      <EditRecordingModal @update-list="fetchPlaylists()" />
    </section>
  </div>
</template>

<script>
import AddRecordingModal from "../Modals/AddRecordingModal.vue";
import EditRecordingModal from "../Modals/EditRecordingModal.vue";
import { VOIP_API } from "../../utils";
// import { centrifugo } from '../../Centrifuge';
import { mapGetters } from 'vuex';
import { GET_LOCAL_SETTING_IS_DARK_MODE } from '@/store/helper/getters';
import { AMI_EVENTS, AMI_SOCKET } from '@/ami';

const types = {
  file: {
    value: 'file',
    text: "Mine",
  },
  shared: {
    value: 'shared',
    text: "Shared with me",
  },
  system: {
    value: 'system',
    text: "System",
  },
}
const groups = {
  all: {
    value: 'all',
    text: "All",
    icon: "mySoundFiles-tab-all-icon"
  },
  moh: {
    value: 'moh',
    text: "MOH",
    icon: "mySoundFiles-tab-moh-icon"
  },
  ivr: {
    value: 'ivr',
    text: "IVR",
    icon: "mySoundFiles-tab-ivr-icon"
  },
  announcement: {
    value: 'announcement',
    text: "Announcement",
    icon: "mySoundFiles-tab-announcement-icon"
  },
  // core: {
  //   value: 'core',
  //   text: "CORE",
  // },
}
export default {
  name: "Recordings",
  components: {
    AddRecordingModal,
    EditRecordingModal,
  },
  inject: [
    'getProperty',
    'appNotify'
  ],
  props: {
    openedFromModal: {
      type: Boolean,
      default: false
    },
    ivrShow: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      api: {
        playlists: this.$helperFunction.apiInstance(),
        delete_playlist: this.$helperFunction.apiInstance({
          send: [],
        }),
      },
      response: {
        playlists: {},
      },
      filter: {
        playlists: {
          search: '',
          types: Object.keys(types),
          group: groups.all.value,
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      'getUserPermissions',
      'getCurrentUser',
      'getIsMobile',
      GET_LOCAL_SETTING_IS_DARK_MODE,
    ]),
    types(){ return types },
    groups(){ return Object.values(groups).filter(i=>this.ivrShow ? true :  i.value!=groups.ivr.value) },
    allSelectedTypes(){ return Object.keys(types).every(i=>this.filter.playlists.types.includes(i)) },
    playlists(){
      const search = this.filter.playlists.search.toLowerCase().trim()
      const selected_types = this.filter.playlists.types
      const selected_group = this.filter.playlists.group
      const mine = this.response.playlists?.global?.data?.filter?.(i=>i.type=='custom')?.map?.(i=>({ ...i, filter_type: 'file' })) ?? []
      const shared = this.response.playlists?.shared?.data?.map?.(i=>({ ...i, filter_type: 'shared' })) ?? []
      const system = this.response.playlists?.global?.data?.filter?.(i=>i.type=='system')?.map?.(i=>({ ...i, filter_type: 'system' })) ?? []
      return [...mine,...shared,...system].filter(i=>
        (selected_group==groups.all.value ? this.ivrShow ? true : i.group!=groups.ivr.value : selected_group==i.group) && selected_types.includes(i.filter_type) && `${i.label}`.toLowerCase().trim().includes(search)
      )
    },
  },
  filters: {
    filterTabIcon(value){
      if(value == 'file'){return 'soundLibraryTabType-mine-icon'}
      else if(value == 'shared'){return 'soundLibraryTabType-shared-icon'}
      else if(value == 'system'){return 'soundLibraryTabType-system-icon'}
      else {return 'soundLibraryTabType-mine-icon'}
    }
  },
  methods: {
    async deleteRecording(id) {
      if (this.api.delete_playlist.send.includes(id)) return;
      try {
        const { confirm } = await this.$appConfirmation({
          title: "Warning",
          message: `Are you sure you want to delete this file.`,
          variant: 'danger',
          button: {
            no: "No",
            yes: "Yes",
          },
        })
        if(!confirm) return;
        this.api.delete_playlist.send.push(id);
        await VOIP_API.endpoints.recording.delete(id)
        this.response.playlists.global.data = this.response.playlists.global.data.filter((file) => file.id !== id);
        this.appNotify({
          message: "Successfully Deleted",
          type: "success",
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        if (this.api.delete_playlist.send.includes(id)) {
          this.api.delete_playlist.send.splice(this.api.delete_playlist.send.indexOf(id), 1);
        }
      }
    },
    async fetchPlaylists() {
      try {
        this.api.playlists.send = true;
        const { data } = await VOIP_API.endpoints.recording.list({
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
          type: 'ivr',
        })
        this.response.playlists=data
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.playlists.send = false;
      }
    },
    onNotification(message) {
      if (message.data.flag === "file_uploaded_successfully") {
        this.fetchPlaylists();
      }
    },
  },
  created() {
    AMI_SOCKET.on(AMI_EVENTS.broadcast,this.onNotification)
    // centrifugo.notification.on(centrifugo.notification.events.message,this.onNotification)
  },
  mounted(){
    this.fetchPlaylists();
  },
  beforeDestroy() {
    AMI_SOCKET.off(AMI_EVENTS.broadcast,this.onNotification)
    // centrifugo.notification.off(centrifugo.notification.events.message,this.onNotification)
  },
};
</script>
