<template>
  <modal 
    class="dialer_animation right_side_popup viewPDFmodal full-width-big-modal" 
    height="auto" 
    :scrollable="true" 
    :name="modalName" 
    @before-open="onBeforeOpen($event)" 
    @before-close="onBeforeClose()"
  >
    <div class="dialer-edit-header d-flex align-items-center justify-content-between px-0 pt-3">
      <h2 class="dialer-edit-title newer">
        <vb-svg @click="$modal.hide(modalName)" :disabled="api.download.send" class="cursor_pointer_no_hover" name="leftLongArrow-icon" width="20" height="12" viewBox="0 0 30 16" stroke-width="1" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" />
        Invoice details
      </h2>
      <div class="dialer-edit-actions">
        <b-button class="newButton mw-168px" :disabled="api.download.send || api.invoice_detail.send" @click="downloadPDF()">
          <vb-icon icon="squared-exportFile-icon" height="38px" width="38px" />
          <span class="newButton-textPart d-flex justify-content-center position-relative align-items-center">
            <vb-spinner v-if="api.download.send" />
            <template v-else>Download invoice</template>
          </span>
        </b-button>
      </div>
    </div>
    <div v-if="api.invoice_detail.send">
      <div class="w-100 mb-32px p-4 invoiceContainer">
        <table class="w-100" style="border:0px solid black;margin-bottom: 40px !important;">
          <tbody>
            <tr>
              <td style="border:0px solid black">
                <div class="latestShimmerDesign" style="height: 44.66px;width: 196.08px;"></div>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="w-100">
          <tbody>
            <tr>
              <td width="50%" colspan="2" style="text-align: left;">
                <span style="font-size: 12px;font-weight: bold;line-height: 2;color: #000000;"><div class="latestShimmerDesign" style="height: 16px;width: 80px;"></div></span> <br>
                <span style="font-size: 12px;font-weight: bold;line-height: 2;color: #7E7E7E;"><div class="latestShimmerDesign" style="height: 16px;width: 100px;"></div></span> <br>
                <span style="font-size: 12px;font-weight: bold;line-height: 2;color: #7E7E7E;"><div class="latestShimmerDesign" style="height: 16px;width: 100px;"></div></span> <br>
                <span style="font-size: 12px;font-weight: bold;line-height: 2;color: #7E7E7E;"><div class="latestShimmerDesign" style="height: 16px;width: 100px;"></div></span> <br>
                <span style="font-size: 12px;font-weight: bold;line-height: 2;color: #7E7E7E;"><div class="latestShimmerDesign" style="height: 16px;width: 100px;"></div></span> <br>
              </td>
              <td width="50%" colspan="2" style="text-align: right;">
                <span style="font-size: 12px;font-weight: bold;line-height: 2;color: #000000;"><div class="latestShimmerDesign ml-auto" style="height: 16px;width: 80px;"></div></span> <br>
                <span style="font-size: 12px;font-weight: bold;line-height: 2;color: #7E7E7E;"><div class="latestShimmerDesign ml-auto" style="height: 16px;width: 100px;"></div></span> <br>
                <span style="font-size: 12px;font-weight: bold;line-height: 2;color: #7E7E7E;"><div class="latestShimmerDesign ml-auto" style="height: 16px;width: 100px;"></div></span> <br>
                <span style="font-size: 12px;font-weight: bold;line-height: 2;color: #7E7E7E;"><div class="latestShimmerDesign ml-auto" style="height: 16px;width: 100px;"></div></span> <br>
                <span style="font-size: 12px;font-weight: bold;line-height: 2;color: #7E7E7E;"><div class="latestShimmerDesign ml-auto" style="height: 16px;width: 100px;"></div></span> <br>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="w-100" style="margin-top: 30px;border-bottom: 2px solid #3699FF">
          <tbody>
            <tr>
              <td width="50%" colspan="2" style="text-align: left;padding-bottom: 7px;">
                <span style="font-size: 18px;font-weight: bold;line-height: 1.3;color: #000000;">
                  <div class="latestShimmerDesign" style="height: 24px;width: 200px;"></div>
                </span> 
                <br>
              </td>
              <td width="50%" colspan="2" style="text-align: right;padding-bottom: 7px;">
                <span style="font-size: 18px;font-weight: bold;line-height: 1.3;color: #000000;"><div class="latestShimmerDesign ml-auto" style="height: 16px;width: 150px;"></div></span> <br>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="w-100" style="margin-top: 25px;">
          <thead>
            <tr>
              <th width="50%" style="text-align: left;font-size: 13px;font-weight: 800;line-height: 1.3;color: #000000;padding-bottom: 18px;border-bottom: 1px solid #E2E2E2;"> <div class="d-flex"> Subscription <div class="latestShimmerDesign ml-3" style="height: 16px;width: 80px;"></div> </div> </th>
              <th width="16%" style="text-align: right;font-size: 13px;font-weight: 800;line-height: 1.3;color: #000000;padding-bottom: 18px;border-bottom: 1px solid #E2E2E2;">QUANTITY</th>
              <th width="16%" style="text-align: right;font-size: 13px;font-weight: 800;line-height: 1.3;color: #000000;padding-bottom: 18px;border-bottom: 1px solid #E2E2E2;">UNIT PRICE</th>
              <th width="18%" style="text-align: right;font-size: 13px;font-weight: 800;line-height: 1.3;color: #000000;padding-bottom: 18px;border-bottom: 1px solid #E2E2E2;">TOTAL AMOUNT</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="n in 2" :key="n.id">
              <td width="50%" style="text-align: left;font-size: 14px;font-weight: 600;line-height: 1.3;color: #000000;padding-bottom: 16px;padding-top: 12px;border-bottom: 1px solid #E2E2E2;"><div class="latestShimmerDesign" style="height: 16px;width: 50%;"></div></td>
              <td width="16%" style="text-align: right;font-size: 14px;font-weight: 600;line-height: 1.3;color: #000000;padding-bottom: 16px;padding-top: 12px;border-bottom: 1px solid #E2E2E2;"><div class="latestShimmerDesign ml-auto" style="height: 16px;width: 50%;"></div></td>
              <td width="16%" style="text-align: right;font-size: 14px;font-weight: 600;line-height: 1.3;color: #000000;padding-bottom: 16px;padding-top: 12px;border-bottom: 1px solid #E2E2E2;"><div class="latestShimmerDesign ml-auto" style="height: 16px;width: 50%;"></div></td>
              <td width="18%" style="text-align: right;font-size: 14px;font-weight: 600;line-height: 1.3;color: #000000;padding-bottom: 16px;padding-top: 12px;border-bottom: 1px solid #E2E2E2;"><div class="latestShimmerDesign ml-auto" style="height: 16px;width: 50%;"></div></td>
            </tr>
          </tbody>
        </table>
        <table class="w-100" style="margin-top: 32px;">
          <thead>
            <tr>
              <th width="60%" style="text-align: left;font-size: 13px;font-weight: 800;line-height: 1.3;color: #000000;padding-bottom: 11px;"></th>
              <th width="20%" style="text-align: right;font-size: 13px;font-weight: 800;line-height: 1.3;color: #000000;padding-bottom: 11px;border-bottom: 1px solid #E2E2E2;">Subtotal</th>
              <th width="20%" style="text-align: right;font-size: 13px;font-weight: 800;line-height: 1.3;color: #000000;padding-bottom: 11px;border-bottom: 1px solid #E2E2E2;"><div class="latestShimmerDesign ml-auto" style="height: 16px;width: 50%;"></div></th>
            </tr>
            <tr>
              <th width="60%" style="text-align: left;font-size: 13px;font-weight: 800;line-height: 1.3;color: #000000;padding-bottom: 9px;padding-top: 9px;"></th>
              <th width="20%" style="text-align: right;font-size: 13px;font-weight: 800;line-height: 1.3;color: #000000;padding-bottom: 9px;padding-top: 9px;border-bottom: 1px solid #E2E2E2;">
                <div class="d-flex w-100 justify-content-end align-items-center">
                  Plus VAT 
                  <div class="latestShimmerDesign my-auto" style="height: 20px;width: 40px;"></div>
                </div>
              </th>
              <th width="20%" style="text-align: right;font-size: 13px;font-weight: 800;line-height: 1.3;color: #000000;padding-bottom: 9px;padding-top: 9px;border-bottom: 1px solid #E2E2E2;"><div class="latestShimmerDesign ml-auto" style="height: 16px;width: 50%;"></div></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td width="60%" style="text-align: left;font-size: 12px;font-weight: 800;line-height: 1.3;color: #3699FF;padding-bottom: 9px;padding-top: 9px;"></td>
              <td width="20%" style="text-align: right;font-size: 12px;font-weight: 800;line-height: 1.3;color: #3699FF;padding-bottom: 9px;padding-top: 9px;border-bottom: 1px solid #E2E2E2;">Total</td>
              <td width="20%" style="text-align: right;font-size: 12px;font-weight: 800;line-height: 1.3;color: #3699FF;padding-bottom: 9px;padding-top: 9px;border-bottom: 1px solid #E2E2E2;"><div class="latestShimmerDesign ml-auto" style="height: 16px;width: 50%;"></div></td>
            </tr>
          </tbody>
        </table>
        <table class="w-100" style="margin-top: 40px;">
          <tbody>
            <tr>
              <td style="text-align: left;padding: 20px;background-color: #F8F8F8;">
                <span style="text-align: left;font-size: 13px;font-weight: 800;line-height: 1.3;color: #000000;">Notes</span>
                <br><br>
                <span style="text-align: left;font-size: 12px;font-weight: 500;line-height: 1.3;color: #000000;padding-top: 16px;">
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else class="w-100 mb-32px p-4 invoiceContainer">
      <table class="w-100" style="border:0px solid black;margin-bottom: 40px !important;">
        <tbody>
          <tr>
            <td style="border:0px solid black">
              <img width="196.08px" height="44.66px" src="https://www.voipbusiness.com/wp-content/uploads/2023/11/Group-46115.svg" />
            </td>
          </tr>
        </tbody>
      </table>
      <table class="w-100">
        <tbody>
          <tr>
            <td width="50%" colspan="2" style="text-align: left;">
              <span style="font-size: 12px;font-weight: bold;line-height: 2;color: #000000;">{{ response.invoice | get_property('invoice_details.user_detail.companyname') }}</span> <br>
              <span style="font-size: 12px;font-weight: bold;line-height: 2;color: #7E7E7E;">{{ response.invoice | get_property('invoice_details.user_detail.address') }}</span> <br>
              <span style="font-size: 12px;font-weight: bold;line-height: 2;color: #7E7E7E;">{{ response.invoice | get_property('invoice_details.user_detail.city') }}</span> <br>
              <span style="font-size: 12px;font-weight: bold;line-height: 2;color: #7E7E7E;">{{ response.invoice | get_property('invoice_details.user_detail.postcode') }}</span> <br>
              <span style="font-size: 12px;font-weight: bold;line-height: 2;color: #7E7E7E;">{{ response.invoice | get_property('invoice_details.user_detail.countryname') }}</span> <br>
            </td>
            <td width="50%" colspan="2" style="text-align: right;">
              <span style="font-size: 12px; font-weight: bold; line-height: 2; color: #000000;">VoIP Business</span> <br>
              <span style="font-size: 12px; font-weight: bold; line-height: 2; color: #7E7E7E;">Suite 6, Beaufort Court, Admirals Way,</span> <br>
              <span style="font-size: 12px; font-weight: bold; line-height: 2; color: #7E7E7E;">Date: 24 / 09 / 2022</span> <br>
              <span style="font-size: 12px; font-weight: bold; line-height: 2; color: #7E7E7E;">E14 9XL London</span> <br>
              <span style="font-size: 12px; font-weight: bold; line-height: 2; color: #7E7E7E;">United Kingdom</span> <br>
              <span style="font-size: 12px; font-weight: bold; line-height: 2; color: #7E7E7E;">{{ response.invoice | get_property('invoices.status') }}</span> <br>
              <span v-if="isUnPaid" style="font-size: 12px; font-weight: bold; line-height: 2; color: #7E7E7E;">Due Date: {{ response.invoice | get_property('invoices.invoice_due_date') }}</span> <br>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="w-100" style="margin-top: 30px;border-bottom: 2px solid #3699FF">
        <tbody>
          <tr>
            <td width="50%" colspan="2" style="text-align: left;padding-bottom: 7px;">
              <span style="font-size: 18px;font-weight: bold;line-height: 1.3;color: #000000;">
                Invoice 
                <span style="color: #3699FF;">VB-{{ response.invoice | get_property('invoices.invoice_id') }}</span>
              </span> 
              <br>
            </td>
            <td width="50%" colspan="2" style="text-align: right;padding-bottom: 7px;">
              <span style="font-size: 18px;font-weight: bold;line-height: 1.3;color: #000000;">{{ response.invoice | get_property('invoices.invoice_due_date') }}</span> <br>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="w-100" style="margin-top: 25px;">
        <thead>
          <tr>
            <th width="50%" style="text-align: left;font-size: 13px;font-weight: 800;line-height: 1.3;color: #000000;padding-bottom: 18px;border-bottom: 1px solid #E2E2E2;">Subscription</th>
            <th width="16%" style="text-align: right;font-size: 13px;font-weight: 800;line-height: 1.3;color: #000000;padding-bottom: 18px;border-bottom: 1px solid #E2E2E2;">QUANTITY</th>
            <th width="16%" style="text-align: right;font-size: 13px;font-weight: 800;line-height: 1.3;color: #000000;padding-bottom: 18px;border-bottom: 1px solid #E2E2E2;">UNIT PRICE</th>
            <th width="18%" style="text-align: right;font-size: 13px;font-weight: 800;line-height: 1.3;color: #000000;padding-bottom: 18px;border-bottom: 1px solid #E2E2E2;">TOTAL AMOUNT</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in productDetail" :key="item.id">
            <td width="50%" style="text-align: left;font-size: 14px;font-weight: 600;line-height: 1.3;color: #000000;padding-bottom: 16px;padding-top: 12px;border-bottom: 1px solid #E2E2E2;">
              <span>{{ item.description }}</span>
              <br />
              <span v-if="item.is_date_to != 'no'"> ({{item.from_date_readable}}  to {{item.to_date_readable}})</span>
            </td>
            <td width="16%" style="text-align: right;font-size: 14px;font-weight: 600;line-height: 1.3;color: #000000;padding-bottom: 16px;padding-top: 12px;border-bottom: 1px solid #E2E2E2;">{{ item.quantity }}</td>
            <td width="16%" style="text-align: right;font-size: 14px;font-weight: 600;line-height: 1.3;color: #000000;padding-bottom: 16px;padding-top: 12px;border-bottom: 1px solid #E2E2E2;">{{ item.unit_price | to_fixed }}</td>
            <td width="18%" style="text-align: right;font-size: 14px;font-weight: 600;line-height: 1.3;color: #000000;padding-bottom: 16px;padding-top: 12px;border-bottom: 1px solid #E2E2E2;">{{ item.total_price | to_fixed }} </td>
          </tr>
        </tbody>
      </table>
      <table class="w-100" style="margin-top: 32px;">
        <thead>
          <tr>
            <th width="60%" style="text-align: left;font-size: 13px;font-weight: 800;line-height: 1.3;color: #000000;padding-bottom: 11px;"></th>
            <th width="20%" style="text-align: right;font-size: 13px;font-weight: 800;line-height: 1.3;color: #000000;padding-bottom: 11px;border-bottom: 1px solid #E2E2E2;">Subtotal</th>
            <th width="20%" style="text-align: right;font-size: 13px;font-weight: 800;line-height: 1.3;color: #000000;padding-bottom: 11px;border-bottom: 1px solid #E2E2E2;">{{ response.invoice | get_property('invoices.sub_total',0) | to_fixed }}</th>
          </tr>
          <tr>
            <th width="60%" style="text-align: left;font-size: 13px;font-weight: 800;line-height: 1.3;color: #000000;padding-bottom: 9px;padding-top: 9px;"></th>
            <th width="20%" style="text-align: right;font-size: 13px;font-weight: 800;line-height: 1.3;color: #000000;padding-bottom: 9px;padding-top: 9px;border-bottom: 1px solid #E2E2E2;">Plus VAT ({{ response.invoice | get_property('invoices.vat',0) | to_fixed }}%)</th>
            <th width="20%" style="text-align: right;font-size: 13px;font-weight: 800;line-height: 1.3;color: #000000;padding-bottom: 9px;padding-top: 9px;border-bottom: 1px solid #E2E2E2;">$0.00</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td width="60%" style="text-align: left;font-size: 12px;font-weight: 800;line-height: 1.3;color: #3699FF;padding-bottom: 9px;padding-top: 9px;"></td>
            <td width="20%" style="text-align: right;font-size: 12px;font-weight: 800;line-height: 1.3;color: #3699FF;padding-bottom: 9px;padding-top: 9px;border-bottom: 1px solid #E2E2E2;">Total</td>
            <td width="20%" style="text-align: right;font-size: 12px;font-weight: 800;line-height: 1.3;color: #3699FF;padding-bottom: 9px;padding-top: 9px;border-bottom: 1px solid #E2E2E2;">{{ response.invoice | get_property('invoices.total_amount',0) | to_fixed }}</td>
          </tr>
        </tbody>
      </table>
      <table v-if="!isUnPaid" class="w-100" style="margin-top: 5px;">
        <thead>
          <tr v-if="cardPayment!='0.00000000'">
            <th width="60%" style="text-align: left;font-size: 13px;font-weight: 800;line-height: 1.3;color: #000000;padding-bottom: 11px;"></th>
            <th width="20%" style="text-align: right;font-size: 13px;font-weight: 800;line-height: 1.3;color: #000000;padding-bottom: 11px;border-bottom: 1px solid #E2E2E2;">Card Payment</th>
            <th width="20%" style="text-align: right;font-size: 13px;font-weight: 800;line-height: 1.3;color: #000000;padding-bottom: 11px;border-bottom: 1px solid #E2E2E2;">-{{ cardPayment | to_fixed }}</th>
          </tr>
          <tr v-if="balancePayment!='0.00000000'">
            <th width="60%" style="text-align: left;font-size: 13px;font-weight: 800;line-height: 1.3;color: #000000;padding-bottom: 11px;"></th>
            <th width="20%" style="text-align: right;font-size: 13px;font-weight: 800;line-height: 1.3;color: #000000;padding-bottom: 11px;border-bottom: 1px solid #E2E2E2;">Balance Payment</th>
            <th width="20%" style="text-align: right;font-size: 13px;font-weight: 800;line-height: 1.3;color: #000000;padding-bottom: 11px;border-bottom: 1px solid #E2E2E2;">-{{ balancePayment | to_fixed }}</th>
          </tr>
          <tr v-if="reservePayment!='0.00000000'">
            <th width="60%" style="text-align: left;font-size: 13px;font-weight: 800;line-height: 1.3;color: #000000;padding-bottom: 11px;"></th>
            <th width="20%" style="text-align: right;font-size: 13px;font-weight: 800;line-height: 1.3;color: #000000;padding-bottom: 11px;border-bottom: 1px solid #E2E2E2;">Reserve Payment</th>
            <th width="20%" style="text-align: right;font-size: 13px;font-weight: 800;line-height: 1.3;color: #000000;padding-bottom: 11px;border-bottom: 1px solid #E2E2E2;">-{{ reservePayment | to_fixed }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td width="60%" style="text-align: left;font-size: 12px;font-weight: 800;line-height: 1.3;color: #3699FF;padding-bottom: 9px;padding-top: 9px;"></td>
            <td width="20%" style="text-align: right;font-size: 12px;font-weight: 800;line-height: 1.3;color: #3699FF;padding-bottom: 9px;padding-top: 9px;border-bottom: 1px solid #E2E2E2;">Amount Due</td>
            <td width="20%" style="text-align: right;font-size: 12px;font-weight: 800;line-height: 1.3;color: #3699FF;padding-bottom: 9px;padding-top: 9px;border-bottom: 1px solid #E2E2E2;">{{ response.invoice | get_property('invoices.ammount_due',0) | to_fixed }}</td>
          </tr>
        </tbody>
      </table>
      <table class="w-100" style="margin-top: 40px;">
        <tbody>
          <tr>
            <td style="text-align: left;padding: 20px;background-color: #F8F8F8;">
              <span style="text-align: left;font-size: 13px;font-weight: 800;line-height: 1.3;color: #000000;">Notes</span>
              <template v-if="isUnPaid">
                <br>
                <span style="text-align: left;font-size: 12px;font-weight: 500;line-height: 1.3;color: #000000;padding-top: 16px;">- If payment not recieve by the due date the account will be at risk of suspention</span>
              </template>
              <template v-if="cardPayment>0 && getProperty(response.invoice,'invoices.card_type_last_digits')">
                <br>
                <span style="text-align: left;font-size: 12px;font-weight: 500;line-height: 1.3;color: #000000;padding-top: 16px;">- £{{cardPayment | to_fixed}} was paid on {{response.invoice | get_property('invoices.invoice_due_date')}}  by {{response.invoice | get_property('invoices.card_type_last_digits')}}.</span>
              </template>
              <template v-if="reservePayment>0">
                <br>
                <span style="text-align: left;font-size: 12px;font-weight: 500;line-height: 1.3;color: #000000;padding-top: 16px;">- £{{reservePayment | to_fixed}} was paid from reserved.</span>
              </template>
              <template v-if="balancePayment>0">
                <br>
                <span style="text-align: left;font-size: 12px;font-weight: 500;line-height: 1.3;color: #000000;padding-top: 16px;">- £{{balancePayment | to_fixed}} was paid from account balance.</span>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { $fn, VOIP_API } from '../../utils'
export default {
  name: 'InvoicePDFDownload',
  inject:['getProperty','appNotify'],
  props: {
    modalName: {
      type: String,
      default: 'InvoicePDFDownload',
    },
    id: {
      type: String,
      default: ()=>{return $fn.makeid(10)},
    },
  },
  data(){
    return {
      data: {
        invoiceid: '',
      },
      response: {
        invoice: {},
      },
      api: {
        invoice_detail: this.$helperFunction.apiInstance(),
        download: this.$helperFunction.apiInstance(),
      },
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentUser'
    ]),
    isUnPaid(){ return this.response.invoice?.invoices?.status!='PAID' },
    cardPayment(){ return this.response.invoice?.invoices?.amount_from_card ?? '0.00000000' },
    balancePayment(){ return this.response.invoice?.invoices?.amount_from_balance ?? '0.00000000' },
    reservePayment(){ return this.response.invoice?.invoices?.amount_from_reserved ?? '0.00000000' },
    productDetail(){ return this.response.invoice?.invoices?.productDetail ?? [] }
  },
  filters: {
    invoicename(value){ return `VB-${value}` },
    invoiceammount(invoice){ return parseInt(invoice.number_of_days)*parseFloat(invoice.product_prorota_price) },
  },
  methods: {
    onBeforeOpen(event){
      this.data.invoiceid = event?.params?.id || ''
      this.fetchinvoicedetail()
    },
    onBeforeClose(){
      this.data.invoice = {}
      this.response.invoice = {}
      this.api.download.reset()
      this.api.invoice_detail.reset()
    },
    async downloadPDF(){
      if(this.api.download.send) return;
      try {
        this.api.download.send=true
        const { data } = await VOIP_API.endpoints.users.invoicedetailDownloadPDF(this.data.invoiceid,{
          invoice_id: this.data.invoiceid,
          uid: this.getCurrentUser?.uid,
          type: 'billing',
          action: 'user_invoice_details',
        })
        const blob = new Blob([data], { 
          type: "application/pdf" 
        });
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.target = '_blank';
        link.download = `VB-${this.data.invoiceid}`;
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (ex) {
        if(!ex.cancel){
          this.appNotify({
            message: ex.own_message || ex.message,
            type: 'danger',
          })
        }
      } finally {
        this.api.download.send=false
      }
    },
    async fetchinvoicedetail(){
      try {
        this.api.invoice_detail.send=true
        const { data } = await VOIP_API.endpoints.users.invoicedetailUpdated({
          invoice_id: /*239*/this.data.invoiceid,
          uid: this.getCurrentUser?.id,
        })
        this.response.invoice=data ?? {}
      } finally {
        this.api.invoice_detail.send=false
      }
    },
  },
}
</script>

<style>

</style>