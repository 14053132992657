<template>
  <div>

  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { CRM_FETCH_STORE_DATA, CRM_LOGIN } from '@/store/helper/actions'
export default {
  name: 'CommonCRM',
  methods: {
    ...mapActions([
      CRM_LOGIN,
      CRM_FETCH_STORE_DATA
    ]),
  },
  mounted(){
    this.CRM_LOGIN()
    this.CRM_FETCH_STORE_DATA()
  },
}
</script>

<style>

</style>