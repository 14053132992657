<template>
  <div>
    <div class="d-flex align-items-center justify-content-between ivrAdvanceOptions-1stRow">
      <div class="mr-5">
        <div class="latestGreyBox-heading-main">Advance options</div>
        <div class="latestGreyBox-descriptionText">
          Customize advanced settings like menu repetition and post-menu 
          actions for precise call routing and enhanced caller interaction.
        </div>
      </div>
      <button class="newButton ml-5" type="button" v-b-toggle.advanceOptions-collapse>
        <vb-icon :icon="conditions.is_view ? 'squared-hide-icon' : 'squared-show-icon'" height="38px" width="38px" />
        <span class="newButton-textPart">
          <span>{{ conditions.is_view ? 'Hide' : 'Show' }}</span>
        </span>
      </button>
    </div>
    <b-collapse id="advanceOptions-collapse">
      <b-form @submit.prevent="''">
        <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
          <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
            <b-form-group class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mb-0">
              <label>Repeat menu sound file if no action is pressed after</label>
              <!-- <vb-select
                class="dialer-dropDown-onRight"
                :disabled="api.limits.send"
                :selected="forms.limits.input_seconds"
                @change="forms.limits.input_seconds = $event;updateAdvanceOptionsDebounce();"
                :options="secondsOptions"
              />
              <template v-if="forms.limits.submitted">
                <small v-if="!$v.forms.limits.input_seconds.required">*Required</small>
              </template> -->
              <div class="d-flex justify-content-between align-items-center w-100">
                <div class="latestGreyBox-descriptionText mt-0">{{forms.limits.input_seconds ? `${forms.limits.input_seconds} seconds` : 'Select seconds limit' }}</div>
                <p v-if="$v.forms.limits.input_seconds.$error">
                  <span v-if="!$v.forms.limits.input_seconds.required">* Input Seconds is required</span>
                </p>
                <div 
                  v-b-tooltip :title="getUserPermissions.ivr_advance_options?'':'Your package does not support this feature'"
                  :class="!getUserPermissions.ivr_advance_options ? 'for-disable' : ''"
                >
                  <a 
                    class="newButton" 
                    @click="!getUserPermissions.ivr_advance_options ? '' : $modal.show(`${_uid}-selectSecondsModal`)"
                  >
                    <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                    <span class="newButton-textPart">Change</span>
                  </a>
                </div>
              </div>
            </b-form-group>
          </div> 
          <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
            <b-form-group class="whiteBGinputWithGreyRoundedBorder dropdownVersion mb-0 w-100">
              <label>Allow the sound file to repeat</label>
              <!-- <vb-select
                class="dialer-dropDown-onRight"
                :disabled="api.limits.send"
                :selected="forms.limits.ivr_nodtmf_repeat_limit"
                @change="forms.limits.ivr_nodtmf_repeat_limit = $event;updateAdvanceOptionsDebounce();"
                :options="repeatTimeOptions"
              />
              <template v-if="forms.limits.submitted">
                <small v-if="!$v.forms.limits.ivr_nodtmf_repeat_limit.required">*Required</small>
              </template> -->
              <div class="d-flex justify-content-between align-items-center w-100">
                <div class="latestGreyBox-descriptionText mt-0">{{forms.limits.ivr_nodtmf_repeat_limit ? `${forms.limits.ivr_nodtmf_repeat_limit} times` : 'Select repeat limit' }}</div>
                <p v-if="$v.forms.limits.ivr_nodtmf_repeat_limit.$error">
                  <span v-if="!$v.forms.limits.ivr_nodtmf_repeat_limit.required">* Sound Repeats is required</span>
                </p>
                <div 
                  v-b-tooltip :title="getUserPermissions.ivr_advance_options?'':'Your package does not support this feature'"
                  :class="!getUserPermissions.ivr_advance_options ? 'for-disable' : ''"
                >
                  <a 
                    class="newButton" 
                    @click="!getUserPermissions.ivr_advance_options ? '' : $modal.show(`${_uid}-selectRepeatModal`)"
                  >
                    <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                    <span class="newButton-textPart">Change</span>
                  </a>
                </div>
              </div>
            </b-form-group>
          </div>  
          <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
            <b-form-group class="whiteBGinputWithGreyRoundedBorder dropdownVersion optionIsdotted mb-0 w-100">
              <label>If no action has been pressed after playing sound file repeatedly</label>
              <!-- <vb-select
                class="dialer-dropDown-onRight"
                :disabled="api.limits.send"
                :selected="forms.limits.cmd"
                @change="onChangeCMD($event)"
                :options="cmdOptions"
                :isCheckShow="true"
              />
              <template v-if="forms.limits.submitted">
                <small v-if="!$v.forms.limits.cmd.required">*Required</small>
              </template> -->
              <div class="d-flex justify-content-between align-items-center w-100">
                <div class="latestGreyBox-descriptionText mt-0">{{selectedCMDOption | get_property('text','Select option') }}</div>
                <p v-if="$v.forms.limits.cmd.$error">
                  <span v-if="!$v.forms.limits.cmd.required">* Sound Repeats is required</span>
                </p>
                <div 
                  v-b-tooltip :title="getUserPermissions.ivr_advance_options?'':'Your package does not support this feature'"
                  :class="!getUserPermissions.ivr_advance_options ? 'for-disable' : ''"
                >
                  <a class="newButton" 
                    @click="!getUserPermissions.ivr_advance_options ? '' : $modal.show(`${_uid}-selectNoActionOptionModal`)"
                  >
                    <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                    <span class="newButton-textPart">Change</span>
                  </a>
                </div>
              </div>
            </b-form-group>
            <div v-if="cmdArgs.account || cmdArgs.dail_number" class="latestGreyBox-9-9-2023 mt-20px w-100">
              <div class="whiteBoxWithBorderInsideGreyBox">
                <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                  <b-form-group v-if="cmdArgs.account" class="whiteBGinputWithGreyRoundedBorder dropdownVersion mb-0 w-100">
                    <label>Assign IVR</label>
                    <!-- <vb-select
                      class="dialer-dropDown-onRight"
                      :disabled="api.limits.send"
                      :selected="forms.limits.account"
                      @change="forms.limits.account = $event;updateAdvanceOptionsDebounce();"
                      :options="ivrOptions"
                      :isCheckShow="true"
                    />
                      <template v-if="forms.limits.submitted">
                        <small class="error-red" v-if="!$v.forms.limits.account.required">*Required</small>
                      </template> -->
                    <div class="d-flex justify-content-between align-items-center w-100">
                      <div class="latestGreyBox-descriptionText mt-0">
                        <Info :id="forms.limits.account" :is_blf="false" :not_show_on_empty="true" :name="'Select option'" :type="'ivr'" />
                      </div>
                      <!-- <p v-if="$v.forms.limits.account.$error" style="color: red;">
                        <span v-if="!$v.forms.limits.account.required">* Sound Repeats is required</span>
                      </p> -->
                      <div 
                        v-b-tooltip :title="getUserPermissions.ivr_advance_options?'':'Your package does not support this feature'"
                        :class="!getUserPermissions.ivr_advance_options ? 'for-disable' : ''"
                      >
                        <a class="newButton" 
                          @click="!getUserPermissions.ivr_advance_options ? '' : $modal.show(`${_uid}-selectAssignIVROptionModal`)"
                        >
                          <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                          <span class="newButton-textPart">Change</span>
                        </a>
                      </div>
                    </div>
                    <p v-if="$v.forms.limits.account.$error" class="mb-0" style="color: red;">
                      <span v-if="!$v.forms.limits.account.required">* Atleast one option needs to be selected</span>
                    </p>
                  </b-form-group>
                  <b-form-group v-if="cmdArgs.dail_number" class="whiteBGinputWithGreyRoundedBorder dropdownVersion mb-0 w-100">
                    <label>Forward the call to user/operator</label>
                    <!-- <vb-select
                      class="dialer-dropDown-onRight"
                      :disabled="api.limits.send"
                      :selected="forms.limits.dail_number"
                      @change="forms.limits.dail_number = $event;updateAdvanceOptionsDebounce();"
                      :options="extensionsOptions"
                      :isCheckShow="true"
                    />
                      <template v-if="forms.limits.submitted">
                        <small class="error-red" v-if="!$v.forms.limits.dail_number.required">*Required</small>
                      </template> -->
                    <div class="d-flex justify-content-between align-items-center w-100">
                      <div class="latestGreyBox-descriptionText mt-0">
                        <Info :id="forms.limits.dail_number" :is_blf="false" :not_show_on_empty="true" :name="'Select option'" />
                      </div>
                      <p v-if="$v.forms.limits.dail_number.$error">
                        <span v-if="!$v.forms.limits.dail_number.required">* Sound Repeats is required</span>
                      </p>
                      <div 
                        v-b-tooltip :title="getUserPermissions.ivr_advance_options?'':'Your package does not support this feature'"
                        :class="!getUserPermissions.ivr_advance_options ? 'for-disable' : ''"
                      >
                        <a class="newButton" 
                          @click="!getUserPermissions.ivr_advance_options ? '' : $modal.show(`${_uid}-selectForwardCallOptionModal`)"
                        >
                          <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                          <span class="newButton-textPart">Change</span>
                        </a>
                      </div>
                    </div>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
          <div v-if="cmdArgs.sound" class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
            <div class="d-flex align-items-end justify-content-between w-100">
              <div class="w-75">
                <div class="latestGreyBox-heading-main">Forward sound</div>
                <div class="latestGreyBox-descriptionText">Played before forwarding call</div>
                <div class="w-75 mt-20px">
                  <vb-audio-player 
                    class="w-100 whiteBGaudioWithGreyRoundedBorder-again greyer" 
                    v-if="selectedSound.sound_file" 
                    :src="selectedSound.sound_file"
                  />
                </div>
                <p v-if="$v.forms.limits.sound_id.$error">
                  <span v-if="!$v.forms.limits.sound_id.required">* Sound Repeats is required</span>
                </p>
              </div>
              <div
                v-b-tooltip :title="getUserPermissions.ivr_advance_options?'':'Your package does not support this feature'"
                :class="!getUserPermissions.ivr_advance_options ? 'for-disable' : ''"
              >
                <button 
                  class="newButton md-mar-left" 
                  :disabled="!getUserPermissions.ivr_advance_options || api.limits.send" 
                  type="button" 
                  @click="api.limits.send ? '' : $modal.show('RecordingListSelecForForward',{ group: 'announcement', sounds: response.common_values.sounds, selected: selectedSound })"
                >
                  <!-- <span>{{ forms.limits.sound_id ? "Change" : "Assign" }}</span> -->
                  <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                  <span class="newButton-textPart">{{ forms.limits.sound_id ? "Change" : "Assign" }}</span>
                </button>
              </div>
            </div>
          </div>
          <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
            <!-- invalid input -->
            <div class="d-flex align-items-end justify-content-between w-100">
              <div class="w-75">
                <div class="latestGreyBox-heading-main">
                  Invalid input sound
                </div>
                <div class="latestGreyBox-descriptionText">
                  When a wrong menu option is pressed, this sound file will be played
                </div>
                <div class="w-75 mt-20px">
                  <vb-audio-player 
                    v-if="forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit" 
                    class="w-100 whiteBGaudioWithGreyRoundedBorder-again greyer" 
                    :src="forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit" 
                  />
                </div>
                <template v-if="forms.update_wrong_ivr_input.submitted && $v.forms.update_wrong_ivr_input.$invalid">
                  <small v-if="!$v.forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit.required">* Required</small>
                </template>
              </div>
              <div
                v-b-tooltip :title="getUserPermissions.ivr_advance_options?'':'Your package does not support this feature'"
                :class="!getUserPermissions.ivr_advance_options ? 'for-disable' : ''"
              >
                <button 
                  class="newButton md-mar-left" 
                  :disabled="!getUserPermissions.ivr_advance_options || api.update_wrong_ivr_input.send" 
                  type="button" 
                  @click="api.update_wrong_ivr_input.send ? '' : $modal.show('RecordingListSelecForWrongInput',{ group: 'announcement' })"
                >
                  <!-- <span>{{ forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit ? "Change" : "Assign" }}</span> -->
                  <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                  <span class="newButton-textPart">{{ forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit ? "Change" : "Assign" }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="cmdArgs.code_dtmf" class="whiteBoxWithBorderInsideGreyBox mt-20px">
          <div class="whiteBoxWithBorderInsideGreyBox-row">
            <b-form-group class="m-0">
              <div>
                <div class="latestGreyBox-heading-main">Code DTMF</div>
              </div>
              <div class="whiteBGinputWithGreyRoundedBorder">
                <div
                  v-b-tooltip :title="getUserPermissions.ivr_advance_options?'':'Your package does not support this feature'"
                >
                  <b-input 
                    type="number" 
                    :disabled="!getUserPermissions.ivr_advance_options"
                    v-model="forms.limits.code_dtmf" 
                    @change="updateAdvanceOptionsDebounce()" 
                  />
                </div>
                <p v-if="$v.forms.limits.code_dtmf.$error">
                  <span v-if="!$v.forms.limits.code_dtmf.required">* Sound Repeats is required</span>
                </p>
              </div>
            </b-form-group>
          </div>
        </div>
      </b-form>
    </b-collapse>
    <RecordingListSelect 
      :is_single_select="true" 
      @selectedRecordings="forms.limits.ivr_wrongdtmf_repeat_limit = $event;updateAdvanceOptionsDebounce()" 
      :modalName="'RecordingListSelecOnAdvance'" 
    />
    <RecordingListSelect
      :is_single_select="true"
      @selectedRecordings="updateWrongIVRInput($event)"
      :modalName="'RecordingListSelecForWrongInput'"
    />
    <RecordingListSelect
      :is_single_select="true"
      @selectedRecordings="forms.limits.sound_id = $event.sound;updateAdvanceOptionsDebounce('forward_sound')"
      :modalName="'RecordingListSelecForForward'"
    />
    <SelectSounds @selected-sound="forms.limits.sound_id = $event.sound;updateAdvanceOptionsDebounce('forward_sound')" />
    <SelectAccounts @selected-account="forms.limits.account = $event.accountcode;updateAdvanceOptionsDebounce('assign_ivr')" />
    <SelectExtensionModal @select="forms.limits.dail_number=$event.extn;updateAdvanceOptionsDebounce()" />
    <IVRselectActionsModal />
    <modal class="modalForDropdown" width="600px" height="auto" :scrollable="true" :name="`${_uid}-selectSecondsModal`">
      <section class="ct-user-new dialer-edit">
        <div class="dialer-edit-header d-flex w-100 align-items-start justify-content-between pt-0">
          <div>
            <h2 class="dialer-settings-title newer">Repeat menu sound file if no action is pressed after</h2>
            <!-- <div class="dialer-settings-intro newerTextVersion w-75 mb-0">
              Choose your preferred ringtone here, you'll hear a preview when you've selected one
            </div> -->
          </div>
          <div class="dialer-edit-actions">
            <button class="newDoneButton" @click="$modal.hide(`${_uid}-selectSecondsModal`)">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
        </div>
        <div class="latestGreyBox-9-9-2023 hasScrollBarInsideIt mt-20px" style="--total: 90vh; --minusValue: 75px;">
          <div class="whiteBoxWithBorderInsideGreyBox">
            <div class="whiteBoxWithBorderInsideGreyBox-row min-height-none" v-for="option in secondsOptions" :key="option.id" @click="forms.limits.input_seconds = option.value;updateAdvanceOptionsDebounce('repeat_sound_seconds');" >
              <div class="d-flex align-items-center">
                <div  :class="`actLikeSquareRadio mr-20px ${forms.limits.input_seconds==option.value ? 'checked' : '' }`"></div>
                <div class="latestGreyBox-heading-main">{{ option.text }}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </modal>
    <modal class="modalForDropdown" width="600px" height="auto" :scrollable="true" :name="`${_uid}-selectRepeatModal`">
      <section class="ct-user-new dialer-edit">
        <div class="dialer-edit-header d-flex w-100 align-items-start justify-content-between pt-0">
          <div>
            <h2 class="dialer-settings-title newer">Allow the sound file to repeat</h2>
            <div class="dialer-settings-intro newerTextVersion w-75 mb-0">
              <!-- Choose your preferred ringtone here, you'll hear a preview when you've selected one -->
            </div>
          </div>
          <div class="dialer-edit-actions">
            <button class="newDoneButton" @click="$modal.hide(`${_uid}-selectRepeatModal`)">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
        </div>
        <div class="latestGreyBox-9-9-2023 hasScrollBarInsideIt mt-20px" style="--total: 90vh; --minusValue: 75px;">
          <div class="whiteBoxWithBorderInsideGreyBox">
            <div class="whiteBoxWithBorderInsideGreyBox-row min-height-none" v-for="option in repeatTimeOptions" :key="option.id" @click="forms.limits.ivr_nodtmf_repeat_limit = option.value;updateAdvanceOptionsDebounce('repeat_sound_time');" >
              <div class="d-flex align-items-center">
                <div  :class="`actLikeSquareRadio mr-20px ${forms.limits.ivr_nodtmf_repeat_limit==option.value ? 'checked' : '' }`"></div>
                <div class="latestGreyBox-heading-main">{{ option.text }}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </modal>
    <modal class="modalForDropdown" width="600px" height="auto" :scrollable="true" :name="`${_uid}-selectNoActionOptionModal`">
      <section class="ct-user-new dialer-edit">
        <div class="dialer-edit-header d-flex w-100 align-items-start justify-content-between pt-0">
          <div>
            <h2 class="dialer-settings-title newer">If no action has been pressed</h2>
            <div class="dialer-settings-intro newerTextVersion w-75 mb-0">
              <!-- Choose your preferred ringtone here, you'll hear a preview when you've selected one -->
            </div>
          </div>
          <div class="dialer-edit-actions">
            <button class="newDoneButton" @click="$modal.hide(`${_uid}-selectNoActionOptionModal`)">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
        </div>
        <div class="latestGreyBox-9-9-2023 hasScrollBarInsideIt mt-20px" style="--total: 90vh; --minusValue: 75px;">
          <div class="whiteBoxWithBorderInsideGreyBox">
            <div v-if="cmdOptions.length == 0" class="whiteBoxWithBorderInsideGreyBox-row min-height-none" >
              <div class="d-flex align-items-center">
                <div class="latestGreyBox-heading-main">No record found</div>
              </div>
            </div>
            <template v-else>
              <div class="whiteBoxWithBorderInsideGreyBox-row min-height-none" v-for="option in cmdOptions" :key="option.value" @click="onChangeCMD(option.value);" >
                <div class="d-flex align-items-center">
                  <div  :class="`actLikeSquareRadio mr-20px ${forms.limits.cmd==option.value ? 'checked' : '' }`"></div>
                  <div class="latestGreyBox-heading-main">{{ option.text }}</div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </section>
    </modal>
    <modal class="modalForDropdown" width="600px" height="auto" :scrollable="true" :name="`${_uid}-selectAssignIVROptionModal`">
      <section class="ct-user-new dialer-edit">
        <div class="dialer-edit-header d-flex w-100 align-items-start justify-content-between pt-0">
          <div>
            <h2 class="dialer-settings-title newer">Assign IVR</h2>
            <div class="dialer-settings-intro newerTextVersion w-75 mb-0">
              <!-- Choose your preferred ringtone here, you'll hear a preview when you've selected one -->
            </div>
          </div>
          <div class="dialer-edit-actions">
            <button class="newDoneButton" @click="$modal.hide(`${_uid}-selectAssignIVROptionModal`)">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
        </div>
        <div class="latestGreyBox-9-9-2023 hasScrollBarInsideIt mt-20px" style="--total: 90vh; --minusValue: 75px;">
          <div class="whiteBoxWithBorderInsideGreyBox">
            <div v-if="ivrOptions.length == 0" class="whiteBoxWithBorderInsideGreyBox-row min-height-none" >
              <div class="d-flex align-items-center">
                <div class="latestGreyBox-heading-main">No record found</div>
              </div>
            </div>
            <template v-else>
              <div class="whiteBoxWithBorderInsideGreyBox-row min-height-none" v-for="option in ivrOptions" :key="option.id" @click="forms.limits.account = option.value;updateAdvanceOptionsDebounce('assign_ivr');" >
                <div class="d-flex align-items-center">
                  <div  :class="`actLikeSquareRadio mr-20px ${forms.limits.account==option.value ? 'checked' : '' }`"></div>
                  <div class="latestGreyBox-heading-main">
                    <Info :id="option.value" :not_show_on_empty="true" :name="option.text" :type="'ivr'" />
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </section>
    </modal>
    <modal class="MenuOptionExtensionAssignModal dialer_animation right_side_popup m-w-500 bigSquareCheckbox min-width-50percent-modal" width="600px" height="auto" :scrollable="true" :name="`${_uid}-selectForwardCallOptionModal`" @before-close="filters.forward_exten.tab=forwardExtenTabs.USER.value;">
      <section class="ct-user-new dialer-edit">
        <div class="dialer-edit-header d-flex w-100 align-items-start justify-content-between pt-0 mt-16px">
          <div>
            <!-- <h2 class="dialer-settings-title newer">Forward call to</h2> -->
            <h2 class="dialer-settings-title newer">Assign to</h2>
            <div class="dialer-settings-intro newerTextVersion w-75 mb-0">
              <!-- Choose your preferred ringtone here, you'll hear a preview when you've selected one -->
            </div>
          </div>
          <div class="dialer-edit-actions">
            <button class="newDoneButton" @click="$modal.hide(`${_uid}-selectForwardCallOptionModal`);">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
        </div>
        <div class="TabsDesignWithIcon-container mt-20px">
          <div class="TabsDesignWithIcon-item" v-for="tab in forwardExtenTabs" :key="tab.value" 
            @click="filters.forward_exten.tab=tab.value;" 
            :class="{'active': filters.forward_exten.tab==tab.value}"
          >
            <vb-icon :icon="tab.icon" height="38px" width="38px" />
            <span class="TabsDesignWithIcon-item-textPart">{{tab.text}}</span>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-end w-100 mt-20px">
          <!-- <div class="latestGreyBox-heading-main-24px">{{filters.forward_exten.tab}}</div> -->
          <div class="w-50 whiteBGinputWithGreyRoundedBorder">
            <b-input type="text" v-model="filters.forward_exten.search" placeholder="Search user, extension" />
          </div>
        </div>
        <div class="latestGreyBox-9-9-2023 hasScrollBarInsideIt mt-20px" style="--total: 90vh; --minusValue: 75px;">
          <div class="whiteBoxWithBorderInsideGreyBox">
            <!-- <input v-model="filters.forward_exten.search" />
            <div 
              v-for="tab in forwardExtenTabs" 
              :key="tab.value" 
              :class="{
                'active': filters.forward_exten.tab==tab.value
              }" 
              @click="filters.forward_exten.tab=tab.value;updateAdvanceOptionsDebounce();"
            >
              {{ tab.text }}
            </div> -->
            <div 
              class="whiteBoxWithBorderInsideGreyBox-row min-height-none" 
              v-for="extension in extensions" 
              :key="extension.extn" 
              @click="forms.limits.dail_number = extension.extn;updateAdvanceOptionsDebounce('forward_to');" 
            >
              <div class="d-flex align-items-center">
                <div class="actLikeSquareRadio mr-20px" :class="{'checked': forms.limits.dail_number==extension.extn}" />
                <div class="latestGreyBox-heading-main">
                  <Info :id="extension.extn" :type="extension.account" :is_blf="false" />
                </div>
              </div>
            </div>
            <vb-no-record class="my-5" v-if="extensions.length==0" :text="'Sorry, no Data Found'" :design="3">
              <!-- <vb-loading class="my-2" v-if="api_sent.extensions_list" slot="loading" /> -->
            </vb-no-record>
          </div>
        </div>
      </section>
    </modal>
  </div>
</template>

<script>
import { VOIP_API } from '../../utils';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import required from "vuelidate/lib/validators/required";
import requiredIf from "vuelidate/lib/validators/requiredIf";
import RecordingListSelect from "../Modals/RecordingListSelect.vue";
import SelectExtensionModal from "../Modals/SelectExtensionModal.vue";
import IVRselectActionsModal from "../Modals/IVRselectActionsModal.vue";
import SelectSounds from "./SelectSounds.vue";
import SelectAccounts from "./SelectAccounts.vue";
import Info from '../Lists/Info.vue';
const seconds_options = [
  { 
    text: '5 seconds', 
    value: 5 
  },
  { 
    text: '10 seconds', 
    value: 10 
  },
  { 
    text: '15 seconds', 
    value: 15 
  },
  { 
    text: '20 seconds', 
    value: 20 
  },
  { 
    text: '25 seconds', 
    value: 25 
  },
]
const repeat_time_options = [
  { 
    text: '2 times', 
    value: 2 
  },
  { 
    text: '3 times', 
    value: 3 
  },
  { 
    text: '4 times', 
    value: 4 
  },
  { 
    text: '5 times', 
    value: 5 
  },
  { 
    text: '6 times', 
    value: 6 
  },
  { 
    text: '7 times', 
    value: 7 
  },
  { 
    text: '8 times', 
    value: 8 
  },
  { 
    text: '9 times', 
    value: 9 
  },
  { 
    text: '10 times', 
    value: 10 
  },
]
const forward_exten_tabs = {
  USER: {
    value: 'USER',
    text: 'Users',
    icon: 'squared-singleUser-icon'
  },
  TEAM: {
    value: 'TEAM',
    text: 'Teams',
    icon: 'squared-multipleUsers-icon'
  },
  BASICIVR: {
    value: 'BASICIVR',
    text: 'IVRs',
    icon: 'squared-ivr-icon'
  },
  QUEUE: {
    value: 'QUEUE',
    text: 'Call Queues',
    icon: 'squared-callQueue-icon'
  },
}
export default {
  name: 'AdvanceOptions',
  components: {
    RecordingListSelect,
    SelectExtensionModal,
    SelectSounds,
    SelectAccounts,
    IVRselectActionsModal,
    Info,
  },
  props: {
    accountcode: {
      type: String,
      default: '',
    },
  },
  data(){
    return {
      forms: {
        limits: this.$helperFunction.formInstance({
          data: {
            ivr_nodtmf_repeat_limit: "",
            ivr_wrongdtmf_repeat_limit: null,
            input_seconds: "",
            cmd: "",
            account: "",
            sound_id: "",
            code_dtmf: "",
            dail_number: "",
          }
        }),
        update_wrong_ivr_input: this.$helperFunction.formInstance({
          data: {
            ivr_wrongdtmf_repeat_limit: '',
          }
        }),
      },
      api: {
        fetch_files: this.$helperFunction.apiInstance(),
        limits: this.$helperFunction.apiInstance(),
        common_values: this.$helperFunction.apiInstance(),
        advance_value: this.$helperFunction.apiInstance(),
        extensions_list: this.$helperFunction.apiInstance(),
        update_wrong_ivr_input: this.$helperFunction.apiInstance(),
      },
      filters: {
        forward_exten: {
          search: '',
          tab: forward_exten_tabs.USER.value
        },
      },
      response: {
        common_values: {},
        extensions: {},
        files: {},
        advance_options: {},
      },
      conditions: {
        is_view: false,
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getUserPermissions'
    ]),
    forwardExtenTabs(){ return forward_exten_tabs },
    secondsOptions(){ return seconds_options },
    repeatTimeOptions(){ return repeat_time_options },
    cmdOptions() {
      if (!this.response.common_values.cmd_details) return [];
      return this.response.common_values.cmd_details.map((item) => {
        return {
          text: item.display_label || '',
          value: item.ivr_cmd,
        };
      });
    },
    selectedCMDOption(){ return this.cmdOptions?.find?.(i=>i.value==this.forms.limits.cmd) },
    ivrOptions(){
      return Object.values(this.response?.common_values?.accounts ?? {})
      .filter((i) => i.accountcode!=this.accountcode && i.account_type=="BASICIVR")
      .map(i=>({
        text: i.accountname,
        value: i.accountcode,
      }));
    },
    accountsFilter() {
      if (!this.response.common_values.accounts) return [];
      let array = [];
      if (this.forms.limits.cmd == "ivrfwd") {
        array = Object.values(this.response.common_values.accounts).filter((data) => data.accountcode!=this.accountcode && data.account_type=="BASICIVR");
      } else {
        array = Object.values(this.response.common_values.accounts);
      }
      return array
    },
    cmdArgs() {
      const ivr_cmd = this.forms.limits.cmd;
      if (!this.response.common_values.cmd_details) return {};
      const [cmd] = this.response.common_values.cmd_details.filter(
        (item) => item.ivr_cmd == ivr_cmd
      );
      if (!cmd) return {};
      return {
        account: cmd.ivr_cmd_args.includes("accountcode"),
        sound: cmd.ivr_cmd_args.includes("soundfile"),
        dail_number: cmd.ivr_cmd_args.includes("dial-number"),
        code_dtmf: cmd.ivr_cmd_args.includes("code-dtmf"),
        ivr_cmd_args: cmd.ivr_cmd_args,
      };
    },
    ivrCmdArgsValue() {
      if (!this.cmdArgs.ivr_cmd_args) return "";
      const ivr_cmd_args = this.cmdArgs.ivr_cmd_args;
      const str = ivr_cmd_args.includes("soundfile", this.forms.limits.sound_id) ? 
      this.selectedSound.type=='custom' ?
      `${this.getCurrentUser.uid}/${this.getCurrentUser.default_accountcode}/${this.selectedSound.group}/${this.selectedSound.sound}`
      :
      `${this.selectedSound.uid}/${this.selectedSound.accountcode}/${this.selectedSound.type}/${this.selectedSound.sound}` 
      : 
      "";
      return ivr_cmd_args
        .replace("accountcode", this.forms.limits.account)
        .replace("soundfile", str)
        .replace("dial-number", this.forms.limits.dail_number)
        .replace("code-dtmf", this.forms.limits.code_dtmf)
        .replace(/{/g, "")
        .replace(/}/g, "")
      ;
    },
    selectedAccount(){
      if(!this.response.common_values.accounts) return {}
      return this.response.common_values.accounts[this.forms.limits.account] || {}
    },
    selectedSound(){
      let file1 = (this.response.files?.files?.data || this.response.files?.files)?.find?.(i=>i.sound==this.forms.limits.sound_id)
      let file2 = (this.response.files?.shared?.data || this.response.files?.shared)?.find?.(i=>i.sound==this.forms.limits.sound_id)
      let file3 = (this.response.files?.global?.data || this.response.files?.global)?.find?.(i=>i.sound==this.forms.limits.sound_id)
      let cmd_file = this.response.advance_options?.setting?.setting?.ivr_cmd_args_file
      if(cmd_file) {
        cmd_file = {
          sound_file: cmd_file
        }
      }
      let ivr_forward = this.forms.limits.sound_id == this.response.common_values.ivr_forward.sound ? this.response.common_values.ivr_forward : null
      // let common_value_files = this.response.common_values?.sounds?.[this.forms.limits.sound_id]
      return ivr_forward || file1 || file2 || file3 || cmd_file /*|| common_value_files*/ || {}
      // if(!this.response.common_values.sounds) return {}
      
      // return this.response.common_values.sounds[this.forms.limits.sound_id] || {}
    },
    extensions(){
      const search = this.filters.forward_exten.search.trim().toLowerCase()
      return Object.values(this.response.extensions)?.filter?.(i=>/*i.accountcode!=this.getCurrentUser.account && */i.account==this.filters.forward_exten.tab && `${i.accountname} ${i.extn}`.trim().toLowerCase().includes(search)) ?? []
    },
    selectedExtension(){ return this.extensions.find(data=>data.extn==this.forms.limits.dail_number) },
  },
  validations: {
    forms: {
      update_wrong_ivr_input: {
        ivr_wrongdtmf_repeat_limit: {
          required: required,
        },
      },
      limits: {
        ivr_nodtmf_repeat_limit: {
          required: required,
        },
        input_seconds: { 
          required: required,
        },
        cmd: { 
          required: required,
        },
        account: {
          required: requiredIf(function () {
            return this.cmdArgs.account;
          }),
        },
        sound_id: {
          required: requiredIf(function () {
            return this.cmdArgs.sound;
          }),
        },
        code_dtmf: {
          required: requiredIf(function () {
            return this.cmdArgs.code_dtmf;
          }),
        },
        dail_number: {
          required: requiredIf(function () {
            return this.cmdArgs.dail_number;
          }),
        },
      },
    },
  },
  methods: {
    async onChangeCMD(value){
      this.forms.limits.cmd = value;
      await this.$nextTick()
      this.forms.limits.account = this.cmdArgs.account ? this.ivrOptions?.[0]?.value || '' : ''
      this.forms.limits.dail_number = this.cmdArgs.dail_number ? this.extensions?.[0]?.extn || '' : ''
      this.forms.limits.sound_id = this.cmdArgs.sound ? this.response.common_values.ivr_forward.sound || '' : ''
      this.updateAdvanceOptionsDebounce('change_cmd');
    },
    onCollapseState(collapseId, isJustShown){
      if(collapseId=='advanceOptions-collapse'){
        this.conditions.is_view=isJustShown
      }
    },
    updateAdvanceOptionsDebounce: _.debounce(function(flag) {
      this.updateAdvanceOptions(flag)
    },2 * 1000),
    async updateAdvanceOptions(flag) {
      this.forms.limits.submitted = true;
      this.$v.forms.limits.$touch();
      if (this.$v.forms.limits.$invalid || this.api.limits.send) return;
      try {
        this.api.limits.send = true;
        await VOIP_API.endpoints.menu.advancesetting({
          ivr_wrongdtmf_repeat_limit: this.forms.limits.ivr_nodtmf_repeat_limit,
          ivr_nodtmf_repeat_limit: this.forms.limits.ivr_nodtmf_repeat_limit,
          input_seconds: this.forms.limits.input_seconds,
          cmd: this.forms.limits.cmd,
          ivr_cmd_args: this.ivrCmdArgsValue,
          account: this.accountcode,
          flag: flag,
        })
        this.appNotify({
          message: "Successfully updated!",
          type: "success",
        })
        this.$emit('updated')
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.forms.limits.submitted = false;
        this.api.limits.send = false;
      }
    },
    async getCommonValues() {
      try {
        this.api.common_values.send = true;
        const { data } = await VOIP_API.endpoints.menu.getcommonvalues()
        this.response.common_values = data;
      } finally {
        this.api.common_values.send = false;
      }
    },
    async getIvrAdvanceValue() {
      try {
        this.api.advance_value.send = true;
        const { data } = await VOIP_API.endpoints.menu.getivradvanceoption(this.accountcode)
        this.response.advance_options=data
        if (!data.setting.setting) return;
        this.forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit = data?.setting?.setting?.ivr_wrong_input_alert_file || '';
        this.forms.limits.ivr_nodtmf_repeat_limit = data?.setting?.setting?.ivr_nodtmf_repeat_limit || "";
        this.forms.limits.ivr_wrongdtmf_repeat_limit = data?.setting?.setting?.ivr_wrongdtmf_repeat_limit || null;
        this.forms.limits.input_seconds = data?.setting?.setting?.input_seconds || "";
        this.forms.limits.cmd = data?.setting?.setting?.ivr_cmd?.ivr_cmd || "";
        const args = data?.setting?.setting?.ivr_cmd?.ivr_cmd_args?.replace?.(/{/g, "")?.replace?.(/}/g, "")?.split?.("^") ?? [];  // "{soundfile}^{dial-number}"
        const args_val = data?.setting?.setting?.ivr_error_cmd_args?.split?.("^") ?? []; // 5576/700055760000/ivr/h2ZTXS-1707238336^2001
        this.forms.limits.account = args_val[args.indexOf("accountcode")] || "";
        this.forms.limits.sound_id = args_val[args.indexOf("soundfile")] ? args_val[args.indexOf("soundfile")].split('/')[args_val[args.indexOf("soundfile")].split('/').length-1] : ""; // h2ZTXS-1707238336
        this.forms.limits.code_dtmf = args_val[args.indexOf("code-dtmf")] || "";
        this.forms.limits.dail_number = args_val[args.indexOf("dial-number")] || "";
      } finally {
        this.api.advance_value.send = false;
      }
    },
    async updateWrongIVRInput(event){
      if(this.api.update_wrong_ivr_input.send) return;
      try {
        this.api.update_wrong_ivr_input.send=true
        await VOIP_API.endpoints.menu.updateWrongIVRInput({
          file: event.sound,
          account: this.accountcode,
        })
        this.forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit=event.sound_file
        this.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.update_wrong_ivr_input.send=false
      }
    },
    async fetchExtensions() {
      try {
        this.api.extensions_list.send=true
        const { data } = await VOIP_API.endpoints.telephone_number.extensions()
        this.response.extensions = data?.data ?? {};
      } finally {
        this.api.extensions_list.send=false
      }
    },
    async fetchFiles() {
      if(this.api.fetch_files.send) return;
      try {
        this.api.fetch_files.send=true
        const { data } = await VOIP_API.endpoints.recording.list({
          uid: this.getCurrentUser?.uid,
          accountcode: this.getCurrentUser?.account,
        })
        this.response.files=data
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_files.send=false
      }
    },
  },
  created(){
    this.$root.$on('bv::collapse::state', this.onCollapseState.bind(this))
  },
  mounted() {
    this.fetchFiles()
    this.getCommonValues();
    this.getIvrAdvanceValue();
    this.fetchExtensions()
  },
  activated() {
    this.fetchFiles()
    this.getCommonValues();
    this.getIvrAdvanceValue();
    this.fetchExtensions()
  },
  beforeDestroy(){
    this.$root.$off('bv::collapse::state', this.onCollapseState.bind(this))
  },
}
</script>

<style>

</style>