var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-flex alt"
  }, [true ? [!_vm.getIsMobile ? [_c('div', {
    staticClass: "d-flex flex-column w-100"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "dialer-incommingVideoCall-info d-flex align-items-center"
  }, [_c('div', {
    staticClass: "dialer-user-dp mr-2"
  }, [_c('img', {
    staticClass: "rounded",
    attrs: {
      "width": "40",
      "height": "40",
      "src": _vm.callObjInfo.image
    },
    on: {
      "error": function ($event) {
        $event.target.src = _vm.userDp;
      }
    }
  })]), _c('div', {
    staticClass: "dialer-user-bio"
  }, [_c('h6', {
    staticClass: "text-capitalize mb-0"
  }, [_vm._v(_vm._s(_vm.callObjInfo.name))]), _c('p', {
    staticClass: "text-capitalize mb-0"
  }, [_vm._v(_vm._s(_vm.callObjInfo.sub_info))]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('p', {
    staticClass: "m-0 pr-2"
  }, [_vm._v("Time Left: " + _vm._s(_vm._f("time_audio")(_vm.data.time)))]), _c('b-icon', {
    attrs: {
      "icon": "camera-video"
    }
  })], 1)])]), _c('div', {
    staticClass: "d-flex"
  }, [_c('span', {
    staticClass: "md-mar-left"
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "dialer-videoInBox-icon",
      "width": "40",
      "height": "40"
    },
    on: {
      "click": function ($event) {
        return _vm.data.accept('video');
      }
    }
  })], 1), _c('span', {
    staticClass: "md-mar-left"
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "dialer-callAcceptInbox-icon",
      "width": "40",
      "height": "40"
    },
    on: {
      "click": function ($event) {
        return _vm.data.accept('audio');
      }
    }
  })], 1), _c('span', {
    staticClass: "md-mar-left"
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "dialer-callRejectInbox-icon",
      "width": "40",
      "height": "40"
    },
    on: {
      "click": function ($event) {
        return _vm.data.reject();
      }
    }
  })], 1)])]), _vm._t("switchCalls")], 2)] : _vm._e(), _vm.getIsMobile ? [_vm.isMobileMain ? [_c('div', {
    staticClass: "dialer-incommingVideoCall-info v2 d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "incommingCall-topBar"
  }, [_c('span', {
    staticClass: "w-100 text-center"
  }, [_vm._v("Incoming Call ...")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-icon', {
    staticClass: "settings-back-icon cursor_pointer",
    attrs: {
      "icon": "chevron-left",
      "font-scale": "1.3"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('to-dashboard');
      }
    }
  }), _c('div', {
    staticClass: "dialer-user-bio"
  }, [_c('h6', {
    staticClass: "text-capitalize mb-0"
  }, [_vm._v(_vm._s(_vm.callObjInfo.name))]), _c('p', {
    staticClass: "text-capitalize mb-0"
  }, [_vm._v(_vm._s(_vm.callObjInfo.sub_info))]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('p', {
    staticClass: "m-0 pr-2"
  }, [_vm._v("Time Left: " + _vm._s(_vm._f("time_audio")(_vm.data.time)))]), _c('b-icon', {
    attrs: {
      "icon": "camera-video"
    }
  })], 1)]), _c('vb-icon', {
    attrs: {
      "icon": "mobile-duringCall-switchCall-icon",
      "width": "19.461",
      "height": "19.492"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('calls-list');
      }
    }
  })], 1)]), _c('div', {
    staticClass: "incommingCall-middleBar"
  }, [_c('div', {
    staticClass: "dialer-user-dp"
  }, [_c('img', {
    staticClass: "rounded",
    attrs: {
      "width": "40",
      "height": "40",
      "src": _vm.callObjInfo.image
    },
    on: {
      "error": function ($event) {
        $event.target.src = _vm.userDp;
      }
    }
  })])]), _c('div', {
    staticClass: "incommingCall-bottomBar"
  }, [_c('div', {
    staticClass: "d-flex px-3 w-100 justify-content-between mb-3"
  }, [_c('span', {
    staticClass: "md-mar-right"
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "video-IncomingCall-video-icon",
      "width": "57",
      "height": "57"
    },
    on: {
      "click": function ($event) {
        return _vm.data.accept('video');
      }
    }
  })], 1), _c('span', {
    staticClass: "md-mar-left"
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "video-IncomingCall-audio-icon",
      "width": "57",
      "height": "57"
    },
    on: {
      "click": function ($event) {
        return _vm.data.accept('audio');
      }
    }
  })], 1)]), _c('span', {
    staticClass: "w-100 d-flex justify-content-center"
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "video-call-hangupIcon-icon",
      "width": "57",
      "height": "57"
    },
    on: {
      "click": function ($event) {
        return _vm.data.reject();
      }
    }
  })], 1)])])] : [_c('div', {
    staticClass: "jitsiIncomming_call_bar"
  }, [_c('div', {
    staticClass: "dialer-incommingVideoCall-info d-flex align-items-center"
  }, [_c('div', {
    staticClass: "dialer-user-dp mr-2"
  }, [_c('img', {
    staticClass: "rounded",
    attrs: {
      "width": "40",
      "height": "40",
      "src": _vm.callObjInfo.image
    },
    on: {
      "error": function ($event) {
        $event.target.src = _vm.userDp;
      }
    }
  })]), _c('div', {
    staticClass: "dialer-user-bio"
  }, [_c('p', {
    staticClass: "m-0 pr-2 small"
  }, [_vm._v("Incoming Call...")]), _c('h6', {
    staticClass: "text-capitalize mb-0"
  }, [_vm._v(_vm._s(_vm.callObjInfo.name))]), _c('p', {
    staticClass: "text-capitalize mb-0"
  }, [_vm._v(_vm._s(_vm.callObjInfo.sub_info))]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('p', {
    staticClass: "m-0 pr-2"
  }, [_vm._v("Time Left: " + _vm._s(_vm._f("time_audio")(_vm.data.time)))]), _c('b-icon', {
    attrs: {
      "icon": "camera-video"
    }
  })], 1)])]), _c('div', {
    staticClass: "d-flex jitsiIncomming_call_bar_actions"
  }, [_c('span', {
    staticClass: "md-mar-left"
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "video-IncomingCall-video-icon",
      "width": "40",
      "height": "40"
    },
    on: {
      "click": function ($event) {
        return _vm.data.accept('video');
      }
    }
  })], 1), _c('span', {
    staticClass: "md-mar-left"
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "video-IncomingCall-audio-icon",
      "width": "40",
      "height": "40"
    },
    on: {
      "click": function ($event) {
        return _vm.data.accept('audio');
      }
    }
  })], 1), _c('span', {
    staticClass: "md-mar-left"
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "video-call-hangupIcon-icon",
      "width": "40",
      "height": "40"
    },
    on: {
      "click": function ($event) {
        return _vm.data.reject();
      }
    }
  })], 1)])])]] : _vm._e()] : [!_vm.getIsMobile ? [_c('div', {
    staticClass: "d-flex flex-column w-100"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "dialer-incommingVideoCall-info d-flex align-items-center"
  }, [_c('div', {
    staticClass: "dialer-user-dp mr-2"
  }, [_c('img', {
    staticClass: "rounded",
    attrs: {
      "width": "40",
      "height": "40",
      "src": _vm.callObjInfo.image
    },
    on: {
      "error": function ($event) {
        $event.target.src = _vm.userDp;
      }
    }
  })]), _c('div', {
    staticClass: "dialer-user-bio"
  }, [_c('h6', {
    staticClass: "text-capitalize mb-0"
  }, [_vm._v(_vm._s(_vm.callObjInfo.name))]), _c('p', {
    staticClass: "text-capitalize mb-0"
  }, [_vm._v(_vm._s(_vm.callObjInfo.sub_info))]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('p', {
    staticClass: "m-0 pr-2"
  }, [_vm._v("Time Left: " + _vm._s(_vm._f("time_audio")(_vm.time)))]), _c('b-icon', {
    attrs: {
      "icon": "camera-video"
    }
  })], 1)])]), _c('div', {
    staticClass: "d-flex"
  }, [_c('span', {
    staticClass: "md-mar-left"
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "dialer-videoInBox-icon",
      "width": "40",
      "height": "40"
    },
    on: {
      "click": function ($event) {
        return _vm.accept('video');
      }
    }
  })], 1), _c('span', {
    staticClass: "md-mar-left"
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "dialer-callAcceptInbox-icon",
      "width": "40",
      "height": "40"
    },
    on: {
      "click": function ($event) {
        return _vm.accept('audio');
      }
    }
  })], 1), _c('span', {
    staticClass: "md-mar-left"
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "dialer-callRejectInbox-icon",
      "width": "40",
      "height": "40"
    },
    on: {
      "click": _vm.reject
    }
  })], 1)])]), _vm._t("switchCalls")], 2)] : _vm._e(), _vm.getIsMobile ? [_vm.isMobileMain ? [_c('div', {
    staticClass: "dialer-incommingVideoCall-info v2 d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "incommingCall-topBar"
  }, [_c('span', {
    staticClass: "w-100 text-center"
  }, [_vm._v("Incoming Call ...")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-icon', {
    staticClass: "settings-back-icon cursor_pointer",
    attrs: {
      "icon": "chevron-left",
      "font-scale": "1.3"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('to-dashboard');
      }
    }
  }), _c('div', {
    staticClass: "dialer-user-bio"
  }, [_c('h6', {
    staticClass: "text-capitalize mb-0"
  }, [_vm._v(_vm._s(_vm.callObjInfo.name))]), _c('p', {
    staticClass: "text-capitalize mb-0"
  }, [_vm._v(_vm._s(_vm.callObjInfo.sub_info))]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('p', {
    staticClass: "m-0 pr-2"
  }, [_vm._v("Time Left: " + _vm._s(_vm._f("time_audio")(_vm.time)))]), _c('b-icon', {
    attrs: {
      "icon": "camera-video"
    }
  })], 1)]), _c('vb-icon', {
    attrs: {
      "icon": "mobile-duringCall-switchCall-icon",
      "width": "19.461",
      "height": "19.492"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('calls-list');
      }
    }
  })], 1)]), _c('div', {
    staticClass: "incommingCall-middleBar"
  }, [_c('div', {
    staticClass: "dialer-user-dp"
  }, [_c('img', {
    staticClass: "rounded",
    attrs: {
      "width": "40",
      "height": "40",
      "src": _vm.callObjInfo.image
    },
    on: {
      "error": function ($event) {
        $event.target.src = _vm.userDp;
      }
    }
  })])]), _c('div', {
    staticClass: "incommingCall-bottomBar"
  }, [_c('div', {
    staticClass: "d-flex px-3 w-100 justify-content-between mb-3"
  }, [_c('span', {
    staticClass: "md-mar-right"
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "video-IncomingCall-video-icon",
      "width": "57",
      "height": "57"
    },
    on: {
      "click": function ($event) {
        return _vm.accept('video');
      }
    }
  })], 1), _c('span', {
    staticClass: "md-mar-left"
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "video-IncomingCall-audio-icon",
      "width": "57",
      "height": "57"
    },
    on: {
      "click": function ($event) {
        return _vm.accept('audio');
      }
    }
  })], 1)]), _c('span', {
    staticClass: "w-100 d-flex justify-content-center"
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "video-call-hangupIcon-icon",
      "width": "57",
      "height": "57"
    },
    on: {
      "click": _vm.reject
    }
  })], 1)])])] : [_c('div', {
    staticClass: "jitsiIncomming_call_bar"
  }, [_c('div', {
    staticClass: "dialer-incommingVideoCall-info d-flex align-items-center"
  }, [_c('div', {
    staticClass: "dialer-user-dp mr-2"
  }, [_c('img', {
    staticClass: "rounded",
    attrs: {
      "width": "40",
      "height": "40",
      "src": _vm.callObjInfo.image
    },
    on: {
      "error": function ($event) {
        $event.target.src = _vm.userDp;
      }
    }
  })]), _c('div', {
    staticClass: "dialer-user-bio"
  }, [_c('p', {
    staticClass: "m-0 pr-2 small"
  }, [_vm._v("Incoming Call...")]), _c('h6', {
    staticClass: "text-capitalize mb-0"
  }, [_vm._v(_vm._s(_vm.callObjInfo.name))]), _c('p', {
    staticClass: "text-capitalize mb-0"
  }, [_vm._v(_vm._s(_vm.callObjInfo.sub_info))]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('p', {
    staticClass: "m-0 pr-2"
  }, [_vm._v("Time Left: " + _vm._s(_vm._f("time_audio")(_vm.time)))]), _c('b-icon', {
    attrs: {
      "icon": "camera-video"
    }
  })], 1)])]), _c('div', {
    staticClass: "d-flex jitsiIncomming_call_bar_actions"
  }, [_c('span', {
    staticClass: "md-mar-left"
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "video-IncomingCall-video-icon",
      "width": "40",
      "height": "40"
    },
    on: {
      "click": function ($event) {
        return _vm.accept('video');
      }
    }
  })], 1), _c('span', {
    staticClass: "md-mar-left"
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "video-IncomingCall-audio-icon",
      "width": "40",
      "height": "40"
    },
    on: {
      "click": function ($event) {
        return _vm.accept('audio');
      }
    }
  })], 1), _c('span', {
    staticClass: "md-mar-left"
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "video-call-hangupIcon-icon",
      "width": "40",
      "height": "40"
    },
    on: {
      "click": _vm.reject
    }
  })], 1)])])]] : _vm._e()]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }