<template>
  <div v-if="getIsMobile" class="latestGreyBox-9-9-2023 mt-12px">
    <div class="basicWhiteIOScard insideWebApp">
      <div class="basicWhiteIOScard-item">
        <div class="d-flex align-items-center">
          <div class="basicIOSIcon mr-16px">
            <vb-icon class="" icon="latest-mobileLinkedCall-icon"/>
          </div>
          <div class="d-flex flex-column">
            <div class="headingInsideTable">
              Linked calls
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <vb-icon @click="$emit('call-linking-modal-show',{ task_id, cdr_id })" icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
        </div>
      </div>
    </div>
  </div>
  <div v-else class="d-flex flex-column align-items-start mt-45px">
    <div class="TODO-innerHeading">
      Linked calls
      <!-- <b-button variant="link" @click="$emit('call-linking-modal-show',{ task_id, cdr_id })">
        <b-icon icon="plus" />
      </b-button> -->
    </div>
    <Item1 
      v-if="!isEmpty(response.call_log)" 
      class="mt-16px" 
      :call="response.call_log" 
      @onClickInfo1="$modal.show(`${_uid}-TaskCallInfoModalNew`,{
        callInfo: response.call_log
      })"  
    />
    <!-- <vb-no-record v-else :text="api.get_call_log.send?'':'No Call'">
      <vb-loading v-if="api.get_call_log.send" slot="loading" />
    </vb-no-record> -->
    <div class="d-flex align-items-center mt-16px" @click="$emit('call-linking-modal-show',{ task_id, cdr_id })">
      <div class="linkCallToTicket-text mr-16px">Link a call to the ticket</div>
      <vb-icon icon="linkCallToTicket-plus-icon" class="cursor_pointer_no_hover" height="45px" width="45px" />
    </div>
    <CallInfoModalNew :modalName="`${_uid}-TaskCallInfoModalNew`" @latest-record="fetchCallActivity()" />
  </div>
</template>

<script>
import { VOIP_API } from '@/utils';
import { mapGetters } from 'vuex';
import Item1 from '@/components/Home/CallActivity/Item1.vue';
import CallInfoModalNew from "../../Modals/CallInfoModalNew.vue";
export default {
  name: 'CallLog',
  components: { 
    Item1,
    CallInfoModalNew,
  },
  inject: [
    'isEmpty','appNotify'
  ],
  props: {
    cdr_id: {
      type: String,
      default: '',
    },
    task: {
      type: Object,
      default: ()=>({}),
    },
    task_id: {
      type: [String,Number],
      default: '',
    },
  },
  data() {
    return {
      api: {
        get_call_log: this.$helperFunction.apiInstance(),
      },
      response: {
        call_log: {},
      },
      data: {
        cdr_id: '',
      },
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getIsMobile'
    ]),
  },
  methods: {
    async getCallLog() {
      if(this.api.get_call_log.send) return;
      try {
        this.api.get_call_log.send=true
        const { data: { data } } = await VOIP_API.endpoints.call_activity.getCDR({
          uid: this.getCurrentUser.uid,
          accountcode: this.task.created_by,
          cdr_id: this.data.cdr_id,
        })
        this.response.call_log=data
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.get_call_log.send=false
      }
    },
    onUpdatedCallLink(event){
      this.data.cdr_id=event.cdr_id;
      this.$emit('call-log-updated')
      this.getCallLog()
    },
  },
  mounted() {
    this.data.cdr_id=this.cdr_id
    if(this.data.cdr_id){
      this.getCallLog();
    }
  },
}
</script>

<style>

</style>