<template>
  <div>
    <div class="d-flex flex-column">
      <span class="sm-mar-left font-weight-bold min-width-160px" v-for="(number,index) in numbers" :key="index">
        <span class="mr-2" >
          <img class="country-img" width="45px" :src="number | number_formater | flag_icon" />
        </span>
        {{ number | number_formater }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NumbersListNode',
  props: {
    numbers: {
      type: Array,
      default: ()=>[]
    },
  },
}
</script>

<style>

</style>