<template>
  <CenterHorizontalLayout :bottomline="true" :voipaccount="voipaccount" @change-graph="$emit('interface',$event)">
    <div class="node nodeBasic " slot="main">
      <info v-if="voipaccount" :id="voipaccount" />
      <div v-if="!voipaccount" style="font-size: 16px;font-weight: 600;color: #202020;" class="sharedWithHeading">Assign to</div>
      <div class="d-flex justify-content-between align-items-center mt-3">
        <vb-audio-player :design="'minimum'" style="position: relative;" v-if="ivr" :src="ivr | welcomgreetingsrc" class="audioForMobile"/>
        <div :class="`d-flex justify-content-end ${ivr ? '': 'w-100'} align-items-center`">
          <b-icon @click="edit()" icon="pencil-fill" variant="primary" class="cursor_pointer ml-4"/>
          <b-icon v-if="voipaccount" @click="unassign()" icon="trash-fill" variant="primary" class="cursor_pointer ml-2"/>
        </div>
      </div>
    </div>
  </CenterHorizontalLayout>
</template>

<script>
import Info from '@/components/Lists/Info.vue'
import CenterHorizontalLayout from '../layouts/CenterHorizontal.vue'
import { mapGetters } from 'vuex'
import { welcomgreetingsrc } from '@/filter'
export default {
  name: 'NumberAssignNode',
  components: {
    CenterHorizontalLayout,
    Info,
  },
  props: {
    voipaccount: {
      type: String,
      defaault: ''
    },
  },
  computed: {
    ...mapGetters({
      getVoipIVRSAlises: 'getVoipIVRSAlises'
    }),
    ivr(){ return this.getVoipIVRSAlises[this.voipaccount] },
  },
  filters: {
    welcomgreetingsrc: welcomgreetingsrc,
  },
  methods: {
    edit(){
      this.$emit('interface',{ 
        from: 'numbers_graph_node', 
        event: 'update_extension', 
      })
    },
    unassign(){
      this.$emit('interface',{ 
        from: 'numbers_graph_node', 
        event: 'unassign_extension', 
      })
    }
  },
}
</script>

<style>

</style>