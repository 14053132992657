var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-settings-main"
  }, [_vm.getIsMobile ? _c('section', {
    staticClass: "dialer-settings-section locationSettings"
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-width"
  }, [_c('vb-icon', {
    staticClass: "settings-back-icon cursor_pointer",
    attrs: {
      "icon": "latest-sideBarOpenIconForTodoInMobileView-icon",
      "width": "19.979px",
      "height": "16px"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('openSideBar');
      }
    }
  }), _c('h2', {
    staticClass: "dialer-settings-title w-100 mb-0 text-center flex-1"
  }, [_vm._v("My tasks")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover.top",
      value: 'Export',
      expression: "'Export'",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    staticClass: "mr-4px cursor_pointer_no_hover",
    on: {
      "click": function ($event) {
        return _vm.exportTasks();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "taskHub-export-icon",
      "width": "50px",
      "height": "50px"
    }
  })], 1), _c('div', {
    staticClass: "mr-4px cursor_pointer_no_hover",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('SelectFilterOfTasks', {
          assignees: _vm.getTasksFilters.assignees,
          statuses: _vm.getTasksFilters.statuses,
          groups: _vm.getTasksFilters.groups,
          priorities: _vm.getTasksFilters.priorities,
          space_ids: _vm.space_ids
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "todo-filter-icon",
      "height": "50px",
      "width": "50px"
    }
  })], 1), _c('b-dropdown', {
    staticClass: "filterDropdownForTaskHub ml-3",
    attrs: {
      "no-caret": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('vb-icon', {
          attrs: {
            "icon": "latest-HorizontalThreeDots-icon",
            "width": "27px",
            "height": "6px"
          }
        })];
      },
      proxy: true
    }], null, false, 3382409577)
  }, [_c('b-dropdown-form', {
    staticClass: "inputItemContainer",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder withLeftIconOnly w-100"
  }, [_c('div', {
    staticClass: "inputContainer-inside-whiteBGinputWithGreyRoundedBorder"
  }, [_c('vb-icon', {
    staticClass: "greySerchIcon",
    attrs: {
      "icon": "callActivity-search",
      "height": "18px",
      "width": "17.75px"
    }
  }), _c('b-input', {
    directives: [{
      name: "focus",
      rawName: "v-focus"
    }],
    attrs: {
      "placeholder": "Search filters..."
    },
    model: {
      value: _vm.filter.group_by.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.group_by, "search", $$v);
      },
      expression: "filter.group_by.search"
    }
  })], 1)])]), _vm._l(_vm.filterGroupBy, function (group) {
    return _c('b-dropdown-item', {
      key: group.value,
      class: `${_vm.filter.tasks.group_by == group.value ? 'active' : ''}`,
      on: {
        "click": function ($event) {
          _vm.filter.tasks.group_by = group.value;
          _vm.filter.group_by.search = '';
        }
      }
    }, [_vm._v(" " + _vm._s(group.text) + " "), _vm.filter.tasks.group_by == group.value ? _c('b-icon', {
      staticClass: "darker-chevron-down withoutPath",
      attrs: {
        "icon": "check-lg"
      }
    }) : _vm._e()], 1);
  })], 2)], 1)], 1), _vm.space_ids.length == 0 ? _c('div', [_vm._v(" Please select a space in order to see your list of tasks ")]) : [_c('div', {
    staticClass: "users-settings-2nd-section d-flex align-items-center justify-content-between md-mar-top"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder seachBox"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "mobile-searchBar-icon-likeAirCall-icon",
      "width": "12.68px",
      "height": "12.67px"
    }
  }), _c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search tasks"
    },
    on: {
      "input": function ($event) {
        return _vm.getTasksDebounce();
      }
    },
    model: {
      value: _vm.filter.tasks.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.tasks, "search", $$v);
      },
      expression: "filter.tasks.search"
    }
  })], 1)]), _c('div', [_c('div', {}, [_vm.isEmpty(_vm.taskGroup) ? _c('TaskItem', {
    attrs: {
      "addTask": true,
      "space_id": _vm.space_ids[0]
    },
    on: {
      "created": function ($event) {
        return _vm.getTasksDebounce();
      }
    }
  }) : _vm._e(), _vm._l(_vm.taskGroup, function (group) {
    return _c('div', {
      key: group.filter.key
    }, [_c('div', {
      staticClass: "statusDevider"
    }, [_c('span', [_vm._v(_vm._s(group.text))]), group.color ? _c('div', {
      staticClass: "statusColorBox",
      style: `background-color: ${group.color}`
    }) : _vm._e()]), _c('TaskItem', {
      key: `add-task-${group.filter.key}`,
      attrs: {
        "addTask": true,
        "space_id": group.space.id
      },
      on: {
        "created": function ($event) {
          return _vm.getTasksDebounce();
        }
      }
    }), _vm._l(group.tasks, function (task) {
      return _c('TaskItem', {
        key: task.id,
        attrs: {
          "task": task
        },
        on: {
          "deleted": function ($event) {
            return _vm.onDeleteTask($event);
          },
          "taskClick": function ($event) {
            return _vm.$modal.show(`${_vm._uid}-EditTasks`, {
              id: task.id
            });
          }
        }
      });
    })], 2);
  })], 2)])]], 2) : _c('section', {
    staticClass: "dialer-settings-section locationSettings newTasksModule"
  }, [_vm.space_ids.length == 0 ? _c('div', [_c('div', {
    staticClass: "dialer-flex"
  }, [_c('h2', {
    staticClass: "dialer-settings-title d-flex align-items-center"
  }, [_c('vb-icon', {
    staticClass: "settings-back-icon cursor_pointer showOnlyOnSmallerScreens mr-12px",
    attrs: {
      "icon": "latest-sideBarOpenIconForTodoInMobileView-icon",
      "width": "19.979px",
      "height": "16px"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('openSideBar');
      }
    }
  }), _vm._v(" My tasks ")], 1)]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-32px"
  }, [_c('div', {
    staticClass: "emptyScreenContainer"
  }, [_vm.GET_LOCAL_SETTING_IS_DARK_MODE ? _c('img', {
    staticClass: "mt-12px wd-30 h-auto",
    attrs: {
      "src": require('@/assets/images/emptyScreenImages/darkMode/chat.png')
    }
  }) : _c('img', {
    staticClass: "mt-12px wd-30 h-auto",
    attrs: {
      "src": require('@/assets/images/emptyScreenImages/chat.png')
    }
  }), _c('div', {
    staticClass: "emptyScreenContainer-heading"
  }, [_vm._v(" Select a space to view tasks ")]), _c('div', {
    staticClass: "emptyScreenContainer-text w-75"
  }, [_vm._v(" Seamlessly manage tasks, assign call-related actions, and track priorities within our task module. Streamline your workflow for enhanced productivity. ")])])])]) : [_c('div', {
    staticClass: "taskHub-firstSection-grid"
  }, [_c('h2', {
    staticClass: "dialer-settings-title d-flex align-items-center"
  }, [_c('vb-icon', {
    staticClass: "settings-back-icon cursor_pointer showOnlyOnSmallerScreens mr-12px",
    attrs: {
      "icon": "latest-sideBarOpenIconForTodoInMobileView-icon",
      "width": "19.979px",
      "height": "16px"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('openSideBar');
      }
    }
  }), _vm._v(" My tasks ")], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder ml-auto seachBox"
  }, [_c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search tasks"
    },
    on: {
      "input": function ($event) {
        return _vm.getTasksDebounce();
      }
    },
    model: {
      value: _vm.filter.tasks.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.tasks, "search", $$v);
      },
      expression: "filter.tasks.search"
    }
  })], 1), _c('span', {
    staticClass: "dialer-settings-intro newerTextVersion mb-0"
  }, [_vm._v(" Seamlessly manage tasks, assign call-related actions, and track priorities within our task module. Streamline your workflow for enhanced productivity. ")])]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-end w-100 mt-45px"
  }, [_c('div', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover.top",
      value: 'Export',
      expression: "'Export'",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    staticClass: "mr-12px cursor_pointer_no_hover",
    on: {
      "click": function ($event) {
        return _vm.exportTasks();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "taskHub-export-icon",
      "width": "50px",
      "height": "50px"
    }
  })], 1), _c('div', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover.top",
      value: 'Import',
      expression: "'Import'",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    staticClass: "mr-12px cursor_pointer_no_hover",
    on: {
      "click": function ($event) {
        _vm.api.import_tasks.send ? '' : _vm.$refs['upload_file'].click();
      }
    }
  }, [_c('input', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "upload_file",
    attrs: {
      "type": "file",
      "multiple": false,
      "disabled": _vm.api.import_tasks.send,
      "accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    },
    on: {
      "change": function ($event) {
        _vm.importTasks(_vm.getProperty($event, 'target.files[0]'));
      }
    }
  }), _c('vb-icon', {
    attrs: {
      "id": "popover-button-open",
      "icon": "taskHub-import-icon",
      "width": "50px",
      "height": "50px"
    }
  }), _vm.api.import_tasks.upload_progress.is_process ? [_c('b-popover', {
    attrs: {
      "show": "",
      "top": "",
      "target": "popover-button-open"
    }
  }, [_vm._v(" " + _vm._s(`${_vm.api.import_tasks.upload_progress.percentage}%`) + " ")])] : _vm._e()], 2), _c('div', {
    staticClass: "mr-12px cursor_pointer_no_hover compactViewContainer",
    on: {
      "click": function ($event) {
        _vm.isCompact = !_vm.isCompact;
      }
    }
  }, [_vm.isCompact ? _c('vb-icon', {
    attrs: {
      "icon": "todo-compact-icon",
      "height": "50px",
      "width": "50px"
    }
  }) : _c('vb-icon', {
    attrs: {
      "icon": "todo-notCompact-icon",
      "height": "50px",
      "width": "50px"
    }
  })], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion ul-width-fit-content mr-12px"
  }, [_c('vb-select', {
    attrs: {
      "disabled": false,
      "options": _vm.response.filters,
      "textField": "label",
      "valueField": "id",
      "isCheckShow": true,
      "defaultSelectedText": 'Select filter'
    },
    on: {
      "change": function ($event) {
        return _vm.getTasks(true);
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (_ref) {
        var option = _ref.option;
        return [_c('div', [_vm.filter.tasks.filter_id == option.id ? _c('b-icon', {
          attrs: {
            "icon": "check",
            "variant": "success"
          }
        }) : _vm._e(), _vm._v(" " + _vm._s(option.label) + " ")], 1), _c('div', {
          staticClass: "d-flex"
        }, [_vm.api.delete_filter.send.includes(option.id) || _vm.api.toggle_default_filter.send.includes(option.id) ? _c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        }) : [_c('b-button', {
          staticClass: "p-0 ml-8px",
          attrs: {
            "disabled": option.status == 1,
            "variant": "link"
          },
          on: {
            "click": function ($event) {
              $event.stopPropagation();
              return _vm.markDefaultFilter(option);
            }
          }
        }, [option.status == 1 ? _c('b-icon', {
          attrs: {
            "icon": "circle-fill",
            "variant": "success"
          }
        }) : _c('b-icon', {
          attrs: {
            "icon": "circle"
          }
        })], 1), _c('b-button', {
          staticClass: "p-0 ml-8px",
          attrs: {
            "disabled": _vm.filter.tasks.filter_id == option.id,
            "variant": "link"
          },
          on: {
            "click": function ($event) {
              $event.stopPropagation();
              return _vm.deleteFilter(option);
            }
          }
        }, [_c('b-icon', {
          attrs: {
            "icon": "trash-fill",
            "variant": "danger"
          }
        })], 1)]], 2)];
      }
    }]),
    model: {
      value: _vm.filter.tasks.filter_id,
      callback: function ($$v) {
        _vm.$set(_vm.filter.tasks, "filter_id", $$v);
      },
      expression: "filter.tasks.filter_id"
    }
  })], 1), _c('div', {
    staticClass: "mr-12px cursor_pointer_no_hover",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('SelectFilterOfTasks', {
          assignees: _vm.getTasksFilters.assignees,
          statuses: _vm.getTasksFilters.statuses,
          groups: _vm.getTasksFilters.groups,
          priorities: _vm.getTasksFilters.priorities,
          space_ids: _vm.space_ids
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "todo-filter-icon",
      "height": "50px",
      "width": "50px"
    }
  })], 1), _c('div', {
    staticClass: "mr-12px cursor_pointer_no_hover",
    on: {
      "click": function ($event) {
        _vm.filter.tasks.assignees = [];
        _vm.filter.tasks.statuses = [];
        _vm.filter.tasks.groups = [];
        _vm.filter.tasks.priorities = [];
        _vm.filter.tasks.filter_id = '';
        _vm.getTasks(true);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "clearFilters-icon",
      "height": "50px",
      "width": "50px"
    }
  })], 1), _c('b-dropdown', {
    staticClass: "filterDropdownForTaskHub",
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('vb-icon', {
          staticClass: "funnel-icon",
          attrs: {
            "icon": "callInfoModal-funnel-icon",
            "height": "16px",
            "width": "16px"
          }
        }), _vm._v(" Group by: "), _c('span', [_vm._v(_vm._s(_vm.filter.tasks.group_by))])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-form', {
    staticClass: "inputItemContainer",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder withLeftIconOnly w-100"
  }, [_c('div', {
    staticClass: "inputContainer-inside-whiteBGinputWithGreyRoundedBorder"
  }, [_c('vb-icon', {
    staticClass: "greySerchIcon",
    attrs: {
      "icon": "callActivity-search",
      "height": "18px",
      "width": "17.75px"
    }
  }), _c('b-input', {
    directives: [{
      name: "focus",
      rawName: "v-focus"
    }],
    attrs: {
      "placeholder": "Search filters..."
    },
    model: {
      value: _vm.filter.group_by.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.group_by, "search", $$v);
      },
      expression: "filter.group_by.search"
    }
  })], 1)])]), _vm._l(_vm.filterGroupBy, function (group) {
    return _c('b-dropdown-item', {
      key: group.value,
      class: `${_vm.filter.tasks.group_by == group.value ? 'active' : ''}`,
      on: {
        "click": function ($event) {
          _vm.filter.tasks.group_by = group.value;
          _vm.filter.group_by.search = '';
        }
      }
    }, [_vm._v(" " + _vm._s(group.text) + " "), _vm.filter.tasks.group_by == group.value ? _c('b-icon', {
      staticClass: "darker-chevron-down withoutPath",
      attrs: {
        "icon": "check-lg"
      }
    }) : _vm._e()], 1);
  })], 2)], 1), _c('div', {
    class: `${_vm.isCompact ? 'TODO-compactView' : ''}`
  }, [_vm._m(0), _c('div', {
    staticClass: "allTasksList-container",
    on: {
      "scroll": function ($event) {
        return _vm.getFurthurTasks($event);
      }
    }
  }, [_vm.isEmpty(_vm.taskGroup) ? _c('TaskItem', {
    attrs: {
      "addTask": true,
      "space_id": _vm.space_ids[0]
    },
    on: {
      "created": function ($event) {
        return _vm.getTasksDebounce();
      }
    }
  }) : _vm._e(), _vm._l(_vm.taskGroup, function (group) {
    return _c('div', {
      key: group.filter.key
    }, [_c('div', {
      staticClass: "statusDevider"
    }, [_c('span', [_vm._v(_vm._s(group.text))]), group.color ? _c('div', {
      staticClass: "statusColorBox",
      style: `background-color: ${group.color}`
    }) : _vm._e()]), _c('TaskItem', {
      key: `add-task-${group.filter.key}`,
      attrs: {
        "addTask": true,
        "space_id": group.space.id
      },
      on: {
        "created": function ($event) {
          return _vm.getTasksDebounce();
        }
      }
    }), _vm._l(group.tasks, function (task) {
      return _c('TaskItem', {
        key: task.id,
        attrs: {
          "task": task
        },
        on: {
          "taskClick": function ($event) {
            return _vm.$modal.show(`${_vm._uid}-EditTasks`, {
              id: task.id
            });
          },
          "deleted": function ($event) {
            return _vm.onDeleteTask($event);
          }
        }
      });
    })], 2);
  }), _c('div', {
    staticStyle: {
      "height": "50px"
    }
  })], 2)])]], 2), _c('EditTasksModal', {
    attrs: {
      "modalName": `${_vm._uid}-EditTasks`
    },
    on: {
      "update-list": function ($event) {
        return _vm.getTasks(true);
      }
    }
  }), _c('SelectFilterOfTasksModal', {
    on: {
      "apply-filter": function ($event) {
        _vm.filter.tasks.assignees = $event.assignees;
        _vm.filter.tasks.statuses = $event.statuses;
        _vm.filter.tasks.groups = $event.groups;
        _vm.filter.tasks.priorities = $event.priorities;
        _vm.filter.tasks.filter_id = $event.filter_id;
        _vm.getTasks(true);
      },
      "added": function ($event) {
        return _vm.getFilters(_vm.filter.tasks.filter_id);
      }
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "TODO-taksTable-titleRow mt-38px"
  }, [_c('div', {
    staticClass: "TODO-taksTable-titleRow-column first"
  }, [_c('div', {
    staticClass: "TODO-taksTable-titleRow-column-text"
  }, [_vm._v("TASK TITLE")])]), _c('div', {
    staticClass: "TODO-taksTable-titleRow-column second"
  }, [_c('div', {
    staticClass: "TODO-taksTable-titleRow-column-text"
  }, [_vm._v("ASSIGNEES")])]), _c('div', {
    staticClass: "TODO-taksTable-titleRow-column third"
  }, [_c('div', {
    staticClass: "TODO-taksTable-titleRow-column-text"
  }, [_vm._v("STATUS")])]), _c('div', {
    staticClass: "TODO-taksTable-titleRow-column fourth"
  })]);

}]

export { render, staticRenderFns }