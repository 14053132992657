import { TokenService } from "@/services/index"
import store from "../store"
import _ from 'lodash'
import { LOGGER } from "./console";
import Timer from "timer.js";
// import Axios from "axios";
import { MATTERMOST_API, mattermost } from "../MatterMost/index";
// import { 
//   centrifugo,
//   // disconnect as centrfugeDisconnect 
// } from "../Centrifuge/index";
import router from "../router";
import { VOIP_API } from "./api/index";
// import { MOTHERSHIP } from "../mothership/index";
import { URLS } from "./urls";
// import { AsYouType } from 'libphonenumber-js';
import parsePhoneNumber from "libphonenumber-js"
import parseMin from 'libphonenumber-js/min'
import parseMax from 'libphonenumber-js/max'
import parseMobile from 'libphonenumber-js/mobile'
import { INTEGRATIONS } from "../integrations/index";
import { number_formater } from "@/filter";
import { CLEAR_ERRORS, CRM_RESET, PAMARLY_DATA_RESET } from "@/store/helper/actions";
import { MOTHERSHIP } from "@/mothership/index";
import { SET_CRM_API_URL } from "@/store/helper/mutations";
import moment from "moment";
import { AMI_SOCKET } from "@/ami/socket";

export const $fn = {
  parseJwt(token) {
    if(!token) return null
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  },
  getLeaves(tree,key){
    let leaves = [];
    const walk = function(obj,path){
      path = path || "";
      for(var n in obj){
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(n)) {
          if(typeof obj[n] === "object" || obj[n] instanceof Array) {
            walk(obj[n],path + "[" + n + "]");
          } else {
            leaves.push(path + "[" + n + "]");
          }
        }
      }
    }
    walk(tree,key);
    return leaves;
  },
  getImageDimensions(file){
    return new Promise((res, rej) => {
      if(file?.type?.split?.("/")?.[0]!="image") rej()
      const image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = function () {
        res({
          width: image.width,
          height: image.height,
        })
      };
      image.onerror = function () {
        rej();
      };
    })
  },
  readFile(file){
    return new Promise((resolve,reject)=>{
      var read = new FileReader();
      read.onload = function (e) {
        resolve(e.target.result);
      }
      read.onerror = function(err){
        reject(err)
      }
      read.readAsDataURL(file);
    })
  },
  getTxtToMs(text,milliseconds=true){
    let h = 0, m = 0, s = 0, ms = 0, str = text
    if (str.includes('h')){
      const  [hour, other_str] = str.split('h')
      h = parseFloat(hour) || 0
      str = other_str
    }
    if (str.includes('m')){
      const  [min, other_str] = str.split('m')
      m = parseFloat(min) || 0
      str = other_str
    }
    if (str.includes('s')){
      const  [sec, other_str] = str.split('s')
      s = parseFloat(sec) || 0
      str = other_str
    }
    ms = (h * 60 * 60 * 1000) + (m * 60 * 1000) + (s * 1000)
    if(milliseconds) return ms
    return ms / 1000
  },
  pointerEventPath(Event){
    function composedPath (el) {
      let ell = el
      let path = [];
      while (ell) {
        path.push(ell);
        if (ell.tagName === 'HTML') {
          path.push(document);
          path.push(window);
          return path;
        }
        ell = ell.parentElement;
      }
    }
    return Event.path || Event?.composedPath?.() || composedPath(Event.target) || [];
  },
  onScrollBottom(Event,pix=2){
    if(!Event?.target) return false
    const height = Event.target.scrollHeight;
    const top = Event.target.scrollTop;
    const offset_height = Event.target.offsetHeight;
    const scroll_bar_height = height - (height - offset_height);
    const scroll_bottom = Math.floor(height - (scroll_bar_height + top));
    return scroll_bottom<=pix
  },

  getParsePhoneNumber(type=''){
    if(type=='max'){
      return parseMax
    } else if(type=='min'){
      return parseMin
    } else if(type=='mobile'){
      return parseMobile
    } else {
      return parsePhoneNumber
    }
  },
  validPhoneNumber(value,country='',type=''){
    try {
      const phoneNumber = $fn.getParsePhoneNumber(type)(value,country)
      if(phoneNumber && phoneNumber.isValid()){
        if(country){
          return country==phoneNumber.country
        } else {
          return true
        }
      } else {
        return false
      }
    } catch {
      return false
    }
  },
  getNumberFormated(number,country,format='',type){
    const phoneNumber = $fn.getParsePhoneNumber(type)(number, country || TokenService.USER.get()?.country || '')
    if (phoneNumber && phoneNumber.isValid()) {
      if(format=='INTERNATIONAL') {
        return phoneNumber?.format?.("INTERNATIONAL")
      } else if(format=='NATIONAL') {
        return phoneNumber?.format?.("NATIONAL")
      } else {
        return phoneNumber.number
      }
    } else {
      return number
    }
  },
  // validPhoneNumber(value){
  //   const as_you_type = new AsYouType()
  //   as_you_type?.input?.(value)
  //   return !!as_you_type?.isValid?.()
  // },
  
  fileSize(size) {
    let formated_size = size
    if(formated_size>1024){
      formated_size = formated_size/1024
      if(formated_size>1024){
        formated_size = formated_size/1024
        if(formated_size>1024){
          formated_size = formated_size/1024
          if(formated_size>1024){
            formated_size = formated_size/1024
          } else {
            formated_size = Math.floor(formated_size)+'GB'
          }
        } else {
          formated_size = Math.floor(formated_size)+'MB'
        }
      } else {
        formated_size = Math.floor(formated_size)+'KB'
      }
    } else {
      formated_size = Math.floor(formated_size)+'B'
    }
    return formated_size
  },
  blobToBase64(blob){
    return new Promise((resolve,reject)=>{
      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onerror = function () {
        reject()
      }
      reader.onloadend = function () {
        var base64data = reader.result;
        var base64 = base64data.split(",")[1];
        resolve(base64)
      }
    })
  },
  sleep(ms=1 * 1000,payload={}) { return new Promise(resolve => setTimeout(()=>{ resolve(payload) }, typeof ms == 'number' ? ms : $fn.getTxtToMs(ms))); },
  getUniqueID() { return Math.random().toString(36).substr(2, 9); },
  manipulateUserAccountCodes(obj={}){
    let result = {}
    let keys = Object.keys(obj)
    for (let index = 0; index < keys.length; index++) {
      let v = keys[index]
      result[v]=v
      for (let i = 0; i < obj[v].length; i++) {
        let k = obj[v][i]
        result[k]=v
      }
    }
    return result
  },
  manipulateVoipUsers(obj={},prev_obj){
    return Object.values(obj).map(item=>{
      return {
        ...item,
        status: 'offline',
        ami_status: prev_obj?.[item.voipaccount]?.ami_status || 'active',
        idle_state: prev_obj?.[item.voipaccount]?.idle_state || {
          state: 'active',
          seconds: 0
        },
        devices: prev_obj?.[item.voipaccount]?.devices || {},
      }
    })
  },
  manipulateAddressBook(address_book={}){
    const phones = address_book?.shorCodes?.map?.(i=>{
      return {
        number: i.contact_number,
        formated_number: number_formater(i.contact_number,'',true),
        shortCode: i.short_code,
        dialShortCode: i.short_code ? `*${i.short_code}` : '',
        type: i.contact_type,
        uid: `${address_book.global?'global':'local'}-${i.short_code || this.makeid()}`
      }
    })
    const main_phone = phones?.[0]
    return {
      ...address_book,
      all_formated_numbers: phones?.map?.(i=>i.formated_number).join(' '),
      all_numbers: phones?.map?.(i=>i.number).join(' '),
      phones,
      main_phone: main_phone,
      profile_image: address_book?.avatar?.image?.image ?? '',
    }
  },
  async logout(options={}){
    let { is_router_change, cb, is_user, mothership_logout/*, hard*/ } = options
    is_router_change = typeof is_router_change == 'undefined' || is_router_change
    mothership_logout = typeof mothership_logout == 'undefined' || mothership_logout
    if(store.state.logout_running) return;
    store.state.logout_running=true
    const user = TokenService.USER.get()
    const token = TokenService.TOKEN.get()
    const device_id = store.getters.fpData.device_id
    const chat_token = TokenService.MATTERMOST_TOKEN.get()
    const web_app = !process.env.IS_ELECTRON && process.env.VUE_APP_INTEGRATION!='true' && window.self !== window.top
    function afterLogout(){
      INTEGRATIONS.loggedout()
      store.commit("setCommonState");
      store.commit(CLEAR_ERRORS);
      store.dispatch(PAMARLY_DATA_RESET)
      store.state.sip.last_call_number=''
      store.state.mattermost.channels={}
      store.state.notifys=[]
      store.dispatch(CRM_RESET)
      TokenService.removeStorage();
      mattermost.disconnect()
      // centrifugo.mothership.disconnect();
      // centrifugo.mothershipadmin.disconnect();
      // centrifugo.notification_admin.disconnect();
      // centrifugo.notification.disconnect();
      // centrifugo.calls.disconnect();
      AMI_SOCKET.disconnect();
      // console.log('mothership_logout,is_user,user,token,web_app,MOTHERSHIP.iframe',mothership_logout,is_user,user,token,web_app,MOTHERSHIP.iframe)
      if(mothership_logout && is_user && user && token && web_app && MOTHERSHIP.iframe){
        // console.log('MOTHERSHIP.events.send.LOGOUT',MOTHERSHIP.events.send.LOGOUT)
        MOTHERSHIP?.sendData?.(MOTHERSHIP.events.send.LOGOUT)
      }
      if(store.state.global_conditions.is_mobile_setting){
        if(is_router_change){
          router.push({
            name: 'mobile-app-page-authentiacation-failed',
          });
        }
      } else {
        if(is_router_change){
          router.push("/login");
        }
      }
      cb?.()
    }
    try {
      // if(!hard) {
        await store.state?.sip?.phone?.disconnect?.()
        if(chat_token){
          await MATTERMOST_API.endpoints.users.logout();
        }
        if(user && token) {
          await VOIP_API.endpoints.user.logout({
            Authorization: `Bearer ${token}`
          },{
            vaccountcode: user.account,
            vuid: user.uid,
            ...is_user ? {
              extension_accountcode: user?.sip?.user,
              device_id: device_id
            } : {},
            ...web_app ? {
              vb_token: user.vbToken,
            } : {},
          })
        }
      // }
    } catch(ex) {
      LOGGER.danger('logout',ex)
    } finally {
      store.state.logout_running=false
      afterLogout()
    }
    // try {
    //   await store.state?.sip?.phone?.disconnect?.()
    // } catch(ex) {
    //   LOGGER.danger('logout',ex)
    // } finally {
    //   try {
    //     if(chat_token){
    //       await MATTERMOST_API.endpoints.users.logout();
    //     }
    //   } catch(ex) {
    //     LOGGER.danger('logout 2',ex)
    //   } finally {
    //     try {
    //       if(user && token) {
    //         await VOIP_API.endpoints.user.logout({
    //           Authorization: `Bearer ${token}`
    //         },{
    //           vaccountcode: user.account,
    //           vuid: user.uid,
    //           ...is_user ? {
    //             extension_accountcode: user?.sip?.user,
    //             device_id: device_id
    //           } : {},
    //           ...!process.env.IS_ELECTRON && process.env.VUE_APP_INTEGRATION!='true' && window.self !== window.top ? {
    //             vb_token: user.vbToken,
    //           } : {},
    //         })
    //         // if(!process.env.IS_ELECTRON && process.env.VUE_APP_INTEGRATION!='true' && window.self !== window.top){
    //         //   if(MOTHERSHIP.iframe){
    //         //     MOTHERSHIP?.sendData?.(MOTHERSHIP.events.send.LOGOUT)
    //         //   } else {
    //         //     await VOIP_API.endpoints.user.userlogout({
    //         //       uuid: user.vbToken,
    //         //       device_id: device_id
    //         //     })
    //         //   }
    //         // }
    //         // if(MOTHERSHIP.iframe){
    //         //   MOTHERSHIP?.sendData?.(MOTHERSHIP.events.send.LOGOUT)
    //         // } else {
    //         //   if(!process.env.IS_ELECTRON && process.env.VUE_APP_INTEGRATION!='true' && window.self !== window.top){
    //         //     await VOIP_API.endpoints.user.userlogout({
    //         //       uuid: user.vbToken,
    //         //       device_id: device_id
    //         //     })
    //         //   }
    //         // }
    //       }
    //     } catch(ex) {
    //       LOGGER.danger('logout 3',ex)
    //     } finally {
    //       INTEGRATIONS.loggedout()
    //       store.commit("setCommonState");
    //       store.dispatch(PAMARLY_DATA_RESET)
    //       store.state.sip.last_call_number=''
    //       store.state.mattermost.channels={}
    //       store.state.notifys=[]
    //       TokenService.removeStorage();
    //       mattermost.disconnect()
    //       centrifugo.mothership.disconnect();
    //       centrifugo.mothershipadmin.disconnect();
    //       centrifugo.notification_admin.disconnect();
    //       centrifugo.notification.disconnect();
    //       centrifugo.calls.disconnect();
    //       store.state.logout_running=false
    //       if(store.state.global_conditions.is_mobile_setting){
    //         if(is_router_change){
    //           router.push({
    //             name: 'mobile-app-page-authentiacation-failed',
    //           });
    //         }
    //       } else {
    //         if(is_router_change){
    //           router.push("/login");
    //         }
    //       }
    //       cb?.()
    //       if(user && token && is_user && !process.env.IS_ELECTRON && process.env.VUE_APP_INTEGRATION!='true' && window.self !== window.top && MOTHERSHIP.iframe){
    //         MOTHERSHIP?.sendData?.(MOTHERSHIP.events.send.LOGOUT)
    //       }
    //     }
    //   }
    // }
  },
  setUserLocally(response,password,organizations,selected_organization_id=''){
    var moment = require("moment");
    URLS.setUrls({
      relay_centrifugo: response.response.common_notification_url,
      centrifugo_notification: response.response.harmony_notification_url,
      Jitsi_url: response.response.jitsi_url,
      sipSocket_url: response.response.socket_url,
      chat_api: response.response.chat_url,
      chat_socket: response.response.chat_url,
      billing_api: response.response.billingApiServer,
      kone_api: response.response.apiServer,
    })
    let sip = {
      user: response.response.registerExtension.sipUser,
      pass: response.response.registerExtension.sipPass,
      port: response.response.registerExtension.sipPort,
      name: response.response.registerExtension.accountname,
      extn: response.response.registerExtension.extn,
      server: response.response.registerExtension.sipServer,
    };
    // if(process.env.IS_ELECTRON){
    //   sip.user = response.response.desktop.sipUser 
    //   sip.pass = response.response.desktop.sipPass 
    //   sip.port = response.response.desktop.sipPort 
    //   sip.name = response.response.desktop.accountname 
    //   sip.extn = response.response.desktop.extn 
    //   sip.server = response.response.desktop.sipServer 
    // } else if (process.env.VUE_APP_INTEGRATION=='true') { // 
    //   sip.user = response.response.integration.sipUser 
    //   sip.pass = response.response.integration.sipPass 
    //   sip.port = response.response.integration.sipPort 
    //   sip.name = response.response.integration.accountname 
    //   sip.extn = response.response.integration.extn 
    //   sip.server = response.response.integration.sipServer 
    // } else { 
    //   sip.user = response.response.web.sipUser 
    //   sip.pass = response.response.web.sipPass 
    //   sip.port = response.response.web.sipPort 
    //   sip.name = response.response.web.accountname 
    //   sip.extn = response.response.web.extn 
    //   sip.server = response.response.web.sipServer 
    // }
    const user = {
      sip,
      account: response.response.voipaccountcode,
      default_accountcode: response.response.defaultaccountcode,
      secret: response.response.secret,
      apiServer: response.response.apiServer,
      billingApiServer: response.response.billingApiServer,
      billingEmail: response.response.billingEmail,
      email: response.response.email,
      extn: response.response.extn,
      firstname: response.response.firstname,
      lastname: response.response.lastname,
      uid: response.response.uid,
      pass: password,
      jid_account: response.response.jidUser,
      jid_pass: response.response.jidPass,
      profile_img: response.response.profile_img,
      chat_id: response.response.chatId,
      missed_call_notification: response.response.missed_call_notification,
      emailvoicemail: response.response.emailvoicemail,
      notification_url: URLS.centrifugoNotification,
      notification_token: response.response.notificationToken,
      notification_token_default: response.response.notificationTokenDefault,
      notification_channel: `notification#${response.response.voipaccountcode}`,
      notification_channel_default: `notification#${response.response.defaultaccountcode}`,
      display_name: response.response.display_name,
      login_at: moment(new Date()),
      desktop_notification: response.response.desktop_notification=='Y',
      email_notification: response.response.email_notification=='Y',
      mm_team_id: response.response.mm_team_id,
      administrator_account: response.response.administrator_account === "yes",
      organizations: organizations,
      profileImg: response.response.profileImg,
      selected_organization_id: selected_organization_id,
      referCode: response.response.referCode,
      status_id: '',
      package_type: response.response.package_type,
      companyLogo: response.response.companyLogo,
      company: response.response.company || '',
      status: '',
      appMode: response.response.appMode,
      is_number_purchased: response.response.number_assigned && response.response.number_assigned!=0,
      dialerServerVersion: response.response.dialerServerVersion,
      vbToken: response.response.vbToken,
      features: response.response.features,
      two_factor_auth: response.response.two_factor_auth,
      user_type: response.response.user_type,
      logs_mode: response.response.logs_mode=='yes',
      logs_super_mode: response.response.logs_super_mode=='yes',
      suspended: ['0','3',0,3].includes(response.response.active),
      blf: response.response.blf=='enable',
      country: '',
      other_system: {
        pamarly: {
          username: 'test@vavoip.com',
          password: '12345678',
          organization_id: 2
        }
      },
    };
    TokenService.TOKEN.set(response.response.token);
    TokenService.REFRESH_TOKEN.set(response.response.refresh_token);
    TokenService.USER.set(user);
    store.commit(SET_CRM_API_URL,response.response.CrmSystemHostApi)
    store.state.common.user = user
  },
  messageShow(post,t_type){
    var msg = "";
    if (!_.isEmpty(post)) {
      const { props, type, message, metadata } = post;
      if (!type) {
        if (message) {
          msg = message;
        } else {
          if (metadata && metadata.files) {
            if (metadata.files.length > 1) {
              msg = "files";
            } else {
              msg = metadata.files[0].mime_type.split("/")[0] + " message";
            }
          }
        }
      } else {
        switch (type) {
          case "system_displayname_change":
            if (props.old_displayname) {
              msg = `${store.getters.getVoipUsersAlisesDisplayName[props.username]} updated the ${t_type} display name from ${props.old_displayname} to ${props.new_displayname}.`;
            } else {
              msg = `${store.getters.getVoipUsersAlisesDisplayName[props.username]} set the ${t_type} display name ${props.new_displayname}.`;
            }
            break;
          case "system_header_change":
            if (props.old_header) {
              msg = `${store.getters.getVoipUsersAlisesDisplayName[props.username]} updated the ${t_type} header from ${props.old_header} to ${props.new_header}`;
            } else {
              msg = `${store.getters.getVoipUsersAlisesDisplayName[props.username]} set the ${t_type} header ${props.new_header}`;
            }
            break;
          case "system_purpose_change":
            if (props.old_purpose) {
              msg = `${store.getters.getVoipUsersAlisesDisplayName[props.username]} updated the ${t_type} purpose from ${props.old_purpose} to ${props.new_purpose}.`;
            } else {
              msg = `${store.getters.getVoipUsersAlisesDisplayName[props.username]} set the ${t_type} purpose ${props.new_purpose}.`;
            }
            break;
          case "system_add_to_channel":
            msg = `${store.getters.getVoipUsersAlisesDisplayName[props.addedUsername]} added to the ${t_type} by ${store.getters.getVoipUsersAlisesDisplayName[props.username]}.`;
            break;
          case "system_remove_from_channel":
            msg = `${store.getters.getVoipUsersAlisesDisplayName[props.removedUsername]} removed from the ${t_type}`;
            break;
          case "system_channel_deleted":
            msg = `${store.getters.getVoipUsersAlisesDisplayName[props.username]} delete the ${t_type}.`;
            break;
          case "system_leave_channel":
            msg = `${store.getters.getVoipUsersAlisesDisplayName[props.username]} leave the ${t_type}.`;
            break;
          case "system_join_channel":
            msg = `${store.getters.getVoipUsersAlisesDisplayName[props.username]} created the ${t_type}.`;
            break;
          default:
            msg = '';
            break;
        }
      }
    }
    return msg;
  },
  /**
   *
   * exit the full screen
   * @param {{data: Object, }} message
   * @return {Object} 
   */
  closeFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) { /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE11 */
      document.msExitFullscreen();
    }
  },
  /**
   *
   * make the element on full screen
   * @param {Object} elem
   */
  openFullscreen(elem) {
    if (typeof elem === 'object') {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
      }   
    }
  },
  /**
   *
   *
   * @param {{
   *  count: Boolean, 
   *  source: Boolean, 
   *  validation_errors: Boolean, 
   *  response_error: Boolean, 
   *  status: Boolean, 
   *  upload_progress: Boolean, 
   *  error_message: Boolean, 
   *  send: Any
   * }} message
   * @return {Object} 
   */
  apiInstance(message={}){
    const { count, source, validation_errors, response_error, status, upload_progress, error_message, send, success_message, error_trigger, request_time, count_down } = message
    let obj = {}
    if(count){
      obj.count = 0
      const incrementCount = function(){ 
        this.count=this.count+1 
      }
      obj.increment = incrementCount.bind(obj)
    }
    if(source){
      obj.source = null
    }
    if(status){
      obj.status = 0
    }
    if(error_message){
      obj.error_message = ''
    }
    if(success_message){
      obj.success_message = ''
    }
    if(validation_errors){
      obj.validation_errors = {}
    }
    if(response_error){
      obj.error = {}
    }
    if(upload_progress){
      obj.upload_progress = {
        total: 0,
        loaded: 0,
        percentage: 0,
        is_process: false,
        onProgress(event){
          this.total=event.total
          this.loaded=event.loaded
          this.percentage=Math.floor((event.loaded / event.total) * 100)
          this.is_process=Math.floor((event.loaded / event.total) * 100) < 100
        },
      }
    }
    
    if(error_trigger) {
      obj.error_trigger={
        show: false,
        trigger(cb,ms){
          let that = this
          this.show=true
          setTimeout(()=>{
            that.show=false
            cb?.()
          },ms || 1*1000)
        },
        reset(){
          this.show=false
        },
      }
    }
    if(request_time) {
      obj.request_time = {
        time: '',
        setTime(){
          this.time=moment().utc().format('DD MM YYYY hh:mm:ss')
        },
        compare(type='minutes',default_value=null){
          if(this.time) {
            const now = moment.utc().format('DD MM YYYY hh:mm:ss')
            return moment.utc(now,'DD MM YYYY hh:mm:ss').diff(moment.utc(this.time,'DD MM YYYY hh:mm:ss'), type)
          } else {
            return default_value
          }
        },
        reset(){
          this.time=''
        },
      }
    }
    if(count_down) {
      obj.count_down = {
        seconds: 0,
        timer: new Timer({
          tick: 1,
          ontick(){
            obj.count_down.seconds -= 1;
          },
          onend(){
            obj.count_down.seconds = 0;
          },
        }),
        start(value,unit='second'){
          const time = moment().utc().add(value,unit).format('DD MM YYYY hh:mm:ss')
          const now = moment.utc().format('DD MM YYYY hh:mm:ss')
          const dif = moment.utc(time,'DD MM YYYY hh:mm:ss').diff(moment.utc(now,'DD MM YYYY hh:mm:ss'), 'seconds')
          this.seconds=dif
          this.timer.start(dif)
        },
        reset(){
          this.timer.stop()
          this.seconds=0
        },
      }
    }
    /**
     *
     *
     * @param {{
     *      callBack: Function, 
     *      cancel_message: String
     * }} message
     */
    const reset = function(message){
      const { callBack, cancel_message } = message ?? {}
      if(count) obj.count=0
      if(source) {
        obj.source?.cancel?.(cancel_message??'Cancel Request')
        obj.source=null
      }
      if(status) obj.status=0
      if(error_message) obj.error_message=''
      if(success_message) obj.success_message=''
      if(validation_errors) obj.validation_errors={}
      if(response_error) obj.error={}
      if(upload_progress) {
        obj.upload_progress.total=0
        obj.upload_progress.loaded=0
        obj.upload_progress.percentage=0
        obj.upload_progress.is_process=false
      }
      if(request_time) obj.request_time.reset()
      if(count_down) obj.count_down.reset()
      if(error_trigger) obj.error_trigger.reset()
      obj.send = send ?? false
      callBack?.(obj)
    }
    
    function emptySuccessMessage(sec){
      setTimeout(()=>{
        obj.success_message = ''
      }, (sec || 1) * 1000)
    }
    if(success_message){
      obj.emptySuccessMessage = emptySuccessMessage.bind(obj)
    }
    obj.reset = reset.bind(obj)
    obj.send = send ?? false
    return obj
  },
  /**
   *
   *
   * @param {{ data: Object }} message
   * @return {Object} 
   */
  formInstance(message={}){
    const { data } = message
    const data1 = JSON.parse(JSON.stringify(data))
    let obj = {
      ...data1,
      submitted: false
    }
    const reset = function(){
      const data2 = JSON.parse(JSON.stringify(data))
      let obj1 = {
        ...obj,
        ...data2,
        submitted: false
      }
      Object.assign(obj,obj1)
    }
    obj.reset = reset.bind(obj)
    return obj
  },
  /**
   *
   *
   * @param {{ data: Object }} message
   * @return {Object} 
   */
  stateInstance(message={}){
    const { data } = message
    const data1 = JSON.parse(JSON.stringify(data))
    let obj = {
      ...data1,
    }
    const reset = function(){
      const data2 = JSON.parse(JSON.stringify(data))
      let obj1 = {
        ...obj,
        ...data2,
      }
      Object.assign(obj,obj1)
    }
    obj.reset = reset.bind(obj)
    return obj
  },
  makeid(length=15,ch='all') { 
    const characters = `${['all','alphabet','capital-alphabet'].includes(ch) ? 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' : ''}${['all','alphabet','small-alphabet'].includes(ch) ? 'abcdefghijklmnopqrstuvwxyz' : ''}${['all','number'].includes(ch) ? '0123456789' : ''}`
    return _.shuffle(characters.split('')).slice(0,length).join('')
  },
  /**
   * 
   * @param { visulizing: {function} } request 
   * @returns {promise}
   */
  recordAudio(request={}){
    const { visulizing, blob, device_id } = request
    const { type: blob_type, name: blob_name } = blob ?? {}
    return new Promise((resolve,reject) => {
      navigator.mediaDevices.getUserMedia({ 
        audio: {
          deviceId: device_id,
        },
      })
      .then(stream=>{
        const tracks = stream.getTracks();
        const mediaRecorder = new MediaRecorder(stream);
        const audioChunks = [];
        const stoptracks = ()=>tracks.forEach((track)=>track.stop());
        var audioContent = new AudioContext();
        var audioStream = audioContent.createMediaStreamSource(stream);
        var analyser = audioContent.createAnalyser();
        audioStream.connect(analyser);
        analyser.fftSize = 1024;
        var frequencyArray = new Uint8Array(analyser.frequencyBinCount);
        function visulize() {
          requestAnimationFrame(visulize);
          analyser.getByteFrequencyData(frequencyArray);
          if(typeof visulizing == 'function'){
            visulizing(frequencyArray)
          }
        }
        requestAnimationFrame(visulize);
        mediaRecorder.addEventListener("dataavailable", event => { audioChunks.push(event.data); });
        function setDeviceId(device_id) {
          if(device_id){
            stream.getAudioTracks().forEach(track=>{
              track.setSinkId(device_id);
            })
          }
        }
        const start = () => mediaRecorder.start();
        const stop = () => new Promise(resolve => {
          // console.log('stop run')
          audioStream.disconnect(analyser);
          audioContent.close();
          stoptracks()
          mediaRecorder.addEventListener("stop", () => {
            const audioBlob = new Blob(audioChunks,{
              type: blob_type || 'audio/mpeg',
              name: blob_name || 'Recording.mp3'
            });
            const audioUrl = URL.createObjectURL(audioBlob);
            const audio = new Audio(audioUrl);
            const play = () => audio.play();
            resolve({ 
              audioBlob, 
              audioUrl, 
              play, 
              audio 
            });
          });
          mediaRecorder.stop();
        });
        // if(device_id) setDeviceId(device_id)
        resolve({ 
          start, 
          stop,
          setDeviceId,
        });
      })
      .catch((ex)=>{
        reject(ex)
      });
    });
  },
  isValidUKPostcode(value) { return /[A-Z]{1,2}[A-Z0-9]{1,2} ?[0-9][A-Z]{2}/i.test(value); },
}