var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-500 EditNumbersModal min-width-50percent-modal",
    attrs: {
      "width": "50%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose($event);
      }
    }
  }, [_c('section', {
    staticClass: "dialer-number-edit"
  }, [_c('div', {
    staticClass: "dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header flex-column align-items-start"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Manage number: " + _vm._s(_vm._f("number_formater")(_vm.data.number.didWithCode || _vm.data.number.number)))])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": _vm.api.delete_number.send,
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('p', {
    staticClass: "vm--modal-text pb-3"
  }, [_vm._v("Easily control and customize your phone number settings.")])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [!_vm.isCallerId ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column w-100"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Incoming calls")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText onlyfirstLetterCapital restLowerCase"
  }, [_vm._v(" When " + _vm._s(_vm._f("number_formater")(_vm.data.number.didWithCode || _vm.data.number.number)) + " is called it will ring to " + _vm._s(_vm._f("filterExtensionType")(_vm.data.extensionType)) + " ")]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between w-100 mt-24px"
  }, [_vm.data.number.ringgroupId == _vm.data.assigned ? [_c('div', {
    staticClass: "d-flex IVR-numbers-container flex-wrap"
  }, _vm._l(_vm.data.number.ringgroup_members, function (assigned) {
    return _c('div', {
      key: assigned,
      staticClass: "newIVRLayout-number-item hasUuersInIt"
    }, [_c('Info', {
      staticClass: "AganUpdatedOn25July2023",
      attrs: {
        "id": assigned,
        "is_blf": false
      }
    })], 1);
  }), 0)] : _vm.data.assigned ? [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('Info', {
    staticClass: "AganUpdatedOn25July2023",
    attrs: {
      "id": _vm.data.assigned,
      "is_blf": false
    },
    scopedSlots: _vm._u([{
      key: "sub-info",
      fn: function () {
        return [_vm.assignedTeam ? _c('div', {
          staticClass: "ViewConfigurationText mr-0 mb-0",
          staticStyle: {
            "font-size": "14px"
          },
          on: {
            "click": function ($event) {
              return _vm.$modal.show('EditNumberAssignMembersTeam', {
                team: _vm.assignedTeam,
                type: 'AssignNumbersTeam'
              });
            }
          }
        }, [_vm._v(" " + _vm._s(_vm._f("get_property")(_vm.assignedTeam, 'members.length', 0)) + " members ")]) : _vm._e()];
      },
      proxy: true
    }], null, false, 1455067655)
  }), _vm.assignedTeam ? _c('b-button', {
    staticClass: "newThisDeviceIndicaterTextBox-25-11-2023 h-fit-content",
    on: {
      "click": function ($event) {
        _vm.assignedTeam.status != 'active' ? _vm.appNotify({
          type: 'danger',
          message: 'Unable to proceed because this team is suspended'
        }) : _vm.$modal.show(`${_vm._uid}-EditTeamModal`, {
          team: _vm.assignedTeam
        });
      }
    }
  }, [_vm._v(" Configure ")]) : _vm.assignedIVR ? _c('b-button', {
    staticClass: "newThisDeviceIndicaterTextBox-25-11-2023 h-fit-content",
    on: {
      "click": function ($event) {
        _vm.assignedIVR.status != '1' ? _vm.appNotify({
          type: 'danger',
          message: 'Unable to proceed because this ivr is suspended'
        }) : _vm.$modal.show(`${_vm._uid}-EditMenuModal`, {
          menu: _vm.assignedIVR
        });
      }
    }
  }, [_vm._v(" Configure ")]) : _vm.assignedUser ? _c('b-button', {
    staticClass: "newThisDeviceIndicaterTextBox-25-11-2023 h-fit-content",
    on: {
      "click": function ($event) {
        _vm.assignedUser.active_status != '1' ? _vm.appNotify({
          type: 'danger',
          message: 'Unable to proceed because this user is suspended'
        }) : _vm.$modal.show(`${_vm._uid}-EditUserModal`, {
          accountcode: _vm.assignedUser.voipaccount
        });
      }
    }
  }, [_vm._v(" Configure ")]) : _vm.assignedQueue ? _c('b-button', {
    staticClass: "newThisDeviceIndicaterTextBox-25-11-2023 h-fit-content",
    on: {
      "click": function ($event) {
        _vm.assignedQueue.status != '1' ? _vm.appNotify({
          type: 'danger',
          message: 'Unable to proceed because this call queue is suspended'
        }) : _vm.$modal.show(`${_vm._uid}-EditCallQueueModal`, {
          call_queue: _vm.assignedQueue
        });
      }
    }
  }, [_vm._v(" Configure ")]) : _vm._e()], 1)] : _vm._e(), _c('button', {
    staticClass: "newButton ml-auto",
    attrs: {
      "disabled": _vm.api.delete_number.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-NumberAssignModal`, {
          number_id: _vm.numberId,
          assigneds: _vm.data.number.ringgroupId == _vm.data.assigned ? _vm.data.number.ringgroup_members : _vm.data.assigned ? [_vm.data.assigned] : [],
          user_mode: _vm.data.user_mode,
          extra_data: {
            ringgroupId: _vm.data.number.ringgroupId
          }
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v(_vm._s(_vm.isExtensionAssign ? "Change" : "Assign"))])], 1)], 2), !_vm.isCallerId ? _c('div', [_vm.assignedTeam ? _c('div') : _vm.assignedIVR ? _c('div', [_c('div', {
    staticClass: "w-100 newIVRLayout-forBorderBottom mt-20px"
  }, [_c('WelcomeGreeting', {
    staticClass: "newIVRLayout-WelcomeGreetingsSection",
    attrs: {
      "accountcode": _vm.assignedIVR.voipaccount,
      "menu": _vm.assignedIVR
    },
    on: {
      "fileChange": function ($event) {
        return _vm.$emit('ivr-update');
      }
    }
  })], 1), _c('MenuOptionsWidget', {
    attrs: {
      "menu": _vm.assignedIVR
    },
    on: {
      "updated": function ($event) {
        return _vm.$emit('ivr-update');
      }
    }
  })], 1) : _vm.assignedUser ? _c('div') : _vm.assignedQueue ? _c('div') : _vm._e()]) : _vm._e()])]) : _vm._e(), !_vm.isCallerId && _vm.ringgroupAccountcode ? [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column w-75"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Dedicate this number to a user")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" This option assigns a telephone number to a user, allowing custom call routing, forwarding, and voicemail settings in the user profile section. ")])]), _c('div', {
    staticClass: "md-mar-left"
  }, [_vm.api.update_user_mode.send ? _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "34px",
      "width": "60px",
      "border-radius": "25px"
    }
  }) : _c('b-form-checkbox', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    ref: "user-mode",
    staticClass: "newerSwitch",
    attrs: {
      "title": _vm.data.extensionType == 'USER' || _vm.data.number.ringgroup_members.length == 1 ? '' : 'Please select only one user to switch this toggle button',
      "disabled": _vm.api.update_user_mode.send || !(_vm.data.extensionType == 'USER' || _vm.data.number.ringgroup_members.length == 1),
      "checked": _vm.data.user_mode,
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.updateUserMode();
      }
    }
  })], 1)])] : _vm._e(), !_vm.isCallerId && _vm.ringgroupAccountcode && !_vm.data.user_mode && _vm.data.number.ringgroupId == _vm.data.assigned ? [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row somethingInside"
  }, [!!_vm.ringgroupAccountcode ? _c('CallForwardingSetting', {
    attrs: {
      "account": _vm.ringgroupAccountcode
    },
    on: {
      "cf-updated": function ($event) {
        return _vm.$emit('cf-updated');
      }
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row somethingInside pt-0 dialer_box_text_my-0"
  }, [!!_vm.ringgroupAccountcode ? _c('ScheduleSetting', {
    attrs: {
      "account": _vm.ringgroupAccountcode
    },
    on: {
      "ofh-days-updated": function ($event) {
        return _vm.$emit('ofh-days-updated');
      },
      "ofh-default-updated": function ($event) {
        return _vm.$emit('ofh-default-updated');
      },
      "ofh-toggle-updated": function ($event) {
        return _vm.$emit('ofh-toggle-updated');
      }
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row somethingInside dialer_box_text_my-0"
  }, [_vm.ringgroupAccountcode ? _c('MusicOnHoldWidget', {
    attrs: {
      "accountcode": _vm.ringgroupAccountcode
    }
  }) : _vm._e()], 1)] : _vm._e(), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column w-100"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Outgoing calls only")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" The assigned users will only be able to dial outbound call using the \"Outbound only number\" as caller ID. ")]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between w-100 mt-24px"
  }, [_c('div', {
    staticClass: "d-flex IVR-numbers-container flex-wrap"
  }, _vm._l(_vm.data.extensions, function (extension) {
    return _c('div', {
      key: extension,
      staticClass: "newIVRLayout-number-item hasUuersInIt"
    }, [_c('Info', {
      staticClass: "AganUpdatedOn25July2023",
      attrs: {
        "id": _vm._f("filterExtensionValue")(extension),
        "is_blf": false
      }
    })], 1);
  }), 0), _c('button', {
    staticClass: "newButton md-mar-left",
    attrs: {
      "disabled": _vm.api.delete_number.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('UserCallerIdShared2', {
          extensions: _vm.data.extensions,
          number: _vm.data.number,
          type: 'UserCallerIdShared'
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-singleUser-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Add users")])], 1)])])]), !_vm.isCallerId ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row rowThatHasLabel"
  }, [_c('div', {
    staticClass: "d-flex flex-column w-75"
  }, [_c('h3', {
    staticClass: "latestGreyBox-heading-main mb-0"
  }, [_vm._v("Label")]), _c('p', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Personalize your phone number with a custom label for easy identification.")]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-50 position-relative addedLabelInsideEditNumbersModal"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.data.number.label ? `( ${_vm.data.number.label} )` : 'No label assigned') + " ")])])]), _c('b-button', {
    staticClass: "newButton",
    attrs: {
      "disabled": _vm.api.delete_number.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('ChangeNumberLabel', {
          label: _vm.data.number.label
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change")])], 1)], 1) : _vm._e()], 2), !_vm.isCallerId ? _c('div', {
    staticClass: "mt-20px"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Call recording")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" Call recordings will be available to review and share in the activity feed on the dashboard and in the phone app. ")]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column w-100"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Record incoming calls")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("If enabled, this will start recording "), _c('b', [_vm._v("incoming calls")]), _vm._v(" automatically")])]), _vm.api.toggle_incoming_call.send || _vm.api.fetch_number_settings.send ? _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "34px",
      "width": "60px",
      "border-radius": "25px"
    }
  }) : _c('b-form-checkbox', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    ref: "toggle_incoming_call_ref",
    staticClass: "newerSwitch",
    attrs: {
      "title": !_vm.getUserPermissions.call_recording ? 'Your package does not support this feature' : '',
      "name": "check-button",
      "switch": "",
      "disabled": !_vm.getUserPermissions.call_recording || _vm.api.toggle_incoming_call.send || _vm.api.fetch_number_settings.send,
      "checked": _vm.data.incoming_call
    },
    on: {
      "change": function ($event) {
        return _vm.toggleIncomingCall($event);
      }
    }
  })], 1), _c('div', {
    staticClass: "innerRedBoxwithRoundedBorder mt-20px"
  }, [_c('vb-icon', {
    staticClass: "dialer-thunder-icon",
    attrs: {
      "icon": "dialer-thunder-icon",
      "height": "23.871px",
      "width": "11.936px"
    }
  }), _c('p', {
    staticClass: "innerRedBoxwithRoundedBorder-text mb-0"
  }, [_vm._v(" We recommend you mention that the call will be recorded in the welcome message. If you wish to delete recorded calls, contact our support team. ")])], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-start justify-content-center w-100"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Play welcome message")]), _vm.api.toggle_welcome_greeting.send || _vm.api.fetch_number_settings.send ? _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "34px",
      "width": "60px",
      "border-radius": "25px"
    }
  }) : _c('b-form-checkbox', {
    ref: "toggle_welcome_greeting_ref",
    staticClass: "newerSwitch",
    attrs: {
      "name": "check-button",
      "switch": "",
      "disabled": _vm.api.toggle_welcome_greeting.send || _vm.api.fetch_number_settings.send,
      "checked": _vm.data.welcome_greeting
    },
    on: {
      "change": function ($event) {
        return _vm.toggleWelcomeGreeting($event);
      }
    }
  })], 1), !_vm.data.welcome_greeting ? _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Customize the first impression of your business by uploading or recording a personalized welcome greeting.")]) : _vm._e(), _vm.data.welcome_greeting ? _c('WelcomeGreeting', {
    staticClass: "newIVRLayout-WelcomeGreetingsSection",
    attrs: {
      "file": _vm.data.welcome_greeting_announcement_url,
      "number": _vm.data.number
    },
    on: {
      "fileChange": function ($event) {
        return _vm.$emit('interface');
      }
    }
  }) : _vm._e()], 1)]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Record outgoing calls")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("If enabled, this will start recording "), _c('b', [_vm._v("outgoing calls")]), _vm._v(" automatically")])]), _vm.api.toggle_outgoing_call.send || _vm.api.fetch_number_settings.send ? _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "34px",
      "width": "60px",
      "border-radius": "25px"
    }
  }) : _c('b-form-checkbox', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    ref: "toggle_outgoing_call_ref",
    staticClass: "newerSwitch",
    attrs: {
      "title": !_vm.getUserPermissions.call_recording ? 'Your package does not support this feature' : '',
      "name": "check-button",
      "switch": "",
      "disabled": !_vm.getUserPermissions.call_recording || _vm.api.toggle_outgoing_call.send || _vm.api.fetch_number_settings.send,
      "checked": _vm.data.outgoing_call
    },
    on: {
      "change": function ($event) {
        return _vm.toggleOutgoingCall($event);
      }
    }
  })], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Call recording transcriptions")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("If enabled, this will automatically transcribe the recorded calls.")])]), _vm.api.toggle_transcriptions.send || _vm.api.fetch_number_settings.send ? _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "34px",
      "width": "60px",
      "border-radius": "25px"
    }
  }) : _c('b-form-checkbox', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    ref: "toggle_transcriptions_ref",
    staticClass: "newerSwitch",
    attrs: {
      "title": !_vm.getUserPermissions.call_recording_transcriptions ? 'Your package does not support this feature' : !_vm.data.incoming_call && !_vm.data.outgoing_call ? 'Firstly enable any one of the incoming or outgoing recording' : '',
      "name": "check-button",
      "switch": "",
      "disabled": !_vm.data.incoming_call && !_vm.data.outgoing_call || !_vm.getUserPermissions.call_recording_transcriptions || _vm.api.toggle_transcriptions.send || _vm.api.fetch_number_settings.send,
      "checked": _vm.data.transcriptions
    },
    on: {
      "change": function ($event) {
        return _vm.toggleTranscriptions($event);
      }
    }
  })], 1)])]) : _vm._e(), !_vm.isCallerId && _vm.ringgroupAccountcode && !_vm.data.user_mode && _vm.data.number.ringgroupId == _vm.data.assigned ? [_vm.ringgroupAccountcode ? _c('VoicemailSetting', {
    staticClass: "VoicemailSetting-insideTeamsModal",
    attrs: {
      "accountcode": _vm.ringgroupAccountcode
    }
  }) : _vm._e()] : _vm._e(), _vm.data.number.did ? _c('EmergenyService', {
    attrs: {
      "did": _vm.data.number.did
    }
  }) : _vm._e(), !_vm.isCallerId ? [!_vm.isExtensionAssign && _vm.data.number.is_trial != 1 ? _c('div', {
    staticClass: "mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Delete this number?")]), _c('div', {
    staticClass: "md-mar-left"
  }, [_vm.data.number.status == 4 ? _c('span', [_vm._v(_vm._s(_vm.data.number.actionable_date))]) : _c('button', {
    staticClass: "newDeleteButton",
    attrs: {
      "disabled": _vm.api.delete_number.send
    },
    on: {
      "click": function ($event) {
        return _vm.deletenumber();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-delete-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDeleteButton-textPart"
  }, [_vm._v("Delete")])], 1)])])])]) : _vm._e()] : _vm.isCallerId ? [_c('div', {
    staticClass: "mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column w-75"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Allow add caller ID")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" There is a decription about that ")])]), _c('div', {
    staticClass: "md-mar-left"
  }, [_vm.api.change_allow_add_callerid.send ? _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "34px",
      "width": "60px",
      "border-radius": "25px"
    }
  }) : _c('b-form-checkbox', {
    ref: "allow-add-callerid",
    staticClass: "newerSwitch",
    attrs: {
      "disabled": _vm.api.change_allow_add_callerid.send,
      "checked": _vm.data.allow_add_callerid,
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.changeAllowAddCallerID();
      }
    }
  })], 1)])])]), _c('div', {
    staticClass: "mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Delete this caller id?")]), _c('div', {
    staticClass: "md-mar-left"
  }, [_vm.data.number.status == 4 ? _c('span', [_vm._v(_vm._s(_vm.data.number.actionable_date))]) : _c('button', {
    staticClass: "newDeleteButton",
    attrs: {
      "disabled": _vm.api.delete_number.send
    },
    on: {
      "click": function ($event) {
        return _vm.deletecallerid();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-delete-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDeleteButton-textPart"
  }, [_vm._v("Delete")])], 1)])])])])] : _vm._e()], 2)])])]), _c('AddRemoveFromList', {
    attrs: {
      "modalName": "UserCallerIdShared2"
    },
    on: {
      "update-list": function ($event) {
        return _vm.onCallerIDUpdated($event);
      }
    }
  }), _c('NumberAssignModal', {
    attrs: {
      "modalName": `${_vm._uid}-NumberAssignModal`
    },
    on: {
      "updated": function ($event) {
        return _vm.onUpdateAssign($event);
      }
    }
  }), _c('AddRemoveFromList', {
    attrs: {
      "modalName": "EditNumberAssignMembersTeam"
    },
    on: {
      "update-list": function ($event) {
        return _vm.getVoipTeams();
      }
    }
  }), _c('ChangeNumberLabelModal', {
    attrs: {
      "numberId": _vm.numberId
    },
    on: {
      "updated": function ($event) {
        _vm.data.number.label = $event.label;
        _vm.$emit('latest');
      }
    }
  }), _c('EditMenuModal', {
    attrs: {
      "modalName": `${_vm._uid}-EditMenuModal`
    },
    on: {
      "interface": function ($event) {
        return _vm.$emit('ivr-update');
      }
    }
  }), _c('EditTeamModal', {
    attrs: {
      "modalName": `${_vm._uid}-EditTeamModal`
    },
    on: {
      "interface": function ($event) {
        return _vm.$emit('team-update');
      }
    }
  }), _c('EditUserModal', {
    attrs: {
      "modalName": `${_vm._uid}-EditUserModal`
    }
  }), _c('EditCallQueueModal', {
    attrs: {
      "modalName": `${_vm._uid}-EditCallQueueModal`
    },
    on: {
      "list-update": function ($event) {
        return _vm.$emit('queue-update');
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }