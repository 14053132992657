<template>
  <div class="crm-main-section CompanySection">
    <div class="crm-main-section-topBar">
      <h2 class="crm-main-section-heading mb-0">
        Companies
      </h2>
      <div class="section-topBar-middlePart">
        <vb-icon icon="crm-inputSearch-icon" />
        <input type="text" placeholder="Search">
      </div>
      <div class="section-topBar-lastPart">
        <button class="crm-button listGridView-btn centerBtn ml-0">
          <vb-icon class="crm-threeDotsHorizontal-icon" icon="crm-threeDotsHorizontal-icon" />
        </button>
        <button class="crm-button">
          <vb-icon class="crm-plusButton-icon" icon="crm-plusButton-icon" />
          Create Companies
        </button>
      </div>
    </div>
    <div class="crm-main-section-body">
      <div class="d-flex justify-content-between">
        <div class="crm-card halfWidth">
          <div class="crm-card-heading">
            <div>
              Companies by day
              <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
            </div>
            
            <div class="d-flex align-items-center">
              <b-dropdown class="crm-dd-container" id="sound-setting-micro-devices" no-caret text="Select Device">
                <template #button-content>
                  <span class="d-flex align-items-center p-0">
                    <span>This week</span>
                    <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                  </span>
                </template>
                <b-dropdown-item >
                  <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                  <span> This week </span>
                </b-dropdown-item>
                <b-dropdown-item >
                  <span> This Month </span>
                </b-dropdown-item>
                <b-dropdown-item >
                  <span> Next Week </span>
                </b-dropdown-item>
                <b-dropdown-item >
                  <span> Next Month </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div class="crm-graph-container">
            put graph here
          </div>
        </div>
        <div class="crm-card halfWidth">
          <div class="crm-card-heading">
            <div>
              Companies by source
              <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
            </div>
            <div class="d-flex align-items-center">
              <b-dropdown class="crm-dd-container" id="sound-setting-micro-devices" no-caret text="Select Device">
                <template #button-content>
                  <span class="d-flex align-items-center p-0">
                    <span>This week</span>
                    <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                  </span>
                </template>
                <b-dropdown-item >
                  <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                  <span> This week </span>
                </b-dropdown-item>
                <b-dropdown-item >
                  <span> This Month </span>
                </b-dropdown-item>
                <b-dropdown-item >
                  <span> Next Week </span>
                </b-dropdown-item>
                <b-dropdown-item >
                  <span> Next Month </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div class="crm-graph-container">
            put graph here
          </div>
        </div>
      </div>
      <div class="w-100 d-flex justify-content-between align-items-center mb-3">
        <div class="w-100 d-flex">
          <b-dropdown class="crm-dd-black-container" no-caret  >
            <template #button-content>
              <span class="d-flex align-items-center p-0">
                <vb-icon icon="crm-filterOptions-icon" class="crm-filterOptions-icon"/>
                <span>Filter</span>
              </span>
            </template>
            <b-dropdown-item >
              <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
              <span> This week </span>
            </b-dropdown-item>
            <b-dropdown-item >
              <span> This Month </span>
            </b-dropdown-item>
            <b-dropdown-item >
              <span> Next Week </span>
            </b-dropdown-item>
            <b-dropdown-item >
              <span> Next Month </span>
            </b-dropdown-item>
          </b-dropdown>
          <button class="crm-button ml-2">
            <vb-icon icon="crm-pencil-icon" height="16px" width="16px"/>
          </button>
        </div>
        <div class="d-flex align-items-center">
          <div class="crm-input-container mb-0 mr-3">
            <input placeholder="Search" class="w-100"/>
            <vb-icon icon="crm-inputSearch-icon" />
          </div>
        </div>
        <!-- <div class="totalsTextOnMain">
          1 Companies
        </div> -->
      </div>
      <vb-table class="mt-1" :isListEmpty="isEmpty(tableData)" :listLength="tableData.length" textNoRecord=" ">
        <template slot="header">
          <tr>
            <th>
              <b-form-checkbox class="crm-checkbox" id="checkbox-1"/>
            </th>
            <th class="dialer-has-sort">
              <span class="dialer-sorted-by ascending">Name</span>
            </th>
            <th class="dialer-has-sort">
              <span>E-mail address</span>
            </th>
            <th class="dialer-has-sort">
              <span>Source</span>
            </th>
            <th class="dialer-has-sort">
              <span>owner</span>
            </th>
            <th class="dialer-col-right cursor_pointer">
              <span>Source</span>
            </th>
          </tr>
        </template>
        <template #body="{ start, end }">
          <tr v-for="(data, index) in tableData" :key="data.id" v-show="index >= start && index <= end">
            <td>
              <b-form-checkbox class="crm-checkbox" v-model="data.chk" id="checkbox-1"/>
            </td>
            <td class="dialer-row-title">
              <span>{{ data.m1 }}</span>
            </td>
            <td class="dialer-row-title">{{ data.m1 }}</td>
            <td class="dialer-row-title">{{ data.m1 }}</td>
            <td class="dialer-row-title">{{ data.m1 }}</td>
            <td class="dialer-col-right">
              {{data.m2}}
            </td>
          </tr>
        </template>
      </vb-table>
    </div>
    

  </div>
</template>

<script>
export default {
  name: 'Companies',
  components: {
    
  },
  inject:['isEmpty'],
  data(){
    return {
      tableData : {
        0: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        1: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        2: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        3: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        4: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        5: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        6: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        7: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        8: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        9: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        10: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
      }
    }
  },
  computed: {
    
  },
  methods: {
    
  },
  mounted(){
    
  },
}
</script>

<style>

</style>