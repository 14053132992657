<template>
  <!-- <vue-resizable
    :id="`local-track-container-${videoCall.room}`"
    :class=" Object.values(participants).length === 0 ? 'no-participant' : 'after-participant'"
    :dragSelector="dragSelector"
    :active="handlers"
    :fit-parent="fit"
    :max-width="maxW | checkEmpty"
    :max-height="maxH | checkEmpty"
    :min-width="minW | checkEmpty"
    :min-height="minH | checkEmpty"
    :width="width"
    :height="width"
    :left="left"
    :top="top"
    @mount="eHandler"
    @resize:move="eHandler"
    @resize:start="eHandler"
    @resize:end="eHandler"
    @drag:move="eHandler"
    @drag:start="eHandler"
    @drag:end="eHandler"
  > -->
    <div
      :id="`local-track-container-${videoCall.room}-2`"
      class="participant-container bill-track-1"
      :class="{
        'speaker': speaker
      }"
      style="overflow: hidden;" 
    >
      <!-- class="localtracks-container-3 -->
      <!-- <b-alert show variant="danger" class="p-3" v-if="error">
        <b-icon icon="plus" @click="$emit('error-close')" />
        <h1>{{ error.type }}</h1>
        <p>{{ error.message }}</p>
        <b-icon icon="x" @click="error.track_failed=='audio' ? $emit('audio') : error.track_failed=='video' ? $emit('camera') : ''" />
      </b-alert> -->
      <div class="p-3 duringVideoCallErrorMsg-container" v-if="error">
        <b-icon class="duringVideoCallErrorMsg-closeIcon" icon="x-circle-fill" @click="$emit('error-close')" />
        <div class="duringVideoCallErrorMsg-inner">
          <p>{{ error.message }}</p>
          <div class="duringVideoCallErrorMsg-retry" @click="error.track_failed=='audio' ? $emit('audio') : error.track_failed=='video' ? $emit('camera') : ''">
            <p>Retry</p>
            <b-icon icon="arrow-clockwise" @click="error.track_failed=='audio' ? $emit('audio') : error.track_failed=='video' ? $emit('camera') : ''" />
          </div>
        </div>
      </div>
      <div v-if="isVideoNotShow || !performanceSetting.show_video" class=" video-not-shown-8569">
        <!-- video-not-show-678 -->
         <!-- style="background-color:#232124" -->
        <div>
          <img
            :src="$store.getters.getCurrentUser.profileImg"
            @error="$event.target.src = user_male"
            :alt="$store.getters.getCurrentUser.display_name"
          />
        </div>
        <span class="callParticipantsName">{{$store.getters.getCurrentUser.display_name}}</span>
      </div>
      <div class="remote-tracks-conatiner forLocalTrack">
        <LocalTrack
          v-show="!isVideoNotShow || performanceSetting.show_video"
          v-for="(localTrack, index) in localTracks"
          :key="`${localTrack.type}_${
            localTrack.videoType
          }_${localTrack.isMuted()}_${localTrack.getId()}_${index}`"
          :localTrack="localTrack"
          :index="index"
          :JitsiConference="JitsiConference"
          :isJoined="isJoined"
          :isVideoMuted="isVideoMuted"
          :payload="payload"
          :listIndex="listIndex"
        />
      </div>
    </div>
  <!-- </vue-resizable> -->
</template>

<script>
import LocalTrack from "./LocalTrack";
// import VueResizable from "vue-resizable";
import { 
  // vue_resizeable_controller, 
  local_files 
} from "../../../mixin";
import { LOGGER } from "../../../utils";
export default {
  name: "LocalTracks",
  mixins: [
    // vue_resizeable_controller, 
    local_files
  ],
  components: {
    LocalTrack,
    // VueResizable,
  },
  props: {
    participants: {
      type: Object,
      default: function () {
        return {};
      },
    },
    localTracks: {
      type: Array,
    },
    JitsiConference: {
      type: Object,
    },
    isJoined: {
      type: Boolean,
    },
    isVideoMuted: {
      type: Boolean,
    },
    payload: {
      type: Object,
    },
    listIndex: {
      type: Number,
    },
    videoCall: {
      type: Object,
      default: function () {
        return {};
      },
    },
    speaker: {
      type: Boolean,
      default: false,
    },
    performanceSetting: {
      type: Object,
      default: ()=>({})
    },
    error: {
      type: [Object,String],
      default: ()=>({})
    },
  },
  data() {
    return {
      user_male: require("@/assets/images/user-male.png"),
    };
  },
  computed: {
    isVideoNotShow() {
      const [video] = this.localTracks.filter((track) => track.type === "video");
      return this.videoCall.isVideoMute || !video;
    },
  },
  methods: {
    eHandler(data) {
      LOGGER.info(data);
      // this.width = data.width>data.height ? data.width:data.height;
      // this.height = data.width>data.height ? data.width:data.height
      // this.width = data.width;
      this.left = data.left;
      this.top = data.top;
      this.event = data.eventName;
    },
  },
  created() {
    this.dragSelector = `#local-track-container-${this.videoCall.room}-2`;
    this.handlers = ["rb", "lb", "lt", "rt"];
  },
};
</script>

<style lang="scss" scoped>
.no-participant {
  left: 0px !important;
  top: 0px !important;
  width: 100% !important;
  height: 100% !important;
  .localtracks-container-3 {
    width: 100%;
    height: 100%;
  }
}
.localtracks-container {
  position: absolute;
  height: 80%;
  width: 80%;
  bottom: 0px;
  right: 0px;
  z-index: 2;
  flex: 0;
}
.localtracks-container-2 {
  height: 100%;
  width: 100%;
}
.video-not-show-678 {
  height: inherit;
  width: inherit;
  overflow: hidden;
  position: absolute;
  background: grey;
  display: flex;
  justify-content: center;
  align-items: center;
  @include border-radius($border-radius);
  div {
    height: 80px;
    width: 80px;
    overflow: hidden;
    @include border-radius(50%);
    img {
      height: 100%;
      width: 100%;
    }
  }
}






.remote-track-container {
  height: inherit;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
</style>
