var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('HorizontalLayout', {
    attrs: {
      "bottomline": true
    },
    on: {
      "change-graph": function ($event) {
        return _vm.$emit('interface', $event);
      }
    }
  }, [_c('div', {
    attrs: {
      "slot": "right"
    },
    slot: "right"
  }, [_c('info', {
    staticClass: "align-items-center",
    attrs: {
      "id": _vm.voipaccount,
      "is_blf": false
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }