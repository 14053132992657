<template>
  <div class="content-area d-flex h-100 dialer_main_body">
    <div class="dialer_main_body_inner w-100">
      <template v-if="getCurrentUser.administrator_account">
        <div class="dialer_inner_left_side allow-scroll analyticsLeftSideBar d-r-768px-none h-100" >
          <h2 class="dialer_main_title text-capitalize">Analytics</h2>
          <ul class="list-unstyled">
            <li 
              v-for="tab in tabs" 
              :key="tab.component" 
              @click="SET_LOCAL_SETTING_ANALYTICS_TAB(tab.component)" 
              class="dialer_tab_btn"
              :class="{
                'active' : GET_LOCAL_SETTING_ANALYTICS.tab === tab.component
              }"
            >
              <a class="text-capitalize">
                <span>
                  <b-icon :icon="tab.icon" scale="1.3" class="mr-3" />
                </span>
                <span>{{ tab.label }}</span>
              </a> 
            </li>
          </ul>
        </div>
        <button class="d-r-sidebar-button" @click="showSidebar">
          <font-awesome-icon :icon="['fas', 'bars']" scale="1.2" />
        </button>
      </template>
      <div class="dialer_inner_right_side">
        <component :is="GET_LOCAL_SETTING_ANALYTICS.tab" @change-tab="SET_LOCAL_SETTING_ANALYTICS_TAB($event)"></component>
      </div>
    </div>
  </div>
</template>

<script>
import Summary from "@/components/Analytics/Summary.vue";
import Users from "@/components/Analytics/Users.vue";
import Teams from "@/components/Analytics/Teams.vue";
import Numbers from "@/components/Analytics/Numbers.vue";
import Reports from "../components/Analytics/ReportsCopy.vue";
import AllExtensions from "@/components/Analytics/AllExtensions.vue";
import Billing from "@/components/Analytics/Billing.vue";
import Something from "@/components/Analytics/Something.vue";
import Something2 from "@/components/Analytics/Something2.vue";
import Voicemail from "@/components/Analytics/Voicemail.vue";
import { mapGetters, mapMutations } from 'vuex';
import { GET_LOCAL_SETTING_ANALYTICS } from '@/store/helper/getters';
import { SET_LOCAL_SETTING_ANALYTICS_TAB } from '@/store/helper/mutations';

export default {
  name: "Analytics",
  components: {
    Summary,
    Numbers,
    Users,
    Teams,
    Reports,
    AllExtensions,
    Billing,
    Something,
    Something2,
    Voicemail,
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      GET_LOCAL_SETTING_ANALYTICS
    ]),
    tabs(){
      return [
        {
          icon: "graph-up",
          label: "summary",
          component: "Summary",
        },
        {
          icon: "person",
          label: "users",
          component: "Users",
        },
        {
          icon: "phone",
          label: "numbers",
          component: "Numbers",
        },
        {
          icon: "people",
          label: "teams",
          component: "Teams",
        },
        {
          icon: "voicemail",
          label: "Voicemail",
          component: "Voicemail",
        },
        // {
        //   icon: "file-earmark-text",
        //   label: "reporting",
        //   component: "Reports",
        // },
        // {
        //   icon: "file-earmark-text",
        //   label: "Reporting",
        //   component: "Reports",
        // },
        // {
        //   icon: "wallet",
        //   label: "Billed Calls",
        //   component: "Billing",
        // },
        // {
        //   icon: "file-earmark-text",
        //   label: "Reporting Copy",
        //   component: "Something",
        // },
        {
          icon: "file-earmark-text",
          label: "Reporting",
          component: "Something2",
        },
      ]
    },
  },
  methods: {
    ...mapMutations([
      SET_LOCAL_SETTING_ANALYTICS_TAB
    ]),
    showSidebar(){
      document.querySelector(".dialer_inner_left_side").classList.toggle('d-r-768px-none');
      document.querySelector(".d-r-sidebar-button").classList.toggle('active');
    },
  },
  created() {
    if(!this.getCurrentUser.administrator_account){
      this.SET_LOCAL_SETTING_ANALYTICS_TAB('Summary')
    } else {
      this.SET_LOCAL_SETTING_ANALYTICS_TAB(this.GET_LOCAL_SETTING_ANALYTICS.tab || 'Summary')
    }
  },
};
</script>
