<template>
  <div class="dialer-settings-main"> 
    <section v-if="getIsMobile" class="dialer-settings-section locationSettings ">
      <div class="d-flex align-items-center mb-3 calc-title-width">
        <vb-icon icon="latest-sideBarOpenIconForTodoInMobileView-icon" width="19.979px" height="16px" class="settings-back-icon cursor_pointer" style="" @click="$emit('openSideBar')" />
        <h2 class="dialer-settings-title w-100 mb-0 text-center flex-1">My tasks</h2>
        <div class="d-flex align-items-center">
          <div v-b-popover.hover.top="'Export'" class="mr-4px cursor_pointer_no_hover" @click="exportTasks()">
            <vb-icon icon="taskHub-export-icon" width="50px" height="50px" />
          </div>
          <div class="mr-4px cursor_pointer_no_hover" @click="$modal.show('SelectFilterOfTasks',{
            assignees: getTasksFilters.assignees,
            statuses: getTasksFilters.statuses,
            groups: getTasksFilters.groups,
            priorities: getTasksFilters.priorities,
            space_ids: space_ids,
          })">
            <!-- Filters -->
            <vb-icon icon="todo-filter-icon" height="50px" width="50px" />
          </div>
          <b-dropdown no-caret class="filterDropdownForTaskHub ml-3" right>
            <template #button-content>
              <vb-icon icon="latest-HorizontalThreeDots-icon" width="27px" height="6px" />
            </template>
            <!-- <b-dropdown-item v-for="group in groupBy" :key="group.value" @click="filter.tasks.group_by=group.value;">
              <div class="d-flex align-items-center">
                <b-icon :style="`opacity: ${filter.tasks.group_by==group.value?'1':'0'}`" icon="check" font-scale="1.4" class="mr-2" />
                <span class="mr-3">{{group.text}}</span>
              </div>
            </b-dropdown-item> -->
            <b-dropdown-form @submit.prevent="''" class="inputItemContainer">
              <div class="whiteBGinputWithGreyRoundedBorder withLeftIconOnly w-100">
                <div class="inputContainer-inside-whiteBGinputWithGreyRoundedBorder">
                  <vb-icon icon="callActivity-search" class="greySerchIcon" height="18px" width="17.75px" />
                  <b-input placeholder="Search filters..." v-model="filter.group_by.search" v-focus />
                </div>
              </div>
            </b-dropdown-form>
            <b-dropdown-item :class="`${filter.tasks.group_by==group.value?'active':''}`"
              v-for="group in filterGroupBy" 
              :key="group.value" 
              @click="filter.tasks.group_by=group.value;filter.group_by.search='';"
            >
              {{ group.text }}
              <b-icon v-if="filter.tasks.group_by==group.value" class="darker-chevron-down withoutPath" icon="check-lg" />
            </b-dropdown-item>
          </b-dropdown>
          <!-- <b-dropdown no-caret class="filterDD insideTODO">
            <template #button-content>
              <vb-icon icon="latest-HorizontalThreeDots-icon" width="27px" height="6px" />
            </template>
            <b-dropdown-item>Something</b-dropdown-item>
            <b-dropdown-item>Something</b-dropdown-item>
            <b-dropdown-item>Something</b-dropdown-item>
            <b-dropdown-item>Something</b-dropdown-item>
          </b-dropdown> -->
        </div>
      </div>
      
      <div v-if="space_ids.length==0">
        Please select a space in order to see your list of tasks
      </div>
      <template v-else>
        <div class="users-settings-2nd-section d-flex align-items-center justify-content-between md-mar-top">
          <div class="whiteBGinputWithGreyRoundedBorder seachBox">
            <vb-icon icon="mobile-searchBar-icon-likeAirCall-icon" width="12.68px" height="12.67px"/>
            <b-input type="text" v-model="filter.tasks.search" @input="getTasksDebounce()" placeholder="Search tasks"  />
          </div>
        </div>
        <div >
          <div style="">
            <TaskItem v-if="isEmpty(taskGroup)" :addTask="true" :space_id="space_ids[0]" @created="getTasksDebounce()" />
            <div v-for="group in taskGroup" :key="group.filter.key">
              <div class="statusDevider">
                <span>{{ group.text }}</span>
                <div v-if="group.color" class="statusColorBox" :style="`background-color: ${group.color}`"></div>
              </div>
              <TaskItem :addTask="true" :key="`add-task-${group.filter.key}`" :space_id="group.space.id" @created="getTasksDebounce()" />
              <TaskItem 
                v-for="task in group.tasks" 
                :key="task.id" 
                :task="task" 
                @deleted="onDeleteTask($event)"
                @taskClick="$modal.show(`${_uid}-EditTasks`,{ 
                  id: task.id 
                })" 
              />
            </div>
          </div>
        </div>
      </template>
    </section>
    <section v-else class="dialer-settings-section locationSettings newTasksModule">
      <div v-if="space_ids.length==0">
        <div class="dialer-flex">
          <h2 class="dialer-settings-title d-flex align-items-center">
            <vb-icon icon="latest-sideBarOpenIconForTodoInMobileView-icon" width="19.979px" height="16px" class="settings-back-icon cursor_pointer showOnlyOnSmallerScreens mr-12px" style="" @click="$emit('openSideBar')" />
            My tasks
          </h2>
        </div>
        <div class="latestGreyBox-9-9-2023 mt-32px">
          <div class="emptyScreenContainer">
            <img class="mt-12px wd-30 h-auto" v-if="GET_LOCAL_SETTING_IS_DARK_MODE" :src="require('@/assets/images/emptyScreenImages/darkMode/chat.png')"/>
            <img class="mt-12px wd-30 h-auto" v-else :src="require('@/assets/images/emptyScreenImages/chat.png')"/>
            <div class="emptyScreenContainer-heading">
              Select a space to view tasks
            </div>
            <div class="emptyScreenContainer-text w-75">
              Seamlessly manage tasks, assign call-related actions, and track priorities within our task module. Streamline your workflow for enhanced productivity.
            </div>
          </div>
        </div>
      </div>
      <template v-else>
        <div class="taskHub-firstSection-grid">
          <h2 class="dialer-settings-title d-flex align-items-center">
            <vb-icon icon="latest-sideBarOpenIconForTodoInMobileView-icon" width="19.979px" height="16px" class="settings-back-icon cursor_pointer showOnlyOnSmallerScreens mr-12px" style="" @click="$emit('openSideBar')" />
            My tasks
          </h2>
          <div class="whiteBGinputWithGreyRoundedBorder ml-auto seachBox">
            <b-input type="text" v-model="filter.tasks.search" @input="getTasksDebounce()" placeholder="Search tasks"  />
          </div>
          <span class="dialer-settings-intro newerTextVersion mb-0">
            Seamlessly manage tasks, assign call-related actions, and track priorities within our task module. 
            Streamline your workflow for enhanced productivity.
          </span>
        </div>
        <!-- <div class="users-settings-2nd-section d-flex align-items-center justify-content-between md-mar-top">
          <span class="w-50">
            Seamlessly manage tasks, assign call-related actions, and track priorities within our task module. 
            Streamline your workflow for enhanced productivity.
          </span>
          <div class="d-flex justify-content-end align-items-center w-50">
            <div class="whiteBGinputWithGreyRoundedBorder sm-mar-left seachBox">
              <b-input type="text" v-model="filter.tasks.search" @input="getTasksDebounce()" placeholder="Search tasks"  />
            </div>
          </div>
        </div> -->
        
        <div class="d-flex align-items-center justify-content-end w-100 mt-45px">
          <div v-b-popover.hover.top="'Export'" class="mr-12px cursor_pointer_no_hover" @click="exportTasks()">
            <vb-icon icon="taskHub-export-icon" width="50px" height="50px" />
          </div>
          <div v-b-popover.hover.top="'Import'" class="mr-12px cursor_pointer_no_hover" @click="api.import_tasks.send?'':$refs['upload_file'].click()">
            <input 
              ref="upload_file" 
              type="file" 
              v-show="false" 
              :multiple="false" 
              :disabled="api.import_tasks.send"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              @change="importTasks(getProperty($event,'target.files[0]'))" 
            />
            <vb-icon id="popover-button-open" icon="taskHub-import-icon" width="50px" height="50px" />
            <template v-if="api.import_tasks.upload_progress.is_process">
              <b-popover show top target="popover-button-open">
                {{ `${api.import_tasks.upload_progress.percentage}%` }}
              </b-popover>
            </template>
            <!-- <p v-if="api.import_tasks.upload_progress.is_process">{{ `${api.import_tasks.upload_progress.percentage}%` }}</p> -->
          </div>
          <div class="mr-12px cursor_pointer_no_hover compactViewContainer" @click="isCompact=!isCompact;">
            <vb-icon v-if="isCompact" icon="todo-compact-icon" height="50px" width="50px" />
            <vb-icon v-else icon="todo-notCompact-icon" height="50px" width="50px" />
            <!-- <div v-if="isCompact">Compact</div>
            <div v-else>Not Compact</div> -->
          </div>
          <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion ul-width-fit-content mr-12px">
            <vb-select 
              v-model="filter.tasks.filter_id"
              :disabled="false"
              @change="getTasks(true)"
              :options="response.filters"
              textField="label"
              valueField="id"
              :isCheckShow="true"
              :defaultSelectedText="'Select filter'"
            >
              <template #option="{ option }">
                <div>
                  <b-icon v-if="filter.tasks.filter_id==option.id" icon="check" variant="success" />
                  {{ option.label }}
                </div>
                <div class="d-flex">
                  <b-spinner v-if="api.delete_filter.send.includes(option.id) || api.toggle_default_filter.send.includes(option.id)" type="grow" label="Spinning" />
                  <template v-else>
                    <b-button class="p-0 ml-8px" :disabled="option.status==1" @click.stop="markDefaultFilter(option)" variant="link">
                      <b-icon v-if="option.status==1" icon="circle-fill" variant="success" />
                      <b-icon v-else icon="circle" />
                    </b-button>
                    <b-button class="p-0 ml-8px" :disabled="filter.tasks.filter_id==option.id" @click.stop="deleteFilter(option)" variant="link">
                      <b-icon icon="trash-fill" variant="danger" />
                    </b-button>
                  </template>
                </div>
              </template>
            </vb-select>
          </div>
          <div class="mr-12px cursor_pointer_no_hover" @click="$modal.show('SelectFilterOfTasks',{
            assignees: getTasksFilters.assignees,
            statuses: getTasksFilters.statuses,
            groups: getTasksFilters.groups,
            priorities: getTasksFilters.priorities,
            space_ids: space_ids,
          })">
            <!-- Filters -->
            <vb-icon icon="todo-filter-icon" height="50px" width="50px" />
          </div>
          <div 
            class="mr-12px cursor_pointer_no_hover" 
            @click="
              filter.tasks.assignees=[];
              filter.tasks.statuses=[];
              filter.tasks.groups=[];
              filter.tasks.priorities=[];
              filter.tasks.filter_id='';
              getTasks(true);
            "
          >
            <!-- Clear Filters -->
            <vb-icon icon="clearFilters-icon" height="50px" width="50px" />
            <!-- <b-icon icon="slash-circle-fill"  variant="danger" />clearFilters-icon -->
          </div>
          <b-dropdown class="filterDropdownForTaskHub" right>
            <template #button-content>
              <vb-icon icon="callInfoModal-funnel-icon" class="funnel-icon" height="16px" width="16px" />
              Group by: <span>{{ filter.tasks.group_by }}</span>
            </template>
            <b-dropdown-form @submit.prevent="''" class="inputItemContainer">
              <div class="whiteBGinputWithGreyRoundedBorder withLeftIconOnly w-100">
                <div class="inputContainer-inside-whiteBGinputWithGreyRoundedBorder">
                  <vb-icon icon="callActivity-search" class="greySerchIcon" height="18px" width="17.75px" />
                  <b-input placeholder="Search filters..." v-model="filter.group_by.search" v-focus />
                </div>
              </div>
            </b-dropdown-form>
            <b-dropdown-item :class="`${filter.tasks.group_by==group.value?'active':''}`"
              v-for="group in filterGroupBy" 
              :key="group.value" 
              @click="filter.tasks.group_by=group.value;filter.group_by.search='';"
            >
              {{ group.text }}
              <b-icon v-if="filter.tasks.group_by==group.value" class="darker-chevron-down withoutPath" icon="check-lg" />
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div :class="`${isCompact?'TODO-compactView':''}`">
          <!-- <div class="TODO-taksTable-titleRow mt-38px">
            <div class="TODO-taksTable-titleRow-column first">
              <div class="TODO-taksTable-titleRow-column-text">TASK TITLE</div>
            </div>
            <div class="TODO-taksTable-titleRow-column second">
              <div class="TODO-taksTable-titleRow-column-text">CATEGORIES</div>
            </div>
            <div class="TODO-taksTable-titleRow-column third">
              <div class="TODO-taksTable-titleRow-column-text">DUE DATE</div>
            </div>
            <div class="TODO-taksTable-titleRow-column fourth">
              <div class="TODO-taksTable-titleRow-column-text">STATUS</div>
            </div>
          </div> -->
          <div class="TODO-taksTable-titleRow mt-38px">
            <div class="TODO-taksTable-titleRow-column first">
              <div class="TODO-taksTable-titleRow-column-text">TASK TITLE</div>
            </div>
            <div class="TODO-taksTable-titleRow-column second">
              <div class="TODO-taksTable-titleRow-column-text">ASSIGNEES</div>
            </div>
            <div class="TODO-taksTable-titleRow-column third">
              <div class="TODO-taksTable-titleRow-column-text">STATUS</div>
            </div>
            <div class="TODO-taksTable-titleRow-column fourth" ></div>
          </div>
          <div @scroll="getFurthurTasks($event)" class="allTasksList-container">
            <TaskItem v-if="isEmpty(taskGroup)" :addTask="true" :space_id="space_ids[0]" @created="getTasksDebounce()" />
            <div v-for="group in taskGroup" :key="group.filter.key">
              <div class="statusDevider">
                <span>{{ group.text }}</span>
                <div v-if="group.color" class="statusColorBox" :style="`background-color: ${group.color}`"></div>
              </div>
              <TaskItem :addTask="true" :key="`add-task-${group.filter.key}`" :space_id="group.space.id" @created="getTasksDebounce()" />
              <TaskItem 
                v-for="task in group.tasks" 
                :key="task.id" 
                :task="task" 
                @taskClick="$modal.show(`${_uid}-EditTasks`,{ 
                  id: task.id 
                })" 
                @deleted="onDeleteTask($event)"
              />
            </div>
            <div style="height: 50px;" />
          </div>
        </div>
      </template>
    </section>
    <EditTasksModal :modalName="`${_uid}-EditTasks`" @update-list="getTasks(true)" />
    <SelectFilterOfTasksModal 
      @apply-filter="
        filter.tasks.assignees=$event.assignees;
        filter.tasks.statuses=$event.statuses;
        filter.tasks.groups=$event.groups;
        filter.tasks.priorities=$event.priorities;
        filter.tasks.filter_id=$event.filter_id;
        getTasks(true);
      " 
      @added="getFilters(filter.tasks.filter_id)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
import moment from 'moment-timezone'
import { VOIP_API } from '@/utils'
import TaskItem from './TaskItem.vue'
import EditTasksModal from '../Modals/Todo/EditTasksModal.vue'
import SelectFilterOfTasksModal from '../Modals/Todo/SelectFilterOfTasksModal.vue'
import { GET_LOCAL_SETTING_IS_DARK_MODE } from '@/store/helper/getters'
const group_by = {
  tasks: {
    text: 'Tasks',
    value: 'tasks',
  },
  status: {
    text: 'Status',
    value: 'status',
  },
  groups: {
    text: 'Groups',
    value: 'groups',
  },
  spaces: {
    text: 'Spaces',
    value: 'spaces',
  },
  priorities: {
    text: 'Priorities',
    value: 'priorities',
  },
  due_date: {
    text: 'Due dates',
    value: 'due_date',
  },
}
const priorities = {
  high: {
    text: 'High',
    value: 'high',
    color: '#ff403b',
  },
  mid: {
    text: 'Medium',
    value: 'mid',
    color: '#ffba39',
  },
  low: {
    text: 'Low',
    value: 'low',
    color: '#8ad129',
  }
}
export default {
  name: 'NewTasks',
  components: { 
    TaskItem,
    EditTasksModal,
    SelectFilterOfTasksModal,
  },
  inject: [
    'isEmpty',
    'getProperty',
    'appNotify',
  ],
  props: {
    space_ids: {
      type: Array,
      default: ()=>[]
    },
    flag: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      api: {
        tasks: this.$helperFunction.apiInstance({ 
          source: true, 
        }),
        fetch_filters: this.$helperFunction.apiInstance(),
        delete_filter: this.$helperFunction.apiInstance({
          send: [],
        }),
        export_tasks: this.$helperFunction.apiInstance(),
        import_tasks: this.$helperFunction.apiInstance({
          upload_progress: true,
        }),
        toggle_default_filter: this.$helperFunction.apiInstance({
          send: [],
        }),
      },
      response: {
        tasks: [],
        filters: [],
      },
      paginations: {
        tasks: {
          per_page: 10,
          page: 1,
          total: 0,
        },
      },
      filter: {
        tasks: {
          search: '',
          group_by: group_by.tasks.value,
          assignees: [],
          statuses: [],
          groups: [],
          priorities: [],
          filter_id: '',
        },
        group_by: {
          search: '',
        },
      },
      conditions: {
        add_task: false,
      },
      isCompact: true,
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getUserPermissions',
      'getIsMobile',
      GET_LOCAL_SETTING_IS_DARK_MODE
    ]),
    groupBy(){ return group_by },
    filterGroupBy(){
      const groups = Object.values(this.groupBy)
      const search = this.filter.group_by.search.trim().toLowerCase()
      return groups.filter(i=>i.text.trim().toLowerCase().includes(search))
    },    
    taskGroup(){
      let vm = this
      let grouping = {}, result = [], default_text = '', statuses = {}, groups = {}, spaces = {}
      if(this.filter.tasks.group_by==group_by.status.value){
        grouping = _.groupBy(this.response.tasks,'status.id')
        default_text='No Status'
        statuses = _.keyBy((_.uniqBy(this.response.tasks,'status.id')?.map?.(i=>i.status) ?? []),'id') ?? {}
      } else if(this.filter.tasks.group_by==group_by.groups.value){
        grouping = _.groupBy(this.response.tasks,'group.id')
        default_text='No Group'
        groups = _.keyBy((_.uniqBy(this.response.tasks,'group.id')?.map?.(i=>i.group) ?? []),'id') ?? {}
      } else if(this.filter.tasks.group_by==group_by.spaces.value){
        grouping = _.groupBy(this.response.tasks,'spaces.id')
        default_text='No Space'
        spaces = _.keyBy((_.uniqBy(this.response.tasks,'spaces.id')?.map?.(i=>i.spaces) ?? []),'id') ?? {}
      } else if(this.filter.tasks.group_by==group_by.priorities.value){
        grouping = _.groupBy(this.response.tasks,'priority')
        default_text='No Priority'
      } else if(this.filter.tasks.group_by==group_by.due_date.value){
        grouping = _.groupBy(this.response.tasks,(item) => moment(item.due_date, 'YYYY-MM-DD hh:mm').format('YYYY-MM-DD'))
        default_text='No Due Date'
      } else {
        grouping = _.groupBy(this.response.tasks,'')
      }
      result = Object.keys(grouping)?.map(i=>{
        let text = '', color = '', invalid_value=['Invalid date','undefined',undefined]
        if(vm.filter.tasks.group_by==group_by.status.value){
          text=statuses[i]?.title
          color=statuses[i]?.color
        } else if(vm.filter.tasks.group_by==group_by.groups.value){
          text=groups[i]?.name
          color=groups[i]?.color
        } else if(vm.filter.tasks.group_by==group_by.spaces.value){
          text=spaces[i]?.name
          color=spaces[i]?.color
        } else if(vm.filter.tasks.group_by==group_by.priorities.value){
          text=priorities[i]?.text
          color=priorities[i]?.color
        } else {
          text=i
        }
        return {
          filter: {
            priority: i,
            key: invalid_value.includes(i) ? '' : i,
            status_type: grouping[i]?.[0]?.status?.type,
            text: invalid_value.includes(text) ? undefined : text,
          },
          text: invalid_value.includes(text) ? default_text : (text || default_text),
          color: color ?? '',
          status: grouping[i]?.[0]?.status,
          group: grouping[i]?.[0]?.group,
          space: grouping[i]?.[0]?.spaces,
          tasks: grouping[i]
        }
      })
      if(this.filter.tasks.group_by==group_by.priorities.value){
        result.sort((a,v)=>{
          if(a.filter.key=='high') return -1
          else if(a.filter.key=='mid' && v.filter.key!='high') return -1
          else if(a.filter.key=='low' && !['high','mid'].includes(v.filter.key)) return -1
          return 0
        })
      } else if(this.filter.tasks.group_by==group_by.due_date.value) {
        result = _.orderBy(result, ['filter.key'], 'desc')
      } else if(this.filter.tasks.group_by==group_by.status.value) {
        result.sort((a,v)=>{
          if(a.filter.status_type=='active') return -1
          else if(a.filter.status_type=='done' && v.filter.status_type!='active') return -1
          else if(a.filter.status_type=='closed' && !['active','done'].includes(v.filter.status_type)) return -1
          return 0
        })
      } else {
        result = _.orderBy(result, ['filter.text'], 'asc')
      }
      return result
    },
    selectedFilter(){
      return this.response.filters.find(i=>i.id==this.filter.tasks.filter_id)
    },
    getTasksFilters(){
      let filters;
      if(this.selectedFilter){
        filters = JSON.parse(this.selectedFilter.filters)
      }
      return {
        assignees: filters?.assignees ?? this.filter.tasks.assignees ?? [],
        statuses: filters?.statuses ?? this.filter.tasks.statuses ?? [],
        groups: filters?.groups ?? this.filter.tasks.groups ?? [],
        priorities: filters?.priorities ?? this.filter.tasks.priorities ?? [],
      }
    },
  },
  watch: {
    space_ids() {
      this.load()
    },
    flag() {
      this.getTasks(true)
    },
  },
  methods: {
    async onDeleteTask({ task_id }){
      const index = this.response.tasks.findIndex(task=>task.id==task_id)
      if(index>-1) this.response.tasks.splice(index,1)
    },
    getTasksDebounce: _.debounce(function (next){
      this.getTasks(next?false:true)
    }, 2*1000),
    async getTasks(start) {
      const request_againt_error = "Requesting again"
      this.api.tasks.source?.cancel?.(request_againt_error);
      this.api.tasks.source = require("axios").default.CancelToken.source()
      try {
        this.api.tasks.send=true
        if(start) {
          this.paginations.tasks.page=1
        } else if (this.paginations.tasks.total==this.response.tasks.length) return;
        else {
          this.paginations.tasks.page=this.paginations.tasks.page+1
        }
        let response = {}
        if(this.space_ids.length){
          const { data } = await VOIP_API.endpoints.tasklist.list({
            uid: this.getCurrentUser.uid,
            accountcode: this.getCurrentUser.account,
            flag: this.flag,
            space_id: this.space_ids,
            search: this.filter.tasks.search || null,
            assignee: this.getTasksFilters.assignees,
            status: this.getTasksFilters.statuses,
            groups: this.getTasksFilters.groups,
            priority: this.getTasksFilters.priorities,
            page: this.paginations.tasks.page,
          },this.api.tasks.source.token)
          response = data ?? {}
        }
        const tasks = response?.data ?? []
        if(this.paginations.tasks.page==1){
          this.response.tasks = tasks
        } else {
          this.response.tasks = this.response.tasks.concat(tasks)
        }
        this.paginations.tasks.total=response?.total ?? 0
        this.paginations.tasks.per_page=response?.per_page ?? 10
      } catch (ex) {
        if(ex.message!=request_againt_error){
          this.appNotify({
            message: ex.own_message || ex.message,
            type: 'danger',
          })
        }
      } finally {
        this.api.tasks.send=false
      }
    },
    getFurthurTasks(event){
      const height = event.target.scrollHeight;
      const top = event.target.scrollTop;
      const offset_height = event.target.offsetHeight;
      const scroll_bar_height = height - (height - offset_height);
      const scroll_bottom = Math.floor(height - (scroll_bar_height + top));
      if (scroll_bottom <= 25) {
        this.getTasksDebounce(true);
        event.target.scrollTop=top-25
      }
    },
    async getFilters(filter_id){
      this.api.fetch_filters.source?.cancel?.("Requesting again");
      this.api.fetch_filters.source = require("axios").default.CancelToken.source()
      try {
        this.api.fetch_filters.send=true
        const { data } = await VOIP_API.endpoints.tasklist.filters.list({
          accountcode: this.getCurrentUser.account,
          space_ids: JSON.parse(JSON.stringify(this.space_ids)).sort().join(','),
        },this.api.fetch_filters.source.token)
        const filters = data ?? []
        const default_filter = filters.find(i=>i.status==1)
        this.response.filters=filters
        const selected_filter = filters.find(i=>i.id==this.filter.tasks.filter_id)
        if(filter_id){
          this.filter.tasks.filter_id=filter_id
        } else if(!selected_filter && default_filter) {
          this.filter.tasks.filter_id=default_filter.id ?? ''
        }
      } catch (ex) {
        if(!ex.cancel){
          this.appNotify({
            message: ex.own_message,
            type: 'danger',
          })
        }
      } finally {
        this.getTasks(true);
        this.api.fetch_filters.send=false
      }
    },
    async deleteFilter(option){
      const option_id = option?.id ?? ''
      if(!option && this.api.delete_filter.send.includes(option_id)) return;
      try {
        this.api.delete_filter.send.push(option_id)
        await VOIP_API.endpoints.tasklist.filters.remove(option_id)
        this.getFilters()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        if(this.api.delete_filter.send.includes(option_id)){
          this.api.delete_filter.send.splice(this.api.delete_filter.send.indexOf(option_id),1)
        }
      }
    },
    async markDefaultFilter(option){
      const option_id = option?.id ?? ''
      if(!option && this.api.toggle_default_filter.send.includes(option_id)) return;
      try {
        this.api.toggle_default_filter.send.push(option_id)
        await VOIP_API.endpoints.tasklist.filters.makeDefault(option_id,{
          accountcode: this.getCurrentUser.account,
          space_ids: JSON.parse(JSON.stringify(this.space_ids)).sort().join(','),
          status: '1',
        })
        this.getFilters()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        if(this.api.toggle_default_filter.send.includes(option_id)){
          this.api.toggle_default_filter.send.splice(this.api.toggle_default_filter.send.indexOf(option_id),1)
        }
      }
    },
    async exportTasks(){
      if(this.api.export_tasks.send) return;
      try {
        this.api.export_tasks.send=true
        const { data } = await VOIP_API.endpoints.tasklist.export({
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
          flag: "export",
          space_ids: this.space_ids,
        })
        const blobUrl = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.target = '_blank';
        link.download = `tasks - ${moment().format('DD MMM YYYY hh_mm_ss a')}`;
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.export_tasks.send=false
      }
    },
    async importTasks(file){
      if (this.api.import_tasks.send || !file) return;
      try {
        this.api.import_tasks.send = true;
        const form_data = new FormData();
        form_data.append("accountcode", this.getCurrentUser?.account);
        form_data.append("uid", this.getCurrentUser?.uid);
        form_data.append("space_ids", this.space_ids);
        form_data.append("flag", 'import');
        form_data.append("file", file);
        await VOIP_API.endpoints.tasklist.import(form_data,this.api.import_tasks.upload_progress.onProgress.bind(this.api.import_tasks.upload_progress));
        this.appNotify({
          message: "Successfully Uploaded",
          type: "success",
        });
        this.getTasks(true)
      } catch(ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: "error",
        });
      } finally {
        this.$refs['upload_file'].value=''
        this.api.import_tasks.send = false;
      }
    },
    load(){
      this.filter.tasks.assignees=[]
      this.filter.tasks.groups=[]
      this.filter.tasks.priorities=[]
      this.filter.tasks.statuses=[]
      this.filter.tasks.filter_id=''
      this.filter.tasks.search=''
      if(this.space_ids.length==0){
        this.response.filters=[]
        this.response.tasks=[]
        this.paginations.tasks.page=1
        this.paginations.tasks.total=0
        this.paginations.tasks.per_page=10
      } else {
        this.getFilters()
      }
    },
  },
  mounted () {
    this.load();
  },
  activated(){
    this.load();
  },
}
</script>

<style>

</style>