var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-settings-main"
  }, [_c('section', {
    staticClass: "dialer-settings-section dialer-integration-products"
  }, [_c('div', {
    staticClass: "settingsFirstSection"
  }, [_vm.getIsMobile ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-width-button w-100"
  }, [_c('b-icon', {
    staticClass: "settings-back-icon cursor_pointer",
    attrs: {
      "icon": "chevron-left",
      "font-scale": "1.5"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }), _c('h2', {
    staticClass: "dialer-settings-title w-100 mb-0 text-center"
  }, [_vm._v("My integrations")])], 1), _c('div', [_c('button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "ml-auto newDoneButton",
    attrs: {
      "disabled": !_vm.getUserPermissions.integrations,
      "title": _vm.getUserPermissions.integrations ? '' : 'Your package does not support this feature'
    }
  }, [_c('vb-icon', {
    staticClass: "submitIcon",
    attrs: {
      "icon": "squared-submit-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _vm._m(0)], 1)])] : [_c('h2', {
    staticClass: "dialer-settings-title mb-0 settingsFirstSection-left newer"
  }, [_vm._v(" My integrations "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon md-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('KbInfoModal', {
          kb: 'integrations'
        });
      }
    }
  })], 1), _c('div', {
    staticClass: "settingsFirstSection-right"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end align-items-center mt-0"
  }, [_c('button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "ml-auto newDoneButton",
    attrs: {
      "disabled": !_vm.getUserPermissions.integrations,
      "title": _vm.getUserPermissions.integrations ? '' : 'Your package does not support this feature'
    }
  }, [_c('vb-icon', {
    staticClass: "submitIcon",
    attrs: {
      "icon": "squared-submit-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _vm._m(1)], 1)])])]], 2), _vm._m(2), _c('div', {
    staticClass: "TabsDesignWithIcon-container mt-20px"
  }, _vm._l(_vm.tabs, function (tab) {
    return _c('div', {
      key: tab.id,
      staticClass: "TabsDesignWithIcon-item mwd-200px",
      class: {
        'active': _vm.selected.tab == tab.value
      },
      on: {
        "click": function ($event) {
          _vm.selected.tab = tab.value;
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": tab.icon,
        "height": "38px",
        "width": "38px"
      }
    }), _c('span', {
      staticClass: "TabsDesignWithIcon-item-textPart"
    }, [_vm._v(_vm._s(tab.text))])], 1);
  }), 0), _c('div', {
    staticClass: "dialer-box alt mt-20px"
  }, [_c('div', {
    staticClass: "integrationBox-container"
  }, [_vm._l(_vm.integrations, function (data, index) {
    return _c('div', {
      key: index,
      staticClass: "integrationBox",
      class: {
        'comingSoon': data.comming_son
      }
    }, [_c('div', {
      staticClass: "nameImg-container"
    }, [_c('vb-icon', {
      staticClass: "integrationType-icon",
      attrs: {
        "icon": _vm._f("integrationIcons")(_vm._f("get_property")(data, 'plateforms'))
      }
    }), _c('div', {
      staticClass: "integrationType-name"
    }, [_vm._v(_vm._s(_vm._f("integrationName")(_vm._f("get_property")(data, 'plateforms'))))])], 1), _c('div', {
      staticClass: "description"
    }, [_vm._v(_vm._s(_vm._f("integrationDesc")(_vm._f("get_property")(data, 'plateforms'))))]), _vm.response.integrateds[data.plateforms] ? _c('div', [_c('b-checkbox', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip"
      }],
      attrs: {
        "title": _vm.getUserPermissions.integrations ? '' : 'Your package does not support this feature',
        "disabled": !_vm.getUserPermissions.integrations,
        "checked": !!_vm.response.integrateds[data.plateforms].up_sync_enable
      },
      on: {
        "change": function ($event) {
          return _vm.changeSync(data.plateforms);
        }
      }
    }, [_vm._v(" Up sync ")]), _c('b-checkbox', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip"
      }],
      attrs: {
        "title": _vm.getUserPermissions.integrations ? '' : 'Your package does not support this feature',
        "disabled": !_vm.getUserPermissions.integrations,
        "checked": !!_vm.response.integrateds[data.plateforms].down_sync_enable
      },
      on: {
        "change": function ($event) {
          return _vm.changeSync(data.plateforms, 'down');
        }
      }
    }, [_vm._v(" Down sync ")]), _c('b-checkbox', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip"
      }],
      attrs: {
        "title": _vm.getUserPermissions.integrations ? '' : 'Your package does not support this feature',
        "disabled": !_vm.getUserPermissions.integrations,
        "checked": !!_vm.response.integrateds[data.plateforms].cdr_sync_enable
      },
      on: {
        "change": function ($event) {
          return _vm.changeSync(data.plateforms, 'cdr');
        }
      }
    }, [_vm._v(" CDR ")])], 1) : _vm._e(), _c('div', {
      staticClass: "checkInstalled-container"
    }, [_c('div', {
      staticClass: "companyType"
    }, [_vm._v(_vm._s(_vm._f("integrationType")(_vm._f("get_property")(data, 'plateforms'))))]), _c('vb-icon', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip"
      }],
      staticClass: "integrationAdded-icon",
      attrs: {
        "title": _vm.getUserPermissions.integrations ? '' : 'Your package does not support this feature',
        "icon": _vm.response.integrateds[data.plateforms] ? 'integrationInstalled-icon' : 'integrationAdd-icon'
      },
      on: {
        "click": function ($event) {
          !_vm.getUserPermissions.integrations ? '' : _vm.response.integrateds[data.plateforms] ? _vm.deleteintegrate(data.plateforms) : _vm.integrate(data);
        }
      }
    })], 1)]);
  }), _vm.api.list.send ? _vm._l(6, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "integrationBox"
    }, [_vm._m(3, true), _vm._m(4, true), _vm._m(5, true), _vm._m(6, true)]);
  }) : _vm._e()], 2), _vm.integrations.length == 0 && _vm.api.list.send == false ? _c('div', {
    staticClass: "emptyScreenContainer"
  }, [_vm.GET_LOCAL_SETTING_IS_DARK_MODE ? _c('img', {
    attrs: {
      "width": "579px",
      "height": "424px",
      "src": require('@/assets/images/emptyScreenImages/darkMode/integrationsEmptyScreenImage.png')
    }
  }) : _c('img', {
    attrs: {
      "width": "579px",
      "height": "424px",
      "src": require('@/assets/images/emptyScreenImages/integrationsEmptyScreenImage.png')
    }
  }), _c('div', {
    staticClass: "emptyScreenContainer-heading"
  }, [_vm._v("Supercharge your workflow seamless integrations await!")]), _c('div', {
    staticClass: "emptyScreenContainer-text w-75"
  }, [_vm._v("Connect with your favorite tools and elevate your VoIP experience. It's just a click away!")]), _c('button', {
    staticClass: "dialer-button dialer-button-primary mt-20px",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        '';
      }
    }
  }, [_vm._v("Explore now")])]) : _vm._e()])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_c('span', [_vm._v("Submit app")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_c('span', [_vm._v("Submit app")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "settingsSecondSection"
  }, [_c('span', {
    staticClass: "dialer-settings-intro newerTextVersion mt-0 mb-0"
  }, [_vm._v("Manage and configure your integrations with third party applications like your favorite CRMs.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-center w-fit-content"
  }, [_c('div', {
    staticClass: "latestShimmerDesign mr-3",
    staticStyle: {
      "height": "43px",
      "width": "43px"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "26px",
      "width": "66px"
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex w-100 flex-column mt-4",
    staticStyle: {
      "min-height": "95px"
    }
  }, [_c('div', {
    staticClass: "latestShimmerDesign mt-1",
    staticStyle: {
      "height": "16px",
      "width": "100%"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign mt-1",
    staticStyle: {
      "height": "16px",
      "width": "100%"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign mt-1",
    staticStyle: {
      "height": "16px",
      "width": "100%"
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex w-100 flex-column justify-content-start"
  }, [_c('div', {
    staticClass: "d-flex align-items-center py-2"
  }, [_c('div', {
    staticClass: "latestShimmerDesign mr-3",
    staticStyle: {
      "height": "16px",
      "width": "16px",
      "border-radius": "50%"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "22px",
      "width": "70px"
    }
  })]), _c('div', {
    staticClass: "d-flex align-items-center py-2"
  }, [_c('div', {
    staticClass: "latestShimmerDesign mr-3",
    staticStyle: {
      "height": "16px",
      "width": "16px",
      "border-radius": "50%"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "22px",
      "width": "70px"
    }
  })]), _c('div', {
    staticClass: "d-flex align-items-center py-2"
  }, [_c('div', {
    staticClass: "latestShimmerDesign mr-3",
    staticStyle: {
      "height": "16px",
      "width": "16px",
      "border-radius": "50%"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "22px",
      "width": "70px"
    }
  })])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-center mt-3 justify-content-between w-100"
  }, [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "19px",
      "width": "30px"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "38px",
      "width": "38px",
      "border-radius": "50%"
    }
  })]);

}]

export { render, staticRenderFns }