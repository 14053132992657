<template>
  <div>
    <div class="TODO-innerHeading mt-30px">Checklist</div>
    <div class="latestGreyBox-9-9-2023 mt-12px">
      <div class="d-flex align-items-center w-100">
        <p class="Checklist-progressBar-container-text mb-0 mr-3">{{checkListPercentatge}}%</p>
        <div class="Checklist-progressBar-container">
          <div class="Checklist-progressBar-container-filler" :style="`width:${checkListPercentatge}%`"></div>
        </div>
      </div>
      <div class="Checklist-items-container">
        <div :class="`Checklist-item ${checklist.status=='1' ? 'checked' : ''}`" v-for="checklist in response.checklist" :key="checklist.id">
          <div class="Checklist-item-leftPart">
            <div class="Checklist-item-checkBoxType" @click="disabled ? '' : onCheckListMarkChange(checklist)">
              <div v-if="checklist.status=='1'" class="Checklist-item-checkBoxType-filledState" >
                <b-icon icon="check" variant="primary" />
              </div>
              <div v-else class="Checklist-item-checkBoxType-emptyState"></div>
            </div>
            <p class="Checklist-item-leftPart-text mb-0">{{ checklist.description }}</p>
          </div>
          <div class="Checklist-item-rightPart">
            <div class="d-flex align-items-center stackedAvatars-container mr-16px">
              <vb-avatar v-if="checklist.assign_checklist" :is_blf="false" :id="checklist.assign_checklist" />
            </div>
            <vb-icon 
              v-if="!checklist.assign_checklist"
              @click="onSelectUser(checklist)" 
              icon="addAssiggnee-InTodoCheckList-icon" 
              class="addAssiggnee-InTodoCheckList-icon" 
              width="21.395px" 
              height="22.896px" 
            />
            <!-- <b-icon icon="trash" variant="danger" @click="disabled ? '' : removeCheckList(checklist)" />
            <b-icon icon="pencil" variant="secondary" @click="api.update_checklist.send || disabled?'':setUpdateCheckList(checklist)" /> -->
            <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD buttonHeightAuto">
              <b-dropdown varient="link" no-caret class="ico-filter" right> 
                <template #button-content>
                  <b-icon icon="three-dots" variant="dark" class="fillBlack" scale="1.5" />
                </template>
                <b-dropdown-item @click="api.update_checklist.send || disabled?'':setUpdateCheckList(checklist)">
                  <vb-icon icon="crm-pencil-icon" height="14px" width="14px" class="fillBlack"/>
                  <span class="ml-2">Rename</span>
                </b-dropdown-item>
                <b-dropdown-item @click="onSelectUser(checklist)">
                  <vb-icon icon="assignTo-InTodoCheckList-icon" height="14px" width="14px" class="fillBlack"/>
                  <span class="ml-2">Assign to</span>
                </b-dropdown-item>
                <b-dropdown-item @click="disabled ? '' : removeCheckList(checklist)">
                  <vb-icon icon="trash-InTodoCheckList-icon" height="14px" width="14px" class="fillBlack"/>
                  <span class="ml-2">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
        <div class="Checklist-item addItem">
          <b-form v-if="!!conditions.update_checklist" @submit.prevent="updateCheckList()" class="d-flex justify-content-between w-100">
            <div class="whiteBGinputWithGreyRoundedBorder flex-fill">
              <b-input placeholder="Update Item" v-focus v-model="forms.update_checklist.title" :disabled="api.update_checklist.send || disabled" :maxlength="$v.forms.update_checklist.title.$params.maxLength.max" />
              <p class="errorRed" v-if="(forms.update_checklist.submitted && $v.forms.update_checklist.title.$invalid) || api.update_checklist.validation_errors.title">
                <span v-if="!$v.forms.update_checklist.title.required">Title is required</span>
                <span v-if="!$v.forms.update_checklist.title.maxLength">Title can be maximum {{ $v.forms.update_checklist.title.$params.maxLength.max }} character</span>
                <span v-for="(em,i) in api.update_checklist.validation_errors.title" :key="i">{{ em }}</span>
              </p>
            </div>
            <div class="d-flex">
              <b-button class="dialer-button dialer-button-primary ml-16px" :disabled="api.update_checklist.send || disabled" type="submit">
                Update
              </b-button>
              <b-button class="dialer-button dialer-button-delete ml-16px" :disabled="api.update_checklist.send || disabled" variant="link" @click="conditions.update_checklist=''">
                <!-- <b-icon icon="x" variant="danger" /> -->
                Cancel
              </b-button>
            </div>
          </b-form>
          <div v-else-if="!conditions.add_checklist" class="Checklist-item-leftPart" @click="disabled ? '' : conditions.add_checklist=true">
            <div class="Checklist-item-checkBoxType">
              <b-icon icon="plus-lg" class="plus-lg" variant="#7A7A7A" />
            </div>
            <span class="Checklist-item-leftPart-text">
              Add an item
            </span>
          </div>
          <b-form v-else @submit.prevent="addCheckList()" class="d-flex justify-content-between w-100">
            <div class="whiteBGinputWithGreyRoundedBorder flex-fill">
              <!-- <label>Add Item</label> -->
              <b-input placeholder="Add Item" v-focus v-model="forms.add_checklist.title" :disabled="api.add_checklist.send || disabled" :maxlength="$v.forms.add_checklist.title.$params.maxLength.max" />
              <p class="errorRed" v-if="(forms.add_checklist.submitted && $v.forms.add_checklist.title.$invalid) || api.add_checklist.validation_errors.title">
                <span v-if="!$v.forms.add_checklist.title.required">Title is required</span>
                <span v-if="!$v.forms.add_checklist.title.maxLength">Title can be maximum {{ $v.forms.add_checklist.title.$params.maxLength.max }} character</span>
                <span v-for="(em,i) in api.add_checklist.validation_errors.title" :key="i">{{ em }}</span>
              </p>
            </div>
            <div class="d-flex">
              <b-button class="newDoneButton ml-16px" :disabled="api.add_checklist.send || disabled" type="submit">
                <vb-icon icon="squared-submit-icon" class="submitIcon" height="38px" width="38px" />
                <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                  Submit
                </span>
              </b-button>
              <b-button class="dialer-button dialer-button-delete ml-16px" :disabled="api.add_checklist.send || disabled" variant="link" @click="conditions.add_checklist=false">
                <!-- <b-icon icon="x" variant="danger" /> -->
                Cancel
              </b-button>
            </div>
          </b-form>
        </div>
      </div>
    </div>
    

    











    <!--<div class="d-flex flex-column align-items-start mt-45px">
      <div class="TODO-innerHeading">Checklist</div>
      <div class="d-flex align-items-center mt-30px w-100">
        <p class="todo-smallGreyBox-text mb-0 mr-3">{{checkListPercentatge}}%</p>
        <div class="Checklist-progressBar-container">
          <div class="Checklist-progressBar-container-filler" :style="`width:${checkListPercentatge}%`"></div>
        </div>
      </div>
      <div class="d-flex" v-for="checklist in response.checklist" :key="checklist.id">
        <div @click="disabled ? '' : onCheckListMarkChange(checklist)">
          <b-icon v-if="checklist.status=='1'" icon="check-circle-fill" variant="primary" />
          <b-icon v-else icon="circle" variant="primary" />
        </div>
        <span style="color: black;">{{ checklist.description }}</span>
        <b-icon icon="trash" variant="danger" @click="disabled ? '' : removeCheckList(checklist)" />
        <b-icon icon="pencil" variant="secondary" @click="api.update_checklist.send || disabled?'':setUpdateCheckList(checklist)" />
      </div>
    </div>
    <b-form v-if="!!conditions.update_checklist" @submit.prevent="updateCheckList()" class="d-flex">
      <b-input v-focus v-model="forms.update_checklist.title" :disabled="api.update_checklist.send || disabled" :maxlength="$v.forms.update_checklist.title.$params.maxLength.max" />
      <p v-if="(forms.update_checklist.submitted && $v.forms.update_checklist.title.$invalid) || api.update_checklist.validation_errors.title">
        <span v-if="!$v.forms.update_checklist.title.required">Title is required</span>
        <span v-if="!$v.forms.update_checklist.title.maxLength">Title can be maximum {{ $v.forms.update_checklist.title.$params.maxLength.max }} character</span>
        <span v-for="(em,i) in api.update_checklist.validation_errors.title" :key="i">{{ em }}</span>
      </p>
      <b-button :disabled="api.update_checklist.send || disabled" type="submit">
        Update
      </b-button>
      <b-button :disabled="api.update_checklist.send || disabled" variant="link" @click="conditions.update_checklist=''">
        <b-icon icon="x" variant="danger" />
      </b-button>
    </b-form>
    <button v-else-if="!conditions.add_checklist" @click="disabled ? '' : conditions.add_checklist=true" class="dialer-button dialer-button-primary mt-45px">
      Add an item
    </button>
    <b-form v-else @submit.prevent="addCheckList()" class="d-flex latestGreyBox-9-9-2023 mt-12px align-items-end">
      <div class="whiteBGinputWithGreyRoundedBorder ">
        <label>Add Item</label>
        <b-input v-focus v-model="forms.add_checklist.title" :disabled="api.add_checklist.send || disabled" :maxlength="$v.forms.add_checklist.title.$params.maxLength.max" />
        <p v-if="(forms.add_checklist.submitted && $v.forms.add_checklist.title.$invalid) || api.add_checklist.validation_errors.title">
          <span v-if="!$v.forms.add_checklist.title.required">Title is required</span>
          <span v-if="!$v.forms.add_checklist.title.maxLength">Title can be maximum {{ $v.forms.add_checklist.title.$params.maxLength.max }} character</span>
          <span v-for="(em,i) in api.add_checklist.validation_errors.title" :key="i">{{ em }}</span>
        </p>
      </div>
      <b-button class="dialer-button dialer-button-primary ml-16px" :disabled="api.add_checklist.send || disabled" type="submit">
        Submit
      </b-button>
      <b-button class="dialer-button dialer-button-delete ml-16px" :disabled="api.add_checklist.send || disabled" variant="link" @click="conditions.add_checklist=false">
        <!~~ <b-icon icon="x" variant="danger" /> ~~>
        Cancel
      </b-button>
    </b-form>-->
  </div>
</template>

<script>
import _ from 'lodash';
import required from 'vuelidate/lib/validators/required';
import maxLength from 'vuelidate/lib/validators/maxLength';
import { VOIP_API } from '@/utils';
import { mapGetters } from 'vuex';
export default {
  name: 'TaskCheckList',
  props: {
    task_id: {
      type: [String,Number],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      forms: {
        add_checklist: this.$helperFunction.formInstance({
          data: {
            title: '',
          },
        }),
        update_checklist: this.$helperFunction.formInstance({
          data: {
            title: '',
          },
        }),
      },
      api: {
        fetch_available_members: this.$helperFunction.apiInstance(),
        assign_user: this.$helperFunction.apiInstance(),
        checklist: this.$helperFunction.apiInstance(),
        mark_checklist: this.$helperFunction.apiInstance(),
        add_checklist: this.$helperFunction.apiInstance({ 
          validation_errors: true,
        }),
        update_checklist: this.$helperFunction.apiInstance({ 
          validation_errors: true,
        }),
        remove_checklist: this.$helperFunction.apiInstance({ 
          send: '' 
        }),
      },
      conditions: {
        add_checklist: false,
        update_checklist: '',
      },
      response: {
        checklist: [],
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser',
    ]),
    checkListPercentatge(){
      const count = _.countBy(this.response.checklist,'status')
      const complete = count["1"] ?? 0
      const not_complete = count["0"] ?? 0
      return Math.ceil(complete/(complete+not_complete)*100) || 0
    },
    
  },
  validations: {
    forms: {
      add_checklist: {
        title: {
          required: required,
          maxLength: maxLength(255),
        },
      },
      update_checklist: {
        title: {
          required: required,
          maxLength: maxLength(255),
        },
      },
    }
  },
  methods: {
    async addCheckList(){
      this.forms.add_checklist.submitted=true
      this.$v.forms.add_checklist.$touch()
      if(this.$v.forms.add_checklist.$invalid || this.api.add_checklist.send) return;
      try {
        this.api.add_checklist.send=true
        this.api.add_checklist.validation_errors={}
        await VOIP_API.endpoints.tasklist.checklist.create({
          task_id: this.task_id,
          description: this.forms.add_checklist.title,
        })
        this.conditions.add_checklist=false
        this.$emit('checklist-added')
        this.appNotify({
          message: 'Successfully Created',
          type: 'success',
        })
        this.forms.add_checklist.reset()
        this.fetchCheckList();
      } catch (ex) {
        this.api.add_checklist.validation_errors=ex.own_errors
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.add_checklist.send=false
      }
    },
    async updateCheckList(){
      this.forms.update_checklist.submitted=true
      this.$v.forms.update_checklist.$touch()
      if(this.$v.forms.update_checklist.$invalid || this.api.update_checklist.send) return;
      try {
        this.api.update_checklist.send=true
        this.api.update_checklist.validation_errors={}
        await VOIP_API.endpoints.tasklist.checklist.update(this.conditions.update_checklist,{
          task_id: this.task_id,
          description: this.forms.update_checklist.title,
        })
        this.conditions.update_checklist=''
        this.forms.update_checklist.reset()
        this.$emit('checklist-updated')
        this.appNotify({
          message: 'Successfully Updated',
          type: 'success',
        })
        this.fetchCheckList();
      } catch (ex) {
        this.api.update_checklist.validation_errors=ex.own_errors
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.update_checklist.send=false
      }
    },
    setUpdateCheckList(cehck){
      if(this.api.remove_checklist.send) return;
      this.conditions.update_checklist=cehck.id
      this.forms.update_checklist.title=cehck.description ?? ''
    },
    async removeCheckList(check){
      if(this.api.remove_checklist.send) return;
      try {
        const { confirm } = await this.$appConfirmation({
          title: "WARNING",
          message: `Are you sure you want to delete?`,
          variant: 'danger',
          button: {
            no: "No",
            yes: "Yes",
          },
        })
        if(!confirm) return;
        this.api.remove_checklist.send=check.id
        await VOIP_API.endpoints.tasklist.checklist.delete(check.id,{
          task_id: this.task_id,
        })
        this.$emit('checklist-removed')
        this.fetchCheckList();
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.remove_checklist.send=''
      }
    },
    async onCheckListMarkChange(check){
      let value = check.status=="1"
      const check_id = check.id
      try {
        if(this.api.mark_checklist.send) return;
        this.api.mark_checklist.send=true
        await VOIP_API.endpoints.tasklist.checklist.update(check_id,{
          task_id: this.task_id,
          description: check.description,
          status: value ? "0" : "1"
        })
        value=!value
        this.$emit('checklist-mark-change')
        this.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
        this.fetchCheckList();
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.mark_checklist.send=false
      }
    },
    async fetchCheckList(){
      try {
        if(this.api.checklist.send) return;
        this.api.checklist.send=true
        const { data } = await VOIP_API.endpoints.tasklist.checklist.list({
          task_id: this.task_id,
        })
        this.response.checklist=data
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.checklist.send=false
      }
    },
    async onSelectUser(checklist){
      if(this.api.fetch_available_members.send) return;
      try {
        this.api.fetch_available_members.send=true
        const { data } = await VOIP_API.endpoints.tasklist.availableMembers({
          task_id: this.task_id,
          flag: 'assigned',
        })
        const members = data?.members ?? []
        const selected = data?.selected ?? []
        const available = _.difference(_.union(members),_.union(selected))
        this.$emit('select-voip-users-modal-show',{
          accountcode: checklist.assign_checklist,
          accountcodes: available,
          extra_data: {
            checklist_id: checklist.id
          },
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_available_members.send=false
      }
    },
    async assignUser(event){
      if(this.api.assign_user.send) return;
      try {
        this.api.assign_user.send=true
        await VOIP_API.endpoints.tasklist.checklist.assignUser({
          checklist_id: event.extra_data.checklist_id,
          assign_checklist: event.accountcode,
        })
        this.fetchCheckList()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.assign_user.send=false
      }
    },
  },
  mounted () {
    this.fetchCheckList();
  },
}
</script>

<style>

</style>