var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_vm._m(0), _c('div', {
    staticClass: "w-fit-content"
  }, [_vm.api.fetch_notification_settings.send ? _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "34px",
      "width": "60px",
      "border-radius": "25px"
    }
  }) : _c('div', [_c('b-form-checkbox', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    ref: "push_notification",
    staticClass: "newerSwitch",
    attrs: {
      "title": _vm.getUserPermissions.enable_push_notifications ? '' : 'Your package does not support this feature',
      "disabled": !_vm.getUserPermissions.enable_push_notifications || _vm.api.change_push_notification.send || _vm.api.fetch_notification_settings.send,
      "checked": _vm.data.push_notification,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.changePushNotification($event);
      }
    }
  })], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column justify-content-center align-items-start"
  }, [_c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center"
  }, [_vm._m(1), _c('div', {
    staticClass: "w-fit-content"
  }, [_vm.api.fetch_notification_settings.send ? _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "34px",
      "width": "60px",
      "border-radius": "25px"
    }
  }) : _c('b-form-checkbox', {
    ref: "voicemail_notification",
    staticClass: "newerSwitch",
    attrs: {
      "disabled": _vm.api.change_voicemail_notification.send || _vm.api.fetch_notification_settings.send,
      "checked": _vm.data.voicemail_notification,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.changeVoicemailNotification($event);
      }
    }
  })], 1)]), _vm.data.voicemail_notification ? _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px w-100"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_vm._m(2), _c('div', {
    staticClass: "w-fit-content"
  }, [_vm.api.fetch_notification_settings.send ? _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "34px",
      "width": "60px",
      "border-radius": "25px"
    }
  }) : _c('b-form-checkbox', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    ref: "voicemail_attachment",
    staticClass: "newerSwitch",
    attrs: {
      "title": _vm.getUserPermissions.voicemail_attachment ? '' : 'Your package does not support this feature',
      "disabled": _vm.api.change_voicemail_attachment.send || !_vm.getUserPermissions.voicemail_attachment || _vm.api.fetch_notification_settings.send,
      "checked": _vm.data.voicemail_attachment,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.changeVoicemailAttachment($event);
      }
    }
  })], 1)])])]) : _vm._e()]), _c('MissedCallNotificationWidget', {
    attrs: {
      "accountcode": _vm.accountcode
    }
  }), _vm.isCurrentUser ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_vm._m(3), _c('div', {
    staticClass: "w-fit-content"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    attrs: {
      "title": _vm.getUserPermissions.after_call_feedback ? '' : 'Your package does not support this feature'
    }
  }, [_vm.api.fetch_notification_settings.send ? _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "34px",
      "width": "60px",
      "border-radius": "25px"
    }
  }) : _c('b-form-checkbox', {
    ref: "after_call_feedback",
    staticClass: "newerSwitch",
    attrs: {
      "disabled": !_vm.getUserPermissions.after_call_feedback,
      "checked": _vm.data.after_call_feedback,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.changeAfterCallFeedback($event);
      }
    }
  })], 1)])]) : _vm._e()], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Enable push notifications")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Get push alerts for call activity")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Voicemail")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Get voicemail alerts in your email inbox. Stay connected, even away from the phone.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Attach voicemail recording")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Include the voicemail recording as an attachment to the email.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("After call feedback")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Have better insight with 'after call feedback'. Appearing at the end of every call")])]);

}]

export { render, staticRenderFns }