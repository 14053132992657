var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CenterHorizontalLayout', {
    attrs: {
      "bottomline": true,
      "voipaccount": _vm.voipaccount
    },
    on: {
      "change-graph": function ($event) {
        return _vm.$emit('interface', $event);
      }
    }
  }, [_c('div', {
    staticClass: "node nodeBasic",
    attrs: {
      "slot": "main"
    },
    slot: "main"
  }, [_vm.voipaccount ? _c('info', {
    attrs: {
      "id": _vm.voipaccount
    }
  }) : _vm._e(), !_vm.voipaccount ? _c('div', {
    staticClass: "sharedWithHeading",
    staticStyle: {
      "font-size": "16px",
      "font-weight": "600",
      "color": "#202020"
    }
  }, [_vm._v("Assign to")]) : _vm._e(), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center mt-3"
  }, [_vm.ivr ? _c('vb-audio-player', {
    staticClass: "audioForMobile",
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "design": 'minimum',
      "src": _vm._f("welcomgreetingsrc")(_vm.ivr)
    }
  }) : _vm._e(), _c('div', {
    class: `d-flex justify-content-end ${_vm.ivr ? '' : 'w-100'} align-items-center`
  }, [_c('b-icon', {
    staticClass: "cursor_pointer ml-4",
    attrs: {
      "icon": "pencil-fill",
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.edit();
      }
    }
  }), _vm.voipaccount ? _c('b-icon', {
    staticClass: "cursor_pointer ml-2",
    attrs: {
      "icon": "trash-fill",
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.unassign();
      }
    }
  }) : _vm._e()], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }