<template>
  <div class="crm-card halfWidth">
    <div class="crm-card-heading">
      <div>
        Deals by stage
        <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
      </div>
      <div class="d-flex align-items-center">
        <b-dropdown class="crm-dd-container" id="sound-setting-micro-devices" no-caret  >
          <template #button-content>
            <span class="d-flex align-items-center p-0">
              <span>{{ selectedPipeline | get_property('name') }}</span>
              <b-icon-chevron-down scale="1.1" />
            </span>
          </template>
          <b-dropdown-item v-for="pipeline in GET_CRM_PIPELINES" :key="pipeline.id" @click="filters.deals_by_stage.pipeline_id=pipeline.id;fetchDealsByStage();">
            <vb-svg
              v-if="filters.deals_by_stage.pipeline_id==pipeline.id"
              name="dialer-tick" 
              width="10.5" 
              height="7.584" 
              viewBox="0 0 10.5 7.584" 
              stroke-width="0" 
              stroke="#005DFF" 
              fill="none" 
              stroke-linecap="round" 
              stroke-linejoin="round" 
            />
            <span>{{ pipeline.name }}</span>
          </b-dropdown-item>
        </b-dropdown>
        <b-dropdown class="crm-dd-container" id="sound-setting-micro-devices" no-caret  >
          <template #button-content>
            <span class="d-flex align-items-center p-0">
              <span>{{ selectedRange | get_property('label') }}</span>
              <b-icon-chevron-down scale="1.1" />
            </span>
          </template>
          <b-dropdown-item v-for="range in ranges" :key="range.value" @click="filters.deals_by_stage.range=range.value;fetchDealsByStage();">
            <vb-svg
              v-if="filters.deals_by_stage.range==range.value"
              name="dialer-tick" 
              width="10.5" 
              height="7.584" 
              viewBox="0 0 10.5 7.584" 
              stroke-width="0" 
              stroke="#005DFF" 
              fill="none" 
              stroke-linecap="round" 
              stroke-linejoin="round" 
            />
            <span>{{ range.label }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div class="crm-graph-container" style="position: relative;">
      <canvas ref="deals_by_stage_chart"></canvas>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { VOIP_API } from '@/utils'
import { mapGetters } from 'vuex'
import { GET_CRM_PIPELINES } from '@/store/helper/getters'
import Chart from 'chart.js'
export default {
  name: 'DealsByStageGraphWidget',
  data() {
    return {
      api: {
        fetch_deals_by_stage: this.$helperFunction.apiInstance({ 
          source: true, 
        }),
      },
      response: {
        deals_by_stage: {},
      },
      filters: {
        deals_by_stage: {
          range: 7,
          pipeline_id: '',
        },
      },
      charts: {
        deals_by_stage: null,
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      GET_CRM_PIPELINES
    ]),
    ranges(){ return this.response.deals_by_stage?.ranges ?? [] },
    selectedRange(){ return this.ranges.find(i=>this.filters.deals_by_stage.range==i.value) },
    selectedPipeline(){ return this.GET_CRM_PIPELINES.find(i=>this.filters.deals_by_stage.pipeline_id==i.id) },
  },
  methods: {
    async fetchDealsByStage() {
      this.api.fetch_deals_by_stage.source?.cancel?.("Requesting Again")
      this.api.fetch_deals_by_stage.source=axios.CancelToken.source()
      try {
        this.api.fetch_deals_by_stage.send=true
        const { data: deals_by_stage } = await VOIP_API.endpoints.crm.cards.dealsByStage({
          pipeline_id: this.filters.deals_by_stage.pipeline_id,
          range: this.filters.deals_by_stage.range,
        },this.api.fetch_deals_by_stage.source.token)
        this.response.deals_by_stage=deals_by_stage
        this.makeChart()
      } catch (ex) {
        if(!ex.cancel){
          this.appNotify({
            message: ex.own_message,
            type: 'danger',
          })
        }
      } finally {
        this.api.fetch_deals_by_stage.send=false
        this.api.fetch_deals_by_stage.source=null
      }
    },
    makeChart(){
      this.charts.deals_by_stage?.destroy?.();
      const labels = this.response.deals_by_stage.result.map(i=>i.label)
      const data = this.response.deals_by_stage.result.map(i=>i.value)
      this.charts.deals_by_stage = new Chart(this.$refs.deals_by_stage_chart, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Deals By Stage',
              data: data
            }
          ],
        },
        options: {
          responsive: true,
          scales: {
            yAxes: [
              {
                gridLines: {
                  offsetGridLines: true
                },
                ticks: {
                  beginAtZero: true,
                  stepSize: 2,
                },
              }
            ],
          },
        },
      });
      this.charts.deals_by_stage.update();
    },
  },
  created(){
    this.filters.deals_by_stage.pipeline_id=this.GET_CRM_PIPELINES?.[0]?.id ?? ''
  },
  mounted(){
    this.fetchDealsByStage()
  },
}
</script>

<style>

</style>