<template>
  <div 
    :id="participant._id" 
    class="participant-container"
    :class="{
      'speaker': speaker
    }"
  >
    <div v-if="isVideoNotShown || !performanceSetting.show_video" class="video-not-shown-8569">
      <div class="position-relative" :style="`${isHold ? 'overflow:visible' : ''}`">
        <img :src="image" @error="$event.target.src=user_male" :alt="name">
        <div v-if="isHold" class="d-flex align-items-center justify-content-center isHold-container" style="">
          <div class="isHold-innerOverlay" style=""></div>
          <p style="" class="p-2 mb-0 isHold-text">On Hold</p>
        </div>
      </div>
      <span class="callParticipantsName">{{name}}</span>
      <!-- <div v-if="isHold" class="d-flex align-items-center justify-content-center" style="position:absolute;left:auto;height:170px;width:170px;top:0px;">
        <div style="background-color:black;height:170px;width:170px;position:absolute;left:auto;opacity:0.5;"></div>
        <p style="font-size:28px;font-weight:bold;background-color:#00000087;color:white;border-radius:12px;" class="p-2 mb-0">On Hold</p>
      </div> -->
    </div>
    <div class="hand-rise v2" v-if="!!emoji">
      
      <div style="font-size:25px;">🖐</div>
      
    </div>
    <RemoteTracks
      v-show="!isVideoNotShown"
      :tracks="participant._tracks"
      :participant="participant"
      :JitsiConference="JitsiConference"
      :speaker_device_id="speaker_device_id"
      :videoCall="videoCall"
    />
  </div>
</template>

<script>
// import { $fn } from "../../../utils";
import RemoteTracks from "./RemoteTracks/index";

export default {
  name: "Participant",
  components: {
    RemoteTracks,
  },
  props: {
    participant: {
      type: Object,
    },
    JitsiConference: {
      type: Object,
    },
    speaker_device_id: {
      type: String,
      default: ''
    },
    speaker: {
      type: Boolean,
      default: false
    },
    performanceSetting: {
      type: Object,
      default: ()=>({})
    },
    videoCall:{
      type: Object
    },
  },
  data() {
    return {
      name: "",
      image: "",
      user_male: require("@/assets/images/user-male.png"),
    };
  },
  computed: {
    isVideoNotShown(){
      const video = this.participant._tracks.find(track=>track.type==='video')
      return !video || this.participant?._properties?.isVideoMuted=='true' || this.participant?._properties?.isHold=='true'
    },
    isHold(){
      return this.participant._properties.isHold=='true'
    },
    emoji(){ return this.participant?._properties?.emoji }
  },
  watch: {
    "participant._displayName": {
      immediate: true,
      handler: "watchingDisplayName",
    },
  },
  methods: {
    watchingDisplayName(displayName) {
      if (displayName) {
        this.name = this.$store.getters.getVoipUsersAlisesDisplayName[this.participant._displayName];
        this.image = this.$store.getters.getVoipUsersAlisesImages[this.participant._displayName];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// bill
.hand-rise {
  background-color: white;
  position: absolute;
  top: 0;
  z-index: 999999999;
}


.participant-container {
  height: 100%;
  width: 100%;
  flex: 1;
  overflow: hidden;
  position: relative;
  // background-color:#6B6969;
  background-color:#2f2d30;
  .image-container {
    position: absolute;
    bottom: 5px;
    left: 5px;
    z-index: 1;
    height: 50px;
    display: flex;
    overflow: hidden;
    justify-content: space-evenly;
    align-items: center;
    img {
      height: 100%;
      width: 50px;
    }
    p {
      margin: 0;
      margin-left: 5px;
    }
  }
}
.video-not-shown-8569 {
  height: inherit;
  width: inherit;
  overflow: hidden;
  // background: grey;
  background: #2f2d30;
  position: absolute;
  // z-index: 1;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
    }
  }
}
</style>
