<template>
  <div style="position: relative;width:800px;">
    <div style="display: flex;">
      <div style="display:flex;flex:1;opacity:0;">
        <div class="node nodeBasic arrowOnLeftSide">
          <MoreInfoNode :graph="graph" :moreText="moreText" :voipaccount="voipaccount" @interface="$emit('change-graph',$event)" />
        </div>
        <div style="border-top: 2px solid #c5ccd0;width:50px;" />
      </div>
      <div class="nodeBasic-container" style="display:flex;flex:1;justify-content:center;align-items:flex-start;">
        <slot name="main" />
      </div>
      <div style="display:flex;flex:1;" :style="`opacity: ${showMore?'':'0'}`">
        <div style="border-top: 2px solid #c5ccd0;width:50px;" class="mt-4"/>
        <div style="flex:1" class="node nodeBasic minWidth-200">
          <MoreInfoNode :graph="graph" :moreText="moreText" class="mb-2" :voipaccount="voipaccount" @interface="$emit('change-graph',$event)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MoreInfoNode from '../common/MoreInfoNode.vue';
import _ from 'lodash';
export default {
  name: 'CenterHorizontalNode',
  components: {
    MoreInfoNode,
  },
  props:{
    bottomline:{
      type: [String, Boolean]
    },
    nobottom:{
      type: [Boolean],
      default: false
    },
    voipaccount: {
      type: [String,Number],
    },
    graph: {
      type: Boolean,
      default: true,
    },
    moreText: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      'getVoipUsersAlises',
      'getVoipTeams',
      'getVoipCallQueues',
      'getVoipIVRSAlises',
      'getVoipNumbers',
    ]),
    info() {
      const key = this.voipaccount ?? ''
      const user = this.getVoipUsersAlises[key];
      const team = this.getVoipTeams.find(team=>key==team?.ringroup?.accountcode || key==team?.real_id || key==team?.ringroup?.extn)
      const call_queue = this.getVoipCallQueues.find(call_queue=>key==call_queue?.voipaccount || key==call_queue?.extn)
      const ivr = this.getVoipIVRSAlises[key]
      const number = this.getVoipNumbers.find(i=>key==i.real_id)
      if(team) return { data: team, type: 'team' }
      else if(call_queue) return { data: call_queue, type: 'call_queue' }
      else if(ivr) return { data: ivr, type: 'ivr' }
      else if(user) return { data: user, type: 'user' }
      else if(number) return { data: number, type: 'number' }
      else return null;
    },
    members(){
      if(this.info?.type=='team') return _.take(this.info.data?.members?.map?.(i=>i.accountcode),4) ?? []
      else if(this.info?.type=='call_queue') return _.take(this.info.data?.members?.map?.(i=>i.membername),4) ?? []
      else if(this.info?.type=='ivr') return _.take(this.info.data?.schedules?.[0]?.options?.map?.(i=>i.ivr_cmd_args),4) ?? []
      else if(this.info?.type=='number') return _.take(this.info.data?.extensions,4) ?? []
      else return []
    },
    showMore(){ return this.members.length>0 },
  },
}
</script>

<style>

</style>