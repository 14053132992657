var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ExtensionsIcon position-relative"
  }, _vm._l(_vm.extensions, function (extension, index) {
    return _c('div', {
      key: index,
      class: `ExtentionItself ${extension.device_status.status == 'online' ? 'statusOnline' : 'statusOffline'}`
    }, [_c('vb-icon', {
      attrs: {
        "id": _vm._f("get_property")(extension, 'accountcode'),
        "icon": _vm._f("extensionIcon")(_vm._f("get_property")(extension, 'type')),
        "width": "25px",
        "height": "25px"
      }
    }), _c('b-popover', {
      attrs: {
        "target": _vm._f("get_property")(extension, 'accountcode'),
        "triggers": "hover",
        "placement": "top"
      }
    }, [_c('small', [_vm._v(_vm._s(_vm._f("get_property")(extension, 'accountname')) + " " + _vm._s(extension.device_status.status))])])], 1);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }