<template>
  <div class="ExtensionsIcon position-relative">
    <div :class="`ExtentionItself ${extension.device_status.status == 'online' ? 'statusOnline' : 'statusOffline'}`" v-for="(extension, index) in extensions" :key="index" >
      <!-- <vb-icon :id="extension | get_property('type')" :icon="extension | get_property('type') | extensionIcon" width="25px" height="25px"/>
      <b-popover :target="extension | get_property('type')" triggers="hover" placement="top">
        <small>{{extension | get_property('type') | extensionText}} {{extension.device_status.status}}</small>
      </b-popover> -->
      <vb-icon :id="extension | get_property('accountcode')" :icon="extension | get_property('type') | extensionIcon" width="25px" height="25px"/>
      <b-popover :target="extension | get_property('accountcode')" triggers="hover" placement="top">
        <small>{{extension | get_property('accountname')}} {{extension.device_status.status}}</small>
      </b-popover>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ExtensionsIcon',
  props: {
    extensions: {
      type: Array,
      default: ()=>[]
    },
    voipaccount: {
      type: String,
    },
  },
  computed: {
    ...mapGetters([
      'getVoipUsersAlises',
    ]),
    user(){ return this.getVoipUsersAlises[this.voipaccount] ?? {} },
  },
  filters:{
    extensionIcon(value){
      if(value=='WEBEXT') return 'devices-webExtension-icon'
      else if(value=='DESKTOPEXT') return 'devices-desktopExtension-icon'
      else if(value=='MOBILEEXT') return 'devices-mobileExtension-icon'
      else if(value=='HEADEXT') return 'devices-headExtension-icon'
      else return 'devices-webExtension-icon'
    },
    extensionText(value){
      if(value=='WEBEXT') return 'Web Extension'
      else if(value=='DESKTOPEXT') return 'Desktop Extension'
      else if(value=='MOBILEEXT') return 'Mobile Extension'
      else if(value=='HEADEXT') return 'Handset Extension'
      else return value
    },
    extensionStatus(value){
      if(value=='online') return 'online'
      else return 'offline'
    },
  },
}
</script>

<style>

</style>