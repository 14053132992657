<template>
  <div class="custom-100vh">
    <LoaderMain v-if="loading" />
    <template v-else>
      <KbInfoModal />
      <NotificationPermissionsModal />
      <!-- <EditTasksModal :modalName="'GlobalEditTask'" /> -->
      <div 
        id="dialer-parent" 
        class="d-flex flex-column dialer-parent  dialer-light-mode" 
        :class="{ 
          'dialer-dark-mode': !$store.getters.getIsMobile && isInCall,
          'nowOutOfCall': !$store.getters.getIsMobile && !isInCall, 
          'blur': $store.state.global_conditions.blur_bg, 
          'darkModeV2 v2': GET_LOCAL_SETTING_IS_DARK_MODE && !$store.getters.getIsMobile 
        }"
      >
        <CommonCalls :class="['dialer-call-notification',isInCall ? 'incall-true' : '' ]" id="videocomon" />
        <CallFeedbackModal />
        <template>
          <CommonChat v-if="!$store.getters.isIntegration" ref="common_chat" />
          <CommonPamarly v-if="!$store.getters.isIntegration" ref="common_pamarly" />
          <CommonCRM v-if="!$store.getters.isIntegration" ref="common_crm" />
          <KeyboardShortcuts v-if="!$store.getters.isIntegration" />
          <div id="dialer-parent-inner" :class="['dialer-parent-inner', isInCall ? 'z-index-0-5' : '']">
            <div class="d-flex h-100">
              <Sidebar 
                id="leftSidebar" 
                v-if="($store.getters.getIsMobile && !['video-call'].includes(activeTab)) || !$store.getters.getIsMobile" 
                ref="leftSideBar" 
                @selectedTab="$store.state.global_conditions.tab = $event" 
                :activeTab="activeTab" 
              />
              <div 
                class="dialer-main-center-area d-flex flex-column h-100" 
                :class="{
                  'applyMargin dialer-screenSize-shrink': applyMargin === true,
                  'ItIsInVideoCall': activeTab=='video-call',
                  'settingsIsOpened': activeTab=='mobile_settings',
                }"
              >
                <CommonComponent />
                <keep-alive>
                  <HomeCopy 
                    v-if="activeTab=='homeCopy'" 
                    ref="home-copy" 
                    :dialer="true" 
                    :logs="!$store.getters.getIsMobile" 
                    :class="{
                      'new-dashboard-height' : !$store.getters.getCurrentUser.is_number_purchased
                    }"
                  />
                </keep-alive>
                <keep-alive>
                  <Chat 
                    v-if="activeTab=='chat'" 
                    ref="chat" 
                    :class="{
                      'new-dashboard-height' : !$store.getters.getCurrentUser.is_number_purchased
                    }"
                  />
                </keep-alive>
                <Scheduling 
                  v-if="activeTab=='schedule'" 
                  ref="schedule" 
                  :class="{
                    'new-dashboard-height' : !$store.getters.getCurrentUser.is_number_purchased
                  }" 
                />
                <VideoCall :style="`display:${activeTab=='video-call'?'':'none !important'}`" ref="video-call" />
                <SelectContext v-if="activeTab=='selectContext'" ref="selectContext" />
                <HomeCopy 
                  v-if="activeTab=='callLogs'" 
                  ref="callLogs" 
                  :dialer="false" 
                  :logs="true"
                  :class="{
                    'new-dashboard-height' : !$store.getters.getCurrentUser.is_number_purchased
                  }" 
                />
                <UserProfilePage v-if="activeTab=='user_profile'" ref="user_profile" />
                <Analytics 
                  v-if="activeTab=='analytics'" 
                  ref="analytics" 
                  :class="{
                    'new-dashboard-height' : !$store.getters.getCurrentUser.is_number_purchased
                  }"
                />
                <keep-alive>
                  <CallDashboard v-if="activeTab=='callDashboard'" ref="call-dashboard" />
                </keep-alive>
                <keep-alive>
                  <Settings 
                    v-if="activeTab=='settings'" 
                    ref="settings" 
                    :class="{
                      'new-dashboard-height' : !$store.getters.getCurrentUser.is_number_purchased
                    }"
                  />
                </keep-alive>
                <keep-alive>
                  <MobileSettings 
                    v-if="activeTab=='mobile_settings'" 
                    ref="mobile_settings" 
                    :class="{
                      'new-dashboard-height' : !$store.getters.getCurrentUser.is_number_purchased
                    }"
                  />
                </keep-alive>
                <QuickStart v-if="activeTab=='QuickStartPage'"/>
                <HRM v-if="isHRMTab" />
                <Pamarly v-if="isPamarlyTab" />
                <CRM v-if="isCrmTab" />
                <keep-alive>
                  <Todo v-if="activeTab=='todo'" ref="todo" /> 
                </keep-alive>
              </div>
              <RightSideBar 
                ref="rightSideBar" 
                v-if="($store.getters.getIsMobile && !['mobile_settings','video-call'].includes(activeTab)) || !$store.getters.getIsMobile" 
                @selectedTab="$store.state.global_conditions.tab = $event" 
                :activeTab="activeTab" 
              />
            </div>
          </div>
        </template>
      </div>
      
      <EditTasksModal :modalName="'GlobalEditTask'" />
    </template>
    <AppTour />
    <CheckedServiceAvailableModal />
  </div>
</template>

<script>
import NotificationPermissionsModal from '../components/Modals/NotificationPermissionsModal.vue'
import EditTasksModal from '../components/Modals/Todo/EditTasksModal.vue'
import CallFeedbackModal from '../components/Modals/CallFeedbackModal.vue';
import CommonChat from '../components/CommonChat.vue';
import KeyboardShortcuts from "../components/KeyboardShortcuts.vue";
import CommonCalls from "../components/Calls/index.vue";
import CommonPamarly from "../components/CommonPamarly.vue";
import CommonCRM from "../components/CommonCRM.vue";
import CommonComponent from "../components/CommonComponent.vue";
import Sidebar from "../components/Sidebar.vue";
import RightSideBar from "../components/rightsidebar/RightSideBar.vue";
import LoaderMain from "../components/LoaderMain.vue";
import Analytics from "../views/Analytics.vue";
import CallDashboard from "../views/CallDashboard.vue";
import SelectContext from "../views/SelectContext.vue";
import Chat from "../views/Chat.vue";
import HomeCopy from "../views/HomeCopy4.vue";
import Scheduling from "../views/Schedule.vue";
import Settings from "../views/Settings.vue";
import MobileSettings from "../views/MobileSettings.vue";
import UserProfilePage from "../views/UserProfilePage.vue";
import KbInfoModal from '../components/Modals/KbInfoModal.vue'
import VideoCall from "../views/VideoCall.vue";
import HRM from '@/views/hrm/index.vue'
import Pamarly from '@/views/pamarly/index.vue'
import CRM from '@/views/crm/index.vue'
import QuickStart from '@/views/QuickStart.vue'
import CheckedServiceAvailableModal from '../components/Modals/CheckedServiceAvailable.vue';
import AppTour from '../components/AppTour.vue'
import Todo from "../views/Todo.vue";
import _ from 'lodash';
import { 
  LOGGER,  
  VOIP_API, 
  events as AppEvents, 
  $fn,
  events,
} from "../utils";
// import { 
//   centrifugo,
// } from "../Centrifuge";
import { TokenService } from "../services/storage.service";
// import { Subscriber, URI } from 'sip.js';
import { phone, user } from "../sip/app";
import { electron_events } from '../electron/events';
import { MOTHERSHIP } from '../mothership';
import { files } from "../utils";
import { mapState, mapGetters, mapMutations } from 'vuex';
import { INTEGRATIONS } from '@/integrations';
import moment from "moment-timezone";
import { GET_LOCAL_SETTING_IS_DARK_MODE } from '@/store/helper/getters';
import { AMI_EVENTS, AMI_SOCKET } from '@/ami';
import { SET_LOCAL_SETTING_CALLS_FEEDBACK } from '@/store/helper/mutations';
// import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
// import CallLogs from "../components/Home/CallActivity/List.vue";
// import Home from "@/views/Home";
// import HomeCopy2 from "@/views/HomeCopy2";
// import Recording from "@/views/Recording";
// import SingleBox from "@/views/SingleBox";
// import Voicemail from "@/views/Voicemail";
// import TimeLine from "@/views/TimeLine";
// import Timer from "timer.js";
// import { APP_TOUR } from '@/apptour/index'
// import { TIDIO } from '../tidio';
let focusTimeout, snooze_time, snooze_timer, timer = 3*60*60*1000 //? 3 hours

export default {
  name: "Dashboard",
  components: {
    // CallLogs,
    CheckedServiceAvailableModal,
    UserProfilePage,
    SelectContext,
    // HomeCopy2,
    CommonChat,
    KeyboardShortcuts,
    Sidebar,
    CommonCalls,
    AppTour,
    CommonComponent,
    CommonPamarly,
    CommonCRM,
    Analytics,
    CallDashboard,
    Chat,
    // Home,
    HomeCopy,
    // Recording,
    Scheduling,
    Settings,
    MobileSettings,
    // SingleBox,
    VideoCall,
    // Voicemail,
    RightSideBar,
    LoaderMain,
    // TimeLine,
    NotificationPermissionsModal,
    KbInfoModal,
    QuickStart,
    HRM,
    Pamarly,
    CRM,
    Todo,
    CallFeedbackModal,
    EditTasksModal,
  },
  data() {
    return {
      applyBackground: false,
      applyMargin: false,
      loading: true,
      notification_permission: false,
      subscribers: {},
    };
  },
  provide(){
    return{
      local_filess: files,
      logout: this.logout,
      getVoipUsers: this.getVoipUsers,
      getVoipTeams: this.getVoipTeamss,
      getCallQueue: this.getCallQueue,
      getIvrs: this.getIvr,
      getAddressBookLocal: this.getAddressBookLocal,
      getAddressBookGlobal: this.getAddressBookGlobal,
      getSpeedDialLocal: this.getSpeedDialLocal,
      getSpeedDialGlobal: this.getSpeedDialGlobal,
      getNumbers: this.getNumbers,
      getCallTags: this.getCallTags,
      getChangePlanLog: this.getChangePlanLog,
      snooze: this.snooze,
      getFeatures: this.getFeatures,
      appFetchSubscriptionDetail: this.fetchSubscriptionDetail,
      appFetchUserAccountCode: this.getUserAccountCode,
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapState({
      electron: (state)=>state.electron
    }),
    ...mapGetters([
      'getVoipUsersAlises',
      'getVoipStatuses',
      'getUserPermissions',
      'fpData',
      'activeSession',
      'isActiveSessionIncoming',
      'getVoipUserAccountcode',
      GET_LOCAL_SETTING_IS_DARK_MODE
    ]),
    isInCall(){
      const sip_sessions = !_.isEmpty(this.$store.state.sip.phone) ? this.$store.state.sip.phone.getSessions() || [] : []
      const jitsi_outgoings = !_.isEmpty(this.$store.state.calls.outgoing_calls) ? this.$store.state.calls.outgoing_calls : []
      const jitsi_incomings = !_.isEmpty(this.$store.state.calls.incoming_calls) ? this.$store.state.calls.incoming_calls : []
      const jitsi_videocalls = !_.isEmpty(this.$store.state.calls.videoCalls) ? this.$store.state.calls.videoCalls : []
      const new_sip_sessions = sip_sessions/*.filter(session=>session.number!='*154*');*/
      return !_.isEmpty(new_sip_sessions) || !_.isEmpty(jitsi_outgoings) || !_.isEmpty(jitsi_incomings) || !_.isEmpty(jitsi_videocalls)
    },
    isHRMTab(){ 
      return [
        'HrmHomePage',
        'HrmDocuments',
        'hrm-home',
        'hrm-calender',
        'hrm-setting',
        'hrm-reports',
        'hrm-settingNew'
      ].includes(this.activeTab) 
    },
    isPamarlyTab(){ 
      return [
        'pamarly-home',
        'pamarly-teams',
        'pamarly-resources',
        'pamarly-permissions',
        'pamarly-folder',
        'pamarly-deleted-resources',
        'pamarly-history'
      ].includes(this.activeTab) 
    },
    isCrmTab(){ 
      return [
        'crm-home',
        'crm-setting',
        'crm-insights',
        'crm-deals',
        'crm-activities',
        'crm-contacts',
        'crm-companies',
        'crm-products',
        'crm-inbox',
        'crm-documents'
      ].includes(this.activeTab) 
    },
    activeTab(){ return this.$store.state.global_conditions.tab },
    tours(){ return this.$tours },
    // userhavepermissions(){return this.current_user ? this.current_user.features || [] : []},
    userhavepermissions(){return this.$store.getters.getCurrentUser ? this.$store.getters.getCurrentUser?.features || [] : []},
  },
  watch: {
    "$store.state.common.user":{
      deep: true,
      handler(value){
        const user = JSON.parse(JSON.stringify(value))
        // console.log(':::::::::::::user:::::::::::::',user)
        const l_user = TokenService.USER.get()
        let new_user = {
          ...l_user,
          ...user,
        }
        TokenService.USER.set(new_user)
        if(process.env.IS_ELECTRON) window.electron.send(electron_events.USER_UPDATEED,new_user)
        if(this.$store?.state?.common?.state?.voipusers?.[user.account]?.status) this.$store.state.common.state.voipusers[user.account].status=user.status_id
        if(JSON.stringify(new_user)!==JSON.stringify(user)) this.$store.state.common.user=new_user
      }
    },
    "getUserPermissions": {
      immediate: true,
      handler(){
        // LOGGER.log('::::::::::::features::::::::::::',JSON.stringify(this.$store.state.common.user, null, 2),this.$store.state.common.user?.features)
        if(!this.$store.state.common.user?.features){
          const user = JSON.parse(JSON.stringify(this.$store.state.common.user))
          const l_user = TokenService.USER.get()
          let new_user = {
            ...l_user,
            ...user,
          }
          TokenService.USER.set(new_user)
          if(process.env.IS_ELECTRON) window.electron.send(electron_events.USER_UPDATEED,new_user)
          if(JSON.stringify(new_user)!==JSON.stringify(user)) this.$store.state.common.user=new_user
        }
      },
    },
    "$store.state.common.voipusers"(users) {
      this.getUserAccountCode()
      if (typeof users === "object") {
        this.getStatuses()
        // let alises = {};
        // for (var account in users) {
          // Object.defineProperty(alises, users[account].user.jid_account, {
          //   value: account,
          //   writable: true,
          //   configurable: true,
          //   enumerable: true,
          // });
          // Object.defineProperty(alises, users[account].user.user_chat_id, {
          //   value: account,
          //   writable: true,
          //   configurable: true,
          //   enumerable: true,
          // });
          // Object.defineProperty(alises, users[account].extn, {
          //   value: account,
          //   writable: true,
          //   configurable: true,
          //   enumerable: true,
          // });
          // Object.defineProperty(alises, users[account].user.profile_id, {
          //   value: account,
          //   writable: true,
          //   configurable: true,
          //   enumerable: true,
          // });
          // this.subscribeUser(account);
        // }
      }
    },
    "$store.state.common.statuses"(statuses){
      if(!_.isEmpty(statuses)){
        if(process.env.IS_ELECTRON){
          window.electron.send(electron_events.SET_STATUSES,{
            statuses,
          })
        }
      }
    },
    "$store.getters.getIsMobile"(is_mobile){
      // if(this.isInCall){
      //   this.$store.state.global_conditions.tab='video-call'
      // } else {
        // this.$store.state.global_conditions.tab='homeCopy'
      // }
      if(is_mobile) {
        if(this.$store.state.global_conditions.tab!='video-call'){
          this.$store.state.global_conditions.tab='homeCopy'
        }
      } else {
        if(this.$store.state.global_conditions.tab!='video-call'){
          this.$store.state.global_conditions.tab='homeCopy'
        } else if(this.isActiveSessionIncoming) {
          this.$store.state.global_conditions.tab='homeCopy'
        }
      }
    },
    "isInCall"(isInCall)  {
      if(isInCall && this.$store.getters.getIsMobile){
        this.$store.state.global_conditions.tab='video-call'
      } else if(!isInCall && this.$store.getters.getIsMobile){
        this.$store.state.global_conditions.tab='homeCopy'
      }
    },
  },
  methods: {
    ...mapMutations([
      SET_LOCAL_SETTING_CALLS_FEEDBACK
    ]),
    logout(...args){ return $fn.logout(...args) },
    async getUserAccountCode(){
      const current_user = TokenService.USER.get()
      const { data } = await VOIP_API.endpoints.users.getUsersAccountcode({
        uid: current_user.uid
      })
      this.$store.state.common.user_accountcode=data ?? {}
    },
    getStatuses(){
      let vm = this;
      let user_status_id = '';
      VOIP_API.endpoints.user_statuses.usersStatus()
      .then(({ data: statuses })=>{
        for (let index = 0; index < statuses.length; index++) {
          const { accountcode, status_id } = statuses[index];
          if(!vm.$store.state.common.voipusers[accountcode]) continue;
          vm.$set(vm.$store.state.common.voipusers[accountcode],'status',status_id)
        }
        return statuses.find(status=>status.accountcode==vm.$store.getters.getCurrentUser.account)
      })
      .then((status)=>{
        user_status_id=status?.status_id
      })
      .finally(()=>{
        if(user_status_id!=vm.$store.getters.getCurrentUser.status_id){
          vm.$store.dispatch('setStatus',user_status_id)
          vm.getSnoozeSetting({ set_value: true })
        }
      })
    },
    registerUser() {
      let vm = this
      const current_user = TokenService.USER.get()
      user.User = current_user.sip.user;
      user.Pass = current_user.sip.pass;
      const device_id = this.$store.getters.fpData.device_id
      vm.$store.state.sip.phone = phone.init(user.User, user.Pass,device_id);
      vm.$store.state.sip.phone.onuserstatechange = function({ accountcode, state }){
        const voipaccount = vm.getVoipUserAccountcode[accountcode]
        if (vm.getVoipUsersAlises[voipaccount]) {
          vm.$store.state.common.voipusers[voipaccount].state = state;
        }
      }
      vm.$store.state.sip.phone.onuserblfchange = function({ account, blf_state, }) {
        const voipaccount = vm.getVoipUserAccountcode[account]
        if(vm.getVoipUsersAlises[voipaccount]){
          vm.$set(vm.$store.state.common.voipusers[voipaccount],'blf_state',blf_state)
        }
      }
      vm.$store.state.sip.phone.userAgent?.start?.()
      .then(() => vm.$store.state.sip.phone.registerer.register())
      .finally(()=>{
        vm.setStore()
      }) ?? vm.setStore();
      // if(process.env.NODE_ENV!='production'){
        window.phone = this.$store.state.sip.phone;
      // }
    },
    // subscribeUser(account){
    //   let vm = this;
    //   try {
    //     if (vm.$store.state.sip.phone.userAgent) {
    //       const targetURI = new URI("sip", account, user.Realm);
    //       const eventType = "presence";
    //       const subscriber = new Subscriber(vm.$store.state.sip.phone.userAgent, targetURI, eventType, {
    //         extraHeaders: ["Accept: application/pidf+xml, application/xpidf+xml"],
    //       });
    //       subscriber.delegate = {
    //         onNotify(notification) {
    //           try {
    //             const accountcode = notification?.incomingNotifyRequest?.message?.from?.uri?.normal?.user;
    //             var fromXML = require("from-xml")?.fromXML;
    //             var data = fromXML(notification?.request?.body ?? '');
    //             LOGGER.log(`account ${account}`,accountcode,data.presence.note)
    //             if (!_.isEmpty(vm.$store.state.common.voipusers)) {
    //               if (vm.$store.state.common.voipusers[accountcode]) {
    //                 vm.$store.state.common.voipusers[accountcode].state = data.presence.note;
    //               }
    //             }
    //           } catch (error) {
    //             LOGGER.danger(`account ${account}`,"error in onNotify function", error);
    //           }
    //         },
    //       };
    //       subscriber.stateChange.addListener(function (newState) {
    //         LOGGER.log(`account ${account}`,newState)
    //         try {
    //           if(vm.$store.state.common.voipusers[account]){
    //             vm.$set(vm.$store.state.common.voipusers[account],'blf_state',newState)
    //           }
    //         } catch(error) {
    //           LOGGER.danger(`account ${account}`,"error in stateChange listener", {error});
    //         }
    //       });
    //       subscriber.subscribe();
    //       vm.$set(vm.subscribers,account,subscriber)
    //       return subscriber;
    //     } else {
    //       LOGGER.info(`account ${account}`,"phone", vm.$store.state.sip.phone);
    //     }
    //   } catch (error) {
    //     LOGGER.danger("error in subscribe function", error);
    //   }
    // },
    // async subscribe(account) {
    //   LOGGER.log('subscribe function run',account)
    //   const current_user = TokenService.USER.get()
    //   if(account!==current_user.account){
    //     try {
    //       let vm = this;
    //       // this.$store.state.common.voipusers[account].state = "offline";
    //       if (vm.$store.state.sip.phone.userAgent) {
    //         const targetURI = new URI("sip", account, user.Realm);
    //         const eventType = "presence";
    //         const subscriber = new Subscriber(vm.$store.state.sip.phone.userAgent, targetURI, eventType, {
    //           extraHeaders: ["Accept: application/pidf+xml, application/xpidf+xml"],
    //         });
    //         subscriber.delegate = {
    //           onNotify: vm.onNotify,
    //         };
    //         subscriber.stateChange.addListener(function (newState) {
    //           try {
    //             LOGGER.info(_.get(vm.$store.state.common.voipusers,`[${account}].user.display_name`),newState,);
    //             if (newState == 'Terminated') {
    //               // subscriber.unsubscribe()
    //               // vm.$delete(vm.subscribers,account)
    //               // vm.subscribe(account)
    //             }
    //             if(vm.$store.state.common.voipusers[account]){
    //               vm.$set(vm.$store.state.common.voipusers[account],'blf_state',newState)
    //             }
    //           } catch(error) {
    //             LOGGER.danger("error in stateChange listener", {error});
    //           }
    //         });
    //         subscriber.subscribe();
    //         // console.log(subscriber);
    //         vm.$set(vm.subscribers,account,subscriber)
    //         return subscriber;
    //       } else {
    //         LOGGER.info("phone", vm.$store.state.sip.phone);
    //       }
    //     } catch (error) {
    //       LOGGER.danger("error in subscribe function", error);
    //     }
    //   }
    //   return null;
    // },
    // onNotify(notification) {
    //   try {
    //     // LOGGER.info("onNotify", notification);
    //     const account = notification.incomingNotifyRequest.message.from.uri.normal.user;
    //     var fromXML = require("from-xml").fromXML;
    //     // LOGGER.info('notification.request.body',notification.request.body)
    //     var data = fromXML(notification.request.body);
    //     LOGGER.info(_.get(this.$store.state.common.voipusers,`[${account}].user.display_name`),data.presence.note)
    //     if (!_.isEmpty(this.$store.state.common.voipusers)) {
    //       if (this.$store.state.common.voipusers[account]) {
    //         this.$store.state.common.voipusers[account].state = data.presence.note;
    //       }
    //     }
    //   } catch (error) {
    //     LOGGER.danger("error in onNotify function", error);
    //   }
    // },
    snooze(payload){
      let vm = this
      const user = TokenService.USER.get()
      return new Promise((resolve,reject)=>{
        let later = payload?.time
        const sec = payload?.sec ?? 0
        const label = payload?.label ?? payload
        const status_change = payload?.status_change ?? true
        const is_off = label=='off'
        const now = Date.now()
        if(!['off','unlimited','5 min','10 min','15 min','20 min','25 min','30 min','35 min','40 min','45 min','60 min','custom'].includes(label)){
          reject()
          return;
        }
        if(label=='off'){
          later = null
        } else if(label=='unlimited'){
          later = now + 2147483647
        } else if(label=='5 min'){
          later = now + 5*60*1000
        } else if(label=='10 min'){
          later = now + 10*60*1000
        } else if(label=='15 min'){
          later = now + 15*60*1000
        } else if(label=='20 min'){
          later = now + 20*60*1000
        } else if(label=='25 min'){
          later = now + 25*60*1000
        } else if(label=='30 min'){
          later = now + 30*60*1000
        } else if(label=='35 min'){
          later = now + 35*60*1000
        } else if(label=='40 min'){
          later = now + 40*60*1000
        } else if(label=='45 min'){
          later = now + 45*60*1000
        } else if(label=='60 min'){
          later = now + 60*60*1000
        } else if(label=='custom'){
          later = now + sec*1000
        }
        clearTimeout(snooze_time)
        clearInterval(snooze_timer)
        VOIP_API.endpoints.snooz.setSetting({
          account: user.account,
          snooze: later
        })
        .then(()=>{
          if(!is_off) return vm.getSnoozeSetting()
          else return;
        })
        .then(()=>{
          phone.toggleDnd(!is_off)
          vm.$store.state.sip.snooze_time=0
          vm.$store.state.sip.snooze=label
          TokenService.SNOOZE_LABEL.set(label)
          return $fn.sleep(2*1000)
        })
        .then(()=>{
          if(status_change){
            if(is_off) return this.getVoipStatuses?.find?.(status=>status.status=='Available' && status.accountcode==null)
            else return this.getVoipStatuses?.find?.(status=>status.status=='Do not disturb' && status.accountcode==null)
          }
          else return;
        })
        .then((status)=>{
          if(status) vm.$store.dispatch('setStatus',status.id)
          else return;
        })
        .then(()=>{
          resolve()
        })
        .catch((ex)=>{
          reject(ex)
        })
      })
    },
    getSnoozeSetting(paylaod){
      const set_value = paylaod?.set_value
      let vm = this
      const user = TokenService.USER.get()
      if(set_value){
        const SNOOZE_LABEL = TokenService.SNOOZE_LABEL.get()
        if(SNOOZE_LABEL){
          vm.$store.state.sip.snooze=SNOOZE_LABEL
        }
      }
      return new Promise((resolve,reject)=>{
        VOIP_API.endpoints.snooz.getSetting(user.account)
        .then(({ data })=>{
          const snooz_timestamp = parseInt(data?.snooze) || 0
          const sec = Math.floor((snooz_timestamp - Date.now())/1000)
          if(sec>0){
            let timer_sec = sec
            clearInterval(snooze_timer)
            clearTimeout(snooze_timer)
            snooze_timer = setInterval(()=>{
              vm.$store.state.sip.snooze_time=--timer_sec
            },1 * 1000)
            snooze_time = setTimeout(()=>{
              vm.snooze({
                label: 'off'
              })
            },sec * 1000)
          } else {
            vm.snooze({
              label: 'off'
            })
          }
          resolve(data)
        })
        .catch((ex)=>{
          // console.log('snooze-setting',ex)
          reject(ex)
        })
      })
    },
    getFeatures() {
      let vm = this
      return new Promise((resolve,reject)=>{
        VOIP_API.endpoints.features.list()
        .then(({ data: features=[] })=>{
          resolve(features)
          vm.$store.state.common.user.features = features;
        })
        .catch((ex)=>{
          reject(ex)
        })
      })
    },
    getNumbers() {
      let vm = this
      return new Promise((resolve,reject)=>{
        VOIP_API.endpoints.telephone_number.numbers()
        .then(({ data })=>{
          const numbers = data?.data ?? data ?? []
          resolve(numbers)
          vm.$store.commit("setVoipNumbers", numbers);
        })
        .catch((ex)=>{
          reject(ex)
        })
      })
    },
    getVoipUsers(){
      let vm = this
      const current_user = TokenService.USER.get()
      VOIP_API.endpoints.users.list()
      .then(({ data: { data: voipusers } })=>{
        if(_.isEmpty(voipusers)) throw new Error('Users is Empty')
        return $fn.manipulateVoipUsers(voipusers,vm.$store.state.common.voipusers)
      })
      .then((voipusers)=>{
        return _.keyBy(voipusers,'voipaccount')
      })
      .then((voipusers)=>{
        vm.$store.commit("setVoipUsers", voipusers);
      })
      .catch(ex=>{
        if(ex.message=='Users is Empty'){
          vm.$store.commit("setVoipUsers", {});
        }
        LOGGER.danger('error in voipusers api',ex)
      })
      .finally(()=>{
        VOIP_API.endpoints.users.deletedlist(current_user.uid)
        .then(({ data: deleted_voipusers })=>{
          return deleted_voipusers?.map?.((user)=>{
            return {
              voipaccount: user.accountcode,
              user: user.voip_user,
              extn: user.extn,
              profile_img: '',
              status: '',
              state: 'Not online',
              deleted: true,
            }
          }) ?? []
        })
        .then((deleted_voipusers)=>{
          return _.keyBy(deleted_voipusers,'voipaccount')
        })
        .then((deleted_voipusers)=>{
          vm.$store.commit("setVoipDeletedUsers", deleted_voipusers);
        })
      });
    },
    getSpeedDialLocal(){
      const current_user = TokenService.USER.get()
      let vm = this;
      return new Promise((resolve,reject)=>{
        let total_data = [], page = 1, total_rec = 0;
        const getData = () => {
          const onComplete = (type='success',data) => {
            vm.$store.commit("setLocalSpeedDial", {
              data: total_data,
              total: total_rec
            });
            if(type=='success'){
              resolve(total_data)
            } else {
              reject(data)
            }
          }
          VOIP_API.endpoints.speed_dials.list({
            account: current_user.account,
            page: page,
          })
          .then((res)=>{
            const data = res?.data?.data ?? []
            total_rec = res?.data?.meta?.pagination?.total ?? (total_data?.length + data?.length)
            total_data=[...total_data,...data]
            if(total_data.length!=total_rec){
              page=page+1
              getData()
            } else {
              onComplete()
            }
          })
          .catch((ex)=>{
            onComplete('error',ex)
          })
        }
        getData()
      })
    },
    getSpeedDialGlobal(){
      const current_user = TokenService.USER.get()
      let vm = this;
      return new Promise((resolve,reject)=>{
        let total_data = [], page = 1, total_rec = 0;
        const getData = () => {
          const onComplete = (type='success',data) => {
            vm.$store.commit("setGlobalSpeedDial", {
              data: total_data,
              total: total_rec
            });
            if(type=='success'){
              resolve(total_data)
            } else {
              reject(data)
            }
          }
          VOIP_API.endpoints.speed_dials.globallist({
            account: current_user.account,
            page: page,
          })
          .then((res)=>{
            const data = res?.data?.data ?? []
            total_rec = res?.data?.meta?.pagination?.total ?? (total_data?.length + data?.length)
            total_data=[...total_data,...data]
            if(total_data.length!=total_rec){
              page=page+1
              getData()
            } else {
              onComplete()
            }
          })
          .catch((ex)=>{
            onComplete('error',ex)
          })
        }
        getData()
      })
    },
    getAddressBookLocal(){
      let vm = this;
      return new Promise((resolve,reject)=>{
        let ___addressbook_local = [], page = 1, total_rec = 0;
        const getData = () => {
          const onComplete = (type='success',data) => {
            vm.$store.commit("setLocalAddressbook", {
              data: ___addressbook_local,
              total: total_rec
            });
            if(type=='success'){
              resolve(___addressbook_local)
            } else {
              reject(data)
            }
          }
          VOIP_API.endpoints.address_books.list({
            page: page,
            flag: 'local',
          })
          .then((res)=>{
            const _address_books = (res?.data?.data ?? []).map(address_book=>$fn.manipulateAddressBook(address_book))
            total_rec = res?.data?.meta?.pagination?.total ?? (___addressbook_local?.length + _address_books?.length)
            ___addressbook_local=[...___addressbook_local,..._address_books]
            if(___addressbook_local.length!=total_rec){
              page=page+1
              getData()
            } else {
              onComplete()
            }
          })
          .catch((ex)=>{
            onComplete('error',ex)
          })
        }
        getData()
      })
    },
    getAddressBookGlobal(){
      let vm = this;
      return new Promise((resolve,reject)=>{
        let ___addressbook_global = [], page = 1, total_rec = 0;
        const getData = () => {
          const onComplete = (type='success',data) => {
            vm.$store.commit("setGlobalAddressbook", {
              data: ___addressbook_global,
              total: total_rec
            });
            if(type=='success'){
              resolve(___addressbook_global)
            } else {
              reject(data)
            }
          }
          VOIP_API.endpoints.address_books.list({
            page: page,
            flag: 'global',
          })
          .then((res)=>{
            const _address_books = (res?.data?.data ?? []).map(address_book=>$fn.manipulateAddressBook(address_book))
            total_rec = res?.data?.meta?.pagination?.total ?? (___addressbook_global?.length + _address_books?.length)
            ___addressbook_global=[...___addressbook_global,..._address_books]
            if(___addressbook_global.length!=total_rec){
              page=page+1
              getData()
            } else {
              onComplete()
            }
          })
          .catch((ex)=>{
            onComplete('error',ex)
          })
        }
        getData()
      })
    },
    getCallQueue(){
      let vm = this;
      return new Promise((resolve,reject)=>{
        let __call_queues = [], page = 1, total_rec = 0;
        const getData = () => {
          const onComplete = (type='success',data) => {
            __call_queues = _.orderBy(__call_queues, ['real_id'], ['asc'])
            VOIP_API.endpoints.call_queues.getstatuses()
            .then((res)=>{
              const statuses = res?.data ?? {}
              LOGGER.log('statuses,__call_queues',statuses,__call_queues)
              return __call_queues?.map?.(call_queue=>{
                return {
                  ...call_queue,
                  call_queue_status: statuses?.[call_queue.voipaccount],
                }
              })
            })
            .then((cal_queues)=>{
              vm.$store.commit("setCallQueues", {
                data: cal_queues,
                total: total_rec
              });
            })
            .catch(()=>{
              vm.$store.commit("setCallQueues", {
                data: __call_queues,
                total: total_rec
              });
            })
            .finally(()=>{
              if(type=='success'){
                resolve(__call_queues)
              } else {
                reject(data)
              }
            })
          }
          VOIP_API.endpoints.call_queues.list({
            page: page,
          })
          .then((res)=>{
            const _call_queues = res?.data?.data ?? []
            total_rec = res?.data?.meta?.pagination?.total ?? (__call_queues?.length + _call_queues?.length)
            __call_queues=[...__call_queues,..._call_queues]
            if(__call_queues.length!=total_rec){
              page=page+1
              getData()
            } else {
              onComplete()
            }
          })
          .catch((ex)=>{
            onComplete('error',ex)
          })
        }
        getData()
      })
    },
    getIvr(){
      let vm = this;
      return new Promise((resolve,reject)=>{
        let __ivrs = [], page = 1, total_rec = 0;
        const getData = () => {
          const onComplete = (type='success',data) => {
            vm.$store.commit("setIVRS", {
              data: __ivrs,
              total: total_rec
            });
            if(type=='success'){
              resolve(__ivrs)
            } else {
              reject(data)
            }
          }
          VOIP_API.endpoints.menu.list({
            page,
          })
          .then((res) => {
            const _ivrs = res?.data?.data ?? {}
            total_rec = res?.data?.total ?? (Object.keys(__ivrs)?.length + Object.keys(_ivrs)?.length)
            __ivrs={
              ...__ivrs,
              ..._ivrs
            }
            if(Object.keys(__ivrs).length!=total_rec){
              page=page+1
              getData()
            } else {
              onComplete()
            }
          }).catch((ex)=>{
            onComplete('error',ex)
          })
        }
        getData()
      })
    },
    getVoipTeamss(){
      let vm = this, __teams = [], page = 1, total_rec = 0;
      const getTeams = () => {
        VOIP_API.endpoints.teams.list({
          fid: 12,
          page: page,
          accountcode: TokenService.USER.get()?.account,
        })
        .then((res)=>{
          const _teams = res?.data?.data ?? []
          total_rec = res?.data?.meta?.pagination?.total ?? (__teams?.length + _teams?.length)
          __teams=[...__teams,..._teams]
          if(__teams.length!=total_rec){
            page=page+1
            getTeams()
          } else {
            vm.$store.commit("setVoipTeams", {
              data: __teams,
              total: total_rec
            });
          }
        })
        .catch((ex)=>{
          this.appNotify({
            message: ex.own_message,
            type: 'danger',
          })
        })
        .finally(()=>{
          // TODO
        })
      }
      getTeams()
    },
    getCallTags(){
      let vm = this
      return new Promise((resolve,reject)=>{
        const current_user = TokenService.USER.get()
        VOIP_API.endpoints.tags.list({
          uid: current_user.uid,
          accountcode: current_user.account,
        })
        .then(({ data }) => {
          const tags = data?.data ?? []
          vm.$store.commit('setCallTags',tags)
          resolve(tags)
        })
        .catch((ex)=>{
          reject(ex)
        })
      })
    },
    getChangePlanLog(){
      let vm = this
      return new Promise((resolve,reject)=>{
        const current_user = TokenService.USER.get()
        VOIP_API.endpoints.users.getChangePlanLog({
          uid: current_user.uid,
          accountcode: current_user.account,
        })
        .then(({ data }) => {
          vm.$store.commit('setPlanLogs',data)
          resolve(data)
        })
        .catch((ex)=>{
          reject(ex)
        })
      })
    },
    checkAppVersion(){
      const current_user = TokenService.USER.get()
      const version = this.electron.app_version
      VOIP_API.endpoints.electron.appVersion({
        version,
        accountcode: current_user.account,
        uid: current_user.uid,
      })
      .then(({ data })=>{
        window.electron.send(electron_events.SWITCH_VERSION,{
          version: data?.label,
          url: data?.source
        })
      })
    },
    fetchSubscriptionDetail(){
      let vm = this
      const current_user = TokenService.USER.get()
      VOIP_API.endpoints.billing.getsubscriptiondetail({ 
        uid: current_user.uid,
      })
      .then(({ data }) => {
        vm.$store.state.common.current_subscription = data ?? {};
      })
    },
    setStore(){
      let vm = this
      const current_user = TokenService.USER.get()
      // const user_statuses = TokenService.USER_STATUSES.get() ?? {}
      const appData = TokenService.APP_DATA.get()
      if(appData){
        this.$store.commit("setCommonState",appData);
        TokenService.APP_DATA.remove()
      }
      // VOIP_API.endpoints.users.checkregistration({
      //   accountcode: current_user.sip.user,
      //   uuid: vm.$store.state.sip.phone.uuid || vm.fpData.device_id,
      //   fp_data: vm.fpData,
      //   device_id: vm.fpData.device_id,
      // })
      // time zone
      VOIP_API.endpoints.users.accountdetail()
      .then(({ data: accountdetail }) => {
        const timezone = accountdetail?.user_detail?.timezone ?? ''
        if(timezone) moment.tz.setDefault(timezone);
        else moment.tz.setDefault();
        vm.$root.$emit(events.fetch_call_activity)
        return $fn.sleep(5*1000,accountdetail?.user_detail?.country ?? moment.tz(moment().tz())._z.countries()?.[0] ?? ''/* accountdetail?.user_detail?.country ?? ''*/)
      })
      .then((country)=>{
        vm.$store.state.common.user.country = country
      })
      // statuses
      VOIP_API.endpoints.statuses.list({
        uid: current_user.uid,
      })
      .then(({ data: statuses })=>{
        return statuses.map((statuses) => {
          return { ...statuses, allow_call: statuses.allow_call == "Y" };
        });
      })
      .then((statuses)=>{
        vm.$store.state.common.statuses = statuses;
      //   const available = statuses?.find?.(status=>status.status=='Available' && status.accountcode==null)
      //   const currently = statuses?.find?.(status=>status.id==current_user.status_id)
      //   const before = statuses?.find?.(status=>status.id==user_statuses[current_user.account])
      //   console.log('currently,before,available',currently,before,available)
      //   return currently ?? before ?? available
      // })
      // .then((status)=>{
      //   vm.$store.dispatch('setStatus',status.id || 56)
      //   return $fn.sleep(3*1000)
      // })
      // .finally(()=>{
      //   vm.getSnoozeSetting({
      //     set_value: true
      //   })
      });
      // organization list
      VOIP_API.endpoints.user.organizationlist({
        accountcode: current_user.account,
        uid: current_user.uid
      })
      .then(({ data: { data: organizations } })=>{
        const new_organizations_array = organizations.map(organization=>{
          return {
            id: organization.origanization.origanization_id,
            uid: organization.origanization.origanization_uid,
            label: organization.origanization.origanization_label,
            logo: organization.origanization.origanization_logo,
          }
        })
        LOGGER.log('new_organizations_array',new_organizations_array)
        return $fn.sleep(2*1000,new_organizations_array)
      })
      .then((new_organizations_array)=>{
        vm.$store.state.common.user.organizations = new_organizations_array;
        vm.$store.state.common.user.selected_organization_id = vm.$store.state.common.user.selected_organization_id || new_organizations_array[0] ? new_organizations_array[0].id : '';
      })
      vm.getCallTags()
      vm.getVoipUsers()
      vm.getVoipTeamss(true)
      vm.getCallQueue()
      vm.getSpeedDialLocal()
      vm.getSpeedDialGlobal()
      vm.getAddressBookLocal()
      vm.getAddressBookGlobal()
      vm.getIvr()
      vm.getNumbers()
      vm.fetchSubscriptionDetail()
      vm.getChangePlanLog()
      vm.getFeatures()
    },
    socketConnection(){
      let vm = this
      const current_user = TokenService.USER.get()
      // let token = ''
      // let default_token = ''
      VOIP_API.endpoints.users.checkregistration({
        accountcode: current_user.sip.user,
        uuid: vm.$store.state.sip.phone.uuid || vm.fpData.device_id,
        fp_data: vm.fpData,
        device_id: vm.fpData.device_id,
      })
      .finally(()=>{
        // VOIP_API.endpoints.centrifuge.gettoken()
        // .then(({ data: { token: centrifugo_token, /*default_token: centrifugo_default_token*/ } })=>{
        //   token=centrifugo_token
        //   // default_token=centrifugo_default_token
        // })
        // .finally(()=>{
        //   // centrifugo.mothership.connect(token);
        //   // centrifugo.notification.connect(token);
        //   // centrifugo.calls.connect(token);
        //   // centrifugo.mothershipadmin.connect(default_token);
        //   // centrifugo.notification_admin.connect(default_token);
          AMI_SOCKET.connect()
        // })
      })
    },
    // onCentrifugoConnected: _.debounce(function(){
    //   let vm = this
    //   VOIP_API.endpoints.users.checkLogin({
    //     extension_accountcode: TokenService.USER.get()?.sip?.user,
    //     device_id: vm.fpData.device_id,
    //   })
    //   .then(({ data: status })=>{
    //     if(status=='logout' || status?.status=='logout'){
    //       vm.logout({is_user: true})
    //     }
    //   })
    // }, 2 * 1000),
    onFocus(){
      clearTimeout(focusTimeout)
    },
    onBlur(){
      // let vm=this
      focusTimeout = setTimeout(() => {
        // vm.logout()
      }, timer);
    },
    load(){
      let vm = this
      // FingerprintJS.load({
      //   token: process.env.VUE_APP_FINGERPRINT_JS_KEY
      // })
      // .then((fp) => {
      //   return fp.get({
      //     extendedResult: true
      //   })
      // })
      // .then((result) =>{
      //   vm.$store.state.fp_device=result
      // })
      // .finally(()=>{
        VOIP_API.endpoints.call.getNotificationSettings(TokenService.USER.get()?.account)
        .then(({ data })=>{
          vm.SET_LOCAL_SETTING_CALLS_FEEDBACK(data.after_call_feedback==1);
        })
        .finally(()=>{
          VOIP_API.endpoints.features.userAllowedFeatures({
            accountcode: TokenService.USER.get()?.account,
          })
          .then(({ data })=>{
            vm.$store.state.common.user_allowed_features=data
          })
          .finally(()=>{
            VOIP_API.endpoints.dns.deviceInfo()
            .finally((()=>{
              VOIP_API.endpoints.users.accountdetail()
              .then(({ data: accountdetail }) => {
                const timezone = accountdetail?.user_detail?.timezone ?? ''
                if(timezone) moment.tz.setDefault(timezone);
                else moment.tz.setDefault();
                vm.$store.state.common.user.blf = accountdetail?.user_detail?.blf=='enable';
                vm.$store.state.common.user.country = accountdetail?.user_detail?.country ?? moment.tz(moment().tz())._z.countries()?.[0] ?? ''
              })
              .finally(()=>{
                vm.loading=false
                vm.checkBrowserAndOSRecomendation()
                vm.socketConnection()
                vm.registerUser()
              })
            }))
          })
        })
      // })
    },
    async checkBrowserAndOSRecomendation(){
      if(!process.env.IS_ELECTRON){
        const { browser: { chrome }, device: { desktop } } = this.$store.state.device_info
        if(!chrome) {
          await this.$appConfirmation({
            title: "Attention",
            message: `We recomended to use chrome browser for good experience`,
            ok: true,
            button: {
              yes: "OK",
            },
          });
        }
        if(!desktop) {
          await this.$appConfirmation({
            title: "Attention",
            message: `We recomended to use our mobile application for good experience`,
            ok: true,
            button: {
              yes: "OK",
            },
          });
        }
      }
    },
    //--events--
    async switchOrganization(message){
      this.loading = true;
      try {
        const { organization } = message
        const password = this.$store.getters.getCurrentUser?.pass//this.current_user.pass
        const organizations = this.$store.getters.getCurrentUser?.organizations//this.current_user.organizations
        const selected_organization_id = organization.id
        if(window.self !== window.top){
          MOTHERSHIP?.sendData?.(MOTHERSHIP.events.send.SWITCH_ORGANIZATION,{
            organization_id: selected_organization_id,
            password: password,
          })
        } else {
          // LOGGER.log('organization.id',organization.id,'this.current_user.selected_organization_id',this.current_user.selected_organization_id)
          LOGGER.log('organization.id',organization.id,'this.current_user.selected_organization_id',this.$store.getters.getCurrentUser?.selected_organization_id)
          // if(organization.id===this.current_user.selected_organization_id) {
          if(organization.id===this.$store.getters.getCurrentUser?.selected_organization_id) {
            this.loading = false;
            return;
          }
          const { data } = await VOIP_API.endpoints.user.organizationLogin({
            id: organization.id,
            password: password,
          });
          if (data.account_created === "0") {
            this.$appConfirmation({
              title: "Error",
              message: `Please complete for signup process`,
              button: {
                no: "Cancel",
                yes: "Go",
              },
              callback: ({confirm}) => {
                if (confirm) {
                  //TODO
                }
              },
            });
          } else {
            LOGGER.log('Switching')
            await this.logout({is_user: true})
            await $fn.setUserLocally(data,password,organizations,selected_organization_id)
            await this.load()
          }
        }
      } finally {
        this.loading = false;
      }
    },
    STATUS_BROADCAST(message){
      const { data: { accountcode, status } } = message
      if(!this.$store.state.common.voipusers[accountcode]) return;
      this.$set(this.$store.state.common.voipusers[accountcode],'status',status?.id ?? '')
      if(accountcode==this.$store.getters.getCurrentUser?.account) {
        let new_user = {
          ...this.$store.getters.getCurrentUser,
          status_id: status?.id ?? '',
          status: status?.status ?? '',
        };
        this.$store.commit('setUser',new_user)
        // this.$store.dispatch('setStatus',status.id)
      }
    },
    updateOnlineStatus(){
      if(window.navigator.onLine){
        this.socketConnection()
        this.setStore()
      } else {
        //TODO
      }
    },
    onBeforeUnload(){
      TokenService.APP_DATA.set({
        value: this.$store._modules.root.state.common,
      })
    },
  },
  created() {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    this.$store.state.global_conditions.tab = "homeCopy";
    // centrifugo.mothership.onconnect=this.onCentrifugoConnected
    // centrifugo.mothershipadmin.onconnect=this.onCentrifugoConnected
    // centrifugo.notification_admin.onconnect=this.onCentrifugoConnected
    // centrifugo.notification.onconnect=this.onCentrifugoConnected
    // centrifugo.calls.onconnect=this.onCentrifugoConnected
    // window.addEventListener('blur',this.onBlur)
    // window.addEventListener('focus',this.onFocus)
    window.addEventListener("beforeunload", this.onBeforeUnload);
    AMI_SOCKET.on(AMI_EVENTS.STATUS_BROADCAST,this.STATUS_BROADCAST)
    // centrifugo.calls.on(centrifugo.calls.events.STATUS_BROADCAST,this.STATUS_BROADCAST)
    this.$root.$on(AppEvents.switch_organization,this.switchOrganization)
    this.load()
  },
  mounted(){
    // TIDIO.show()
    let vm = this
    const user = TokenService.USER.get()
    if(process.env.IS_ELECTRON){
      this.checkAppVersion()
      window.electron.send(electron_events.TURN_OFF_SPLASH)
      window.electron.send(electron_events.LOGIN,{
        name: user.display_name || '',
      })
      window.electron.receive('logout',()=>{
        vm.logout()
        // vm.logout({is_user: true})
      })
      window.electron.receive('dial-call-main',(event)=>{
        // console.log('dial-call-main')
        const { number } = event || {}
        if(!number) return;
        vm.$root.$emit(events.audio_call, {
          number,
        });
      })
      window.electron.receive('setStatus',(event)=>{
        const { id } = event
        vm.$store.dispatch('setStatus',id)
      })
    }
    if(window.self !== window.top){
      MOTHERSHIP?.sendData?.(MOTHERSHIP.events.send.IFRAME_LOADED)
      INTEGRATIONS.loggedin()
    }
    // setTimeout(()=>{
    //   vm.loading=false
      // APP_TOUR.start()
      // window.parent.postMessage({ message: "loader-complete", value: '' }, "*");
      // window.postMessage({ message: "loader-complete", value: '' }, "*");
    // }, 2 * 1000)
    // this.$tours['app-tour'].start()
  },
  beforeDestroy(){
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
    // window.removeEventListener('blur',this.onBlur)
    // window.removeEventListener('focus',this.onFocus)
    window.removeEventListener("beforeunload", this.onBeforeUnload);
    this.$root.$off(AppEvents.switch_organization,this.switchOrganization)
    AMI_SOCKET.off(AMI_EVENTS.STATUS_BROADCAST,this.STATUS_BROADCAST)
    // centrifugo.calls.off(centrifugo.calls.events.STATUS_BROADCAST,this.STATUS_BROADCAST)
    clearTimeout(focusTimeout)
  },
};
</script>

<style lang="scss" scoped>
.dialer-parent {
  .dialer-parent-inner {
    height: 100vh;
  }
}
</style>
