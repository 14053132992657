var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: `bill-tracks-container grid-view ${_vm.participantsLength > 50 ? 'exceded' : ''} ${_vm.participantsLength > 15 ? 'greaterThan15' : _vm.participantsLength > 30 ? 'greaterThan30' : ''}`,
    attrs: {
      "participants": _vm.participantsLength + 1
    },
    on: {
      "mousedown": function ($event) {
        return _vm.dragMouseDown($event, `popup-videos-${_vm.videoCall.room}`);
      }
    }
  }, [_vm._t("default"), _vm._l(Object.values(_vm.participants), function (participant, index) {
    return _c('Participant', {
      key: participant._id,
      class: `bill-track-${index + 2}`,
      attrs: {
        "speaker": _vm.speaker_id == participant._id,
        "participant": participant,
        "JitsiConference": _vm.JitsiConference,
        "speaker_device_id": _vm.speaker_device_id,
        "performanceSetting": _vm.performanceSetting,
        "videoCall": _vm.videoCall
      }
    });
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }