import { render, staticRenderFns } from "./UserRecordedVoicemailCopy.vue?vue&type=template&id=3359f231&scoped=true&"
import script from "./UserRecordedVoicemailCopy.vue?vue&type=script&lang=js&"
export * from "./UserRecordedVoicemailCopy.vue?vue&type=script&lang=js&"
import style0 from "./UserRecordedVoicemailCopy.vue?vue&type=style&index=0&id=3359f231&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3359f231",
  null
  
)

export default component.exports