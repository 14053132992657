var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-box alt position-relative p-0"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "dialer-call-options v3 w-100"
  }, [_c('div', {
    staticClass: "selectedContainer"
  }, [_vm.forwarding_setting.forward_note == 'voicemail' ? [_c('div', {
    staticClass: "selectedText-big"
  }, [_vm._v("Ask to leave a voicemail")]), _c('div', {
    staticClass: "selectedText-small"
  }, [_vm._v(" If phone rings for "), _c('span', {
    staticClass: "color-primary text-decoration-underline",
    on: {
      "click": function ($event) {
        return _vm.duration();
      }
    }
  }, [_vm._v(_vm._s(_vm.forwarding_setting.timeout) + " Seconds")]), _vm._v(" call will be forward to voicemail ")])] : _vm.forwarding_setting.forward_note == 'extension' ? [_c('div', {
    staticClass: "selectedText-big"
  }, [_vm._v("Redirect to a user, team or IVR menu")]), _c('div', {
    staticClass: "selectedText-small"
  }, [_vm._v(" If phone rings for "), _c('span', {
    staticClass: "color-primary cursor_pointer text-decoration-underline",
    on: {
      "click": function ($event) {
        return _vm.duration();
      }
    }
  }, [_vm._v(_vm._s(_vm.forwarding_setting.timeout) + " Seconds")]), _vm._v(" call will be forward to "), _c('span', {
    staticClass: "color-primary cursor_pointer text-decoration-underline",
    on: {
      "click": function ($event) {
        return _vm.extension();
      }
    }
  }, [_vm._v(_vm._s(_vm.forwarding_setting.extension_name))])])] : _vm.forwarding_setting.forward_note == 'number' ? [_c('div', {
    staticClass: "selectedText-big"
  }, [_vm._v("Redirect to another number")]), _c('div', {
    staticClass: "selectedText-small"
  }, [_vm._v(" If phone rings for "), _c('span', {
    staticClass: "color-primary text-decoration-underline",
    on: {
      "click": function ($event) {
        return _vm.duration();
      }
    }
  }, [_vm._v(_vm._s(_vm.forwarding_setting.timeout) + " Seconds")]), _vm._v(" call will be forward to "), _c('span', {
    staticClass: "color-primary text-decoration-underline",
    on: {
      "click": function ($event) {
        return _vm.number();
      }
    }
  }, [_vm._v(_vm._s(_vm.forwarding_setting.forward_number))])])] : [_c('div', {
    staticClass: "selectedText-big"
  }, [_vm._v("Keep ringing")]), _c('div', {
    staticClass: "selectedText-small"
  }, [_vm._v("Call forwarding is off")])]], 2)])]), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-icon', {
    staticClass: "cursor_pointer mt-2",
    attrs: {
      "icon": "pencil-fill",
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.edit();
      }
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }