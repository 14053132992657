var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: `dialer-settings-main ${_vm.openedFromModal ? 'p-0 b-0' : ''}`
  }, [_c('section', {
    class: `dialer-settings-section dialer-callRecording-settings MySoundFiles ${_vm.openedFromModal ? 'p-0' : ''}`
  }, [_c('div', {
    staticClass: "dialer-flex"
  }, [!_vm.openedFromModal ? [_vm.getIsMobile ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-width-button"
  }, [_c('b-icon', {
    staticClass: "settings-back-icon cursor_pointer",
    attrs: {
      "icon": "chevron-left",
      "font-scale": "1.5"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }), _c('h2', {
    staticClass: "dialer-settings-title w-100 mb-0 text-center"
  }, [_vm._v("My sound files")])], 1)] : _c('h2', {
    staticClass: "dialer-settings-title"
  }, [_vm._v(" My sound files "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon md-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('KbInfoModal', {
          kb: 'mySoundFiles'
        });
      }
    }
  })], 1)] : [_c('div', {
    staticClass: "dialer-edit-header w-100"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_c('vb-svg', {
    staticClass: "cursor_pointer_no_hover",
    attrs: {
      "name": "leftLongArrow-icon",
      "width": "20",
      "height": "12",
      "viewBox": "0 0 30 16",
      "stroke-width": "1",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('closeModal');
      }
    }
  }), _vm._v(" My sound files ")], 1), _c('div', {
    staticClass: "dialer-edit-actions"
  })])], _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    class: {
      'for-disable': !_vm.getUserPermissions.add_sound_file
    },
    attrs: {
      "title": _vm.getUserPermissions.add_sound_file ? '' : 'Your package does not support this feature'
    }
  }, [_c('button', {
    staticClass: "newButton",
    attrs: {
      "disabled": !_vm.getUserPermissions.add_sound_file
    },
    on: {
      "click": function ($event) {
        _vm.$modal.show('AddRecordingModal', {
          suggested_name: `sound ${_vm.getProperty(_vm.response.playlists, 'files.data.length', 0) + 1}`
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Add sound")])], 1)])], 2), _vm._m(0), _c('div', {
    staticClass: "TabsDesignWithIcon-container mt-20px mb-20px"
  }, _vm._l(_vm.groups, function (group) {
    return _c('div', {
      key: group.value,
      staticClass: "TabsDesignWithIcon-item",
      class: {
        'selected': _vm.filter.playlists.group == group.value,
        'mwd-168px': group.value == 'announcement'
      },
      on: {
        "click": function ($event) {
          _vm.filter.playlists.group = group.value;
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": group.icon,
        "height": "38px",
        "width": "38px"
      }
    }), _c('span', {
      staticClass: "TabsDesignWithIcon-item-textPart"
    }, [_vm._v(_vm._s(group.text))])], 1);
  }), 0), _c('div', {
    staticClass: "d-flex align-items-center w-100 justify-content-end"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD mr-16px"
  }, [_c('b-dropdown', {
    staticClass: "dd-text-dark dd-with-checkbox",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('vb-svg', {
          staticClass: "filterDD-icon",
          attrs: {
            "name": "filter-icon",
            "width": "19",
            "height": "18",
            "viewBox": "0 0 19 18",
            "stroke-width": "0",
            "stroke": "",
            "fill": "none",
            "stroke-linecap": "round",
            "stroke-linejoin": "round"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-form', [_c('b-form-checkbox', {
    staticClass: "makeCheckBoxesSquare fixVerticalAlign",
    attrs: {
      "checked": _vm.allSelectedTypes
    },
    on: {
      "change": function ($event) {
        _vm.filter.playlists.types = $event ? Object.keys(_vm.types) : [];
      }
    }
  }, [_c('span', {
    staticClass: "ml-4"
  }, [_vm._v("All")])]), _vm._l(_vm.types, function (type) {
    return _c('b-form-checkbox', {
      key: type.value,
      staticClass: "makeCheckBoxesSquare fixVerticalAlign",
      attrs: {
        "checked": _vm.filter.playlists.types.includes(type.value)
      },
      on: {
        "change": function ($event) {
          _vm.filter.playlists.types.includes(type.value) ? _vm.filter.playlists.types.splice(_vm.filter.playlists.types.indexOf(type.value), 1) : _vm.filter.playlists.types.push(type.value);
        }
      }
    }, [_c('span', {
      staticClass: "ml-4"
    }, [_vm._v(_vm._s(type.text))])]);
  })], 2)], 1)], 1), _c('div', {
    staticClass: "seachBox whiteBGinputWithGreyRoundedBorder"
  }, [_c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search sound"
    },
    model: {
      value: _vm.filter.playlists.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.playlists, "search", $$v);
      },
      expression: "filter.playlists.search"
    }
  })], 1)])]), _c('vb-table', {
    staticClass: "dialer-table-call-recording latestTableDesign-withBlackBorders-again hasSoundFiles showGraphIconOnlyOnHover",
    attrs: {
      "isListEmpty": _vm.playlists.length == 0,
      "listLength": _vm.playlists.length,
      "loading": _vm.api.playlists.send,
      "noRecordWholePage": true
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.playlists, function (file, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: `${file.sound}-${file.sound}-${file.label}-${file.group}`
          }, [_c('td', {
            staticClass: "dialer-row-title",
            on: {
              "click": function ($event) {
                !_vm.api.delete_playlist.send.includes(file.id) && file.status == 'processing' || file.filter_type != 'file' ? '' : _vm.$modal.show('EditRecordingModal', {
                  sound: file
                });
              }
            }
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [file.group == 'moh' ? _c('vb-icon', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              modifiers: {
                "hover": true
              }
            }],
            staticClass: "mr-16px",
            attrs: {
              "title": file.group,
              "icon": "moh-table-tdIcon",
              "width": "35px",
              "height": "35px"
            }
          }) : file.group == 'ivr' ? _c('vb-icon', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              modifiers: {
                "hover": true
              }
            }],
            staticClass: "mr-16px",
            attrs: {
              "title": file.group,
              "icon": "ivr-icon",
              "width": "35px",
              "height": "35px"
            }
          }) : file.group == 'announcement' ? _c('vb-icon', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              modifiers: {
                "hover": true
              }
            }],
            staticClass: "mr-16px",
            attrs: {
              "title": file.group,
              "icon": "announcement-icon-2",
              "width": "35px",
              "height": "35px"
            }
          }) : _c('vb-icon', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              modifiers: {
                "hover": true
              }
            }],
            staticClass: "mr-16px",
            attrs: {
              "title": file.group ? file.group : '',
              "icon": "MySoundFiles-table-tdIcon",
              "width": "35px",
              "height": "35px"
            }
          }), _c('div', [_c('span', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              modifiers: {
                "hover": true
              }
            }],
            attrs: {
              "title": file.created_at ? `created at ${file.created_at.slice(0, 10)}` : ''
            }
          }, [_vm._v(_vm._s(file.label))]), file.filter_type == 'file' ? _c('div', {
            staticClass: "insideTable-subText"
          }, [_vm._v("Uploaded by you")]) : file.filter_type == 'shared' ? _c('div', {
            staticClass: "insideTable-subText"
          }, [_vm._v("Shared with you")]) : file.filter_type == 'system' ? _c('div', {
            staticClass: "insideTable-subText"
          }, [_vm._v("From library")]) : _vm._e()]), file.filter_type == 'file' && file.shared ? _c('span', {
            staticClass: "OwnertaggedVersion ml-16px"
          }, [_vm._v("Shared")]) : _vm._e()], 1)]), _c('td', [file.status == 'processing' ? _c('div', [_c('div', {
            staticClass: "dialer-audio-playback"
          }, [_vm._v(_vm._s(file.status))]), _c('span', {
            staticClass: "icon-refresh",
            on: {
              "click": function ($event) {
                return _vm.fetchPlaylists();
              }
            }
          })]) : _c('div', {
            staticClass: "d-flex align-items-center justify-content-between"
          }, [_c('vb-audio-player', {
            staticClass: "whiteBGaudioWithGreyRoundedBorder-again",
            attrs: {
              "src": file.sound_file
            }
          }), file.filter_type == 'file' ? _c('b-button', {
            staticClass: "dialer-button dialer-button-delete-alt buttonNothing p-0 ml-8px",
            attrs: {
              "disabled": _vm.api.delete_playlist.send.includes(file.id),
              "variant": "link"
            },
            on: {
              "click": function ($event) {
                return _vm.deleteRecording(file.id);
              }
            }
          }, [_vm.api.delete_playlist.send.includes(file.id) ? _c('b-icon', {
            attrs: {
              "icon": "circle-fill",
              "animation": "throb",
              "variant": "primary"
            }
          }) : _c('b-icon', {
            attrs: {
              "icon": "trash-fill",
              "variant": "danger"
            }
          })], 1) : _vm._e()], 1)])]);
        });
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "-webkit-fill-available"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "50px",
              "width": "250px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "20px",
              "width": "60px"
            }
          })]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "48px",
              "width": "48px"
            }
          })])]);
        });
      },
      proxy: true
    }, {
      key: "no-record",
      fn: function () {
        return [_c('div', {
          staticClass: "latestGreyBox-9-9-2023 mb-32px mt-20px"
        }, [_c('div', {
          staticClass: "emptyScreenContainer"
        }, [_vm.GET_LOCAL_SETTING_IS_DARK_MODE ? _c('img', {
          staticClass: "mt-50px w-50 h-auto",
          attrs: {
            "src": require('@/assets/images/emptyScreenImages/darkMode/mysoundfiles.png')
          }
        }) : _c('img', {
          staticClass: "mt-50px w-50 h-auto",
          attrs: {
            "src": require('@/assets/images/emptyScreenImages/mysoundfiles.png')
          }
        }), _c('div', {
          staticClass: "emptyScreenContainer-heading"
        }, [_vm._v("No sound files found")]), _c('div', {
          staticClass: "emptyScreenContainer-text w-75"
        }, [_vm._v("Upload sound files to personalize your call experience")]), _c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip"
          }],
          attrs: {
            "title": _vm.getUserPermissions.add_sound_file ? '' : 'Your package does not support this feature'
          }
        }, [_c('button', {
          staticClass: "newButton mt-20px",
          attrs: {
            "disabled": !_vm.getUserPermissions.add_sound_file
          },
          on: {
            "click": function ($event) {
              _vm.$modal.show('AddRecordingModal', {
                suggested_name: `sound ${_vm.getProperty(_vm.response.playlists, 'files.data.length', 0) + 1}`
              });
            }
          }
        }, [_c('vb-icon', {
          attrs: {
            "icon": "squared-add-icon",
            "height": "38px",
            "width": "38px"
          }
        }), _c('span', {
          staticClass: "newButton-textPart"
        }, [_vm._v("Add sound file")])], 1)])])])];
      },
      proxy: true
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Name")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Audio")])])])])], 2), _c('AddRecordingModal', {
    on: {
      "update-list": function ($event) {
        return _vm.fetchPlaylists();
      }
    }
  }), _c('EditRecordingModal', {
    on: {
      "update-list": function ($event) {
        return _vm.fetchPlaylists();
      }
    }
  })], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "dialer-settings-intro with-button mt-4 mb-4"
  }, [_c('span', [_vm._v("This is a library of all sound files available to you, which can be used for IVR, Voicemail and more.")])]);

}]

export { render, staticRenderFns }