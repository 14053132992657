var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "TODO-innerHeading mt-30px"
  }, [_vm._v("Checklist")]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-12px"
  }, [_c('div', {
    staticClass: "d-flex align-items-center w-100"
  }, [_c('p', {
    staticClass: "Checklist-progressBar-container-text mb-0 mr-3"
  }, [_vm._v(_vm._s(_vm.checkListPercentatge) + "%")]), _c('div', {
    staticClass: "Checklist-progressBar-container"
  }, [_c('div', {
    staticClass: "Checklist-progressBar-container-filler",
    style: `width:${_vm.checkListPercentatge}%`
  })])]), _c('div', {
    staticClass: "Checklist-items-container"
  }, [_vm._l(_vm.response.checklist, function (checklist) {
    return _c('div', {
      key: checklist.id,
      class: `Checklist-item ${checklist.status == '1' ? 'checked' : ''}`
    }, [_c('div', {
      staticClass: "Checklist-item-leftPart"
    }, [_c('div', {
      staticClass: "Checklist-item-checkBoxType",
      on: {
        "click": function ($event) {
          _vm.disabled ? '' : _vm.onCheckListMarkChange(checklist);
        }
      }
    }, [checklist.status == '1' ? _c('div', {
      staticClass: "Checklist-item-checkBoxType-filledState"
    }, [_c('b-icon', {
      attrs: {
        "icon": "check",
        "variant": "primary"
      }
    })], 1) : _c('div', {
      staticClass: "Checklist-item-checkBoxType-emptyState"
    })]), _c('p', {
      staticClass: "Checklist-item-leftPart-text mb-0"
    }, [_vm._v(_vm._s(checklist.description))])]), _c('div', {
      staticClass: "Checklist-item-rightPart"
    }, [_c('div', {
      staticClass: "d-flex align-items-center stackedAvatars-container mr-16px"
    }, [checklist.assign_checklist ? _c('vb-avatar', {
      attrs: {
        "is_blf": false,
        "id": checklist.assign_checklist
      }
    }) : _vm._e()], 1), !checklist.assign_checklist ? _c('vb-icon', {
      staticClass: "addAssiggnee-InTodoCheckList-icon",
      attrs: {
        "icon": "addAssiggnee-InTodoCheckList-icon",
        "width": "21.395px",
        "height": "22.896px"
      },
      on: {
        "click": function ($event) {
          return _vm.onSelectUser(checklist);
        }
      }
    }) : _vm._e(), _c('div', {
      staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD buttonHeightAuto"
    }, [_c('b-dropdown', {
      staticClass: "ico-filter",
      attrs: {
        "varient": "link",
        "no-caret": "",
        "right": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function () {
          return [_c('b-icon', {
            staticClass: "fillBlack",
            attrs: {
              "icon": "three-dots",
              "variant": "dark",
              "scale": "1.5"
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          _vm.api.update_checklist.send || _vm.disabled ? '' : _vm.setUpdateCheckList(checklist);
        }
      }
    }, [_c('vb-icon', {
      staticClass: "fillBlack",
      attrs: {
        "icon": "crm-pencil-icon",
        "height": "14px",
        "width": "14px"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Rename")])], 1), _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.onSelectUser(checklist);
        }
      }
    }, [_c('vb-icon', {
      staticClass: "fillBlack",
      attrs: {
        "icon": "assignTo-InTodoCheckList-icon",
        "height": "14px",
        "width": "14px"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Assign to")])], 1), _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          _vm.disabled ? '' : _vm.removeCheckList(checklist);
        }
      }
    }, [_c('vb-icon', {
      staticClass: "fillBlack",
      attrs: {
        "icon": "trash-InTodoCheckList-icon",
        "height": "14px",
        "width": "14px"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Delete")])], 1)], 1)], 1)], 1)]);
  }), _c('div', {
    staticClass: "Checklist-item addItem"
  }, [!!_vm.conditions.update_checklist ? _c('b-form', {
    staticClass: "d-flex justify-content-between w-100",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.updateCheckList();
      }
    }
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder flex-fill"
  }, [_c('b-input', {
    directives: [{
      name: "focus",
      rawName: "v-focus"
    }],
    attrs: {
      "placeholder": "Update Item",
      "disabled": _vm.api.update_checklist.send || _vm.disabled,
      "maxlength": _vm.$v.forms.update_checklist.title.$params.maxLength.max
    },
    model: {
      value: _vm.forms.update_checklist.title,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update_checklist, "title", $$v);
      },
      expression: "forms.update_checklist.title"
    }
  }), _vm.forms.update_checklist.submitted && _vm.$v.forms.update_checklist.title.$invalid || _vm.api.update_checklist.validation_errors.title ? _c('p', {
    staticClass: "errorRed"
  }, [!_vm.$v.forms.update_checklist.title.required ? _c('span', [_vm._v("Title is required")]) : _vm._e(), !_vm.$v.forms.update_checklist.title.maxLength ? _c('span', [_vm._v("Title can be maximum " + _vm._s(_vm.$v.forms.update_checklist.title.$params.maxLength.max) + " character")]) : _vm._e(), _vm._l(_vm.api.update_checklist.validation_errors.title, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()], 1), _c('div', {
    staticClass: "d-flex"
  }, [_c('b-button', {
    staticClass: "dialer-button dialer-button-primary ml-16px",
    attrs: {
      "disabled": _vm.api.update_checklist.send || _vm.disabled,
      "type": "submit"
    }
  }, [_vm._v(" Update ")]), _c('b-button', {
    staticClass: "dialer-button dialer-button-delete ml-16px",
    attrs: {
      "disabled": _vm.api.update_checklist.send || _vm.disabled,
      "variant": "link"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.update_checklist = '';
      }
    }
  }, [_vm._v(" Cancel ")])], 1)]) : !_vm.conditions.add_checklist ? _c('div', {
    staticClass: "Checklist-item-leftPart",
    on: {
      "click": function ($event) {
        _vm.disabled ? '' : _vm.conditions.add_checklist = true;
      }
    }
  }, [_c('div', {
    staticClass: "Checklist-item-checkBoxType"
  }, [_c('b-icon', {
    staticClass: "plus-lg",
    attrs: {
      "icon": "plus-lg",
      "variant": "#7A7A7A"
    }
  })], 1), _c('span', {
    staticClass: "Checklist-item-leftPart-text"
  }, [_vm._v(" Add an item ")])]) : _c('b-form', {
    staticClass: "d-flex justify-content-between w-100",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.addCheckList();
      }
    }
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder flex-fill"
  }, [_c('b-input', {
    directives: [{
      name: "focus",
      rawName: "v-focus"
    }],
    attrs: {
      "placeholder": "Add Item",
      "disabled": _vm.api.add_checklist.send || _vm.disabled,
      "maxlength": _vm.$v.forms.add_checklist.title.$params.maxLength.max
    },
    model: {
      value: _vm.forms.add_checklist.title,
      callback: function ($$v) {
        _vm.$set(_vm.forms.add_checklist, "title", $$v);
      },
      expression: "forms.add_checklist.title"
    }
  }), _vm.forms.add_checklist.submitted && _vm.$v.forms.add_checklist.title.$invalid || _vm.api.add_checklist.validation_errors.title ? _c('p', {
    staticClass: "errorRed"
  }, [!_vm.$v.forms.add_checklist.title.required ? _c('span', [_vm._v("Title is required")]) : _vm._e(), !_vm.$v.forms.add_checklist.title.maxLength ? _c('span', [_vm._v("Title can be maximum " + _vm._s(_vm.$v.forms.add_checklist.title.$params.maxLength.max) + " character")]) : _vm._e(), _vm._l(_vm.api.add_checklist.validation_errors.title, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()], 1), _c('div', {
    staticClass: "d-flex"
  }, [_c('b-button', {
    staticClass: "newDoneButton ml-16px",
    attrs: {
      "disabled": _vm.api.add_checklist.send || _vm.disabled,
      "type": "submit"
    }
  }, [_c('vb-icon', {
    staticClass: "submitIcon",
    attrs: {
      "icon": "squared-submit-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm._v(" Submit ")])], 1), _c('b-button', {
    staticClass: "dialer-button dialer-button-delete ml-16px",
    attrs: {
      "disabled": _vm.api.add_checklist.send || _vm.disabled,
      "variant": "link"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.add_checklist = false;
      }
    }
  }, [_vm._v(" Cancel ")])], 1)])], 1)], 2)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }