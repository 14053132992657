var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-analytics-main dialer-analytics-likeSomeThing"
  }, [_c('div', {
    staticClass: "dialer-box p-16-24-px"
  }, [_c('div', {
    staticClass: "analytics-newHeader"
  }, [_vm._m(0), _c('div', {
    staticClass: "analytics-newHeader-rightSide justify-content-end"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion userTeamsFilter filterWithText w-33-imp"
  }, [_c('b-dropdown', {
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b', {
          staticClass: "text-right"
        }, [_vm._v(_vm._s(`${_vm.filters.voicemails.extensions.length ? _vm.filters.voicemails.extensions.length : 'No'} selected`))]), _c('div', {
          staticClass: "d-flex align-items-center"
        }, [_vm.filters.voicemails.extensions.length > 0 ? _c('b-icon', {
          staticClass: "ml-3",
          attrs: {
            "icon": "x-circle-fill",
            "varinat": "#000000"
          },
          on: {
            "click": function ($event) {
              $event.stopPropagation();
              _vm.filters.voicemails.extensions = [];
            }
          }
        }) : _vm._e(), _c('b-icon', {
          staticClass: "fillBlack sm-mar-left",
          attrs: {
            "icon": "three-dots-vertical",
            "variant": "dark",
            "scale": "1.5"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-form', [_c('b-check-group', {
    on: {
      "change": function ($event) {
        return _vm.fetchReportingVoicemails();
      }
    },
    model: {
      value: _vm.filters.voicemails.extensions,
      callback: function ($$v) {
        _vm.$set(_vm.filters.voicemails, "extensions", $$v);
      },
      expression: "filters.voicemails.extensions"
    }
  }, _vm._l(_vm.extensions, function (extension, key) {
    return _c('b-check', {
      key: key,
      attrs: {
        "value": key
      }
    }, [_c('Info', {
      attrs: {
        "id": key,
        "is_blf": false,
        "is_suspended_show": false,
        "type": 'user,team'
      }
    })], 1);
  }), 1)], 1)], 1)], 1)])]), _vm._m(1), _c('div', {
    staticClass: "d-flex align-items-center w-100"
  }), _c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again",
    attrs: {
      "isListEmpty": _vm.voicemails.length == 0,
      "listLength": _vm.voicemails.length,
      "loading": _vm.api.fetch_voicemails.send,
      "noRecordWholePage": true
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.voicemails, function (voicemail, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: index
          }, [_c('td', {
            staticClass: "dialer-row-title dialer-col-name-width wd-75"
          }, [voicemail.callerid ? _c('Info', {
            attrs: {
              "only_name": true,
              "id": _vm._f("calleridTextFilter")(voicemail.callerid)
            }
          }) : _vm._e(), _c('b', [_vm._v(_vm._s(_vm._f("number_formater")(_vm._f("calleridTextFilter")(voicemail.callerid))))]), _vm._v(" called "), voicemail.mailboxuser ? _c('Info', {
            attrs: {
              "only_name": true,
              "id": voicemail.mailboxuser
            }
          }) : _vm._e()], 1), _c('td', {
            staticClass: "dialer-col-center dialer-missed dialer-col-right wd-25"
          }, [_c('div', {
            staticClass: "d-flex justify-content-end w-100"
          }, [voicemail.filename ? _c('vb-audio-player', {
            attrs: {
              "src": voicemail.filename,
              "design": 'minimum'
            }
          }) : _vm._e()], 1)])]);
        });
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex"
          }, [_c('div', {
            staticClass: "latestShimmerDesign mr-2",
            staticStyle: {
              "height": "35px",
              "width": "35px"
            }
          }), _c('div', {
            staticClass: "d-flex flex-column justify-content-between"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "16px",
              "width": "60px"
            }
          }), _c('div', {
            staticClass: "latestShimmerDesign mt-1",
            staticStyle: {
              "height": "16px",
              "width": "30px"
            }
          })])])]), _c('td', [_c('div', {
            staticClass: "d-flex flex-column align-items-center w-100"
          }, [_c('div', {
            staticClass: "latestShimmerDesign mt-2",
            staticStyle: {
              "height": "16px",
              "width": "50px"
            }
          })])])]);
        });
      },
      proxy: true
    }, {
      key: "no-record",
      fn: function () {
        return [_c('div', {
          staticClass: "latestGreyBox-9-9-2023 mb-32px"
        }, [_c('div', {
          staticClass: "emptyScreenContainer"
        }, [_c('img', {
          staticClass: "mt-50px w-50 h-auto",
          attrs: {
            "src": require('@/assets/images/emptyScreenImages/teamAnalytics.png')
          }
        }), _c('div', {
          staticClass: "emptyScreenContainer-heading"
        }, [_vm._v(" Team analytics: dive into team performance ")]), _c('div', {
          staticClass: "emptyScreenContainer-text w-75"
        }, [_vm._v(" Explore comprehensive analytics for your teams' call activities, including call durations, volumes, and more. ")])])])];
      },
      proxy: true
    }])
  }, [_c('tr', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Number")])]), _c('th', {
    staticClass: "dialer-has-sort dialer-col-right dialer-missed"
  }, [_c('span', [_vm._v("Recording")])])])])], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "analytics-newHeader-leftSide"
  }, [_c('div', [_c('h1', [_vm._v("Voicemail")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "dialer-settings-intro newerTextVersion mb-0"
  }, [_c('span', [_vm._v(" Access detailed analytics of voicemails ,to review and respond to messages efficiently. ")])]);

}]

export { render, staticRenderFns }