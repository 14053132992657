<template>
  <div>
    <template v-if="openedFromModal">
      <!-- <div class="dialer-edit-header w-100">
        <h2 class="dialer-settings-title newer mb-0">
          <vb-svg @click="$emit('closeModal')" class="cursor_pointer_no_hover" name="leftLongArrow-icon" width="20" height="12" viewBox="0 0 30 16" stroke-width="1" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" />
          Call recording
        </h2>
        <div 
          v-b-tooltip :title="getUserPermissions.multi_moh?'':'Your package does not support this feature'"
          :class="{
            'for-disable': !getUserPermissions.multi_moh
          }"
        >
          <button 
            class="newButton" 
            @click="$modal.show('StorageModal')"
            :disabled="!getUserPermissions.multi_moh" 
          >
            <vb-icon icon="squared-change-icon" height="38px" width="38px" />
            <span class="newButton-textPart">Call recording storage</span>
          </button>
        </div>
      </div> -->
      <div class="settingsFirstSection pt-3">
        <h2 class="dialer-settings-title mb-0 settingsFirstSection-left newer">
          <vb-svg @click="$emit('closeModal')" class="cursor_pointer_no_hover" name="leftLongArrow-icon" width="20" height="12" viewBox="0 0 30 16" stroke-width="1" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" />
          <span class="md-mar-left">Call recording</span>
        </h2>
        <div class="settingsFirstSection-right">
          <div class="d-flex justify-content-end align-items-center mt-0">
            <div class="whiteBGinputWithGreyRoundedBorder sm-mar-left sm-mar-right seachBox">
              <b-input type="text" placeholder="Search user , extension" v-model="filter.call_recordings.search" />
            </div>
            <div 
              v-b-tooltip :title="getUserPermissions.multi_moh?'':'Your package does not support this feature'"
              :class="{
                'for-disable': !getUserPermissions.multi_moh
              }"
            >
              <button 
                class="newButton" 
                @click="$modal.show('StorageModal')"
                :disabled="!getUserPermissions.multi_moh" 
              >
                <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                <span class="newButton-textPart">Call recording storage</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div :class="`dialer-settings-main ${openedFromModal ? 'p-0 b-0' : ''}`">
      <section :class="`dialer-settings-section dialer-callRecording-settings ${openedFromModal ? 'p-0' : ''}`">
        <template v-if="!openedFromModal">
          <div v-if="getIsMobile" class="d-flex align-items-center justify-content-between flex-column">
            <div class="w-100 d-flex align-items-center justify-content-start calc-title-width-button">
              <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" @click="$emit('back')" />
              <h2 class="dialer-settings-title text-center">Call recordings</h2>
            </div>
            <div 
              v-b-tooltip :title="getUserPermissions.call_recordings_backup?'':'Your package does not support this feature'"
              class="w-100 d-flex align-items-center justify-content-end"
              :class="{
                'for-disable' : !getUserPermissions.call_recordings_backup
              }"
            >
              <button :disabled="!getUserPermissions.call_recordings_backup" class="dialer-button dialer-button-primary" @click="$modal.show('StorageModal')">
                <span class="">Call recording storage</span>
              </button>
            </div>
          </div>      
          <div v-else class="d-flex align-items-center justify-content-between">
            <h2 class="dialer-settings-title">
              Call recordings
              <b-icon icon="info-circle-fill" @click="$modal.show('KbInfoModal',{ kb: 'callRecordings' })" class="cursor_pointer infoKbIcon md-mar-left" />
            </h2>
            <div 
              v-b-tooltip :title="getUserPermissions.call_recordings_backup?'':'Your package does not support this feature'"
              :class="{
                'for-disable' : !getUserPermissions.call_recordings_backup
              }"
            >
              <button :disabled="!getUserPermissions.call_recordings_backup" class="dialer-button dialer-button-primary" @click="$modal.show('StorageModal')">
                <span class="">Call recording storage</span>
              </button>
            </div>
          </div>
        </template>
        <div v-else class="d-flex align-items-center justify-content-end">
          <div 
            v-b-tooltip :title="getUserPermissions.call_recordings_backup?'':'Your package does not support this feature'"
            :class="{
              'for-disable' : !getUserPermissions.call_recordings_backup
            }"
          >
            <button v-if="!openedFromModal" :disabled="!getUserPermissions.call_recordings_backup" class="newButton" @click="$modal.show('StorageModal')">
              <!-- <span class="">Call recording storage</span> -->
              <vb-icon icon="squared-change-icon" height="38px" width="38px" />
              <span class="newButton-textPart">Call recording storage</span>
            </button>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-between md-mar-top md-mar-bottom callRecording-2nd-section">
          <span class="dialer-settings-intro newerTextVersion mb-0">
            Set users to, on-demand or auto call recording, to capture calls for compliance, quality assurance, and training.
          </span>
          <!-- <div class="whiteBGinputWithGreyRoundedBorder seachBox">
            <b-input type="text" placeholder="Search user , extension" v-model="filter.call_recordings.search" />
          </div> -->
        </div>
        <vb-table 
          class="latestTableDesign-withBlackBorders-again" 
          :isListEmpty="filterCallRecording.length==0" 
          :listLength="filterCallRecording.length" 
          :loading="api.call_recordings.send"
        >
          <template #header>
            <tr>
              <th class="dialer-has-sort">
                <span>NAME</span>
              </th>
              <!-- <th class="dialer-has-sort">
                <span>settings</span>
              </th> -->
              <th class="dialer-has-sort">
                <span>recording</span>
              </th>
              <th class="dialer-has-sort dialer-col-right">
                <span>advance</span>
              </th>
            </tr>
          </template>
          <template #body="{ start, end }">
            <tr 
              class="dialer-row-select" 
              v-for="(user, index) in filterCallRecording" 
              :key="user.account" 
              v-show="index >= start && index <= end"
            >
              <!-- @click="
                $modal.show('CallRecordingEditModal',{ 
                  account: user.account, 
                  user_info: user,
                  allow_recording: user.allow_recording, 
                  setting: user.auto == 1 ? 'auto' : user.ondemand == 1 ? 'ondemand' : 'off' 
                })
              "  -->
              <td class="dialer-row-title one">
                <Info class="w-fit-content" :id="user.account" is_suspended_show :name="user.user" :sub_info="user.extn" :is_blf="false" />
              </td>
              <!-- <td class="dialer-col-right two">
                <div class="d-flex align-items-center justify-content-end">
                  <vb-icon :icon="user | filterIcon" height="30" :width="user | filterIconWidth" class="noRect svgTextFill-5757575"/>
                </div>
              </td> -->
              <td class="dialer-row-title two">
                <div class="d-flex align-items-center">
                  <vb-icon :icon="user | filterIcon" height="30" :width="user | filterIconWidth" :id="user.account" class="noRect svgTextFill-5757575 mr-8px"/>
                  <b-form-checkbox
                    v-b-tooltip :title="getUserPermissions.call_recording?'':'Your package does not support this feature'"
                    :ref="`${user.account}-ondeman_check`"
                    class="newerSwitch"
                    switch 
                    :disabled="api.update_call_recording.send || !getUserPermissions.call_recording"
                    :checked="user.off!='1'" 
                    @change="updateCallRecordingSetting($event?'ondemand':'off',user.account)"
                  />
                  <b-popover :target="user.account" triggers="hover" placement="top">
                    {{user | filterIcon | filterIconText}}
                  </b-popover>
                </div>
              </td>
              <td class="dialer-col-right three">
                <!-- <b-form-checkbox
                  v-b-tooltip :title="getUserPermissions.call_recording?'':'Your package does not support this feature'"
                  :ref="`${user.account}-ondeman_check`"
                  class="newerSwitch"
                  switch 
                  :disabled="api.update_call_recording.send || !getUserPermissions.call_recording"
                  :checked="user.off!='1'" 
                  @change="updateCallRecordingSetting($event?'ondemand':'off',user.account)"
                /> -->
                <!-- v-if="user.off!='1'" -->
                <b-form-checkbox 
                  v-b-tooltip :title="getUserPermissions.call_recording?user.off!='1'?'':'Turn on call recording to configure advance call recording option.':'Your package does not support this feature'"
                  :ref="`${user.account}-auto_check`" 
                  class="newerSwitch" 
                  switch 
                  :disabled="api.update_call_recording.send || !getUserPermissions.call_recording || user.off=='1'"
                  :checked="user.auto=='1'" 
                  @change="updateCallRecordingSetting($event?'auto':'ondemand',user.account)"
                />
              </td>
            </tr>
          </template>
          <template #loading>
            <tr v-for="n in 5" :key="n">
              <td>
                <div class="d-flex">
                  <div class="latestShimmerDesign mr-2" style="height:35px;width:35px;"></div>
                  <div class="d-flex flex-column justify-content-between">
                    <div class="latestShimmerDesign flex-1" style="height:16px;width:60px"></div>
                    <div class="latestShimmerDesign mt-1" style="height:16px;width:30px"></div>
                  </div>
                </div>
              </td>
              <td class="dialer-row-title">
                <div class="latestShimmerDesign" style="height:21px;width:90px;"></div>
              </td>
              <td class="dialer-col-right">
                <div class="latestShimmerDesign ml-auto" style="height:21px;width:90px;"></div>
              </td>
            </tr>
          </template>
        </vb-table>
      </section>
      <StorageModal />
      <CallRecordingEditModal @latest="fetchCallRecordings()" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { VOIP_API } from "../../utils";
import Info from "../Lists/Info.vue";
import StorageModal from "../Modals/StorageModal.vue";
import CallRecordingEditModal from "../Modals/CallRecordingEditModal.vue";

export default {
  name: "CallRecording",
  components: {
    StorageModal,
    CallRecordingEditModal,
    Info,
  },
  inject:['isEmpty','appNotify'],
  props: {
    openedFromModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      response: {
        call_recordings: {},
      },
      api: {
        call_recordings: this.$helperFunction.apiInstance(),
        update_call_recording: this.$helperFunction.apiInstance(),
      },
      filter: {
        call_recordings: {
          search: "",
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      'getUserPermissions',
      'getVoipUsersAlises',
      'getCurrentUser',
      'getIsMobile'
    ]),
    filterCallRecording() {
      const search = this.filter.call_recordings.search.trim().toLowerCase();
      const call_recordings = this.response.call_recordings
      const filter_call_recordings = Object.values(call_recordings).filter((val) =>`${val.extn} ${val.user}`.trim().toLowerCase().includes(search));
      return filter_call_recordings
    },
  },
  filters: {
    filterIcon(user){
      // user?.allow_recording != '1' || 
      if(user?.auto != 1 && user?.ondemand != 1) return 'CallRecordingOff-inSettings-icon'
      else if(user?.auto == 1) return 'CallRecordingAuto-inSettings-icon' //auto
      else if(user?.ondemand == 1) return 'CallRecordingOnDemand-inSettings-icon' //demand
      // else if(user?.allow_recording == '1') return 'CallRecordingOn-inSettings-icon'
      else return 'CallRecordingOff-inSettings-icon'
    },
    filterIconWidth(user){
      // if(user?.auto != 1 && user?.ondemand != 1) return 155
      // else if(user?.auto == 1) return 201
      // else if(user?.ondemand == 1) return 210
      
      if(user?.auto != 1 && user?.ondemand != 1) return 20
      else if(user?.auto == 1) return 20
      else if(user?.ondemand == 1) return 20
      else return 20
    },
    filterIconText(value){
      if(value == 'CallRecordingOff-inSettings-icon') return 'Call recording off'
      else if(value == 'CallRecordingAuto-inSettings-icon') return 'On demand call recording' //auto
      else if(value == 'CallRecordingOnDemand-inSettings-icon') return 'Automatic call recording' //demand
      else return 'Call recording off'
    }
  },
  methods: {
    async fetchCallRecordings() {
      if(this.api.call_recordings.send) return;
      try {
        this.api.call_recordings.send=true
        const { data } = await VOIP_API.endpoints.users.usercallrecordingsetting()
        this.response.call_recordings = data ?? [];
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.call_recordings.send=false
      }
    },
    async updateCallRecordingSetting(value,account) {
      if(this.api.update_call_recording.send) return;
      try {
        this.api.update_call_recording.send = true;
        await VOIP_API.endpoints.users.updateusercallrecordingsetting({
          ondemand: value=="ondemand" ? "1" : "0",
          auto: value=="auto" ? "1" : "0",
          off: value=="off" ? "1" : "0",
          account: account,
        })
        this.response.call_recordings[account].off=value=="off" ? "1" : "0"
        this.response.call_recordings[account].auto=value=="auto" ? "1" : "0"
        this.response.call_recordings[account].ondemand=value=="ondemand" ? "1" : "0"
        this.appNotify({
          message: "Successfully Updated!",
          type: "success",
        })
        this.$emit('latest');
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        if(this.$refs[`${account}-ondeman_check`]?.[0]) this.$refs[`${account}-ondeman_check`][0].localChecked = this.response.call_recordings[account].off!='1';
        if(this.$refs[`${account}-auto_check`]?.[0]) this.$refs[`${account}-auto_check`][0].localChecked = this.response.call_recordings[account].auto=='1';
        this.api.update_call_recording.send = false;
      }
    },
  },
  mounted() {
    this.fetchCallRecordings();
  },
};
</script>

<style lang="scss">
.dialer-tabs-switch-version {
  display: flex;
  justify-content: center;
  max-width: 205px;
  margin-top: 20px;

  .dialer-tab-headers {
    display: inline-flex;
    justify-content: center;
    border: none;

    .dialer-tab {
      cursor: pointer;
      font-size: 15px;
      color: $body-color;
      padding: 8px 10px;
      width: 85px;
      text-align: center;

      &.selected {
        color: $white;
        background-color: $primary;
        box-shadow: none;
        @include border-radius(7px);
      }
    }
  }
}
.can-toggle {
  position: relative;
  display: inline-block;
  vertical-align: top;
  input {
    &[type="checkbox"] {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      &:checked {
        & ~ label {
          .can-toggle__switch {
            background-color: $primary;
            &:before {
              content: attr(data-unchecked);
              left: 0;
              color: $white;
            }
            &:after {
              background-color: $white;
              content: attr(data-checked);
              transform: translate3d(65px, 0, 0);
              color: $primary;
              left: 23px;
              z-index: auto;
            }
          }
        }
      }
    }
    & + label {
      padding-left: 26px;
      height: 25px;
      line-height: 21px;
      font-weight: normal;
      position: relative;
      cursor: pointer;
      display: inline-block;
      font-size: 1rem;
    }
  }
  label {
    .can-toggle__switch {
      position: relative;
      transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
      background: $gray-400;
      height: 42px;
      width: 160px;
      flex: 0 0 134px;
      @include border-radius(12px);
      &:before {
        content: attr(data-checked);
        position: absolute;
        top: 2;
        left: 83px;
        text-transform: uppercase;
        text-align: center;
        color: $body-color;
        font-size: 12px;
        line-height: 40px;
        width: 80px;
        padding: 0 12px;
        @include border-radius(12px);
      }
      &:after {
        background: $primary;
        color: $white;
        content: attr(data-unchecked);
        position: absolute;
        left: 2px;
        top: 2px;
        @include border-radius(12px);
        width: 70px;
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        z-index: auto;
        text-transform: uppercase;
        text-align: center;
        transition: -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
        transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);
        transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1),
          -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
      }
    }
  }
}
</style>