<template>
  <div>
    <modal 
      class="center-zoomIn-transition v2 TopUpProductsModal" 
      width="500px" 
      height="auto" 
      :scrollable="true" 
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <section class="ct-user-new dialer-edit">
        <div class="dialer-form lg-mar-bottom">
          <div v-if="getIsMobile" class="dialer-edit-header flex-column">
            <div class="d-flex justify-content-between align-items-center w-100">
              <h2 v-if="conditions.top_up_balance_products" class="dialer-edit-title">Topup balance products</h2>
              <h2 v-else-if="conditions.sms_balance_products" class="dialer-edit-title">SMS balance products</h2>
              <h2 v-else class="dialer-edit-title">Topup call products</h2>
            </div>
            <div class="dialer-edit-actions d-flex justify-content-end w-100">
              <button class="newDoneButton" type="button" :disabled="!!api.add_topup.send" @click="$modal.hide(modalName)">
                <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                <span class="newDoneButton-textPart">Done</span>
              </button>
            </div>
          </div>
          <div v-else class="dialer-edit-header d-flex justify-content-between align-items-center">
            <div>
              <h2 v-if="conditions.top_up_balance_products" class="dialer-settings-title newer mb-0">Topup balance products</h2>
              <h2 v-else-if="conditions.sms_balance_products" class="dialer-edit-title newer mb-0">SMS balance products</h2>
              <h2 v-else class="dialer-settings-title newer mb-0">Topup call products</h2>
            </div>
            <div class="dialer-edit-actions">
              <a class="newCloseButton" @click="$modal.hide(modalName)">
                <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                <span class="newCloseButton-textPart">Close</span>
              </a>
            </div>
          </div>
          <div class="TabsDesignWithIcon-container mt-20px">
            <div class="TabsDesignWithIcon-item" v-for="allTab in tabs" :key="allTab.id" @click="selected.tab = allTab.id" :class="{'active': selected.tab == allTab.id}">
              <vb-icon :icon="allTab.icon" height="38px" width="38px" />
              <span class="TabsDesignWithIcon-item-textPart">{{allTab.text}}</span>
            </div>
          </div>
          <template v-if="selected.tab==tabs.add_topup.id">
            <div class="latestGreyBox-9-9-2023 mt-20px">
              <div class="latestGreyBox-heading-main-18px text-left">Topup the following amount</div>
              <b-form-group id="phone-numbers-radio" class="whiteBoxWithBorderInsideGreyBox withoutBorder p-4 mt-16px topUpCheckbox-container">
                <template v-if="api.fetch_topup_products.send">
                  <div class="latestShimmerDesign m-2" v-for="n in 8" :key="n.id" style="height:40px;width:calc(25% - 24px)"></div>
                </template>
                <template v-else>
                  <b-form-radio 
                    :disabled="!!api.add_topup.send || api.fetch_topup_products.send"
                    class="topUpCheckbox" v-for="product in response.products" :key="product.id"
                    :name="`${product.real_id}`" 
                    :value="product.real_id"
                    v-model="selected.product_id"
                  >
                    <span class="topUpCheckbox-textPart">£{{ product.price }}</span>
                  </b-form-radio>
                </template>
              </b-form-group>
              <div class="w-100 d-flex justify-content-end">
                <button class="newBlueButton" @click="addTopup(selected.product_id)">Add top up</button>
              </div>
            </div>
          </template>
          <template v-if="selected.tab==tabs.history.id">
            <div class="md-mar-vertical">
              <vb-table 
                :isListEmpty="response.history.length==0" 
                :listLength="response.history.length" 
                :loading="api.fetch_topup_history.send" 
                class="TopUpProductsBox latestTableDesign-withBlackBorders-again"
              >
                <tr slot="header">
                  <th class="dialer-has-sort">
                    <span>Amount</span>
                  </th>
                  <th class="dialer-has-sort">
                    <span>Type</span>
                  </th>
                  <th class="dialer-has-sort dialer-col-right">
                    <span>Description</span>
                  </th>
                </tr>
                <template #body="{ start, end }">
                  <tr v-for="(credit, index) in response.history" :key="credit.id" v-show="index >= start && index <= end">
                    <td>{{ credit.amount | to_fixed }}</td>
                    <td>{{ credit.type }}</td>
                    <td class="dialer-has-sort dialer-col-right">{{ credit.description }}</td>
                  </tr>
                </template>
                <template #loading>
                  <tr v-for="n in 3" :key="n.id">
                    <td>
                      <div class="latestShimmerDesign mb-2" style="height: 21px;width: 100%;"></div>
                    </td>
                    <td>
                      <div class="latestShimmerDesign mb-2" style="height: 21px;width: 100%;"></div>
                    </td>
                    <td>
                      <div class="latestShimmerDesign mb-2" style="height: 21px;width: 100%;"></div>
                    </td>
                  </tr>
                </template>
              </vb-table>
            </div>
          </template>
        </div>
      </section>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { VOIP_API } from '../../utils';
let tabs = {
  add_topup: {
    id: 1,
    text: "Add Topup",
    icon: 'newbilling-tab-addTopUp-icon'
  },
  history: {
    id: 2,
    text: "History",
    icon: 'newbilling-tab-history-icon'
  },
}
export default {
  name: "AddCreditModal",
  props: {
    modalName: {
      type: String,
      default: 'AddCreditModal',
    },
  },
  data(){
    return {
      api: {
        add_topup: this.$helperFunction.apiInstance({ send: '' }),
        fetch_topup_products: this.$helperFunction.apiInstance(),
        fetch_topup_history: this.$helperFunction.apiInstance(),
      },
      selected: this.$helperFunction.stateInstance({
        data: {
          tab: tabs.add_topup.id,
          product_id: '',
        }
      }),
      response: this.$helperFunction.stateInstance({
        data: {
          products: [],
          history: [],
        }
      }),
      conditions: this.$helperFunction.stateInstance({
        data: {
          top_up_balance_products: false,
          sms_balance_products: false,
        }
      }),
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getIsMobile',
    ]),
    tabs(){ return tabs },
  },
  methods: {
    onBeforeOpen(event){
      this.conditions.top_up_balance_products=!!event?.params?.top_up_balance_products
      this.conditions.sms_balance_products=!!event?.params?.sms_balance_products
      if(event?.params?.top_up_balance_products && event?.params?.show_history){
        this.selected.tab=tabs.history.id
      }
      this.fetchTopupProducts()
      this.fetchHistory()
    },
    onBeforeClose(){
      this.selected.reset()
      this.response.reset()
      this.conditions.reset()
      this.api.add_topup.reset()
      this.api.fetch_topup_history.reset()
      this.api.fetch_topup_products.reset()
    },
    async fetchHistory(){
      if(this.api.fetch_topup_history.send) return;
      try {
        this.api.fetch_topup_history.send=true
        const { data } = await VOIP_API.endpoints.topup.gethistory({
          topup_history_type: this.conditions.top_up_balance_products ? 'voip_topup_balance' : this.conditions.sms_balance_products ? 'sms_balance' : 'call_credit_balance',
        })
        this.response.history=data ?? []
      } finally {
        this.api.fetch_topup_history.send=false
      }
    },
    async fetchTopupProducts(){
      if(this.api.fetch_topup_products.send) return;
      try {
        this.api.fetch_topup_products.send=true
        const { data: { data } } = await VOIP_API.endpoints.topup.getproducts()
        this.response.products=data ?? []
      } finally {
        this.api.fetch_topup_products.send=false
      }
    },
    async addTopup(){
      if (!this.selected.product_id) return;
      if (this.api.add_topup.send) return;
      const product = this.response.products.find(i=>i.real_id==this.selected.product_id)
      const id = product?.whmcs_id ?? ''
      const price = product?.price ?? ''
      if (this.api.add_topup.send || !id) return;
      try {
        const { confirm } = await this.$appConfirmation({
          title: "Top-up confirmation",
          message: `You are about to add a topup of ${price} to your account.Do You want to continue?`,
          button: {
            no: "No",
            yes: "Yes",
          },
        })
        if(!confirm) return;
        this.api.add_topup.send = id
        await VOIP_API.endpoints.topup.add2({
          id: id,
          topup_type: this.conditions.top_up_balance_products ? 'voip_topup_balance' : this.conditions.sms_balance_products ? 'sms_balance' : 'call_credit_balance',
          accountcode: this.getCurrentUser?.account,
          uid: this.getCurrentUser?.uid,
          default_account: this.getCurrentUser?.default_accountcode,
        })
        this.$modal.hide(this.modalName)
        this.$emit('added')
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.add_topup.send = ''
      }
    },
  },
};
</script>
