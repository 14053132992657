<template>
  <modal 
    class="center-zoomIn-transition sipActiveCall-Modal SelectSnoozeTimeModal CallForwardingNumberInputModal" 
    transition="center-zoomIn-transition" 
    :name="modalName" 
    @before-open="onBeforeOpen($event)" 
    @before-close="onBeforeClose()"
  >
    <form @submit.prevent="onDone()" class="dialer-call-notification-tray w-100">
      <div class="dialer-call-notification dialer-keypadNotification w-100">
        <!-- <div class="dialer-call-notification-close" @click="$modal.hide(modalName)">
          <vb-icon icon="dialer-close-icon" width="14" height="14" />
        </div> -->
        <div class="dialer-call-notification-inner d-flex align-items-center justify-content-between w-100 mb-2">
          <div class="dialer-call-notification-text">
            <div class="dialer-call-notification-main-text">{{ modalTitle }}</div>
          </div>
          <a class="newCloseButton" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </a>
        </div>
        <div class="vm--modal-text">Please enter the number for unanswered call forwarding.</div>
        <div class="latestGreyBox-9-9-2023 mt-20px">
          <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion maxHeight150px w-100">
            <vb-select v-model="forms.number.country" @change="forms.number.number='';" :options="countries">
              <!-- <template #selected-option="{ option }">{{ CountryName2[option] || option }}</template>
              <template #option="{ option }">{{ CountryName2[option] || option }}</template> -->
            </vb-select>
          </div>
          <div class="whiteBGinputWithGreyRoundedBorder w-100 mt-20px">
            <input 
              v-vb-input-number-formate="forms.number.country" 
              placeholder="Forward number" 
              type="text" 
              v-model="forms.number.number" 
              class="bg-white w-100" 
            />
            <p v-if="forms.number.submitted && $v.forms.number.number.$invalid" class="text text-danger animated bounceIntop">
              <span v-if="!$v.forms.number.number.validNumber">* number is invalid</span>
            </p>
          </div>
        </div>
        <button class="fullWidthDoneButton mt-20px">
          Update
        </button>
      </div>
    </form>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { $fn } from '@/utils';
import moment from 'moment-timezone';
import { validNumberChecker } from '@/filter';
import { CountryName2, flagsCountryCode } from '@/utils/flags';
import _ from 'lodash'
export default {
  name: "CallForwardingNumberInputModal",
  props: {
    modalName: {
      type: String,
      default: "CallForwardingNumberInputModal",
    },
    modalTitle: {
      type: String,
      // default: "Add/update number",
      default: "Number forwarding",
    },
  },
  data() {
    return {
      forms: {
				number: this.$helperFunction.formInstance({
          data: {
            country: 'GB',
            number: '',
          }
        }),
			}
    };
  },
  computed: {
    ...mapGetters([
      'getUserPermissions'
    ]),
    countries(){ 
      return _.orderBy(moment.tz.countries().map((i)=>({
        value: i,
        text: CountryName2[i],
        flag: flagsCountryCode[i],
      })) , ['text'], ['asc']);
    },
    CountryName2(){ return CountryName2 },
  },
  validations: {
    forms: {
      number: {
        number: {
          validNumber(value){ return $fn.validPhoneNumber(value,this.forms.number.country) },
        },
      }
    },
  },
  methods: {
    onBeforeOpen(event){
      const phoneNumber = validNumberChecker(event?.params?.number)
      if(phoneNumber) {
        this.forms.number.country = phoneNumber?.country || ''
        this.forms.number.number = phoneNumber?.format('NATIONAL')
      }
    },
    onBeforeClose(){
      this.forms.number.reset()
    },
    onDone() {
      this.forms.number.submitted = true
      this.$v.forms.number.$touch()
      if(this.$v.forms.number.$invalid) return;
      const ber = $fn.getNumberFormated(this.forms.number.number,this.forms.number.country)
      // console.log(ber)
      this.$emit("number", {
        ber
      });
      this.$modal.hide(this.modalName);
      this.forms.number.submitted = false
    },
  },
};
</script>

<style lang="scss">

.custom-snooze-time {
  padding: $dialer-default-padding;
  .b-time {
    width: 100%;
    .b-time-header {
      output {
        border-radius: 12px;
        border: 1px solid #f0f0f0;
        // background-color: #f0f0f0;
        background-color: $chat_primary_user;
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
      }
    }
    & > div {
      margin: $dialer-default-padding 0px;
      .b-form-spinbutton {
        margin: 0px 8px;
      }
    }
  }
}

.cancel-button-modal {
  padding: 5px 8px;
  border-radius: 50%;
  border: 0px;
  background-color: $primaryLight;
}
</style>
