var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-settings-main"
  }, [[_c('section', {
    staticClass: "dialer-profile-setting v2 dialer-settings-section"
  }, [_c('div', {
    staticClass: "settingsFirstSection"
  }, [_vm.getIsMobile ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-width-button w-100"
  }, [_c('b-icon', {
    staticClass: "settings-back-icon cursor_pointer",
    attrs: {
      "icon": "chevron-left",
      "font-scale": "1.5"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }), _c('h2', {
    staticClass: "dialer-settings-title w-100 mb-0 text-center"
  }, [_vm._v("My profile")])], 1)] : [_c('h2', {
    staticClass: "dialer-settings-title mb-0 settingsFirstSection-left newer"
  }, [_vm._v(" My profile "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon md-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('KbInfoModal', {
          kb: 'profile'
        });
      }
    }
  })], 1)]], 2), _vm._m(0), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main mt-24px"
  }, [_vm._v("Personal information & account credentials")]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-24px mb-32px"
  }, [_c('div', {
    staticClass: "d-flex align-items-center profileImg-section-latest-container-parent"
  }, [_c('div', {
    staticClass: "profileImg-section-latest-container"
  }, [_c('div', {
    staticClass: "profileImg-section-latest",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-UserProfileImageUploader`, {
          type: 'USER',
          accountcode: _vm.getCurrentUser.account,
          current_image: _vm.getCurrentUser.profileImg
        });
      }
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.getCurrentUser.profileImg,
      "alt": _vm.getCurrentUser.display_name
    },
    on: {
      "error": function ($event) {
        $event.target.src = _vm.local_filess.images.user_male;
      }
    }
  })]), _c('h3', {
    staticClass: "latestGreyBox-heading-main-18px mt-12px mb-0"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.user, 'user.display_name')))])]), _c('form', {
    staticClass: "dialer-form flex-fill ml-60px d-flex flex-column dialer-change-password",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    staticClass: "d-flex w-100"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder pr-3 flex-fill"
  }, [_c('label', [_vm._v("First name")]), _c('input', {
    staticClass: "w-100",
    attrs: {
      "disabled": ""
    },
    domProps: {
      "value": _vm.generalInfo.first_name
    }
  })]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder pl-3 flex-fill"
  }, [_c('label', [_vm._v("Last name")]), _c('input', {
    staticClass: "w-100",
    attrs: {
      "disabled": ""
    },
    domProps: {
      "value": _vm.generalInfo.last_name
    }
  })])]), _c('div', {
    staticClass: "d-flex w-100"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder withLeftIconOnly flex-fill mt-16px mr-32px"
  }, [_c('label', [_vm.userType == 'user' ? [_vm._v("Username")] : [_vm._v("E-mail")]], 2), _c('div', {
    staticClass: "inputContainer-inside-whiteBGinputWithGreyRoundedBorder"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "latest-emailInsideInput-icon",
      "width": "18.818px",
      "height": "14.112px"
    }
  }), _c('input', {
    staticClass: "w-100",
    attrs: {
      "disabled": ""
    },
    domProps: {
      "value": _vm.currentUserEmail
    }
  })], 1)]), _c('div', {
    staticClass: "profileImg-section-inputs-column prcnt33 mt-auto"
  }, [_c('button', {
    staticClass: "newButton w-100",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-ChangePasswordModal`, {
          accountcode: _vm.getCurrentUser.account
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change password")])], 1)])])])])])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_vm._m(1), _c('div', {
    staticClass: "w-fit-content"
  }, [_c('b-form-checkbox', {
    staticClass: "newerSwitch",
    attrs: {
      "switch": "",
      "checked": _vm.GET_LOCAL_SETTING_THEME.mode == 'dark'
    },
    on: {
      "change": function ($event) {
        _vm.SET_LOCAL_SETTING_THEME_MODE($event ? 'dark' : 'colored3');
        _vm.appNotify({
          message: 'Successfully updated',
          type: 'success'
        });
      }
    }
  })], 1)]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_vm._m(2), _c('div', {
    staticClass: "w-fit-content"
  }, [_c('b-form-checkbox', {
    staticClass: "newerSwitch",
    attrs: {
      "switch": "",
      "checked": _vm.GET_LOCAL_SETTING_NUMBERS.formating.international_formate
    },
    on: {
      "change": function ($event) {
        _vm.SET_LOCAL_SETTING_NUMBERS_FORMATING_INTERNATIONAL_FORMATE(!_vm.GET_LOCAL_SETTING_NUMBERS.formating.international_formate);
        _vm.appNotify({
          message: 'Successfully updated',
          type: 'success'
        });
      }
    }
  })], 1)]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row somethingInside"
  }, [_c('DesktopNotificationWidget', {
    attrs: {
      "accountcode": _vm.voipaccount
    }
  })], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_vm._m(3), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "w-fit-content",
    class: {
      'for-disable': !_vm.getUserPermissions.call_waiting
    },
    attrs: {
      "title": _vm.getUserPermissions.call_waiting ? '' : 'Your package does not support this feature'
    }
  }, [_vm.api.user_detail.send ? [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "34px",
      "width": "60px",
      "border-radius": "25px"
    }
  })] : _c('b-form-checkbox', {
    ref: "call_waiting",
    staticClass: "newerSwitch",
    attrs: {
      "disabled": !_vm.getUserPermissions.call_waiting || _vm.api.update_call_waiting.send || _vm.api.user_detail.send,
      "checked": !_vm.conditions.call_waiting,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.updateCallWaiting();
      }
    }
  })], 2)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row d-flex flex-column"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_vm._m(4), _c('b-check', {
    ref: "two_factor_auth_checkbox",
    staticClass: "newerSwitch",
    attrs: {
      "disabled": _vm.api.update_two_factor_auth.send,
      "switch": "",
      "checked": _vm.tfaEnabled
    },
    on: {
      "change": function ($event) {
        return _vm.changeTwoFactorAuth($event ? 'yes' : 'no');
      }
    }
  })], 1), _vm.tfaEnabled ? _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px w-100"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 dd-2FA"
  }, [_c('div', {
    staticClass: "dialer-dropdown"
  }, [_c('b-dropdown', {
    ref: "dropdown",
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', {
          staticClass: "text-left"
        }, [_vm.selected.two_fact_auth === 'yes' ? [_vm._v("All devices")] : _vm.selected.two_fact_auth === 'non_trusted' ? [_vm._v("Un-trusted devices")] : [_vm._v("Select options")]], 2), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 2811948084)
  }, [_c('b-dropdown-item', {
    attrs: {
      "disabled": _vm.api.update_two_factor_auth.send
    },
    on: {
      "click": function ($event) {
        return _vm.changeTwoFactorAuth('yes');
      }
    }
  }, [_vm._v(" All devices ")]), _c('b-dropdown-item', {
    attrs: {
      "disabled": _vm.api.update_two_factor_auth.send
    },
    on: {
      "click": function ($event) {
        return _vm.changeTwoFactorAuth('non_trusted');
      }
    }
  }, [_vm._v(" Un-trusted devices ")])], 1)], 1)])])])]) : _vm._e()])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_vm._m(5), _c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again smallerFontSizes mb-4 TrustedDevicesTableContainer",
    attrs: {
      "isListEmpty": _vm.filterTrustedDevices.length == 0,
      "listLength": _vm.filterTrustedDevices.length,
      "loading": _vm.api.trusted_devices.send,
      "noRecordWholePage": true,
      "perPage": 5
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.filterTrustedDevices, function (data, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: index,
            staticClass: "dialer-row-select"
          }, [_c('td', {
            staticClass: "dialer-row-title one"
          }, [_c('div', {
            staticClass: "deviceContainer forTrustedDevicesTable d-flex align-items-center w-fit-content"
          }, [_c('vb-icon', {
            attrs: {
              "icon": _vm._f("extensionDeviceInfo")(_vm._f("jsonParse")(data.fp_data), 'icon'),
              "height": "25px",
              "width": "25px"
            }
          }), _c('vb-icon', {
            staticClass: "ml-2",
            attrs: {
              "icon": _vm._f("extensionDeviceInfo")(_vm._f("jsonParse")(data.fp_data), 'osicon'),
              "height": "25px",
              "width": "25px"
            }
          }), _vm.isSameDevice(data.fp_data) ? _c('div', {
            staticClass: "newThisDeviceIndicaterTextBox-25-11-2023"
          }, [_c('span', [_vm._v("This device")])]) : _vm._e()], 1)]), _c('td', {
            staticClass: "dialer-row-title two"
          }, [_c('span', [_vm._v(_vm._s(_vm._f("extensionDeviceInfo")(_vm._f("jsonParse")(data.fp_data), 'location')))])]), _c('td', {
            staticClass: "dialer-row-title three"
          }, [_c('span', [_vm._v(_vm._s(_vm._f("extensionDeviceInfo")(_vm._f("jsonParse")(data.fp_data), 'ip')))])]), _c('td', {
            staticClass: "dialer-row-title four"
          }, [_c('span', [_vm._v(_vm._s(_vm._f("filter_date_current")(data.created_at)))])]), _c('td', {
            staticClass: "dialer-row-title dialer-col-right five"
          }, [_c('div', {
            staticClass: "d-flex justify-content-end"
          }, [_vm.api.delete_trusted_device.send.includes(data.id) ? _c('vb-spinner') : _c('div', {
            staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD",
            attrs: {
              "disabled": _vm.api.delete_trusted_device.send.includes(data.id)
            }
          }, [_c('b-dropdown', {
            attrs: {
              "right": ""
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function () {
                return [_c('b-icon', {
                  attrs: {
                    "icon": "three-dots-vertical",
                    "scale": "1.5"
                  }
                })];
              },
              proxy: true
            }], null, true)
          }, [_c('b-dropdown-item', {
            on: {
              "click": function ($event) {
                return _vm.deleteTrustedDevice(data);
              }
            }
          }, [_vm._v("Delete")])], 1)], 1)], 1)])]);
        });
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex align-items-center w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "25px",
              "width": "25px"
            }
          }), _c('div', {
            staticClass: "latestShimmerDesign ml-2",
            staticStyle: {
              "height": "25px",
              "width": "25px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex align-items-center w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "140px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "108px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "108px"
            }
          })])]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "25px",
              "width": "20px"
            }
          })])]);
        });
      },
      proxy: true
    }, {
      key: "no-record",
      fn: function () {
        return [_c('div', {
          staticClass: "latestGreyBox-9-9-2023 mt-32px mb-32px"
        }, [_c('div', {
          staticClass: "emptyScreenContainer"
        }, [_vm.GET_LOCAL_SETTING_IS_DARK_MODE ? _c('img', {
          staticClass: "mt-12px wd-30 h-auto",
          attrs: {
            "src": require('@/assets/images/emptyScreenImages/darkMode/trustedDevices.png')
          }
        }) : _c('img', {
          staticClass: "mt-12px wd-30 h-auto",
          attrs: {
            "src": require('@/assets/images/emptyScreenImages/trustedDevices.png')
          }
        }), _c('div', {
          staticClass: "emptyScreenContainer-heading mt-12px"
        }, [_vm._v(" Trusted devices ")]), _c('div', {
          staticClass: "emptyScreenContainer-text w-75"
        }, [_vm._v(" Your trusted devices have their own hangout spot right here! Keep an eye on your device list. When logging in with an untrusted device, you've got the option to add it to the trusted list. Let's ensure your account stays safe and secure! ")])])])];
      },
      proxy: true
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort one"
  }, [_c('span', [_vm._v("Device")])]), _c('th', {
    staticClass: "dialer-has-sort two"
  }, [_c('span', [_vm._v("Location")])]), _c('th', {
    staticClass: "dialer-has-sort three"
  }, [_c('span', [_vm._v("IP address")])]), _c('th', {
    staticClass: "dialer-has-sort four"
  }, [_c('span', [_vm._v("Last visit")])]), _c('th', {
    staticClass: "dialer-has-sort five"
  }, [_c('span')])])])], 2)], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_vm._m(6), _c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again mb-4",
    attrs: {
      "isListEmpty": _vm.callQueuesAndTeams.length == 0,
      "listLength": _vm.callQueuesAndTeams.length,
      "noRecordWholePage": true
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref2) {
        var start = _ref2.start,
          end = _ref2.end;
        return _vm._l(_vm.callQueuesAndTeams, function (callQueuesAndTeam, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: callQueuesAndTeam.voipaccount || callQueuesAndTeam.real_id,
            staticClass: "dialer-row-select"
          }, [_c('td', {
            staticClass: "dialer-row-title onlyOneTD"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('Info', {
            attrs: {
              "id": callQueuesAndTeam.voipaccount || callQueuesAndTeam.real_id,
              "is_suspended_show": "",
              "is_blf": false
            }
          }), _c('div', {
            staticClass: "OwnertaggedVersion ml-16px mb-0"
          }, [_vm._v(" " + _vm._s(callQueuesAndTeam.object == "Teams" ? callQueuesAndTeam.object : 'Call queue') + " ")])], 1)])]);
        });
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign mr-2",
            staticStyle: {
              "height": "35px",
              "width": "35px"
            }
          }), _c('div', {
            staticClass: "d-flex flex-column justify-content-between"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "16px",
              "width": "60px"
            }
          }), _c('div', {
            staticClass: "latestShimmerDesign mt-1",
            staticStyle: {
              "height": "16px",
              "width": "30px"
            }
          })])])]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "20px",
              "width": "60px"
            }
          })])]);
        });
      },
      proxy: true
    }, {
      key: "no-record",
      fn: function () {
        return [_c('div', {
          staticClass: "latestGreyBox-9-9-2023 mt-20px mb-32px"
        }, [_c('div', {
          staticClass: "emptyScreenContainer"
        }, [_c('img', {
          staticClass: "mt-50px",
          attrs: {
            "width": "398px",
            "height": "149px",
            "src": require('@/assets/images/emptyScreenImages/groups2.png')
          }
        }), _c('div', {
          staticClass: "emptyScreenContainer-heading mt-50px"
        }, [_vm._v(" Not Part of Any Teams or Queues Yet ")]), _c('div', {
          staticClass: "emptyScreenContainer-text w-75"
        }, [_vm._v(" Looks like you're flying solo for now! No groups, no queues – just you, the lone ranger of productivity! But hey, if you're feeling like you're missing out on the team fun, nudge your administrator to put you into a group. ")]), _c('button', {
          staticClass: "newButton mt-20px"
        }, [_c('vb-icon', {
          attrs: {
            "icon": "squared-adminUser-icon",
            "height": "38px",
            "width": "38px"
          }
        }), _c('span', {
          staticClass: "newButton-textPart"
        }, [_vm._v("Request Admin")])], 1)])])];
      },
      proxy: true
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Name")])])])])], 2)], 1), _vm.organizationLength > 1 ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row somethingInside"
  }, [_c('Organizations', {
    staticClass: "w-100 p-0"
  })], 1)]) : _vm._e()]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Notifications")]), _c('NotificationSettingsWidget', {
    attrs: {
      "accountcode": _vm.voipaccount
    }
  })], 1), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "mr-16px"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main mb-16px"
  }, [_vm._v("Dedicated numbers")]), _vm.response.did_assigned.length > 0 ? _c('div', {
    staticClass: "d-flex IVR-numbers-container flex-wrap"
  }, _vm._l(_vm.response.did_assigned, function (did) {
    return _c('div', {
      key: did.did,
      staticClass: "newIVRLayout-number-item"
    }, [_c('img', {
      staticClass: "country-img",
      attrs: {
        "width": "45px",
        "src": _vm._f("flag_icon")(_vm._f("number_formater")(did.did))
      }
    }), _c('span', {
      staticClass: "newIVRLayout-number-item-text"
    }, [_vm._v(_vm._s(_vm._f("number_formater")(did.did)))])]);
  }), 0) : [_c('p', {
    staticClass: "dialer-box-text highlight mb-0"
  }, [_vm._v("No numbers assigned")])]], 2)]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row border-0"
  }, [_vm._m(7), _c('button', {
    staticClass: "newButton ml-5",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.is_view = !_vm.conditions.is_view;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": _vm.conditions.is_view ? 'squared-hide-icon' : 'squared-show-icon',
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_c('span', [_vm._v(_vm._s(_vm.conditions.is_view ? 'Hide' : 'Show'))])])], 1)]), _c('b-collapse', {
    staticClass: "mt-20px",
    attrs: {
      "visible": _vm.conditions.is_view
    }
  }, [_c('div', {
    staticClass: "mb-28px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row somethingInside pt-0"
  }, [_c('CallForwardingSetting', {
    attrs: {
      "account": _vm.voipaccount
    },
    on: {
      "cf-updated": function ($event) {
        return _vm.$emit('cf-updated');
      }
    }
  })], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row somethingInside pt-0 dialer_box_text_my-0"
  }, [_c('ScheduleSetting', {
    attrs: {
      "account": _vm.voipaccount
    },
    on: {
      "ofh-days-updated": function ($event) {
        return _vm.$emit('ofh-days-updated');
      },
      "ofh-default-updated": function ($event) {
        return _vm.$emit('ofh-default-updated');
      },
      "ofh-toggle-updated": function ($event) {
        return _vm.$emit('ofh-toggle-updated');
      }
    }
  })], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row somethingInside dialer_box_text_my-0"
  }, [_c('MusicOnHoldWidget', {
    attrs: {
      "accountcode": _vm.voipaccount
    }
  })], 1), _c('VoicemailSetting', {
    staticClass: "VoicemailSetting-insideTeamsModal makeGreyBoxTransparent",
    attrs: {
      "accountcode": _vm.voipaccount
    }
  })], 1)])], 1)]), _c('DevicesLatestSettings'), _c('IntegrationsSettings')], 1)], _c('ProfileImageUploader', {
    attrs: {
      "modalName": `${_vm._uid}-UserProfileImageUploader`
    },
    on: {
      "image-uploaded": function ($event) {
        '';
      }
    }
  }), _c('ChangePasswordModal', {
    attrs: {
      "modalName": `${_vm._uid}-ChangePasswordModal`
    }
  })], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "settingsSecondSection"
  }, [_c('span', {
    staticClass: "dialer-settings-intro newerTextVersion mt-0 mb-0"
  }, [_c('span', [_vm._v("Tailor your profile to suit your preferences.")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Dark mode")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Easy on the eyes, and perfect for low-light settings")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("International format")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Enabling this option will display numbers in international format, ensuring clear communication across borders.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Call waiting")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("If enabled, you will be able to receive multiple incoming calls at the same time.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Two factor authentication")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Enhance security through two-factor authentication for an added layer of account protection")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main mt-24px"
  }, [_vm._v("Trusted devices")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Below are the trusted devices exempt from 2FA for login. All other devices will require 2FA for enhanced security.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main mt-24px"
  }, [_vm._v("Groups")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("View all teams within this organization from this section.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mr-16px"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("User based call routing")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  })]);

}]

export { render, staticRenderFns }