<template>
  <div class="crm-main-section DealsSection">
    <TopBar :title="'Deals'">
      <button class="crm-button listGridView-btn" :class="{active: !design.deals.grid}" @click="design.deals.grid=false">
        <vb-icon class="crm-listView-icon" icon="crm-listView-icon" />
      </button>
      <button class="crm-button listGridView-btn centerBtn" :class="{active: design.deals.grid}" @click="design.deals.grid=true">
        <vb-icon class="crm-gridView-icon" icon="crm-gridView-icon" />
      </button>
      <button class="crm-button" @click="$modal.show('CreateDeal')">
        <vb-icon class="crm-plusButton-icon" icon="crm-plusButton-icon" />
        Create Deal
      </button>
    </TopBar>
    <div class="crm-main-section-body">
      <div class="d-flex justify-content-between">
        <DealsByStageGraphWidget />
        <WonDealsByDayGraphWidget />
      </div>
      <div class="w-100 d-flex justify-content-between align-items-center mb-3">
        <div class="d-flex">
          <b-dropdown class="crm-dd-black-container" no-caret  >
            <template #button-content>
              <span class="d-flex align-items-center p-0">
                <template v-if="selectedDealFilter">
                  {{ selectedDealFilter.text }}
                </template>
                <template v-else>
                  <vb-icon icon="crm-filterOptions-icon" class="crm-filterOptions-icon"/>
                  <span>Filter</span>
                </template>
              </span>
            </template>
            <b-dropdown-item v-for="filter in dealFilters" :key="filter.value" @click="selectFilter(filter.value)" >
              <vb-svg 
                v-if="filters.deals.filter_type==filter.value"
                name="dialer-tick" 
                width="10.5" 
                height="7.584" 
                viewBox="0 0 10.5 7.584" 
                stroke-width="0" 
                stroke="#005DFF" 
                fill="none" 
                stroke-linecap="round" 
                stroke-linejoin="round" 
              />
              <span> {{ filter.text }} </span>
            </b-dropdown-item>
          </b-dropdown>
          <button class="crm-button ml-2">
            <vb-icon icon="crm-pencil-icon" height="16px" width="16px"/>
          </button>
        </div>
        <div class="crm-input-container mb-0">
          <input placeholder="Search" class="w-100" v-model="filters.deals.search" @input="paginations.deals.page=1;fetchDealsDebounce();" />
          <vb-icon icon="crm-inputSearch-icon" />
        </div>
        <div class="totalsTextOnMain">
          {{ response.summery | get_property('value',0) }} - {{ response.summery | get_property('count',0) }} deals
        </div>
      </div>
      <vb-table 
        class="mt-3" 
        :isListEmpty="response.deals.length==0" 
        :listLength="paginations.deals.total" 
        textNoRecord="There is no record"
        :perPage="paginations.deals.per_page"
        :page="paginations.deals.page"
        @page-changed="paginations.deals.page=$event;fetchDeals();"
        @per-page-changed="paginations.deals.per_page=$event;paginations.deals.page=1;fetchDeals();"
        :loading="api.fetch_deals.send"
      >
        <template slot="header">
          <tr>
            <th>
              <b-form-checkbox class="crm-checkbox" v-model="allSelected" />
            </th>
            <th class="dialer-has-sort">
              <span class="dialer-sorted-by ascending" @click="changeOrder('name')">
                Deal name
                <template v-if="filters.deals.orders.find(i=>i.attribute=='name')">
                  <b-icon v-if="filters.deals.orders.find(i=>i.attribute=='name').direction=='asc'" icon="caret-up-fill" />
                  <b-icon v-else icon="caret-down-fill" />
                </template>
              </span>
            </th>
            <th class="dialer-has-sort">
              <span @click="changeOrder('stage')">
                stage
                <template v-if="filters.deals.orders.find(i=>i.attribute=='stage')">
                  <b-icon v-if="filters.deals.orders.find(i=>i.attribute=='stage').direction=='asc'" icon="caret-up-fill" />
                  <b-icon v-else icon="caret-down-fill" />
                </template>
              </span>
            </th>
            <th class="dialer-has-sort">
              <span @click="changeOrder('amount')">
                amount
                <template v-if="filters.deals.orders.find(i=>i.attribute=='amount')">
                  <b-icon v-if="filters.deals.orders.find(i=>i.attribute=='amount').direction=='asc'" icon="caret-up-fill" />
                  <b-icon v-else icon="caret-down-fill" />
                </template>
              </span>
            </th>
            <th class="dialer-has-sort">
              <span @click="changeOrder('amount')">
                expected close date
                <template v-if="filters.deals.orders.find(i=>i.attribute=='expected_close_date')">
                  <b-icon v-if="filters.deals.orders.find(i=>i.attribute=='expected_close_date').direction=='asc'" icon="caret-up-fill" />
                  <b-icon v-else icon="caret-down-fill" />
                </template>
              </span>
            </th>
            <th class="dialer-has-sort">
              <span @click="changeOrder('status')">
                status
                <template v-if="filters.deals.orders.find(i=>i.attribute=='status')">
                  <b-icon v-if="filters.deals.orders.find(i=>i.attribute=='status').direction=='asc'" icon="caret-up-fill" />
                  <b-icon v-else icon="caret-down-fill" />
                </template>
              </span>
            </th>
            <th class="dialer-col-right cursor_pointer">
              <span @click="changeOrder('created_at')">
                CREATED AT
                <template v-if="filters.deals.orders.find(i=>i.attribute=='created_at')">
                  <b-icon v-if="filters.deals.orders.find(i=>i.attribute=='created_at').direction=='asc'" icon="caret-up-fill" />
                  <b-icon v-else icon="caret-down-fill" />
                </template>
              </span>
            </th>
          </tr>
        </template>
        <template #body>
          <tr v-for="data in response.deals" :key="data.id">
            <td>
              <b-form-checkbox 
                class="crm-checkbox" 
                :checked="!!selected.deals[data.id]" 
                @change="$event ? $set(selected.deals,data.id,data) : $delete(selected.deals,data.id)" 
              />
            </td>
            <td class="dialer-row-title">
              <span>{{ data | get_property('name') }}</span>
            </td>
            <td class="dialer-row-title">{{ data | get_property('stage.name') }}</td>
            <td class="dialer-row-title">{{ data | get_property('amount') }}</td>
            <td class="dialer-row-title">{{ data | get_property('expected_close_date') | filter_date_current }}</td>
            <td class="dialer-row-title">
              <span class="tagTypeDesign">{{ data.status }}</span>
            </td>
            <td class="dialer-col-right">{{ data | get_property('created_at') | filter_date_current }}</td>
          </tr>
        </template>
      </vb-table>
    </div>
    <CreateDealModal @created="fetchDealsDebounce()" />
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios'
import { $fn, VOIP_API } from '@/utils';
import CreateDealModal from '../Modals/crm/deals/CreateDeal.vue';
import WonDealsByDayGraphWidget from './widgets/deals/WonDealsByDayGraph.vue';
import DealsByStageGraphWidget from './widgets/deals/DealsByStageGraph.vue';
import TopBar from './widgets/TopBar.vue';
let filters = [
  {
    text: 'Deals Created This Month',
    value: 'deals-created-this-month',
    rules: [
      {
        type: 'rule',
        query: {
          type: 'date',
          rule: 'created_at',
          operator: 'is',
          value: 'this_month'
        },
      }
    ],
  },
  {
    text: 'Lost Deals',
    value: 'lost-deals',
    rules: [
      {
        type: 'rule',
        query: {
          type: 'select',
          rule: 'status',
          operator: 'equal',
          value: 'lost'
        },
      }
    ],
  },
  {
    text: 'My Deals',
    value: 'my-deals',
    rules: [
      {
        type: 'rule',
        query: {
          type: 'select',
          rule: 'user_id',
          operator: 'equal',
          value: 'me'
        },
      }
    ],
  },
  {
    text: 'My Recently Assigned Deals',
    value: 'my-recently-assigned-deals',
    rules: [
      {
        type: 'rule',
        query: {
          type: 'select',
          rule: 'user_id',
          operator: 'equal',
          value: 'me'
        },
      },
      {
        type: 'rule',
        query: {
          type: 'date',
          rule: 'owner_assigned_date',
          operator: 'is',
          value: 'this_month'
        },
      }
    ],
  },
  {
    text: 'Open Deals',
    value: 'open-deals',
    rules: [
      {
        type: 'rule',
        query: {
          type: 'select',
          rule: 'status',
          operator: 'equal',
          value: 'open'
        },
      },
    ],
  },
  {
    text: 'Won Deals',
    value: 'won-deals',
    rules: [
      {
        type: 'rule',
        query: {
          type: 'select',
          rule: 'status',
          operator: 'equal',
          value: 'won'
        },
      },
    ],
  }
]
export default {
  name: 'Deals',
  components: { 
    CreateDealModal,
    WonDealsByDayGraphWidget,
    DealsByStageGraphWidget,
    TopBar 
  },
  inject:['isEmpty','appNotify'],
  data(){
    return {
      api: {
        fetch_deals: this.$helperFunction.apiInstance({ 
          source: true, 
        }),
      },
      response: {
        deals: [],
        summery: {},
      },
      selected: {
        deals: {},
      },
      filters: {
        deals: {
          search: '',
          orders: [
            {
              attribute: 'created_at',
              direction: 'desc',
            }
          ],
          rules: {
            condition: 'and',
            children: [],
          },
          filter_type: '',
        },
      },
      design: {
        deals: {
          grid: false,
        },
      },
      paginations: {
        deals: {
          per_page: 5,
          page: 1,
          total: 0,
        },
      },
    }
  },
  computed: {
    allSelected: {
      get(){
        return this.response.deals.every(i=>!!this.selected.deals[i.id])
      },
      set(value){
        if(value) {
          this.selected.deals={
            ...this.selected.deals,
            ..._.keyBy(this.response.deals, 'id'),
          };
        } else {
          this.selected.deals={}
        }
      },
    },
    dealFilters(){ return filters },
    selectedDealFilter(){ return this.dealFilters.find(i=>i.value==this.filters.deals.filter_type) },
  },
  methods: {
    selectFilter(value){
      const filter = this.dealFilters.find(i=>i.value==value)
      this.filters.deals.filter_type=filter.value
      this.filters.deals.rules.children=filter.rules
      this.fetchDealsDebounce()
    },
    changeOrder(attribute){
      const index = this.filters.deals.orders.findIndex(i=>i.attribute==attribute)
      if(index>-1) {
        if(this.filters.deals.orders[index].direction=='asc') this.filters.deals.orders[index].direction='desc'
        else this.filters.deals.orders.splice(index,1)
      } else {
        this.filters.deals.orders.push({
          attribute: attribute,
          direction: 'asc',
        })
      }
      this.fetchDealsDebounce()
    },
    fetchDealsDebounce: _.debounce(function(){
      this.fetchDeals();
    }, 1*1000),
    
    async fetchDeals(){
      this.api.fetch_deals.source?.cancel?.("Requesting Again")
      this.api.fetch_deals.source=axios.CancelToken.source()
      function getValues(object,array){
        let result = {}
        for (let index = 0; index < array.length; index++) {
          const attribute = array[index]
          result[attribute] = _.get(object,attribute)
        }
        return result
      }
      let object = {
        rules: this.filters.deals.rules,
        order: this.filters.deals.orders
      } 
      const params = getValues(object,[...$fn.getLeaves(this.filters.deals.orders,'order'),...$fn.getLeaves(this.filters.deals.rules,'rules')])
      try {
        this.api.fetch_deals.send=true
        const { data: { data: deals, meta } } = await VOIP_API.endpoints.crm.deals.listTable({
          q: this.filters.deals.search,
          per_page: this.paginations.deals.per_page,
          page: this.paginations.deals.page,
          ...params,
        },this.api.fetch_deals.source.token)
        this.response.deals=deals
        this.response.summery=meta?.summary ?? {}
        this.paginations.deals.total=meta.total ?? 0

      } catch (ex) {
        if(!ex.cancel){
          this.appNotify({
            message: ex.own_message,
            type: 'danger',
          })
        }
      } finally {
        this.api.fetch_deals.send=false
        this.api.fetch_deals.source=null
      }
    },
  },
  mounted(){
    this.fetchDeals()
  },
}
</script>

<style>

</style>