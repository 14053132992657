import { AUTH_CRM_API_ENDPOINTS } from "./auth"
import { CARDS_CRM_API_ENDPOINTS } from "./cards"
import { COMPANIES_CRM_API_ENDPOINTS } from "./companies"
import { CONTACTS_CRM_API_ENDPOINTS } from "./contacts"
import { DEALS_CRM_API_ENDPOINTS } from "./deals"
import { PIPELINES_CRM_API_ENDPOINTS } from "./piplines"


export const CRM_API_ENDPOINTS = {
  auth: AUTH_CRM_API_ENDPOINTS,
  deals: DEALS_CRM_API_ENDPOINTS,
  contacts: CONTACTS_CRM_API_ENDPOINTS,
  companies: COMPANIES_CRM_API_ENDPOINTS,
  pipelines: PIPELINES_CRM_API_ENDPOINTS,
  cards: CARDS_CRM_API_ENDPOINTS
}