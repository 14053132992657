var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex flex-column align-items-start mr-20px"
  }, [_c('div', {
    staticClass: "lightGreyText-labelType"
  }, [_vm._v("Task duration")]), _c('div', {
    staticClass: "mt-12px d-flex align-items-center"
  }, [_c('b-button', {
    staticClass: "p-0",
    attrs: {
      "variant": "link",
      "disabled": _vm.api.update_status.send
    },
    on: {
      "click": function ($event) {
        return _vm.updateStatus();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": _vm.conditions.start ? 'taskduration-stop-icon' : 'taskduration-start-icon',
      "height": "45px",
      "width": "45px"
    }
  })], 1), _vm.conditions.start ? _c('p', {
    staticClass: "lightGreyText-labelType mb-0 ml-16px"
  }, [_vm._v(_vm._s(_vm._f("duration_format")(_vm.secs)))]) : _vm._e()], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }