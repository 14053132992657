
import { VOIP_API } from "../index"

export const INTEGRATIONS_API_ENDPOINTS = {
  list(params={}){
    return VOIP_API.axios.voip.request({
      url: `integration`,
      method: 'GET',
      params: {
        action: 'get_integration',
        ...params,
      },
    })
  },
  integratedlist(params={}){
    return VOIP_API.axios.voip.request({
      url: `integration`,
      method: 'GET',
      params: {
        action: 'get_user_integration',
        ...params,
      },
    })
  },
  deleteintegration(params={}){
    return VOIP_API.axios.voip.request({
      url: `integration`,
      method: 'GET',
      params: {
        action: 'delete_user_integration',
        ...params,
      },
    })
  },
  changeSync(data={}){
    return VOIP_API.axios.voip.request({
      url: `integration-sync`,
      method: 'POST',
      data,
    })
  },
}