var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-800 ChangePlaylistMusicOnHoldModal",
    attrs: {
      "width": "80%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-assign-to dialer-edit"
  }, [_vm.getIsMobile ? _c('div', {
    staticClass: "dialer-edit-header flex-column"
  }, [_c('div', {
    staticClass: "d-flex w-100 justify-content-between align-items-center mb-2"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Playlist files")]), _vm.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        _vm.api.set_default.send ? '' : _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _vm._e()], 1)]) : _c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Playlist files")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": _vm.api.set_default.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart position-relative d-flex justify-content-center"
  }, [_vm.api.set_default.send ? _c('vb-spinner') : [_vm._v("Done")]], 2)], 1)])]), _c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again hasSoundFiles",
    attrs: {
      "textNoRecord": " ",
      "isListEmpty": _vm.playlists.length == 0,
      "listLength": _vm.playlists.length,
      "loading": _vm.api.playlists.send
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.playlists, function (playlist, index) {
          return _c('MOHItem', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: playlist.id,
            attrs: {
              "playlist": playlist
            },
            scopedSlots: _vm._u([{
              key: "right-end",
              fn: function () {
                return [_c('b-button', {
                  staticClass: "p-0",
                  attrs: {
                    "disabled": _vm.api.set_default.send,
                    "variant": "link"
                  },
                  on: {
                    "click": function ($event) {
                      _vm.selected.playlist_id = playlist.id;
                      _vm.setDefault();
                    }
                  }
                }, [_c('div', {
                  class: `actLikeSquareRadio checkboxVersion ${_vm.selected.playlist_id == playlist.id ? 'checked' : ''}`
                })])];
              },
              proxy: true
            }], null, true)
          });
        });
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "-webkit-fill-available"
            }
          })]), _c('td', [_c('div', {
            staticClass: "d-flex align-items-center w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "64px",
              "width": "250px"
            }
          }), _c('div', {
            staticClass: "latestShimmerDesign flex-1 ml-2",
            staticStyle: {
              "height": "21px",
              "width": "40px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "-webkit-fill-available"
            }
          })])]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "48px",
              "width": "48px"
            }
          })])]);
        });
      },
      proxy: true
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort wd-30"
  }, [_vm._v("Playlist name")]), _c('th', {
    staticClass: "dialer-has-sort w-50"
  }, [_vm._v("Files")])])])], 2)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }