import { render, staticRenderFns } from "./OutgoingCalls.vue?vue&type=template&id=93b50c1a&scoped=true&"
import script from "./OutgoingCalls.vue?vue&type=script&lang=js&"
export * from "./OutgoingCalls.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93b50c1a",
  null
  
)

export default component.exports