var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-main-section DocumentSection"
  }, [_c('div', {
    staticClass: "crm-main-section-topBar"
  }, [_c('h2', {
    staticClass: "crm-main-section-heading mb-0"
  }, [_vm._v(" Documents ")]), _c('div', {
    staticClass: "section-topBar-middlePart"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-inputSearch-icon"
    }
  }), _c('input', {
    attrs: {
      "type": "text",
      "placeholder": "Search"
    }
  })], 1), _c('div', {
    staticClass: "section-topBar-lastPart"
  }, [_c('button', {
    staticClass: "crm-button listGridView-btn centerBtn ml-0"
  }, [_c('vb-icon', {
    staticClass: "crm-threeDotsHorizontal-icon",
    attrs: {
      "icon": "crm-threeDotsHorizontal-icon"
    }
  })], 1), _c('button', {
    staticClass: "crm-button"
  }, [_c('vb-icon', {
    staticClass: "crm-plusButton-icon",
    attrs: {
      "icon": "crm-plusButton-icon"
    }
  }), _vm._v(" Create Documents ")], 1)])]), _c('div', {
    staticClass: "crm-main-section-body"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "crm-card halfWidth"
  }, [_c('div', {
    staticClass: "crm-card-heading"
  }, [_c('div', [_vm._v(" Sent documents by day "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-dropdown', {
    staticClass: "crm-dd-container mr-3",
    attrs: {
      "id": "sound-setting-micro-devices",
      "no-caret": "",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("Sales Pipeline")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('span', [_vm._v(" All ")])]), _c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" Sales Pipeline ")])], 1)], 1), _c('b-dropdown', {
    staticClass: "crm-dd-container",
    attrs: {
      "id": "sound-setting-micro-devices",
      "no-caret": "",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("This week")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" This week ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" This Month ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Week ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Month ")])])], 1)], 1)]), _c('div', {
    staticClass: "crm-graph-container"
  }, [_vm._v(" put graph here ")])]), _c('div', {
    staticClass: "crm-card halfWidth"
  }, [_c('div', {
    staticClass: "crm-card-heading"
  }, [_c('div', [_vm._v(" Documents by status "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-dropdown', {
    staticClass: "crm-dd-container",
    attrs: {
      "id": "sound-setting-micro-devices",
      "no-caret": "",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v("This week")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" This week ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" This Month ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Week ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Month ")])])], 1)], 1)]), _c('div', {
    staticClass: "crm-graph-container"
  }, [_vm._v(" put graph here ")])])]), _c('div', {
    staticClass: "w-100 d-flex align-items-center mb-3"
  }, [_c('button', {
    staticClass: "crm-colored-btn draft mr-2"
  }, [_c('vb-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "crm-draft-btn-icon",
      "font-scale": "1.3"
    }
  }), _vm._v(" Draft ")], 1), _c('button', {
    staticClass: "crm-colored-btn sent mr-2"
  }, [_c('vb-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "crm-sent-btn-icon",
      "font-scale": "1.3"
    }
  }), _vm._v(" Sent ")], 1), _c('button', {
    staticClass: "crm-colored-btn accepted mr-2"
  }, [_c('vb-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "crm-accepted-btn-icon",
      "font-scale": "1.3"
    }
  }), _vm._v(" Accepted ")], 1), _c('button', {
    staticClass: "crm-colored-btn lost mr-2"
  }, [_c('vb-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "crm-x-icon",
      "font-scale": "1.3"
    }
  }), _vm._v(" Lost ")], 1)]), _c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center mb-3"
  }, [_c('div', {
    staticClass: "w-100 d-flex"
  }, [_c('b-dropdown', {
    staticClass: "crm-dd-black-container",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('vb-icon', {
          staticClass: "crm-filterOptions-icon",
          attrs: {
            "icon": "crm-filterOptions-icon"
          }
        }), _c('span', [_vm._v("Filter")])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" This week ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" This Month ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Week ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" Next Month ")])])], 1), _c('button', {
    staticClass: "crm-button ml-2"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-pencil-icon",
      "height": "16px",
      "width": "16px"
    }
  })], 1)], 1), _c('div', {
    staticClass: "crm-input-container mb-0 mr-3"
  }, [_c('input', {
    staticClass: "w-100",
    attrs: {
      "placeholder": "Search"
    }
  }), _c('vb-icon', {
    attrs: {
      "icon": "crm-inputSearch-icon"
    }
  })], 1)]), _c('vb-table', {
    staticClass: "mt-3",
    attrs: {
      "isListEmpty": _vm.isEmpty(_vm.tableData),
      "listLength": _vm.tableData.length,
      "textNoRecord": " "
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.tableData, function (data, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: data.id
          }, [_c('td', [_c('b-form-checkbox', {
            staticClass: "crm-checkbox",
            attrs: {
              "id": "checkbox-1"
            },
            model: {
              value: data.chk,
              callback: function ($$v) {
                _vm.$set(data, "chk", $$v);
              },
              expression: "data.chk"
            }
          })], 1), _c('td', {
            staticClass: "dialer-row-title"
          }, [_c('span', [_vm._v(_vm._s(data.m1))])]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_c('span', {
            staticClass: "tagTypeDesign"
          }, [_vm._v(_vm._s(data.m1))])]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_c('span', {
            staticClass: "tagTypeDesign"
          }, [_vm._v(_vm._s(data.m1))])]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(data.m1))]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_vm._v(" " + _vm._s(data.m2) + " ")])]);
        });
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', [_c('b-form-checkbox', {
    staticClass: "crm-checkbox",
    attrs: {
      "id": "checkbox-1"
    }
  })], 1), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    staticClass: "dialer-sorted-by ascending"
  }, [_vm._v("Document Title")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Document Type")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("status")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("owner")])]), _c('th', {
    staticClass: "dialer-col-right cursor_pointer"
  }, [_c('span', [_vm._v("Amount")])])])])], 2)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }