var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup AudioSettingsModal v2",
    attrs: {
      "width": "40%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-close": _vm.onBeforeClose
    }
  }, [_c('div', {
    staticClass: "dialer-soundSetting-container"
  }, [_c('div', {
    staticClass: "dialer-soundSetting dialer-soundSetting-responsive"
  }, [_c('div', {
    staticClass: "dialer-edit-header d-block pb-0"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Communication device and alert")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v("Set up your audio and video devices, and personalize your ringtone and alert sounds.")])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start",
    attrs: {
      "web-dashboard-media-setting-microphone": ""
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100 testButtonsContainer-parent"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-fill pr-4"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Microphone")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("When you are speaking to a caller, which microphone do you want to use?")])]), _c('div', {
    staticClass: "testButtonsContainer justify-content-end"
  }, [_c('div', {
    staticClass: "dialer-soundSetting-testing-audio dialer-soundSetting-testing-call",
    on: {
      "click": function ($event) {
        _vm.isEmpty(_vm.testCallSession) ? _vm.testCall() : _vm.$store.state.sip.phone.activeSession.hangup();
      }
    }
  }, [_c('vb-svg', {
    attrs: {
      "name": "dialer-outlineCall-icon",
      "width": "18.801",
      "stroke": "",
      "height": "18.802",
      "viewBox": "0 0 18.801 18.802",
      "fill": "none",
      "stroke-width": "0",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', {
    staticClass: "dialer-soundSetting-testing-audio-text"
  }, [_vm._v(" " + _vm._s(_vm.isEmpty(_vm.testCallSession) ? "Make a free test call" : "End call") + " ")])], 1)])]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion optionAndButtonIsdotted addScrollToMenu withCustomSvgCheck mt-12px w-100 mb-0"
  }, [_c('b-dropdown', {
    attrs: {
      "id": "sound-setting-micro-devices"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', [_vm._v(_vm._s(_vm._f("get_property")(_vm.media_devices_setting.selectedMicDevice, 'label', 'Select device')))]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })];
      },
      proxy: true
    }])
  }, _vm._l(_vm.media_devices_setting.audioinputs, function (device) {
    return _c('b-dropdown-item', {
      key: device.deviceId,
      on: {
        "click": function ($event) {
          _vm.media_devices_setting.micId = device.deviceId;
        }
      }
    }, [device.deviceId == _vm.media_devices_setting.mic_id ? _c('vb-svg', {
      attrs: {
        "name": "dialer-tick",
        "width": "10.5",
        "height": "7.584",
        "viewBox": "0 0 10.5 7.584",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }) : _vm._e(), _c('span', [_vm._v(" " + _vm._s(device.label) + " ")])], 1);
  }), 1)], 1), _vm.media_devices_setting.mic_id ? _c('MicrophoneTester', {
    staticClass: "mt-4 w-100",
    attrs: {
      "deviceId": _vm.media_devices_setting.mic_id
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start",
    attrs: {
      "web-dashboard-media-setting-speaker": ""
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100 testButtonsContainer-parent"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-fill pr-4"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Speaker")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("When you are talking to a caller, which speaker do you want to use?")])]), _c('div', {
    staticClass: "testButtonsContainer justify-content-end"
  }, [_c('div', {
    staticClass: "dialer-soundSetting-testing-audio",
    on: {
      "click": function ($event) {
        _vm.is_play = _vm.is_play == _vm.audioTypes.speaker ? '' : _vm.audioTypes.speaker;
      }
    }
  }, [_c('vb-svg', {
    attrs: {
      "id": "toggle-play",
      "name": _vm.is_play != _vm.audioTypes.speaker ? 'dialer-outlinePlay-icon' : 'dialer-pause-arrowhead',
      "width": "16",
      "stroke": "",
      "height": "18.035",
      "viewBox": "0 0 16 18.035",
      "fill": "none",
      "stroke-width": "0",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', {
    staticClass: "dialer-soundSetting-testing-audio-text"
  }, [_vm._v(" Test audio ")])], 1)])]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion optionAndButtonIsdotted addScrollToMenu withCustomSvgCheck mt-12px w-100 mb-0"
  }, [_c('b-dropdown', {
    attrs: {
      "id": "sound-setting-micro-devices",
      "text": "Select device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', [_vm._v(" " + _vm._s(_vm._f("get_property")(_vm.media_devices_setting.selectedSpeakerDevice, 'label', 'Select device')) + " ")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })];
      },
      proxy: true
    }])
  }, _vm._l(_vm.media_devices_setting.audiooutputs, function (device) {
    return _c('b-dropdown-item', {
      key: device.deviceId,
      on: {
        "click": function ($event) {
          _vm.media_devices_setting.speakerId = device.deviceId;
        }
      }
    }, [_vm.media_devices_setting.speaker_id == device.deviceId ? _c('vb-svg', {
      attrs: {
        "name": "dialer-tick",
        "width": "10.5",
        "height": "7.584",
        "viewBox": "0 0 10.5 7.584",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }) : _vm._e(), _c('span', [_vm._v(_vm._s(device.label))])], 1);
  }), 1)], 1), _c('div', {
    staticClass: "SpeakerProgressContainer w-100"
  }, [_c('input', {
    attrs: {
      "min": "0",
      "max": "100",
      "type": "range",
      "step": "1"
    },
    domProps: {
      "value": _vm.media_devices_setting.speaker_volume
    },
    on: {
      "change": function ($event) {
        _vm.media_devices_setting.speakerVolume = $event.target.value;
      }
    }
  }), _c('audio', {
    attrs: {
      "show": false,
      "id": "testing-audio",
      "src": require('@/assets/sounds/incoming.mp3')
    },
    on: {
      "ended": function ($event) {
        _vm.is_play = '';
      }
    }
  })])])]), _c('div', {
    staticClass: "mt-20px",
    attrs: {
      "web-dashboard-media-setting-video": ""
    }
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-fill pr-4"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Video")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("When you are talking to a caller, which camera do you want to use?")])])]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion optionAndButtonIsdotted addScrollToMenu withCustomSvgCheck mt-12px w-100 mb-0"
  }, [_c('b-dropdown', {
    attrs: {
      "id": "sound-setting-micro-devices",
      "text": "Select device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', [_vm._v(" " + _vm._s(_vm._f("get_property")(_vm.media_devices_setting.selectedCameraDevice, 'label', 'Select device')) + " ")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })];
      },
      proxy: true
    }])
  }, _vm._l(_vm.media_devices_setting.videoinputs, function (device) {
    return _c('b-dropdown-item', {
      key: device.deviceId,
      on: {
        "click": function ($event) {
          _vm.media_devices_setting.cameraId = device.deviceId;
        }
      }
    }, [_vm.media_devices_setting.camera_id == device.deviceId ? _c('vb-svg', {
      attrs: {
        "name": "dialer-tick",
        "width": "10.5",
        "height": "7.584",
        "viewBox": "0 0 10.5 7.584",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }) : _vm._e(), _c('span', [_vm._v(" " + _vm._s(device.label) + " ")])], 1);
  }), 1)], 1), _c('div', {
    staticClass: "cameraContainer latestGreyBox-9-9-2023 w-100 d-flex justify-content-center"
  }, [_vm.media_devices_setting.camera_id ? _c('CameraTester', {
    attrs: {
      "deviceId": _vm.media_devices_setting.camera_id
    }
  }) : [_c('div', {
    staticClass: "d-flex w-100 justify-content-center"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/cameraPlaceholder.png")
    }
  })])]], 2)])])]), _c('div', {
    staticClass: "mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start",
    attrs: {
      "web-dashboard-media-setting-ringing-device": ""
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-fill pr-4"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Ringing device")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Which speaker do you want to use to hear incoming calls ringing?")])])]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion optionAndButtonIsdotted addScrollToMenu withCustomSvgCheck mt-12px w-100 mb-0"
  }, [_c('b-dropdown', {
    attrs: {
      "id": "sound-setting-micro-devices",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', [_vm._v(" " + _vm._s(_vm._f("get_property")(_vm.media_devices_setting.selectedRingingSpeakerDevice, 'label', 'Select device')) + " ")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })];
      },
      proxy: true
    }])
  }, _vm._l(_vm.media_devices_setting.audiooutputs, function (device) {
    return _c('b-dropdown-item', {
      key: device.deviceId,
      on: {
        "click": function ($event) {
          _vm.media_devices_setting.ringingSpeakerId = device.deviceId;
        }
      }
    }, [_vm.media_devices_setting.ringing_speaker_id == device.deviceId ? _c('vb-svg', {
      attrs: {
        "name": "dialer-tick",
        "width": "10.5",
        "height": "7.584",
        "viewBox": "0 0 10.5 7.584",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }) : _vm._e(), _c('span', [_vm._v(_vm._s(device.label))])], 1);
  }), 1)], 1), _c('div', {
    staticClass: "SpeakerProgressContainer w-100"
  }, [_c('input', {
    attrs: {
      "min": "0",
      "max": "100",
      "type": "range",
      "step": "1"
    },
    domProps: {
      "value": _vm.media_devices_setting.ringing_speaker_volume
    },
    on: {
      "change": function ($event) {
        _vm.media_devices_setting.ringingSpeakerVolume = $event.target.value;
      }
    }
  })])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start",
    attrs: {
      "web-dashboard-media-setting-incoming-ringtone": ""
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-fill pr-4"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Incoming ringtone")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Choose your preferred ringtone here, you'll hear a preview when you've selected one")])]), _c('a', {
    staticClass: "newButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-IncommingRingtone`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change")])], 1)]), _c('div', {
    staticClass: "testButtonsContainer w-100 justify-content-between mt-20px"
  }, [_c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_c('b', [_vm._v(_vm._s(_vm._f("get_property")(_vm.media_devices_setting.selectedIncomingRingtone, 'label', 'Select ringtone')))])]), _c('audio', {
    attrs: {
      "show": false,
      "id": "incoming-ringtone-testing-audio",
      "src": _vm._f("get_property")(_vm.media_devices_setting.selectedIncomingRingtone, 'file')
    },
    on: {
      "ended": function ($event) {
        _vm.is_play = '';
      }
    }
  }), _c('div', {
    staticClass: "dialer-soundSetting-testing-audio",
    on: {
      "click": function ($event) {
        _vm.is_play = _vm.is_play == _vm.audioTypes.incoming_ringtone ? '' : _vm.audioTypes.incoming_ringtone;
      }
    }
  }, [_c('vb-svg', {
    attrs: {
      "id": "toggle-play",
      "name": _vm.is_play != _vm.audioTypes.incoming_ringtone ? 'dialer-outlinePlay-icon' : 'dialer-pause-arrowhead',
      "width": "16",
      "stroke": "",
      "height": "18.035",
      "viewBox": "0 0 16 18.035",
      "fill": "none",
      "stroke-width": "0",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', {
    staticClass: "dialer-soundSetting-testing-audio-text"
  }, [_vm._v(" Test audio ")])], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start",
    attrs: {
      "web-dashboard-media-setting-alert-ringtone": ""
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-fill pr-4"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Alert ringtone")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Choose your preferred ringtone here, you'll hear a preview when you've selected one")])]), _c('a', {
    staticClass: "newButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-AlertRingtone`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change")])], 1)]), _c('div', {
    staticClass: "testButtonsContainer w-100 justify-content-between mt-20px"
  }, [_c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_c('b', [_vm._v(_vm._s(_vm._f("get_property")(_vm.media_devices_setting.selectedAlertRingtone, 'label', 'Select ringtone')))])]), _c('audio', {
    attrs: {
      "show": false,
      "id": "alert-ringtone-testing-audio",
      "src": _vm._f("get_property")(_vm.media_devices_setting.selectedAlertRingtone, 'file')
    },
    on: {
      "ended": function ($event) {
        _vm.is_play = '';
      }
    }
  }), _c('div', {
    staticClass: "dialer-soundSetting-testing-audio",
    on: {
      "click": function ($event) {
        _vm.is_play = _vm.is_play == _vm.audioTypes.alert_ringtone ? '' : _vm.audioTypes.alert_ringtone;
      }
    }
  }, [_c('vb-svg', {
    attrs: {
      "id": "toggle-play",
      "name": _vm.is_play != _vm.audioTypes.alert_ringtone ? 'dialer-outlinePlay-icon' : 'dialer-pause-arrowhead',
      "width": "16",
      "stroke": "",
      "height": "18.035",
      "viewBox": "0 0 16 18.035",
      "fill": "none",
      "stroke-width": "0",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', {
    staticClass: "dialer-soundSetting-testing-audio-text"
  }, [_vm._v(" Test audio ")])], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start",
    attrs: {
      "web-dashboard-media-setting-alert-ringtone": ""
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-fill pr-4"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Send message ringtone")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Choose your preferred ringtone here, you'll hear a preview when you've selected one")])]), _c('a', {
    staticClass: "newButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-SendMessageRingtone`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change")])], 1)]), _c('div', {
    staticClass: "testButtonsContainer w-100 justify-content-between mt-20px"
  }, [_c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_c('b', [_vm._v(_vm._s(_vm._f("get_property")(_vm.media_devices_setting.selectedSendmessageRingtone, 'label', 'Select ringtone')))])]), _c('audio', {
    attrs: {
      "show": false,
      "id": "send-message-ringtone-testing-audio",
      "src": _vm._f("get_property")(_vm.media_devices_setting.selectedSendmessageRingtone, 'file')
    },
    on: {
      "ended": function ($event) {
        _vm.is_play = '';
      }
    }
  }), _c('div', {
    staticClass: "dialer-soundSetting-testing-audio",
    on: {
      "click": function ($event) {
        _vm.is_play = _vm.is_play == _vm.audioTypes.send_message_ringtone ? '' : _vm.audioTypes.send_message_ringtone;
      }
    }
  }, [_c('vb-svg', {
    attrs: {
      "id": "toggle-play",
      "name": _vm.is_play != _vm.audioTypes.send_message_ringtone ? 'dialer-outlinePlay-icon' : 'dialer-pause-arrowhead',
      "width": "16",
      "stroke": "",
      "height": "18.035",
      "viewBox": "0 0 16 18.035",
      "fill": "none",
      "stroke-width": "0",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', {
    staticClass: "dialer-soundSetting-testing-audio-text"
  }, [_vm._v(" Test audio ")])], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start",
    attrs: {
      "web-dashboard-media-setting-alert-ringtone": ""
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-fill pr-4"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Receive message ringtone")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Choose your preferred ringtone here, you'll hear a preview when you've selected one")])]), _c('a', {
    staticClass: "newButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-RecieveMessageRingtone`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change")])], 1)]), _c('div', {
    staticClass: "testButtonsContainer w-100 justify-content-between mt-20px"
  }, [_c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_c('b', [_vm._v(_vm._s(_vm._f("get_property")(_vm.media_devices_setting.selectedRecievemessageRingtone, 'label', 'Select ringtone')))])]), _c('audio', {
    attrs: {
      "show": false,
      "id": "recieve-message-ringtone-testing-audio",
      "src": _vm._f("get_property")(_vm.media_devices_setting.selectedRecievemessageRingtone, 'file')
    },
    on: {
      "ended": function ($event) {
        _vm.is_play = '';
      }
    }
  }), _c('div', {
    staticClass: "dialer-soundSetting-testing-audio",
    on: {
      "click": function ($event) {
        _vm.is_play = _vm.is_play == _vm.audioTypes.recieve_message_ringtone ? '' : _vm.audioTypes.recieve_message_ringtone;
      }
    }
  }, [_c('vb-svg', {
    attrs: {
      "id": "toggle-play",
      "name": _vm.is_play != _vm.audioTypes.recieve_message_ringtone ? 'dialer-outlinePlay-icon' : 'dialer-pause-arrowhead',
      "width": "16",
      "stroke": "",
      "height": "18.035",
      "viewBox": "0 0 16 18.035",
      "fill": "none",
      "stroke-width": "0",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', {
    staticClass: "dialer-soundSetting-testing-audio-text"
  }, [_vm._v(" Test audio ")])], 1)])])])])])])])]), _c('modal', {
    staticClass: "modalForDropdown",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-IncommingRingtone`
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header d-flex w-100 align-items-start justify-content-between pt-0"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Incoming ringtone")]), _c('div', {
    staticClass: "dialer-settings-intro newerTextVersion w-75 mb-0"
  }, [_vm._v(" Choose your preferred ringtone here, you'll hear a preview when you've selected one ")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-IncommingRingtone`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 hasScrollBarInsideIt mt-20px",
    staticStyle: {
      "--total": "90vh",
      "--minusValue": "75px"
    }
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, _vm._l(_vm.media_devices_setting.incomingRingtons, function (ringtone) {
    return _c('div', {
      key: ringtone.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-none",
      on: {
        "click": function ($event) {
          _vm.media_devices_setting.incomingRingtoneId = ringtone.id;
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      class: `actLikeSquareRadio mr-20px ${_vm.media_devices_setting.incoming_ringtone == ringtone.id ? 'checked' : ''}`
    }), _c('div', {
      staticClass: "latestGreyBox-heading-main"
    }, [_vm._v(_vm._s(ringtone.label))])]), _c('vb-audio-player', {
      attrs: {
        "design": 'minimum',
        "src": ringtone.file
      }
    })], 1);
  }), 0)])])]), _c('modal', {
    staticClass: "modalForDropdown",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-AlertRingtone`
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header d-flex w-100 align-items-start justify-content-between pt-0"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Alert ringtone")]), _c('div', {
    staticClass: "dialer-settings-intro newerTextVersion w-75 mb-0"
  }, [_vm._v(" Choose your preferred ringtone here, you'll hear a preview when you've selected one ")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-AlertRingtone`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 hasScrollBarInsideIt mt-20px",
    staticStyle: {
      "--total": "90vh",
      "--minusValue": "75px"
    }
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, _vm._l(_vm.media_devices_setting.alertRingtons, function (ringtone) {
    return _c('div', {
      key: ringtone.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-none",
      on: {
        "click": function ($event) {
          _vm.media_devices_setting.alertRingtoneId = ringtone.id;
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      class: `actLikeSquareRadio mr-20px ${_vm.media_devices_setting.alert_ringtone == ringtone.id ? 'checked' : ''}`
    }), _c('div', {
      staticClass: "latestGreyBox-heading-main"
    }, [_vm._v(_vm._s(ringtone.label))])]), _c('vb-audio-player', {
      attrs: {
        "design": 'minimum',
        "src": ringtone.file
      }
    })], 1);
  }), 0)])])]), _c('modal', {
    staticClass: "modalForDropdown",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-SendMessageRingtone`
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header d-flex w-100 align-items-start justify-content-between pt-0"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Send message ringtone")]), _c('div', {
    staticClass: "dialer-settings-intro newerTextVersion w-75 mb-0"
  }, [_vm._v(" Choose your preferred ringtone here, you'll hear a preview when you've selected one ")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-SendMessageRingtone`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 hasScrollBarInsideIt mt-20px",
    staticStyle: {
      "--total": "90vh",
      "--minusValue": "75px"
    }
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, _vm._l(_vm.media_devices_setting.sendMessageRingtons, function (ringtone) {
    return _c('div', {
      key: ringtone.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-none",
      on: {
        "click": function ($event) {
          _vm.media_devices_setting.sendMessageRingtoneId = ringtone.id;
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      class: `actLikeSquareRadio mr-20px ${_vm.media_devices_setting.send_message_ringtone == ringtone.id ? 'checked' : ''}`
    }), _c('div', {
      staticClass: "latestGreyBox-heading-main"
    }, [_vm._v(_vm._s(ringtone.label))])]), _c('vb-audio-player', {
      attrs: {
        "design": 'minimum',
        "src": ringtone.file
      }
    })], 1);
  }), 0)])])]), _c('modal', {
    staticClass: "modalForDropdown",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-RecieveMessageRingtone`
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header d-flex w-100 align-items-start justify-content-between pt-0"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Receive message ringtone")]), _c('div', {
    staticClass: "dialer-settings-intro newerTextVersion w-75 mb-0"
  }, [_vm._v(" Choose your preferred ringtone here, you'll hear a preview when you've selected one ")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-RecieveMessageRingtone`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 hasScrollBarInsideIt mt-20px",
    staticStyle: {
      "--total": "90vh",
      "--minusValue": "75px"
    }
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, _vm._l(_vm.media_devices_setting.recieveMessageRingtons, function (ringtone) {
    return _c('div', {
      key: ringtone.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-none",
      on: {
        "click": function ($event) {
          _vm.media_devices_setting.recieveMessageRingtoneId = ringtone.id;
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      class: `actLikeSquareRadio mr-20px ${_vm.media_devices_setting.recieve_message_ringtone == ringtone.id ? 'checked' : ''}`
    }), _c('div', {
      staticClass: "latestGreyBox-heading-main"
    }, [_vm._v(_vm._s(ringtone.label))])]), _c('vb-audio-player', {
      attrs: {
        "design": 'minimum',
        "src": ringtone.file
      }
    })], 1);
  }), 0)])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }