<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable100VHcustom bigSquareCheckbox min-width-50percent-modal vm--container" 
      width="50%" 
      :scrollable="true" 
      height="auto" 
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <div class="dialer-edit-blocked pb-3 w-100">
        <div class="dialer-edit">
          <form class="dialer-form" @submit.prevent="addnumber()">
            <div v-if="getIsMobile" class="dialer-edit-header flex-column">
              <div class="w-100 d-flex justify-content-between align-items-center">
                <h2 class="dialer-edit-title">Add blocked number</h2>
                <b-icon class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
              </div>
              <div class="dialer-edit-actions w-100 d-flex justify-content-end">
                <button class="newDoneButton" :disabled="api.add_number.send">
                  <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                  <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                    <vb-spinner v-if="api.add_number.send" />
                    <template v-else> Done </template>
                  </span>
                </button>
              </div>
            </div>
            <div v-else class="dialer-edit-header position-relative">
              <h2 class="dialer-settings-title newer mb-0">
                <vb-svg 
                  :disabled="api.add_number.send" 
                  @click="$modal.hide(modalName)" 
                  class="cursor_pointer_no_hover" 
                  name="leftLongArrow-icon" 
                  width="20" 
                  height="12" 
                  viewBox="0 0 30 16" 
                  stroke-width="1" 
                  stroke="" 
                  fill="none" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                />
                Add blocked number
              </h2>
              <div class="dialer-edit-actions">
                <button :disabled="api.add_number.send" class="newDoneButton">
                  <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                  <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                    <vb-spinner v-if="api.add_number.send" />
                    <template v-else> Done </template>
                  </span>
                </button>
              </div>
            </div>
            <b-alert :show="!!api.add_number.error_message" variant="danger">{{ api.add_number.error_message }}</b-alert>
            <div class="latestGreyBox-9-9-2023 mt-20px">
              <div class="whiteBoxWithBorderInsideGreyBox">
                <div class="whiteBoxWithBorderInsideGreyBox-row">
                  <div class="whiteBGinputWithGreyRoundedBorder w-100">
                    <label class="dialer-input-label">Number</label>
                    <input class="w-100" type="text" v-vb-input-number-formate :disabled="api.add_number.send" v-model="forms.add_number.callerid" label="Number" />
                    <p v-if="$v.forms.add_number.callerid.$error" class="text text-danger animated bounceIntop">
                      <span v-if="!$v.forms.add_number.callerid.required">* Number is required</span>
                      <span v-else-if="!$v.forms.add_number.callerid.validNumber">* Number is not valid</span>
                    </p>
                    <p v-else-if="api.add_number.validation_errors.callerid" class="text text-danger animated bounceIntop">
                      <span v-for="(em,i) in api.add_number.validation_errors.callerid" :key="i">* {{ em }}</span>
                    </p>
                  </div>
                </div>
                <div class="whiteBoxWithBorderInsideGreyBox-row">
                  <div class="whiteBGinputWithGreyRoundedBorder w-100">
                    <label class="dialer-input-label">Reason for blocking</label>
                    <input 
                      class="w-100" 
                      type="text" 
                      :disabled="api.add_number.send" 
                      v-model="forms.add_number.displayname" 
                      :maxlength="$v.forms.add_number.displayname.$params.maxLength.max"
                    />
                    <p v-if="$v.forms.add_number.displayname.$error" class="text text-danger animated bounceIntop">
                      <span v-if="!$v.forms.add_number.displayname.required">* Reason is required</span>
                      <span v-else-if="!$v.forms.add_number.displayname.maxLength">* Reason can be maximum {{$v.forms.add_number.displayname.$params.maxLength.max}} characters</span>
                    </p>
                    <p v-else-if="api.add_number.validation_errors.displayname" class="text text-danger animated bounceIntop">
                      <span v-for="(em,i) in api.add_number.validation_errors.displayname" :key="i">* {{ em }}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="latestGreyBox-heading-main w-100 mt-20px">Direction of number block</div>
              <div class="latestGreyBox-descriptionText w-100">
                You can choose which direction you'd like to block this number.
                Inbound will prevent calls coming in whereas, Outbound will prevent
                calls being made to that number. Selecting both In and
                Outbound will prevent calls to or from this number.
              </div>
              <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
                <div class="whiteBoxWithBorderInsideGreyBox-row">
                  <div class="latestGreyBox-heading-main w-100">Block inbound calls</div>
                  <b-form-checkbox 
                    switch 
                    :disabled="api.add_number.send" 
                    :checked="['both','in'].includes(forms.add_number.direction)"
                    @change="forms.add_number.direction=$event?forms.add_number.direction=='out'?'both':'in':forms.add_number.direction=='both'?'out':''" 
                    class="newerSwitch" 
                  />
                </div>
                <div class="whiteBoxWithBorderInsideGreyBox-row">
                  <div class="latestGreyBox-heading-main w-100">Block outbound calls</div>
                  <b-form-checkbox 
                    switch 
                    :disabled="api.add_number.send" 
                    :checked="['both','out'].includes(forms.add_number.direction)"
                    @change="forms.add_number.direction=$event?forms.add_number.direction=='in'?'both':'out':forms.add_number.direction=='both'?'in':''" 
                    class="newerSwitch" 
                  />
                </div>
                <p v-if="$v.forms.add_number.direction.$error" class="text text-danger animated bounceIntop">
                  <span v-if="!$v.forms.add_number.direction.required">* Direction is required</span>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import maxLength from 'vuelidate/lib/validators/maxLength';
import { mapGetters } from 'vuex';
import { $fn, VOIP_API } from "../../utils";
export default {
  name: "AddBlockNumbersModal",
  props: {
    modalName: {
      type: String,
      default: 'AddBlockNumbersModal',
    },
  },
  data() {
    return {
      forms: {
        add_number: this.$helperFunction.formInstance({
          data: {
            callerid: '',
            displayname: '',
            direction: 'both',
          }
        }),
      },
      api: {
        add_number: this.$helperFunction.apiInstance({
          validation_errors: true,
          error_message: true,
        }),
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getIsMobile',
    ])
  },
  validations: {
    forms: {
      add_number: {
        callerid: { 
          required: required,
          validNumber(value){ return $fn.validPhoneNumber(value) },
        },
        displayname: {
          required: required,
          maxLength: maxLength(255)
        },
        direction: {
          required: required,
        },
      },
    },
  },
  methods: {
    onBeforeOpen(){
      //TODO
    },
    onBeforeClose(){
      this.forms.add_number.reset()
      this.api.add_number.reset()
      this.$v.forms.add_number.$reset();
    },
    async addnumber(){
      if(this.api.add_number.send) return;
      this.$v.forms.add_number.$touch();
      if(this.$v.forms.add_number.$invalid) return;
      try {
        this.api.add_number.send=true
        this.api.add_number.validation_errors={}
        this.api.add_number.error_message=''
        await VOIP_API.endpoints.inbound_rule.create({
          callerid: $fn.getNumberFormated(this.forms.add_number.callerid),
          displayname: this.forms.add_number.displayname,
          type: this.forms.add_number.direction,
          action: "save",
          enable: "Y",
          command: "busy",
          fid: 12,
          args: "fsdfsd",
          source: "app",
        })
        this.appNotify({
          message: "Number Added Successfully",
          type: "success",
        })
        this.$modal.hide(this.modalName);
        this.$emit("interface");
      } catch (ex) {
        this.api.add_number.validation_errors=ex.own_errors
        this.api.add_number.error_message=ex.own_message
      } finally {
        this.api.add_number.send=false
      }
    },
  },
};
</script>
