var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    ref: "app_designed",
    staticClass: "flow__graph designed designedAgain scaleDown-0-6",
    attrs: {
      "id": "app_designed"
    }
  }, [_c('flowy', {
    attrs: {
      "nodes": _vm.nodes
    }
  })], 1), _c('AddRemoveFromList', {
    attrs: {
      "modalName": 'EditCallQueueMembersGraphModal'
    },
    on: {
      "update-list": function ($event) {
        return _vm.getCallQueue();
      }
    }
  }), _c('EditCallQueueModal', {
    attrs: {
      "modalName": `EditCallQueueGraphModal`
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }