<template>
  <div class="crm-card halfWidth">
    <div class="crm-card-heading">
      <div>
        Won deals by day
        <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
      </div>
      <div class="d-flex align-items-center">
        <b-dropdown class="crm-dd-container" id="sound-setting-micro-devices" no-caret  >
          <template #button-content>
            <span class="d-flex align-items-center p-0">
              <span>{{ selectedUser | get_property('name') }}</span>
              <b-icon-chevron-down scale="1.1" />
            </span>
          </template>
          <b-dropdown-item v-for="user in voipusers" :key="user.voipaccount" @click="filters.won_deals_by_day.user_id=user.voipaccount;fetchWonDealsByDay();">
            <vb-svg
              v-if="filters.won_deals_by_day.user_id==user.voipaccount"
              name="dialer-tick" 
              width="10.5" 
              height="7.584" 
              viewBox="0 0 10.5 7.584" 
              stroke-width="0" 
              stroke="#005DFF" 
              fill="none" 
              stroke-linecap="round" 
              stroke-linejoin="round" 
            />
            <span>{{ user.user.display_name }}</span>
          </b-dropdown-item>
        </b-dropdown>
        <b-dropdown class="crm-dd-container" id="sound-setting-micro-devices" no-caret  >
          <template #button-content>
            <span class="d-flex align-items-center p-0">
              <span>{{ selectedRange | get_property('label') }}</span>
              <b-icon-chevron-down scale="1.1" />
            </span>
          </template>
          <b-dropdown-item v-for="range in ranges" :key="range.value" @click="filters.won_deals_by_day.range=range.value;fetchWonDealsByDay();">
            <vb-svg
              v-if="filters.won_deals_by_day.range==range.value"
              name="dialer-tick" 
              width="10.5" 
              height="7.584" 
              viewBox="0 0 10.5 7.584" 
              stroke-width="0" 
              stroke="#005DFF" 
              fill="none" 
              stroke-linecap="round" 
              stroke-linejoin="round" 
            />
            <span>{{ range.label }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div class="crm-graph-container" style="position: relative;">
      <canvas ref="won_deals_by_day_chart" />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { VOIP_API } from '@/utils'
import { mapState } from 'vuex'
import Chart from 'chart.js'
export default {
  name: 'WonDealsByDayGraphWidget',
  data() {
    return {
      api: {
        fetch_won_deals_by_day: this.$helperFunction.apiInstance({ 
          source: true, 
        }),
      },
      response: {
        won_deals_by_day: {},
      },
      filters: {
        won_deals_by_day: {
          range: 7,
          user_id: '',
        },
      },
      charts: {
        won_deals_by_day: null,
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapState({
      voipusers: (state) => Object.values(state.common.voipusers),
    }),
    ranges(){ return this.response.won_deals_by_day?.ranges ?? [] },
    selectedRange(){ return this.ranges.find(i=>this.filters.won_deals_by_day.range==i.value) },
    selectedUser(){ return this.voipusers.find(i=>this.filters.won_deals_by_day.user_id==i.id) },
  },
  methods: {
    async fetchWonDealsByDay() {
      this.api.fetch_won_deals_by_day.source?.cancel?.("Requesting Again")
      this.api.fetch_won_deals_by_day.source=axios.CancelToken.source()
      try {
        this.api.fetch_won_deals_by_day.send=true
        const { data: won_deals_by_day } = await VOIP_API.endpoints.crm.cards.wonDealsByDay({
          user_id: this.filters.won_deals_by_day.user_id,
          range: this.filters.won_deals_by_day.range,
        },this.api.fetch_won_deals_by_day.source.token)
        this.response.won_deals_by_day=won_deals_by_day
        this.makeChart()
      } catch (ex) {
        if(!ex.cancel){
          this.appNotify({
            message: ex.own_message,
            type: 'danger',
          })
        }
      } finally {
        this.api.fetch_won_deals_by_day.send=false
        this.api.fetch_won_deals_by_day.source=null
      }
    },
    makeChart(){
      this.charts.won_deals_by_day?.destroy?.();
      const labels = this.response.won_deals_by_day.result.map(i=>i.label)
      const data = this.response.won_deals_by_day.result.map(i=>i.value)
      this.charts.won_deals_by_day = new Chart(this.$refs.won_deals_by_day_chart, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Won Deals By Day',
              data: data,
            }
          ],
        },
        options: {
          responsive: true,
          scales: {
            yAxes: [
              {
                gridLines: {
                  offsetGridLines: true
                },
                ticks: {
                  beginAtZero: true,
                  stepSize: 2,
                },
              }
            ],
          },
        },
      });
      this.charts.won_deals_by_day.update();
    },
  },
  mounted(){
    this.fetchWonDealsByDay()
  },
}
</script>

<style>

</style>