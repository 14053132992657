var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-500 EditNumbersModal min-width-50percent-modal linkCallModal",
    attrs: {
      "name": _vm.modalName,
      "width": "50%",
      "height": "auto",
      "scrollable": true
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', [_c('div', {
    staticClass: "dialer-edit-header flex-column align-items-start"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_c('vb-svg', {
    staticClass: "cursor_pointer_no_hover",
    attrs: {
      "disabled": _vm.api.link_call.send,
      "name": "leftLongArrow-icon",
      "width": "20",
      "height": "12",
      "viewBox": "0 0 30 16",
      "stroke-width": "1",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }), _vm._v(" Link call ")], 1)]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('b-button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": _vm.api.link_call.send
    },
    on: {
      "click": function ($event) {
        return _vm.linkCall();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm._v(" Done ")])], 1)], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('b-form-radio-group', {
    attrs: {
      "disabled": _vm.api.link_call.send
    },
    model: {
      value: _vm.selected.cdr_id,
      callback: function ($$v) {
        _vm.$set(_vm.selected, "cdr_id", $$v);
      },
      expression: "selected.cdr_id"
    }
  }, _vm._l(_vm.calllogs, function (call_log, index) {
    return _c('b-form-radio', {
      key: index,
      staticClass: "squreRadioButtons withCallLogInIt",
      attrs: {
        "value": call_log.callerid
      }
    }, [_c('Item1', {
      attrs: {
        "call": call_log,
        "conditions": {
          actions: false
        }
      }
    })], 1);
  }), 1), _vm.calllogs.length == 0 ? _c('vb-no-record', {
    attrs: {
      "design": 3,
      "text": _vm.api.get_call_logs.send ? '' : 'No Calls'
    }
  }, [_vm.api.get_call_logs.send ? _c('div', {
    staticClass: "w-100",
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }, _vm._l(6, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "w-100"
    }, [_c('div', {
      class: `latestShimmerDesign w-75 mt-20px ${n % 2 == 1 ? 'ml-auto' : 'mr-auto'}`,
      staticStyle: {
        "height": "75px"
      }
    })]);
  }), 0) : _vm._e()]) : _vm._e()], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }