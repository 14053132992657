var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-main-section DealsSection"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100 mb-5"
  }, [_c('h2', {
    staticClass: "crm-main-section-heading text-case-unset mb-0"
  }, [_vm._v(" ReCaptcha "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1)]), _c('div', {
    staticClass: "crm-card p-32"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('h2', {
    staticClass: "crm-main-section-heading text-case-unset my-0",
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v(" ReCaptcha ")]), _c('a', {
    staticClass: "crm-link withIcon",
    attrs: {
      "target": "blank"
    }
  }, [_vm._v(" v2 "), _c('b-icon', {
    attrs: {
      "icon": "box-arrow-up-right"
    }
  })], 1)]), _vm._m(0), _c('div', {
    staticClass: "crm-card-heading mb-1 mt-4"
  }, [_vm._v("Ignored IP Addresses")]), _vm._m(1), _c('p', {
    staticClass: "crm-card-text w-100 mb-0"
  }, [_vm._v(" Enter coma separated IP addresses that you want the reCaptcha to skip validation. ")]), _c('button', {
    staticClass: "crm-button mt-4"
  }, [_vm._v(" Save ")])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "w-50 pr-3"
  }, [_c('div', {
    staticClass: "crm-card-heading mb-1 mt-4"
  }, [_vm._v("Site Key")]), _c('div', {
    staticClass: "crm-input-container withoutIcon mb-0"
  }, [_c('input', {
    staticClass: "w-100"
  })])]), _c('div', {
    staticClass: "w-50 pl-3"
  }, [_c('div', {
    staticClass: "crm-card-heading mb-1 mt-4"
  }, [_vm._v("Secret Key")]), _c('div', {
    staticClass: "crm-input-container withoutIcon mb-0"
  }, [_c('input', {
    staticClass: "w-100"
  })])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-input-container withoutIcon mb-0"
  }, [_c('textarea', {
    staticClass: "w-100"
  })]);

}]

export { render, staticRenderFns }