import Emitter from "component-emitter"

/* eslint-disable no-undef */
export const MobileApp = {
  init(){
    try {
      Emitter(this)
      window.mobileMessage = this.onMessage.bind(this)
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return this
    }
  },
  sendMessage(event,payload={}){
    const message = JSON.stringify({
      event,
      payload
    })
    Android?.jsMessage?.(message)
    window?.webkit?.messageHandlers?.iosListener?.postMessage?.(message)
  },
  onMessage(message){
    const { event, payload } = JSON.parse(message)
    this.emit(event,payload)
  },
}