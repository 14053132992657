<template>
  <modal class="dialer_animation right_side_popup developerOptionsModal" :name="modalName" @before-open="onBeforeOpen" @before-close="onBeforeClose">
    <div class="dialer-assign-to my-4 dialer-edit d-flex align-items-center justify-content-between">
      <h2 class="dialer-edit-title newer mb-0">
        <template v-if="api.check.status==1">Select Region and Platform</template>
        <template v-else>Select Setup</template>
      </h2>
      <div class="dialer-edit-actions d-flex justify-content-end">
        <button class="newCloseButton" @click="$modal.hide(modalName)">
          <vb-icon icon="squared-close-icon" height="38px" width="38px" />
          <span class="newCloseButton-textPart">Close</span>
        </button>
      </div>
    </div>
    <div style="max-height: calc(100vh - 120px);overflow-y: auto;height: calc(100vh - 120px);">
      <div class="dialer-login-form">
        <b-form v-if="api.verify_pincode.status!=1" @submit.prevent="verifyPincode()">
          <div class="dialer-form-inner signupLoginV2 d-flex bg-transparent p-0 w-100">
            <div class="wrap w-100">
              <div class="signupLoginV2-section-2-right w-100">
                <div class="latestGreyBox-9-9-2023 mt-20px w-100">
                  <b-alert variant="danger" :show="!!api.verify_pincode.error_message">{{ api.verify_pincode.error_message }}</b-alert>
                  <div class="whiteBGinputWithGreyRoundedBorder text-left mt-20px">
                    <div class="w-75 text-left d-flex mx-auto flex-column justify-content-center">
                      <label class="w-100">Enter PIN</label>
                      <vb-pin-code
                        :disabled="api.verify_pincode.send"
                        class="input"
                        :numberslength="$v.forms.verify_pincode.pincode.$params.maxLength.max"
                        @change="forms.verify_pincode.pincode=$event"
                      />
                    </div>
                    <template v-if="forms.verify_pincode.submitted && $v.forms.verify_pincode.pincode.$invalid">
                      <b-icon id="su-email" icon="info-circle-fill" variant="danger" class="danger-info-icon"/>
                      <div class="errorTemplate-v2">
                        <p class=" animated bounceIntop">
                          <span v-if="!$v.forms.verify_pincode.pincode.required">* pincode is required</span>
                          <span v-else-if="!$v.forms.verify_pincode.pincode.maxLength">* pincode can be maximum {{$v.forms.verify_pincode.pincode.$params.maxLength.max}} character</span>
                          <span v-else-if="!$v.forms.verify_pincode.pincode.minLength">* pincode should be minimum {{$v.forms.verify_pincode.pincode.$params.minLength.min}} character</span>
                        </p>
                      </div>
                    </template>
                  </div>
                  <div class="d-flex justify-content-end w-100 mt-20px">
                    <b-button :disabled="api.verify_pincode.send" class="dialer-button dialer-button-primary" type="submit" variant="primary">
                      <vb-spinner v-if="api.verify_pincode.send" />
                      <template v-else>Check</template>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
        <b-form v-else @submit.prevent="check()">
          <div class="dialer-form-inner signupLoginV2 d-flex bg-transparent p-0 w-100">
            <div class="wrap w-100">
              <div class="signupLoginV2-section-2-right w-100 pr-3">
                <div class="latestGreyBox-9-9-2023 mt-20px">
                  <b-alert variant="danger" v-if="!!api.check.error_message">{{ api.check.error_message }}</b-alert>
                  <div class="whiteBGinputWithGreyRoundedBorder text-left dropdownVersion w-100">
                    <label class="text-left">Select Setup</label>
                    <vb-select
                      class="w-100"
                      :disabled="api.cp_list.send"
                      :selected="forms.cp_list.point"
                      @change="forms.cp_list.point = $event;changeCP();"
                      :options="cpListOptions"
                    />
                  </div>
                  <div v-if="isElectron" class="whiteBoxWithBorderInsideGreyBox mt-20px">
                    <div class="whiteBoxWithBorderInsideGreyBox-row">
                      <div class="latestGreyBox-heading-main">Developer Options</div>
                      <b-checkbox switch v-model="$store.state.electron.dev_mode" class="newerSwitch"></b-checkbox>
                    </div>
                    <div class="whiteBoxWithBorderInsideGreyBox-row">
                      <div class="latestGreyBox-heading-main">Disable Desktop App Update</div>
                      <b-checkbox switch v-model="$store.state.electron.update.disabled" class="newerSwitch"></b-checkbox>
                    </div>
                  </div>
                  <div class="whiteBoxWithBorderInsideGreyBox">
                    <div class="whiteBoxWithBorderInsideGreyBox-row py-4 min-height-none">
                      <div class="latestGreyBox-heading-main">Console Log</div>
                      <button @click="$modal.show(`${_uid}-DeveloperConsoleLog`)">View</button>
                    </div>
                  </div>
                </div>
                <div v-if="isLoggedIn" class="latestGreyBox-9-9-2023 mt-20px">
                  <div class="whiteBoxWithBorderInsideGreyBox">
                    <div class="whiteBoxWithBorderInsideGreyBox-row py-4 min-height-none">
                      <div class="latestGreyBox-heading-main">Test Mode</div>
                      <b-form-checkbox 
                        class="newerSwitch" 
                        ref="test_mode"
                        :disabled="api.update_test_mode.send || api.get_test_mode.send"
                        :checked="forms.update_test_mode.test_mode"
                        @change="updateTestMode()"
                        switch
                      />
                    </div>
                  </div>
                  <div class="whiteBoxWithBorderInsideGreyBox">
                    <div class="whiteBoxWithBorderInsideGreyBox-row py-4 min-height-none">
                      <div class="latestGreyBox-heading-main">Features</div>
                      <button @click="$modal.show(`${_uid}-DeveloperFeaturesModal`)">View</button>
                    </div>
                  </div>
                  <div class="whiteBoxWithBorderInsideGreyBox">
                    <div class="whiteBoxWithBorderInsideGreyBox-row py-4 min-height-none">
                      <div class="latestGreyBox-heading-main">Team Pickup Groups</div>
                      <button @click="$modal.show(`${_uid}-PickupGroupsModal`)">View</button>
                    </div>
                  </div>
                  <div class="whiteBoxWithBorderInsideGreyBox">
                    <div class="whiteBoxWithBorderInsideGreyBox-row py-4 min-height-none">
                      <div class="latestGreyBox-heading-main">Sound Library</div>
                      <button @click="$modal.show(`${_uid}-SoundLibraryModal`,{ ivr_show: true })">View</button>
                    </div>
                  </div>
                  <div class="whiteBoxWithBorderInsideGreyBox">
                    <div class="whiteBoxWithBorderInsideGreyBox-row py-4 min-height-none">
                      <div class="latestGreyBox-heading-main">Errors</div>
                      <button @click="$modal.show(`${_uid}-DeveloperErrorsStoreModal`)">View</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
    <PickupGroupsModal :modalName="`${_uid}-PickupGroupsModal`" :teamPickupGroup="true" />
    <SoundLibraryModal :modalName="`${_uid}-SoundLibraryModal`" />
    <DeveloperFeaturesModal :modalName="`${_uid}-DeveloperFeaturesModal`" />
    <DeveloperErrorsStoreModal :modalName="`${_uid}-DeveloperErrorsStoreModal`" />
    <DeveloperConsoleLog :modalName="`${_uid}-DeveloperConsoleLog`" />
  </modal>
</template>

<script>
import { VOIP_API } from '@/utils';
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import { TokenService } from '@/services';
import { electron_events } from '@/electron/events';
import { mapGetters } from 'vuex';
import DeveloperFeaturesModal from './DeveloperFeaturesModal.vue'
import PickupGroupsModal from './PickupGroupsModal.vue'
import SoundLibraryModal from './SoundLibraryModal.vue'
import DeveloperErrorsStoreModal from './DeveloperErrorsStoreModal.vue';
import DeveloperConsoleLog from './DeveloperConsoleLog.vue';

export default {
  name: 'DeveloperSetting',
  components: {
    DeveloperFeaturesModal,
    PickupGroupsModal,
    SoundLibraryModal,
    DeveloperErrorsStoreModal,
    DeveloperConsoleLog,
  },
  props: {
    modalName: {
      type: String,
      default: 'DeveloperSetting',
    },
  },
  data(){
    return {
      forms: {
        verify_pincode: this.$helperFunction.formInstance({
          data: {
            pincode: '',
          }
        }),
        cp_list: this.$helperFunction.formInstance({
          data: {
            point: '',
          }
        }),
        check: this.$helperFunction.formInstance({
          data: {
            pin: '',
          }
        }),
        set: this.$helperFunction.formInstance({
          data: {
            region: '',
            platform: '',
          }
        }),
        update_test_mode: this.$helperFunction.formInstance({
          data: {
            test_mode: false,
          }
        }),
      },
      response: {
        regions: [],
        platforms: [],
        cp_list: [],
      },
      api: {
        get_test_mode: this.$helperFunction.apiInstance(),
        update_test_mode: this.$helperFunction.apiInstance({
          error_message: true,
        }),
        verify_pincode: this.$helperFunction.apiInstance({
          status: true, 
          error_message: true, 
        }),
        check: this.$helperFunction.apiInstance({ 
          status: true, 
          error_message: true, 
          validation_errors: true, 
        }),
        cp_list: this.$helperFunction.apiInstance({ 
          error_message: true,  
        }),
        set_cp: this.$helperFunction.apiInstance({ 
          error_message: true,  
        }),
        regions: this.$helperFunction.apiInstance(),
        platforms: this.$helperFunction.apiInstance(),
      },
      filters: {
        features: {
          search: '',
        },
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser',
    ]),
    isElectron(){ return process.env.IS_ELECTRON ? true : false },
    cpListOptions(){ return this.response.cp_list?.map(i=>({text:i.name,value:i.domain})) },
    isLoggedIn(){ return !!this.getCurrentUser?.account },
  },
  validations: {
    forms: {
      check: {
        pin: {
          required,
          maxLength: maxLength(6),
          minLength: minLength(6),
        },
      },
      verify_pincode: {
        pincode: {
          required,
          maxLength: maxLength(6),
          minLength: minLength(6),
        },
      },
      set: {
        region: {
          required,
        },
        platform: {
          required,
        },
      },
    },
  },
  watch: {
    "$store.state.electron.dev_mode":{
      immediate: true,
      handler(value){
        if(process.env.IS_ELECTRON){
          window.electron.send(electron_events.CHANE_DEV_MODE,{
            dev_mode: value
          })
        }
      }
    },
  },
  methods: {
    onBeforeOpen(){
      this.forms.cp_list.point=TokenService.CP_DOMAIN.get() ?? ''
      this.forms.set.region=VOIP_API.region
      this.forms.set.platform=VOIP_API.platform
      this.getCPList()
    },
    onBeforeClose(){
      this.response.regions = []
      this.response.platforms = []
      this.response.cp_list = []
      this.filters.features.search = ''
      this.forms.cp_list.reset()
      this.forms.check.reset()
      this.forms.set.reset()
      this.forms.verify_pincode.reset()
      this.forms.update_test_mode.reset()
      this.api.check.reset()
      this.api.cp_list.reset()
      this.api.set_cp.reset()
      this.api.regions.reset()
      this.api.platforms.reset()
      this.api.verify_pincode.reset()
      this.api.get_test_mode.reset()
      this.api.update_test_mode.reset()
    },
    // cp
    async verifyPincode(){
      this.forms.verify_pincode.submitted=true
      this.$v.forms.verify_pincode.$touch()
      if(this.$v.forms.verify_pincode.$invalid || this.api.verify_pincode.send) return;
      try {
        this.api.verify_pincode.send=true
        this.api.verify_pincode.status=0
        this.api.verify_pincode.error_message=''
        await VOIP_API.endpoints.dns.verifyPincode({
          pincode: this.forms.verify_pincode.pincode,
        })
        this.api.verify_pincode.status=1
        this.getCPList()
        if(this.isLoggedIn) {
          this.fetchTestMode() 
        }
      } catch (ex) {
        this.api.verify_pincode.error_message=ex.own_message
        this.api.verify_pincode.status=2
      } finally {
        this.api.verify_pincode.send=false
        this.forms.verify_pincode.submitted=false
      }
    },
    getCPList(){
      let vm = this
      if(vm.api.cp_list.send) return;
      vm.api.cp_list.send=true
      VOIP_API.endpoints.dns.getCPList()
      .then(({ data: list }) => {
        vm.response.cp_list = list || [];
      }).finally(()=>{
        vm.api.cp_list.send=false
      });
    },
    changeCP(){
      let vm = this
      if(vm.api.set_cp.send) return;
      vm.api.set_cp.send=true
      VOIP_API.endpoints.dns.getCP({
        domain: vm.forms.cp_list.point,
      })
      .then(({ data }) => {
        VOIP_API.CPUrl=data
      })
      .finally(()=>{
        vm.api.set_cp.send=false
      });
    },
    // test mode
    async fetchTestMode(){
      if(this.api.get_test_mode.send) return;
      try {
        this.api.get_test_mode.send=true
        const { data } = await VOIP_API.endpoints.test_mode.get({
          uid: this.getCurrentUser.uid,
        })
        this.forms.update_test_mode.test_mode=data=='enabled'
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.get_test_mode.send=false
      }
    },
    async updateTestMode(){
      if(this.api.update_test_mode.send) return;
      try {
        this.api.update_test_mode.send=true
        await VOIP_API.endpoints.test_mode.update({
          uid: this.getCurrentUser.uid,
        })
        this.forms.update_test_mode.test_mode=!this.forms.update_test_mode.test_mode
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.$refs['test_mode'].localChecked = this.forms.update_test_mode.test_mode;
        this.api.update_test_mode.send=false
      }
    },
    // set region and platform
    check(){
      let vm = this
      vm.forms.check.submitted=true
      vm.$v.forms.check.$touch()
      if(vm.$v.forms.check.$invalid || vm.api.check.send) return;
      vm.api.check.send=true
      vm.api.check.status=0
      vm.api.check.error_message=''
      vm.api.check.validation_errors={}
      VOIP_API.endpoints.auth.settingRegionPlatform({
        pin: vm.forms.check.pin,
      })
      .then(()=>{
        vm.api.check.status=1
        vm.fetchRegions()
        vm.fetchPlatforms()
      })
      .catch((ex)=>{
        vm.api.check.error_message=ex.own_message
        vm.api.check.validation_errors=ex.own_errors
        vm.api.check.status=2
      })
      .finally(()=>{
        vm.api.check.send=false
        vm.forms.check.submitted=false
      })
    },
    fetchRegions(){
      let vm = this
      if(vm.api.regions.send) return;
      vm.api.regions.send=true
      VOIP_API.endpoints.auth.getRegions({
        flag: 'all'
      })
      .then((res)=>{
        vm.response.regions=res?.data
      })
      .catch((ex)=>{
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.regions.send=false
      })
    },
    fetchPlatforms(){
      let vm = this
      if(vm.api.platforms.send) return;
      vm.api.platforms.send=true
      VOIP_API.endpoints.auth.getPlatforms({
        flag: 'all'
      })
      .then((res)=>{
        vm.response.platforms=res?.data
      })
      .catch((ex)=>{
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.platforms.send=false
      })
    },
    set(){
      let vm = this
      vm.forms.set.submitted=true
      vm.$v.forms.set.$touch()
      if(vm.$v.forms.set.$invalid) return;
      VOIP_API.setRegionPlatform({
        region: vm.forms.set.region,
        platform: vm.forms.set.platform,
      })
      vm.$modal.hide(vm.modalName)
      vm.forms.set.submitted=false
    },
  },
}
</script>

<style>

</style>