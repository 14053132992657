var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.info ? _c('div', {}, [_vm.info.type == 'number' ? _vm._l(_vm.filterMembers, function (member, index) {
    return _c('div', {
      key: index,
      staticClass: "mb-2"
    }, [_c('Info', {
      attrs: {
        "id": _vm._f("getExtnByNumberHTML")(member),
        "is_blf": false
      }
    })], 1);
  }) : [_vm._l(_vm.filterMembers, function (member, index) {
    return _c('div', {
      key: index,
      staticClass: "mb-2"
    }, [_c('Info', {
      attrs: {
        "id": member.key,
        "name": member.name,
        "is_blf": false
      }
    })], 1);
  }), _c('div', {
    staticClass: "d-flex justify-content-end w-100 mt-4"
  }, [_vm.members.length > 4 && _vm.moreText ? _c('div', {
    staticStyle: {
      "color": "#5576d1",
      "font-weight": "600",
      "font-size": "16px"
    }
  }, [_vm._v(" " + _vm._s(_vm.members.length - 4) + " more members ")]) : _vm._e(), _vm.graph ? _c('vb-icon', {
    staticClass: "cursor_pointer graphIconInsideGraph-icon",
    attrs: {
      "icon": "graphIconInsideGraph-icon",
      "height": "32px",
      "width": "32px"
    },
    on: {
      "click": function ($event) {
        return _vm.showGraph();
      }
    }
  }) : _vm._e()], 1)]], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }