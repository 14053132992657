
import { VOIP_API } from "../index"

export const CALL_API_ENDPOINTS = {
  checkCAllUpgradable(number=''){
    return VOIP_API.axios.voip.request({
      url: `call-isupgradeable/${number}`,
      method: "GET",
    })
  },
  getNotificationSettings(accountcode='',params={}){
    return VOIP_API.axios.voip.request({
      url: `get-notification-settings/${accountcode}`,
      method: "GET",
      params,
    })
  },
  setNotificationSettings(data={}){
    return VOIP_API.axios.voip.request({
      url: `notification-settings`,
      method: "POST",
      data,
    })
  },
  onCallReject(data={}){
    return VOIP_API.axios.voip.request({
      url: `hangup-mobile-call`,
      method: "POST",
      data,
    })
  },
}