<template>
  <modal
    :name="modalName"
    class="dialer_animation right_side_popup m-w-500 addExtension-voicemailModal addRemove_userModal bigSquareCheckbox CallQueueAddRemoveUsersModal min-width-50percent-modal fixScrollIssue"
    height="auto"
    @before-open="onBeforeOpen($event)"
    @closed="onClosed()"
    :clickToClose="false"
  >
    <div class="dialer-edit addUser">
      <div class="dialer-edit-header">
        <div class="d-flex align-items-center">
          <vb-svg
            class="cursor_pointer"
            name="leftLongArrow-icon"
            width="20"
            height="12"
            viewBox="0 0 30 16"
            stroke-width="1"
            stroke=""
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            @click="$modal.hide(modalName)"
          />
          <span class="dialer-edit-title md-mar-left">{{ data.type | textFilter('title',{ extra_data: data.extra_data }) }}</span>
        </div>
        <div class="dialer-edit-actions">
          <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="update()" />
          <a v-else>
            <button type="button" :disabled="api.update.send" @click="update()" class="newDoneButton"> 
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </a>
        </div>
      </div>
      <div v-if="data.type==modalLists.UserCallerIdShared" class="vm--modal-text">Select users who are authorised to make outbound calls using this number.</div>
      <div class="latestGreyBox-9-9-2023 mt-20px">
        <div class="d-flex align-items-center justify-content-between">
          <div class="whiteBGinputWithGreyRoundedBorder w-50">
            <b-input type="text" :placeholder="data.type | textFilter('place-holder')" :disabled="api.update.send || api.list.send" v-model="filter.search" />
          </div>
        </div>
        <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
          <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset">
            <b-form-checkbox 
              class="bg-transparent w-100" 
              :class="{
                'switchInsideCheckBox-container': data.type==modalLists.CallQueueMembers
              }" 
              :checked="isAllCheck" 
              @change="onSelectAll()"
            >
              <template v-if="data.type==modalLists.CallQueueMembers">
                <div>Select all</div>
                <div>Force login</div>
              </template>
              <template v-else>
                <span>Select all</span>
              </template>
            </b-form-checkbox>
          </div>
          <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset" v-for="item in searchedList" :key="item.account">
            <b-form-checkbox class="w-100 bg-transparent" :disabled="api.update.send" :checked="!!list.selected[item.account]" @change="onChange(item)">
              <div class="d-flex justify-content-between align-item-center">
                <Info :id="item.account" :is_blf="false" />
                <span class="mt-1" v-if="!list.selected[item.account] && list.added[item.account]">Removed</span>
                <span class="mt-1" v-if="list.selected[item.account] && list.not_added[item.account]">Added</span>
                <template v-if="data.type==modalLists.CallQueueMembers">
                  <div v-if="list.added[item.account]" class="forceSwitchContainer">
                    <label class="switch"> 
                      <input type="checkbox" :checked="item.force=='yes'" :disabled="api.update_member_force_admin.send==item.account" @change="updateForceAdmin(item,item.account)">
                      <span class="slider round"></span>
                    </label>
                  </div>
                </template>
              </div>
            </b-form-checkbox>
          </div>
          <vb-no-record class="my-4" v-if="isEmpty(searchedList) && !api.list.send" :text="data.type | textFilter('no-record')" :design="3" />
          <template v-if="isEmpty(searchedList) && api.list.send">
            <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4 justify-content-start" v-for="n in 3" :key="n.id">
              <div class="latestShimmerDesign mx-3" style="height: 24px;width: 24px;"></div>
              <div class="d-flex align-items-center">
                <div class="latestShimmerDesign mr-2" style="height: 45px;width: 45px;"></div>
                <div class="d-flex flex-column">
                  <div class="latestShimmerDesign mb-2" style="height: 21px;width: 120px;"></div>
                  <div class="latestShimmerDesign" style="height: 20px;width: 60px;"></div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import _ from "lodash";
import { LOGGER, VOIP_API } from "../../utils";
import Info from "../Lists/Info.vue";
import { mapGetters } from 'vuex';
import { GET_LOCAL_SETTING_IS_DARK_MODE } from '@/store/helper/getters';
const ModalsList = {
  AddExtensionModal: "AddExtensionModal",
  AddUserWithExtensionModal: "AddUserWithExtensionModal", // voicemail
  AssignNumbersTeam: "AssignNumbersTeam", // teams
  CallQueueMembers: "CallQueueMembers", // call queue
  UserCallerIdShared: "UserCallerIdShared", // number
  space_members: "space_members", // number
  task_assigned: "task_assigned", // number
  task_viewer: "task_viewer", // number
};
export default {
  name: "AddRemoveFromList",
  components: { 
    Info 
  },
  inject:[
    'isEmpty',
    'appNotify'
  ],
  props: {
    modalName: {
      type: String,
      default: "ModalName",
    },
    accountcode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      list: {
        not_added: {},
        added: {},
        selected: {},
      },
      api: {
        update: this.$helperFunction.apiInstance(),
        list: this.$helperFunction.apiInstance(),
        update_member_force_admin: this.$helperFunction.apiInstance({
          send: ''
        }),
      },
      filter: {
        search: '',
      },
      data: {
        type: '',
        extra_data: {}
      },
    };
  },
  computed: {
    ...mapGetters({
      getVoipUsersAlises: 'getVoipUsersAlises',
      getCurrentUser: 'getCurrentUser',
      getIsMobile: 'getIsMobile',
      getVoipUsersAlisesDisplayName: 'getVoipUsersAlisesDisplayName',
      GET_LOCAL_SETTING_IS_DARK_MODE: GET_LOCAL_SETTING_IS_DARK_MODE,
    }),
    user(){ return this.getVoipUsersAlises[this.accountcode] ?? this.getVoipUsersAlises[this.getCurrentUser.account] },
    modalLists() { return ModalsList; },
    searchedList() {
      const search = this.filter.search.trim().toLowerCase();
      const available = (_.isArray(this.list.not_added) ? this.list.not_added: Object.values(this.list.not_added || {}))
      const unavailable = (_.isArray(this.list.added) ? this.list.added: Object.values(this.list.added || {}))
      const list = [...available,...unavailable]
      if (this.data.type==ModalsList.UserCallerIdShared) return _.orderBy(list.filter(i=>`${i.html}`.trim().toLowerCase().includes(search)), ["html"], ["asc"]);
      else if (this.data.type==ModalsList.space_members) return _.orderBy(list.filter(i=>`${i.account}`.trim().toLowerCase().includes(search)), ["account"], ["asc"]);
      else return _.orderBy(list.filter(i=>`${i.display_name} ${i.extn}`.trim().toLowerCase().includes(search)), ["display_name"], ["asc"]);
    },
    keysSearchedList() {
      const array = _.isArray(this.searchedList) ? this.searchedList : Object.values(this.searchedList);
      return array.map((item) => item.account);
    },
    isAllCheck() {
      const array = _.isArray(this.list.selected) ? this.list.selected : Object.keys(this.list.selected);
      return !_.isEmpty(this.keysSearchedList) ? this.keysSearchedList.every((el) => array.includes(el)) : false;
    },
  },
  filters: {
    getExtnByNumberHTML(value){ 
      const str = value?.replace?.(/[^0-9]/g,'')
      return str?.substr?.(str?.length-4,str?.length) ?? value 
    },
    textFilter(data_type,type='title',extra={}) {
      const { extra_data } = extra
      if(type=='title') {
        if (data_type==ModalsList.AddExtensionModal) return "Added extensions";
        else if (data_type==ModalsList.AddUserWithExtensionModal) return "Added users";
        else if (data_type==ModalsList.AssignNumbersTeam) return "Assigned users";
        else if (data_type==ModalsList.CallQueueMembers) return "Assigned users";
        else if (data_type==ModalsList.UserCallerIdShared) return `Outgoing calls only for ${extra_data?.number?.number}`;
        else if(data_type==ModalsList.space_members) return 'Select space member'
        else return 'Select users';
      } else if(type=='place-holder') {
        return "Search User";
      } else {
        if (data_type==ModalsList.AddExtensionModal) return "No extension have been added yet";
        else return "No users found";
      }
    }
  },
  methods: {
    onBeforeOpen(event) {
      this.data.type = _.get(event,'params.type') || ''
      if (this.data.type==ModalsList.AssignNumbersTeam) {
        this.$set(this.data.extra_data, "team", event.params.team);
        this.data.extra_data.team = event.params.team;
      } else if (this.data.type==ModalsList.CallQueueMembers) {
        this.$set(this.data.extra_data, "call_queue", event.params.call_queue);
        this.data.extra_data.call_queue = event.params.call_queue;
      } else if (this.data.type==ModalsList.UserCallerIdShared) {
        this.$set(this.data.extra_data,"extensions",_.isArray(event.params.extensions) ? event.params.extensions : []);
        this.$set(this.data.extra_data,"number",_.isObject(event.params.number) ? event.params.number : {});
      } else if (this.data.type==ModalsList.space_members) {
        this.$set(this.data.extra_data,"space_id",event.params.space_id);
      } else if (this.data.type==ModalsList.task_viewer) {
        this.$set(this.data.extra_data,"task_id",event.params.task_id);
      } else if (this.data.type==ModalsList.task_assigned) {
        this.$set(this.data.extra_data,"task_id",event.params.task_id);
      }
      this.load(event);
    },
    onClosed() {
      this.list.not_added = {};
      this.list.added = {};
      this.list.selected = {};
      this.api.update.reset();
      this.api.list.reset();
      this.api.update_member_force_admin.reset();
      this.filter.search=''
      this.data.type = ''
      this.data.extra_data = {}
      this.$emit("closed");
    },
    async updateForceAdmin(member,accountcode){
      if(this.api.update_member_force_admin.send) return;
      try {
        this.api.update_member_force_admin.send=accountcode
        await VOIP_API.endpoints.call_queues.memberForceAdmin(accountcode,{
          id: this.data.extra_data.call_queue.voipaccount,
          account: accountcode,
          value: member.force=='no'?'yes':'no',
        })
        this.appNotify({
          message: 'Successfully change',
          type: 'success',
        })
        this.$emit('update-list')
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.update_member_force_admin.send=''
        this.load()
      }
    },
    async load(event) {
      if (this.api.list.send) return;
      try {
        this.api.list.send = true;
        if (this.data.type==ModalsList.AddExtensionModal) {
          this.list.not_added = event.params.availableUsers;
          this.list.added = event.params.savedUsers;
        } else if (this.data.type==ModalsList.AddUserWithExtensionModal) {
          this.list.not_added = event.params.availableUsers;
          this.list.added = event.params.savedUsers;
        } else if (this.data.type==ModalsList.AssignNumbersTeam) {
          const { data: res } = await VOIP_API.endpoints.teams.teammembers(this.data.extra_data.team.real_id, {
            fid: 12,
          })
          if (res.selected != undefined && Object.keys(res.selected).length != 0) {
            let obj = {};
            for (let key in res.selected) {
              Object.defineProperty(obj, key, {
                value: {
                  account: key,
                  ...res.selected[key],
                },
                configurable: true,
                enumerable: true,
                writable: true,
              });
            }
            this.list.added = obj;
          } else {
            this.list.added = {};
          }
          if (res.available != undefined && Object.keys(res.available).length != 0) {
            let obj = {};
            for (let key in res.available) {
              Object.defineProperty(obj, key, {
                value: {
                  account: key,
                  ...res.available[key],
                },
                configurable: true,
                enumerable: true,
                writable: true,
              });
            }
            this.list.not_added = obj;
          } else {
            this.list.not_added = {};
          }
        } else if (this.data.type==ModalsList.CallQueueMembers) {
          const { data: res } = await VOIP_API.endpoints.call_queues.members(this.data.extra_data.call_queue.voipaccount)
          if (res.selectedMemebers != undefined && Object.keys(res.selectedMemebers).length != 0) {
            let obj = {};
            for (let key in res.selectedMemebers) {
              Object.defineProperty(obj, key, {
                value: {
                  account: key,
                  display_name: this.getVoipUsersAlisesDisplayName[key],
                  extn: this.getVoipUsersAlises[key]?.extn,
                  ...res.selectedMemebers[key],
                },
                configurable: true,
                enumerable: true,
                writable: true,
              });
            }
            this.list.added = obj;
          } else {
            this.list.added = {};
          }
          if (res.availableMembers != undefined && Object.keys(res.availableMembers).length != 0) {
            let obj = {};
            for (let key in res.availableMembers) {
              Object.defineProperty(obj, key, {
                value: {
                  account: key,display_name: this.getVoipUsersAlisesDisplayName[key],
                  extn: this.getVoipUsersAlises[key]?.extn,
                  ...res.availableMembers[key],
                },
                configurable: true,
                enumerable: true,
                writable: true,
              });
            }
            this.list.not_added = obj;
          } else {
            this.list.not_added = {};
          }
        } else if (this.data.type==ModalsList.UserCallerIdShared) {
          const { data: res } = await VOIP_API.endpoints.callerid.extensions({
            callerid: this.data.extra_data.number.object=="TelephoneNumbers" ? this.data.extra_data.number.did : this.data.extra_data.number.number,
            type: this.data.extra_data.number.object=="TelephoneNumbers" ? "did" : "callerid",
          })
          if (res.selected != undefined && Object.keys(res.selected).length != 0) {
            let obj = {};
            for (let key in res.selected) {
              Object.defineProperty(obj, key, {
                value: {
                  account: key,
                  html: res.selected[key].html,
                },
                configurable: true,
                enumerable: true,
                writable: true,
              });
            }
            this.list.added = obj;
          } else {
            this.list.added = {};
          }
          if (res.available != undefined && Object.keys(res.available).length != 0) {
            LOGGER.log("run", res.available);
            let obj = {};
            for (let key in res.available) {
              Object.defineProperty(obj, key, {
                value: {
                  account: key,
                  html: res.available[key].html,
                },
                configurable: true,
                enumerable: true,
                writable: true,
              });
            }
            this.list.not_added = obj;
          } else {
            this.list.not_added = {};
          }
        } else if (this.data.type==ModalsList.space_members) {
          const { data: res } = await VOIP_API.endpoints.task_spaces.get(this.data.extra_data.space_id,{
            uid: this.getCurrentUser.uid,
          })
          const selected = (res?.members?.map(i=>i.accountcode) ?? []).filter(i=>i!=this.getCurrentUser.account)
          const available = _.difference(Object.keys(this.$store.state.common.voipusers),selected).filter(i=>i!=this.getCurrentUser.account);
          if (selected != undefined && selected.length != 0) {
            let obj = {};
            for (let key of selected) {
              Object.defineProperty(obj, key, {
                value: {
                  account: key,
                },
                configurable: true,
                enumerable: true,
                writable: true,
              });
            }
            this.list.added = obj;
          } else {
            this.list.added = {};
          }
          if (available != undefined && available.length != 0) {
            LOGGER.log("run", available);
            let obj = {};
            for (let key of available) {
              Object.defineProperty(obj, key, {
                value: {
                  account: key,
                },
                configurable: true,
                enumerable: true,
                writable: true,
              });
            }
            this.list.not_added = obj;
          } else {
            this.list.not_added = {};
          }
        } else if (this.data.type==ModalsList.task_viewer) {
          const { data: res } = await VOIP_API.endpoints.tasklist.availableMembers({
            task_id: this.data.extra_data.task_id,
            flag: 'viewer',
          })
          const members = res?.members ?? []
          const selected = res?.selected ?? []
          const available = _.difference(_.union(members),_.union(selected))
          if (selected != undefined && selected.length != 0) {
            let obj = {};
            for (let key of selected) {
              Object.defineProperty(obj, key, {
                value: {
                  account: key,
                  display_name: this.getVoipUsersAlisesDisplayName[key],
                  extn: this.getVoipUsersAlises[key]?.extn,
                },
                configurable: true,
                enumerable: true,
                writable: true,
              });
            }
            this.list.added = obj;
          } else {
            this.list.added = {};
          }
          if (available != undefined && available.length != 0) {
            LOGGER.log("run", available);
            let obj = {};
            for (let key of available) {
              Object.defineProperty(obj, key, {
                value: {
                  account: key,
                  display_name: this.getVoipUsersAlisesDisplayName[key],
                  extn: this.getVoipUsersAlises[key]?.extn,
                },
                configurable: true,
                enumerable: true,
                writable: true,
              });
            }
            this.list.not_added = obj;
          } else {
            this.list.not_added = {};
          }
        } else if (this.data.type==ModalsList.task_assigned) {
          const { data: res } = await VOIP_API.endpoints.tasklist.availableMembers({
            task_id: this.data.extra_data.task_id,
            flag: 'assigned',
          })
          const members = res?.members ?? []
          const selected = res?.selected ?? []
          const available = _.difference(_.union(members),_.union(selected))
          if (selected != undefined && selected.length != 0) {
            let obj = {};
            for (let key of selected) {
              Object.defineProperty(obj, key, {
                value: {
                  account: key,
                  display_name: this.getVoipUsersAlisesDisplayName[key],
                  extn: this.getVoipUsersAlises[key]?.extn,
                },
                configurable: true,
                enumerable: true,
                writable: true,
              });
            }
            this.list.added = obj;
          } else {
            this.list.added = {};
          }
          if (available != undefined && available.length != 0) {
            LOGGER.log("run", available);
            let obj = {};
            for (let key of available) {
              Object.defineProperty(obj, key, {
                value: {
                  account: key,
                  display_name: this.getVoipUsersAlisesDisplayName[key],
                  extn: this.getVoipUsersAlises[key]?.extn,
                },
                configurable: true,
                enumerable: true,
                writable: true,
              });
            }
            this.list.not_added = obj;
          } else {
            this.list.not_added = {};
          }
        }
        this.list.selected = _.cloneDeep(this.list.added)
      } finally {
        this.api.list.send = false;
      }
    },
    async update(){
      if(this.api.update.send) return;
      try {
        this.api.update.send=true
        const { confirm } = await this.$appConfirmation({
          title: "Warning",
          message: `Are you sure you want to update`,
          variant: 'warning',
          button: {
            no: "No",
            yes: "Yes",
          },
        })
        if(!confirm) return;
        if (this.data.type==ModalsList.AddExtensionModal) {
          await VOIP_API.endpoints.voicemail.updatesetting.updatesharedaccounts(this.user?.voipaccount, {
            members: Object.keys(this.list.selected).join(","),
          })
          this.appNotify({
            message: "Successfully updated!",
            type: "success",
          })
          this.$modal.hide(this.modalName)
        } else if (this.data.type==ModalsList.AddUserWithExtensionModal) {
          await VOIP_API.endpoints.voicemail.updatesetting.updatesharedaccounts(this.user?.voipaccount, {
            members: Object.keys(this.list.selected).join(","),
          })
          this.appNotify({
            message: "Successfully updated!",
            type: "success",
          })
          this.$modal.hide(this.modalName)
        } else if (this.data.type==ModalsList.AssignNumbersTeam) {
          let selected_list = Object.keys(this.list.selected);
          let added_list = Object.keys(this.list.added);
          const new_selected = _.difference(selected_list,added_list);
          const old_deleted = _.difference(added_list,selected_list);
          let selected = {};
          for (let index = 0; index < selected_list.length; index++) {
            const element = selected_list[index];
            Object.defineProperty(selected, element, {
              value: element,
              configurable: true,
              enumerable: true,
              writable: true,
            });
          }
          await VOIP_API.endpoints.teams.updateteammmembers({
            id: this.data.extra_data.team.real_id,
            fid: 12,
            selected: selected,
            new_selected: new_selected.join(','),
            old_deleted: old_deleted.join(','),
          })
          this.appNotify({
            message: "Team members has been updated",
            type: "success",
            duration: 15000,
          })
          this.$emit("update-list");
          this.$modal.hide(this.modalName)
        } else if (this.data.type==ModalsList.CallQueueMembers) {
          let selected_list = Object.keys(this.list.selected);
          let added_list = Object.keys(this.list.added);
          const new_selected = _.difference(selected_list,added_list);
          const old_deleted = _.difference(added_list,selected_list);
          await VOIP_API.endpoints.call_queues.addMembers(this.data.extra_data.call_queue.voipaccount,{
            id: this.data.extra_data.call_queue.voipaccount,
            fid: 12,
            new_selected: new_selected.join(','),
            old_deleted: old_deleted.join(','),
          })
          this.appNotify({
            message: "Call queue members has been updated",
            type: "success",
            duration: 15000,
          })
          this.$emit("update-list");
          this.$modal.hide(this.modalName)
        } else if (this.data.type==ModalsList.UserCallerIdShared) {
          let array = Object.keys(this.list.selected);
          let obj = {};
          for (let index = 0; index < Object.keys(this.list.selected).length; index++) {
            const element = array[index];
            Object.defineProperty(obj, element, {
              value: element,
              configurable: true,
              enumerable: true,
              writable: true,
            });
          }
          await VOIP_API.endpoints.callerid.updateextensions({
            values: obj,
            callerid: this.data.extra_data.number.object=="TelephoneNumbers" ? this.data.extra_data.number.did : this.data.extra_data.number.number,
            type: this.data.extra_data.number.object=="TelephoneNumbers" ? "did" : "callerid",
            uid: this.getCurrentUser.uid,
          })
          this.appNotify({
            message: "Users updated successfully!",
            type: "success",
            duration: 15000,
          })
          this.$emit("update-list",this.list.added);
          this.$modal.hide(this.modalName)
        } else if (this.data.type==ModalsList.space_members) {
          let array = Object.keys(this.list.selected);
          let obj = {};
          for (let index = 0; index < array.length; index++) {
            const element = array[index];
            Object.defineProperty(obj, element, {
              value: element,
              configurable: true,
              enumerable: true,
              writable: true,
            });
          }
          await VOIP_API.endpoints.task_spaces.updateMember({
            space_id: this.data.extra_data.space_id,
            accountcode: [...Object.keys(obj),this.getCurrentUser.account],
          })
          this.appNotify({
            message: "Users updated successfully!",
            type: "success",
            duration: 15000,
          })
          this.$emit("update-list",this.list.added);
          this.$modal.hide(this.modalName);
        } else if (this.data.type==ModalsList.task_viewer) {
          let array = Object.keys(this.list.selected);
          let obj = {};
          for (let index = 0; index < array.length; index++) {
            const element = array[index];
            Object.defineProperty(obj, element, {
              value: element,
              configurable: true,
              enumerable: true,
              writable: true,
            });
          }
          await VOIP_API.endpoints.tasklist.updateViewer({
            task_id: this.data.extra_data.task_id,
            accountcode: Object.keys(obj),
          })
          this.appNotify({
            message: "Users updated successfully!",
            type: "success",
            duration: 15000,
          })
          this.$emit("update-list",this.list.added);
          this.$modal.hide(this.modalName)
        } else if (this.data.type==ModalsList.task_assigned) {
          let array = Object.keys(this.list.selected);
          let obj = {};
          for (let index = 0; index < array.length; index++) {
            const element = array[index];
            Object.defineProperty(obj, element, {
              value: element,
              configurable: true,
              enumerable: true,
              writable: true,
            });
          }
          await VOIP_API.endpoints.tasklist.updateAssignedTo({
            task_id: this.data.extra_data.task_id,
            assigned_to: Object.keys(obj),
          })
          this.appNotify({
            message: "Users updated successfully!",
            type: "success",
            duration: 15000,
          })
          this.$emit("update-list",this.list.added);
          this.$modal.hide(this.modalName)
        }
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.update.send=false
      }
    },
    onSelectAll() {
      const array = _.isArray(this.searchedList) ? this.searchedList : Object.values(this.searchedList);
      if (!this.isAllCheck) {
        for (let index = 0; index < array.length; index++) {
          const data = array[index];
          let key = data.account;
          this.$set(this.list.selected, key, data);
        }
      } else {
        for (let index = 0; index < array.length; index++) {
          const data = array[index];
          let key = data.account;
          this.$delete(this.list.selected, key);
        }
      }
    },
    onChange(data) {
      if (this.api.update.send) return;
      let key = data.account;
      if (!key) return;
      if (this.list.selected[key]) this.$delete(this.list.selected, key, data);
      else this.$set(this.list.selected, key, data);
    },
  },
};
</script>