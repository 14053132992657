var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('OutOfHoursLayout', [_c('div', {
    staticClass: "div2Inner",
    staticStyle: {
      "text-align": "center",
      "height": "42%",
      "background": "rgba(224, 211, 211, 0.48)",
      "border-radius": "9px"
    },
    attrs: {
      "slot": "active-hours"
    },
    slot: "active-hours"
  }, [_c('div', {
    staticClass: "dialer-box-header"
  }, [_vm._v("In Active Hours?")]), _c('div', {
    staticClass: "d-flex justify-content-end w-100",
    staticStyle: {
      "display": "flex",
      "position": "relative"
    }
  }, [_c('a', {
    staticStyle: {
      "text-decoration": "underline",
      "color": "blue"
    },
    on: {
      "click": function ($event) {
        return _vm.openday();
      }
    }
  }, [_c('b-icon', {
    staticClass: "cursor_pointer mt-2",
    attrs: {
      "icon": "pencil-fill",
      "variant": "primary"
    }
  })], 1)])]), _c('div', {
    attrs: {
      "slot": "default"
    },
    slot: "default"
  }, [_vm.isAllDefaulted ? [_vm.forwardSetting.forward_note == 'voicemail' ? [_c('div', {
    staticClass: "selectedText-big"
  }, [_vm._v("Ask to leave a voicemail")]), _c('div', {
    staticClass: "selectedText-small"
  }, [_vm._v("Call will be forward to voicemail")])] : _vm.forwardSetting.forward_note == 'extension' ? [_c('div', {
    staticClass: "selectedText-big"
  }, [_vm._v("Redirect to a user, team or IVR menu")]), _c('div', {
    staticClass: "selectedText-small"
  }, [_vm._v(" Call will be forward to "), _c('span', {
    staticClass: "color-primary cursor_pointer",
    class: {
      'text-underline': !_vm.forwardSetting.extension_name
    }
  }, [_vm._v(_vm._s(_vm.forwardSetting.extension_name || _vm.forwardSetting.forward_number || 'Select extension'))])])] : _vm.forwardSetting.forward_note == 'number' ? [_c('div', {
    staticClass: "selectedText-big"
  }, [_vm._v("Redirect to another number")]), _c('div', {
    staticClass: "selectedText-small"
  }, [_vm._v(" Call will be forward to "), _c('span', {
    staticClass: "color-primary cursor_pointer",
    class: {
      'text-underline': !_vm.forwardSetting.forward_number
    }
  }, [_vm._v(_vm._s(_vm.forwardSetting.forward_number || 'Select Number'))])])] : [_c('div', {
    staticClass: "selectedText-big"
  }, [_vm._v("Keep ringing")]), _c('div', {
    staticClass: "selectedText-small"
  }, [_vm._v("Call forwarding is off")])]] : [_c('div', {
    staticClass: "defaultViewText"
  }, [_vm._v("View Your Default Settings")])], _vm.isAllDefaulted ? _c('span', {
    staticClass: "d-flex w-100 justify-content-end",
    staticStyle: {
      "position": "relative"
    },
    on: {
      "click": function ($event) {
        return _vm.opendefault();
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "pencil-fill",
      "variant": "primary"
    }
  })], 1) : _c('span', {
    staticClass: "d-flex w-100 justify-content-end",
    staticStyle: {
      "position": "relative"
    },
    on: {
      "click": function ($event) {
        return _vm.opendefault();
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "eye-fill",
      "variant": "primary"
    }
  })], 1)], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }