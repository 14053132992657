// components
import NoRecord from './components/utils/NoRecord.vue';
import Loading from './components/utils/Loading.vue';
import CustomeSVG from './components/utils/CustomeSVG.vue';
import Avatar from './components/utils/Avatar.vue';
import Status from './components/utils/Status.vue';
import AudioPlayer from './components/utils/AudioPlayer.vue';
import Table from './components/utils/Table.vue';
import Icon from './components/utils/Icon.vue';
import ToggleButton from './components/utils/ToggleButton.vue';
import Spinner from './components/utils/Spinner.vue';
import Select from './components/utils/Select.vue';
import CircularProgressiveBar from './components/utils/CirclularProgressiveBar.vue';
import Pagination from './components/utils/Pagination.vue';
import ColorPicker from './components/utils/ColorPicker.vue';
import PinCode from './components/utils/PinCode.vue';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
// import LockedFeature from './components/utils/LockedFeature.vue';
// import FlowBlock from "./components/FlowBlock";
// import FlowNode from "./components/FlowNode";

// import GraphNode from "./components/Graph/Node copy.vue";
// import GraphVerticalNode from "./components/Graph/VerticalNode copy.vue"
// import GraphHorizontalNode from './components/Graph/HorizontalNode copy.vue'
// import GraphNumberHorizontalNode from './components/Graph/NumberHorizontalNode.vue';
import GraphNode from "./components/Graph/Node.vue";
// functions
import { $fn } from './utils/index';

// mixin
// import { common } from './mixin'

// filters
import { 
  time,
  // date,
  isEmpty,
  // searchUsers,
  time_audio,
  date_current,
  filter_date_current,
  // first_Character,
  get_property,
  check_empty,
  to_fixed,
  base64_to_url, 
  number_formater,
  flag_icon,
  formatBytes,
  duration_format,
} from './filter'

//directive
import { 
  tag_color,
  input_number_formate,
} from './directives'
import { confirmation } from './bootstrap/confirmation';
export default {
  install(Vue){
    // instance
    Vue.prototype.$helperFunction = {
      apiInstance(...args){return $fn.apiInstance(...args) },
      formInstance(...args){return $fn.formInstance(...args) },
      stateInstance(...args){return $fn.stateInstance(...args) },
    }
    Vue.prototype.$appConfirmation = confirmation

    // component
    // Vue.component("FlowBlock", FlowBlock);
    // Vue.component("FlowNode", FlowNode);

    // Vue.component("vb-graph-number-horizontal-node", GraphNumberHorizontalNode)
    // Vue.component("vb-graph-horizontal-node", GraphHorizontalNode)
    // Vue.component("vb-graph-vertical-node", GraphVerticalNode)
    // Vue.component("vb-graph-node", GraphNode);
    Vue.component("vb-graph-node", GraphNode);
    Vue.component('vb-no-record', NoRecord)
    Vue.component('vb-loading', Loading)
    Vue.component('vb-svg', CustomeSVG)
    Vue.component('vb-avatar', Avatar)
    Vue.component('vb-status', Status)
    Vue.component('vb-audio-player', AudioPlayer)
    Vue.component('vb-table', Table)
    Vue.component('vb-icon', Icon)
    Vue.component('vb-toggle-button', ToggleButton)
    Vue.component('vb-select', Select)
    Vue.component('vb-circular-progressive-bar', CircularProgressiveBar)
    Vue.component('vb-spinner', Spinner)
    Vue.component('vb-pin-code', PinCode)
    Vue.component('vb-pagination', Pagination)
    Vue.component('vb-color-picker', ColorPicker)
    // Vue.component('vb-locked-feature', LockedFeature)
    // filter
    Vue.filter('time',time)
    Vue.filter('flag_icon',flag_icon)
    Vue.filter('duration_format',duration_format)
    // Vue.filter('date',date)
    Vue.filter('isEmpty',isEmpty)
    // Vue.filter('searchUsers',searchUsers)
    Vue.filter('time_audio',time_audio)
    Vue.filter('date_current',date_current)
    Vue.filter('filter_date_current',filter_date_current)
    // Vue.filter('first_Character',first_Character)
    Vue.filter('get_property',get_property)
    Vue.filter('check_empty',check_empty)
    Vue.filter('to_fixed',to_fixed)
    Vue.filter('base64_to_url',base64_to_url)
    Vue.filter('number_formater',number_formater)
    Vue.filter('format_bytes',formatBytes)
    
    // mixin
    // Vue.mixin(common)

    //directive
    Vue.directive('vb-tag-color',tag_color)
    Vue.directive('vb-input-number-formate',input_number_formate)

    // --third party--
    // component
    Vue.component('vue-ctk-date-time-picker',VueCtkDateTimePicker)
    
  }
}