var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "center-zoomIn-transition v2 m-w-500",
    attrs: {
      "width": "500px",
      "height": "auto",
      "scrollable": true,
      "name": "AddPickUpGroupModal"
    },
    on: {
      "closed": _vm.onClosed
    }
  }, [_c('div', {
    staticClass: "dialer-form s1"
  }, [_vm.$store.getters.getIsMobile ? _c('div', {
    staticClass: "dialer-edit-header flex-column"
  }, [_c('div', {
    staticClass: "d-flex w-100 justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Add pickup group")]), _vm.$store.getters.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('AddPickUpGroupModal');
      }
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "dialer-edit-actions d-flex w-100 justify-content-end mt-2"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "submit",
      "disabled": _vm.api_sent.add
    },
    on: {
      "click": _vm.add
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm.api_sent.add ? _c('vb-spinner') : [_vm._v("Done")]], 2)], 1)])]) : _c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Add pickup group")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('AddPickUpGroupModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('b-alert', {
    staticClass: "alert-danger",
    attrs: {
      "dismissible": true,
      "fade": true,
      "show": !!_vm.error
    },
    on: {
      "dismissed": function ($event) {
        _vm.error = '';
      }
    }
  }, [_vm._v(_vm._s(_vm.error))]), _c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.add.apply(null, arguments);
      }
    }
  }, [_c('b-form-group', [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('label', [_vm._v("Name")]), _c('b-form-input', {
    attrs: {
      "disabled": _vm.api_sent.add,
      "type": "text",
      "placeholder": "Enter name"
    },
    model: {
      value: _vm.forms.create.name,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create, "name", $$v);
      },
      expression: "forms.create.name"
    }
  }), _vm.submitted.add ? [!_vm.$v.forms.create.name.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [_vm._v("*Name is Required")]) : _vm._e()] : _vm._e()], 2)])])], 1), _c('div', {
    staticClass: "w-100 mt-4"
  }, [_c('button', {
    staticClass: "fullWidthDoneButton",
    attrs: {
      "type": "submit",
      "disabled": _vm.api_sent.add
    },
    on: {
      "click": _vm.add
    }
  }, [_vm.api_sent.add ? _c('vb-spinner') : [_vm._v("Create pickup group")]], 2)])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }