<template>
  <div>
    <div class="flow__graph designed designedAgain scaleDown-0-6" ref="app_designed" id="app_designed">
      <flowy class="" :nodes="nodes"></flowy>
    </div>
    <AddRemoveFromList :modalName="'EditCallQueueMembersGraphModal'" @update-list="getCallQueue()" />
    <EditCallQueueModal :modalName="`EditCallQueueGraphModal`" />
  </div>
</template>

<script>
import AddRemoveFromList from '../../Modals/AddRemoveFromList.vue'
import EditCallQueueModal from '../../Modals/EditCallQueueModal.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'TeamGraph',
  components: {
    AddRemoveFromList,
    EditCallQueueModal,
  },
  inject: ['getCallQueue'],
  props:{
    accountcode:{
      type: String
    }
  },
  computed:{
    ...mapGetters({
      getCurrentUser: 'getCurrentUser',
      getVoipCallQueues: 'getVoipCallQueues'
    }),
    callQueue(){ return this.getVoipCallQueues.find(call_queue=>this.accountcode==call_queue?.voipaccount || this.accountcode==call_queue?.extn) },
    nodes(){
      if(!this.callQueue) return []
      const voipaccount = this.callQueue?.voipaccount
      const numbers = this.callQueue?.userDid?.map?.(i=>i.did)
      const is_numbers = numbers?.length>0
      const nodes = []
      const callBack = this.callBack
      if(is_numbers){
        nodes.push({
          id: "numbers",
          parentId: -1,
          nodeComponent: "vb-graph-node",
          data: {
            node: 'NumbersList',
            numbers,
            callBack,
          }
        })
      }
      nodes.push({
        id: "info",
        parentId: is_numbers ? "numbers" : -1,
        nodeComponent: "vb-graph-node",
        data: {
          node: 'Info',
          voipaccount,
          callBack,
        }
      })
      nodes.push({
        id: 'callqueuemember',
        parentId: 'info',
        nodeComponent: "vb-graph-node",
        data: {
          node: 'CallQueueMember',
          voipaccount,
          callBack,
        }
      })
      return nodes;
    },
  },
  methods: {
    callBack(message){
      // console.log("check message", message)
      switch (message?.event) {
        case 'edit_members':
          this.$modal.show('EditCallQueueMembersGraphModal',{
            call_queue: this.callQueue, 
            type: 'CallQueueMembers'
          })
          break;
        case 'edit_modal': 
          this.$modal.show('EditCallQueueGraphModal',{ 
            call_queue: this.callQueue, 
          })
          break;
        case 'show_graph':
          this.$emit('change-graph',message?.payload?.voipaccount)
          break;
        default:
          break;
      }
    },
  },
}
</script>

<style>

</style>