var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CenterHorizontalLayout', {
    attrs: {
      "nobottom": true,
      "voipaccount": _vm.voipaccount
    },
    on: {
      "change-graph": function ($event) {
        return _vm.$emit('interface', $event);
      }
    }
  }, [_c('div', {
    staticClass: "node nodeBasic",
    attrs: {
      "slot": "main"
    },
    slot: "main"
  }, [_c('div', {
    staticClass: "w-100 d-flex justify-content-between aling-items-center"
  }, [_c('div', {
    staticClass: "sharedWithHeading"
  }, [_vm._v("Shared With")]), _c('vb-icon', {
    attrs: {
      "icon": "multipleUsersInsideGraph-icon",
      "height": "30px",
      "width": "35px"
    }
  })], 1), _c('div', {
    staticClass: "w-100 sharedWithNumber"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.number, 'extensions.length', 0)))]), _c('div', {
    staticClass: "w-100 d-flex justify-content-end"
  }, [_c('b-icon', {
    attrs: {
      "icon": "pencil-fill",
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.edit();
      }
    }
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }